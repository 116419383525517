define('tm3/pods/report/info/cut-time-entries/controller', ['exports', 'tm3/mixins/report-info-controller', 'moment'], function (exports, _reportInfoController, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        tableName: 'report/cut-time-entries',
        queryParams: ['field', 'start', 'end'],
        field: '',
        start: '',
        end: '',

        groupFieldsOptions: [{ display: 'Employee', value: 'employee' }, { display: 'Case', value: 'case' }],

        selectedField: null,

        getReportIsDisabled: Ember.computed('start', 'end', 'selectedField', function () {
            return !this.get('start') || !this.get('end') || !this.get('selectedField');
        }),

        showDatesWarning: Ember.computed('start', 'end', function () {
            var start = this.get('start');
            var end = this.get('end');
            if (start && end) {
                return (0, _moment.default)(end).isBefore((0, _moment.default)(start));
            }
            return false;
        }),

        actions: {
            clearStart: function clearStart() {
                this.set('start', '');
            },
            clearEnd: function clearEnd() {
                this.set('end', '');
            },
            groupFieldChanged: function groupFieldChanged(selectedField) {
                this.set('selectedField', selectedField);
                this.set('field', selectedField ? selectedField['value'] : '');
            }
        }
    });
});