define('tm3/components/light-table/columns/base-with-filter', ['exports', 'ember-cli-table-pagination/components/light-table/columns/base-with-filter'], function (exports, _baseWithFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _baseWithFilter.default;
    }
  });
});