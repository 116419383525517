define('tm3/pods/components/matter/notes/note-message/component', ['exports', 'tm3/pods/components/matter/notes/note-message/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notesService: Ember.inject.service(),
        layout: _template.default,
        tagName: '',
        isReply: false,
        nest: 0,

        marginLeftByNest: Ember.computed('nest', function () {
            return 80 + 10 * this.get('nest');
        }),

        actions: {
            addNesting: function addNesting(currentNest) {
                return currentNest + 1;
            }
        }
    });
});