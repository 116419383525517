define('tm3/mixins/case-management/info/edit-chm-route', ['exports', 'tm3/utils/matter-column-mapping'], function (exports, _matterColumnMapping) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var applLists = ['status', 'matter_monitor_status', 'matter_analytic_status', 'user_status_options', 'relationship_of_coplaintiff_options', 'parties_in_court_options', 'litigation_types', 'States', 'service_types', 'case_status', 'status', 'taxes_states', 'case_contact_types', 'contact_account_relations', 'case_group_phases'];

    exports.default = Ember.Mixin.create({
        lists: Ember.inject.service(),
        chmId: null,
        collectionId: null,

        beforeModel: function beforeModel(transition) {
            this.set('chmId', transition.params['case-management.info.edit-chm'].collection_has_matter_id);
            this.set('collectionId', transition.params['case-management.info'].collection_id);
        },
        model: function model() {
            var _this = this;

            return Ember.RSVP.hash({
                lists: this.get('lists').fetchMultipleLists(applLists),
                employees: this.store.query('employee', {
                    sort: 'last_name,first_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
                    status: 'Active'
                }),
                allEmployees: this.store.findAll('employee'), // we need to load all employees because employees may not contains inactive employees
                matterTypes: this.store.query('matter-type', {
                    sort: 'name'
                }),
                collectionHasMatter: this.store.query('collection-has-matter', {
                    id: this.get('chmId'),
                    with: 'collection_related_data,matters'
                }),
                collectionHasMatterColumns: this.store.query('collection-has-matter-column', {
                    collection_id: this.get('collectionId')
                }),
                collectionFields: this.store.query('collection-field', {
                    collection_id: this.get('collectionId')
                }),
                collectionHasWorkflow: this.store.query('collection-has-workflow', {
                    collection_id: this.get('collectionId'),
                    sort: 'weight',
                    with: 'workflows,matter_tasks'
                }),
                workflowStatuses: this.get('lists').getListForGroup('workflow_status')
            }).then(function (hash) {
                return _this.store.findRecord('matter', hash.collectionHasMatter.get('firstObject.matter.id')).then(function (matter) {
                    hash.matter = matter;
                    return hash;
                });
            });
        },
        setupController: function setupController(controller, resolved) {
            var matter = resolved.matter;
            controller.set('matter', matter);
            controller.set('collectionHasMatter', resolved.collectionHasMatter.get('firstObject'));
            controller.set('collectionFields', resolved.collectionFields.rejectBy('renderType', 'computed'));

            applLists.forEach(function (item) {
                controller.set(item, resolved.lists[item]);
            });

            controller.set('states', resolved.lists['States']);
            controller.set('matterTypes', resolved.matterTypes);
            controller.set('courtTypes', [Ember.Object.create({ value: 'federal', display: 'Federal' }), Ember.Object.create({ value: 'state', display: 'State' })]);
            controller.set('employees', resolved.employees);

            var matterColumnMapping = _matterColumnMapping.default;
            var chmcFields = [];

            for (var key in matterColumnMapping) {
                var value = matterColumnMapping[key];
                if (!value['type']) {
                    value['type'] = 'text';
                }
                if (!value['selectAction']) {
                    value['selectAction'] = '';
                }
                if (!value['options']) {
                    value['options'] = [];
                }
                if (!value['optionDisplayField']) {
                    value['optionDisplayField'] = 'display';
                }
                if (!value['searchEnabled']) {
                    value['searchEnabled'] = false;
                }
            }

            resolved.collectionHasMatterColumns.forEach(function (chmc) {
                var value = chmc.get('value');
                var valueCamelCase = chmc.get('value').camelize();
                var type = matterColumnMapping[value]['type'];
                var options = [];
                if (type === 'enum' || type === 'radio' || type === 'checkboxes' || type === 'enum-multiple') {
                    options = controller.get(matterColumnMapping[value]['options']);
                }
                var chmcField = Ember.Object.create({
                    value: valueCamelCase,
                    display: chmc.get('display'),
                    type: type,
                    options: options,
                    weight: chmc.get('weight'),
                    optionDisplayField: matterColumnMapping[value]['optionDisplayField'],
                    searchEnabled: matterColumnMapping[value]['searchEnabled'],
                    selectAction: matterColumnMapping[value]['selectAction']
                });
                chmcFields.push(chmcField);
            });

            controller.set('collectionHasMatterColumns', chmcFields);

            // create matterWorkflows objects -> works like edit on Workflows page
            var activeWorkflows = resolved.collectionHasWorkflow.filter(function (chw) {
                return chw.get('enabled') > 0;
            });
            controller.set('activeWorkflows', activeWorkflows);
            controller.set('workflowStatuses', resolved.workflowStatuses);
        }
    });
});