define('tm3/utils/case-workflow', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        // the parent record
        parent: null,
        // the child record
        child: null,

        /**
         * is a workflow enabled for this case
         */
        isEnabled: Ember.computed('child', function () {
            if (Ember.isEmpty(this.get('child'))) {
                return false;
            } else {
                return true;
            }
        }),

        /**
         * inverse of isEnabled
         * used for if statements in template
         */
        isDisabled: Ember.computed('isEnabled', function () {
            var isEnabled = this.get('isEnabled');
            return !isEnabled;
        })
    });
});