define('tm3/pods/accounting/billing/subscription-costs/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return this.store.findRecord('subscription-cost', params.id);
        }
    });
});