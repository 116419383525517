define('tm3/pods/components/current-announcements/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        announcements: [],

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this._super.apply(this, arguments);

            var store = this.get('store');

            store.query('announcement', {
                only_current: true,
                with: 'matters'
            }).then(function (announcements) {
                _this.set('announcements', announcements);
            });
        }
    });
});