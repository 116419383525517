define('tm3/mixins/case-management/info/cases-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        modelName: 'collection-has-matter',
        session: Ember.inject.service(),
        beforeModel: function beforeModel() {
            var _this = this;

            var collectionId = this.modelFor('case-management.info').collection.get('id');

            this.store.peekAll('collection-has-workflow').filterBy('collection_id', collectionId).forEach(function (chw) {
                _this.store.unloadRecord(chw);
            });
            this.store.peekAll('collection-has-matter').filterBy('collection_id', collectionId).forEach(function (chm) {
                _this.store.unloadRecord(chm);
            });
            this.store.peekAll('collection-has-matter-column').filterBy('collection_id', collectionId).forEach(function (chmc) {
                _this.store.unloadRecord(chmc);
            });
            this.store.peekAll('collection-field').filterBy('collection_id', collectionId).forEach(function (cf) {
                _this.store.unloadRecord(cf);
            });
        },
        model: function model(params) {
            return {};
        },
        setupController: function setupController(controller, resolved) {
            controller.set('collection', this.modelFor('case-management.info').collection);

            controller.set('loadDataParams', {
                collection_id: this.modelFor('case-management.info').collection.get('id')
            });
            controller.set('currentEmployee', this.store.findRecord('employee', this.get('session.data.authenticated.id')));
            this._super(controller, resolved);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});