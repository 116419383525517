define('tm3/pods/matter/detail/info/route', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        model: function model() {
            var _this = this;

            var matterId = this.get('currentMatter.id');

            return this.store.findRecord('matter', matterId).then(function (matter) {
                return Ember.RSVP.hash({
                    matter: matter,
                    matterHasRecognitions: _this.store.query('matter-has-recognition', {
                        matter_id: matter.get('id')
                    }),
                    currentBudgets: _this.store.query('budget', {
                        matter_id: matter.get('id')
                    }),
                    currentMatterHasUsers: _this.store.query('matter-has-user', {
                        matter_id: matter.get('id'),
                        with: 'users,groups',
                        with_current_employer: true
                    }),
                    currentFirmHasMatters: _this.store.query('firm-has-matter', {
                        matter_id: matter.get('id')
                    }),
                    caseOwnerGroup: _this.store.findRecord('group', _environment.default.APP.caseOwnerGroupId)
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model.matter);
            controller.set('matterHasUsers', model.matterHasUsers);
            controller.set('matterHasRecognitions', model.matterHasRecognitions);
            controller.set('currentBudgets', model.currentBudgets);

            // process current matter has user
            this.processCurrentMatterHasUsers(model.currentMatterHasUsers, model.caseOwnerGroup, controller);

            // process current firms
            this.processCurrentFirmHasMatters(model.currentFirmHasMatters, controller);
        },
        processCurrentMatterHasUsers: function processCurrentMatterHasUsers(currentMatterHasUsers, caseOwnerGroup, controller) {
            var currentMatterContacts = [];
            var currentOrigins = [];
            var currentCaseOwners = [];

            currentMatterHasUsers.forEach(function (mhu) {
                if (mhu.get('isOrigin')) {
                    currentOrigins.pushObject(mhu);
                }

                if (mhu.get('userType') === 'client' && mhu.get('group.name') !== 'Expired Contact') {
                    currentMatterContacts.pushObject(mhu);
                }

                if (mhu.get('userType') === 'employee' && mhu.get('group.id') === caseOwnerGroup.get('id')) {
                    currentCaseOwners.pushObject(mhu);
                }
            });

            controller.set('currentOrigins', currentOrigins);
            controller.set('currentCaseOwners', currentCaseOwners);
            controller.set('currentMatterContacts', currentMatterContacts);
        },
        processCurrentFirmHasMatters: function processCurrentFirmHasMatters(currentFirmHasMatters, controller) {
            var matterCorporations = [];
            var matterFirms = [];

            currentFirmHasMatters.forEach(function (fhm) {
                switch (fhm.get('relationshipToMatter')) {
                    case 'Corporation':
                        matterCorporations.pushObject(fhm);

                        break;

                    case 'Law Firm':
                        matterFirms.pushObject(fhm);

                        break;

                    default:
                }
            });

            controller.set('currentMatterCorporations', matterCorporations);
            controller.set('currentMatterFirms', matterFirms);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});