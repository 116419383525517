define('tm3/pods/components/sc-calendar/nav/component', ['exports', 'ember-power-calendar/components/power-calendar/nav', 'html2canvas'], function (exports, _nav, _html2canvas) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _nav.default.extend({
        reportBuilder: Ember.inject.service(),

        printing: false,
        printTitle: 'Calendar',

        viewUnit: Ember.computed('calendar.view', function () {
            switch (this.get('calendar.view')) {
                case 'month':
                    return 'month';
                case 'week':
                    return 'week';
                case 'agenda':
                    return 'day';
                default:
                    return 'day';
            }
        }),

        actions: {
            printCalendar: function printCalendar() {
                var _this = this;

                if (this.get('printing')) return;
                this.set('printing', true);

                // doing this to avoid cutting the image generated
                var sideBar = document.querySelector('.main-sidebar');
                var contentWrapper = document.querySelector('.content-wrapper');
                var display = void 0;
                var width = void 0;
                if (sideBar) {
                    display = sideBar.style.display;
                    width = sideBar.style.width;
                    sideBar.style.display = 'none';
                    sideBar.style.width = 0;
                }
                var marginLeft = void 0;
                if (contentWrapper) {
                    marginLeft = contentWrapper.style.marginLeft;
                    contentWrapper.style.marginLeft = 0;
                }
                Ember.run.later(function () {
                    (0, _html2canvas.default)(document.querySelector('.ember-power-calendar')).then(function (canvas) {
                        var img = canvas.toDataURL('image/png');

                        if (sideBar) {
                            Ember.run.next(function () {
                                sideBar.style.display = display;
                                sideBar.style.width = width;
                                contentWrapper.style.marginLeft = marginLeft;
                            });
                        }

                        var jsonObject = {
                            report_builder: {
                                standard: {
                                    type: 'pdf_export',
                                    format: 'pdf',
                                    style: 'misc'
                                },
                                custom: {
                                    html: window.btoa(unescape(encodeURIComponent('<img src="' + img + '" />'))),
                                    header_text: _this.get('printTitle')
                                }
                            }
                        };

                        _this.get('reportBuilder').executeReport(jsonObject);
                        _this.set('printing', false);
                    });
                }, sideBar ? 1000 : 0);
            }
        }
    });
});