define('tm3/mixins/case-management/info-controller', ['exports', 'tm3/mixins/with-full-height-pages'], function (exports, _withFullHeightPages) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_withFullHeightPages.default, {
        application: Ember.inject.controller(),
        reportBuilder: Ember.inject.service(),
        goBack: Ember.inject.service(),

        breadCrumb: 'Case Groups',
        breadCrumbPath: 'case-management.list',

        _fullHeightPages: ['case-management.info.cases'],

        childRouteTitle: Ember.computed('application.currentRouteName', function () {
            var currentRouteName = this.get('application.currentRouteName');

            var rtn = '';

            switch (currentRouteName) {
                case 'case-management.info.cases':
                    rtn = 'Cases';
                    break;

                case 'case-management.info.admin.fields':
                    rtn = 'Configure Fields for this Case Group';
                    break;

                case 'case-management.info.edit-chm':
                    rtn = 'Edit';
                    break;
            }

            return rtn;
        }),

        showAddBtn: Ember.computed('application.currentRouteName', function () {
            return false; //this.get('application.currentRouteName') !== 'case-management.info.edit-chm'
        }),

        actions: {
            toggleAddCase: function toggleAddCase() {
                var isAdding = this.get('isAdding');
                isAdding = !isAdding;
                this.set('isAdding', isAdding);
            },
            exportAsPDF: function exportAsPDF() {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'pdf_export',
                            format: 'pdf',
                            style: 'misc'
                        },
                        custom: {
                            html: window.btoa(unescape(encodeURIComponent(Ember.$('#grid-to-export').html())))
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            },
            goBackToList: function goBackToList() {
                if (!this.get('goBack').back()) {
                    this.transitionToRoute('matter-list', {
                        queryParams: { collection_id: this.get('model.collection.id') }
                    });
                }
            }
        }
    });
});