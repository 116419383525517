define('tm3/pods/report/info/review-time-report/controller', ['exports', 'tm3/mixins/report-info-controller', 'tm-common/mixins/crud/error', 'moment'], function (exports, _reportInfoController, _error, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, _reportInfoController.default, {
        session: Ember.inject.service(),
        reportBuilder: Ember.inject.service(),
        queryParams: ['month', 'selectedEmployee', 'billType', 'selectedCase'],
        month: '',
        selectedEmployee: '',
        selectedCase: '',
        billType: 'Time',

        employee: null,

        // timeColumns: [
        //   Column.create({'displayName': 'Date', 'fieldName': 'billableGroup.start', 'order': 0}),
        //   Column.create({'displayName': 'Case', 'fieldName': 'matter.tmCode', 'apiName' : 'matters:tm_code', 'order': 2}),
        //   Column.create({'displayName': 'Hours', 'fieldName': 'qty', 'order': 3}),
        //   Column.create({'displayName': 'Description', 'fieldName': 'description', 'order': 4})
        // ],
        //
        // pieceColumns: [
        //   Column.create({'displayName': 'Date', 'fieldName': 'billableGroup.start', 'order' : 0}),
        //   Column.create({'displayName': 'Associate', 'fieldName': 'billableGroup.createdBy.fullName', 'order': 1}),
        //   Column.create({'displayName': 'Hours', 'fieldName': 'qty', 'order': 2}),
        //   Column.create({'displayName': 'Description', 'fieldName': 'description', 'order': 3})
        // ],

        componentName: Ember.computed('billType', function () {
            var billType = this.get('billType');
            var componentName = '';
            switch (billType) {
                case 'Time':
                    componentName = 'report/review-time-report-table/time-table';
                    break;

                case 'Piece':
                    componentName = 'report/review-time-report-table/piece-table';
                    break;
            }

            return componentName;
        }),

        actions: {
            clearFrom: function clearFrom() {
                this.set('from', '');
            },
            clearTo: function clearTo() {
                this.set('to', '');
            },
            clearEmployee: function clearEmployee() {
                this.set('employee', null);
                this.set('selectedEmployee', '');
            },
            clearCase: function clearCase() {
                this.set('case', null);
                this.set('selectedCase', '');
            },
            searchMatter: function searchMatter(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchMatter, term, resolve, reject, 600);
                });
            },
            export: function _export(format) {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'review_time_report',
                            format: format,
                            style: 'misc'
                        },
                        custom: {
                            matter_id: this.get('case.id'),
                            created_by_id: this.get('employee.id'),
                            bg_type: this.get('billType')
                        }
                    }
                };
                var m = (0, _moment.default)(this.get('month'), 'MMMM YYYY');
                var start = m.format('YYYY-MM-01');
                var end = m.format('YYYY-MM-' + m.daysInMonth());
                if (start && end) {
                    jsonObject['report_builder']['custom']['start'] = '~' + start + '~' + end;
                } else {
                    if (start) {
                        jsonObject['report_builder']['custom']['start'] = '>=' + start;
                    }
                    if (end) {
                        jsonObject['report_builder']['custom']['start'] = '<=' + end;
                    }
                }
                this.get('reportBuilder').executeReport(jsonObject);
            }
        },

        _performSearchMatter: function _performSearchMatter(term, resolve, reject) {
            return this.store.query('matter', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});