define('tm3/pods/components/matter/admin/doc-report-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            deleteRecord: function deleteRecord() {
                this.attrs.deleteReport(this.get('deleteItem._content'));
            },
            rollback: function rollback() {
                this.get('report').rollback();
            }
        }
    });
});