define('tm3/pods/components/table-pagination/toolbar-slim-quicksearch/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['col-xs-8', 'col-md-8'],
        button: Ember.computed.reads('toolsParams.buttonSize')
    });
});