define('tm3/pods/components/me/time-entry-card/component', ['exports', 'ember-light-table/components/lt-row'], function (exports, _ltRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ltRow.default.extend({
        dataObj: Ember.computed('row.content', function () {
            var bg = this.get('row.content');

            return {
                time: {
                    case: bg.get('mainBillables.firstObject.matter.tmCode'),
                    case_id: bg.get('mainBillables.firstObject.matter.id'),
                    person_of_interest: bg.get('mainBillables.firstObject.matter.caseNickname'),
                    start: bg.get('start'),
                    name: bg.get('mainBillables.firstObject.billableType.name'),
                    employee_name: bg.get('mainBillables.firstObject.employeeName'),
                    last_name: bg.get('createdBy.lastName'),
                    first_name: bg.get('createdBy.firstName'),
                    budget_name: bg.get('mainBillables.firstObject.budget.name'),
                    approval_status: bg.get('approvalStatus'),
                    description: bg.get('mainBillables.firstObject.description'),
                    cutDescription: bg.get('mainBillables.firstObject.cutDescription'),
                    cutReasons: bg.get('mainBillables.firstObject.cutReasons'),
                    trainee: bg.get('mainBillables.firstObject.trainee'),
                    is_money: '1',
                    total: bg.get('total'),
                    qty: bg.get('mainBillables.firstObject.qty'),
                    cut: bg.get('mainBillables.firstObject.cut'),
                    rate: bg.get('mainBillables.firstObject.rate'),
                    billType: bg.get('mainBillables.firstObject.billType'),
                    canSeeAmounts: bg.get('canSeeAmounts')
                },
                expenses: bg.get('expenses').map(function (exp) {
                    return {
                        is_money: '1',
                        cut: exp.get('cut'),
                        qty: exp.get('qty'),
                        rate: exp.get('rate'),
                        name: exp.get('billableType.name'),
                        description: exp.get('description')
                    };
                })
            };
        }),
        actions: {
            add: function add() {
                this.sendAction('addBillableFromCardAction', this.get('dataObj.time.case_id'));
            }
        }
    });
});