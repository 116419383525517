define('tm3/pods/admin/matter-type/info/workflow-default/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        sortFieldsBy: ['workflowDefault.enabled:desc', 'workflowDefault.weight:asc', 'workflow.name'],
        sortedWorkflows: Ember.computed.sort('model', 'sortFieldsBy'),

        /**
         * This mixin requires 3 field on the model mixed:
         * enabled
         * viewable
         */
        actions: {
            /**
             * either enable or disable a workflow for the current matter
             * @param workflow
             */
            toggleMatterWorkflowDefault: function toggleMatterWorkflowDefault(item) {
                var _this = this;

                if (!item.constructor.modelName) {
                    // create record in API first
                    var mwd = this.store.createRecord('matter-workflow-default', {
                        matterType: this.get('matterType'),
                        workflow: item.workflow,
                        enabled: 1
                    });

                    mwd.save().then(function (data) {
                        _this.get('notify').success('Enable workflow for case.');
                        _this.send('refreshModel');
                    }, function (reason) {
                        _this.validationReport(mwd);
                    });
                } else {
                    // remove record from api
                    item.destroyRecord();
                    this.get('notify').success('Disable workflow for case.');
                    this.send('refreshModel');
                }
            },


            /**
             * for a supplied field, update it's weight
             *
             * @param sortedFields
             * @param draggedModel
             */
            reorderFields: function reorderFields(sortedFields, draggedModel) {
                var weight = 1;
                sortedFields.forEach(function (field) {
                    // find the mhw to be sorted and submit save to api
                    if (field.workflowDefault.get('enabled') === 1) {
                        field.workflowDefault.set('weight', weight);
                        field.workflowDefault.save();
                    }

                    weight++;
                });

                this.get('notify').success('New field order saved');
                // refresh after save complete
                this.send('refreshModel');
            },


            /**
             * take in an updated workflow and save record to api
             * @param matterWorkflowDefault
             */
            updateMatterWorkflowDefault: function updateMatterWorkflowDefault(matterWorkflowDefault) {
                var _this2 = this;

                matterWorkflowDefault.save().then(function (data) {
                    _this2.get('notify').success('Change Saved');
                }, function (reason) {
                    _this2.validationReport(matterWorkflowDefault);
                });
            }
        }
    });
});