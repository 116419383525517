define('tm3/pods/components/matter/dashboard/jury-project/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        title: 'Dashboard Panel, please pass as attribute',
        route: 'matter.analytics',
        matterHasUsers: null,

        actions: {
            saveCaseDetails: function saveCaseDetails() {
                var _this = this;

                this.get('matter').save().then(function () {
                    _this.get('notify').success('Case details saved successfully');
                    _this.refreshModel();
                    _this.set('editing', false);
                }).catch(function () {
                    _this.validationReport(_this.get('matter'));
                });
            },
            invalidData: function invalidData() {
                this.get('notify').warning('Some data is invalid');
            },
            cancel: function cancel() {
                this.get('matter').rollbackAttributes();
                this.set('editing', false);
            },
            updateMoneyValue: function updateMoneyValue(field, newValue) {
                var matter = this.get('matter');

                matter.set(field, newValue);
            }
        }
    });
});