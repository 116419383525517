define('tm3/pods/components/documents/subject-page-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        isReport: false,
        isTab: false,
        isOther: false
    });
});