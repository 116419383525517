define('tm3/helpers/value-from-map', ['exports', 'tm-common/helpers/value-from-map'], function (exports, _valueFromMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _valueFromMap.default;
    }
  });
  Object.defineProperty(exports, 'valueFromMap', {
    enumerable: true,
    get: function () {
      return _valueFromMap.valueFromMap;
    }
  });
});