define('tm3/services/go-back', ['exports', 'tm-common/services/go-back'], function (exports, _goBack) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _goBack.default;
    }
  });
});