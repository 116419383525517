define('tm3/pods/accounting/billing/subscription-costs/processes/info/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        process: Ember.computed.alias('model.process'),
        billables: Ember.computed.alias('model.billables'),
        columns: [{
            propertyName: 'invoice.id',
            title: 'Invoice #',
            linkTo: 'accounting.billing.invoices.info',
            linkParam: ['invoice.id']
        }, {
            propertyName: 'description',
            title: 'Description'
        }, {
            propertyName: 'matter.tmCode',
            title: 'File #'
        }, {
            propertyName: 'qty',
            title: 'QTY'
        }, {
            propertyName: 'formattedRate',
            title: 'Rate'
        }, {
            propertyName: 'formattedTotal',
            title: 'Total'
        }]
    });
});