define('tm3/helpers/get-appl-list', ['exports', 'tm-common/helpers/get-appl-list'], function (exports, _getApplList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getApplList.default;
    }
  });
});