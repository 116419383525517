define('tm3/pods/me/reimbursements/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),

        queryParams: ['reimbursementStatus', 'perPage', 'page', 'byDate', 'from', 'to', 'employee', 'excludeItemsWithoutReceipts'],
        reimbursementStatus: 'pending',
        perPage: 100,
        page: 1,
        byDate: 'Expense Date',

        from: null,
        to: null,

        employee: null,

        selectAll: false,
        excludeItemsWithoutReceipts: false,

        expenses: Ember.computed.alias('model.billables'),

        actions: {
            changePage: function changePage(p) {
                this.set('page', p);
            },
            clearFilters: function clearFilters() {
                this.setProperties({
                    reimbursementStatus: 'pending',
                    byDate: 'Expense Date',
                    from: null,
                    to: null,
                    page: 1
                });
            }
        }
    });
});