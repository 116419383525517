define('tm3/models/outbound-communication-log', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany,
        Model = _emberData.default.Model;
    exports.default = Model.extend({
        appName: attr('string'),
        countClientsSentTo: attr('number'),
        countEmployeesSentTo: attr('number'),
        countThingsSent: attr('number'),
        createdOn: attr('string'),
        sentFrom: attr('string'),

        createdBy: belongsTo('employee'),
        matter: belongsTo('matter'),
        things: hasMany('outbound-communication-log-thing'),
        users: hasMany('outbound-communication-log-user')
    });
});