define('tm3/pods/report/list/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        reportCatalog: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                reportCatalog: this.get('reportCatalog').getReportList(),
                dataReports: this.store.findAll('data-report'),
                dataReportHasUsers: this.store.query('data-report-has-user', {
                    user_id: this.get('currentUser.authUserId')
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            resolved.dataReports.forEach(function (dr) {
                var drhu = resolved.dataReportHasUsers.findBy('dataReport.id', dr.get('id'));

                if (!drhu) {
                    return;
                }
                var catalogReport = resolved.reportCatalog.findBy('apiName', dr.get('name'));

                if (Ember.isPresent(dr.get('url'))) {
                    dr.set('displayName', dr.get('name'));
                    dr.set('route', 'report.info.fake-report');
                } else if (catalogReport) {
                    if (catalogReport.get('displayName')) {
                        dr.set('displayName', catalogReport.get('displayName'));
                    }

                    if (catalogReport.get('perPage')) {
                        dr.set('perPage', catalogReport.get('perPage'));
                    }

                    if (catalogReport.get('route')) {
                        dr.set('route', catalogReport.get('route'));
                    }

                    if (catalogReport.get('component')) {
                        dr.set('component', catalogReport.get('component'));
                    }

                    if (catalogReport.get('subReport')) {
                        dr.set('subReport', catalogReport.get('subReport'));
                    }

                    if (catalogReport.get('parentReport')) {
                        dr.set('parentReport', catalogReport.get('parentReport'));
                    }
                }
            });

            this._super(controller, resolved.dataReports);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});