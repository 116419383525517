define('tm3/pods/components/matter/dashboard/case-group-details/component', ['exports', 'tm3/mixins/case-group-column'], function (exports, _caseGroupColumn) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var EditableColumn = Ember.Object.extend({
        column: null,
        editCellComponent: null
    });

    exports.default = Ember.Component.extend(_caseGroupColumn.default, {
        store: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        title: 'Case Group Details',

        matterId: Ember.computed('matterId', 'currentMatter.id', function () {
            return this.get('matterId') ? this.get('matterId') : this.get('currentMatter.id');
        }),

        columns: [],

        editableColumns: Ember.computed('columns', function () {
            var _this = this;

            return this.get('columns').map(function (c) {
                return EditableColumn.create({
                    column: c,
                    editCellComponent: _this.getEditCellComponent(c.get('editRenderType')),
                    viewCellComponent: _this.getViewCellComponent(c.get('viewRenderType'))
                });
            });
        }),

        hasData: Ember.computed('editableColumns', function () {
            var _this2 = this;

            return this.get('editableColumns').find(function (ec) {
                return Ember.isPresent(_this2.get('currentMatter.matter.' + ec.get('column.fieldName')));
            });
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this._getCaseGroup();
        },
        _getCaseGroup: function _getCaseGroup() {
            var _this3 = this;

            this.get('currentMatter.matter.collection').then(function (collection) {
                if (!collection) {
                    _this3.set('columns', []);

                    return;
                }
                _this3.get('store').query('collection-column', {
                    collection_id: collection.get('id'),
                    view_render_type: '!workflow!',
                    dashboard_viewable: 1
                }).then(function (caseGroupFieldSettings) {
                    _this3.set('columns', caseGroupFieldSettings);
                });
            });
        }
    });
});