define('tm3/helpers/array-by-index', ['exports', 'tm-common/helpers/array-by-index'], function (exports, _arrayByIndex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _arrayByIndex.default;
    }
  });
  Object.defineProperty(exports, 'arrayByIndex', {
    enumerable: true,
    get: function () {
      return _arrayByIndex.arrayByIndex;
    }
  });
});