define('tm3/pods/components/report/revenue-summary/by-person/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            billedTotal: function billedTotal(items) {
                return items.reduce(function (acc, item) {
                    if (item.hasOwnProperty('total_billed_amount')) {
                        return acc + parseFloat(item.total_billed_amount) + parseFloat(item.total_billed_expense);
                    } else {
                        return acc;
                    }
                }, 0);
            },
            nonBilledTotal: function nonBilledTotal(items) {
                return items.reduce(function (acc, item) {
                    if (item.hasOwnProperty('total_non_billed_amount')) {
                        return acc + parseFloat(item.total_non_billed_amount) + parseFloat(item.total_non_billed_expense);
                    } else {
                        return acc;
                    }
                }, 0);
            },
            wipTotal: function wipTotal(items) {
                return items.reduce(function (acc, item) {
                    if (item.hasOwnProperty('total_wip_amount')) {
                        return acc + parseFloat(item.total_wip_amount) + parseFloat(item.total_wip_expense);
                    } else {
                        return acc;
                    }
                }, 0);
            }
        }
    });
});