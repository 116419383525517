define('tm3/pods/components/matter/subjects/subject-type-cell/component', ['exports', 'tm3/pods/components/matter/subjects/subject-type-cell/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        showRelationship: false,
        didReceiveAttrs: function didReceiveAttrs() {
            var subjectType = this.get('row.subjectType').split('_')[0];
            this.set('subjectTypeInitial', subjectType.substring(0, 1).toUpperCase());
            var relationshipText = this.get('showRelationship') && this.get('row.relationship') ? ' - ' + this.get('row.relationship') : '';
            this.set('tooltipText', subjectType + relationshipText);
        }
    });
});