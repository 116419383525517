define('tm3/pods/components/billable-groups/billable-group-bulk-edit/component', ['exports', 'tm3/pods/components/billable-groups/billable-group-bulk-edit/template', 'ember-lifeline/mixins/run'], function (exports, _template, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        layout: _template.default,

        billableGroup: null,
        oldInvoiceGroup: Ember.computed.reads('billableGroup.billables.firstObject.invoiceGroup'),
        oldBudget: Ember.computed.reads('billableGroup.billables.firstObject.budget'),
        matter: null,
        selectedMatter: null,
        selectedBudget: null,
        selectedInvoiceGroup: null,

        invoiceGroups: null,
        isInvoiceGroupsLoading: false,
        budgets: null,
        isBudgetsLoading: false,
        isMatterLocked: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('selectedMatter', this.get('matter'));
            this.selectedMatterChanged();
        },

        selectedMatterChanged: Ember.observer('selectedMatter', function () {
            var _this = this;

            // reset budget and invoice on matter change
            var matter = this.get('selectedMatter');
            var isFirstLoad = false;
            if (this.get('matter') && matter && this.get('matter').get('id') === matter.get('id')) {
                // set the old invoice and budget
                this.setProperties({
                    selectedBudget: this.get('oldBudget'),
                    selectedInvoiceGroup: this.get('oldInvoiceGroup')
                });
                isFirstLoad = true;
            } else {
                // reset budget and invoice on matter change
                this.setProperties({
                    selectedBudget: null,
                    selectedInvoiceGroup: null
                });
            }
            if (matter) {
                this.get('store').query('matter', {
                    id: matter.get('id'),
                    with: 'budgets,invoice_groups'
                }).then(function () {
                    // load matter's invoiceGroups
                    _this.set('isInvoiceGroupsLoading', true);
                    matter.get('invoiceGroups').then(function (items) {
                        _this.set('invoiceGroups', items);
                        _this.set('isInvoiceGroupsLoading', false);
                        if (items.get('length') > 0 && !_this.get('selectedInvoiceGroup')) {
                            _this.set('selectedInvoiceGroup', items.get('firstObject'));
                        }
                    }, function () {
                        _this.set('isInvoiceGroupsLoading', false);
                        _this.get('notify').error('Failed to load Invoice Groups for matter');
                    });
                    // load matter's budgets
                    _this.set('isBudgetsLoading', true);
                    matter.get('budgets').then(function (items) {
                        _this.set('budgets', items);
                        _this.set('isBudgetsLoading', false);
                        if (items.get('length') > 0 && !_this.get('selectedBudget') && !isFirstLoad) {
                            _this.set('selectedBudget', items.get('firstObject'));
                        }
                    }, function () {
                        _this.set('isBudgetsLoading', false);
                        _this.get('notify').error('Failed to load Budgets for matter');
                    });
                });
            }
        }),

        actions: {
            save: function save() {
                var start = this.get('billableGroup.start');
                var matterId = this.get('selectedMatter.id');
                var invoiceGroupId = this.get('selectedInvoiceGroup.id');
                var budgetId = this.get('selectedBudget.id');
                if (start && matterId && invoiceGroupId) {
                    this.sendAction('save', {
                        start: start,
                        matter_id: matterId,
                        budget_id: budgetId,
                        invoice_group_id: invoiceGroupId
                    });
                } else {
                    this.get('notify').error('You must select a Start date, Case and Invoice Group');
                }
            },
            cancel: function cancel() {
                this.sendAction('cancel');
            },


            /**
             * when the user types new values into the select box
             * this can also act as a place to perform custom filtering/munging/merging of API results
             * before pushing the final list to the autosuggest
             * @param inputVal
             */
            searchMatters: function searchMatters(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchMatter, term, resolve, reject, 600);
                });
            }
        },

        _performSearchMatter: function _performSearchMatter(term, resolve, reject) {
            return this.get('store').query('matter', {
                'name||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});