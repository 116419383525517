define('tm3/pods/components/matter/dashboard/sent-reports/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
        Provides a dashboard widget for viewing the twenty most recent
        `OutboundCommunicationLogRecords`.
    
        @module MatterDashboardSentReports
        @extends Ember.Component
     */

    var ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        /**
            Provides the twenty most recent `OutboundCommunicationLogRecords` for
            the current `MatterRecord`.
             @property sentReports
            @type {ArrayProxy}
         */
        sentReports: Ember.computed('matterId', function () {
            var _getProperties = this.getProperties('matterId', 'store'),
                matterId = _getProperties.matterId,
                store = _getProperties.store;

            var sentReports = store.query('outbound-communication-log', {
                matter_id: matterId,
                page: 1,
                perPage: 20,
                with: 'employees,outbound_communication_log_things,outbound_communication_log_users',
                sortField: '-created_on'
            });

            return ArrayPromiseProxy.create({ promise: sentReports });
        })
    });
});