define('tm3/pods/matter/subjects/info/documents/route', ['exports', 'tm-common/mixins/get-resource-has-tags', 'tm3/mixins/alert-users-route'], function (exports, _getResourceHasTags, _alertUsersRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_getResourceHasTags.default, _alertUsersRoute.default, {
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        subjectId: null,

        beforeModel: function beforeModel(transition) {
            this.set('subjectId', transition.params['matter.subjects.info'].subject_id);
        },
        model: function model(params) {
            return Ember.RSVP.hash({
                fileTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                matter: this.get('currentMatter.matter'),
                subjects: this.store.query('subject', {
                    matter_id: this.get('currentMatter.matter.id'),
                    sort: 'last_name,first_name'
                }),
                tags: this.store.query('tag', { type: 'document' }),
                reports: this.store.query('report', {
                    matter_id: this.get('currentMatter.id')
                }),
                documents: this.store.query('document', {
                    subject_id: this.get('subjectId'),
                    with: 'reports,resource_has_tags,subjects'
                }),
                alertUsers: this.employeesModelMixin(),
                scrapes: this.store.query('scrape', {
                    matter_id: this.get('currentMatter.id'),
                    subject_id: this.get('subjectId'),
                    with: 'hyperlinks'
                })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model.matter);
            var subjectInfo = this.modelFor('matter.subjects.info');
            var subject = subjectInfo.subject.get('firstObject');
            controller.set('subject', subject);
            controller.set('documents', model.documents);
            controller.set('matter', model.matter);
            controller.set('fileTypes', model.fileTypes.rejectBy('display', 'REPORT TAB').sortBy('weight'));
            controller.set('fileTypesForForms', model.fileTypes.sortBy('weight'));
            controller.set('subjects', model.subjects);
            controller.set('tags', model.tags);
            controller.set('reports', model.reports);
            controller.set('alertUsers', model.alertUsers);
            controller.set('posts', subjectInfo.posts);
            controller.set('scrapes', model.scrapes);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            },
            willTransition: function willTransition(transition) {
                var documents = this.get('controller.documents');
                documents.forEach(function (doc) {
                    doc.rollbackAttributes();
                });
                this.send('modifyFormIsOpen', false);
                return true;
            }
        }
    });
});