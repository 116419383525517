define('tm3/pods/matter/workflows/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column', 'tm-common/helpers/is-orphan-matter-field', 'tm-common/mixins/crud/error'], function (exports, _controller, _column, _isOrphanMatterField, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, _error.default, {
        appName: 'tm-admin',
        perPage: 75,
        breadCrumb: 'Workflows',
        breadCrumbPath: 'matter.workflows.list',
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        reportBuilder: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),
        store: Ember.inject.service(),
        analyticList: Ember.inject.service(),
        tableTitle: 'Workflows',
        editPath: 'matter.workflows.edit',
        linkPath: 'matter.subjects.info.documents',

        with: 'subject_workflows,analytics',
        sortField: 'member_id',
        refreshTime: null,
        refreshTimeOptions: null,

        quickSearchField: 'last_name||first_name||member_id||court_panel||court_juror',

        showEditSubjectWorkflowsModal: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('refreshTime', { seconds: 0, display: 'Never autorefresh' });
            this.set('refreshTimeOptions', [{ seconds: 0, display: 'Never autorefresh' }, { seconds: 60, display: 'Autorefresh Every minute' }, { seconds: 300, display: 'Autorefresh Every 5 minutes' }, { seconds: 600, display: 'Autorefresh Every 10 minutes' }]);
        },


        currentEmployee: Ember.computed('employees.[]', 'session.data.authenticated.id', function () {
            return this.get('employees').findBy('id', this.get('session.data.authenticated.id'));
        }),

        setupWorkflowData: function setupWorkflowData(extraData) {
            var _this = this;

            var ENV = this.get('ENV');

            this.set('subjectWorkflowColumns', extraData.subjectWorkflowColumns);
            this.set('matterHasWorkflows', extraData.matterHasWorkflows);
            this.set('employeesForEditForm', extraData.employees);
            this.set('workflowStatusesForEditForm', extraData.workflowStatuses);

            extraData.subjectWorkflowColumns.forEach(function (col) {
                if (col.get('displayName') === 'S') {
                    col.set('width', '40px');
                } else if (!col.get('width')) {
                    col.set('width', '115px');
                }

                if (col.get('workflowAssignedColumn')) {
                    col.set('usesPowerSelect', true);
                    col.set('valuePath', 'user');
                    col.set('displayPath', 'user.fullName');
                }

                if (col.get('workflowTimeColumn')) {
                    col.set('enableSearch', false);
                }

                /* switch (col.get('fieldName')) {
                   case 'publishedOn':
                   case 'createdOn':
                   case 'dueOn':
                   col.set('advFilterComponent', 'table-filter-components/date')
                   break
                   } */
            });

            var publishedOn = extraData.subjectWorkflowColumns.findBy('fieldName', 'publishedOn');

            if (Ember.isPresent(publishedOn)) {
                publishedOn.set('cellComponent', 'formatted-date-cell');
            }

            this.set('allSubjectFields', extraData.subjectWorkflowColumns);

            var formattedFields = extraData.subjectWorkflowColumns.filter(function (mf) {
                return !Ember.isPresent(mf.get('mergeField')) || (0, _isOrphanMatterField.isOrphanMatterField)([mf, _this.get('currentMatter.matterFields'), 'isViewableWorkflow']);
            });

            var subjectTypeCellComponentName = 'matter.subjects.subject-type-cell';
            var matterTypeId = this.get('currentMatter.matter.matterType.id');

            if (this.get('appName') === 'js-admin' && matterTypeId && ENV.APP.jurorMatterTypeIds.indexOf(parseInt(matterTypeId, 10)) > -1) {
                formattedFields = formattedFields.rejectBy('fieldName', 'relationship');
                subjectTypeCellComponentName = 'matter.subjects.subject-type-with-relationship-cell';
                formattedFields.unshiftObject(_column.default.create({
                    displayName: 'Type',
                    fieldName: 'subject_type',
                    enableSearch: false,
                    apiName: 'subject_type',
                    order: formattedFields.length + 1,
                    width: '40px',
                    cellClassNames: 'subject-type-cell',
                    cellComponent: subjectTypeCellComponentName
                }));
            }

            this.set('columns', formattedFields);

            var juryLocations = extraData.juryLocations.toArray();

            juryLocations.unshiftObjects([Ember.Object.create({
                id: 'no_change',
                value: 'no_change',
                display: 'No Change'
            }), Ember.Object.create({
                id: 'clear_values',
                value: 'clear_values',
                display: 'Clear Values'
            })]);
            this.set('juryLocations', juryLocations);

            if (extraData.subjectWorkflowColumns.findBy('fieldName', 'location')) {
                this.set('showLocations', true);
            } else {
                this.set('showLocations', false);
            }

            this.set('activeWorkflows', extraData.matterHasWorkflows.filter(function (mhw) {
                return mhw.get('enabled') > 0;
            }).map(function (mhw) {
                var matterWorkflow = Ember.Object.create({
                    id: mhw.get('id'),
                    name: mhw.get('workflow.name'),
                    matter_has_workflows_id: mhw.get('id')
                });

                matterWorkflow.set('assigned',
                // eslint-disable-next-line no-bitwise
                mhw.get('enabled') & 1 ? Ember.Object.create({
                    id: 'no_change',
                    fullName: 'No Change'
                }) : false);
                // eslint-disable-next-line no-bitwise
                matterWorkflow.set('showAssigned', !!(mhw.get('enabled') & 1));
                matterWorkflow.set('status',
                // eslint-disable-next-line no-bitwise
                mhw.get('enabled') & 2 ? Ember.Object.create({
                    value: 'no_change',
                    display: 'No Change'
                }) : false);
                matterWorkflow.set('showStatus', true);
                matterWorkflow.set('comment', '');
                matterWorkflow.set('showComment', true);
                matterWorkflow.set('hours', '');
                matterWorkflow.set('showHours', true);
                matterWorkflow.set('deadline', '');
                matterWorkflow.set('showDeadline', true);

                return matterWorkflow;
            }));

            var employees = extraData.employees.toArray();

            employees.unshiftObjects([Ember.Object.create({
                id: 'no_change',
                fullName: 'No Change'
            }), Ember.Object.create({
                id: 'clear_values',
                fullName: 'Clear Values'
            })]);
            this.set('employees', employees.filterBy('status', 'Active'));

            var workflowStatuses = extraData.workflowStatuses.toArray();

            workflowStatuses.unshiftObjects([Ember.Object.create({
                value: 'no_change',
                display: 'No Change'
            }), Ember.Object.create({
                value: 'clear_values',
                display: 'Clear Values'
            })]);
            this.set('workflowStatuses', workflowStatuses);

            var currentMatter = this.get('currentMatter');

            var matterFields = currentMatter.get('matterFields').map(function (item) {
                return Ember.ObjectProxy.create({
                    content: item
                });
            });

            this.set('matterFields', matterFields.filter(function (mf) {
                return mf.get('isViewableWorkflow');
            }));

            this.set('matterFieldFamilies', currentMatter.get('matterFieldFamilies'));
            this.set('analyticsLists', this.store.peekAll('analytic-list'));

            if (this.get('shouldSetLocationToPool')) {
                this.setLocationToPool();
                this.set('shouldSetLocationToPool', false);
            }

            this.configureFilterObservers();

            return extraData;
        },
        setLocationToPool: function setLocationToPool() {
            var column = this.get('columns').findBy('displayName', 'Location');

            if (column) {
                column.set('advFilterOperator', {
                    display: 'Contains',
                    value: 'contains',
                    input: 1
                });
                column.set('advFilterValue', 'pool');
                column.set('filterValue', 'pool');
            }
        },
        customStateOnFirstLoad: function customStateOnFirstLoad() {
            if (this.get('appName') === 'js-admin') {
                this.set('filterParams', { location: 'pool' });
                this.setLocationToPool();
            }
        },


        columnFields: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('apiInteractionName');
            });
        }),
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),
        columnWidths: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('width');
            });
        }),

        toolsParams: Ember.computed('activeWorkflows.[]', 'appName', 'columnFields', 'columnNames', 'columnWidths', 'currentMatter.matter', 'currentParams', 'employees', 'juryLocations', 'lastUpdate', 'refreshTime', 'selectedRows', 'showLocations', 'tableData.[]', 'tableTitle', 'totalRecords', 'totalWithoutFilter', 'workflowStatuses', function () {
            var _this2 = this;

            return {
                totalRecords: this.get('totalRecords'),
                totalWithoutFilter: this.get('totalWithoutFilter'),
                sizeCurrentPage: this.get('tableData.length'),
                toolsComponent: 'workflows-list/extended-tools',
                tableTitle: this.get('tableTitle'),
                appName: this.get('appName'),
                columnFields: this.get('columnFields'),
                columnNames: this.get('columnNames'),
                columnWidths: this.get('columnWidths'),
                currentParams: this.get('currentParams'),
                matter: this.get('currentMatter.matter'),
                juryLocations: this.get('juryLocations'),
                showLocations: this.get('showLocations'),
                selectedRows: this.get('selectedRows'),
                activeWorkflows: this.get('activeWorkflows'),
                employees: this.get('employees'),
                workflowStatuses: this.get('workflowStatuses'),
                refreshTime: this.get('refreshTime'),
                updateRefreshTime: function updateRefreshTime(selectedRefresh) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('updateRefreshTime', selectedRefresh);
                },
                saveAssignMultiple: function saveAssignMultiple(selectedRows, activeWorkflows, selectedJuryLocation) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('saveAssignMultiple', {
                        selectedRows: selectedRows,
                        activeWorkflows: activeWorkflows,
                        selectedJuryLocation: selectedJuryLocation
                    });
                },
                refreshModel: function refreshModel() {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('refreshModel');
                },
                lastUpdate: this.get('lastUpdate'),
                showModal: function showModal(name) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('showModal', name);
                },
                exportFlagReport: function exportFlagReport() {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('exportFlagReport');
                },
                exportSubjectList: function exportSubjectList(format, flagReport) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('exportSubjectList', format, flagReport);
                },
                editFlag: function editFlag(subject) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('editFlag', subject);
                },
                publishSubjects: function publishSubjects(selectedRows) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('publishSubjects', selectedRows);
                },
                publishLimitedSubjects: function publishLimitedSubjects(selectedRows) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this2.send('publishLimitedSubjects', selectedRows);
                }
            };
        }),

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        getRefreshOptionBySeconds: function getRefreshOptionBySeconds(seconds) {
            var defaultRefresh = { seconds: 60, display: 'Autorefresh Every minute' };
            var refreshTimeOptions = this.get('refreshTimeOptions');
            var selected = null;

            refreshTimeOptions.forEach(function (option) {
                if (option.seconds === seconds) {
                    selected = option;
                }
            });

            return selected || defaultRefresh;
        },
        fromTableSettingsState: function fromTableSettingsState(state) {
            if (Ember.isEmpty(state)) return;
            this.set('page', state.page);
            // this.set('perPage', state.perPage);
            this.set('sortField', state.sortField);
            this.set('quickSearch', state.quickSearch);
            this.set('filterParams', state.filterParams);
            this.set('extraParams', state.extraParams);
            this.set('refreshTime', this.getRefreshOptionBySeconds(state.refreshTime));
        },
        toTableSettingsState: function toTableSettingsState() {
            /* We need to return a json object
               that can be used to re-hydrate the controller
               after a cold refresh to use saved filters
               sorting, page, perPage etc that are not part of
               the queryParams */

            var mapColumn = function mapColumn(col) {
                return {
                    fieldName: col.get('fieldName'),
                    filterValue: col.get('filterValue'),
                    showFilter: col.get('showFilter'),
                    advFilterValue: col.get('advFilterValue'),
                    advFilterValue2: col.get('advFilterValue2'),
                    advFilterOperator: col.get('advFilterOperator')
                };
            };

            return {
                page: this.get('page'),
                perPage: this.get('perPage'),
                sortField: this.get('sortField'),
                refreshTime: this.get('refreshTime').seconds,
                quickSearch: this.get('quickSearch'),
                extraParams: this.get('extraParams'),
                filterParams: this.get('filterParams'),
                columns: this.get('columns').map(mapColumn),
                additionalColumnsForFilter: this.get('additionalColumnsForFilter').map(mapColumn)
            };
        },


        rowParams: Ember.computed(function () {
            var _this3 = this;

            return {
                assignToMe: function assignToMe(workflow) {
                    // eslint-disable-next-line ember/no-side-effects
                    _this3.send('assignToMe', workflow);
                }
            };
        }),

        actions: {
            saveFlag: function saveFlag() {
                var _this4 = this;

                var record = this.get('flaggingRecord');
                var note = record.get('flagNote');

                if (Ember.isEmpty(note)) {
                    record.set('flagStatus', 'unflagged');
                } else {
                    record.set('flagStatus', 'flagged');
                }

                record.saveFlag({
                    subject: {
                        flag_status: record.get('flagStatus'),
                        flag_note: record.get('flagNote')
                    }
                }).then(function () {
                    _this4.get('notify').success('Saved successfully');
                }).then(function () {
                    _this4.set('isEditingFlagNote', false);
                    _this4.set('flaggingRecord', null);
                });
            },
            saveAssignMultiple: function saveAssignMultiple(obj) {
                var _this5 = this;

                var ENV = this.get('ENV');
                var selectedRows = obj.selectedRows;
                var activeWorkflows = obj.activeWorkflows;
                var selectedJuryLocation = obj.selectedJuryLocation;

                var location = selectedJuryLocation.get('value');
                var data = { subject_ids: [] };

                selectedRows.forEach(function (r) {
                    data.subject_ids.push(r.get('id'));
                });

                data.workflows = activeWorkflows.filter(function (mhw) {
                    var assigned = mhw.get('assigned.id');
                    var comment = mhw.get('comment');
                    var status = mhw.get('status.value');
                    var hours = mhw.get('hours');
                    var deadline = mhw.get('deadline');

                    if (assigned === 'no_change' && status === 'no_change' && !comment && !hours && !deadline && location === 'no_change') {
                        return false;
                    }

                    return true;
                }).map(function (mhw) {
                    return {
                        workflow_name: Ember.String.underscore(mhw.get('name').replace(/\s/g, '')),
                        assigned_to: mhw.get('assigned.id'),
                        status: mhw.get('status.value'),
                        comment: mhw.get('changeComment') ? mhw.get('comment') : 'no_change',
                        hours: mhw.get('changeHours') ? mhw.get('hours') : 'no_change',
                        deadline: mhw.get('changeDeadline') ? mhw.get('deadline') : 'no_change'
                    };
                });

                if (data.workflows.length === 0) {
                    this.get('notify').warning('You didn\'t make any changes to the form.');
                } else {
                    var promises = [];

                    data.workflows.forEach(function (w, index) {
                        var extendedObject = {
                            subject_ids: data.subject_ids
                        };

                        if (index === 0) {
                            extendedObject.location = location;
                        } else {
                            extendedObject.location = 'no_change';
                        }

                        promises.pushObject(Ember.$.ajax({
                            type: 'POST',
                            url: ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/subject_workflow_v2s/mass_save_workflow',
                            data: Ember.assign(w, extendedObject),
                            headers: {
                                'X-Authorization': 'Token: ' + _this5.get('session.data.authenticated.token')
                            }
                        }));
                    });

                    Ember.RSVP.all(promises).then(function () {
                        _this5.get('notify').success('Cases Fields were updated successfully with new defaults');
                        _this5.set('value', _this5.get('session.data.authenticated.last_name') + ', ' + _this5.get('session.data.authenticated.first_name'));
                    }).catch(function (e) {
                        _this5.handleModelAndXHR(e);
                    }).finally(function () {
                        _this5.send('invalidateModel');
                        _this5.set('isUpdatingCases', false);
                        Ember.$('#update-cases-modal').modal('toggle');
                    });
                }
            },
            updateRefreshTime: function updateRefreshTime(selectedRefresh) {
                this.set('refreshTime', selectedRefresh);
            },
            exportFlagReport: function exportFlagReport() {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'flag_report',
                            format: 'pdf',
                            style: 'misc'
                        },
                        custom: {
                            matter_id: this.get('currentMatter.id')
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            },
            publishSubjects: function publishSubjects(subjectWorkflows) {
                var _this6 = this;

                var subjectIds = subjectWorkflows.mapBy('id');

                this.get('ajax').post('subjects/publish_multiple', {
                    data: {
                        ids: subjectIds
                    }
                }).then(function () {
                    _this6.get('notify').success('Subjects published successfully');
                    _this6.send('invalidateModel');
                }, function () {
                    _this6.get('notify').error('Failed to publish subjects');
                });
            },
            publishLimitedSubjects: function publishLimitedSubjects(subjectWorkflows) {
                var _this7 = this;

                var subjectIds = subjectWorkflows.mapBy('id');

                this.get('ajax').post('subjects/publish_limited_multiple', {
                    data: {
                        ids: subjectIds
                    }
                }).then(function () {
                    _this7.get('notify').success('Subjects limited published successfully');
                    _this7.send('invalidateModel');
                }, function () {
                    _this7.get('notify').error('Failed to publish-limited subjects');
                });
            },


            /** Refactore from workflows/base-cell to improve performance have only 2 modals for assigned/status instead of hundreds in each cell */
            assignToMe: function assignToMe(row, apiName, cell) {
                if (this.get('currentEmployee')) {
                    this.set('currentRow', row);
                    this.set('currentCell', cell);
                    this.set('currentApiName', apiName);
                    this.set('showChangeAssignModal', true);
                } else {
                    this.get('notify').error('You are not listed as a valid employee for this case');
                }
            },
            saveAssign: function saveAssign() {
                var _this8 = this;

                var ENV = this.get('ENV');

                Ember.$.ajax({
                    type: 'POST',
                    url: ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/subject_workflow_v2s/quick_save_workflow',
                    data: {
                        subject_id: this.get('currentRow.id'),
                        workflow_name: this.get('currentApiName'),
                        assigned_to: this.get('session.data.authenticated.id')
                    },
                    headers: {
                        'X-Authorization': 'Token: ' + this.get('session.data.authenticated.token')
                    },
                    success: function success() {
                        var subject = _this8.get('currentRow.content');

                        if (subject) {
                            subject.set(_this8.get('currentCell.column.valuePath').replace('.shortName', ''), _this8.get('currentEmployee'));
                            _this8.store.queryRecord('subject', {
                                with: 'subject_workflows,analytics',
                                subject_id: subject.get('id')
                            });
                        }
                        _this8.get('notify').success('Assigned To updated successfully');
                        _this8.set('showChangeAssignModal', false);
                    },
                    error: function error(e) {
                        _this8.handleModelAndXHR(e.responseJSON);
                    }
                });
            },
            setStatus: function setStatus(row, apiName, status, cell) {
                this.set('currentRow', row);
                this.set('currentCell', cell);
                this.set('currentApiName', apiName);
                this.set('newStatus', status);
                this.set('showChangeStatusModal', true);
            },
            saveStatus: function saveStatus() {
                var _this9 = this;

                var ENV = this.get('ENV');
                var status = this.get('newStatus');

                Ember.$.ajax({
                    type: 'POST',
                    url: ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/subject_workflow_v2s/quick_save_workflow',
                    data: {
                        subject_id: this.get('currentRow.id'),
                        workflow_name: this.get('currentApiName'),
                        status: status
                    },
                    headers: {
                        'X-Authorization': 'Token: ' + this.get('session.data.authenticated.token')
                    },
                    success: function success() {
                        var subject = _this9.get('currentRow.content');

                        if (subject) {
                            subject.set(_this9.get('currentCell.column.valuePath'), status);
                            _this9.store.queryRecord('subject', {
                                with: 'subject_workflows,analytics',
                                subject_id: subject.get('id')
                            });
                        }
                        _this9.get('notify').success('Status updated successfully');
                        _this9.set('showChangeStatusModal', false);
                    },
                    error: function error(e) {
                        _this9.handleModelAndXHR(e.responseJSON);
                    }
                });
            },
            editSubjectWorkflows: function editSubjectWorkflows(subject) {
                this.set('subjectToEdit', subject);
                this.set('showEditSubjectWorkflowsModal', true);
            },
            closeEditModal: function closeEditModal() {
                this.get('subjectWorkflowsForm').send('cancel');
            },
            subjectWorkflowEditSaved: function subjectWorkflowEditSaved() {
                this.send('refreshModel');
                this.set('showEditSubjectWorkflowsModal', false);
            }
        }
    });
});