define('tm3/pods/crm/contacts/info/matters/route', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment'], function (exports, _error, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        lists: Ember.inject.service(),
        counts: Ember.inject.service(),
        queryParams: {
            page: {
                refreshModel: true
            },
            showOnly: {
                refreshModel: true
            }
        },
        /**
         * load mHU records to side load matters as well
         *
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            var clientsInfoModel = this.modelFor('crm.contacts.info');
            var client = clientsInfoModel.model.get('firstObject');
            var clientId = client.get('id');

            var individualService = params.showOnly === 'individual' ? this.store : this.get('counts');
            var teamService = params.showOnly === 'team' ? this.store : this.get('counts');

            return Ember.RSVP.hash({
                user: this.store.findRecord('user', clientId),
                userGroups: this.store.query('group', {
                    group_type: _environment.default.APP.groupTypes.client
                }),
                client: client,
                contactRelationships: this.get('lists').getListForGroup('case_contact_types'),
                matterHasUser: individualService.query('matter-has-user', {
                    user_id: clientId,
                    with: 'matters,groups',
                    page: params.page,
                    per_page: 20,
                    sort: '-matters:tm_num'
                }),
                // This are all matters a user has access to
                matterHasTeams: this.store.query('user-has-team', {
                    user_id: clientId,
                    with: 'teams'
                }).then(function (uht) {
                    var teamIds = uht.map(function (u) {
                        return u.get('team.id');
                    });

                    return teamService.query('matter-has-team', {
                        team_id: teamIds,
                        with: 'groups,teams,matters',
                        page: params.page,
                        per_page: 20,
                        sort: '-matters:tm_num'
                    });
                }),
                showOnly: params.showOnly
            });
        },


        /**
         * set model to the current user
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.user);
            controller.set('client', resolved.client);

            if (resolved.showOnly === 'individual') {
                controller.set('totalPages', resolved.matterHasUser.meta.total_pages);
                controller.set('totalIndividual', resolved.matterHasUser.meta.total_record_count);
                controller.set('totalTeam', resolved.matterHasTeams);
            } else {
                controller.set('totalPages', resolved.matterHasTeams.meta.total_pages);
                controller.set('totalTeam', resolved.matterHasTeams.meta.total_record_count);
                controller.set('totalIndividual', resolved.matterHasUser);
            }

            controller.set('individualAccess', resolved.matterHasUser);
            controller.set('teamAccess', resolved.matterHasTeams);

            controller.set('userGroups', resolved.userGroups);
            controller.set('contactRelationships', resolved.contactRelationships);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            },


            /**
             * save matter_has_user to api
             * @param connection
             */
            save: function save(connection) {
                var _this = this;

                connection.save().then(function (data) {
                    _this.get('notify').success('Case Connection Saved');
                    _this.controller.set('currentConnection', null);
                    _this.controller.set('showForm', false);
                }, function (reason) {
                    _this.validationReport(reason);
                });
            },


            changePage: function changePage(page) {
                this.controller.set('page', page);
                this.refresh();
            }
        }
    });
});