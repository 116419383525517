define('tm3/pods/matter/admin/workflows/route', ['exports', 'tm3/utils/case-workflow'], function (exports, _caseWorkflow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        model: function model(params) {
            var matterId = this.get('currentMatter.id');
            return Ember.RSVP.hash({
                matter: this.store.findRecord('matter', matterId),
                workflows: this.store.query('workflow', { workflow_type: 'subject' }),
                matterHasWorkflows: this.store.query('matter_has_workflow', { matter_id: matterId })
            });
        },


        /**
         * create a custom workflow object that merges the workflow with settings for that particular matter
         *
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, resolved) {
            var workflows = [];
            resolved.workflows.forEach(function (workflow) {
                // reset default values
                // they will be modified if a matching matter workflow is found
                var relatedMatterWorkflow = null;

                // look for a matching matter's record
                resolved.matterHasWorkflows.forEach(function (matterWorkflow) {
                    var relatedWorkflow = matterWorkflow.get('workflow');
                    if (relatedWorkflow.get('id') === workflow.get('id')) {
                        relatedMatterWorkflow = matterWorkflow;
                    }
                });

                var workflowObject = _caseWorkflow.default.create({
                    parent: workflow,
                    child: relatedMatterWorkflow
                });

                workflows.pushObject(workflowObject);
            });
            this._super(controller, workflows);
            controller.set('matter', resolved.matter);
        },


        actions: {
            invalidateModel: function invalidateModel() {
                // display message in the console of your browser
                // Ember.Logger.log('Route is now refreshing...');
                // refresh the model
                this.refresh();
            }
        }
    });
});