define('tm3/validators/start-date', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = validateStartDate;
    function validateStartDate() /* options = {} */{
        return function (key, newValue, oldValue, changes, content) {
            var dueOn = changes.dueOn ? changes.dueOn : content.get('dueOn');

            if (newValue === undefined || newValue === null || newValue === '') {
                return true;
            }

            if (dueOn) {
                return (0, _moment.default)(newValue).isBefore((0, _moment.default)(dueOn)) ? true : 'Start date must be before due date';
            }

            return true;
        };
    }
});