define('tm3/components/page-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        classNames: 'content-header',
        showSubjectSelect: false,

        actions: {
            selectSubject: function selectSubject(subject) {
                this.sendAction('selectFromAutosuggest', subject.get('id'));
            }
        }
    });
});