define('tm3/pods/components/accounting/billing/invoices/flat-entry-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        showCut: false,
        selectedItemForQtyCutEdit: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
        },

        actions: {
            editQtyCut: function editQtyCut() {
                if (this.get('canEdit')) {
                    this.sendAction('editQtyCut', this.get('pieceEntry'));
                }
            },
            cancelQtyCutEdit: function cancelQtyCutEdit() {
                this.sendAction('cancelQtyCutEdit');
            },
            saveQtyCut: function saveQtyCut(qty, cut) {
                this.sendAction('saveQtyCut', this.get('pieceEntry'), qty, cut);
            }
        }
    });
});