define('tm3/initializers/known-deprecations', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;


    var IGNORED_DEPRECATION_IDS = [
        // 'ember-debug.deprecate-options-missing', // ember-cli-uploader don't pass the options to deprecate function
        // 'ds.serializer.model-for-key-missing' // deprecation for "meta" key on responses
    ];

    var IGNORED_WARNING_IDS = [
        // 'ember-htmlbars.style-xss-warning',
        // 'ds.serializer.model-for-key-missing' // deprecation for "meta" key on responses
    ];

    function initialize() {
        if (Ember.Debug && typeof Ember.Debug.registerDeprecationHandler === 'function') {
            Ember.Debug.registerDeprecationHandler(function (message, options, next) {
                if (options && IGNORED_DEPRECATION_IDS.includes(options.id)) {
                    return;
                }
                Ember.Logger.debug('known-deprecation:', options.id);
                next(message, options);
            });
            Ember.Debug.registerWarnHandler(function (message, options, next) {
                if (options && IGNORED_WARNING_IDS.includes(options.id)) {
                    return;
                }
                Ember.Logger.debug('known-warning: ', options.id);
                next(message, options);
            });
        }
    }

    exports.default = {
        name: 'known-deprecations',
        initialize: initialize
    };
});