define('tm3/pods/crm/deals/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),

        model: function model(params) {
            // params.with = 'firms';
            params.with = 'all';

            return Ember.RSVP.hash({
                deals: this.store.query('deal', params),
                notes: this.store.query('crm-note-has-deal', {
                    deal_id: params.id,
                    with: 'crm_notes',
                    sort: '-crm_notes:created_on'
                }),
                clients: this.store.query('deal-has-client', {
                    deal_id: params.id,
                    with: 'all'
                }),
                dealTags: this.store.query('crm-tag-has-deal', {
                    deal_id: params.id,
                    with: 'crm_tags'
                }),
                todos: this.store.query('deal-has-todo', {
                    deal_id: params.id,
                    with: 'all',
                    'todos:archived': 0
                }),
                files: this.store.query('deal-has-file', {
                    deal_id: params.id,
                    with: 'files'
                }),
                tags: this.store.findAll('crm-tag'),
                employees: this.store.query('employee', { status: 'Active', sort: 'last_name' }),
                todoCategories: this.store.findAll('todo-category'),
                noteCategories: this.store.findAll('crm-note-category'),
                newTodo: this.store.createRecord('todo', { priority: 'Normal' })
            });
        },
        setupController: function setupController(controller, resolved) {
            var model = resolved.deals.get('firstObject');
            this._super(controller, model);

            controller.set('clients', resolved.clients);
            controller.set('todos', resolved.todos);
            controller.set('notes', resolved.notes);
            controller.set('dealTags', resolved.dealTags);
            controller.set('tags', resolved.tags);
            controller.set('employees', resolved.employees);
            controller.set('todoCategories', resolved.todoCategories);
            controller.set('noteCategories', resolved.noteCategories);
            controller.set('files', resolved.files);
            controller.set('newTodo', resolved.newTodo);

            // reset each time a user returns to this route
            controller.set('isAdding', false);

            var clientIds = Ember.A();
            resolved.clients.forEach(function (client) {
                clientIds.pushObject(client.get('client.id'));
            });
            this.store.query('firm-has-client', {
                user_id: clientIds,
                with: 'all'
            });
            controller.set('contactTodos', this.store.query('todo', {
                client_id: clientIds,
                archived: 0
            }));
        },

        actions: {
            fetch: function fetch() {
                this.refresh();
            },
            delete: function _delete(deal) {
                var self = this;
                deal.destroyRecord().then(function () {
                    self.get('notify').success('Deal Removed');
                    self.transitionTo('crm.deals.list');
                });
            }
        }
    });
});