define('tm3/pods/me/time/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            matterId: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var currentUserId = this.get('session.data.authenticated.id');
            return Ember.RSVP.hash({
                billableGroup: this.store.queryRecord('billable-group', {
                    id: params.billable_group_id,
                    with: 'billables,budgets,receipts,invoice_groups,charges,matters'
                }),
                billableTypes: this.store.findAll('billable-type'),
                expenseTypes: this.store.query('billable-type', {
                    report: 1,
                    sort: 'id'
                }),
                charges: this.store.query('charge', {
                    billable_group_id: params.billable_group_id,
                    with: 'billable_types',
                    sort: 'date,billable_type_id'
                }),
                expenseReportHasMatters: this.store.query('expense-report-has-matter', {
                    billable_group_id: params.billable_group_id,
                    with: 'matters'
                }),
                invoiceGroups: this.store.query('invoice-group', {
                    matter_id: params.matterId
                }),
                budgets: this.store.query('budget', {
                    matter_id: params.matterId,
                    enabled: 1
                }),
                timeBillableGroups: this.store.query('billable-group', {
                    type: 'Time',
                    created_by_id: currentUserId,
                    with: 'billables,matters'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            if (resolved) {
                resolved.billableGroup.get('billables').forEach(function (b) {
                    b.set('receipt', resolved.billableGroup.get('receipts').filterBy('billable.id', b.get('id')).get('firstObject'));
                });
            }
            this._super(controller, resolved);
        }
    });
});