define('tm3/pods/matter/subjects/info/workflow/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        subjectId: null,
        matterId: null,

        beforeModel: function beforeModel(transition) {
            this.set('subjectId', transition.params['matter.subjects.info'].subject_id);
            this.set('matterId', transition.params.matter.matter_id);
        },
        model: function model() {
            var subjectInfo = this.modelFor('matter.subjects.info');

            return Ember.RSVP.hash({
                subject: {},
                employees: this.store.query('employee', {
                    with_access_to_matter: this.get('matterId')
                }),
                workflows: subjectInfo.workflows,
                workflowStatus: this.get('lists').getListForGroup('workflow_status')
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('model.subject', this.modelFor('matter.subjects.info').subject.get('firstObject'));

            model.workflows.forEach(function (w) {
                switch (w.get('status')) {
                    case 'P':
                        w.set('statusLabel', 'label-warning');

                        break;
                    case 'I':
                        w.set('statusLabel', 'label-danger');

                        break;
                    case 'C':
                        w.set('statusLabel', 'label-success');

                        break;
                    case 'S':
                        w.set('statusLabel', 'label-primary');

                        break;
                    case 'U':
                        w.set('statusLabel', 'label-default');

                        break;
                    default:
                        w.set('statusLabel', 'label-default');

                        break;
                }
                w.set('statusOption', model.workflowStatus.findBy('value', w.get('status')));
            });
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            },
            willTransition: function willTransition() {
                var workflows = this.get('controller.model.workflows');

                workflows.forEach(function (workflow) {
                    workflow.rollbackAttributes();
                });

                return true;
            }
        }
    });
});