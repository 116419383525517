define('tm3/pods/crm/contacts/info/accounts/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        notify: Ember.inject.service(),

        model: function model() {
            var clientsInfoModel = this.modelFor('crm.contacts.info');
            var client = clientsInfoModel.model.get('firstObject');
            var clientId = client.get('id');

            return Ember.RSVP.hash({
                model: this.store.query('firm-has-client', { user_id: clientId, with: 'firms' }),
                relationships: this.get('lists').getListForGroup('contact_account_relations'),
                contact: client
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('contact', resolved.contact);
            controller.set('relationships', resolved.relationships);
            controller.set('firmRelationship', resolved.relationships.get('firstObject'));
        },


        actions: {
            // call this to update a connection
            // include logic to rebuild parent route to reflect possible changes
            updateAccountConnection: function updateAccountConnection(item) {
                item.save();
                this.send('refreshInfoRoute');
            },


            // remove a client 2 account connection
            // for parent route to refresh
            removeAccountConnection: function removeAccountConnection(item) {
                var _this = this;

                item.destroyRecord().then(function () {
                    _this.get('notify').success('Successfully removed account from this contact!');
                    _this.send('refreshInfoRoute');
                    return false;
                });
            },
            reloadModel: function reloadModel() {
                this.refresh();
            }
        }
    });
});