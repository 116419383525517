define('tm3/pods/components/invoice-table/tools/component', ['exports', 'ember-cli-table-pagination/components/bs-table-pagination/table-tools'], function (exports, _tableTools) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _tableTools.default.extend({
        filterByMatterType: Ember.computed.reads('toolsParams.filterByMatterType'),
        // quickSearchField: 'tm_code',
        quickSearchField: Ember.computed.reads('toolsParams.quickSearchField'),

        onInit: Ember.on('init', function () {
            if (this.get('filterByMatterType')) {
                var allColumns = this.get('allColumns');
                var fileColumn = allColumns.findBy('displayName', 'File #');
                if (fileColumn) {
                    fileColumn.set('advFilterOperator', this.get('operators').findBy('value', 'contains'));
                    fileColumn.set('advFilterValue', this.get('filterByMatterType'));
                }
                var statusColumn = allColumns.findBy('displayName', 'Status');
                if (statusColumn) {
                    statusColumn.set('advFilterOperator', this.get('operators').findBy('value', 'not_contains'));
                    statusColumn.set('advFilterValue', 'billed');
                }
                this.send('applyFilter');
            }
        }),

        selectedQuickSearchField: Ember.computed('quickSearchField', function () {
            switch (this.get('quickSearchField')) {
                case 'tm_code':
                    return 'File #';
                case 'id':
                    return 'Invoice #';
                case 'split':
                    return 'Split #';
            }
        })
    });
});