define('tm3/pods/components/accounting/billing/invoice-outputs/ledes-or-quickbooks-output/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        service: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var service = invoiceSubs.findBy('type', 'Service');
            return service || 0;
        }),

        expense: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var expense = invoiceSubs.findBy('type', 'Expense');
            return expense || 0;
        }),

        discount: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var discount = invoiceSubs.findBy('type', 'Discount');
            return discount || 0;
        }),

        tax: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var tax = invoiceSubs.findBy('type', 'Tax');
            return tax || 0;
        }),

        watchAllInvoice: Ember.observer('selectAllInvoices', function () {
            var selectAllInvoices = this.get('selectAllInvoices');
            this.get('invoiceSummary.invoiceOutputs').setEach('includeInExport', selectAllInvoices);
        }),

        invoiceSummaryClients: Ember.computed('invoiceSummary.clients', function () {
            var clients = this.get('invoiceSummary.clients');
            var invoiceSummaryClients = [];
            clients.forEach(function (client) {
                var clientObj = Ember.Object.create({
                    id: client.get('id'),
                    firstName: client.get('firstName'),
                    lastName: client.get('lastName'),
                    remittance: false,
                    summaryAndDetail: false,
                    manualExpenses: false,
                    receipts: false
                });

                invoiceSummaryClients.push(clientObj);
            });

            return invoiceSummaryClients;
        }),

        actions: {
            percentage: function percentage(val) {
                return val * 100;
            }
        }
    });
});