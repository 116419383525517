define('tm3/pods/components/case-management/assign-cell/component', ['exports', 'tm3/pods/components/case-management/assign-cell/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        classNames: ['assign-cell'],

        mouseEnter: function mouseEnter() {
            if (!this.get('value')) {
                this.set('showAssignToMe', true);
            }
        },
        mouseLeave: function mouseLeave() {
            if (!this.get('value')) {
                this.set('showAssignToMe', false);
            }
        }
    });
});