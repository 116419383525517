define('tm3/pods/matter/admin/scrub-data/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        currentMatter: Ember.inject.service(),

        model: function model() {
            var matter = this.get('currentMatter.matter');
            return {
                matter: matter
            };
        },
        resetController: function resetController(controller, isExiting, transition) {
            if (isExiting) {
                controller.set('resultLog', '');
            }
        }
    });
});