define('tm3/pods/crm/contacts/info/controller', ['exports', 'tm-common/mixins/crud/error', 'tm-common/utils/copy-to-clipboard'], function (exports, _error, _copyToClipboard) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        actions: {
            // triggers when a value has been selected in the auto suggest
            navigateToClient: function navigateToClient(client) {
                this.transitionToRoute('crm.contacts.info.activity', client.get('id'));
            },
            showTodoForm: function showTodoForm() {
                this.set('newTodo', this.store.createRecord('todo', {
                    priority: 'Normal',
                    client: this.get('model')
                }));
                Ember.$('#todoForm').removeClass('hidden');
            },


            // connection related logic
            hideTodoForm: function hideTodoForm() {
                Ember.$('#todoForm').addClass('hidden');
            },
            saveBackground: function saveBackground(model) {
                var self = this;
                var text = Ember.$('.backgroundTextareaText').val();
                var contact = this.get('model');
                contact.set('history', text);

                contact.save().then(function (data) {
                    Ember.$('#backgroundModal').modal('toggle');
                    self.get('notify').success('Updated background');
                }, function (reason) {
                    self.validationReport(contact);
                });
            },
            removeContact: function removeContact(contact) {
                var _this = this;

                contact.destroyRecord().then(function () {
                    _this.get('notify').success('The contact has been removed successfully');
                    _this.transitionToRoute('crm.contacts.list');
                }).catch(function (error) {
                    contact.rollbackAttributes();
                    _this.get('notify').error("Contact was not removed");
                });
            },
            copyEmail: function copyEmail(email) {
                var _this2 = this;

                (0, _copyToClipboard.default)(email).then(function () {
                    _this2.get('notify').success('Copied to the clipboard');
                }).catch(function () {
                    _this2.get('notify').error('Error using the clipboard');
                });
            },


            /** power-select for clients */
            searchClient: function searchClient(term) {
                var _this3 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this3, _this3._performSearchClient, term, resolve, reject, 600);
                });
            }
        },
        _performSearchClient: function _performSearchClient(term, resolve, reject) {
            return this.store.query('client', {
                'first_name||last_name': '*' + term + '*',
                limit: 100,
                sortField: 'last_name'
            }).then(function (items) {
                return resolve(items);
            });
        },


        truncatedHistory: Ember.computed('model.history', function () {
            var truncatedHistory = '';
            var history = this.get('model.history');
            if (history == null) {
                truncatedHistory = '';
            } else if (history.length > 100) {
                truncatedHistory = Ember.$.trim(history).substring(0, 100) + '...';
            } else {
                truncatedHistory = history;
            }

            return truncatedHistory;
        })
    });
});