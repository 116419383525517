define('tm3/pods/matter/workflows/list-old/controller', ['exports', 'tm3/utils/case-workflow'], function (exports, _caseWorkflow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        reportBuilder: Ember.inject.service(),

        // store a list of enabled fields and workflows for this case
        matterFieldColumns: [],
        workflowColumns: [],
        tableColumns: [],

        showFilter: false,
        showFilterObserver: Ember.observer('showFilter', function () {
            if (this.get('scrollMode')) {
                this.adjustTableDimensions();
            }
        }),

        /**
         * load the dynamic list of enabled matter fields for this case
         */
        loadMatterFieldColumns: Ember.observer('matterFields', function () {
            Ember.Logger.debug('ran loadMatterFieldColumns');
            // for each, figure out what is enabled
            var matterFields = this.get('matterFields').sortBy('weight');

            var list = matterFields.filter(function (item) {
                return !!(item.get('viewable') & 2);
            });

            this.set('matterFieldColumns', list);
        }),

        /**
         * load the dynamic list of workflows enabled for this case
         * resulting array stores a list of custom Workflow objects
         */
        loadWorkflowColumns: Ember.observer('matterHasWorkflow', function () {
            Ember.Logger.debug('ran loadWorkflowColumns');
            // for each, figure out what is enabled
            var matterHasWorkflow = this.get('matterHasWorkflow').sortBy('weight');

            // let sortedList = matterHasWorkflow.sortBy('weight');

            var list = matterHasWorkflow.filter(function (item) {
                return item.get('enabled') > 0;
            });

            var enabledColumns = [];

            list.forEach(function (item) {
                item.set('filterValue', '');

                var workflowObject = _caseWorkflow.default.create({
                    parent: item.get('workflow'),
                    child: item
                });
                enabledColumns.pushObject(workflowObject);
            });

            Ember.Logger.debug(enabledColumns);
            this.set('workflowColumns', enabledColumns);
        }),

        /**
         * combine and flatten subject records with their workflows entries
         */
        mergedSubjects: Ember.computed('matterFieldColumns', 'workflowColumns', 'subjects', function () {
            var _this = this;

            var matterFieldColumns = this.get('matterFieldColumns');
            var workflowColumns = this.get('workflowColumns');
            var subjects = this.get('subjects');
            var mergedSubjects = [];
            var mergedColumns = [];

            // build table columns
            mergedColumns.push({ display: 'Flagged', fieldName: 'flag_status', type: 'flag' });
            matterFieldColumns.forEach(function (item) {
                mergedColumns.push({
                    display: item.get('display'),
                    fieldName: item.get('value'),
                    type: 'matterField',
                    filterValue: ''
                });
            });
            workflowColumns.forEach(function (task) {
                var mhw = task.get('child');
                var workflow = task.get('parent');
                if (mhw.get('assigned') === true) {
                    mergedColumns.push({
                        display: workflow.get('name'),
                        fieldName: workflow.get('name') + '-assigned',
                        type: 'task'
                    });
                }
                if (mhw.get('status') === true) {
                    mergedColumns.push({
                        display: 'S',
                        fieldName: workflow.get('name') + '-status',
                        type: 'task',
                        filterValue: ''
                    });
                }
                if (mhw.get('comments') === true) {
                    mergedColumns.push({
                        display: 'Comment',
                        fieldName: workflow.get('name') + '-comments',
                        type: 'task',
                        filterValue: ''
                    });
                }
            });

            Ember.run.schedule('afterRender', function () {
                _this.set('tableColumns', mergedColumns);
            });

            subjects.forEach(function (subject) {
                // store next merged subject + workflows
                var mergedRecord = Ember.Object.create({
                    record: subject,
                    id: subject.get('id'),
                    flag_status: !Ember.isEmpty(subject.get('flagNote')) ? 'flagged' : subject.get('flagStatus'),
                    flag_note: subject.get('flagNote')
                });
                matterFieldColumns.forEach(function (item) {
                    var plainName = item.get('value');
                    var camelName = Ember.String.camelize(plainName);
                    var fieldValue = subject.get(camelName);
                    if (Ember.$.type(fieldValue) === 'number') {
                        mergedRecord[plainName] = Number(camelName);
                    } else {
                        mergedRecord[plainName] = fieldValue;
                    }
                });

                // find matching task from subject
                var simpleWorkflows = subject.get('simpleWorkflows');

                workflowColumns.forEach(function (task) {
                    var matchingTask = null;
                    var workflowId = task.get('parent.id');
                    simpleWorkflows.forEach(function (item) {
                        if (item.get('workflow.id') === workflowId) {
                            matchingTask = item;
                        }
                    });
                    // only process if a matching task if found for this subject
                    if (matchingTask) {
                        var name = task.get('parent.name');
                        if (task.get('child.assigned') === true) {
                            mergedRecord[name + '-assigned'] = matchingTask.get('lastName');
                        }

                        if (task.get('child.status') === true) {
                            mergedRecord[name + '-status'] = matchingTask.get('status');
                        }

                        if (task.get('child.comments') === true) {
                            mergedRecord[name + '-comments'] = matchingTask.get('comments');
                        }
                    } else {
                        // NOTE(leo): according to the previous commits the following variable should be declared here
                        var _name = task.name;
                        mergedRecord[_name + '-assigned'] = '';
                        mergedRecord[_name + '-status'] = '';
                        mergedRecord[_name + '-comments'] = '';
                    }
                });
                mergedSubjects.push(mergedRecord);
            });

            return mergedSubjects;
        }),

        filteredMergedSubjects: Ember.computed('mergedSubjects', 'tableColumns.@each.filterValue', function () {
            var mergedSubjects = this.get('mergedSubjects');
            var tableColumns = this.get('tableColumns');

            tableColumns.forEach(function (column) {
                if (column.filterValue) {
                    mergedSubjects = mergedSubjects.filter(function (item, index, enumerable) {
                        var subjectValue = item.get(column.fieldName);
                        if (subjectValue) {
                            var lcSubjectValue = subjectValue.toLowerCase();
                            if (lcSubjectValue.indexOf(column.filterValue.toLowerCase()) !== -1) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
            });

            return mergedSubjects;
        }),

        sortProperties: ['last_name'],
        sortedModel: Ember.computed.sort('filteredMergedSubjects', 'sortProperties'),

        actions: {
            sortField: function sortField(fieldName) {
                var sort = this.get('sortProperties')[0].split(':');
                if (sort[1] === 'asc') {
                    this.set('sortProperties', [fieldName + ':desc']);
                } else {
                    this.set('sortProperties', [fieldName + ':asc']);
                }
            },
            editFlag: function editFlag(subject) {
                this.set('isEditingFlagNote', true);
                this.set('flaggingSubject', subject.record);
            },
            flagSubject: function flagSubject() {
                var _this2 = this;

                var subject = this.get('flaggingSubject');
                var note = subject.get('flagNote');
                if (Ember.isEmpty(note)) {
                    subject.set('flagStatus', 'unflagged');
                } else {
                    subject.set('flagStatus', 'flagged');
                }
                subject.save().then(function () {
                    _this2.get('notify').success('Saved successfully');
                }).then(function () {
                    _this2.set('isEditingFlagNote', false);
                    _this2.set('flaggingSubject', null);
                    _this2.send('invalidateModel');
                });
            },
            cancelFlagSubject: function cancelFlagSubject() {
                this.set('isEditingFlagNote', false);
                this.set('flaggingSubject', null);
            },
            exportExcel: function exportExcel() {
                var _this3 = this;

                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'excel_export',
                            format: 'excel',
                            style: 'misc'
                        },
                        custom: {
                            columns: this.get('tableColumns').map(function (col) {
                                return { display: col.display, value: col.fieldName };
                            }),
                            rows: this.get('sortedModel').map(function (row) {
                                var simpleObj = {};
                                _this3.get('tableColumns').forEach(function (col) {
                                    simpleObj[col.fieldName] = row.get(col.fieldName);
                                });
                                return simpleObj;
                            })
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            }
        }
    });
});