define('tm3/pods/admin/employees/info/route', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Employees'],

        model: function model(params) {
            return Ember.RSVP.hash({
                employee: this.store.findRecord('employee', params.id),
                user: this.store.findRecord('user', params.id),
                userGroups: this.store.query('user-has-group', {
                    user_id: params.id,
                    with: 'all'
                }),
                userTeams: this.store.query('user-has-team', {
                    user_id: params.id,
                    with: 'all'
                }),
                groups: this.store.query('group', {
                    group_type: _environment.default.APP.groupTypes.system,
                    sort: 'name'
                }),
                teams: this.store.query('team', { sort: 'name', mode: 'Employee' })
            });
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'admin' });
        },

        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});