define('tm3/pods/admin/matter-type/info/workflow-default/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Case Settings'],

        model: function model(params) {
            var matterType = this.modelFor('admin.matter-type.info').matterType;

            return Ember.RSVP.hash({
                matterType: matterType,
                workflows: this.store.query('workflow', {
                    type: 'subject'
                }),
                workflowDefaults: this.store.query('matter-workflow-default', {
                    matter_type_id: matterType.get('id')
                })
            });
        },


        /**
         * create a custom workflow object that merges the workflow with settings for that particular matter
         *
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, resolved) {
            var workflowDefaults = resolved.workflowDefaults;

            var model = [];

            resolved.workflows.forEach(function (w) {
                if (w.get('type') === 'subject') {
                    var item = {
                        workflow: w
                    };

                    var wd = workflowDefaults.findBy('workflowId', parseInt(w.get('id')));

                    if (wd) {
                        item.workflowDefault = wd;
                    } else {
                        item.workflowDefault = Ember.Object.create({
                            workflow: w,
                            enabled: 0,
                            viewable: 0,
                            disabled: true
                        });
                    }

                    model.push(item);
                }
            });

            this._super(controller, model);
            controller.set('matter', resolved.matter);
            controller.set('matterType', resolved.matterType);
        },


        actions: {
            refreshModel: function refreshModel() {
                // display message in the console of your browser
                // Ember.Logger.log('Route is now refreshing...');
                // refresh the model
                this.refresh();
            }
        }
    });
});