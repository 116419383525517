define('tm3/pods/components/matter/admin/team-form/component', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        /**
         * if an employee group is selected then only use case groups
         * otherwise use portal groups for a team of type client
         */
        filteredGroups: Ember.computed('groups', 'team.team', function () {
            var mode = this.get('team.team.mode');
            if (this.get('team.team')) {
                if (Ember.isEmpty(mode)) {
                    return [];
                }
            }
            var groupType = mode === 'Employee' ? _environment.default.APP.groupTypes.employee : _environment.default.APP.groupTypes.client;
            return this.get('groups').filterBy('groupType', groupType);
        }),

        observeTeam: Ember.observer('team.team', function () {
            this.set('team.group', null);
        })
    });
});