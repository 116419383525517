define('tm3/pods/components/social-link/component', ['exports', 'moment', 'tm3/pods/components/social-link/template'], function (exports, _moment, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        showLinkIcon: true,
        origin: 'tm',
        editIconName: 'edit',
        displayMetricsBreaks: false,
        metrics: Ember.computed('allMetrics.[]', function () {
            return this.get('allMetrics').filterBy('resource.id', this.get('link.id'));
        }),
        hyperlinkScrapes: Ember.computed('scrapes.[]', function () {
            return this.get('scrapes').filterBy('hyperlinkResource.id', this.get('link.id')).sortBy('createdOn');
        }),
        display: Ember.computed('link.display', 'link.type', function () {
            var display = this.get('link.display');
            var type = this.get('link.type');
            switch (type) {
                case 'Note':
                    return display ? display : 'Note';
                case 'None':
                    return 'None';
                default:
                    return display;
            }
        }),
        notesMetric: Ember.computed('metrics.[]', function () {
            return this.get('metrics').findBy('key', 'SM_NOTES');
        }),
        nonNotesMetrics: Ember.computed('metrics.[]', function () {
            return this.get('metrics').rejectBy('key', 'SM_NOTES');
        }),
        nonNotesMetricsOrder: ['weight:asc'],
        sortedNonNotesMetrics: Ember.computed.sort('nonNotesMetrics', 'nonNotesMetricsOrder'),
        showUpdatedAt: Ember.computed('link.createdOn', 'link.updatedOn', function () {
            var created = this.get('link.createdOn');
            var updated = this.get('link.updatedOn');
            if (created && updated) {
                return (0, _moment.default)(updated).diff((0, _moment.default)(created), 'minutes') > 1;
            }
        }),

        actions: {
            markDocumentAsViewed: function markDocumentAsViewed(item) {
                item.set('hasBeenViewed', 1);
            }
        }
    });
});