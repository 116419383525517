define('tm3/pods/crm/copper-monitoring/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),

        model: function model() {
            return this.get('ajax').request('/auth/monitor-copper-sync');
        },
        setupController: function setupController(controller, model) {
            controller.set('copper_tm2', model.copper_tm2);
            controller.set('tm2_copper', model.tm2_copper);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});