define('tm3/components/increment-for', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        numOfTimes: Ember.computed('times', function () {
            var times = this.get('times');
            return new Array(parseInt(times));
        })
    });
});