define('tm3/models/matter', ['exports', 'tm-common/models/matter', 'ember-api-actions'], function (exports, _matter, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _matter.default.extend({
        scrubData: (0, _emberApiActions.memberAction)({
            type: 'post',
            path: 'scrub_data'
        })
    });
});