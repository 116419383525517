define('tm3/pods/matter-new/route', ['exports', 'tm3/mixins/matter-edit-route'], function (exports, _matterEditRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_matterEditRoute.default, {
        // Property set to create a new record on store on the mixin
        isNewMatter: true,
        requiredPermissions: ['Edit Case']
    });
});