define('tm3/pods/components/documents/table-toolbar/component', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-toolbar'], function (exports, _tableToolbar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableToolbar.default,
        classNames: ['row', 'documents-table-toolbar']
    });
});