define('tm3/pods/components/accounting/billing/manual-expense/manual-form/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isEdit: false,
        notify: Ember.inject.service(),

        initStartDate: Ember.on('init', function () {
            var start = this.get('model.billableGroup.start');
            this.set('startDate', start ? (0, _moment.default)(start, 'YYYY-MM-DD').toDate() : new Date());
        }),

        actions: {
            save: function save() {
                if (this.get('startDate')) {
                    var formattedStartDate = (0, _moment.default)(this.get('startDate')).format('YYYY-MM-DD');
                    this.set('model.billableGroup.start', formattedStartDate);
                    this.sendAction('save');
                } else {
                    this.get('notify').error('Please select a start date');
                }
            },
            addExpense: function addExpense() {
                this.sendAction('addExpense');
            },
            removeExpense: function removeExpense(expense) {
                this.sendAction('removeExpense', expense);
            },
            removeNewExpense: function removeNewExpense(expense) {
                this.sendAction('removeNewExpense', expense);
            },
            expenseTypeChanged: function expenseTypeChanged(selectedType) {
                this.sendAction('expenseTypeChanged', selectedType);
            }
        }
    });
});