define('tm3/pods/components/crm/contacts/info/accounts/account-connection/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        editing: false,
        isSmall: false,

        location: Ember.computed('item.firm.city', 'item.firm.state', function () {
            var city = this.get('item.firm.city');
            var state = this.get('item.firm.state');
            if (city && state) {
                return city + ', ' + state;
            }
            return city || state;
        }),

        itemClass: Ember.computed('item.relationship', function () {
            switch (this.get('item.relationship')) {
                case 'Current Employer':
                    return 'label-success';
                case 'Former Employer':
                    return 'label-danger';
                case 'Insurance Company':
                    return 'label-info';
                case 'Jury Consultant':
                    return 'label-primary';
                case 'Local Counsel':
                    return 'label-default';
                case 'National Counsel':
                    return 'label-default';
                case 'Third Party Administrator':
                    return 'label-warning';
                default:
                    return 'label-default';
            }
        }),

        actions: {
            toggleEditAccountConnection: function toggleEditAccountConnection() {
                this.set('editing', true);
            },
            cancelEditAcccountConnection: function cancelEditAcccountConnection() {
                this.get('item').rollbackAttributes();
                this.set('editing', false);
            },
            updateAccountConnection: function updateAccountConnection(item) {
                this.sendAction('updateAccountConnection', item);
                this.set('editing', false);
            },
            removeAccountConnection: function removeAccountConnection(item) {
                this.sendAction('removeAccountConnection', item);
            }
        }
    });
});