define('tm3/initializers/add-authorized-mixin', ['exports', 'tm-common/mixins/authorized-route-mixin'], function (exports, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() /* application */{
        Ember.Route.reopen(_authorizedRouteMixin.default, {
            /*
            By default all routes now need permissions
            For Available options:
            Select * From permissions
            */
            requiredPermissions: [],
            requireAnyPermission: []
        });
    }

    exports.default = {
        name: 'add-authorized-mixin',
        initialize: initialize
    };
});