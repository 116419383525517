define('tm3/pods/me/dashboard/controller', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        permissions: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        store: Ember.inject.service(),

        month: '',
        currentMonth: '',
        queryParams: ['month', 'bonusReportYear'],

        bonusReportYear: 2023,

        standardBonus: Ember.computed('billableHoursNonJury', 'standardCreditRate', 'bonusThreshold', 'standardBonusAmount', function () {
            return Math.max(0, (this.get('billableHoursNonJury') * this.get('standardCreditRate') - this.get('bonusThreshold')) * this.get('standardBonusAmount'));
        }),
        juryBonus: Ember.computed('billableHoursJury', 'juryBonusAmount', function () {
            return this.get('billableHoursJury') * this.get('juryBonusAmount');
        }),

        billableHoursNonJury: Ember.computed.alias('model.billableHours.non_jury'),
        billableHoursJury: Ember.computed.alias('model.billableHours.jury'),

        standardCreditRate: Ember.computed.alias('model.employee.standardBonusCreditRate'),
        bonusThreshold: Ember.computed.alias('model.employee.bonusThreshold'),
        standardBonusAmount: Ember.computed.alias('model.employee.standardBonusAmount'),
        juryBonusAmount: Ember.computed.alias('model.employee.juryBonusAmount'),

        hasDependenciesForStandard: Ember.computed('standardCreditRate', 'bonusThreshold', 'standardBonusAmount', function () {
            return this.get('standardCreditRate') > 0 && this.get('bonusThreshold') > 0 && this.get('standardBonusAmount') > 0;
        }),
        hasDependenciesForJury: Ember.computed.gt('juryBonusAmount', 0),

        months: Ember.computed(function () {
            var firstOfTheMonth = (0, _moment.default)().date(1);
            var months = [];

            months.push({
                id: firstOfTheMonth.format('YYYY-MM-01'),
                display: firstOfTheMonth.format('MMMM YYYY')
            });

            for (var i = 0, len = 5; i < len; i += 1) {
                var month = firstOfTheMonth.subtract(1, 'days').date(1);

                months.push({
                    id: month.format('YYYY-MM-01'),
                    display: month.format('MMMM YYYY')
                });
            }

            return months;
        }),

        actions: {
            addTime: function addTime() {
                var matters = this.get('store').query('matter', {
                    where_current_user_has_time_entries: true,
                    limit: 5
                });

                this.set('billableGroupId', 'new');
                this.set('billableGroupType', 'Time');
                this.set('caseOptions', matters);
                this.set('addingTimeToMatter', true);
            },
            closeAddTimeModal: function closeAddTimeModal() {
                this.set('addingTimeToMatter', null);
            },
            changeMonth: function changeMonth(newMonth) {
                this.set('currentMonth', newMonth);
                this.set('month', newMonth.id);
            }
        }
    });
});