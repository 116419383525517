define('tm3/validators/invoice-group', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: [(0, _validators.validatePresence)({ presence: true, message: 'Name is required' })],
        invoiceGroupMembers: (0, _validators.validateLength)({ min: 1 })
    };
});