define('tm3/utils/operators-list', ['exports', 'ember-cli-table-pagination/utils/operators-list'], function (exports, _operatorsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _operatorsList.default;
    }
  });
});