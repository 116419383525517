define('tm3/pods/preview/report-browser/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        model: function model(params, transition) {
            var _this = this;

            this.set('documentId', transition.params.preview.document_id);
            var documentPromise = this.store.queryRecord('document', {
                resource_id: this.get('documentId'),
                with: 'reports'
            });
            return documentPromise.then(function (document) {
                return Ember.RSVP.hash({
                    docTypes: _this.get('lists').getListForGroup('jury_doc_type_tm'),
                    documentId: _this.get('documentId'),
                    document: document,
                    // Related documents by the same report name
                    documents: _this.store.query('document', {
                        matter_id: document.get('matter.id'),
                        'reports:name': document.get('report.name'),
                        with: 'reports'
                    })
                });
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('currentReportName', model.document.get('report.name'));
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});