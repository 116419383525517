define('tm3/pods/components/subjects/publish-subjects-button/component', ['exports', 'tm3/pods/components/subjects/publish-subjects-button/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        notify: Ember.inject.service(),

        selectedRows: null,
        styleForJSI: false,

        actions: {
            togglePublishSubjectsModal: function togglePublishSubjectsModal() {
                var selectedRows = this.get('selectedRows');

                if (selectedRows.length > 0) {
                    this.set('showModal', true);
                } else {
                    this.get('notify').error('You must selected at least one subject.');
                }
            }
        }
    });
});