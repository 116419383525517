define('tm3/pods/preview/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var _this = this;

            var documentPromise = this.store.queryRecord('document', {
                resource_id: params.document_id,
                show_receipts: true,
                with: 'reports'
            });
            var promise = documentPromise.then(function (doc) {
                var matter = doc.get('matter');
                return Ember.RSVP.hash({
                    document: doc,
                    subjectsInCase: _this.store.query('subject', {
                        matter_id: matter.get('id'),
                        sort: 'last_name,first_name'
                    }),
                    matter: matter
                });
            });
            return promise;
        }
    });
});