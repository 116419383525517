define('tm3/pods/matter/document-new/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        actions: {
            beforeUpload: function beforeUpload(filesToUpload) {
                this.get('notify').warning('Starting to upload the files');
            },
            afterUpload: function afterUpload(response) {
                this.get('notify').success('Documents uploaded');
                this.transitionToRoute('matter.documents');
            },
            errorUpload: function errorUpload(_error) {
                this.get('notify').error('There was a problem uploading the files');
            },
            cancelUpload: function cancelUpload() {
                this.transitionToRoute('matter.documents');
            }
        }
    });
});