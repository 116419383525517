define('tm3/pods/components/my-tasks/cell/priority/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        'data-test-component': 'my-tasks-priority-table-cell',
        tagName: 'div'
    });
});