define('tm3/helpers/add-target-to-link', ['exports', 'tm-common/helpers/add-target-to-link'], function (exports, _addTargetToLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _addTargetToLink.default;
    }
  });
  Object.defineProperty(exports, 'addTargetToLink', {
    enumerable: true,
    get: function () {
      return _addTargetToLink.addTargetToLink;
    }
  });
});