define('tm3/pods/report/info/case-profitability-report/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'case_profitability_report',
        lists: Ember.inject.service(),

        queryParams: {
            matter_id: {
                refreshModel: false
            },
            year: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 50,
                    page: params.page ? params.page : 1,
                    matter_id: params.matter_id ? params.matter_id : false,
                    year: params.year ? params.year : false
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                selectedMatter: params.matter_id ? this.store.findRecord('matter', params.matter_id) : null,
                lists: this.get('lists').fetchMultipleLists(['billing_years']),
                matterId: params.matter_id,
                year: params.year
            });
        },
        setupController: function setupController(controller, resolved) {
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('selectedMatter', resolved.selectedMatter);
            resolved.years = resolved.lists['billing_years'].sort(function (a, b) {
                return Ember.compare(parseInt(b.get('value'), 10), parseInt(a.get('value'), 10));
            });
            if (resolved.year) {
                controller.set('selectedYear', resolved.years.findBy('value', resolved.year));
            }
            this._super(controller, resolved);
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});