define('tm3/pods/components/matter/admin/invoice-groups/detail/invoice-group-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        'data-test-component': 'admin-invoice-group-detail-row',

        panelStyle: Ember.computed('item.active', function () {
            var item = this.get('item');
            var bgColor = item.active ? '#f1f1f1' : '#ffffff';

            return 'background-color: ' + bgColor;
        })
    });
});