define('tm3/pods/crm/accounts/info/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        actions: {
            // triggers when a value has been selected in the auto suggest
            navigateToAccount: function navigateToAccount(item) {
                this.transitionToRoute('crm.accounts.info.activity', item.get('id'));
            },
            removeAccount: function removeAccount(account) {
                var _this = this;

                account.destroyRecord().then(function () {
                    _this.get('notify').success('The account has been removed successfully');
                    _this.transitionToRoute('crm.accounts.list');
                }).catch(function (error) {
                    account.rollbackAttributes();
                    _this.get('notify').error(error.errors[0].detail.title);
                });
            },


            /** power-select for clients */
            searchAccount: function searchAccount(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchAccount, term, resolve, reject, 600);
                });
            }
        },
        _performSearchAccount: function _performSearchAccount(term, resolve, reject) {
            return this.store.query('firm', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        },


        truncatedHistory: Ember.computed('model.history', function () {
            var truncatedHistory = '';
            var history = this.get('model.history');
            if (typeof history === 'undefined' || history === null) {
                truncatedHistory = history;
            } else if (history.length > 100) {
                truncatedHistory = Ember.$.trim(history).substring(0, 100) + '...';
            } else {
                truncatedHistory = history;
            }

            return truncatedHistory;
        })
    });
});