define('tm3/components/simple-mde', ['exports', 'ember-simplemde/components/simple-mde'], function (exports, _simpleMde) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _simpleMde.default;
    }
  });
});