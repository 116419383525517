define('tm3/mixins/case-group-column', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        getViewCellComponent: function getViewCellComponent(renderType) {
            switch (renderType) {
                case 'due-date':
                    return 'custom-table-pagination/due-date-cell';
                case 'date':
                    return 'custom-table-pagination/date-cell';
                case 'money':
                    return 'custom-table-pagination/money-cell';
                case 'number':
                    return 'custom-table-pagination/number-cell';
                case 'tags':
                    return 'custom-table-pagination/tags-cell';
                case 'workflow':
                    return 'case-management/workflow-cell';
                case 'case-group':
                    return 'custom-table-pagination/case-group-cell';
                case 'progress-status':
                    return 'custom-table-pagination/progress-status';
                default:
                    return null;
            }
        },
        getAcceptedOperators: function getAcceptedOperators(renderType) {
            switch (renderType) {
                case 'due-date':
                    return ['between', 'equal', 'blank', 'not_blank'];
                case 'date':
                case 'money':
                case 'number':
                    return ['between', 'equal'];
                case 'case-group':
                    return ['in'];
                default:
                    return ['contains', 'equal'];
            }
        },
        getFilterCellComponent: function getFilterCellComponent(renderType) {
            switch (renderType) {
                case 'due-date':
                case 'date':
                    return 'table-filter-components/date';
                case 'case-group':
                    return 'table-filter-components/case-group';
                default:
                    return null;
            }
        },
        getEditCellComponent: function getEditCellComponent(editRenderType) {
            // all of this managed by form-field component
            // text
            // date
            // longtext
            // hyperlink
            // enum
            // readonly

            // workflow
            switch (editRenderType) {
                case 'workflow':
                    return 'matter-list/editable-cell/workflow';
                default:
                    return null;
            }
        }
    });
});