define('tm3/pods/matter/subjects/info/db-memos/find-db/controller', ['exports', 'tm3/mixins/search-db-controller'], function (exports, _searchDbController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_searchDbController.default, {
        queryParams: ['tags', 'page', 'q', 'subject_id'],
        subject_id: null
    });
});