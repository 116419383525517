define('tm3/pods/admin/employees/info/billing/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        actions: {
            cancel: function cancel() {
                this.get('model.employee').rollbackAttributes();
            },
            save: function save() {
                var self = this;
                var employee = this.get('model.employee');
                employee.save().then(function () {
                    self.get('notify').success('Employee saved successfully');
                }, function () {
                    self.validationReport(employee);
                });
            }
        }
    });
});