define('tm3/pods/matter/renumber-tabs/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        session: Ember.inject.service(),
        lists: Ember.inject.service(),

        queryParams: {
            groupBy: {
                refreshModel: true
            },
            selectedGroup: {
                refreshModel: true
            },
            sortField: {
                refreshModel: true
            },
            docTypesOrder: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var groupBy = params.groupBy;
            var selectedGroup = params.selectedGroup;
            var sortField = params.sortField;
            var docTypesOrder = params.docTypesOrder;
            delete params.groupBy;
            delete params.selectedGroup;
            delete params.sortField;
            delete params.docTypesOrder;

            if (groupBy !== 'none') {
                if (selectedGroup === null) {
                    params['id'] = -1; // Always empty list when in group mode
                } else {
                    switch (groupBy) {
                        case 'reportName':
                            if (selectedGroup !== -1) {
                                params['reports:name'] = selectedGroup;
                            } else {
                                // spike the request since you haven't selected a group yet
                                params['reports:name'] = 'XYZ';
                                params['per_page'] = 1;
                            }
                            break;
                        case 'docType':
                            if (selectedGroup !== -1) {
                                params['doc_type'] = selectedGroup;
                            } else {
                                params['doc_type'] = 'XYZ';
                                params['per_page'] = 1;
                            }
                            break;
                        case 'subject':
                            params['subject_id'] = selectedGroup;
                            break;
                        case 'tags':
                            params['tag_name'] = selectedGroup;
                            break;
                        case 'viewable':
                            switch (selectedGroup) {
                                case 'Private':
                                    params['viewable'] = 0;
                                    break;
                                case 'Public':
                                    params['viewable'] = 1;
                                    break;
                                case 'Pending':
                                    params['viewable'] = 2;
                                    break;
                                default:
                                    params['viewable'] = null;
                            }
                            break;
                    }
                }
            }
            var docParams = Ember.assign({
                matter_id: this.get('currentMatter.id'),
                perPage: 1000,
                sortField: sortField,
                with: 'v_resource_has_resources,subjects,resource_has_tags,reports',
                doc_types_order: docTypesOrder
            }, params);
            return Ember.RSVP.hash({
                sortField: sortField,
                documentTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                documents: this.store.query('document', docParams).then(function (documents) {
                    return documents.map(function (d, index) {
                        d.set('weight', index);
                        return d;
                    });
                })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var currentDocTypes = model.documents.map(function (d) {
                return d.get('docType');
            }).uniq();
            controller.set('documentTypes', model.documentTypes.filter(function (d) {
                return currentDocTypes.contains(d.get('value'));
            }).map(function (d) {
                if (Ember.isEmpty(d.get('weightVirtual'))) {
                    d.set('weightVirtual', d.get('weight'));
                }
                return d;
            }));
            if (model.sortField) {
                controller.set('sortFieldInitial', controller.get('sortOptions').findBy('field', model.sortField));
            }
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});