define('tm3/pods/me/receivables/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentUser: Ember.inject.service(),

        queryParams: {
            tabSelected: {
                refreshModel: true
            },
            currentFirm: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                firms: this.store.createRecord('receivable').getFirms().then(function (response) {
                    return response.firms;
                }),
                hasReport: this.store.query('data-report-has-user', {
                    with: 'data_reports',
                    'data_reports:name': 'unpaid_invoice_outputs',
                    user_id: this.get('currentUser.authUserId')
                }),
                firmModel: Ember.isEmpty(params.currentFirm) ? null : this.store.findRecord('firm', params.currentFirm),
                receivableNotes: Ember.isEmpty(params.currentFirm) ? [] : this.store.query('receivable-note', {
                    firm_id: params.currentFirm,
                    with: 'users,firms,receivable_note_has_invoice_outputs,invoice_outputs',
                    get_last_paid_on: 'true',
                    sortField: '-created_on'
                }),
                invoices: Ember.isEmpty(params.currentFirm) ? [] : this.store.createRecord('receivable').getInvoices({
                    firm_id: params.currentFirm
                }).then(function (response) {
                    return response.invoices;
                }),
                params: params
            });
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);

            if (!Ember.isEmpty(model.params.currentFirm)) {
                controller.set('selectedFirm', model.firms.findBy('id', model.params.currentFirm));
            }
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});