define('tm3/pods/matter/admin/billing-summary/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column', 'moment'], function (exports, _controller, _column, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        appName: 'tm-admin',

        columns: [_column.default.create({
            displayName: 'Status',
            fieldName: 'status',
            order: 0,
            cellComponent: 'accounting/billing/invoices/status-cell'
        }), _column.default.create({
            displayName: 'Total',
            fieldName: 'billedAmount',
            cellComponent: 'custom-table-pagination/money-cell',
            order: 1
        }), _column.default.create({
            displayName: 'Services',
            fieldName: 'serviceAmount',
            disableServerInteractions: true,
            cellComponent: 'custom-table-pagination/money-cell',
            order: 2
        }), _column.default.create({
            displayName: 'Expenses',
            fieldName: 'expenseAmount',
            disableServerInteractions: true,
            cellComponent: 'custom-table-pagination/money-cell',
            order: 3
        }), _column.default.create({
            displayName: 'Discount',
            fieldName: 'discountAmount',
            cellComponent: 'custom-table-pagination/money-cell',
            order: 4
        }), _column.default.create({
            displayName: 'Taxes',
            fieldName: 'taxAmount',
            disableServerInteractions: true,
            cellComponent: 'custom-table-pagination/money-cell',
            order: 5
        }), _column.default.create({
            displayName: 'Outstanding Amount',
            fieldName: 'outstandingAmount',
            cellComponent: 'custom-table-pagination/money-cell',
            order: 6
        }), _column.default.create({ displayName: 'Bill Date', fieldName: 'billDate', order: 7 }), _column.default.create({
            displayName: 'Invoice Group',
            fieldName: 'invoiceGroup.name',
            apiName: 'invoice_groups:name',
            order: 8
        }), _column.default.create({
            displayName: 'Budgets',
            fieldName: 'truncatedBudgets',
            order: 9,
            disableServerInteractions: true
        })],
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),

        tableTitle: 'Invoices',
        linkPath: 'accounting.billing.invoices.info',

        titleParams: Ember.computed('columns', 'currentParams', function () {
            return {
                icon: 'building',
                columnFields: ['status', 'billed_amount', 'bill_date'],
                columnNames: this.get('columnNames'),
                currentParams: this.get('currentParams')
            };
        }),

        processTableData: function processTableData(tableData) {
            tableData.forEach(function (invoice) {
                invoice.set('billDate', (0, _moment.default)(invoice.get('billDate')).format('MM-DD-YYYY'));
            });
            return tableData;
        }
    });
});