define('tm3/helpers/should-show-end-date', ['exports', 'tm-common/helpers/should-show-end-date'], function (exports, _shouldShowEndDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _shouldShowEndDate.default;
    }
  });
  Object.defineProperty(exports, 'shouldShowEndDate', {
    enumerable: true,
    get: function () {
      return _shouldShowEndDate.shouldShowEndDate;
    }
  });
});