define('tm3/pods/admin/employees/list/controller', ['exports', 'ember-lifeline/mixins/run'], function (exports, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_run.default, {
        reportBuilder: Ember.inject.service(),
        queryParams: ['q', 'statusFilter', 'onSchedulerFilter'],
        q: null,
        statusFilter: 'active',
        onSchedulerFilter: false,

        /**
         * only change q after an specified time
         */
        query: null,
        queryTimer: null,
        throttledQuery: Ember.observer('query', function () {
            this.debounceTask('updateQuery', 600);
        }),

        updateQuery: function updateQuery() {
            this.set('q', this.get('query'));
        },


        actions: {
            search: function search() {
                this.set('q', this.get('query'));
            },
            exportList: function exportList(format) {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'employee_billing_data',
                            format: format,
                            style: 'misc'
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            }
        }
    });
});