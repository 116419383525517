define('tm3/pods/crm/accounts/info/matters/add-matter/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        /**
         *
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return this.modelFor('crm.accounts.info').firm;
        },


        /**
         *
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, resolved) {
            this._super(controller, {});
            controller.set('firm', resolved);
            controller.set('relatedMatter', null);
            controller.set('councilType', null);
            controller.set('firmMatterNumber', null);
            controller.set('relationship', null);
            controller.set('defendant', false);
            controller.set('primary', false);
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'crm.accounts.info' });
        }
    });
});