define('tm3/pods/matter/admin/reports-by-type/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'billable_type_time_reports',

        queryParams: {
            bill_type: {
                refreshModel: true
            },
            invoice_group: {
                refreshModel: true
            },
            budget: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var model = this.modelFor('matter');
            return Ember.RSVP.hash({
                matter: model.matter,
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id,
                    bill_type: params.bill_type.split(','),
                    billable_type: params.billable_type_id,
                    invoice_group: params.invoice_group ? params.invoice_group : null,
                    budget: params.budget ? params.budget : null
                }),
                billableType: this.store.findRecord('billable-type', params.billable_type_id),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1
            });
        },
        renderTemplate: function renderTemplate() {
            this.render();
            window.scrollTo(0, 0);
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
        }
    });
});