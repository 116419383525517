define('tm3/pods/matter/side-bar/component', ['exports', 'tm3/mixins/sidebar-tree'], function (exports, _sidebarTree) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_sidebarTree.default, {
        tagName: 'aside',
        classNames: ['main-sidebar'],

        session: Ember.inject.service(),

        isRoot: Ember.computed('session', function () {
            return this.get('session.data.authenticated.groups').findBy('name', 'ROOT');
        })
    });
});