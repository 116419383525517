define('tm3/pods/crm/accounts/info/activity/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sortedProperties: ['createdOn:desc'],
        sortedActivity: Ember.computed.sort('model', 'sortedProperties')
    });
});