define('tm3/pods/crm/deals/edit/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),
        model: function model(params) {
            params.with = 'all';
            return Ember.RSVP.hash({
                deals: this.store.query('deal', params),
                employees: this.store.query('employee', { status: 'Active', sort: 'last_name' }),
                stages: this.get('lists').getListForGroup('crm_stage')
            });
        },
        setupController: function setupController(controller, resolved) {
            var model = resolved.deals.get('firstObject');
            this._super(controller, model);
            controller.set('employees', resolved.employees);
            controller.set('stages', resolved.stages.sortBy('weight'));
        },
        renderTemplate: function renderTemplate() {
            this.render();
            window.scrollTo(0, 0);
        },


        actions: {
            save: function save(model) {
                var self = this;
                model.save().then(function (data) {
                    self.get('notify').success('Opportunity Updated');
                    self.transitionTo('crm.deals.list');
                }, function (reason) {
                    self.validationReport(model);
                });
            }
        }
    });
});