define('tm3/pods/accounting/billing/batches/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var search = { invoice_batch_id: params.invoice_batch_id, with: 'matters,clients,invoice_groups' };

            return Ember.RSVP.hash({
                model: this.store.findRecord('invoice-batch', params.invoice_batch_id),
                invoices: this.store.query('invoice', search)
            });
        },
        setupController: function setupController(controller, resolved) {
            var invoiceBatch = resolved.model;
            var invoices = invoiceBatch.get('invoices');
            this._super(controller, invoiceBatch);

            controller.set('batchName', invoiceBatch.get('name'));
            // set a boolean for prelim status
            // used to modify link on template

            var status = invoiceBatch.get('status');
            if (status === 'Preliminary' || status === 'Level 1 Complete' || status === 'Level 2 Complete') {
                invoiceBatch.set('isPrelim', true);
            } else {
                invoiceBatch.set('isPrelim', false);
            }
            // decorate with a prelim boolean
            invoices.forEach(function (item) {
                var status = item.get('status');
                if (status === 'Preliminary' || status === 'Level 1 Complete' || status === 'Level 2 Complete') {
                    item.set('isPrelim', true);
                } else {
                    item.set('isPrelim', false);
                }
            });
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});