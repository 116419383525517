define('tm3/pods/components/invoice-table/title/component', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reads = Ember.computed.reads;
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        classNames: ['box-header', 'with-border'],
        showSkipped: false,
        showNewItem: Ember.computed('titleParams', function () {
            if ('showNewItem' in this.get('titleParams')) {
                return this.get('titleParams.showNewItem');
            }
            return true;
        }),
        icon: reads('titleParams.icon'),
        newItemClass: reads('titleParams.newItemClass'),
        newItemLabel: reads('titleParams.newItemLabel'),
        newItemLink: reads('titleParams.newItemLink'),
        newItemTitle: reads('titleParams.newItemTitle'),
        newItemAction: reads('titleParams.newItemAction'),

        changeStatus: reads('titleParams.changeStatus'),
        selectedRows: reads('titleParams.selectedRows'),

        allToLevel1: Ember.computed('selectedRows.[]', function () {
            // All selected invoices have the available transition to Level 1
            var selected = this.get('selectedRows.length');
            return selected > 0 && this.get('selectedRows').filter(function (invoice) {
                return invoice.get('availableTransitions').includes('to Level 1 Complete');
            }).get('length') === this.get('selectedRows.length');
        }),
        canUpdateToLevel1Class: Ember.computed('allToLevel1', function () {
            return this.get('allToLevel1') ? '' : 'disabled';
        }),
        allToLevel2: Ember.computed('selectedRows.[]', function () {
            // All selected invoices have the available transition to Level 2
            var selected = this.get('selectedRows.length');
            return selected > 0 && this.get('selectedRows').filter(function (invoice) {
                return invoice.get('availableTransitions').includes('to Level 2 Complete');
            }).get('length') === this.get('selectedRows.length');
        }),
        canUpdateToLevel2Class: Ember.computed('allToLevel2', function () {
            return this.get('allToLevel2') ? '' : 'disabled';
        }),
        allToBilled: Ember.computed('selectedRows.[]', function () {
            // All selected invoices have the available transition to Billed
            var selected = this.get('selectedRows.length');
            return selected > 0 && this.get('selectedRows').filter(function (invoice) {
                return invoice.get('availableTransitions').includes('to Billed');
            }).get('length') === this.get('selectedRows.length');
        }),
        canUpdateToBilledClass: Ember.computed('allToBilled', function () {
            return this.get('allToBilled') ? '' : 'disabled';
        }),

        updatePayment: function updatePayment(row, index, type) {
            var _this = this;

            Ember.get(this, 'store').findRecord('invoice-output', row.splitNum).then(function (io) {
                io.updatePayment({
                    amount_paid: row.paid,
                    payment_date: row.paymentDate,
                    type: type
                }).then(function (result) {
                    Ember.get(_this, 'notify').success('Invoice Output Payment successfully saved');
                    Ember.$('#note-' + row.splitNum + '-' + index).text('Done');
                    Ember.$('#total-paid-' + row.splitNum).text((0, _formatMoney.default)(result.invoice_paid_amount));
                }).catch(function () {
                    Ember.get(_this, 'notify').error('Invoice Output Payment was not saved');
                });
            });
        },


        actions: {
            beforeUpload: function beforeUpload(files) {
                Ember.set(this, 'isUploading', true);
                Ember.set(this, 'uploadResult', null);
                Ember.get(this, 'notify').warning('Please wait while the upload/processing takes place');
            },
            afterUpload: function afterUpload(response) {
                Ember.set(this, 'uploadResult', response);
                Ember.set(this, 'isUploading', false);
                Ember.$('input[type=file]').val(null);
            },
            cancelUpload: function cancelUpload() {
                Ember.set(this, 'showUploadModal', false);
                Ember.set(this, 'isUploading', false);
            },
            errorUpload: function errorUpload(_error) {
                Ember.get(this, 'notify').error('There was a problem during upload of the file');
                Ember.set(this, 'isUploading', false);
            },
            overridePayment: function overridePayment(row, index) {
                this.updatePayment(row, index, 'override');
            },
            addToPayment: function addToPayment(row, index) {
                this.updatePayment(row, index, 'add-to');
            }
        }
    });
});