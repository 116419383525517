define('tm3/pods/crm/contacts/edit/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Contacts'],

        model: function model(params) {
            return this.store.findRecord('client', params.client_id);
        }
    });
});