define('tm3/pods/components/icon-resource-viewable/component', ['exports', 'tm3/pods/components/icon-resource-viewable/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        tagName: '',

        /**
         * @public
         * @property
         */
        resource: null,

        /**
         * @public
         * @property
         */
        readonly: false,

        /**
         * @public
         * @property
         */
        size: 1,

        classColor: Ember.computed('resource.viewable', 'resource.doNotUse', function () {
            if (this.get('resource.doNotUse')) {
                return 'icon-red';
            }
            switch (this.get('resource.viewable')) {
                case 0:
                    return 'icon-grey';
                case 1:
                    return 'icon-green';
                case 2:
                    return 'icon-purple';
            }
        }),

        actions: {
            changeViewable: function changeViewable() {
                if (this.get('readonly')) {
                    return;
                }
                if (this.get('resource.doNotUse')) {
                    return;
                }
                var newVal = 0;
                switch (this.get('resource.viewable')) {
                    case 0:
                        newVal = 1;
                        break;
                    case 1:
                        newVal = 2;
                        break;
                    case 2:
                        newVal = 0;
                        break;
                }
                if (typeof this.attrs['onChange'] === 'function') {
                    this.attrs['onChange'](newVal);
                }
            }
        }
    });
});