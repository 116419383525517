define('tm3/pods/components/me/time/tfoot-totals/component', ['exports', 'ember-light-table/utils/css-styleify'], function (exports, _cssStyleify) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',

        table: null,

        data: Ember.computed('table.rows.[]', function () {
            return this.get('table.rows');
        }),

        actions: {
            style: function style(column) {
                return (0, _cssStyleify.default)(column.getProperties(['width']));
            },
            total: function total(fieldName, acc, item) {
                return acc + (item.get(fieldName) ? parseFloat(item.get(fieldName)) : 0);
            }
        }
    });
});