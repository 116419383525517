define('tm3/pods/admin/field-templates/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        requiredPermissions: ['Manage Case Settings'],

        queryParams: {
            field_template_id: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                fieldTemplates: this.store.findAll('field-template'),
                matterFieldDefaults: params.field_template_id ? this.store.query('matter-field-default', {
                    field_template_id: params.field_template_id
                }) : [],
                publicLists: this.store.query('appl-lists-category', {
                    private: false
                }),
                lists: this.get('lists').fetchMultipleLists(['matter_field_input_types', 'icon_file_names']),
                params: params
            });
        },
        setupController: function setupController(controller, resolved) {
            // plug list values into the model
            resolved.iconList = resolved.lists['icon_file_names'];
            resolved.matterFieldInputTypes = resolved.lists['matter_field_input_types'];

            resolved.matterFieldDefaults.forEach(function (mfd) {
                mfd.set('selectedFieldType', resolved.matterFieldInputTypes.findBy('value', mfd.get('fieldType')));
            });
            this._super(controller, resolved);
            controller.set('publicLists', resolved.publicLists.map(function (l) {
                return Ember.Object.create({
                    value: l.get('categoryName'),
                    display: l.get('displayName')
                });
            }));
            if (resolved.params.field_template_id) {
                controller.set('selectedFieldTemplate', resolved.fieldTemplates.findBy('id', resolved.params.field_template_id));
            }
        },


        actions: {
            fetchModel: function fetchModel() {
                this.refresh();
            }
        }
    });
});