define('tm3/pods/components/admin/employees/info/reports/data-report/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        hasAccess: Ember.computed('dataReport', 'reportPerms', function () {
            var dataReport = this.get('dataReport');
            var reportPerms = this.get('reportPerms');
            var hasPerm = reportPerms.findBy('dataReport.id', dataReport.get('id'));
            if (hasPerm) {
                return true;
            }
            return false;
        }),

        dataReportHasUser: Ember.computed('reportPerms', 'dataReport', function () {
            var reportPerms = this.get('reportPerms');
            var dataReport = this.get('dataReport');
            return reportPerms.findBy('dataReport.id', dataReport.get('id'));
        })
    });
});