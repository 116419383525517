define('tm3/pods/components/accounts-list/table-tools/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        quickSearchField: Ember.computed.reads('toolsParams.quickSearchField'),
        sortOptions: Ember.computed.reads('toolsParams.options'),
        sortFieldInitial: Ember.computed.reads('toolsParams.initial'),
        actions: {
            changeSort: function changeSort(newSort) {
                this.attrs.changeSort(newSort.field, newSort.direction);
                this.set('sortFieldInitial', newSort);
            }
        }
    });
});