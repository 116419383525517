define('tm3/utils/matter-column-mapping', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    /*
        Object with all matter columns used to dynamically display appropriate form input for each field
          by sending the appropriate params to form-field component
    
        Default values:
          type: text
          options: []
          selectAction: ''
    
        types:
          enum -> display power-select
          enum-multiple -> display power-select-multiple
          text -> text input
          date -> datepicker
          checkbox -> input type checkbox
          checkboxes -> multiselect-checkboxes
          radio -> radio buttons
    
        options:
          string defining name of list of options to populate select, generate checkboxes, radio buttons.
          list must be stored on the controller
    
       selectAction:
          action (defined in controller) that should trigger when user selects an option
          used for power-select & power-select-multiple
    
        searchEnabled:
          used for power-select & power-select-multiple
       */

    var MatterColumnMapping = {
        allegation: {},
        analytic_status: {
            type: 'enum',
            selectAction: 'selectAnalyticStatus',
            options: 'matter_analytic_status',
            optionDisplayField: 'display'
        },
        billing_rate: {},
        branch: {
            type: 'enum',
            options: 'branches',
            selectAction: 'selectBranch',
            optionDisplayField: 'name'
        },
        budget: {},
        case_category: {},
        case_owner: {
            type: 'enum',
            options: 'employees',
            selectAction: 'selectCaseOwner',
            optionDisplayField: 'fullName'
        },
        case_status: {
            type: 'enum',
            options: 'case_status',
            selectAction: 'selectCaseStatus',
            optionDisplayField: 'display'
        },
        case_supervisor: {
            type: 'enum',
            options: 'employees',
            selectAction: 'selectCaseSupervisor',
            optionDisplayField: 'display'
        },
        compensatory: {},
        court_case_number: {},
        court_type: {
            type: 'radio',
            options: 'courtTypes'
        },
        created_by: {},
        created_on: {},
        defendant: {},
        defendant_fault: {},
        exp_travel: {},
        judge: {},
        litigation_type: {
            type: 'enum',
            selectAction: 'selectLitigationType',
            options: 'litigation_types',
            optionDisplayField: 'display'
        },
        matter_type: {
            type: 'enum',
            selectAction: 'selectMatterType',
            options: 'matterTypes',
            searchEnabled: true,
            optionDisplayField: 'name'
        },
        mistrial: {
            type: 'checkbox'
        },
        monitor_status: {
            type: 'enum',
            selectAction: 'selectMonitorStatus',
            options: 'matter_monitor_status',
            optionDisplayField: 'display'
        },
        name: {
            type: 'readonly'
        },
        origin: {},
        overwrite_social_media: {
            type: 'text',
            selectAction: '',
            options: []
        },
        parties_in_court: {
            type: 'checkboxes',
            options: 'parties_in_court_options'
        },
        person_of_interest: {},
        plaintiff: {},
        plaintiff_fault: {},
        plaintiff_firms: {},
        primary_id: {},
        product: {},
        punitive: {},
        purpose_of_investigation: {},
        referred_by: {},
        relationship_of_coplaintiff: {
            type: 'radio',
            options: 'relationship_of_coplaintiff_options'
        },
        special_instructions: {},
        start_date: {
            type: 'date'
        },
        status: {
            type: 'enum',
            options: 'status',
            selectAction: 'selectStatus',
            optionDisplayField: 'display'
        },
        tm_code: {
            type: 'readonly'
        },
        type_of_service: {
            type: 'enum-multiple',
            selectAction: 'selectTypeOfService',
            options: 'service_types',
            searchEnabled: true
        },
        user_status: {
            type: 'radio',
            options: 'user_status_options'
        },
        venue_county: {},
        venue_state: {
            type: 'enum',
            selectAction: 'selectVenueState',
            options: 'states',
            optionDisplayField: 'display',
            searchEnabled: true
        }
    };

    exports.default = MatterColumnMapping;
});