define('tm3/pods/admin/matter-type/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Case Settings'],
        lists: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                matterType: this._super.apply(this, arguments),
                caseCategories: this.get('lists').getListForGroup('case_category'),
                fieldTemplates: this.store.findAll('field-template')
            });
        }
    });
});