define('tm3/pods/components/matter/admin/budget-form/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var AVG_RATE = 130;

    exports.default = Ember.Component.extend(_error.default, {
        initialNames: ['Default Budget', 'Jury Research', 'Phase 1', 'Phase 1 Supplemental', 'Phase 2', 'Special Project', 'Forensic Capture', 'Custom'],

        customName: null,

        originalAmount: true,

        showCustomNameField: Ember.computed('budgetChangeset', 'budgetChangeset.name', function () {
            return this.get('budgetChangeset').get('name') === 'Custom' || !this.get('initialNames').includes(this.get('budgetChangeset').get('name'));
        }),
        init: function init() {
            this._super();
            if (this.get('budgetChangeset').get('name') === '') {
                this.get('budgetChangeset').set('name', 'Default Budget');
                return;
            }
            if (this.get('showCustomNameField')) {
                this.set('customName', this.get('budgetChangeset').get('name'));
                this.get('budgetChangeset').set('name', 'Custom');
            }
        },

        observesAmount: Ember.observer('budgetChangeset.amount', 'budgetChangeset.flatRate', 'budgetChangeset.flatServiceItems.@each.{qty,amount}', 'originalAmount', function () {
            var amountFromFlatService = this.get('budgetChangeset.flatServiceItems').reduce(function (acc, item) {
                return acc + item.get('qty') * item.get('amount');
            }, 0);
            if (!this.get('budgetChangeset.flatRate')) {
                if (this.get('originalAmount') === this.get('budgetChangeset.amount')) {
                    return;
                }
            } else {
                if (this.get('originalAmount') === amountFromFlatService) {
                    return;
                }
            }
            if (this.get('budgetChangeset.amount') !== 0 && !this.get('budgetChangeset.flatRate')) {
                this.set('budgetChangeset.estimatedHours', Number(this.get('budgetChangeset.amount') / AVG_RATE).toFixed(2));
            }
            if (amountFromFlatService !== 0 && this.get('budgetChangeset.flatRate')) {
                this.set('budgetChangeset.estimatedHours', Number(amountFromFlatService / AVG_RATE).toFixed(2));
            }
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this.get('budgetChangeset._content').parseItems();
            this.set('originalAmount', this.get('budgetChangeset.amount'));
        },


        actions: {
            saveWithCustomName: function saveWithCustomName() {
                if (this.get('showCustomNameField')) {
                    this.get('budgetChangeset').set('name', this.get('customName'));
                }

                if (this.get('budgetChangeset.flatRate')) {
                    this.set('budgetChangeset.amount', this.get('budgetChangeset._content.amountFromFlatService'));
                    this.set('budgetChangeset.flatItems', JSON.stringify(this.get('budgetChangeset._content.flatServiceItems')));
                } else {
                    this.set('budgetChangeset.flatItems', '[]');
                }
            },
            addFlatService: function addFlatService() {
                this.get('budgetChangeset._content').addFlatService();
            },
            removeFlatService: function removeFlatService(service) {
                this.get('budgetChangeset._content').removeFlatService(service);
            },
            reorderItems: function reorderItems(items, draggedItems) {
                this.set('budgetChangeset._content.flatServiceItems', items);
            }
        }
    });
});