define('tm3/pods/components/subjects-list/extended-tools/component', ['exports', 'ember-cli-table-pagination/components/bs-table-pagination/table-tools'], function (exports, _tableTools) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _tableTools.default.extend({
        classNames: ['col-md-12'],

        onInit: Ember.on('init', function () {
            this.set('classNames', ['col-md-12']);

            var column = this.get('allColumns').findBy('displayName', 'Location');
            if (column) {
                column.set('advFilterOperator', this.get('operators').findBy('value', 'equal'));
                column.set('advFilterValue', 'pool');
            }

            this.send('applyFilter');
        }),
        buttonLink: Ember.computed.reads('toolsParams.buttonLink'),
        buttonText: Ember.computed.reads('toolsParams.buttonText'),

        showEditMultipleModal: false,

        // matter: reads('titleParams.matter'),
        matter: Ember.computed.reads('toolsParams.matter'),
        // selectedRows: reads('titleParams.selectedRows')
        selectedRows: Ember.computed.reads('toolsParams.selectedRows')
    });
});