define('tm3/pods/matter/sent-reports/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['page', 'perPage'],
        page: 1,
        perPage: 50,
        sortDirection: 'desc',
        sortProperty: 'createdOn',

        totalRecordCount: Ember.computed.alias('model.meta.total_record_count'),

        pageCount: Ember.computed('perPage', 'totalRecordCount', function () {
            var _getProperties = this.getProperties('perPage', 'totalRecordCount'),
                perPage = _getProperties.perPage,
                totalRecordCount = _getProperties.totalRecordCount;

            return Math.floor(totalRecordCount / perPage);
        }),

        /**
            Provides sorted `OutboundCommunicationLogRecords` based on
            computed `sortExpression`.
             @property sortedReports
            @type {Array}
         */
        sortedReports: Ember.computed.sort('model', 'sortExpression'),

        /**
            Provides desired Ember `sortExpression` based on `sortDirection` and
            `sortProperty`.
             @property sortExpression
            @type {String}
         */
        sortExpression: Ember.computed('sortDirection', 'sortProperty', function () {
            var _getProperties2 = this.getProperties('sortDirection', 'sortProperty'),
                sortDirection = _getProperties2.sortDirection,
                sortProperty = _getProperties2.sortProperty;

            return [sortProperty + ':' + sortDirection];
        }),

        actions: {
            /**
                Handles sorting of `OutboundCommunicationLogRecords` based on user
                interaction byt updating `sortDirection` and `sortProperty` as needed.
                 @method setSortProperty
                @param {String} sortPropertyName
             */
            setSortProperty: function setSortProperty(sortPropertyName) {
                var _getProperties3 = this.getProperties('sortDirection', 'sortProperty'),
                    sortDirection = _getProperties3.sortDirection,
                    sortProperty = _getProperties3.sortProperty;

                var isNewSortProperty = sortPropertyName !== sortProperty;

                if (isNewSortProperty) {
                    this.setProperties({
                        sortDirection: 'asc',
                        sortProperty: sortPropertyName
                    });
                } else {
                    this.set('sortDirection', sortDirection === 'desc' ? 'asc' : 'desc');
                }
            },


            /**
                Updates the current page of `OutboundCommunicationLogRecords` based
                on user interaction.
                 @method setPage
             * @param {*} page
             */
            setPage: function setPage(page) {
                this.set('page', page);
            }
        }
    });
});