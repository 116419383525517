define('tm3/pods/admin/employees/info/reports/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),

        groupedReports: Ember.computed('dataReports.[]', function () {
            var dataReports = this.get('dataReports');
            var groupedReports = [];
            var categories = dataReports.mapBy('category').uniq();
            categories.forEach(function (category) {
                groupedReports[category] = dataReports.filterBy('category', category).sortBy('displayName');
            });
            return groupedReports;
        })
    });
});