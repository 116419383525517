define('tm3/pods/me/travel/list/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        session: Ember.inject.service(),
        notify: Ember.inject.service(),

        queryParams: {
            currentTravelExp: {
                refreshModel: true
            },
            start: {
                refreshModel: true
            },
            end: {
                refreshModel: true
            },
            status: {
                refreshModel: true
            }
        },

        model: function model(params) {
            params['created_by_id'] = this.get('session.data.authenticated.id');
            params['bg_type'] = 'Time';
            params['with'] = 'billable_types,billables,receipts,charges';
            var status = params['status'];
            var approvalStatus = null;
            if (status !== 'All') {
                approvalStatus = status;
            }
            if (params['start']) {
                params['start'] = '>=' + params['start'];
            }
            if (params['end']) {
                params['end'] = '<=' + params['end'];
            }

            var chargesFilters = {
                'billable_groups:created_by_id': this.get('session.data.authenticated.id'),
                'billable_groups:type': 'Travel',
                with: 'billable_groups,billable_types,invoice_groups,travel_expense_has_files'
            };
            if (approvalStatus) {
                chargesFilters['approval_status'] = approvalStatus;
            }
            if (params['start']) {
                chargesFilters['billable_groups:start'] = params['start'];
            }
            if (params['end']) {
                chargesFilters['billable_groups:end'] = params['end'];
            }

            return Ember.RSVP.hash({
                charges: this.store.query('charge', chargesFilters),
                current: params.current,
                deductions: params.currentTravelExp ? this.store.query('deduction', { billable_group_id: params.currentTravelExp, with: 'billable_types' }) : [],
                currentTravelExp: params.currentTravelExp,
                currentEmployee: this.store.findRecord('employee', this.get('session.data.authenticated.id'))
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('isAddDisabled', false);
            controller.set('activeMatterBillable', null);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            edit: function edit(billableGroup, matterId) {
                this.transitionTo('me.travel.edit', billableGroup.get('id'), {
                    queryParams: {
                        matterId: matterId,
                        billable_type: billableGroup.get('type')
                    }
                });
            },
            addBillable: function addBillable(matterId, billType) {
                this.transitionTo('me.travel.edit', 'new', {
                    queryParams: {
                        canSelectMatter: true,
                        billable_type: billType,
                        matterId: matterId
                    }
                });
            }
        }
    });
});