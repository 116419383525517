define('tm3/pods/crm/contacts/edit/client-details/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        model: function model() {
            var clientId = this.paramsFor('crm.contacts.edit').client_id;
            var clientRecord = this.store.findRecord('client', clientId);
            var lists = this.get('lists');

            return Ember.RSVP.hash({
                model: clientRecord,
                clientTypes: lists.getListForGroup('crm_client_type'),
                tiers: lists.getListForGroup('copper_tiers'),
                userNameSuffixes: lists.getListForGroup('user_name_suffixes'),
                userTitles: lists.getListForGroup('user_titles')
            });
        },
        setupController: function setupController(controller, resolved) {
            var clientRecord = resolved.model;

            this._super(controller, clientRecord);

            controller.setProperties({
                clientTypes: resolved.clientTypes,
                userTitles: resolved.userTitles,
                userNameSuffixes: resolved.userNameSuffixes,
                tiers: resolved.tiers
            });

            if (clientRecord.get('userName') === clientRecord.get('email')) {
                controller.set('copyEmail', true);
            }
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('copyEmail', false);
            }
        }
    });
});