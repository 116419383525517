define('tm3/pods/matter/admin/reports/controller', ['exports', 'ember-lifeline/mixins/run', 'tm3/mixins/report-info-controller'], function (exports, _run, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, _run.default, {
        queryParams: ['invoice_group', 'budget'],
        invoice_group: null,
        budget: null,

        showEmployeeWarning: false,
        showTableWarning: false,

        byTypeServiceEntries: Ember.computed('model.typeTableData.table_data.[]', function () {
            var allEntries = this.get('model.typeTableData.table_data');
            var entries = [];

            allEntries.forEach(function (entry) {
                if (entry.bill_type === 'Time' || entry.bill_type === 'Piece') {
                    entries.push(entry);
                }
            });

            return entries;
        }),

        byTypeExpenseEntries: Ember.computed('model.typeTableData.table_data.[]', function () {
            var allEntries = this.get('model.typeTableData.table_data');
            var entries = [];

            allEntries.forEach(function (entry) {
                if (entry.bill_type !== 'Time' && entry.bill_type !== 'Piece') {
                    entries.push(entry);
                }
            });

            return entries;
        }),

        canSeeAmounts: Ember.computed('model.employeeTableData.table_data.[]', function () {
            return this.get('model.employeeTableData.table_data.firstObject.has_access') === '1';
        }),

        observeSelectedInvoice: Ember.observer('selectedInvoiceGroup', function () {
            this.debounceTask('updateInvoiceParam', 600);
        }),
        observeSelectedBudget: Ember.observer('selectedBudget', function () {
            this.debounceTask('updateBudgetParam', 600);
        }),

        updateInvoiceParam: function updateInvoiceParam() {
            this.set('invoice_group', this.get('selectedInvoiceGroup.id'));
        },
        updateBudgetParam: function updateBudgetParam() {
            this.set('budget', this.get('selectedBudget.id'));
        },


        actions: {
            total: function total(fieldName, acc, item) {
                return acc + parseFloat(item[fieldName]);
            }
        }
    });
});