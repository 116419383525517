define('tm3/utils/calendar-functions', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        setCurrentItem: function setCurrentItem(scCalendarEvent, event, target) {
            event.preventDefault();
            event.stopPropagation();
            switch (scCalendarEvent.type) {
                case 'event':
                    target.get('store').findRecord('event', scCalendarEvent.id).then(function (eventRec) {
                        target.set('currentEvent', eventRec);
                        target.set('selectedItemMatterId', eventRec.get('matter.id'));
                    });
                    break;
                case 'task':
                    target.get('store').findRecord('task', scCalendarEvent.id).then(function (task) {
                        target.set('currentTask', task);
                    });
                    break;
                case 'report':
                    target.get('store').findRecord('report', scCalendarEvent.id.split('-')[0]).then(function (report) {
                        target.set('currentReport', report);
                        target.set('selectedItemMatterId', report.get('matter.id'));
                    });
                    break;
                case 'case-trial':
                    target.set('selectedItemMatterId', scCalendarEvent.id);
                    target.set('showTrial', true);
                    break;
            }
        },
        createNewEventAndSetAsCurrent: function createNewEventAndSetAsCurrent(target, matter) {
            var newEvent = target.get('store').createRecord('event', {
                start: (0, _moment.default)(target.get('center')).format('YYYY-MM-DD')
            });
            if (matter) {
                newEvent.set('matter', matter);
            }
            target.set('currentEvent', newEvent);
        },
        saveItem: function saveItem(item, onSuccess, onError) {
            return item.save().then(function () {
                onSuccess();
            }, function (_error) {
                onError();
            });
        },
        deleteItem: function deleteItem(item, onSuccess, onError) {
            return item.destroyRecord().then(function () {
                onSuccess();
            }, function (_error) {
                onError();
            });
        },
        cancelCurrentItemEdit: function cancelCurrentItemEdit(target, itemName) {
            target.get(itemName).rollbackAttributes();
            target.set(itemName, null);
        }
    };
});