define('tm3/pods/components/matter-update/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @property passiv-event model
     */
    event: null,

    /**
     * @property boolean if we want to show the date
     */
    withDate: true
  });
});