define('tm3/pods/crm/tags/browse/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['See Contacts'],

        // indicate that when this query param changes, refresh the route
        queryParams: {
            filterList: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var clientParams = { with: 'clients', crm_tag_id: '' };
            var firmParams = { with: 'firms', crm_tag_id: '' };
            var dealParams = { with: 'deals', crm_tag_id: '' };
            var tagFilters = params.filterList;

            // pass this along for further processing
            firmParams.crm_tag_id = tagFilters;
            clientParams.crm_tag_id = tagFilters;
            dealParams.crm_tag_id = tagFilters;

            return Ember.RSVP.hash({
                tags: this.store.findAll('crm-tag'),
                clientTags: this.store.query('crm-tag-has-client', clientParams),
                firmTags: this.store.query('crm-tag-has-firm', firmParams),
                dealTags: this.store.query('crm-tag-has-deal', dealParams),
                filterList: tagFilters
            });
        },
        setupController: function setupController(controller, resolved) {
            var filterList = resolved.filterList;
            var activeTags = [];
            var availableTags = [];
            var tags = resolved.tags;
            // special logic to divide tags
            tags.forEach(function (tag) {
                var active = 0;
                filterList.forEach(function (id) {
                    if (id === tag.get('id')) {
                        active = 1;
                    }
                });

                if (active === 1) {
                    activeTags.pushObject(tag);
                } else {
                    availableTags.pushObject(tag);
                }
            });

            var clientList = [];
            var dealList = [];
            var firmList = [];

            this._super(controller, {});
            controller.set('activeTags', activeTags);
            controller.set('availableTags', availableTags);
            controller.set('filterList', filterList);

            /**
             * a bunch of logic to eliminate duplicates
             */

            resolved.firmTags.forEach(function (item) {
                var firm = item.get('firm');
                var firmId = firm.get('id');
                var match = firmList.findBy('id', firmId);
                if (match) {} else {
                    firmList.pushObject(firm);
                }
            });
            controller.set('firmTags', firmList);

            resolved.dealTags.forEach(function (item) {
                var deal = item.get('deal');
                var dealId = deal.get('id');
                var match = dealList.findBy('id', dealId);
                if (match) {} else {
                    dealList.pushObject(deal);
                }
            });
            controller.set('dealTags', dealList);

            resolved.clientTags.forEach(function (item) {
                var client = item.get('client');
                var clientId = client.get('id');
                var match = clientList.findBy('id', clientId);
                if (match) {} else {
                    clientList.pushObject(client);
                }
            });
            controller.set('clientTags', clientList);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});