define('tm3/pods/me/avatar/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service('notify'),

        showSaveButton: Ember.computed.notEmpty('model.avatarId'),

        actions: {
            saveAvatar: function saveAvatar() {
                var self = this;
                var user = this.get('model');
                user.save().then(function () {
                    self.get('notify').success('Avatar was saved');
                }, function () {
                    self.validationReport(user);
                });
            },
            removeAvatar: function removeAvatar() {
                var self = this;
                var user = this.get('model');
                user.set('avatarId', null);
                user.save().then(function () {
                    self.get('notify').success('Avatar was removed');
                }, function () {
                    self.validationReport(user);
                });
            }
        }
    });
});