define('tm3/pods/report/info/budget-utilization/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),

        model: function model(params) {
            return this.get('ajax').request('report_builder', {
                data: {
                    type: 'budget_utilization',
                    style: 'misc',
                    format: 'html'
                }
            }).catch(function (error) {
                return error;
            });
        }
    });
});