define('tm3/pods/case-management/info/cases/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'tm3/mixins/case-management/info/cases-controller'], function (exports, _controller, _casesController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, _casesController.default, {
        appName: 'tm-admin',
        useTableSettings: false
    });
});