define('tm3/pods/report/info/unpaid-invoice-outputs/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        lists: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        apiName: 'unpaid_invoice_outputs',

        queryParams: {
            groupBy: { refreshModel: false },
            selectedAge: { refreshModel: false },
            employeeId: { refreshModel: false }
        },

        /**
         * load report and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 99999,
                    page: 1,
                    group_by: params.groupBy,
                    min_age: params.selectedAge,
                    employee_id: params.employeeId
                }),
                employee: Ember.isEmpty(params.employeeId) ? null : this.store.findRecord('employee', params.employeeId),
                groupBy: params.groupBy,
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: 99999,
                page: 1
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('selectedGroupBy', resolved.groupBy);
            controller.set('selectedEmployee', resolved.employee);
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});