define('tm3/pods/accounting/billing/subscription-costs/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'subscription-cost',
        currentRoute: 'admin.subscription-cost.list',

        requiredPermissions: ['Admin Time & Expense'],

        model: function model(params) {
            params.with = 'all';
            return this._super(params);
        },


        actions: {
            doNothing: function doNothing() {
                // Do nothing at all
            }
        }
    });
});