define('tm3/pods/crm/contacts/info/access/controller', ['exports', 'tm3/config/environment', 'ember-lifeline/mixins/run'], function (exports, _environment, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_run.default, {
        session: Ember.inject.service(),
        notify: Ember.inject.service(),

        teamToAdd: undefined,

        accessEnabled: Ember.computed.equal('model.status', 'Active'),

        actions: {
            toggleAddTeamForm: function toggleAddTeamForm() {
                Ember.$('#addTeamForm').toggleClass('hidden');
            },
            cancelAddNewTeam: function cancelAddNewTeam() {
                Ember.$('#addTeamForm').toggleClass('hidden');
                this.set('teamToAdd', undefined);
            },
            submitAddNewTeam: function submitAddNewTeam(team) {
                var _this = this;

                var self = this;

                Ember.$('#addUserToTeamSpinner').removeClass('hidden');

                if (team) {
                    var user = this.get('user');
                    var uht = self.store.createRecord('user-has-team', {
                        team: team,
                        user: user
                    });

                    uht.save().then(function () {
                        _this.runTask(function () {
                            Ember.$('#addTeamForm').toggleClass('hidden');
                            self.set('teamToAdd', undefined);
                            self.send('refreshModel');
                        }, 1);
                    }, function (reason) {
                        self.validationReport(uht);
                    });
                } else {
                    this.get('notify').error('You must select a team to add');
                }
            },
            deleteTeamConnection: function deleteTeamConnection(item) {
                var self = this;

                Ember.$('#addUserToTeamSpinner').removeClass('hidden');

                item.destroyRecord().then(function () {
                    self.send('refreshModel');
                });
            },
            impersonateUser: function impersonateUser(app) {
                var user = this.get('user');
                var url = void 0;

                switch (app) {
                    case 'js-portal':
                        url = _environment.default.APP.portalJs2URL;
                        break;
                    case 'sc-portal':
                        url = _environment.default.APP.portalScURL;
                        break;
                }

                user.impersonateUser({ user_id: user.get('id'), app: app }).then(function (resp) {
                    var newToken = resp.token;
                    window.open(url + 'auth/force-login?token=' + newToken, '_blank');
                });
            }
        }
    });
});