define('tm3/pods/components/custom-table-pagination/tags-cell/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        tagName: 'div',

        allTags: Ember.computed('row', function () {
            var publicTags = this.get('row.tags');
            var privateTags = this.get('row.privateTags');

            if (Ember.isEmpty(publicTags) && Ember.isEmpty(privateTags)) {
                // check the other fields
                publicTags = this.get('row.matter.publicTagNames');
                privateTags = this.get('row.matter.privateTagNames');
            }

            var publics = publicTags ? publicTags.split(', ').sort() : [];
            var privates = privateTags ? privateTags.split(', ').sort() : [];

            var result = [];
            result.pushObjects(publics.map(function (t) {
                return {
                    type: 'public',
                    name: t
                };
            }));
            result.pushObjects(privates.map(function (t) {
                return {
                    type: 'private',
                    name: t
                };
            }));

            return result;
        })
    });
});