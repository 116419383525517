define('tm3/pods/matter/subjects/info/posts/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model() {
            var subjectInfo = this.modelFor('matter.subjects.info');
            var subject = subjectInfo.subject.get('firstObject');
            var hyperlinks = subject.get('hyperlinks');
            var resourcesIds = hyperlinks.map(function (link) {
                return link.get('resourceId');
            });
            return Ember.RSVP.hash({
                matter: this.get('currentMatter.matter'),
                hyperlinks: hyperlinks,
                subject: subject,
                scrapes: this.store.query('scrape', {
                    hyperlink_resource_id: resourcesIds
                }),
                posts: this.get('store').query('post', {
                    hyperlink_resource_id: resourcesIds
                })
            });
        },


        actions: {
            willTransition: function willTransition(transition) {
                if (this.get('controller.showNewPostForm')) {
                    var currentPost = this.get('controller.currentPostProxy');
                    if (!confirm('Are you sure you want to navigate away from the edit form? Any unsaved edits will be lost.')) {
                        transition.abort();
                        return true;
                    } else {
                        currentPost.rollback();
                    }
                }
                this.set('controller.showNewPostForm', false);
                return true;
            },
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});