define('tm3/pods/components/admin/employees/info/tab-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        'data-test-component': 'admin-employees-info-tab-bar',
        activeItem: null,
        classNames: ['nav', 'nav-tabs'],
        tagName: 'ul',

        init: function init() {
            this._super.apply(this, arguments);

            this.set('menuItems', ['tab-details', 'tab-groups-and-teams', 'tab-reports', 'tab-billing', 'tab-skills']);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            Ember.run.scheduleOnce('afterRender', this, this.toggleMenu);
        },
        toggleMenu: function toggleMenu() {
            var element = this.get('activeItem');
            var menuItems = this.get('menuItems');
            var elm = '#' + element;

            Ember.$(elm).toggleClass('active');

            menuItems.forEach(function (item) {
                // reset all other menu items to off position
                if (item !== element) {
                    elm = '#' + item;
                    Ember.$(elm).removeClass('active');
                }
            });
        },


        actions: {
            /* update current item call toggle */
            makeActive: function makeActive(element) {
                this.set('activeItem', element);
                this.toggleMenu();
            }
        }
    });
});