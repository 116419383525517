define('tm3/mixins/calculate-invoice-amounts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        calculateTimeTotal: function calculateTimeTotal(invoiceDetails) {
            var calculatedTotal = invoiceDetails.get('timeEntries').reduce(function (total, time) {
                if (!time.billables.time) {
                    console.log('Billable with no time', time.billables);
                    return total;
                }
                return total + parseFloat(time.billables.time.total);
            }, 0);
            return calculatedTotal;
        },
        calculatePieceTotal: function calculatePieceTotal(invoiceDetails) {
            return invoiceDetails.get('pieceEntries').reduce(function (total, piece) {
                return total + parseFloat(piece.billables.piece.total);
            }, 0);
        },
        calculateTimeRelatedExpenseTotal: function calculateTimeRelatedExpenseTotal(invoiceDetails) {
            var expTotal = 0;
            var time = invoiceDetails.get('timeEntries');
            time.forEach(function (item, index, enumerable) {
                expTotal = expTotal + item.billables.expenses.reduce(function (previousValue, itemExpense, index, enumerable) {
                    return previousValue + parseFloat(itemExpense.total);
                }, 0);
            });
            return expTotal;
        },
        calculatePieceRelatedExpensesTotal: function calculatePieceRelatedExpensesTotal(invoiceDetails) {
            var expTotal = 0;
            var piece = invoiceDetails.get('pieceEntries');
            piece.forEach(function (item, index, enumerable) {
                expTotal = expTotal + item.billables.expenses.reduce(function (previousValue, itemExpense, index, enumerable) {
                    return previousValue + parseFloat(itemExpense.total);
                }, 0);
            });
            return expTotal;
        },
        calculateManualTotal: function calculateManualTotal(invoiceDetails) {
            var total = 0;
            var manual = invoiceDetails.get('manualEntries');
            manual.forEach(function (m) {
                m.billables.forEach(function (b) {
                    total += parseFloat(b.total);
                });
            });
            return total;
        },
        calculateTravelTotal: function calculateTravelTotal(invoiceDetails) {
            var travelTotal = 0;
            var travel = invoiceDetails.get('travelEntries');
            travel.forEach(function (item, index, enumerable) {
                if (Ember.isPresent(item.billables.travel)) {
                    travelTotal = travelTotal + item.billables.travel.reduce(function (previousValue, itemTravel, index, enumerable) {
                        return previousValue + parseFloat(itemTravel.total);
                    }, 0);
                }
            });
            return travelTotal;
        }
    });
});