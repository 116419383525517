define('tm3/pods/me/notification-settings/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            var userId = this.get('session').get('data.authenticated.id');

            return Ember.RSVP.hash({
                user_settings: this.store.query('user-setting', {
                    user_id: userId,
                    group_name: ['email_settings', 'user_settings']
                }),
                user: this.store.findRecord('user', userId)
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, {});

            resolved.user_settings.forEach(function (setting) {
                switch (setting.get('name')) {
                    case 'email_frequency':
                        controller.set('emailFrequency', setting.get('value'));
                        break;
                    case 'email_digest_group_by':
                        controller.set('digestGroupBy', setting.get('value'));
                        break;
                    case 'skip_own_notifications':
                        controller.set('skipOwnNotifications', setting.get('value') === 'true');
                        break;
                    case 'email_frequency_interval':
                        if (setting.get('value')) {
                            controller.set('emailFrequencyInterval', setting.get('value') / 60);
                        } else {
                            controller.set('emailFrequencyInterval', 8);
                        }
                        break;
                    case 'notifications_keep_before_delete':
                        if (setting.get('value')) {
                            controller.set('notificationsKeepBeforeDelete', setting.get('value'));
                        } else {
                            controller.set('notificationsKeepBeforeDelete', 30);
                        }
                        break;
                    case 'subject_refresh_time':
                        if (setting.get('value')) {
                            var option = controller.get('refreshTimeOptions').findBy('seconds', parseInt(setting.get('value')));
                            if (!option) {
                                option = controller.get('refreshTimeOptions').findBy('seconds', 180);
                            }
                            controller.set('refreshTime', option);
                        } else {
                            var _option = controller.get('refreshTimeOptions').findBy('seconds', 180);
                            controller.set('refreshTime', _option);
                        }
                        break;

                    case 'daily_recap':
                        controller.set('dailyRecap', false);
                        break;

                    case 'subjects_autosave':
                        controller.set('areSubjectsAutoSaving', setting.get('value') === 'true');
                        break;
                }
            });

            controller.set('user', resolved.user);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});