define('tm3/pods/components/matter/billables/edit/receipt-list/component', ['exports', 'tm3/pods/components/matter/billables/edit/receipt-list/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        actions: {
            removeReceipt: function removeReceipt(receipt) {
                this.sendAction('removeReceipt', receipt);
            },
            changeBillable: function changeBillable(receipt, selectedBillable) {
                // Had to do this instead of mut receipt.billable b/c it was not working with pipe helper
                receipt.set('billable', selectedBillable);
            }
        }
    });
});