define('tm3/pods/crm/accounts/edit/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var appLists = ['States', 'crm_region', 'crm_rating', 'crm_sc_business', 'crm_industry', 'countries'];

    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        requiredPermissions: ['Manage Accounts'],

        model: function model(params) {
            // params.matter_id
            return Ember.RSVP.hash({
                model: this.store.findRecord('firm', params.firm_id),
                lists: this.get('lists').fetchMultipleLists(appLists)
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);

            appLists.forEach(function (listName) {
                controller.set(listName.toLowerCase(), resolved.lists[listName]);
            });
        }
    });
});