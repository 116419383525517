define('tm3/mixins/after-render', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        /*
        This hook is guaranteed to be executed when the root element of this view has been inserted into the DOM.
        */
        didInsertElement: function didInsertElement() {
            this._super();
            Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
        },
        afterRenderEvent: function afterRenderEvent() {
            return this;
        }
    });
});