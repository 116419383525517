define('tm3/pods/matter/subjects/new/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        // currrent-matter service for use in dashboard and related routes
        currentMatter: Ember.inject.service(),
        matterId: null,

        beforeModel: function beforeModel(transition) {
            this.set('matterId', transition.params['matter'].matter_id);
        },


        /**
         * load the current subject
         * @returns {*}
         */
        model: function model() {
            return Ember.RSVP.hash({
                subject: this.store.createRecord('subject'),
                matter: this.store.findRecord('matter', this.get('matterId')),
                phrases: this.store.query('field-phrase', {
                    matter_id: ['NULL', this.get('matterId')]
                })
            });
        },


        /**
         * pre model
         * verify that a subject was loaded correctly
         * @param controller
         * @param model
         */
        setupController: function setupController(controller, model) {
            var currentMatter = this.get('currentMatter');

            // remove some hard coded values from the list
            var matterFields = currentMatter.get('matterFields').reject(function (item, index, enumerable) {
                return item.get('value') === 'last_name' || item.get('value') === 'first_name';
            });

            controller.set('matter', model.matter);
            controller.set('matterFields', matterFields.filter(function (mf) {
                return mf.get('isViewableAdminSubjectProfile');
            }));

            var fieldLabels = currentMatter.get('matterFields').map(function (mf) {
                return mf.get('display');
            });
            controller.set('phrases', model.phrases.filter(function (phrase) {
                return fieldLabels.includes(phrase.get('field'));
            }));
            this._super(controller, model.subject);
        },

        actions: {
            willTransition: function willTransition(transition) {
                if (Object.keys(this.controller.get('model').changedAttributes()).length > 0 && !window.confirm('Are you sure you want to navigate away from the editing form? Any unsaved edits will be lost.')) {
                    transition.abort(true); // I don't know why I have to pass true, I didn't see anything in the docs, but if I don't the confirmation pops up indefinitely
                    return true;
                }
                this.controller.set('isEditingSubject', false);
                return true;
            }
        }
    });
});