define('tm3/pods/matter/timelines/controller', ['exports', 'tm-common/mixins/matter-timeline-controller', 'tm-common/mixins/timeline-controller'], function (exports, _matterTimelineController, _timelineController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_matterTimelineController.default, _timelineController.default, {
        currentMatter: Ember.inject.service(),

        actions: {
            addNewTimelineEvent: function addNewTimelineEvent(subject) {
                this.set('currentSubject', subject);
                this.set('newEventModal', true);
            },
            editTimelineEvent: function editTimelineEvent(eventProxy) {
                var event = this.get('model.timelineEvents').findBy('id', eventProxy.get('id'));
                this.set('currentEvent', event);
                this.set('editEventModal', true);
            },
            submit: function submit() {},
            exportTimeline: function exportTimeline() {
                var _this = this;

                var subjects = this.get('model.subjectsProxy').filterBy('isSelected');
                if (subjects.get('length ') < 1) {
                    this.get('notify').warning('You must select at least one subject');
                } else {
                    var jsonObject = {
                        report_builder: {
                            standard: {
                                type: 'timeline',
                                format: 'pdf',
                                style: 'misc',
                                entity: 'TimelineEvents'
                            },
                            custom: {
                                current_params: {
                                    page: 1,
                                    perPage: 50,
                                    sortField: '',
                                    with: 'timeline_event_types,timeline_event_parents',
                                    matter_id: this.get('currentMatter.id')
                                },
                                column_fields: [''],
                                column_names: [''],
                                column_widths: [],
                                entity: 'TimelineEvent',
                                landscape: false,
                                include_footer: true,
                                viewable: this.get('showHidden'),
                                subjects: subjects.map(function (s) {
                                    return {
                                        color: s.color,
                                        subject_id: s.subject.get('id')
                                    };
                                })
                            }
                        }
                    };

                    // this.get('reportBuilder').executeReport(jsonObject, (response) => {
                    this.get('reportBuilder').enqueueReport(jsonObject, function (response) {
                        if (response.ok) {
                            _this.set('enqueuedJobId', response.job);
                        } else {
                            _this.get('notify').error('There was a problem enqueueing the report for generation');
                        }
                    });
                }
            }
        }
    });
});