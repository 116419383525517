define('tm3/pods/matter/renumber-tabs/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),

        groupBy: null,
        selectedGroup: null,
        queryParams: ['groupBy', 'selectedGroup', 'sortField', 'docTypesOrder'],

        documentTypes: null,

        sortOptions: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('sortOptions', [{ direction: 'asc', field: 'name', display: 'Name' }, { direction: 'desc', field: 'created_on', display: 'Most Recent' }, { direction: 'asc', field: 'created_on', display: 'Less Recent' }, {
                direction: 'asc',
                field: 'reports:name',
                display: 'Report Name Ascending'
            }, {
                direction: 'desc',
                field: 'reports:name',
                display: 'Report Name Descending'
            }, { direction: 'asc', field: 'tab_number', display: 'Tab Number Ascending' }, {
                direction: 'desc',
                field: 'tab_number',
                display: 'Tab Number Descending'
            }, { direction: 'asc', field: 'doc_type', display: 'Document Type' }, { direction: 'asc', field: 'subject', display: 'Subject Name' }, {
                direction: 'asc',
                field: 'subject_order_and_doc_type',
                display: 'Subject Order Number'
            }, { direction: 'asc', field: 'viewable', display: 'Published' }]);
        },


        sortFieldInitial: undefined,
        sortField: 'name',

        orderedDocuments: Ember.computed('model.documents.@each.weight', function () {
            return this.get('model.documents').sortBy('weight');
        }),

        orderedDocTypes: Ember.computed('documentTypes.@each.weightVirtual', function () {
            return this.get('documentTypes').sortBy('weightVirtual');
        }),

        groupLabel: Ember.computed('groupBy', function () {
            var groupBy = this.get('groupBy');

            switch (groupBy) {
                case 'none':
                    return 'None';
                case 'reportName':
                    return 'Report';
                case 'docType':
                    return 'Doc Type';
                case 'subject':
                    return 'Subject';
                case 'tags':
                    return 'Tags';
                case 'viewable':
                    return 'Published Status';
                default:
                    return 'Unknown';
            }
        }),
        actions: {
            reorderDocuments: function reorderDocuments(sortedDocs) {
                sortedDocs.forEach(function (doc, index) {
                    doc.set('weight', index);
                });
            },
            setTabNumbersSequentially: function setTabNumbersSequentially() {
                this.get('orderedDocuments').rejectBy('excluded').forEach(function (doc, index) {
                    doc.set('tabNumber', index + 1);
                });
            },
            setTabNumbersBySubject: function setTabNumbersBySubject() {
                var currentSubject = null;
                var index = 0;

                this.get('orderedDocuments').rejectBy('excluded').forEach(function (doc) {
                    if (currentSubject !== doc.get('subject.id')) {
                        index = 0;
                        currentSubject = doc.get('subject.id');
                    }
                    doc.set('tabNumber', index += 1);
                });
            },
            save: function save() {
                var _this = this;

                var promises = [];

                this.get('orderedDocuments').rejectBy('excluded').forEach(function (doc) {
                    var attributes = doc.changedAttributes();

                    if (doc.get('hasDirtyAttributes') && Ember.isPresent(attributes.tabNumber)) {
                        promises.pushObject(doc.save());
                    }
                });

                if (promises.length > 0) {
                    Ember.RSVP.all(promises).then(function () {
                        _this.get('notify').success('Documents Tab Numbers saved');
                        _this.send('refresh');
                    }, function () {
                        _this.get('notify').error('Error while saving documents Tab Numbers');
                    });
                }
            },
            changeSort: function changeSort(newSort) {
                this.set('sortFieldInitial', newSort);

                if (newSort) {
                    this.set('sortField', (newSort.direction === 'desc' ? '-' : '') + newSort.field);
                } else {
                    this.set('sortField', 'name');
                }
            },
            reorderDocTypes: function reorderDocTypes(sortedDocTypes) {
                sortedDocTypes.forEach(function (docType, index) {
                    docType.set('weightVirtual', index);
                });
            },
            newDocTypeOrder: function newDocTypeOrder() {
                this.set('showDocTypeOrder', false);
                this.set('docTypesOrder', this.get('orderedDocTypes').map(function (docType) {
                    return docType.get('value');
                }).join(','));
            }
        }
    });
});