define('tm3/pods/matter/dashboard/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Controller.extend({
        matterId: alias('model.matterId'),

        employeesWithoutToBeAssigned: Ember.computed('employees.[]', function () {
            return this.get('employees').rejectBy('id', '16842');
        }),

        actions: {
            doNothing: function doNothing() {}
        }
    });
});