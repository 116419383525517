define('tm3/mixins/travel-expense-controller', ['exports', 'moment', 'tm3/mixins/get-available-matters'], function (exports, _moment, _getAvailableMatters) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_getAvailableMatters.default, {
        startDate: Ember.computed.alias('model.billableGroup.start'),
        endDate: Ember.computed.alias('model.billableGroup.end'),
        charges: Ember.computed.alias('model.charges'),
        allCharges: [],
        deductions: [],

        chargesByExpenseType: Ember.computed('model.expenseTypes', 'charges', 'startDate', 'endDate', function () {
            var _this = this;

            var chargesByExpenseType = [];
            this.set('allCharges', []);

            var daysToProcess = (0, _moment.default)(this.get('endDate')).diff((0, _moment.default)(this.get('startDate')), 'd') + 1;
            this.get('model.expenseTypes').forEach(function (expenseType) {
                var rowObj = {};
                rowObj.expenseType = expenseType;
                rowObj.charges = [];
                var initialDay = (0, _moment.default)(_this.get('startDate'));
                for (var d = 0; d < daysToProcess; d++) {
                    var date = initialDay.clone();

                    var found = false;

                    _this.get('charges').filterBy('date', date.format('YYYY-MM-DD')).forEach(function (charge) {
                        if (charge.get('billableType.id') === expenseType.get('id')) {
                            rowObj.charges.pushObject(charge);
                            found = true;
                        }
                    });

                    if (!found) {
                        rowObj.charges.pushObject(_this.store.createRecord('charge', {
                            date: date,
                            billableType: expenseType
                        }));
                    }
                    initialDay.add(1, 'day');
                }
                _this.get('allCharges').pushObjects(rowObj.charges);
                chargesByExpenseType.pushObject(rowObj);
            });

            return chargesByExpenseType;
        }),

        deductionsObserver: Ember.observer('model.deductions', function () {
            this.set('deductions', this.get('model.deductions').toArray());
        }),

        chargesObserver: Ember.observer('allCharges.@each.{rate,qty}', function () {
            var _this2 = this;

            var total = this.get('allCharges').map(function (charge) {
                charge.finalizeTotal();
                return charge;
            }).filter(function (charge) {
                return Ember.isPresent(charge.get('total'));
            }).reduce(function (acc, charge) {
                return acc + parseFloat(charge.get('total'));
            }, 0);
            Ember.run.next(function () {
                _this2.set('model.billableGroup.total', total);
            });
        }),

        availableMattersObserver: Ember.observer('model.billableGroup', 'startDate', 'endDate', function () {
            var start = this.get('startDate');
            var end = this.get('endDate');
            var startParameter = void 0,
                endParameter = void 0;
            if (Ember.isEmpty(start)) {
                startParameter = '';
            } else {
                startParameter = (0, _moment.default)(start).format('YYYY-MM-DD');
            }
            if (Ember.isEmpty(end)) {
                endParameter = '';
            } else {
                endParameter = (0, _moment.default)(end).format('YYYY-MM-DD');
            }
            this.get('getAvailableMattersTask').perform(this.get('model.billableGroup'), startParameter, endParameter);
        })
    });
});