define('tm3/validators/bulk-time-entry', ['exports', 'ember-changeset-validations/validators', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _sometimes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        startDate: [(0, _validators.validatePresence)({
            presence: true,
            message: 'Start Date is required'
        })],
        qty: [(0, _validators.validatePresence)({
            presence: true,
            message: 'Hours is required'
        }), (0, _validators.validateNumber)({
            gt: 0,
            positive: true,
            message: 'Hours should be a number greater than 0'
        })],
        cut: [(0, _validators.validateNumber)({
            gte: 0,
            positive: true,
            message: 'Cut should be a number greater or equal than 0'
        })],
        description: [(0, _validators.validatePresence)({
            presence: true,
            message: 'Description is required'
        })],
        cutDescription: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function (changes, content) {
            return this.get('cut') > 0;
        }),

        budget: [(0, _validators.validatePresence)({
            presence: true,
            message: 'Budget is required'
        })],
        invoiceGroup: [(0, _validators.validatePresence)({
            presence: true,
            message: 'Invoice Group is required'
        })],
        billableType: [(0, _validators.validatePresence)({
            presence: true,
            message: 'Billable Type is required'
        })]
    };
});