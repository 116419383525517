define('tm3/pods/components/documents/table-pager/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reads = Ember.computed.reads,
        alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        groupOptions: reads('pagerParams.groupBy'),
        onGroupChange: reads('pagerParams.onGroupChange'),

        groupLabel: Ember.computed('groupOptions', function () {
            var groupOptions = this.get('groupOptions');
            switch (groupOptions) {
                case 'none':
                    return 'None';
                case 'reportName':
                    return 'Report';
                case 'docType':
                    return 'Doc Type';
                case 'subject':
                    return 'Subject';
                case 'tags':
                    return 'Tags';
                case 'viewable':
                    return 'Published Status';
            }
        }),
        onInitComponent: Ember.on('init', function () {
            if (this.get('toolsParams')) {
                this.set('perPageFormat', this.get('toolsParams.perPageFormat') ? this.get('toolsParams.perPageFormat') : 'select');
                this.set('showAll', this.get('toolsParams.showAll') ? this.get('toolsParams.showAll') : this.get('isInfinite'));
            } else {
                this.set('showAll', this.get('isInfinite'));
            }
        }),
        // properties
        perPageOptions: [50, 100, 250, 'All'],
        perPageAll: 100000000, // 100 million records

        showAll: false,
        perPageFormat: 'select',
        sortOptions: reads('toolsParams.options'),
        sortFieldInitial: alias('toolsParams.initial'),

        // document templates
        docTemplates: reads('contentParams.docTemplates'),
        selectedDocTemplate: reads('contentParams.selectedDocTemplate'),
        user: reads('contentParams.user'),
        onSelectTemplate: reads('contentParams.onSelectTemplate'),
        onConfigureTemplate: reads('contentParams.onConfigureTemplate'),

        addTemplate: function addTemplate() {
            var _this = this;

            var newTemplate = this.get('store').createRecord('document-template', {
                user: this.get('user')
            });

            newTemplate.save().then(function (savedTemplate) {
                _this.sendAction('onConfigureTemplate', savedTemplate.get('id'));
            }, function () {});
        },


        actions: {
            addTemplate: function addTemplate() {
                this.addTemplate();
            },
            editTemplate: function editTemplate() {
                var selectedDocTemplate = this.get('selectedDocTemplate');
                if (selectedDocTemplate) {
                    this.sendAction('onConfigureTemplate', this.get('selectedDocTemplate'));
                } else {
                    this.addTemplate();
                }
            },
            changeSort: function changeSort(newSort) {
                this.attrs.changeSort(newSort.field, newSort.direction);
                this.set('sortFieldInitial', newSort);
            },
            showAllRecords: function showAllRecords() {
                this.set('showAll', true);
                this.attrs.changePerPage(this.get('perPageAll'));
                this.attrs.refresh();
            },
            showByPageRecords: function showByPageRecords() {
                this.set('showAll', false);
                this.attrs.changePerPage(this.get('perPageOptions.firstObject'));
                this.attrs.refresh();
            },
            changePerPage: function changePerPage(nr) {
                if (nr === 'All') {
                    this.send('showAllRecords');
                } else {
                    this.attrs.changePerPage(nr);
                }
            }
        }
    });
});