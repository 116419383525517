define('tm3/components/string-is-truncated', ['exports', 'tm-common/components/string-is-truncated'], function (exports, _stringIsTruncated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stringIsTruncated.default;
    }
  });
});