define('tm3/pods/components/smm/table-tools/component', ['exports', 'ember-cli-table-pagination/components/bs-table-pagination/table-tools'], function (exports, _tableTools) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _tableTools.default.extend({
        // quickSearchField: 'tm_code',
        selectedRows: Ember.computed.reads('toolsParams.selectedRows'),
        statusOptions: ['Completed', 'Pending', 'New', 'Failed', 'Canceled', 'All'],
        selectedStatus: Ember.computed.reads('toolsParams.initialStatus'),

        filterByStatus: function filterByStatus() {
            var allColumns = this.get('allColumns');
            var statusColumn = allColumns.findBy('displayName', 'Status');
            if (statusColumn) {
                statusColumn.set('advFilterOperator', this.get('operators').findBy('value', 'contains'));
                var selectedStatus = this.get('selectedStatus');
                if (selectedStatus === 'All') {
                    statusColumn.set('advFilterValue', null);
                } else {
                    statusColumn.set('advFilterValue', selectedStatus);
                }
            }
            this.send('applyFilter');
        },

        actions: {
            changeFilterStatus: function changeFilterStatus(newStatus) {
                this.set('selectedStatus', newStatus);
                this.filterByStatus();
            }
        }
    });
});