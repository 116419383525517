define('tm3/components/dashboard-case-note', ['exports', 'tm-common/components/dashboard-case-note'], function (exports, _dashboardCaseNote) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dashboardCaseNote.default;
    }
  });
});