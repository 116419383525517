define('tm3/serializers/matter', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            // https://github.com/emberjs/rfcs/pull/57#issuecomment-121008369
            var foreignKey = hash['matter_type_id'];

            if (foreignKey) {
                hash['matter_type_id'] = foreignKey;
            }
            return this._super(typeClass, hash, prop);
        },
        serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
            var _this = this;

            var normalizedRootKey = this.payloadKeyFromModelName(typeClass.modelName);
            var payload = this.serialize(snapshot, options);
            var newValues = snapshot.changedAttributes();
            var attrs = {};
            snapshot.eachAttribute(function (attr, options) {
                attrs[attr] = options;
            });
            var data = {};
            Object.keys(newValues).forEach(function (attribute) {
                var oldSnapshot = {
                    attr: function attr(key) {
                        return newValues[key][0];
                    },
                    type: snapshot.type
                };
                _this.serializeAttribute(oldSnapshot, data, attribute, attrs[attribute]);
            });
            payload['conflict_resolution_changed_attributes'] = data;
            hash[normalizedRootKey] = payload;
        }
    });
});