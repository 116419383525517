define('tm3/controllers/error', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            check401: function check401(message) {
                return message.indexOf('401') >= 0;
            }
        }
    });
});