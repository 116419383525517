define('tm3/components/charts/custom-bar-chart/bar-chart-bar', ['exports', 'tm-common/components/charts/custom-bar-chart/bar-chart-bar'], function (exports, _barChartBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _barChartBar.default;
    }
  });
});