define('tm3/pods/components/admin-fields-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['row', 'matter-field-row', 'link'],

        isAdminPage: false,
        field: null,
        iconList: null,

        actions: {
            clickTitle: function clickTitle() {
                this.sendAction('clickedField', this.get('field'));
            }
        }
    });
});