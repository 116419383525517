define('tm3/pods/accounting/billing/batches/info/summary/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'invoice',

        model: function model(params) {
            var parentModel = this.modelFor('accounting.billing.batches.info');
            var allParams = this.getAllParams(params);
            allParams['invoice_batch_id'] = parentModel.model.id;
            allParams['include_private_tags'] = true;
            this.currentParams = allParams;
            return this.findPaged(this.modelName, allParams);
        },

        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            var parentModel = this.modelFor('accounting.billing.batches.info');
            this.set('controller.invoiceBatch', parentModel.model);
            this.get('controller.model').setEach('isSelected', false);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.send('fetch');
            },
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});