define('tm3/pods/components/model-saved-icon/component', ['exports', 'tm3/pods/components/model-saved-icon/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: '',

    /**
     * @property
     * @public
     *
     * model to show on the UI if was saved or not
     */
    model: null
  });
});