define('tm3/transforms/utcdate', ['exports', 'tm-common/transforms/utcdate'], function (exports, _utcdate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _utcdate.default;
    }
  });
});