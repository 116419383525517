define('tm3/pods/components/report/new-cases-report/by-month/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        'data-test-component': 'new-cases-by-month-report',

        showTotals: Ember.computed.gt('tableData.length', 1),

        totals: Ember.computed('tableData', function () {
            var data = this.get('tableData');

            if (!data || data.length === 0) {
                return [];
            }

            var totals = [];
            var length = data[0].cases_per_month.length;


            for (var i = 0; i < length; i += 1) {
                totals.push(0);
            }

            data.forEach(function (item) {
                var i = 0;

                item.cases_per_month.forEach(function (cases) {
                    totals[i] += cases.num;
                    i += 1;
                });
            });

            return totals;
        })
    });
});