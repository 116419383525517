define('tm3/components/power-select-multiple', ['exports', 'ember-power-select/components/power-select-multiple'], function (exports, _powerSelectMultiple) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _powerSelectMultiple.default.extend({
        // Place here your system-wide preferences
        searchEnabled: false,
        allowClear: true,
        placeholder: 'Please select an option'
    });
});