define('tm3/pods/accounting/billing/tax-rules/add/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Time & Expense'],

        beforeModel: function beforeModel() {
            this.transitionTo('accounting.billing.tax-rules.form', 'new');
        }
    });
});