define('tm3/pods/crm/accounts/edit/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        actions: {
            save: function save() {
                var self = this;
                var model = this.get('model');

                model.save().then(function (data) {
                    self.get('notify').success('Account saved!');
                    self.transitionToRoute('crm.accounts.info', model.id);
                }, function (reason) {
                    if (Ember.isPresent(reason.errors)) {
                        var details = reason.errors[0].additional_info.details;
                        if (Ember.isPresent(details)) {
                            self.get('notify').error(details);
                        }
                    }
                    self.validationReport(model);
                });
            }
        }
    });
});