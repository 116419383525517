define('tm3/mixins/subject-import', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        // will this subject be added or updated against an existing record
        mode: 0,

        // the id of the existing subject record
        id: 0,

        // the field data to be imported
        // this object will use the names that matche real subject properties
        data: {},

        // what was the final result when attempting to import this record
        result: '',

        // errors generated when attempting to process the import record
        errors: ''
    });
});