define('tm3/pods/matter/admin/sync-tm-data/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requireAnyPermission: ['Manage All Cases', 'Edit Case'],

        session: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        model: function model(params) {
            var matterId = this.get('currentMatter.id');
            return Ember.RSVP.hash({
                matter: this.store.findRecord('matter', matterId)
            });
        },


        actions: {
            willTransition: function willTransition(transition) {
                var target = transition.targetName;
                if (target !== 'matter.admin.sync-tm-data') {
                    this.controller.set('visibility', '');
                    this.controller.set('syncURL', '');
                }
            }
        }
    });
});