define('tm3/pods/components/matter/db-memos/tag-link/component', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        stateCategory: Ember.computed('ENV', function () {
            return _environment.default.APP.tags.stateCategory;
        })
    });
});