define('tm3/pods/components/custom-table-pagination/matter-private-tag-names-cell/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        tagName: 'div',

        valueAsList: Ember.computed('value', function () {
            var value = this.get('value');
            return value ? value.split(', ') : [];
        })
    });
});