define('tm3/pods/crm/todos/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        // indicate that when this query param changes, refresh the route
        queryParams: {
            assigned_to_id: {
                refreshModel: true
            },
            archived: {
                refreshModel: true
            },
            categories: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var currentCategories = [];
            params.with = 'employees,clients,todo_categories';
            params.sortField = 'due_on';

            if (params.archived) {
                params.archived = 1;
            } else {
                params.archived = 0;
            }

            if (params.assigned_to_id === 'all') {
                delete params.assigned_to_id;
            } else if (params.assigned_to_id === 'me') {
                params.assigned_to_id = this.get('session.data.authenticated.id');
            }

            params.todo_category_id = params.categories;
            currentCategories = params.categories;
            delete params.categories;

            return Ember.RSVP.hash({
                todos: this.store.query('todo', params),
                employees: this.store.query('employee', {
                    status: 'Active',
                    sort: 'last_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer']
                }),
                todoCategories: this.store.query('todo-category', { sort: 'id' }),
                newTodo: this.store.createRecord('todo', { priority: 'Normal' }),
                commonTodo: this.store.createRecord('todo', { priority: 'Normal' }),
                categories: currentCategories,
                assigned_to_id: params.assigned_to_id
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.todos);
            controller.set('employees', resolved.employees);
            controller.set('todoCategories', resolved.todoCategories);
            controller.set('newtodo', resolved.newTodo);
            controller.set('commonTodo', resolved.commonTodo);
            controller.send('clearSelection');

            var selectedCategories = [];
            resolved.categories.forEach(function (cId) {
                var category = resolved.todoCategories.findBy('id', cId);
                selectedCategories.pushObject(category);
            });
            controller.set('selectedCategories', selectedCategories);
            controller.set('assigned_to_id', resolved.assigned_to_id === undefined ? 'all' : resolved.assigned_to_id);
            controller.set('assignedTo', resolved.employees.findBy('id', resolved.assigned_to_id));
        },


        actions: {
            foobar: function foobar() {
                this.refresh();
            },
            saveNew: function saveNew() {
                var self = this;
                var controller = this.controllerFor('crm.todos');
                var todo = controller.get('newTodo');

                todo.save().then(function (data) {
                    var deal = controller.get('relatedDeal');
                    if (typeof deal !== 'undefined' && deal.get('id') !== '') {
                        var formData = {
                            todo: todo,
                            deal: deal
                        };
                        var relatedDeal = self.store.createRecord('deal-has-todo', formData);
                        relatedDeal.save();
                    }

                    self.set('relatedDeal', undefined);
                    self.get('notify').success('Todo Saved!');
                    controller.send('closeTodoForm');
                    self.refresh();
                }, function (reason) {
                    self.validationReport(todo);
                });
            },
            delete: function _delete(todo) {
                var controller = this.controllerFor('crm.todos');
                todo.destroyRecord();
                controller.get('model').removeObject(todo);
                this.get('notify').success('Todo Removed!');
            },
            archive: function archive(todo) {
                var controller = this.controllerFor('crm.todos');
                controller.get('model').removeObject(todo);
                todo.set('archived', 1);
                var self = this;

                todo.save().then(function (data) {
                    self.get('notify').success('Todo Archived!');
                }, function (reason) {
                    self.validationReport(todo);
                });
            },
            saveAll: function saveAll() {
                var self = this;
                var controller = this.controllerFor('crm.todos');
                Ember.run(function () {
                    controller.set('isSavingCommon', true);
                });
                var todos = controller.get('model');
                var promises = Ember.A();
                var commonTodo = controller.get('commonTodo');
                var commonData = commonTodo.toJSON();
                todos.filterBy('isSelected', true).forEach(function (todo) {
                    if (Ember.isPresent(commonData.dueOn)) {
                        todo.set('dueOn', commonData.dueOn);
                    }
                    if (Ember.isPresent(commonData.todoCategory)) {
                        todo.set('todoCategory', commonTodo.get('todoCategory'));
                    }
                    if (Ember.isPresent(commonData.assignedTo)) {
                        todo.set('assignedTo', commonTodo.get('assignedTo'));
                    }
                    if (Ember.isPresent(commonData.priority)) {
                        todo.set('priority', commonData.priority);
                    }
                    promises.pushObject(todo.save().then(function (data) {}, function (reason) {
                        self.validationReport(todo);
                    }));
                });
                Ember.RSVP.all(promises).then(function () {
                    controller.set('isSavingCommon', false);
                    self.get('notify').success('Todos Saved!');
                    controller.send('clearSelection');
                    controller.send('closeCommonForm');
                    self.refresh();
                });
            }
        }
    });
});