define('tm3/pods/matter/admin/doc-reports/controller', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        reports: Ember.computed.alias('model.reports'),
        reportTypes: Ember.computed.alias('model.reportTypes'),

        newReport: null,

        actions: {
            newReport: function newReport() {
                this.set('newReport', this.get('store').createRecord('report', {
                    start: (0, _moment.default)().format('YYYY-MM-DD'),
                    isEditing: true
                }));
            }
        }
    });
});