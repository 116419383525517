define('tm3/pods/accounting/billing/manual-expense/edit/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        notify: Ember.inject.service(),
        requiredPermissions: ['Manage Manual Expenses'],

        model: function model(params) {
            return Ember.RSVP.hash({
                billables: this.store.query('billable', {
                    billable_group_id: params.billable_group_id,
                    'billable_groups:type': 'Manual',
                    with: 'billable_groups,invoice_groups,budgets,matters,billable_types'
                }),
                billableGroup: this.store.findRecord('billable-group', params.billable_group_id),
                billableTypes: this.store.findAll('billable-type'),
                newExpenses: [],
                billing_phrases: this.store.query('phrase', {
                    with: 'phrase_case_type_group,phrase_case_type_group_matter_type',
                    phrase_type: 'billing',
                    soft_deleted: 0,
                    sort: 'billable_type_id,order,name,content'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.resetMassInputs();
            controller.send('expenseTypeChanged', resolved.billables.get('firstObject.billableType'));;
        },
        validateExpense: function validateExpense(expense) {
            var valid = true;
            if (!Ember.isPresent(expense.get('billableType'))) {
                valid = false;
                this.get('notify').error('Each expense in the expense list must have a billable Type selected');
            }
            if (!Ember.isPresent(expense.get('matter'))) {
                valid = false;
                this.get('notify').error('Each expense in the expense list must have a matter selected');
            }

            if (!Ember.isPresent(expense.get('invoiceGroup'))) {
                valid = false;
                this.get('notify').error('Each expense in the expense list must have an invoice group');
            }
            return valid;
        },


        actions: {
            /**
             * save all the data related to the billable-group
             */
            save: function save() {
                var _this = this;

                var billableGroup = this.controller.get('model.billableGroup');
                var expenses = this.controller.get('model.billables');
                var newExpenses = this.controller.get('model.newExpenses');

                var valid = true;
                newExpenses.forEach(function (expense) {
                    valid = valid && _this.validateExpense(expense);
                });
                expenses.forEach(function (expense) {
                    valid = valid && _this.validateExpense(expense);
                });

                if (valid) {
                    var promises = [];

                    billableGroup.save().then(function (result) {
                        expenses.forEach(function (billable) {
                            if (!billable.get('billed')) {
                                promises.pushObject(billable.save().catch(function (error) {
                                    _this.validationReport(billable);
                                    throw error;
                                }));
                            }
                        });

                        newExpenses.forEach(function (expense) {
                            var billable = _this.store.createRecord('billable', {
                                cut: expense.get('cut'),
                                qty: expense.get('qty'),
                                rate: expense.get('rate'),
                                billType: 'Manual',
                                billableGroup: result,
                                billableType: expense.get('billableType'),
                                expense: true,
                                description: expense.get('description'),
                                billed: expense.get('billed'),
                                matter: expense.get('matter'),
                                budget: expense.get('budget'),
                                invoiceGroup: expense.get('invoiceGroup')
                            });

                            promises.pushObject(billable.save().catch(function (error) {
                                _this.validationReport(billable);
                                throw error;
                            }));
                        });

                        Ember.RSVP.all(promises).then(function () {
                            _this.transitionTo('accounting.billing.manual-expense.list');
                            _this.get('notify').success('Successfully saved');
                        }).catch(function () {
                            _this.get('notify').error('Something was wrong while saving the data');
                        });
                    }).catch(function () {
                        _this.validationReport(billableGroup);
                    });
                }
            },


            /**
             * cancel the modifications
             */
            cancel: function cancel() {
                this.controller.get('model.billableGroup').rollbackAttributes();
                this.transitionTo('matter.billable-groups.info', this.controller.get('model.billableGroup.id'));
            },


            /**
             * destroy an expense record from the list
             */
            removeExpense: function removeExpense(expense) {
                var _this2 = this;

                expense.destroyRecord().then(function () {
                    _this2.get('notify').success('Expense was removed');
                });
            },


            /**
             * destroy an expense record from the list
             */
            removeNewExpense: function removeNewExpense(expense) {
                var expenses = this.get('controller.model.newExpenses');
                expenses.removeObject(expense);
            }
        }
    });
});