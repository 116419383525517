define('tm3/pods/components/subjects/info/db-memos/subject-selection/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        filter: '',
        subjectsExpanded: false,
        subjectSelectionDisabled: false,

        filteredSubjects: Ember.computed('subjects', 'filter', function () {
            var filter = this.get('filter');

            if (!filter) {
                return this.get('subjects');
            }

            return this.get('subjects').filter(function (s) {
                var fieldToFilter = s.get('fullName');

                if (Ember.isEmpty(fieldToFilter)) {
                    fieldToFilter = '';
                }

                return fieldToFilter.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        }),

        checkedSubjectsObserver: Ember.observer('subjects.@each.isChecked', function () {
            this.set('checkedSubjects', this.get('subjects').filter(function (s) {
                return s.get('isChecked');
            }));
        }),

        allSubjectsAreSelected: Ember.computed('filteredSubjects.@each.isChecked', function () {
            return this.get('filteredSubjects').isEvery('isChecked', true);
        }),

        actions: {
            setAllSubjectsCheck: function setAllSubjectsCheck(value) {
                this.get('filteredSubjects').forEach(function (subject) {
                    subject.set('isChecked', value);
                });
            }
        }
    });
});