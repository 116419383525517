define('tm3/mixins/travel-expense-route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Mixin.create(_error.default, {
        queryParams: {
            matterId: {
                refreshModel: true
            },
            billable_type: {
                refreshModel: true
            }
        },
        notify: Ember.inject.service(),
        goBack: Ember.inject.service(),
        goBackOptions: {},
        afterSaveTravelExpenseRoute: 'me.travel.list',
        checkIfBilled: true,

        model: function model(params) {
            var currentUserId = this.get('session.data.authenticated.id');
            if (Ember.isEmpty(params.billable_type)) {
                params.billable_type = 'Travel';
            }
            return Ember.RSVP.hash({
                billableGroup: this.store.queryRecord('billable-group', {
                    id: params.billable_group_id,
                    with: 'billables,budgets,receipts,invoice_groups,charges,matters,travel_expense_has_files'
                }),
                expenseTypes: this.store.query('billable-type', {
                    report: 1,
                    sort: 'id'
                }),
                charges: params.billable_group_id ? this.store.query('charge', {
                    billable_group_id: params.billable_group_id,
                    with: 'billable_types',
                    sort: 'date,billable_type_id'
                }) : null,
                deductions: params.billable_group_id ? this.store.query('deduction', {
                    billable_group_id: params.billable_group_id,
                    with: 'billable_types'
                }) : [],
                invoiceGroups: this.store.query('invoice-group', {
                    matter_id: params.matterId
                }),
                budgets: this.store.query('budget', {
                    matter_id: params.matterId,
                    enabled: 1
                }),
                matter: params.matterId ? this.store.queryRecord('matter', {
                    id: params.matterId
                }) : null,
                billableGroupId: params.billable_group_id,
                billableGroupType: params.billable_type,
                currentUserId: currentUserId
            });
        },
        afterModel: function afterModel(model) {
            if (this.get('checkIfBilled') && model.billableGroup && model.billableGroup.get('approvalStatus') === 'Billed') {
                this.transitionTo(this.get('afterSaveTravelExpenseRoute'));
            }
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);

            // if (isEmpty(resolved.billableGroup)) {
            //   this.transitionTo('accounting.billing.travel-expenses')
            // }
        },
        destroyUnsavedExpenses: function destroyUnsavedExpenses() {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                var promises = [];
                var billableGroup = _this.get('billableGroup');
                if (billableGroup) {
                    billableGroup.get('billables').forEach(function (billable) {
                        return billable ? billable.rollbackAttributes() : null;
                    });
                    billableGroup.get('billables').filterBy('isNew', true).forEach(function (billable) {
                        promises.pushObject(billable.unloadRecord());
                    });
                    resolve(Ember.RSVP.all(promises));
                } else {
                    resolve();
                }
            });
        },
        cleanBillableGroupsState: function cleanBillableGroupsState() {
            // rollback changes for billableGroup include_for_travel
            this.store.peekAll('billable-group').invoke('rollbackAttributes');
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            saveTravelExpense: function saveTravelExpense() {
                var _this2 = this;

                var stay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

                this.controller.set('isSavingExpenses', stay ? 'continue' : 'exit');
                var chargesByExpenseType = this.controller.get('chargesByExpenseType');
                var checkedMatters = this.controller.get('getAvailableMattersTask.lastSuccessful.value').filterBy('isChecked', true); // list of matterProxies
                var checkedMattersIds = checkedMatters.mapBy('id');

                this.get('store').query('expense-report-has-matter', {
                    billable_group_id: this.controller.get('model.billableGroup.id'),
                    with: 'matters,budgets'
                }).then(function (erhms) {
                    var expenseReportHasMatters = erhms;
                    var oldCharges = _this2.controller.get('charges');
                    var oldSelectedMattersIds = expenseReportHasMatters.mapBy('matter.id');
                    var billableGroup = _this2.controller.get('model.billableGroup');
                    var promises = [];
                    var deductionPromises = [];
                    var deletePromises = [];
                    var total = 0;

                    if (checkedMatters.get('length') > 0) {
                        var anyBillableGroupSelected = checkedMatters.any(function (m) {
                            return m.get('content.billableGroups').any(function (bg) {
                                return bg.get('includeForTravel');
                            });
                        });

                        if (!anyBillableGroupSelected) {
                            _this2.get('notify').error('Please select at least one time entry.');
                            _this2.controller.set('isSavingExpenses', false);
                            return;
                        }

                        var allCharges = [].concat.apply([], chargesByExpenseType.mapBy('charges'));
                        allCharges.forEach(function (item) {
                            if (item.get('rate') <= 0) {
                                item.set('rate', null);
                            }
                        });
                        var allEffectiveCharges = allCharges.filter(function (item) {
                            return item.hasEffectiveValues();
                        });

                        if (allEffectiveCharges.length === 0) {
                            _this2.get('notify').error('Please enter at least one charge.');
                            _this2.controller.set('isSavingExpenses', false);
                            return;
                        }

                        var allChargesIds = allEffectiveCharges.mapBy('id');

                        oldCharges.forEach(function (c) {
                            if (!allChargesIds.includes(c.get('id'))) {
                                deletePromises.push(c.destroyRecord());
                            }
                        });

                        // delete unused expense-report-has-matter objects
                        expenseReportHasMatters.forEach(function (erhm) {
                            if (!checkedMattersIds.includes(erhm.get('matter.id'))) {
                                promises.pushObject(erhm.destroyRecord());
                            } else {
                                // delete expense-report-has-matter if user deselected all time entries for a checked matter
                                var checkedMatter = checkedMatters.findBy('id', erhm.get('matter.id'));
                                var includedForTravel = checkedMatter.get('content.billableGroups').any(function (bg) {
                                    return bg.get('includeForTravel');
                                });
                                if (!includedForTravel) {
                                    promises.pushObject(erhm.destroyRecord());
                                }
                            }
                        });

                        Ember.RSVP.all(deletePromises).then(function () {
                            allEffectiveCharges.forEach(function (charge) {
                                if (charge.get('isNew')) {
                                    var chargeDate = charge.get('date');
                                    charge.set('date', chargeDate.format('YYYY-MM-DD'));
                                    charge.set('billableGroup', billableGroup);
                                    charge.set('type', 'Expense Report');
                                    charge.set('cut', '0.0000');
                                }
                                charge.finalizeTotal();

                                total += parseFloat(charge.get('total'));

                                if (charge.get('hasDirtyAttributes')) {
                                    promises.pushObject(charge.save());
                                }
                            });

                            billableGroup.set('total', total);
                            billableGroup.normalizeDates();

                            promises.pushObject(billableGroup.save().catch(function (e) {
                                _this2.validationReport(billableGroup);
                                throw e;
                            }));

                            checkedMatters.forEach(function (m) {
                                if (!oldSelectedMattersIds.includes(m.get('id'))) {
                                    // tie only matters that have at least one selected time entry (included for travel)
                                    var includedForTravel = m.get('content.billableGroups').any(function (bg) {
                                        return bg.get('includeForTravel');
                                    });
                                    if (includedForTravel) {
                                        var erhmObj = {
                                            billableGroup: billableGroup,
                                            matter: m.get('content'),
                                            budget: m.get('budget')
                                        };
                                        var expenseHasMatter = _this2.store.createRecord('expense-report-has-matter', erhmObj);
                                        promises.pushObject(expenseHasMatter.save());
                                    }
                                } else {
                                    // check if budget changed
                                    var oldERHM = expenseReportHasMatters.findBy('matter.id');
                                    if (oldERHM.get('budget.id') !== m.get('budget.id')) {
                                        oldERHM.set('budget', m.get('budget'));
                                        promises.push(oldERHM.save());
                                    }
                                }
                                // save billableGroups include_for_travel changes
                                m.get('content.billableGroups').forEach(function (bg) {
                                    if (bg.get('hasDirtyAttributes')) {
                                        promises.push(bg.save());
                                    }
                                });
                            });

                            Ember.RSVP.all(promises).then(function () {
                                _this2.controller.get('deductions').forEach(function (deduction) {
                                    deduction.set('billableGroup', billableGroup);
                                    deductionPromises.push(deduction.save().catch(function (e) {
                                        _this2.validationReport(deduction);
                                        throw e;
                                    }));
                                });
                                Ember.RSVP.all(deductionPromises).then(function () {
                                    _this2.get('notify').success('Expenses were saved successfully');
                                    if (stay) {
                                        _this2.send('refresh');
                                    } else {
                                        if (!_this2.get('goBack').back(_this2.get('goBackOptions'))) {
                                            _this2.transitionTo(_this2.get('afterSaveTravelExpenseRoute'));
                                        }
                                    }
                                }).catch(function () {
                                    _this2.get('notify').error('An error occurred trying to save deductions');
                                });
                            }).catch(function () {
                                _this2.get('notify').error('An error occurred trying to save the expenses');
                            }).finally(function () {
                                _this2.cleanBillableGroupsState();
                                _this2.controller.set('isSavingExpenses', false);
                                _this2.controller.set('isEditing', false);
                            });
                        });
                    } else {
                        _this2.get('notify').error('There must be at least one Selected Case for which you have Time Entries within the Date Range you have selected.');
                        _this2.controller.set('isSavingExpenses', false);
                    }
                });
            },
            cancel: function cancel() {
                var _this3 = this;

                var stay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

                var billableGroup = this.controller.get('model.billableGroup');
                if (billableGroup) {
                    billableGroup.rollbackAttributes();
                }
                var deductions = this.controller.get('deductions');
                deductions.forEach(function (deduction) {
                    deduction.rollbackAttributes();
                });
                this.cleanBillableGroupsState();
                this.destroyUnsavedExpenses().then(function () {
                    if (!stay && !_this3.get('goBack').back(_this3.get('goBackOptions'))) {
                        _this3.transitionTo(_this3.get('afterSaveTravelExpenseRoute'));
                    }
                });
            },
            goToRoute: function goToRoute(routeName, params) {
                if (params) {
                    this.transitionTo.apply(this, [routeName].concat(_toConsumableArray(params)));
                } else {
                    this.transitionTo(routeName);
                }
            }
        }
    });
});