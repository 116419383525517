define('tm3/pods/components/non-null-money/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        value: null,
        numericValue: Ember.computed('value', function () {
            var value = this.get('value');
            if (value && typeof value === 'string') {
                return parseFloat(value);
            }
            return value;
        })
    });
});