define('tm3/helpers/group-set', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.groupSet = groupSet;


    var currentGroup = 'empty-group-as-initial-value';

    function groupSet(params /*, hash */) {
        var groupBy = params[1];
        var newVal = groupBy === 'viewable' ? params[0] === 0 ? 'Private' : 'Public' : params[0];
        var style = '';
        if (currentGroup !== newVal) {
            currentGroup = newVal;
            if (newVal === undefined || newVal === null || newVal === '') {
                newVal = 'None';
                style = 'color: #ab731b; font-weight: bold;';
            }
            return Ember.String.htmlSafe('<h1 style="' + style + '">' + newVal + '</h1>');
        } else {
            return '';
        }
    }

    exports.default = Ember.Helper.helper(groupSet);
});