define('tm3/pods/components/matter/add-case-to-client-collection/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set('selectedMatterHash', this.get('selectedMatters'));
            this._performSearchCollections('', function (options) {
                _this.set('clientCollections', options);
            }, function () {});
        },


        actions: {
            searchClientCollections: function searchClientCollections(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchCollections, term, resolve, reject, 600);
                });
            },

            /** power-select for deal */
            searchMatters: function searchMatters(term) {
                var _this3 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this3, _this3._performSearchMatters, term, resolve, reject, 600);
                });
            },
            clearInput: function clearInput() {
                this.set('selectedMatterHash', []);
                this.set('selectedClientCollection', null);
            },
            saveCases: function saveCases(matterHash, collection) {
                var _this4 = this;

                if (!matterHash.length || !collection) {
                    this.get('notify').error('One collection and at least one case must be selected');
                }

                matterHash.setEach('clientCollection', collection);
                Ember.RSVP.all(matterHash.map(function (m) {
                    return m.save();
                })).then(function () {
                    _this4.send('clearInput');
                    _this4.sendAction('afterSave');
                }, function (error) {
                    _this4.handleXHR(error);
                });
            },
            cancel: function cancel() {
                this.send('clearInput');
                this.sendAction('afterCancel');
            },
            createClientCollection: function createClientCollection(select, e) {
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
                    if (select.searchText.length > 200) {
                        this.get('notify').error('The client collection name must be less than 200 characters');
                    } else {
                        // to not call submit
                        e.preventDefault();
                        // to avoid calling this twice
                        e.stopPropagation();

                        // Add to the list of client collections
                        this.get('store').createRecord('client-collection', {
                            name: select.searchText
                        }).save().then(function (cc) {
                            select.options = [cc];
                            select.actions.select(cc);
                        });

                        select.actions.close(e);
                    }
                }
            }
        },
        _performSearchCollections: function _performSearchCollections(term, resolve, reject) {
            return this.get('store').query('client-collection', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        },
        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            return this.get('store').query('matter', {
                'name||plaintiff||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});