define('tm3/pods/matter/subjects/new/controller', ['exports', 'tm-common/mixins/crud/error', 'tm3/mixins/related-subject-table'], function (exports, _error, _relatedSubjectTable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, _relatedSubjectTable.default, {
        queryParams: ['redirectTo'],
        redirectTo: 'info',
        notify: Ember.inject.service(),
        goBack: Ember.inject.service(),
        sortByMatterFields: ['weight'],
        // the argument of this property comes from the related-subject-table mixin
        sortedMatterFields: Ember.computed.sort('matterFieldsFilteredSubjectTypeFields', 'sortByMatterFields'),

        reRender: true,

        actions: {
            cancelAdd: function cancelAdd() {
                this.get('goBack').toPreviousOrigin();
            },
            save: function save() {
                var _this = this;

                var subject = this.get('model');
                subject.set('matter', this.get('matter'));
                subject.save().then(function () {
                    _this.get('notify').success('Subject information saved');
                    var redirectTo = _this.get('redirectTo');
                    if (redirectTo === 'back') {
                        _this.get('goBack').toPreviousOrigin();
                    } else {
                        _this.transitionToRoute('matter.subjects.info', subject.get('id'));
                    }
                }, function () {
                    _this.validationReport(subject);
                });
            },
            saveAndAddAnother: function saveAndAddAnother() {
                var _this2 = this;

                var subject = this.get('model');
                subject.set('matter', this.get('matter'));
                subject.save().then(function () {
                    _this2.get('notify').success('Subject information saved');
                    _this2.set('model', _this2.store.createRecord('subject'));
                    _this2.set('reRender', false);
                    Ember.run.next(function () {
                        _this2.set('reRender', true);
                    });
                }, function () {
                    _this2.validationReport(subject);
                });
            },
            autoSave: function autoSave(model, field, value) {
                // don nothing
                return true;
            }
        }
    });
});