define('tm3/pods/crm/contacts/info/activity/controller', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // support add note comp
        isAdding: false,
        noteCategories: [],
        tickleObserver: 0,
        noteList: [],
        resortNotes: Ember.observer('model', 'tickleObserver', function () {
            var model = this.get('model');
            var noteList = model.sort(function (a, b) {
                var timeA = (0, _moment.default)(a.get('crmNote').get('createdOn'));
                var timeB = (0, _moment.default)(b.get('crmNote').get('createdOn'));

                if (timeA.isSame(timeB)) {
                    return 0;
                } else if (timeA.isAfter(timeB)) {
                    return -1;
                } else {
                    return 1;
                }
            });
            this.set('noteList', noteList);
        }),

        actions: {
            // support add note comp
            addNote: function addNote() {
                this.set('isAdding', true);
            }
        }
    });
});