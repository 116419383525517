define('tm3/pods/matter/report-wizard/controller', ['exports', 'moment', 'sanitize-filename'], function (exports, _moment, _sanitizeFilename) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        reportFileName: Ember.computed('model', function () {
            var matterName = this.get('model.name');
            var date = (0, _moment.default)().format('MM-DD-YYYY');

            return (0, _sanitizeFilename.default)(matterName + ' - SmithCarson Subject Profiles - ' + date);
        }),

        actions: {
            previewDocument: function previewDocument(docId) {
                this.transitionToRoute('preview', docId);
            }
        }
    });
});