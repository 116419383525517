define('tm3/pods/crm/contacts/info/email-logs/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentRoute: 'crm.contacts.info.email-logs',

        modelName: 'email-log',

        model: function model() {
            return {};
        },
        setupController: function setupController(controller, model) {
            var clientsInfoModel = this.modelFor('crm.contacts.info');
            var client = clientsInfoModel.model.get('firstObject');
            var clientId = client.get('id');

            controller.set('loadDataParams', { recipient: clientId });

            this._super(controller, model);
        }
    });
});