define('tm3/pods/components/custom-table-pagination/accounting/billing/invoices/list/table-content/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['ember-cli-table-content'],
        // tagName: '',

        showFilter: false,

        customModel: Ember.computed.reads('contentParams.customModel'),
        columns: Ember.computed.reads('contentParams.columns'),
        sorting: Ember.computed.reads('contentParams.sorting'),
        sortingDirection: Ember.computed.reads('contentParams.sortDirection'),
        loading: Ember.computed.reads('contentParams.loading'),
        numberOfRecords: Ember.computed.reads('contentParams.numberOfRecords')
    });
});