define('tm3/pods/components/table-filter-components/case-type/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        onInit: Ember.on('init', function () {
            var _this = this;

            this.get('store').query('matter-type', {
                sort: 'name',
                active: '1'
            }).then(function (matterTypes) {
                _this.set('caseTypes', matterTypes.map(function (mt) {
                    return mt.get('name');
                }));
            });
        })
    });
});