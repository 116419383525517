define('tm3/pods/components/matter/workflows/assign-multiple/component', ['exports', 'tm3/pods/components/matter/workflows/assign-multiple/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reads = Ember.computed.reads;
    exports.default = Ember.Component.extend({
        layout: _template.default,
        notify: Ember.inject.service(),

        selectedRows: reads('titleParams.selectedRows'),

        fieldsToEdit: Ember.Object.create(),

        showCommentColumn: Ember.computed('activeWorkflows.[]', function () {
            var show = false;
            var activeWorkflows = this.get('activeWorkflows');
            if (activeWorkflows) {
                activeWorkflows.forEach(function (aw) {
                    if (aw.get('comment') !== false) {
                        show = true;
                    }
                });
            }
            return show;
        }),
        showHoursColumn: Ember.computed('activeWorkflows.[]', function () {
            var show = false;
            var activeWorkflows = this.get('activeWorkflows');
            if (activeWorkflows) {
                activeWorkflows.forEach(function (aw) {
                    if (aw.get('hours') !== false) {
                        show = true;
                    }
                });
            }
            return show;
        }),
        showDeadlineColumn: Ember.computed('activeWorkflows.[]', function () {
            var show = false;
            var activeWorkflows = this.get('activeWorkflows');
            if (activeWorkflows) {
                activeWorkflows.forEach(function (aw) {
                    if (aw.get('deadline') !== false) {
                        show = true;
                    }
                });
            }
            return show;
        }),

        selectedJuryLocation: null,

        initComponent: Ember.on('init', function () {
            this.set('selectedJuryLocation', Ember.Object.create({
                id: 'no_change',
                value: 'no_change',
                display: 'No Change'
            }));
        }),

        actions: {
            toggleAssignMultipleModal: function toggleAssignMultipleModal() {
                var selectedRows = this.get('selectedRows');

                if (selectedRows.length > 0) {
                    Ember.$('#assignMultipleModal').modal('toggle');
                } else {
                    this.get('notify').error('You must select at least one row.');
                }
            }
        }
    });
});