define('tm3/helpers/min-date', ['exports', 'tm-common/helpers/min-date'], function (exports, _minDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _minDate.default;
    }
  });
  Object.defineProperty(exports, 'minDate', {
    enumerable: true,
    get: function () {
      return _minDate.minDate;
    }
  });
});