define('tm3/pods/components/report/unpaid-invoice-outputs/component', ['exports', 'tm-common/helpers/nl-2br'], function (exports, _nl2br) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        click: function click(event) {
            if (event.target.hasAttribute('data-invoice') || event.target.parentElement.hasAttribute('data-invoice')) {
                var invoiceId = event.target.getAttribute('data-invoice') || event.target.parentElement.getAttribute('data-invoice');
                this.sendAction('goToInvoice', invoiceId);
            }
        },


        actions: {
            editArNote: function editArNote(ioId) {
                var _this = this;

                this.get('store').findRecord('invoice-output', ioId).then(function (invoiceOutput) {
                    _this.set('editingNote', invoiceOutput);
                });
            },
            saveNote: function saveNote() {
                var _this2 = this;

                var invoiceOutput = this.get('editingNote');
                invoiceOutput.save().then(function () {
                    _this2.set('editingNote', null);
                    $('[data-invoice-output-id=' + invoiceOutput.id + ']').replaceWith((0, _nl2br.nl2br)([invoiceOutput.get('arNote')]).string);
                    _this2.get('notify').success('Note saved');
                });
            }
        }
    });
});