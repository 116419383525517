define('tm3/helpers/max-date', ['exports', 'tm-common/helpers/max-date'], function (exports, _maxDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _maxDate.default;
    }
  });
  Object.defineProperty(exports, 'maxDate', {
    enumerable: true,
    get: function () {
      return _maxDate.maxDate;
    }
  });
});