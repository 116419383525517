define('tm3/pods/components/matter/subjects/new/profile-data-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        reRender: true,

        actions: {
            usePhrase: function usePhrase(phrase) {
                var fieldPath = 'model.' + this.get('item.name');
                var breaks = '';
                for (var i = 0; i <= phrase.get('breaks'); i++) {
                    breaks += '\n';
                }
                this.set('reRender', false);
                var previousVal = this.get(fieldPath);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));
                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            }
        }
    });
});