define('tm3/components/table-pagination/table-content/component', ['exports', 'ember-cli-table-pagination/components/table-pagination/table-content'], function (exports, _tableContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tableContent.default;
    }
  });
});