define('tm3/pods/preview/by-subject/subject-events/route', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment'], function (exports, _error, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        subjectId: null,
        queryParams: {
            matterId: {
                refreshModel: false
            }
        },
        lists: Ember.inject.service(),
        model: function model(params, transition) {
            var _this = this;

            if (params.subject_id) {
                this.set('subjectId', params.subject_id);
            }
            this.set('documentId', transition.params.preview.document_id);
            var subjectPromise = this.store.queryRecord('subject', {
                resource_id: this.get('subjectId'),
                with: 'timeline_events'
            });
            return subjectPromise.then(function (subject) {
                var matter = _this.modelFor('preview.by-subject').matter;
                return Ember.RSVP.hash({
                    documentId: _this.get('documentId'),
                    subjectId: _this.get('subjectId'),
                    subject: subject,
                    events: _this.store.query('timeline-event', {
                        resource_id: subject.get('timelineEvents').map(function (e) {
                            return e.get('id');
                        }),
                        with: 'documents'
                    }).then(function (events) {
                        return events.sortBy('sortableDate');
                    }),
                    eventTypes: _this.store.findAll('timeline-event-type', { backgroundReload: false }),
                    timelineEventParents: _this.store.findAll('timeline-event-parent', { backgroundReload: false }),
                    matter: matter,
                    subjectsInCase: _this.modelFor('preview.by-subject').subjectsInCase,
                    emptyEvent: {},
                    consolidatedTags: _this.store.query('db-tag', {
                        parent_id: [_environment.default.APP.tags.stateCategory, _environment.default.APP.tags.countryCategory, 'NULL']
                    }),
                    documentTypes: _this.get('lists').getListForGroup('jury_doc_type_tm')
                }).then(function (hash) {
                    hash.states = hash.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.stateCategory);
                    hash.countries = hash.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.countryCategory);

                    delete hash.consolidatedTags;

                    return hash;
                });
            });
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});