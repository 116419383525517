define('tm3/pods/client-collections/controller', ['exports', 'tm3/mixins/with-full-height-pages', 'tm-common/mixins/crud/error'], function (exports, _withFullHeightPages, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_withFullHeightPages.default, _error.default, {
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        queryParams: ['collectionSelected'],
        collectionSelected: null,

        mainCase: null,
        thing: null,

        _fullHeightPages: ['client-collections'],

        selectedCases: Ember.computed('model.cases.@each.isSelected', 'mainCase.id', function () {
            return this.get('model.cases').filterBy('isSelected').rejectBy('id', this.get('mainCase.id'));
        }),

        distinctDefendant: Ember.computed('model.cases.@each.defendant', function () {
            return this.get('model.cases').mapBy('defendant').uniq().sort().join(',  ');
        }),
        totalBudget: Ember.computed('model.cases.@each.calcBudgetTotal', function () {
            return this.get('model.cases').reduce(function (acc, c) {
                return acc + c.get('calcBudgetTotal');
            }, 0);
        }),
        totalInvoiced: Ember.computed('model.cases.@each.calcInvoiced', function () {
            return this.get('model.cases').reduce(function (acc, c) {
                return acc + c.get('calcInvoiced');
            }, 0);
        }),

        observesSelected: Ember.observer('selected', function () {
            if (this.get('selected')) {
                this.set('collectionSelected', this.get('selected.id'));
            } else {
                this.set('collectionSelected', null);
            }
        }),
        observesCheckAll: Ember.observer('checkAll', function () {
            this.get('model.cases').setEach('isSelected', this.get('checkAll'));
        }),

        actions: {
            confirmCopyDataFromMainCaseToSelected: function confirmCopyDataFromMainCaseToSelected(typeOfData) {
                this.set('thing', typeOfData);
                this.set('confirmBulkChange', true);
            },
            copyDataFromMainCaseToSelected: function copyDataFromMainCaseToSelected() {
                var _this = this;

                var typeOfData = this.get('thing');
                var promises = [];
                switch (typeOfData) {
                    case 'Contacts':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyContactsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Contacts copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Accounts':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyAccountsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Accounts copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Employees':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyEmployeesFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Employees copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Teams':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyTeamsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Teams copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Fields':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyFieldsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Fields copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Subject Workflows':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copySubjectWorkflowsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Subject Workflows copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Visualizations':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyVisualizationsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Visualizations copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    case 'Budgets':
                        promises = this.get('selectedCases').map(function (selectedCase) {
                            return selectedCase.copyBudgetsFromCase({
                                from_matter_id: _this.get('mainCase.id')
                            });
                        });
                        Ember.RSVP.all(promises).then(function () {
                            _this.get('notify').success('Budgets copied successfully');
                        }).catch(function (error) {
                            _this.get('notify').error('There was an error during the copy \n ' + error.errors[0].additional_info.details);
                        });
                        break;
                    default:
                        this.get('notify').warning('NOT IMPLEMENTED YET');
                        break;
                }
                this.set('confirmBulkChange', false);
            },
            saveMainCase: function saveMainCase() {
                var _this2 = this;

                if (!this.get('selectedCase')) {
                    this.get('notify').warning('Please select a case');
                    return;
                }
                this.get('selected').set('mainMatterId', this.get('selectedCase.id'));
                this.get('selected').save().then(function () {
                    _this2.get('notify').success('Main case saved successfully');
                    _this2.send('refreshModel');
                });
            },
            createCollection: function createCollection() {
                var _this3 = this;

                var clientCollection = this.get('store').createRecord('client-collection', {
                    name: this.get('newCollectionName')
                });
                clientCollection.save().then(function () {
                    _this3.set('newCollectionName', '');
                    _this3.set('newCollection', false);
                    _this3.send('refreshModel');
                }).catch(function () {
                    _this3.validationReport(clientCollection);
                });
            }
        }
    });
});