define('tm3/pods/matter/dashboard/route', ['exports', 'tm3/mixins/report-info-route', 'tm3/config/environment'], function (exports, _reportInfoRoute, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model(params) {
            var currentMatter = this.get('currentMatter');

            this.store.unloadAll('case-workflow');

            return Ember.RSVP.hash({
                matterId: currentMatter.get('id'),
                matter: this.store.queryRecord('matter', {
                    id: currentMatter.get('id'),
                    with: 'matter_types',
                    include_jury_calculations: true
                }),
                initialProducts: this.get('currentMatter.matter').searchProductNames({
                    name: '**'
                }),
                reportTypes: this.get('lists').getListForGroup('report_types'),
                calendarTypes: this.get('lists').getListForGroup('calendar_type'),
                caseStatusOptions: this.get('lists').getListForGroup('case_status'),
                caseWorkflows: this.store.query('case-workflow', {
                    matter_id: currentMatter.get('id')
                }),
                collectionWorkflows: this.store.query('collection-column', {
                    collection_id: currentMatter.get('matter.collection.id'),
                    edit_render_type: 'workflow',
                    with: 'workflow'
                }),
                customWorkflow: this.store.queryRecord('workflow', {
                    name: 'Custom'
                }),
                workflowStatuses: this.get('lists').getListForGroup('workflow_status'),
                serviceTypes: this.get('lists').getListForGroup('service_types'),
                states: this.get('lists').getListForGroup('States'),

                /* employees: this.store.query('employee', {
                     with_access_to_matter: currentMatter.get('id')
                   }), */
                employees: this.get('store').query('employee', {
                    sortField: 'last_name,first_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
                    status: 'Active'
                }),
                matterHasUsers: this.store.query('matter-has-user', {
                    matter_id: currentMatter.get('id')
                }),
                groups: this.store.query('group', {
                    group_type: _environment.default.APP.groupTypes.employee
                }),
                case_contact_types: this.get('lists').getListForGroup('case_contact_types'),
                subjectWorkflowSummary: this.store.query('subject-workflow-summary', {
                    matter_id: currentMatter.get('id')
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            var tosOnMatter = resolved.matter.get('typeOfService');
            var allTypes = resolved.serviceTypes;
            var typeOfServices = [];

            if (tosOnMatter) {
                tosOnMatter.forEach(function (typeOfService) {
                    if (allTypes.findBy('display', typeOfService)) {
                        typeOfServices.push(typeOfService);
                    }
                });
                resolved.matter.set('typeOfService', typeOfServices);
            }

            if ('products' in resolved.initialProducts && resolved.initialProducts.products) {
                controller.set('initialProducts', resolved.initialProducts.products.map(function (p) {
                    return p.name;
                }));
            } else {
                controller.set('initialProducts', []);
            }
            controller.set('states', resolved.states);
            controller.set('employees', resolved.employees);

            controller.set('caseEmployees', resolved.employees.filter(function (e) {
                return resolved.matterHasUsers.findBy('user.id', e.get('id'));
            }));

            this._super(controller, resolved);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});