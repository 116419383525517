define('tm3/pods/components/documents/table-no-data/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isGrouped: Ember.computed('noDataParams.groupBy', function () {
            return this.get('noDataParams.groupBy') !== 'none';
        })
    });
});