define('tm3/pods/components/table-filter-components/task-status/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            if (Ember.typeOf(this.get('column.advFilterValue')) !== 'array') {
                return;
            }
            this.set('selectedStatuses', this.get('statuses').filter(function (s) {
                return _this.get('column.advFilterValue').includes(s.get('value'));
            }));
        },


        statuses: [Ember.Object.create({ value: 'I', display: 'Not Started' }), Ember.Object.create({ value: 'P', display: 'Waiting' }), Ember.Object.create({ value: 'C', display: 'Complete' })],

        actions: {
            selectStatus: function selectStatus(newStatuses) {
                this.set('column.advFilterValue', newStatuses.mapBy('value'));
                this.set('selectedStatuses', newStatuses);
            }
        }
    });
});