define('tm3/helpers/permissions-check', ['exports', 'tm-common/helpers/permissions-check'], function (exports, _permissionsCheck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _permissionsCheck.default;
    }
  });
  Object.defineProperty(exports, 'permissionsCheck', {
    enumerable: true,
    get: function () {
      return _permissionsCheck.permissionsCheck;
    }
  });
});