define('tm3/pods/report/info/project-tracker/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['sortField', 'owner', 'budget_type', 'case_type', 'assignee'],

        sortField: null,

        reportBuilder: Ember.inject.service(),
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        tableData: Ember.computed('model.tableData.table_data', function () {
            return this.get('model.tableData.table_data').map(function (row) {
                return Ember.Object.create(row);
            });
        }),
        tableTotals: Ember.computed('model.tableData.table_totals', function () {
            return this.get('model.tableData.table_totals').map(function (row) {
                return Ember.Object.create(row);
            });
        }),
        columns: Ember.computed.alias('model.tableData.columns'),
        showTotalRow: false,
        noDataMessage: 'Select some filters to show the report',

        actions: {
            getReport: function getReport(format) {
                var jsonOptions = {
                    report_builder: {
                        standard: {
                            type: 'project_tracker_report',
                            format: format,
                            style: 'misc'
                        },
                        custom: {
                            per_page: 2000,
                            page: 1,
                            from_tabular: true,
                            sort_field: this.get('sortField'),
                            owner_id: this.get('owner') ? this.get('owner') : false,
                            budget_type: this.get('budget_type') ? this.get('budget_type') : false,
                            case_type: this.get('case_type') ? this.get('case_type') : false,
                            assignee_id: this.get('assignee') ? this.get('assignee') : false
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonOptions);
            },
            saveWorkflowAssignee: function saveWorkflowAssignee(item, newAssignee) {
                var _this = this;

                var id = item.id + '-' + item.up_next_id + '-' + item.up_next_mw_id + '-';

                return this.get('store').findRecord('case-workflow', id).then(function (workflow) {
                    workflow.set('assignedToId', newAssignee.get('id'));
                    workflow.set('assignedTo', newAssignee.get('shortName'));

                    return workflow.save().then(function () {
                        var row = _this.get('tableData').findBy('id', item.id);

                        if (row) {
                            row.set('assigned', newAssignee.get('fullName'));
                        }
                    });
                });
            },
            saveProjectTrackerNotes: function saveProjectTrackerNotes(item) {
                var _this2 = this;

                return this.get('store').findRecord('matter', item.id).then(function (matter) {
                    matter.set('projectTrackerNotes', item.project_tracker_notes);

                    return matter.save().then(function () {
                        _this2.get('notify').success('Note saved');
                    });
                });
            },
            setSortField: function setSortField(newField) {
                if (this.get('sortField') === newField) {
                    newField = newField + ' DESC';
                }
                this.set('sortField', newField);
            },
            selectEmployee: function selectEmployee(employee) {
                if (employee) {
                    this.set('selectedEmployee', employee);
                    this.set('owner', employee.get('id'));
                } else {
                    this.set('selectedEmployee', '');
                    this.set('owner', null);
                }
            },
            selectType: function selectType(type) {
                if (type) {
                    this.set('selectedType', type);
                    this.set('budget_type', type.get('value'));
                } else {
                    this.set('selectedType', '');
                    this.set('budget_type', null);
                }
            },
            selectCaseType: function selectCaseType(caseType) {
                if (caseType) {
                    this.set('selectedCaseType', caseType);
                    this.set('case_type', caseType.get('id'));
                } else {
                    this.set('selectedCaseType', '');
                    this.set('case_type', null);
                }
            },
            selectAssignee: function selectAssignee(employee) {
                if (employee) {
                    this.set('selectedAssignee', employee);
                    this.set('assignee', employee.get('id'));
                } else {
                    this.set('selectedAssignee', '');
                    this.set('assignee', null);
                }
            }
        }
    });
});