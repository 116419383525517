define('tm3/models/subject', ['exports', 'tm-common/models/subject', 'ember-data'], function (exports, _subject, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _subject.default.extend({
        hasAccess: attr('boolean')
    });
});