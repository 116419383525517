define('tm3/pods/crm/accounts/info/contacts/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),

        associatedClient: undefined,

        actions: {
            // connection related logic
            hideConnectForm: function hideConnectForm() {
                Ember.$('#connectForm').addClass('hidden');
            },
            showConnectForm: function showConnectForm() {
                Ember.$('#connectForm').removeClass('hidden');
            },
            newContactConnection: function newContactConnection() {
                var self = this;
                var firm = this.get('firm');
                var associatedClient = this.get('associatedClient');

                if (associatedClient.get('id') !== '' && associatedClient.get('name') !== '') {
                    var userPromise = this.store.findRecord('user', associatedClient.get('id'));
                    var firmPromise = this.store.findRecord('firm', firm.get('id'));

                    Ember.RSVP.all([firmPromise, userPromise]).then(function (array) {
                        var firm = array[0];
                        var user = array[1];

                        var fhc = self.store.createRecord('firm-has-client', {
                            firm: firm,
                            user: user,
                            relationship: self.get('firmRelationship.value')
                        });

                        fhc.save().then(function (data) {
                            self.set('associatedClient', undefined);
                            self.send('hideConnectForm');
                            self.get('notify').success('New contact has been associated with this account');
                            self.send('reloadModel');
                        }, function (reason) {
                            self.validationReport(fhc);
                        });
                    });
                }
            },
            removeContactConnection: function removeContactConnection(item) {
                item.destroyRecord();
                this.get('notify').success('Successfully removed contact from this contact!');
                return false;
            },


            /** power-select for clients */
            searchClient: function searchClient(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchClient, term, resolve, reject, 600);
                });
            }
        },
        _performSearchClient: function _performSearchClient(term, resolve, reject) {
            return this.store.query('client', {
                'first_name||last_name': '*' + term + '*',
                limit: 100,
                sortField: 'last_name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});