define('tm3/pods/crm/accounts/info/contacts/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        model: function model() {
            var accountsInfoModel = this.modelFor('crm.accounts.info');
            var firm = accountsInfoModel.firm;
            // did not join clients due to unresolved stack overflow question
            return Ember.RSVP.hash({
                model: this.store.query('firm-has-client', { firm_id: firm.get('id'), with: 'users,clients' }),
                relationships: this.get('lists').getListForGroup('contact_account_relations'),
                firm: firm
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('firm', resolved.firm);
            controller.set('relationships', resolved.relationships);
            controller.set('firmRelationship', resolved.relationships.get('firstObject'));
        },


        actions: {
            reloadModel: function reloadModel() {
                this.refresh();
            }
        }
    });
});