define('tm3/pods/report/info/payment-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        notify: Ember.inject.service(),
        tableName: 'report/payment-report',
        queryParams: ['paidOn', 'tmCode'],
        paidOn: '',
        tmCode: '',

        actions: {
            clearPaidOn: function clearPaidOn() {
                this.set('paidOn', '');
            },
            getReport: function getReport() {
                if (this.get('tmCode') || this.get('paidOn')) {
                    this.send('makeReportRequest');
                } else {
                    this.get('notify').warning('You must enter File # or Paid on in order to generate report');
                }
            }
        }
    });
});