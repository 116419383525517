define('tm3/pods/components/matter/subjects/new/profile-data/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        readIndex: 0,

        actions: {
            incrementReadIndex: function incrementReadIndex() {
                var readIndex = this.get('readIndex');
                this.set('readIndex', readIndex + 1);
            },
            getReadIndex: function getReadIndex() {
                return this.get('readIndex');
            }
        }
    });
});