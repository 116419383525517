define('tm3/pods/crm/contacts/info/tab-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['nav', 'nav-tabs'],
        menuItems: ['tab-accounts', 'tab-matters', 'tab-activity', 'tab-deals'],
        activeItem: null,

        actions: {
            // update current item
            // call toggle
            makeActive: function makeActive(element) {
                this.set('activeItem', element);
                this.toggleMenu();
            }
        },

        toggleMenu: function toggleMenu() {
            var element = this.get('activeItem');
            var menuItems = this.get('menuItems');
            var elm = '#' + element;
            Ember.$(elm).toggleClass('active');

            menuItems.forEach(function (item) {
                // reset all other menu items to off position
                if (item !== element) {
                    elm = '#' + item;
                    Ember.$(elm).removeClass('active');
                }
            });
        },
        didInsertElement: function didInsertElement() {
            var self = this;
            Ember.run.scheduleOnce('afterRender', this, function () {
                self.toggleMenu();
            });
        }
    });
});