define('tm3/pods/matter/controller', ['exports', 'tm3/mixins/with-full-height-pages'], function (exports, _withFullHeightPages) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_withFullHeightPages.default, {
        routing: Ember.inject.service('-routing'),

        currentRoute: Ember.computed('routing.currentRouteName', function () {
            return this.get('routing.currentRouteName');
        }),

        pageTitle: Ember.computed('model.title', 'currentRoute', function () {
            var modelTitle = this.get('model.title');
            var currentRoute = this.get('currentRoute');
            switch (currentRoute) {
                case 'matter.notes':
                    return 'Case Notes - ' + modelTitle;

                case 'matter.subjects.list':
                    return 'Subjects - ' + modelTitle;

                case 'matter.db-memos.browse-memos':
                    return 'DB Research - ' + modelTitle;

                case 'matter.documents.index':
                    return 'Documents - ' + modelTitle;

                case 'matter.tasks.index':
                    return 'Tasks - ' + modelTitle;

                case 'matter.calendar':
                    return 'Calendar - ' + modelTitle;

                case 'matter.report-wizard':
                    return 'Report Wizard - ' + modelTitle;

                case 'matter.timelines':
                    return 'Timeline - ' + modelTitle;

                case 'matter.report-notes':
                    return 'Report Notes - ' + modelTitle;

                case 'matter.detail.info':
                    return 'Case Details - ' + modelTitle;

                case 'matter.admin.contacts':
                    return 'Contacts - ' + modelTitle;

                case 'matter.admin.accounts.list':
                    return 'Accounts - ' + modelTitle;

                case 'matter.admin.employees':
                    return 'Employees - ' + modelTitle;

                case 'matter.admin.teams':
                    return 'Teams - ' + modelTitle;

                case 'matter.admin.fields':
                    return 'Fields - ' + modelTitle;

                case 'matter.admin.workflows':
                    return 'Workflows - ' + modelTitle;

                case 'matter.admin.invoice-groups.index':
                case 'matter.admin.invoice-groups.detail':
                    return 'Invoice Groups - ' + modelTitle;

                case 'matter.admin.billing-summary':
                    return 'Invoices - ' + modelTitle;

                case 'matter.admin.budgets':
                    return 'Budgets - ' + modelTitle;

                case 'matter.admin.reports':
                case 'matter.admin.reports-by-employee':
                case 'matter.admin.reports-by-type':
                    return 'Reports - ' + modelTitle;

                case 'matter.billable-groups.list':
                case 'matter.billable-groups.info':
                case 'matter.billable-groups.edit':
                    return 'Time - ' + modelTitle;

                case 'matter.admin.scrub-data':
                    return 'Scrub Data - ' + modelTitle;

                default:
                    return this.get('model.title');
            }
        }),
        refreshModel: function refreshModel() {
            this.send('refreshModel');
        },


        _fullHeightPages: ['matter.subjects.list', 'matter.subjects.info.index', 'matter.subjects.info.documents', 'matter.subjects.info.notes', 'matter.subjects.info.workflow', 'matter.subjects.info.db-memos.index', 'matter.subjects.info.social-links', 'matter.subjects.info.posts', 'matter.subjects.info.timeline-events', 'matter.subjects.info.addresses', 'matter.subjects.new', 'matter.notes'],

        actions: {
            transitionToMatter: function transitionToMatter(matterId) {
                this.transitionToRoute(this.get('linkPath'), matterId);
            },
            selectFromAutosuggest: function selectFromAutosuggest(subjectId) {
                this.transitionToRoute('matter.subjects.info.documents', subjectId);
            },
            matterHasWorkflowWasUpdated: function matterHasWorkflowWasUpdated() {}
        }
    });
});