define('tm3/pods/components/search-db/component', ['exports', 'tm-common/mixins/crud/error', 'tm-common/mixins/scroll-to-mixin'], function (exports, _error, _scrollToMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Component.extend(_scrollToMixin.default, _error.default, {
        permissions: Ember.inject.service(),

        isCountySelected: false,
        userRole: 'user',

        model: {},
        tab: 'general',

        scrollDuration: 0,

        tagSelection: [],
        q: null,
        location: false, // not used

        loading: false,

        canAddTags: Ember.computed('userRole', function () {
            return this.get('userRole') === 'admin';
        }),
        // check that a user has the require permissions to access the Add DB button
        canAddDb: Ember.computed('userRole', function () {
            return this.get('permissions').checkAny(['Manage Databases', 'Edit Databases']);
        }),
        canEditDb: Ember.computed('userRole', function () {
            return this.get('permissions').checkAny(['Manage Databases', 'Edit Databases']);
        }),
        canCreateMemo: Ember.computed('userRole', function () {
            return this.get('userRole') === 'user';
        }),

        store: undefined,

        searchFilter: undefined,
        parentTag: undefined,

        dbSelection: alias('model.databases'),
        filteredTags: Ember.computed('dbSelection', function () {
            var dbs = this.get('dbSelection');
            var dbsTags = [];
            dbs.map(function (db) {
                db.get('dbHasTags').filterBy('tagCategory', 'primary') // show only primary tags
                .mapBy('dbTag').forEach(function (tag) {
                    if (!dbsTags.findBy('id', tag.get('id')) && !tag.get('parent.id')) {
                        // if the tag is general (i.e. no parent) and is not already in our list
                        // TODO FIX: there are still duplicates
                        tag.set('dbCount', 1);
                        dbsTags.push(tag);
                    } else {
                        tag.set('dbCount', tag.get('dbCount') + 1);
                    }
                });
            });
            return dbsTags.sortBy('name');
        }),

        observingQ: Ember.observer('q', function () {
            this.set('page', 1);
        }),

        onlySelect: false,

        actions: {
            toggleExpand: function toggleExpand(db) {
                db.set('isExpanded', !db.get('isExpanded'));
            },


            /**
             *
             * @param tag
             */
            showTag: function showTag(tag) {
                var tagId = tag.get('id');
                this.send('scrollToOverflow', '[data-role="tag-separator"][data-tag="' + tagId + '"]', '#db-search-results');
            },
            setIsCountySelected: function setIsCountySelected(value) {
                this.set('isCountySelected', value);
            }
        }
    });
});