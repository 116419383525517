define('tm3/pods/admin/appl-lists-categories/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),
        requiredPermissions: ['Manage Case Settings'],

        queryParams: {
            category: {
                refreshModel: true
            }
        },

        model: function model() {
            return Ember.RSVP.hash({
                applListsCategories: this.store.query('appl_lists-category', {
                    private: 0,
                    with: 'appl_lists'
                }),
                applList: null,
                newCategory: Ember.Object.create({ categoryName: '', displayName: '', private: 0 }),
                newItem: Ember.Object.create({ display: '', value: '', order: 0 })
            });
        },


        actions: {
            openList: function openList(category) {
                var _this = this;

                this.set('controller.currentApplListCategory', category);
                this.store.query('appl-list', {
                    appl_lists_category_id: category.get('id')
                }).then(function (result) {
                    _this.set('controller.model.applList', result);
                    Ember.$('html, body').animate({ scrollTop: 0 }, 'slow');
                });
            },
            editList: function editList(category) {},
            deleteApplListItem: function deleteApplListItem(item) {
                var _this2 = this;

                var category = this.get('controller.currentApplListCategory');
                item.destroyRecord().then(function () {
                    _this2.store.query('appl-list', {
                        appl_lists_category_id: category.get('id')
                    }).then(function (result) {
                        _this2.set('controller.model.applList', result);
                    });
                    _this2.get('notify').success('The App List Item was removed');
                });
            },
            saveApplListCategory: function saveApplListCategory(cat) {
                var _this3 = this;

                var category = this.store.createRecord('appl-lists-category', {
                    categoryName: cat.get('categoryName'),
                    displayName: cat.get('categoryName'),
                    private: cat.get('private')
                });
                category.save().then(function () {
                    _this3.set('controller.model.newCategory', Ember.Object.create({
                        categoryName: '',
                        displayName: '',
                        private: 0
                    }));
                    _this3.get('notify').success('New Category added successfully');
                    _this3.refresh();
                });
            },
            updateApplListCategory: function updateApplListCategory(category) {
                var _this4 = this;

                category.displayName = category.categoryName;
                category.save().then(function () {
                    _this4.get('notify').success('New Category added successfully');
                    _this4.refresh();
                });
            },
            saveApplListItem: function saveApplListItem(item) {
                var _this5 = this;

                var applList = this.store.createRecord('appl-list', {
                    display: item.get('display'),
                    value: item.get('value'),
                    weight: item.get('weight'),
                    applListsCategory: this.get('controller.currentApplListCategory')
                });
                applList.save().then(function (result) {
                    _this5.set('controller.model.newItem', Ember.Object.create({ display: '', value: '', order: 0 }));
                    _this5.get('notify').success('New Appl List Item created successfully');
                    var applList = _this5.get('controller.model.applList').toArray();
                    applList.push(result);
                    _this5.set('controller.model.applList', applList);
                });
            },
            updateApplListItem: function updateApplListItem(item) {
                var _this6 = this;

                item.save().then(function (result) {
                    _this6.get('notify').success('New Appl List Item created successfully');
                });
            }
        }
    });
});