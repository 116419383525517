define('tm3/pods/components/report/report-viewer/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        reportName: null,

        availableReports: ['InactivePortalUsers', 'JurorReport'],

        /**
         * access the ENV from the app container and return it to
         * have access to the configuration like urls to hit
         *
         * @property ENV
         * @see http://discuss.emberjs.com/t/best-practices-accessing-app-config-from-addon-code/7006/7
         */
        ENV: Ember.computed(function () {
            var applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
            return applicationConfig;
        }),

        /**
         * property that return the right URL to POST to create the report on the API
         */
        postAction: Ember.computed(function () {
            var ENV = this.get('ENV');
            return ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/report_builder';
        }),

        actions: {
            setReport: function setReport(name) {
                this.set('reportName', name);
            },
            downloadFile: function downloadFile() {
                var reportName = this.get('reportName');
                if (!Ember.isNone(reportName)) {
                    var jsonObject = {
                        report_builder: {
                            standard: {
                                type: this.get('reportType')
                            },
                            custom: {
                                report_name: this.get('reportName'),
                                notes: {
                                    public: false,
                                    private: false
                                },
                                request_jasper_report: true
                            }
                        }
                    };

                    // stringify json object
                    var jsonObjectString = JSON.stringify(jsonObject);
                    var self = this;
                    // var ENV = this.get('ENV');

                    // build form and post json object
                    var form = Ember.$('<form>', {
                        id: 'reportForm',
                        method: 'post',
                        action: self.get('postAction'),
                        enctype: 'application/json'
                    });
                    var jsonInput = Ember.$('<input>', { name: 'jsonPost', value: jsonObjectString });
                    var tokenInput = Ember.$('<input>', { name: 'token', value: self.get('session.data.authenticated.token') });

                    Ember.$(form).append(jsonInput);
                    Ember.$(form).append(tokenInput);
                    Ember.$('body').append(form);
                    Ember.$('#reportForm').submit();

                    this.get('notify').success('Please wait while your report is being generated', { closeAfter: 15000 });

                    Ember.$(form).remove();
                }
            }
        }
    });
});