define('tm3/services/recent-matters', ['exports', 'ember-lifeline/mixins/run', 'tm3/config/environment'], function (exports, _run, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_run.default, {
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        fetch: function fetch() {
            var store = this.get('store');

            return store.query('user-viewed-matter', {
                user_id: this.get('session.data.authenticated.id'),
                limit: 15,
                with: 'matters',
                sort: '-updated_on'
            }).then(function (matters) {
                return Ember.RSVP.all(matters.map(function (matterView) {
                    return matterView.get('matter').then(function (matter) {
                        var matterTypeId = matter.get('matterTypeId');
                        var link = void 0;
                        var isJSI = [8, 27, 38].includes(matterTypeId);
                        if (!isJSI) {
                            link = _environment.default.APP.tm2AdminURL; // TODO: This is not actually defined in the environment config
                        } else {
                            link = _environment.default.APP.js2AdminURL;
                        }
                        link += 'matters/' + matter.get('id');
                        var isSameOrigin = isJSI && _environment.default.APP.name === 'js2-admin' || !isJSI && _environment.default.APP.name === 'tm3';
                        return Ember.Object.create({ matter: matter, link: link, isSameOrigin: isSameOrigin });
                    });
                }));
            });
        }
    });
});