define('tm3/adapters/application', ['exports', 'tm3/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'active-model-adapter', 'ember-data', 'tm-common/mixins/authorize-mixin'], function (exports, _environment, _dataAdapterMixin, _activeModelAdapter, _emberData, _authorizeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var InvalidError = _emberData.default.InvalidError,
        errorsHashToArray = _emberData.default.errorsHashToArray;


    var ApplicationAdapter = _activeModelAdapter.default.extend(_dataAdapterMixin.default, _authorizeMixin.default, {
        namespace: _environment.default.APP.restNameSpace,
        host: _environment.default.APP.restDestination,

        query: function query(store, type, _query) {
            if (Ember.isPresent(_query) && Ember.typeOf(_query.custom_endpoint) !== 'undefined') {
                var useCustomEndpoint = _query.custom_endpoint;
                delete _query.custom_endpoint;
                var url = this.buildURL(useCustomEndpoint, null, null, 'query', _query);

                if (this.sortQueryParams) {
                    _query = this.sortQueryParams(_query);
                }

                return this.ajax(url, 'GET', { data: _query });
            } else {
                for (var key in _query) {
                    if (Ember.typeOf(_query[key]) === 'array') {
                        if (_query[key].length === 0) {
                            _query[key] = 'empty.array';
                        }
                    }
                }
                return this._super(store, type, _query);
            }
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {
            if (this.isInvalid(status, headers, payload) || status === 404) {
                var errors = errorsHashToArray(payload.errors[0]);

                return new InvalidError(errors);
            } else {
                return this._super.apply(this, arguments);
            }
        },
        _ajaxRequest: function _ajaxRequest(options) {
            var _arguments = arguments;

            if (options.type === 'DELETE') {
                var oldErrorCallback = options.error;
                options.error = function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status === 404) {
                        jqXHR.status = 204;
                        jqXHR.responseText = '';
                        // Assume delete success if 404
                        return options.success(undefined, 'nocontent', jqXHR);
                    }
                    return oldErrorCallback.apply(undefined, _arguments);
                };
            }

            this._super(options);
        }
    });

    exports.default = ApplicationAdapter;
});