define('tm3/pods/components/subjects/send-multiple/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),

        'data-test-component': 'subjects-send-multiple',
        showModal: false,

        actions: {
            toggleSendMultipleSubjectsModal: function toggleSendMultipleSubjectsModal() {
                var selectedRows = this.get('selectedRows');

                if (selectedRows.length > 0) {
                    this.set('showModal', true);
                } else {
                    this.get('notify').error('You must selected at least one subject.');
                }
            }
        }
    });
});