define('tm3/pods/components/smm/status-cell/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: 'text-center',
        labelClass: Ember.computed('value', function () {
            var value = this.get('value');
            switch (value) {
                case 'New':
                case 'Pending':
                case 'New (previously failed)':
                    return 'warning';
                case 'Canceled':
                    return 'info';
                case 'Completed':
                case 'Finalized':
                    return 'success';
                case 'Failed':
                    return 'danger';
                default:
                    return 'default';
            }
        })
    });
});