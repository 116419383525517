define('tm3/pods/matter/documents/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route', 'tm-common/mixins/crud/error', 'tm3/mixins/alert-users-route'], function (exports, _route, _error, _alertUsersRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, _error.default, _alertUsersRoute.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        lists: Ember.inject.service(),

        queryParams: {
            q: {
                refreshModel: true
            },
            groupBy: {
                refreshModel: true
            },
            docTemplate: {
                refreshModel: false
            },
            selectedGroup: {
                refreshModel: true
            },
            resetCache: {
                refreshModel: false
            },
            perPage: {
                refreshModel: true
            }
        },

        additionalLoad: {
            oldMatterId: null,
            data: {
                fileTypes: null,
                tags: null,
                currentUser: null,
                docTemplates: null,
                defaultDocFields: null,
                docTemplateId: null
            }
        },

        modelName: 'document',
        currentRoute: 'matter.documents',
        model: function model(params) {
            var _this = this;

            var groupBy = params.groupBy;
            var docTemplate = params.docTemplate;
            var selectedGroup = params.selectedGroup;
            var resetCache = params.resetCache;
            var currentUserId = this.get('session.data.authenticated.id');
            var matter = this.get('currentMatter.matter');
            var q = params.q;
            delete params.groupBy;
            delete params.selectedGroup;
            delete params.docTemplate;
            delete params.resetCache;
            delete params.q;

            if (groupBy !== 'none') {
                if (selectedGroup === null) {
                    params['id'] = -1; // Always empty list when in group mode
                } else {
                    switch (groupBy) {
                        case 'reportName':
                            if (selectedGroup !== -1) {
                                params['reports:name'] = selectedGroup;
                            } else {
                                // spike the request since you haven't selected a group yet
                                params['reports:name'] = 'XYZ';
                                params['per_page'] = 1;
                            }
                            break;
                        case 'docType':
                            if (selectedGroup !== -1) {
                                params['doc_type'] = selectedGroup;
                            } else {
                                params['doc_type'] = 'XYZ';
                                params['per_page'] = 1;
                            }
                            break;
                        case 'subject':
                            params['subject_id'] = selectedGroup;
                            break;
                        case 'tags':
                            params['tag_name'] = selectedGroup;
                            break;
                        case 'viewable':
                            switch (selectedGroup) {
                                case 'Private':
                                    params['viewable'] = 0;
                                    break;
                                case 'Public':
                                    params['viewable'] = 1;
                                    break;
                                case 'Pending':
                                    params['viewable'] = 2;
                                    break;
                                default:
                                    params['viewable'] = null;
                            }
                            break;
                    }
                }
            }
            params['resources:matter_id'] = this.get('currentMatter.id');

            if (Ember.isPresent(q)) {
                params['doc_name'] = q;
            }

            if (resetCache || this.get('additionalLoad.oldMatterId') !== params['resources:matter_id']) {
                return this.loadAdditionalData(currentUserId, docTemplate, this.get('currentMatter.id')).then(function () {
                    return _this.loadData(params, groupBy, selectedGroup, matter);
                });
            } else {
                return this.loadData(params, groupBy, selectedGroup, matter);
            }
        },
        loadData: function loadData(params, groupBy, selectedGroup, matter) {
            var allParams = this.getAllParams(params);
            this.currentParams = allParams;

            return Ember.RSVP.hash({
                model: this.findPaged(this.modelName, allParams),
                matter: this.get('currentMatter.matter'),
                caseOwners: this.store.query('matter-has-user', {
                    matter_id: this.get('currentMatter.id'),
                    user_type: 'employee',
                    contact: 'Primary',
                    group_id: 28, // Case Owners
                    with: 'users'
                }),
                matter_id: params['resources:matter_id'],
                fileTypes: this.get('additionalLoad.data.fileTypes'),
                sortField: params.sortField,
                groups: groupBy !== 'none' ? this.store.query('document-group', {
                    matter_id: params['resources:matter_id'],
                    group_by: groupBy
                }) : [],
                groupBy: groupBy,
                selectedGroup: selectedGroup,
                reports: this.store.query('report', {
                    matter_id: this.get('currentMatter.id')
                }),
                tags: this.get('additionalLoad.data.tags'),
                currentUser: this.get('additionalLoad.data.currentUser'),
                docTemplates: this.get('additionalLoad.data.docTemplates'),
                defaultDocFields: this.get('additionalLoad.data.defaultDocFields'),
                docTemplateId: this.get('additionalLoad.data.docTemplateId'),
                alertUsersContacts: this.contactsModelMixin(),
                alertUsersEmployees: this.employeesModelMixin(),
                subjects: this.store.query('subject', {
                    matter_id: this.get('currentMatter.id'),
                    sort: 'last_name,first_name'
                })
            });
        },
        loadAdditionalData: function loadAdditionalData(currentUserId, docTemplate, matterId) {
            var _this2 = this;

            return Ember.RSVP.hash({
                fileTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                tags: this.store.query('tag', { type: 'document' }),
                currentUser: this.store.findRecord('user', currentUserId),
                docTemplates: this.store.query('document-template', {
                    user_id: currentUserId,
                    with: 'document_template_has_fields'
                }),
                defaultDocFields: this.store.findAll('document-field', { backgroundReload: false }),
                docTemplateId: docTemplate
            }).then(function (resolved) {
                _this2.set('additionalLoad.oldMatterId', matterId);
                _this2.set('additionalLoad.data.fileTypes', resolved.fileTypes.sortBy('weight'));
                _this2.set('additionalLoad.data.tags', resolved.tags);
                _this2.set('additionalLoad.data.currentUser', resolved.currentUser);
                _this2.set('additionalLoad.data.docTemplates', resolved.docTemplates);
                _this2.set('additionalLoad.data.defaultDocFields', resolved.defaultDocFields);
                _this2.set('additionalLoad.data.docTemplateId', resolved.docTemplateId);
            });
        },
        setupController: function setupController(controller, resolved) {
            resolved.model.forEach(function (d) {
                return d.set('groupBy', resolved.groupBy);
            });
            this._super(controller, resolved.model);
            controller.set('alertUsersContacts', resolved.alertUsersContacts);
            controller.set('alertUsersEmployees', resolved.alertUsersEmployees);

            controller.set('fileTypes', resolved.fileTypes);
            controller.set('matter_id', resolved.matter_id);
            controller.set('groupBy', resolved.groupBy);
            controller.set('selectedGroup', resolved.selectedGroup);
            controller.set('groups', resolved.groups);
            controller.set('tags', resolved.tags);
            controller.set('matter', resolved.matter);
            controller.set('currentUser', resolved.currentUser);
            controller.set('docTemplates', resolved.docTemplates);
            controller.set('defaultDocFields', resolved.defaultDocFields);
            controller.set('reports', resolved.reports);
            controller.set('caseOwners', resolved.caseOwners);
            controller.set('subjects', resolved.subjects);

            // set main doc template as selected if no doc_template_id is specified in query params
            if (!resolved.docTemplateId && resolved.docTemplates.get('length')) {
                var mainTemplate = resolved.docTemplates.findBy('isMain', 1);
                this.transitionTo({
                    queryParams: { docTemplate: mainTemplate.get('id') }
                });
            } else if (resolved.docTemplateId && !resolved.docTemplates.findBy('id', resolved.docTemplateId)) {
                // also do the same if doc template not found (old bookmarked urls perhaps)
                var _mainTemplate = resolved.docTemplates.findBy('isMain', 1);
                if (_mainTemplate) {
                    this.transitionTo({
                        queryParams: { docTemplate: _mainTemplate.get('id') }
                    });
                }
            }

            controller.set('sortFieldInitial', controller.get('sortOptions').filterBy('direction', resolved.sortField.indexOf('-') === 0 ? 'desc' : 'asc').findBy('field', resolved.sortField.replace('-', '')));

            controller.set('resetCache', false);
        },


        actions: {
            // refresh the current route by rebuilding based on paginator values
            fetch: function fetch() {
                this.refresh();
            },
            changeQuickSearchField: function changeQuickSearchField(quickSearch) {
                this.set('controller.quickSearchField', quickSearch);
            },
            willTransition: function willTransition(transition) {
                var target = transition.targetName;
                if (target.indexOf('matter.documents') < 0) {
                    this.controller.set('documentSelection', []);
                }
            }
        }
    });
});