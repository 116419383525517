define('tm3/pods/crm/full-search/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),
        // indicate that when this query param changes, refresh the route
        queryParams: {
            terms: {
                refreshModel: true
            },
            searchLimit: {
                refreshModel: true
            },
            accounts: {
                refreshModel: true
            },
            notes: {
                refreshModel: true
            },
            contacts: {
                refreshModel: true
            },
            opportunities: {
                refreshModel: true
            },
            cases: {
                refreshModel: true
            },
            todos: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var searchValue = params.terms;
            var searchLimit = params.searchLimit;

            var valid = true;

            if (searchValue === '') {
                // this.get('notify').error('Search value must be populated');
                valid = false;
            }

            if (valid) {
                var wildcard = '*';

                return Ember.RSVP.hash({
                    searchValue: searchValue,
                    accounts: this.store.query('firm', {
                        name: wildcard + searchValue + wildcard,
                        limit: searchLimit,
                        sortField: 'name'
                    }),
                    clients: this.store.query('client', {
                        'first_name||last_name': wildcard + searchValue + wildcard,
                        limit: searchLimit,
                        sortField: 'last_name,first_name'
                    }),
                    deals: this.store.query('deal', {
                        'name||notes': wildcard + searchValue + wildcard,
                        limit: searchLimit,
                        sortField: 'name'
                    }),
                    notes: this.store.query('crm-note', {
                        body: wildcard + searchValue + wildcard,
                        limit: searchLimit,
                        sortField: 'body',
                        with: 'crm_note_has_deals,crm_note_has_clients'
                    }),
                    cases: this.store.query('matter', {
                        'name||plaintiff||allegation||special_instructions': wildcard + searchValue + wildcard,
                        limit: searchLimit,
                        sortField: 'name'
                    }),
                    todos: [],
                    accountBool: params.accounts,
                    clientBool: params.contacts,
                    dealBool: params.opportunities,
                    noteBool: params.notes,
                    caseBool: params.cases,
                    todoBool: params.todos
                });
            } else {
                return [];
            }
        },
        setupController: function setupController(controller, resolved) {
            // process all promises here
            var optionsList = [];
            var accounts = resolved.accounts;
            var clients = resolved.clients;
            var deals = resolved.deals;
            var notes = resolved.notes;
            var cases = resolved.cases;
            var todos = resolved.todos;
            var accountBool = resolved.accountBool;
            var clientBool = resolved.clientBool;
            var dealBool = resolved.dealBool;
            var noteBool = resolved.noteBool;
            var caseBool = resolved.caseBool;
            var todoBool = resolved.todoBool;

            // build generic object from each account object and add to optionsList array
            if (typeof accounts !== 'undefined' && accountBool) {
                accounts.forEach(function (account) {
                    optionsList.pushObject(account);
                });
            }

            // build generic object from each client object and add to optionsList array
            if (typeof clients !== 'undefined' && clientBool) {
                clients.forEach(function (client) {
                    optionsList.pushObject(client);
                });
            }

            if (typeof deals !== 'undefined' && dealBool) {
                deals.forEach(function (deal) {
                    optionsList.pushObject(deal);
                });
            }

            if (typeof notes !== 'undefined' && noteBool) {
                notes.forEach(function (note) {
                    optionsList.pushObject(note);
                });
            }

            if (typeof todos !== 'undefined' && todoBool) {
                todos.forEach(function (todo) {
                    optionsList.pushObject(todo);
                });
            }

            if (typeof cases !== 'undefined' && caseBool) {
                cases.forEach(function (matter) {
                    optionsList.pushObject(matter);
                });
            }

            // sort optionsList by name
            optionsList.sort(function (a, b) {
                var x = '';
                var y = '';

                switch (a.constructor.modelName) {
                    case 'account':
                        x = a.get('name');
                        break;

                    case 'note':
                        x = a.get('body');
                        break;

                    case 'client':
                        x = a.get('fullName');
                        break;

                    case 'deal':
                        x = a.get('name');
                        break;

                    case 'matter':
                        x = a.get('name');
                        break;

                    case 'todo':
                        x = a.get('body');
                        break;
                }

                switch (b.constructor.modelName) {
                    case 'account':
                        x = a.get('name');
                        break;

                    case 'note':
                        x = a.get('body');
                        break;

                    case 'client':
                        x = a.get('fullName');
                        break;

                    case 'deal':
                        x = a.get('name');
                        break;

                    case 'matter':
                        x = a.get('name');
                        break;

                    case 'todo':
                        x = a.get('body');
                        break;
                }

                return x < y ? -1 : x > y ? 1 : 0;
            });

            this._super(controller, optionsList);

            if (typeof resolved.searchValue !== 'undefined') {
                controller.set('termsToSearch', resolved.searchValue);
            }
        }
    });
});