define('tm3/pods/components/matter/admin/account-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        fromAdminPage: false,
        showCreateAccount: false,
        showCreateNewFirm: true,

        councilTypes: ['Local', 'National'],

        initFields: Ember.on('init', function () {
            // relationship
            var type = this.get('type');
            if (type && type !== 'Account') {
                this.set('account.relationshipToMatter', type);
            } else {
                this.set('relationships', ['Corporation', 'Law Firm']);
            }

            // initial firm-has-matter-has-tax
            this.set('fhmht', this.get('store').createRecord('firm-has-matter-has-tax', {
                firmHasMatter: this.get('account'),
                hundredPercent: true,
                split: 100
            }));
            this.filterTaxRules();
        }),

        filterTaxRules: function filterTaxRules() {
            var filteredTaxRules = [];
            var taxRules = this.get('taxRules') || [];
            var selectedTaxRulesIds = this.get('account.firmHasMatterHasTaxes').mapBy('taxRule.id');
            taxRules.forEach(function (tr) {
                if (!selectedTaxRulesIds.includes(tr.get('id'))) {
                    filteredTaxRules.pushObject(tr);
                }
            });
            this.set('filteredTaxRules', filteredTaxRules);
        },


        actions: {
            createFirmAccount: function createFirmAccount(args) {
                this.attrs.createFirmAccount(args);
            },

            /** power-select for firm */
            searchFirm: function searchFirm(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchFirm, term, resolve, reject, 600);
                });
            },
            newFHMHT: function newFHMHT() {
                this.get('account.firmHasMatterHasTaxes').pushObject(this.get('store').createRecord('firm-has-matter-has-tax', {
                    firmHasMatter: this.get('account'),
                    hundredPercent: true,
                    split: 100
                }));
                this.filterTaxRules();
            },
            removeFHMHT: function removeFHMHT(fhmht) {
                this.get('account.firmHasMatterHasTaxes').removeObject(fhmht);
                this.filterTaxRules();
            },
            changePercent: function changePercent(fhmht) {
                if (fhmht.get('hundredPercent')) {
                    fhmht.set('split', 100);
                }
            },
            changeTaxRule: function changeTaxRule(fhmht, taxRule) {
                fhmht.set('taxRule', taxRule);
                this.filterTaxRules();
            }
        },

        _performSearchFirm: function _performSearchFirm(term, resolve, reject) {
            return this.get('store').query('firm', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});