define('tm3/pods/matter/billable-groups/edit/route', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),
        parentController: Ember.computed(function () {
            return this.controllerFor('matter.billable-groups.list');
        }),
        queryParams: {
            billable_type: {
                refreshModel: true
            }
        },

        model: function model(params) {
            this.get('parentController').set('openedBillableGroupId', params.billable_group_id);
            return {
                billableGroupId: params.billable_group_id,
                billableGroupType: params.billable_type,
                billableGroup: params.billable_group_id !== 'new' ? this.store.findRecord('billable-group', params.billable_group_id) : null,
                matter: this.get('currentMatter.matter'),
                isLinkedToCase: this.get('ajax').request('auth/is_linked', {
                    method: 'GET',
                    accept: 'application/json',
                    data: {
                        user_id: this.get('currentUser.user.id'),
                        matter_id: this.get('currentMatter.id')
                    }
                })
            };
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            model.isLinkedToCase.then(function (response) {
                controller.set('isLinked', response.is_linked);
                controller.set('isCaseOwner', response.is_owner);
            });
        },
        afterModel: function afterModel(resolved, transition) {
            var _this = this;

            transition.then(function () {
                if (_this.get('currentMatter.matter.status') === 'Inactive') {
                    _this.get('notify').error('You can not enter new time in an Inactive Case');
                    _this.replaceWith('matter.billable-groups.list');
                }
            });
        },


        actions: {
            goToRoute: function goToRoute(routeName) {
                var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

                if (Ember.isPresent(params)) {
                    this.transitionTo(routeName, params);
                } else {
                    this.transitionTo(routeName);
                }
            },
            deleteBillableGroup: function deleteBillableGroup(billableGroup) {
                this.controller.set('deleteItem', billableGroup);
            },
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});