define('tm3/helpers/width-in-pixels', ['exports', 'ember-cli-table-pagination/helpers/width-in-pixels'], function (exports, _widthInPixels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _widthInPixels.default;
    }
  });
  Object.defineProperty(exports, 'widthInPixels', {
    enumerable: true,
    get: function () {
      return _widthInPixels.widthInPixels;
    }
  });
});