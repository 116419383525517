define('tm3/helpers/duration-uncertainty-label', ['exports', 'tm-common/helpers/duration-uncertainty-label'], function (exports, _durationUncertaintyLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _durationUncertaintyLabel.default;
    }
  });
  Object.defineProperty(exports, 'labelPrefix', {
    enumerable: true,
    get: function () {
      return _durationUncertaintyLabel.labelPrefix;
    }
  });
});