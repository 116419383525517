define('tm3/models/outbound-communication-log-thing', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        Model = _emberData.default.Model;
    exports.default = Model.extend({
        thingId: attr('number'),
        thingName: attr('string'),
        thingType: attr('string'),
        outboundCommunicationLog: belongsTo('outbound-communication-log')
    });
});