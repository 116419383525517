define('tm3/pods/accounting/billing/invoices/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentUser: Ember.inject.service(),
        modelName: 'invoice',
        currentRoute: 'accounting.billing.invoices.list',

        queryParams: {
            matter_type: {
                refreshModel: false
            },
            quickSearchField: {
                refreshModel: true
            },
            q: {
                refreshModel: true
            }
        },

        filterByMatterType: null, // user came from prelim-by-case report page (we filter by clicked matter-type and non-billed invoices)

        requireAnyPermission: ['Manage Invoices', 'See Invoices'],

        model: function model(params) {
            if (params.matter_type) {
                this.set('filterByMatterType', params.matter_type);
            }
            delete params.q;
            delete params.quickSearchField;
            delete params.matter_type;
            return {};
        },
        setupController: function setupController(controller, resolved) {
            // this._super(controller, resolved)
            if (typeof controller.get('quickSearch') === 'string') {
                controller.set('q', controller.get('quickSearch'));
            }
            controller.set('filterByMatterType', this.get('filterByMatterType'));
            controller.set('matter_type', null);

            controller.set('currentParams', this.currentParams);

            var params = {
                include_budgets: true,
                include_case_owners: true,
                include_split_nr: true,
                include_private_tags: true
            };

            controller.set('loadDataParams', params);
            this._super(controller, resolved);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.send('selectAll', false);
                this.refresh();
            },
            changeQuickSearchField: function changeQuickSearchField(quickSearch) {
                this.set('controller.quickSearchField', quickSearch);
            }
        }
    });
});