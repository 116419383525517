define('tm3/helpers/slash-2br', ['exports', 'tm-common/helpers/slash-2br'], function (exports, _slash2br) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _slash2br.default;
    }
  });
  Object.defineProperty(exports, 'nl2br', {
    enumerable: true,
    get: function () {
      return _slash2br.nl2br;
    }
  });
});