define('tm3/pods/components/case-management/info/edit-chm/matter-workflow-row/component', ['exports', 'tm3/pods/components/case-management/info/edit-chm/matter-workflow-row/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        classNames: ['row', 'm-b-5'],
        tagName: '',
        fieldName: Ember.computed('matterWorkflow.workflow.name', function () {
            var name = this.get('matterWorkflow.workflow.name');
            if (name) {
                return Ember.String.camelize(Ember.String.dasherize(name));
            }
            return null;
        }),
        assignField: Ember.computed('fieldName', 'collectionHasMatter', {
            get: function get(key) {
                return this.get('collectionHasMatter').get(this.get('fieldName') + 'A');
            },
            set: function set(key, value) {
                this.get('collectionHasMatter').set(this.get('fieldName') + 'A', value);
                return value;
            }
        }),
        commentField: Ember.computed('fieldName', 'collectionHasMatter', {
            get: function get(key) {
                return this.get('collectionHasMatter').get(this.get('fieldName') + 'C');
            },
            set: function set(key, value) {
                this.get('collectionHasMatter').set(this.get('fieldName') + 'C', value);
                return value;
            }
        })
    });
});