define('tm3/pods/components/matter/sent-reports-table/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        /**
            Provides a dynamic set of desired column widths based on the context
            (`isDashboardPanel`).
             @property columnWidths
            @type {EmberObject}
         */
        columnWidths: Ember.computed('isDashboardPanel', function () {
            var isDashboardPanel = this.get('isDashboardPanel');

            if (isDashboardPanel) {
                return Ember.Object.create({
                    name: '35%',
                    recipients: '35%',
                    sentDate: '20%',
                    type: '10%'
                });
            }

            // Give the browser a little more leeway to layout the cols here
            return Ember.Object.create({
                sentBy: '20%',
                sentDate: '15%',
                type: '10%'
            });
        })
    });
});