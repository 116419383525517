define('tm3/pods/matter/admin/reports-by-type/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['invoice_group', 'budget', 'bill_type'],
        invoice_group: null,
        budget: null,
        reportType: Ember.computed('model.billableType', function () {
            if (this.get('model.billableType.time')) {
                return 'Time';
            } else {
                return 'Expense';
            }
        })
    });
});