define('tm3/pods/accounting/billing/batches/info/print/controller', ['exports', 'tm3/mixins/print-invoice-controller'], function (exports, _printInvoiceController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_printInvoiceController.default, {
        invoiceSummariesTask: null,
        invoiceSummariesModel: Ember.computed.alias('invoiceSummariesTask.value')
    });
});