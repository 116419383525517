define('tm3/mixins/notifications-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        queryParams: ['type', 'dateRange'],
        type: null,
        dateRange: 'week',

        ordering: ['createdOn:desc'],
        orderedNotifications: Ember.computed.sort('notifications', 'ordering'),
        allNotifications: [],
        typeNames: [],
        notifications: Ember.computed('allNotifications.[]', 'type', function () {
            var type = this.get('type');
            if (type) {
                return this.get('allNotifications').filterBy('notificationType.name', type);
            } else {
                return this.get('allNotifications');
            }
        }),
        actions: {
            setDateRange: function setDateRange(range) {
                this.set('dateRange', range);
            },
            deleteItem: function deleteItem(notification) {
                notification.destroyRecord();
            },
            markAsUnread: function markAsUnread(notification) {
                notification.set('read', 0);
                notification.save();
            },
            dismissItem: function dismissItem(notification) {
                notification.set('read', 1);
                notification.save();
            },
            dismissAll: function dismissAll() {
                this.get('notifications').filterBy('read', 0).forEach(function (unseen) {
                    unseen.set('read', 1);
                    unseen.save();
                });
            }
        }
    });
});