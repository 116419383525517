define('tm3/pods/admin/matter-type/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'matter-type',
        currentRoute: 'admin.matter-type.list',
        requiredPermissions: ['Manage Case Settings'],

        model: function model(params) {
            return {};
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {});
            this._super(controller, resolved);
        },


        actions: {
            doNothing: function doNothing() {
                // Do nothing at all
            }
        }
    });
});