define('tm3/pods/components/crm/show-note/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        notifications: Ember.inject.service(),
        isEditing: false,
        noteCategories: [],
        /**
         * @property
         *
         * check if it should show complete (true)
         * or truncated (false  default)
         */
        showAll: false,

        // list of employees who are to be notified
        selectedEmployees: [],

        test: 'test',

        body: Ember.computed('note.body', function () {
            var txt = this.get('note.body');
            return this.textToHTML(txt);
        }),

        // http://stackoverflow.com/questions/5007574/rendering-plaintext-as-html-maintaining-whitespace-without-pre
        textToHTML: function textToHTML(text) {
            // return text.replace(/\t/g, '    ')
            //    .replace(/  /g, '&nbsp; ')
            //    .replace(/  /g, ' &nbsp;')
            //    .replace(/\r\n|\n|\r/g, '<br />');

            return ((text || '') + ''). // make sure it's a string;
            replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\t/g, '    ').replace(/ /g, '&#8203;&nbsp;&#8203;').replace(/\r\n|\r|\n/g, '<br />');
        },


        // required by auto suggest
        relatedDeal: undefined,

        initCRMNote: Ember.on('init', function () {
            var self = this;
            var store = this.get('store');
            if (store) {
                var model = this.get('note');
                store.query('crm-note-has-deal', {
                    crm_note_id: model.get('id')
                }).then(function (noteDeals) {
                    noteDeals.forEach(function (noteDeal) {
                        noteDeal.get('deal').then(function (deal) {
                            self.set('relatedDeal', deal);
                        });
                    });
                });
            }
        }),

        actions: {
            edit: function edit() {
                this.set('isEditing', true);
            },
            cancel: function cancel() {
                this.set('isEditing', false);
            },
            save: function save(model) {
                var self = this;
                var selectedEmployees = this.get('selectedEmployees');
                var store = this.get('store');
                model.save().then(function (data) {
                    store.query('crm-note-has-deal', {
                        crm_note_id: model.get('id')
                    }).then(function (deals) {
                        deals.forEach(function (deal) {
                            deal.destroyRecord();
                        });
                    });

                    // if a deal was selected, also relate note to deal
                    var deal = self.get('relatedDeal');
                    if (typeof deal !== 'undefined' && deal.get('id') !== '') {
                        var dealData = {
                            crmNote: model,
                            deal: deal
                        };
                        var relatedDeal = store.createRecord('crm-note-has-deal', dealData);
                        relatedDeal.save();
                    }
                    // create a single email log entry if any employees are to be alerted
                    if (selectedEmployees.length > 0) {
                        var metadata = {
                            note: model.get('body')
                        };

                        var employeeIds = selectedEmployees.map(function (e) {
                            return e.get('id');
                        }).join(',');

                        self.get('notifications').send('CRMActivity', employeeIds, 'CRM Activity Alert', model.get('body'), null, null, null, JSON.stringify(metadata)).then(function () {
                            self.set('selectedEmployees', []);
                        });
                    }
                    self.get('notify').success('Note saved');
                    self.set('isEditing', false);
                }, function (reason) {
                    self.validationReport(model);
                });
            },
            showMoreOptions: function showMoreOptions() {
                Ember.$('#edit-note-more').toggleClass('hidden');
                Ember.$('#edit-note-method').toggleClass('hidden');
                Ember.$('#edit-note-options').toggleClass('hidden');
                Ember.$('#edit-note-deal').toggleClass('hidden');
            },


            /** power-select for deal */
            searchDeals: function searchDeals(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchDeals, term, resolve, reject, 600);
                });
            },

            /** power-select for category */
            searchCategories: function searchCategories(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchCategories, term, resolve, reject, 600);
                });
            }
        },
        _performSearchDeals: function _performSearchDeals(term, resolve, reject) {
            return this.get('store').query('deal', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});