define('tm3/pods/matter/workflows/list-old/route', ['exports', 'ember-lifeline/mixins/run'], function (exports, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_run.default, {
        currentMatter: Ember.inject.service(),

        model: function model(params) {
            var matterId = this.get('currentMatter.id');

            return Ember.RSVP.hash({
                simpleWorkflows: this.store.query('simple-workflow', { matter_id: matterId }),
                matterFields: this.store.query('matter-field', { matter_id: matterId }),
                subjects: this.store.query('subject', { matter_id: matterId, inactive: 0 }),
                matterHasWorkflow: this.store.query('matter-has-workflow', { matter_id: matterId, with: 'workflows' })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.subjects);
            controller.set('simpleWorkflows', resolved.simpleWorkflows);
            controller.set('matterHasWorkflow', resolved.matterHasWorkflow);
            controller.set('matterFields', resolved.matterFields);
            controller.set('subjects', resolved.subjects);
        },
        adjustScrollBar: function adjustScrollBar() {
            Ember.$('#top-scroll-bar-inner').width(Ember.$('#table-container table').width() + 'px');
        },


        actions: {
            invalidateModel: function invalidateModel() {
                this.refresh();
            },
            didTransition: function didTransition(a, b) {
                Ember.run.next(this, 'initTableContainer');
            }
        },

        initTableContainer: function initTableContainer() {
            var self = this;
            Ember.$('#top-scroll-bar-inner').width(Ember.$('#table-container table').width() + 'px');

            Ember.$(function () {
                Ember.$('#top-scroll-bar').scroll(function () {
                    Ember.$('#table-container').scrollLeft(Ember.$('#top-scroll-bar').scrollLeft());
                });
                Ember.$('#table-container').scroll(function () {
                    Ember.$('#top-scroll-bar').scrollLeft(Ember.$('#table-container').scrollLeft());
                });
                Ember.$('.fa-search').click(function () {
                    self.debounceTask('adjustScrollBar', 150);
                });
            });
        }
    });
});