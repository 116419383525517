define('tm3/helpers/ember-power-calendar-day-classes', ['exports', 'ember-power-calendar/helpers/ember-power-calendar-day-classes'], function (exports, _emberPowerCalendarDayClasses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberPowerCalendarDayClasses.default;
    }
  });
  Object.defineProperty(exports, 'emberPowerCalendarDayClasses', {
    enumerable: true,
    get: function () {
      return _emberPowerCalendarDayClasses.emberPowerCalendarDayClasses;
    }
  });
});