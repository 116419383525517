define('tm3/pods/components/matters/custom-row/component', ['exports', 'ember-light-table/components/lt-row'], function (exports, _ltRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ltRow.default.extend({
        // Color codes for different case statuses
        classNameBindings: ['caseIsInactive', 'caseIsDraft'],

        caseIsInactive: Ember.computed.equal('row.status', 'Inactive'),
        caseIsDraft: Ember.computed.equal('row.status', 'Draft')
    });
});