define('tm3/pods/components/matter/dashboard-panel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentMatter: Ember.inject.service(),

        classNames: ['matter-dashboard-panel'],
        title: 'Dashboard Panel, please pass as attribute',

        /**
            Provides the text for the module link based on the title or optional
            `linkTitle` argument passed in to this component.
             @property anchorText
            @type {String}
         */
        anchorText: Ember.computed('linkTitle', 'title', function () {
            var _getProperties = this.getProperties('linkTitle', 'title'),
                linkTitle = _getProperties.linkTitle,
                title = _getProperties.title;

            if (linkTitle) {
                return linkTitle;
            }

            return 'View All ' + title;
        }),

        matterId: Ember.computed('currentMatter.id', function () {
            return Ember.get(this, 'currentMatter.id');
        })
    });
});