define('tm3/pods/accounting/billing/manual-expense/import/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.set('currentStep', 1);
            controller.set('isProcessing', false);
        }
    });
});