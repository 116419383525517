define('tm3/pods/components/tasks/table-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        sortOptions: [{ display: 'Newest First', field: '-created_on' }, { display: 'Oldest First', field: 'created_on' }, { display: 'Assigned To', field: 'assigned_to_id' }, { display: 'Due Sooner', field: '-due_on' }, { display: 'Due Later', field: 'due_on' }, { display: 'State A-Z', field: 'state' }, { display: 'State Z-A', field: '-state' }, { display: 'County A-Z', field: 'county' }, { display: 'County Z-A', field: '-county' }, { display: 'City A-Z', field: 'city' }, { display: 'City Z-A', field: '-city' }],
        sortByAction: Ember.computed.reads('titleParams.sortByAction'),
        sortField: Ember.computed.reads('titleParams.sortField'),

        initComponent: Ember.on('init', function () {
            this.set('sortBy', this.get('sortOptions').findBy('field', this.get('sortField')));
        }),

        actions: {
            changeSortBy: function changeSortBy(newSortBy) {
                this.set('sortBy', newSortBy);
                this.get('sortByAction').call(this, newSortBy);
            }
        }
    });
});