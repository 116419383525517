define('tm3/utils/sc-calendar-event', ['exports', 'tm3/utils/sc-calendar-event-types'], function (exports, _scCalendarEventTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }

    exports.default = Ember.Object.extend({
        id: null,
        start: null,
        end: null,
        title: 'Event name',
        description: 'Event description',
        type: 'event',
        secondaryType: null,
        color: Ember.computed('type', 'secondaryType', function () {
            var type = this.get('type');
            var secondaryType = this.get('secondaryType');
            var color = '#2C71B4';
            switch (type) {
                case 'event':
                case 'report':
                    if (secondaryType) {
                        var colorForSecondaryType = _scCalendarEventTypes.default.secondaryEventTypes.findBy('name', secondaryType);
                        if (colorForSecondaryType) {
                            color = colorForSecondaryType.color;
                        }
                    } else {
                        var _colorForType = _scCalendarEventTypes.default.eventTypes.findBy('name', type);
                        if (_colorForType) {
                            color = _colorForType.color;
                        }
                    }
                    break;
                case 'case-created':
                case 'case-trial':
                    var colorForType = _scCalendarEventTypes.default.eventTypes.findBy('name', type);
                    if (colorForType) {
                        color = colorForType.color;
                    }
                    break;
            }
            return Ember.String.htmlSafe('background-color:' + color);
        }),

        typeTitle: Ember.computed('type', 'secondaryType', function () {
            var type = this.get('type');
            var secondaryType = this.get('secondaryType');
            return secondaryType ? secondaryType : titleCase(type.replace('-', ' '));
        }),

        fromEvent: function fromEvent(event) {
            this.setProperties({
                id: event.get('id'),
                start: event.get('start'),
                end: event.get('end') ? event.get('end') : event.get('start'),
                title: event.get('title'),
                description: event.get('description'),
                type: 'event',
                secondaryType: event.get('eventType')
            });
            return this;
        },
        fromTask: function fromTask(task) {
            this.setProperties({
                id: task.get('id'),
                start: task.get('dueOn'),
                end: task.get('dueOn'),
                title: task.get('comment'),
                type: 'task'
            });
            return this;
        },
        fromReportPublished: function fromReportPublished(report) {
            this.setProperties({
                id: report.get('id') + '-published',
                start: report.get('publishedOn'),
                end: report.get('publishedOn'),
                title: report.get('name'),
                secondaryType: 'Report (Published)',
                type: 'report-published'
            });
            return this;
        },
        fromReportDue: function fromReportDue(report) {
            this.setProperties({
                id: report.get('id') + '-due',
                start: report.get('dueOn'),
                end: report.get('dueOn'),
                title: report.get('name'),
                secondaryType: 'Report (Due)',
                type: 'report-due'
            });
            return this;
        },
        fromCaseCreated: function fromCaseCreated(matter) {
            this.setProperties({
                id: matter.get('id'),
                start: matter.get('createdOn'),
                end: matter.get('createdOn'),
                title: matter.get('name'),
                type: 'case-created'
            });
            return this;
        },
        fromCaseTrial: function fromCaseTrial(matter) {
            this.setProperties({
                id: matter.get('id'),
                start: matter.get('startDate'),
                end: matter.get('startDate'),
                title: matter.get('name'),
                type: 'case-trial'
            });
            return this;
        },
        fromGlobalEvent: function fromGlobalEvent(gEvent) {
            this.setProperties({
                id: gEvent.id,
                start: gEvent.start_date,
                end: gEvent.end_date ? gEvent.end_date : gEvent.start_date,
                title: gEvent.name,
                type: gEvent.type.indexOf('report-') === 0 ? 'report' : gEvent.type,
                secondaryType: gEvent.event_type
            });
            return this;
        }
    });
});