define('tm3/pods/components/document-url/component', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        metrics: Ember.inject.service(),
        baseURI: _environment.default.APP.documentDownloadURL,
        attributeBindings: ['title', 'href', 'download'],
        title: 'Download the document',
        text: false,
        icon: null,
        afterIcon: null,
        searchable: false,
        click: function click() {
            Ember.get(this, 'metrics').trackEvent({
                category: 'Documents',
                action: 'download',
                label: this.get('docId')
            });

            if (this.get('markDocumentAsViewed')) {
                this.get('markDocumentAsViewed')();
            }

            return true;
        },


        href: Ember.computed('docId', 'subjectId', 'searchable', function () {
            return this.get('baseURI') + this.get('docId') + '/download?token=' + this.get('session.data.authenticated.token') + '&subject_id=' + this.get('subjectId') + '&searchable=' + this.get('searchable');
        }),
        // suggestedFilename
        download: 'download.php',
        tagName: 'a'
    });
});