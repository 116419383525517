define('tm3/pods/matter/documents/edit/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            save: function save() {
                var self = this;
                var doc = this.get('model');
                doc.save().then(function () {
                    self.transitionToRoute('matter.documents');
                });
            }
        }
    });
});