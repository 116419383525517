define('tm3/pods/crm/activity/info/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Item = Ember.Object.extend({ id: '', name: '', type: '' });

    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        noteCategories: [],

        // hold the model that we should connect to this note
        selectedItem: undefined,

        actions: {
            // connection related logic
            hideConnectForm: function hideConnectForm() {
                Ember.$('#connectForm').addClass('hidden');
            },
            showConnectForm: function showConnectForm() {
                Ember.$('#connectForm').removeClass('hidden');
            },
            removeConnection: function removeConnection(connection) {
                connection.destroyRecord();
                this.get('notify').success('Connection removed');
            },


            // connect an item to this crm note
            newConnection: function newConnection(item) {
                var model = this.get('model');
                var self = this;
                var newObject = {
                    crmNote: model
                };

                var newConnectTo;
                var newRecord;
                if (item.type === 'Opportunity') {
                    newConnectTo = this.store.peekRecord('deal', item.id);
                    newObject.deal = newConnectTo;
                    newRecord = this.store.createRecord('crm-note-has-deal', newObject);
                } else {
                    // must be a contact
                    newConnectTo = this.store.peekRecord('client', item.id);
                    newObject.client = newConnectTo;
                    newRecord = this.store.createRecord('crm-note-has-client', newObject);
                }

                newRecord.save().then(function (data) {
                    self.get('notify').success('Connected Note to this ' + item.type);
                    // this.get('model').pushObject(newRecord);
                    if (item.type === 'Opportunity') {} else {
                        // must be a contact
                    }
                    self.send('hideConnectForm');
                    self.set('selectedItem', undefined);
                }, function (reason) {
                    self.validationReport(newRecord);
                });
            },


            // when the user types new values into the select box
            // this can also act as a place to perform custom filtering/munging/merging of API results
            // before pushing the final list to the autosuggest
            searchDealAndClient: function searchDealAndClient(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._searchDealAndClient, term, resolve, reject, 600);
                });
            }
        },

        _searchDealAndClient: function _searchDealAndClient(inputVal, resolve, reject) {
            var self = this;
            var wildcard = '*';

            var deals = self.store.query('deal', {
                name: wildcard + inputVal + wildcard,
                limit: 25,
                sortField: 'name'
            });
            var clients = self.store.query('client', {
                'first_name||last_name': '*' + inputVal + wildcard,
                limit: 25,
                sortField: 'last_name,first_name'
            });

            Ember.RSVP.all([deals, clients]).then(function (array) {
                // process all promises here
                var optionsList = [];
                var deals = array[0];
                var clients = array[1];

                // build generic object from each account object and add to optionsList array
                deals.forEach(function (deals) {
                    var item = Item.create({
                        id: deals.get('id'),
                        name: deals.get('name'),
                        type: 'Opportunity'
                    });
                    optionsList.pushObject(item);
                });

                // build generic object from each client object and add to optionsList array
                clients.forEach(function (client) {
                    var item = Item.create({
                        id: client.get('id'),
                        name: client.get('fullName'),
                        type: 'Contact'
                    });
                    optionsList.pushObject(item);
                });

                // sort optionsList by name
                optionsList.sort(function (a, b) {
                    var x = a.name.toLowerCase();
                    var y = b.name.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });

                // set optionsList on the controller
                resolve(optionsList);
            });
        }
    });
});