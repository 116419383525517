define('tm3/pods/report/info/budget-utilization/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        reportBuilder: Ember.inject.service(),
        actions: {
            getReport: function getReport(format) {
                var jsonOptions = {
                    report_builder: {
                        standard: {
                            type: 'budget_utilization',
                            format: format,
                            style: 'misc'
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonOptions);
            }
        }
    });
});