define('tm3/pods/report/info/billed-cases-by-month/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['month'],

        month: '',

        reportCustomParams: Ember.computed('month', function () {
            return { month: this.get('month') };
        }),

        actions: {
            clearMonth: function clearMonth() {
                this.set('month', '');
            }
        }
    });
});