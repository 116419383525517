define('tm3/pods/me/dashboard/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),

        queryParams: {
            month: {
                refreshModel: true
            },
            bonusReportYear: {
                refreshModel: true
            }
        },

        model: function model(params) {
            // eslint-disable-next-line no-param-reassign
            params.employee_id = this.get('session.data.authenticated.id');

            return Ember.RSVP.hash({
                passive_events: this.store.query('passive-event', {
                    // @TODO use a column alerted to
                    created_by_id: params.employee_id,
                    sort: '-created_on',
                    exclude_jury: true,
                    with: 'matters',
                    limit: 10
                }),
                notes: this.store.query('note', {
                    limit: 10,
                    sort: 'created_on DESC',
                    created_by_id: params.employee_id,
                    exclude_jury: true,
                    note_type: 4 // case-note
                }),
                myTimeTableData: this.makeRequest({
                    type: 'my_time',
                    style: 'tabular',
                    from: (0, _moment.default)().subtract(5, 'days').toISOString(),
                    to: (0, _moment.default)().toISOString(),
                    per_page: 20000,
                    page: params.page ? params.page : 1
                }),
                employee: this.store.findRecord('employee', params.employee_id),
                billableHours: this.get('ajax').request('employees/get_billable_hours_of_month', {
                    data: {
                        employee_id: params.employee_id,
                        month: params.month
                    }
                }),
                bonusData: this.makeRequest({
                    type: 'bonus_report',
                    style: 'tabular',
                    year: params.bonusReportYear,
                    employee_id: params.employee_id,
                    per_page: 20000,
                    page: params.page ? params.page : 1
                }),
                params: params
            });
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        },
        setupController: function setupController(controller, model) {
            if (model.params.month) {
                controller.set('currentMonth', controller.get('months').findBy('id', model.params.month));
            } else {
                controller.set('currentMonth', controller.get('months.firstObject'));
            }

            this._super(controller, model);
        },


        /**
        Makes a request to the API server.
        @method makeRequest
        @param {Object} data The request data
        @return {Ember.RSVP.Promise}
        @protected
        */
        makeRequest: function makeRequest(data) {
            return this.get('ajax').request('report_builder', { data: data }).catch(function (error) {
                return error.message;
            });
        }
    });
});