define('tm3/pods/report/info/sales-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['owner'],

        owner: null,

        reportCustomParams: Ember.computed('owner', function () {
            return { owner_id: this.get('owner') ? this.get('owner') : 'false' };
        }),
        employeesSorting: ['lastName:asc'],
        employees: Ember.computed.sort('model.employees', 'employeesSorting'),

        actions: {
            clearOwner: function clearOwner() {
                this.set('selectedEmployee', '');
                this.set('owner', null);
            },
            selectEmployee: function selectEmployee(employee) {
                this.set('selectedEmployee', employee);
                this.set('owner', employee.get('id'));
            }
        }
    });
});