define('tm3/services/relevant-content-updater', ['exports', 'tm-common/services/relevant-content-updater'], function (exports, _relevantContentUpdater) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _relevantContentUpdater.default;
    }
  });
});