define('tm3/pods/report/info/payment-report/route', ['exports', 'moment', 'tm3/mixins/report-info-route'], function (exports, _moment, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'payment_report',

        queryParams: {
            paidOn: {
                refreshModel: false
            },
            tmCode: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params+
         * @returns {*}
         */
        model: function model(params) {
            if (!params.paidOn && !params.tmCode) {
                params.paidOn = this.getDayBefore();
            }
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    paid_on: params.paidOn,
                    tm_code: params.tmCode
                }),
                paidOn: params.paidOn,
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('paidOn', resolved.paidOn);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
        },
        getDayBefore: function getDayBefore() {
            return (0, _moment.default)().subtract(1, 'days').format('MM-DD-YYYY');
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});