define('tm3/pods/components/log-in-as-user/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        permissions: Ember.inject.service(),
        notifications: Ember.inject.service('notifications'),
        notify: Ember.inject.service(),
        router: Ember.inject.service(),
        store: Ember.inject.service(),
        tagName: '',
        userId: null,

        canSeeLoginAsUser: Ember.computed(function () {
            return this.get('permissions').check(['Impersonate User']);
        }).volatile(),

        actions: {
            loginAsUser: function loginAsUser() {
                var _this = this;

                var userId = this.get('userId');
                if (Ember.isEmpty(userId)) {
                    this.get('notify').warning('User Id is required');
                    return;
                }

                var app = 'tm-admin';

                this.get('store').createRecord('user').impersonateUser({ user_id: userId, app: app }).then(function (resp) {
                    _this.get('notifications').stopNotificationsUpdate();

                    var newToken = resp.token;
                    window.location.assign(_this.get('router').urlFor('matters', { queryParams: { newtoken: newToken } }));
                });
            }
        }
    });
});