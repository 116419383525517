define('tm3/mixins/scheduling-route', ['exports', 'tm-common/mixins/crud/error', 'moment'], function (exports, _error, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_error.default, {
        session: Ember.inject.service(),

        queryParams: {
            generateEmails: {
                refrehsModel: true
            }
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            // Initial population of schedule
            controller.get('getDateRange').perform();
            controller.get('getEmployeeSchedules').perform();
            controller.get('getSummary').perform();

            controller.set('today', (0, _moment.default)(new Date()).format('YYYY-MM-DD'));
        }
    });
});