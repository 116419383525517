define('tm3/pods/tags/browse/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Case Tag Browser'],

        // indicate that when this query param changes, refresh the route
        queryParams: {
            filterList: {
                refreshModel: true
            },
            privateFilterList: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var matterParams = { with: 'matters', matter_tag_id: '' };

            var tagFilters = params.filterList;

            if (tagFilters) {
                // pass this along for further processing
                matterParams.matter_tag_id = tagFilters;
            }
            var privateMatterParams = { with: 'matters', matter_private_tag_id: '' };
            var privateTagFilters = params.privateFilterList;

            if (privateTagFilters) {
                // pass this along for further processing
                privateMatterParams.matter_private_tag_id = privateTagFilters;
            }

            return Ember.RSVP.hash({
                tags: this.store.findAll('matter-tag', { sortField: 'name' }),
                matterTags: this.store.query('matter-has-tag', matterParams),
                filterList: params.filterList,
                privateTags: this.store.findAll('matter-private-tag', { sortField: 'name' }),
                matterPrivateTags: this.store.query('matter-has-private-tag', privateMatterParams),
                privateFilterList: params.privateFilterList
            });
        },
        setupController: function setupController(controller, resolved) {
            // public tags
            var filterList = resolved.filterList;
            var activeTags = [];
            var availableTags = [];
            var tags = resolved.tags;
            // special logic to divide tags
            tags.forEach(function (tag) {
                var active = 0;
                filterList.forEach(function (id) {
                    if (id === tag.get('id')) {
                        active = 1;
                    }
                });

                if (active === 1) {
                    activeTags.pushObject(tag);
                } else {
                    availableTags.pushObject(tag);
                }
            });
            // private tags
            var privateFilterList = resolved.privateFilterList;
            var activePrivateTags = [];
            var availablePrivateTags = [];
            var privateTags = resolved.privateTags;
            // special logic to divide tags
            privateTags.forEach(function (tag) {
                var active = 0;
                privateFilterList.forEach(function (id) {
                    if (id === tag.get('id')) {
                        active = 1;
                    }
                });

                if (active === 1) {
                    activePrivateTags.pushObject(tag);
                } else {
                    availablePrivateTags.pushObject(tag);
                }
            });

            this._super(controller, {});
            controller.set('activeTags', activeTags);
            controller.set('availableTags', availableTags.sortBy('name'));
            controller.set('filterList', filterList);
            controller.set('activePrivateTags', activePrivateTags);
            controller.set('availablePrivateTags', availablePrivateTags.sortBy('name'));
            controller.set('privateFilterList', privateFilterList);

            /**
             * a bunch of logic to eliminate duplicates
             */
            var matterList = [];
            resolved.matterTags.forEach(function (item) {
                var matter = item.get('matter');
                var matterId = matter.get('id');
                var match = matterList.findBy('id', matterId);
                if (match) {} else {
                    matterList.pushObject(matter);
                }
            });
            controller.set('matterTags', matterList);
            var privateMatterList = [];
            resolved.matterPrivateTags.forEach(function (item) {
                var matter = item.get('matter');
                var matterId = matter.get('id');
                var match = matterList.findBy('id', matterId);
                if (match) {} else {
                    privateMatterList.pushObject(matter);
                }
            });
            controller.set('matterPrivateTags', privateMatterList);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});