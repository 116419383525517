define('tm3/pods/components/accounting/billing/invoices/budget-mass-update/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('selectedBudgetId', null);
        },

        actions: {
            onBudgetEdit: function onBudgetEdit() {
                this.set('selectedBudgetId', null);
            },
            onBudgetCancel: function onBudgetCancel() {
                var currentBudgetId = null;
                this.set('selectedBudgetId', currentBudgetId);
            },
            saveBudget: function saveBudget() {
                var newBudgetId = this.get('selectedBudgetId');
                this.sendAction('save', newBudgetId);
            },
            budgetChange: function budgetChange(val) {
                var newVal = val ? val.id : null;
                this.set('selectedBudgetId', newVal);
            }
        },
        selectedBudgetId: null,
        currentSelectedBudget: Ember.computed('budgets.[]', 'selectedBudgetId', {
            get: function get() {
                var budgets = this.get('budgets');
                if (budgets) {
                    var selectedBudgetId = this.get('selectedBudgetId');
                    return budgets.findBy('id', selectedBudgetId);
                }
            }
        })
    });
});