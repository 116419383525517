define('tm3/pods/admin/matter-type/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        // logic to handle sorting a list
        sortField: 'name',
        appName: 'tm2-admin',

        // load pager specific variables
        columns: [_column.default.create({ displayName: '#', fieldName: 'id', order: 0 }), _column.default.create({ displayName: 'Prefix', fieldName: 'prefix', order: 0 }), _column.default.create({ displayName: 'Name', fieldName: 'name', order: 1 })],

        // need to open single matter record
        linkPath: 'admin.matter-type.info.workflow-default',

        tableTitle: 'Case Types',

        actions: {}
    });
});