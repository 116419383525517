define('tm3/pods/accounting/billing/manual-expense/edit/controller', ['exports', 'tm3/mixins/manual-expense-edit-controller'], function (exports, _manualExpenseEditController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_manualExpenseEditController.default, {
        newExpensesList: 'model.newExpenses',
        expensesLists: ['model.billables', 'model.newExpenses'],
        isEdit: true
    });
});