define('tm3/pods/components/matter/dashboard/budget-stats/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        admin: false,
        editable: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('budgetProgress', this.get('budget.budgetProgress'));
            this.get('budget').parseItems();
        },


        actions: {
            edit: function edit() {
                this.sendAction('edit', this.get('budget'));
            },
            delete: function _delete() {
                this.sendAction('delete', this.get('budget'));
            },
            duplicate: function duplicate() {
                this.sendAction('duplicate', this.get('budget'));
            }
        }
    });
});