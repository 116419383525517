define('tm3/pods/case-management/global-list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentRoute: 'case-management.global-list',

        queryParams: {
            collection_id: {
                refreshModel: true
            }
        },

        modelName: 'collection-has-matter',
        session: Ember.inject.service(),
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),
        beforeModel: function beforeModel() {
            // this.store.findRecord('collection', 19)
            this.transitionTo('matter-list');
        },
        model: function model(params) {
            return Ember.RSVP.hash({
                workflows: this.store.query('workflow', { workflow_type: 'matter' }),
                collections: this.store.findAll('collection'),
                commonColumns: this.store.createRecord('collection').getCommonColumns({}),
                workflowStatuses: this.get('lists').getListForGroup('workflow_status'),
                collection_id: params.collection_id
            });
        },
        setupController: function setupController(controller, resolved) {
            var params = { active: true };
            if (resolved.collection_id) {
                params.collection_id = resolved.collection_id;
                controller.set('collection', resolved.collections.findBy('id', resolved.collection_id));
            }

            var filters = JSON.parse(window.localStorage.getItem('global-list-raw-filters'));
            if (Ember.isPresent(filters)) {
                for (var f in filters) {
                    params[Ember.String.underscore(f)] = filters[f];
                }
                controller.set('savedFilters', JSON.parse(window.localStorage.getItem('global-list-structured-filters')));
            }

            controller.set('loadDataParams', params);
            controller.set('currentEmployee', this.store.findRecord('employee', this.get('session.data.authenticated.id')));

            controller.set('workflows', resolved.workflows);
            controller.set('workflowStatuses', resolved.workflowStatuses);
            controller.set('collections', resolved.collections);

            controller.set('commonColumns', resolved.commonColumns.collection_common_columns || []);

            controller.set('cmController', this.controllerFor('case-management'));
            this._super(controller, resolved);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            },
            afterSaveCaseGroup: function afterSaveCaseGroup() {
                this.refresh();
                this.get('notify').success('The new group has been saved');
                this.controller.set('isAddingCaseGroup', false);
            },
            afterCancelCaseGroup: function afterCancelCaseGroup() {
                this.controller.set('isAddingCaseGroup', false);
            },
            afterSaveCase: function afterSaveCase() {
                this.refresh();
                this.send('selectAll', false);
                this.get('notify').success('Case(s) added to selected group');
                this.controller.set('isAddingCase', false);
            },
            afterCancelCase: function afterCancelCase() {
                this.controller.set('isAddingCase', false);
            }
        }
    });
});