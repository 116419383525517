define('tm3/pods/crm/contacts/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var PhoneHolder = Ember.Object.extend({ number: '', type: '', counter: 0 });

    exports.default = Ember.Route.extend(_route.default, {
        lists: Ember.inject.service(),
        queryParams: {
            quickSearchField: {
                refreshModel: true
            },
            q: {
                refreshModel: true
            }
        },
        modelName: 'client',

        with: 'firm_has_clients',

        // the name of the current controller since I don't know how to auto detect
        controllerName: 'crm.contacts.list',

        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('q', controller.get('quickSearch'));
            controller.set('clientTypes', resolved.lists['crm_client_type']);
            controller.set('phoneTypes', resolved.lists['phone_type']);
            controller.set('initialPhoneHolder', resolved.initialPhoneHolder);
            controller.set('currentPhoneHolder', resolved.initialPhoneHolder);
            controller.set('additionalPhoneHolders', []);
            controller.set('relationships', resolved.lists['contact_account_relations']);
            controller.set('firmRelationship', controller.get('relationships.firstObject'));
            controller.set('userTitles', resolved.lists['user_titles']);
            controller.set('userNameSuffixes', resolved.lists['user_name_suffixes']);
            controller.set('tiers', resolved.lists['copper_tiers']);
        },
        model: function model(params) {
            // remove the query params b/c are sent to the backend and return error
            delete params.q;
            delete params.quickSearchField;
            return Ember.RSVP.hash({
                model: this._super(params),
                lists: this.get('lists').fetchMultipleLists(['crm_client_type', 'phone_type', 'contact_account_relations', 'user_titles', 'user_name_suffixes', 'copper_tiers']),
                initialPhoneHolder: PhoneHolder.create({
                    number: '',
                    type: 'Work',
                    counter: 1
                })
            });
        },


        actions: {
            willTransition: function willTransition(transition) {
                var target = transition.targetName;
                if (target !== 'crm.contacts.list') {
                    this.controller.set('q', '');
                    this.controller.set('quickSearch', '');
                }
            },
            didTransition: function didTransition() {
                var self = this;
                Ember.run.schedule('afterRender', self, function () {
                    Ember.$('.quickSearchInput').focus();
                });
            },
            changeQuickSearchField: function changeQuickSearchField(quickSearchField) {
                this.controller.set('quickSearchField', quickSearchField);
            },
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});