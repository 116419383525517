define('tm3/mixins/case-management/info/edit-chm-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        notify: Ember.inject.service(),
        goBack: Ember.inject.service(),

        allFields: Ember.computed('collectionFields', 'collectionHasMatterColumns', function () {
            var fields = [];
            fields.pushObjects(this.get('collectionFields').map(function (x) {
                return Ember.Object.create({
                    type: 'collection-field',
                    record: x,
                    order: x.get('weight')
                });
            }));
            fields.pushObjects(this.get('collectionHasMatterColumns').map(function (x) {
                return Ember.Object.create({
                    type: 'matter-column',
                    record: x,
                    order: x.get('weight')
                });
            }));
            return fields.sortBy('order');
        }),

        actions: {
            selectVenueState: function selectVenueState(selectedState) {
                var matter = this.get('matter');
                matter.set('venueState', selectedState.get('value'));
            },
            selectMatterType: function selectMatterType(selectedMatterType) {
                var matter = this.get('matter');
                matter.set('matterTypeWritable', selectedMatterType);
            },
            selectTypeOfService: function selectTypeOfService(selectedTypeOfService) {
                var matter = this.get('matter');
                var list = [];
                selectedTypeOfService.forEach(function (selTypeOfService) {
                    list.push(selTypeOfService.get('value'));
                });
                matter.set('typeOfService', list);
            },
            selectLitigationType: function selectLitigationType(selectedLitigationType) {
                var matter = this.get('matter');
                matter.set('litigationType', selectedLitigationType.get('value'));
            },
            selectCaseOwner: function selectCaseOwner(selectedCaseOwner) {
                var matter = this.get('matter');
                matter.set('caseOwnerWritable', selectedCaseOwner);
            },
            selectCaseSupervisor: function selectCaseSupervisor(selectedCaseSupervisor) {
                var matter = this.get('matter');
                matter.set('caseSupervisorWritable', selectedCaseSupervisor);
            },
            selectMonitorStatus: function selectMonitorStatus(selectedMonitorStatus) {
                var matter = this.get('matter');
                matter.set('monitorStatus', selectedMonitorStatus.get('value'));
            },
            selectAnalyticStatus: function selectAnalyticStatus(selectedAnalyticStatus) {
                var matter = this.get('matter');
                matter.set('analyticStatus', selectedAnalyticStatus.get('value'));
            },
            selectCaseStatus: function selectCaseStatus(selectedCaseStatus) {
                var matter = this.get('matter');
                matter.set('caseStatus', selectedCaseStatus.get('value'));
            },
            selectStatus: function selectStatus(selectedStatus) {
                var matter = this.get('matter');
                matter.set('status', selectedStatus.get('value'));
            },
            save: function save() {
                var _this = this;

                var matter = this.get('matter');
                var collectionHasMatter = this.get('collectionHasMatter');
                var promises = [];

                // Add a case note when applicable
                var customFieldsWithCaseNote = this.get('allFields').filterBy('type', 'collection-field').filter(function (r) {
                    return ['textWithCaseNote', 'longtextWithCaseNote'].includes(r.get('record.renderType'));
                });
                if (!Ember.isEmpty(customFieldsWithCaseNote)) {
                    var changedAttributes = collectionHasMatter.changedAttributes();
                    var changedFields = Object.keys(changedAttributes).map(function (attributeName) {
                        return {
                            key: attributeName,
                            earlierValue: changedAttributes[attributeName][0],
                            currentValue: changedAttributes[attributeName][1]
                        };
                    });
                    customFieldsWithCaseNote.forEach(function (customField) {
                        var columnName = customField.get('record.value');
                        if (!Ember.isEmpty(changedFields.filterBy('key', columnName))) {
                            var newNote = _this.store.createRecord('note', {
                                matter: matter,
                                sendEmail: false,
                                noteType: '4',
                                body: collectionHasMatter.get(columnName)
                            });
                            promises.push(newNote.save());
                        }
                    });
                }

                promises.push(matter.save());
                promises.push(collectionHasMatter.save());
                Ember.RSVP.all(promises).then(function () {
                    _this.get('notify').success('Data has been saved');

                    if (!_this.get('goBack').back()) {
                        _this.transitionToRoute('case-management.global-list', {
                            queryParams: {
                                collection_id: _this.get('collectionHasMatter.collection.id')
                            }
                        });
                    }
                }, function () {
                    _this.get('notify').error('Error occurred.');
                });
            }
        }
    });
});