define('tm3/pods/matter/timekeeper-rate-sheet/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'timekeeper_rate_sheet',

        model: function model(params) {
            var model = this.modelFor('matter');

            return Ember.RSVP.hash({
                matter: model.matter,

                timekeeperRateSheetData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super.apply(this, arguments);

            if (typeof resolved.timekeeperRateSheetData === 'string') {
                controller.set('noAccess', true);
            }
        }
    });
});