define('tm3/pods/components/admin/appl-lists-categories/category-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        editing: false,
        tagName: 'li',
        classNames: ['list-group-item'],
        initialName: null,
        resetInitialName: Ember.on('init', function () {
            this.set('initialName', this.get('category.categoryName'));
        }),
        didUpdateAttrs: function didUpdateAttrs() {
            this.set('initialName', this.get('category.categoryName'));
        },

        showWarning: Ember.computed('initialName', 'category.categoryName', function () {
            return this.get('initialName') !== this.get('category.categoryName');
        }),

        actions: {
            cancel: function cancel() {
                this.get('category').rollbackAttributes();
                this.toggleProperty('editing');
            },
            updateCategory: function updateCategory(category) {
                if (category.get('categoryName') === '') {
                    this.get('notify').error('You must enter a Category Name');
                    this.set('editing', true);
                } else {
                    this.attrs.updateApplListCategory(category);
                }
            }
        }
    });
});