define('tm3/pods/components/accounting/billing/invoices/manual-expense-row/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        tagName: 'tr',
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var currentBudgetId = this.get('manualBillable.budget_id');
            this.set('selectedBudgetId', currentBudgetId);
        },

        actions: {
            onBudgetEdit: function onBudgetEdit() {
                var currentBudgetId = this.get('manualBillable.budget_id');
                this.set('selectedBudgetId', currentBudgetId);
            },
            onBudgetCancel: function onBudgetCancel() {
                var currentBudgetId = this.get('manualBillable.budget_id');
                this.set('selectedBudgetId', currentBudgetId);
            },
            saveBudget: function saveBudget() {
                var newBudgetId = this.get('selectedBudgetId');
                this.sendAction('saveBudget', this.get('manualBillable.id'), newBudgetId);
            },
            budgetChange: function budgetChange(val) {
                var newVal = val ? val.id : null;
                this.set('selectedBudgetId', newVal);
            },
            editQtyCut: function editQtyCut() {
                if (this.get('canEdit')) {
                    this.sendAction('editQtyCut', this.get('manualBillable'));
                }
            },
            cancelQtyCutEdit: function cancelQtyCutEdit() {
                this.sendAction('cancelQtyCutEdit');
            },
            saveQtyCut: function saveQtyCut(cut, qty) {
                this.sendAction('saveQtyCut', this.get('manualBillable'), cut, qty);
            },
            saveAdjustment: function saveAdjustment(billable, newAdjustment) {
                var _this = this;

                var bill = this.get('store').peekRecord('billable', billable.id);
                if (!bill) {
                    this.get('notify').warning('Record not found to update');
                    return;
                }
                bill.set('adjustment', newAdjustment);
                bill.save().then(function () {
                    _this.get('notify').success('Saved successfully');
                    billable.adjustment = newAdjustment;
                    billable.total = bill.get('total');
                }).catch(function () {
                    _this.validationReport(bill);
                });
            },
            onAdjustmentCancel: function onAdjustmentCancel(billable) {
                var original = 0;
                billable.adjustment = original;
            }
        },
        selectedBudgetId: null,
        currentSelectedBudget: Ember.computed('budgets.[]', 'selectedBudgetId', {
            get: function get() {
                var budgets = this.get('budgets');
                if (budgets) {
                    var selectedBudgetId = this.get('selectedBudgetId');
                    return budgets.findBy('id', selectedBudgetId);
                }
            }
        }),
        currentBudget: Ember.computed('budgets.[]', 'manualBillable.budget_id', {
            get: function get() {
                var budgets = this.get('budgets');
                if (budgets) {
                    var currentBudgetId = this.get('manualBillable.budget_id');
                    return budgets.findBy('id', '' + currentBudgetId);
                }
            }
        })
    });
});