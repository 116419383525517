define('tm3/mixins/with-full-height-pages', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        application: Ember.inject.controller(),

        // override to define which subpages need full height
        _fullHeightPages: [],

        contentWrapperClass: Ember.computed('application.currentRouteName', function () {
            return this._fullHeightPages.includes(this.get('application.currentRouteName')) ? 'content-wrapper--fullheight' : null;
        })
    });
});