define('tm3/pods/components/table-pagination/title-with-add-new/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reads = Ember.computed.reads;
    exports.default = Ember.Component.extend({
        classNames: ['box-header', 'with-border'],
        showNewItem: Ember.computed('titleParams', function () {
            if ('showNewItem' in this.get('titleParams')) {
                return this.get('titleParams.showNewItem');
            }
            return true;
        }),
        icon: reads('titleParams.icon'),
        newItemClass: reads('titleParams.newItemClass'),
        newItemLabel: reads('titleParams.newItemLabel'),
        newItemLink: reads('titleParams.newItemLink'),
        newItemTitle: reads('titleParams.newItemTitle'),
        newItemAction: reads('titleParams.newItemAction')
    });
});