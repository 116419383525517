define('tm3/pods/accounting/billing/tax-rules/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        requireAnyPermission: ['Manage Invoices', 'See Invoices'],
        currentRoute: 'accounting.billing.tax-rules',
        modelName: 'tax_rule',

        queryParams: {
            refresh: {
                refreshModel: true
            }
        },

        model: function model(params) {
            delete params.refresh;
            return {};
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {});
            this._super(controller, resolved);
        }
    });
});