define('tm3/pods/matter-list-dashboard/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            owner_id: {
                refreshModel: true
            },
            due_on: {
                refreshModel: true
            },
            case_status: {
                refreshModel: true
            },
            show_hard_deadlines: {
                refreshModel: true
            },
            show_soft_deadlines: {
                refreshModel: true
            },
            show_tr_tp: {
                refreshModel: true
            },
            show_only_past_due: {
                refreshModel: true
            },
            show_pushed_back: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                employees: this.store.query('employee', {
                    sort: 'last_name,first_name',
                    role: ['Executive', 'Case Manager'],
                    status: 'Active'
                }),
                params: params
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            if (model.params.owner_ids) {
                var owners = model.params.owner_ids.map(function (id) {
                    return model.employees.findBy('id', '' + id);
                });
                controller.set('selectedOwners', owners);
            }
            if (model.params.due_on) {
                controller.set('selectedDue', controller.get('dueOptions').findBy('value', model.params.due_on));
            }

            controller.get('getDueByOwner').perform(model.params.owner_ids, model.params.due_on);
        },


        actions: {
            refreshModel: function refreshModel() {
                var scrollY = window.scrollY;
                this.refresh();
                Ember.run.later(function () {
                    window.scrollTo(0, scrollY);
                }, 500);
            }
        }
    });
});