define('tm3/pods/components/matter/alert-users/component', ['exports', 'ember-component-inbound-actions/inbound-actions', 'tm3/pods/components/matter/alert-users/template'], function (exports, _inboundActions, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inboundActions.default, {
        layout: _template.default,

        contactsTitle: 'Alert Case Contacts',
        employeesTitle: 'Alert Case Employees',

        employees: [],
        teams: [],

        contacts: [],
        contactTeams: [],

        selectedContacts: [],

        reportGroup: null,

        collapsed: false,
        showCollapseExpand: false,

        ccAccounting: false,

        observeContacts: Ember.observer('contacts.@each.isSelected', function () {
            this.set('selectedContacts', this.get('contacts').filterBy('isSelected'));
        }),

        observeEmployees: Ember.observer('employees.@each.isSelected', function () {
            this.set('selectedEmployees', this.get('employees').filterBy('isSelected'));
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            Ember.run.next(function () {
                if (!_this.get('isDestroyed')) {
                    _this.set('selectedContacts', _this.get('contacts').filterBy('isSelected'));
                    _this.set('selectedEmployees', _this.get('employees').filterBy('isSelected'));
                }
            });
        },


        // Reset changes on list of contacts or employees
        willDestroy: function willDestroy() {
            this.get('contacts').setEach('isSelected', false);
            this.get('employees').setEach('isSelected', false);
            this.set('selectedContacts', []);
            this.set('selectedEmployees', []);
        },


        actions: {
            selectAllEmployees: function selectAllEmployees() {
                this.get('employees').setEach('isSelected', true);
            },
            selectNoneEmployees: function selectNoneEmployees() {
                this.get('employees').setEach('isSelected', false);
            },
            selectTeamEmployees: function selectTeamEmployees(team) {
                team.get('employees').setEach('isSelected', team.get('isSelected'));
            },
            selectAllContacts: function selectAllContacts() {
                this.get('contacts').setEach('isSelected', true);
            },
            selectNoneContacts: function selectNoneContacts() {
                this.get('contacts').setEach('isSelected', false);
            },
            selectTeamContacts: function selectTeamContacts(team) {
                team.get('contacts').setEach('isSelected', team.get('isSelected'));
            },
            refresh: function refresh() {
                if (Ember.typeOf(this.attrs.refreshContacts) === 'function') {
                    this.attrs.refreshContacts();
                }
            }
        }
    });
});