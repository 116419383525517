define('tm3/pods/components/case-management/custom-row/component', ['exports', 'ember-light-table/components/lt-row', 'tm3/pods/components/case-management/custom-row/template'], function (exports, _ltRow, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ltRow.default.extend({
        caseIsDraft: Ember.computed.equal('row.status', 'Draft'),
        caseIsInactive: Ember.computed.equal('row.status', 'Inactive'),
        isCollapsed: Ember.computed.alias('row.isCollapsed'),
        layout: _template.default,

        init: function init() {
            this._super.apply(this, arguments);

            // Collapse rows by default
            this.set('row.isCollapsed', true);
        },


        /**
        Supports fixed height rows (along with bound `isCollapsed` property).
         @property classNames
        @type {Array}
        */
        classNames: ['is-collapsible'],

        /**
        Color codes for case status and support for fixed-height rows.
         @property classNameBindings
        @type {Array}
        */
        classNameBindings: ['caseIsInactive', 'caseIsDraft', 'isCollapsed']
    });
});