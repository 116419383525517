define('tm3/components/charts/custom-bar-chart/bar-char-bar-vert', ['exports', 'tm-common/components/charts/custom-bar-chart/bar-char-bar-vert'], function (exports, _barCharBarVert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _barCharBarVert.default;
    }
  });
});