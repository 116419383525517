define('tm3/pods/components/relevant-post-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        documentTypes: [Ember.Object.extend({}).create({
            value: 'POST',
            display: 'POST'
        })],

        observeUrl: Ember.observer('post.url', function () {
            var allowed = ['http://', 'https://'];
            var value = this.get('post.url') || '';
            var len = value.length;

            if (value) {
                var fix = true;
                for (var i = 0; i < allowed.length; i++) {
                    var protocol = allowed[i];

                    if (len <= protocol.length) {
                        if (protocol.substring(0, len) === value) {
                            fix = false;
                            break;
                        }
                    } else {
                        if (value.substring(0, protocol.length) === protocol) {
                            fix = false;
                            break;
                        }
                    }
                }

                if (fix) {
                    this.set('post.url', 'http://' + value);
                }
            }
        }),
        actions: {
            beforeUpload: function beforeUpload(filesToUpload) {
                filesToUpload.forEach(function (file) {
                    file.set('docType', 'POST');
                });
            },
            afterUpload: function afterUpload(response) {
                var _this = this;

                if (Ember.isEmpty(response.documents)) {
                    this.get('notify').error('No documents response');
                    return;
                }
                var saveDocument = function saveDocument(document) {
                    if (!_this.get('post.isNew') && !_this.get('post.isSaving')) {
                        _this.get('post').set('documentResource', document);
                        _this.get('post').save();
                    } else {
                        Ember.run.later(function () {
                            saveDocument(document);
                        }, 500);
                    }
                };
                this.get('store').findRecord('document', response.documents[0].id).then(saveDocument);
            },
            errorUpload: function errorUpload() {
                this.get('notify').error('There was a problem uploading the files');
            },
            cancelUpload: function cancelUpload() {
                this.set('showUploadForm', false);
            },
            deleteDocument: function deleteDocument() {
                this.set('post.documentResource', null);
            }
        }
    });
});