define('tm3/services/analytic-list', ['exports', 'tm-common/services/analytic-list'], function (exports, _analyticList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _analyticList.default;
    }
  });
});