define('tm3/pods/matter/admin/accounts/list/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        fhmhtIds: null,
        fhmhrIds: null,
        account: null,
        showCreateAccount: false,

        actions: {
            /**
             * prepares a new firm-has-matter record
             * and show the modal to edit it
             */
            showAccountForm: function showAccountForm() {
                var matter = this.get('model.matter');
                this.set('account', this.store.createRecord('firm-has-matter', {
                    matter: matter,
                    primary: false,
                    defendant: false
                }));
            },


            /**
             * sets the firm-has-matter record
             * and show the modal to edit it
             */
            showEditForm: function showEditForm(account) {
                this.set('account', account);
                this.set('fhmhtIds', account.get('firmHasMatterHasTaxes').mapBy('id'));
            },
            createFirmAccount: function createFirmAccount(firmData) {
                var _this = this;

                var firm = this.store.createRecord('firm', firmData);
                firm.save().then(function (savedFirm) {
                    _this.set('account.firm', savedFirm);
                    _this.get('notify').success('Firm has been created');
                    _this.set('showCreateAccount', false);
                }, function () {
                    _this.validationReport(firm);
                });
            }
        }
    });
});