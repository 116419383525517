define('tm3/pods/matter/updates/controller', ['exports', 'ember-group-by'], function (exports, _emberGroupBy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sortProperties: ['createdOn:desc'],
        sortedModel: Ember.computed.sort('model', 'sortProperties'),
        groupedUpdates: (0, _emberGroupBy.default)('sortedModel', 'createdDay')
    });
});