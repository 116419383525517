define('tm3/pods/smm/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'scrape',
        currentRoute: 'smm',
        controllerName: 'smm',

        queryParams: {
            filterParams: {
                refreshModel: true
            },
            filter: {
                refreshModel: false
            }
        },

        model: function model(params) {
            return { params: params };
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {});

            var params = resolved.params;

            if (params.filter && !controller.get('loadedFromTableSettings')) {
                var jsonFilter = JSON.parse(params.filter);
                controller.set('currentControllerState', jsonFilter);
                controller.set('enableObservers', false);
                controller.fromTableSettingsState(controller.get('currentControllerState'));
                controller.updateColumnsFromTableSettingState(controller.get('currentControllerState'));
                controller.set('enableObservers', true);
            }

            this._super(controller, resolved);

            controller.set('filter', JSON.stringify(controller.toTableSettingsState()));
            controller.set('loadedFromTableSettings', true);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});