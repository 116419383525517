define('tm3/pods/components/accounting/billing/invoices/description-edit/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        description: '',
        currentValue: '',
        billableId: null,
        editing: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this.set('description', this.get('currentValue'));
        },


        actions: {
            cancel: function cancel() {
                this.set('description', this.get('currentValue'));
                this.set('editing', false);
            },
            save: function save() {
                var _this = this;

                if (Ember.isEmpty(this.get('description'))) {
                    this.get('notify').warning('Please enter a description');
                    return;
                }
                this.get('store').findRecord('billable', this.get('billableId')).then(function (billable) {
                    billable.set('description', _this.get('description'));
                    billable.save().then(function () {
                        _this.get('notify').success('Description saved');
                        _this.set('currentValue', _this.get('description'));
                        _this.set('editing', false);
                    }).catch(function () {
                        _this.get('notify').error('Error saving description');
                    });
                });
            }
        }
    });
});