define('tm3/pods/matter/admin/employees/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        employee: null,
        showModal: false,
        modalTitle: null,
        currentMatter: Ember.inject.service(),

        actions: {
            /**
             * creates a new record on the client side
             * and show the form to edit it
             */
            showCreateForm: function showCreateForm() {
                this.set('employee', this.store.createRecord('matter-has-user', {
                    matter: this.get('matter'),
                    contact: 'Secondary'
                }));
                this.set('showModal', true);
                this.set('modalTitle', 'Connect an Employee');
            },


            /**
             * set the employee to edit
             * and show the form to edit it
             */
            showEditForm: function showEditForm(employee) {
                this.set('employee', employee);
                this.set('showModal', true);
                this.set('modalTitle', employee.get('user.fullName'));
            },
            cancelEmployeeEdit: function cancelEmployeeEdit() {
                var employee = this.get('employee');
                if (employee && !employee.get('isNew')) {
                    employee.rollbackAttributes();
                }
                this.set('showModal', false);
            }
        }
    });
});