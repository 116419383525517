define('tm3/pods/crm/contacts/edit/client-addrs/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),

        actions: {
            /**
             * hide new address form
             */
            hideForm: function hideForm() {
                Ember.$('#addrForm').addClass('hidden');
            },


            /**
             * show new address form
             */
            showForm: function showForm() {
                Ember.$('#addrForm').removeClass('hidden');
                this.set('newAddr', this.store.createRecord('user-addr'));
            },


            /**
             * save newAddress and reset form
             * @param newAddr
             */
            save: function save() {
                var self = this;
                var newAddr = this.get('newAddr');
                newAddr.set('user', this.get('model'));

                newAddr.save().then(function (data) {
                    // reset new address for future runs
                    self.send('hideForm');
                    self.get('notify').success('Address Created');
                }, function (reason) {
                    self.validationReport(newAddr);
                });
            },


            /**
             * remove existing phone number
             * @param item
             */
            delete: function _delete(item) {
                var self = this;
                item.destroyRecord().then(function (data) {
                    self.get('notify').success('Address Removed');
                }, function (reason) {
                    self.validationReport(item);
                });
            },
            linkAddress: function linkAddress(firm) {
                var self = this;
                var newAddr = this.store.createRecord('user-addr', {
                    user: this.get('model'),
                    firm: firm,
                    type: 'Work',
                    primary: true,
                    addr_1: firm.get('address'),
                    addr_2: firm.get('suite'),
                    city: firm.get('city'),
                    state: firm.get('state'),
                    zip: firm.get('zip')
                });

                newAddr.save().then(function (data) {
                    self.get('notify').success('Address Linked');
                    self.send('fetch');
                }, function (reason) {
                    self.validationReport(newAddr);
                });
            }
        }
    });
});