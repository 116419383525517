define('tm3/pods/report/info/utilization-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['month', 'perspective'],

        month: '',

        perspective: 'caseType',

        componentName: Ember.computed('perspective', function () {
            var perspective = this.get('perspective');
            var componentName = '';
            switch (perspective) {
                case 'caseType':
                    componentName = 'report/utilization-report-table/case-type';
                    break;

                case 'case':
                    componentName = 'report/utilization-report-table/case';
                    break;

                case 'employee':
                    componentName = 'report/utilization-report-table/employee';
                    break;
            }

            return componentName;
        }),

        actions: {
            clearMonth: function clearMonth() {
                this.set('month', '');
            }
        }
    });
});