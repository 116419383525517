define('tm3/pods/components/sc-calendar/agenda/component', ['exports', 'ember-power-calendar-utils', 'moment'], function (exports, _emberPowerCalendarUtils, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        from: null,
        to: null,

        didReceiveAttrs: function didReceiveAttrs() {
            if (Ember.isEmpty(this.get('from'))) {
                this.set('from', (0, _moment.default)(this.get('calendar.center')).format('YYYY-MM-DD'));
                this.set('to', (0, _moment.default)(this.get('calendar.center')).format('YYYY-MM-DD'));
            }
        },


        days: Ember.computed('from', 'to', function () {
            var lastDay = (0, _moment.default)(this.get('to'));
            var day = (0, _moment.default)(this.get('from'));
            var days = [];
            if ((0, _emberPowerCalendarUtils.isSame)(day, lastDay)) {
                days.push(this.buildDay(day));
            }
            while ((0, _emberPowerCalendarUtils.isBefore)(day, lastDay)) {
                days.push(this.buildDay(day));
                day = (0, _moment.default)((0, _emberPowerCalendarUtils.add)(day, 1, 'day'));
            }
            return days;
        }),

        buildDay: function buildDay(date) {
            var id = (0, _emberPowerCalendarUtils.formatDate)(date, 'YYYY-MM-DD');

            return (0, _emberPowerCalendarUtils.normalizeCalendarDay)({
                id: id,
                number: date.format('D'),
                date: new Date(date),
                isToday: (0, _emberPowerCalendarUtils.isSame)(date, new Date(), 'day')
            });
        },

        actions: {
            isInside: function isInside(day, scCalendarEvent) {
                return (0, _emberPowerCalendarUtils.isBetween)(day, (0, _moment.default)(scCalendarEvent.get('start'), 'YYYY-MM-DD'), (0, _moment.default)(scCalendarEvent.get('end'), 'YYYY-MM-DD'), 'day', true);
            },
            applyFilter: function applyFilter() {
                this.get('calendar.actions').setStart(this.get('from'));
                this.get('calendar.actions').setEnd(this.get('to'));
                this.get('calendar.actions').changeView('agenda', this.get('calendar'));
            }
        }
    });
});