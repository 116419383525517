define('tm3/pods/me/travel/add/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        session: Ember.inject.service(),
        notify: Ember.inject.service(),

        model: function model() {
            var currentUserId = this.get('session.data.authenticated.id');
            return Ember.RSVP.hash({
                newBillableGroup: this.store.createRecord('billable-group', {
                    type: 'Travel',
                    approvalStatus: null
                }),
                expenseTypes: this.store.query('billable-type', {
                    report: 1,
                    sort: 'id'
                }),
                currentEmployee: this.store.findRecord('employee', currentUserId)
            });
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'me' });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            model.newBillableGroup.set('createdBy', model.currentEmployee);
            controller.set('dateRange', []);
            controller.set('chargesByExpenseType', []);
        },
        cleanBillableGroupsState: function cleanBillableGroupsState() {
            // rollback changes for billableGroup include_for_travel
            this.store.peekAll('billable-group').invoke('rollbackAttributes');
        },


        actions: {
            createExpenses: function createExpenses(continueEditing) {
                var _this = this;

                var controller = this.controller;
                controller.set('isCreatingExpenses', true);
                var billableGroup = controller.get('model.newBillableGroup');

                var checkedMatters = controller.get('getAvailableMattersTask.lastSuccessful.value').filterBy('isChecked', true); // list of matterProxies

                if (checkedMatters.get('length') > 0) {
                    var anyBillableGroupSelected = checkedMatters.any(function (m) {
                        return m.get('content.billableGroups').any(function (bg) {
                            return bg.get('includeForTravel');
                        });
                    });

                    if (!anyBillableGroupSelected) {
                        this.get('notify').error('Please select at least one time entry.');
                        this.controller.set('isCreatingExpenses', false);
                        return;
                    }

                    var chargesByExpenseType = controller.get('chargesByExpenseType');

                    billableGroup.set('createdBy', controller.get('model.currentEmployee'));
                    billableGroup.normalizeDates();

                    var promises = [];
                    var deductionPromises = [];
                    var charges = [];
                    var rateTotal = 0;
                    var chargeCount = 0;

                    // gather and validate all charges before saving the billableGroup
                    chargesByExpenseType.forEach(function (row) {
                        row.charges.forEach(function (charge) {
                            if (charge.hasEffectiveValues()) {
                                chargeCount++;
                                var chargeDate = charge.get('date');
                                if (chargeDate['format']) charge.set('date', chargeDate.format('YYYY-MM-DD'));else charge.set('date', chargeDate);
                                charge.set('billableGroup', billableGroup);
                                charge.set('type', 'Travel');
                                charge.set('cut', '0.0000');
                                charge.finalizeTotal();
                                charges.push(charge);

                                rateTotal += parseFloat(charge.get('total'));
                            }
                        });
                    });

                    if (chargeCount === 0) {
                        this.get('notify').error('Please enter at least one charge.');
                        controller.set('isCreatingExpenses', false);
                        return;
                    }

                    billableGroup.save().then(function () {
                        charges.forEach(function (charge) {
                            promises.pushObject(charge.save());
                        });

                        billableGroup.set('total', rateTotal);
                        promises.pushObject(billableGroup.save());

                        checkedMatters.forEach(function (m) {
                            // tie only matters that have at least one selected time entry (included for travel)
                            var includedForTravel = m.get('content.billableGroups').any(function (bg) {
                                return bg.get('includeForTravel');
                            });
                            if (includedForTravel) {
                                var erhmObj = {
                                    billableGroup: billableGroup,
                                    matter: m.get('content'),
                                    budget: m.get('budget')
                                };

                                var expenseHasMatter = _this.store.createRecord('expense-report-has-matter', erhmObj);

                                promises.pushObject(expenseHasMatter.save());
                                // save billableGroups include_for_travel changes
                                m.get('content.billableGroups').forEach(function (bg) {
                                    if (bg.get('hasDirtyAttributes')) {
                                        promises.push(bg.save());
                                    }
                                });
                            }
                        });

                        Ember.RSVP.all(promises).then(function () {
                            _this.controller.get('deductions').forEach(function (deduction) {
                                deduction.set('billableGroup', billableGroup);
                                deductionPromises.push(deduction.save().catch(function (e) {
                                    _this.validationReport(deduction);
                                    throw e;
                                }));
                            });
                            Ember.RSVP.all(deductionPromises).then(function () {
                                controller.set('isCreatingExpenses', false);
                                _this.get('notify').success('Expenses were created successfully');
                                if (continueEditing) {
                                    _this.transitionTo('me.travel.edit', billableGroup.get('id'));
                                } else {
                                    _this.transitionTo('me.travel.list', {
                                        queryParams: {
                                            currentTravelExp: billableGroup.get('id')
                                        }
                                    });
                                }
                            }).catch(function () {
                                _this.get('notify').error('An error occurred trying to save deductions, the travel expense was created successfully, please try again.');
                                controller.set('isCreatingExpenses', false);
                                _this.transitionTo('me.travel.edit', billableGroup.get('id'));
                            });
                        }).finally(function () {
                            _this.cleanBillableGroupsState();
                        });
                    });
                } else {
                    this.get('notify').error('There must be at least one Selected Case for which you have Time Entries within the Date Range you have selected.');
                    controller.set('isCreatingExpenses', false);
                }
            },
            cancel: function cancel() {
                this.cleanBillableGroupsState();
                this.transitionTo('me.travel.list');
            }
        }
    });
});