define('tm3/pods/matter/admin/invoice-groups/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        accounts: null,
        matter: null,
        invoice_groups: null,

        childRouteIdChanged: Ember.observer('detailId', function () {
            var detailId = this.get('detailId');
            var invoiceGroups = this.get('invoice_groups');

            var activeInvoiceGroup = invoiceGroups.findBy('id', detailId);

            invoiceGroups.forEach(function (ig) {
                ig.set('active', false);
            });

            activeInvoiceGroup.set('active', true);
        }),

        caseHasAtLeastOneAccountAssociated: Ember.computed('accounts', function () {
            var accounts = this.get('accounts');
            return accounts.get('length') > 0;
        }),

        actions: {
            hideForm: function hideForm() {
                Ember.$('#invoiceGroupForm').addClass('hidden');
                this.set('invoiceGroupName', null);
                this.set('isPrimary', false);
            },
            showForm: function showForm() {
                this.set('invoiceGroupName', 'Default');
                this.set('isPrimary', this.get('invoice_groups.length') === 0);
                Ember.$('#invoiceGroupForm').removeClass('hidden');
            },
            saveInvoiceGroup: function saveInvoiceGroup() {
                var _this = this;

                var invoiceGroupName = this.get('invoiceGroupName');
                var newInvoiceGroupAccount = this.get('newInvoiceGroupAccount');
                var matter = this.get('matter');

                if (!newInvoiceGroupAccount) {
                    this.get('notify').error('You must select an account to create a new invoice group');
                } else {
                    var ig = this.store.createRecord('invoice-group', {
                        name: invoiceGroupName,
                        matter: matter,
                        primary: this.get('isPrimary')
                    });

                    if (this.get('isPrimary')) {
                        this.get('invoice_groups').map(function (invGroup) {
                            invGroup.set('primary', false);
                            return invGroup.save();
                        });
                    }

                    var invoiceGroupsReference = this.get('invoice_groups').toArray();

                    ig.save().then(function (data) {
                        invoiceGroupsReference.pushObject(data);
                        _this.set('invoice_groups', invoiceGroupsReference);
                        _this.get('notify').success('Created new invoice group.');

                        var promise = _this.store.findRecord('firm', newInvoiceGroupAccount.get('firm.id'));

                        promise.then(function (firm) {
                            var igm = _this.store.createRecord('invoice-group-member', {
                                firm: firm,
                                invoiceGroup: data,
                                billSplit: '0'
                            });

                            igm.save().then(function (data) {
                                _this.send('hideForm');
                                _this.get('notify').success('Firm added to invoice group');
                                _this.send('refresh');
                                _this.set('newInvoiceGroupAccount', null);
                            }, function (reason) {
                                _this.validationReport(igm);
                            });
                        }).catch(function (reason) {
                            window.alert(reason);
                        });
                    }, function (reason) {
                        _this.validationReport(ig);
                    });
                }
            },
            searchAccount: function searchAccount(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchAccount, term, resolve, reject, 600);
                });
            },
            openInvoiceGroup: function openInvoiceGroup(ig) {
                this.transitionToRoute('matter.admin.invoice-groups.detail', ig.id);
            },
            makePrimary: function makePrimary(ig) {
                var _this3 = this;

                var promises = [];
                promises.pushObjects(this.get('invoice_groups').rejectBy('id', ig.get('id')).map(function (invGroup) {
                    invGroup.set('primary', false);
                    return invGroup.save();
                }));
                ig.set('primary', true);
                promises.push(ig.save());

                Ember.RSVP.all(promises).then(function () {
                    _this3.get('notify').success('Invoice group set as primary');
                });
            }
        },

        _performSearchAccount: function _performSearchAccount(term, resolve, reject) {
            return this.store.query('firm', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});