define('tm3/mixins/case-management/info/edit-route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_error.default, {
        notify: Ember.inject.service(),

        collectionId: null,

        beforeModel: function beforeModel(transition) {
            this.set('collectionId', transition.params['case-management.info'].collection_id);
        },
        model: function model() {
            return this.store.findRecord('collection', this.get('collectionId'));
        },

        actions: {
            saveCollection: function saveCollection() {
                var _this = this;

                var controller = this.controllerFor(this.get('routeName'));
                var collection = controller.get('model');

                collection.save().then(function () {
                    _this.get('notify').success('Case Group saved successfully');
                    _this.refresh();
                }).catch(function () {
                    _this.validationReport(collection);
                });
            },
            cancel: function cancel() {
                var controller = this.controllerFor(this.get('routeName'));
                controller.get('model').rollbackAttributes();
                this.transitionTo('matter-list');
            },
            deleteCollection: function deleteCollection() {
                var _this2 = this;

                var controller = this.controllerFor(this.get('routeName'));
                controller.get('model').destroyRecord().then(function () {
                    _this2.get('notify').success('Collection removed');
                    _this2.transitionTo('matter-list');
                }).catch(function (e) {
                    _this2.get('notify').error('Collection was not removed');
                });
            }
        }
    });
});