define('tm3/routes/application', ['exports', 'tm-common/mixins/secure-default-route-factory', 'ember-simple-auth/mixins/application-route-mixin', 'tm-common/mixins/currently-loading-mixin', 'tm-common/mixins/token-route'], function (exports, _secureDefaultRouteFactory, _applicationRouteMixin, _currentlyLoadingMixin, _tokenRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    Ember.Route.reopenClass(_secureDefaultRouteFactory.default);

    exports.default = Ember.Route.extend(_applicationRouteMixin.default, _currentlyLoadingMixin.default, _tokenRoute.default, {
        session: Ember.inject.service(),
        notifications: Ember.inject.service(),

        setupController: function setupController() {
            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            this._super(args);

            if (!this.get('session.data.authenticated.token')) {
                Ember.$('#jsd-widget').css('visibility', 'hidden');
            } else {
                Ember.$('#jsd-widget').css('visibility', 'unset');
            }
        },


        actions: {
            /**
             *support basic model component
             */
            showModal: function showModal(name, model) {
                this.render(name, {
                    into: 'application',
                    outlet: 'modal',
                    model: model
                });
            },
            removeModal: function removeModal() {
                this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },

            // end basic model logic

            /**
             * logout action required by simple-auth
             */
            invalidateSession: function invalidateSession() {
                try {
                    this.get('session').invalidate();
                } catch (e) {
                    this.transitionTo('auth.login');
                }
            },


            /**
             * action responded for recent-matters component for all the app
             */
            transitionToMatter: function transitionToMatter(matterId) {
                this.transitionTo('matter.dashboard', matterId);
            }
        }
    });
});