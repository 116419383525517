define('tm3/components/light-table-pagination', ['exports', 'ember-cli-table-pagination/components/light-table-pagination'], function (exports, _lightTablePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _lightTablePagination.default;
    }
  });
});