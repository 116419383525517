define('tm3/utils/report-tab-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reportTabTypes = exports.reportTabTypes = [Ember.Object.create({
        display: 'Legacy Report Browser',
        value: 'matter.report-browser'
    }), Ember.Object.create({
        display: 'Subject Reports',
        value: 'matter.new-report'
    })];
});