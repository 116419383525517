define('tm3/pods/components/accounting/billing/batches/in-progress/invoice-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: 'invoice-card',
        showApproveButton: Ember.computed('invoice.canGoToLevel1', 'invoice.canGoToLevel2', 'invoice.canGoToBilled', 'invoice.status', function () {
            return this.get('invoice.status') !== 'Billed' && (this.get('invoice.canGoToLevel1') || this.get('invoice.canGoToLevel2') || this.get('invoice.canGoToBilled'));
        }),

        actions: {
            goToNextLevel: function goToNextLevel() {
                this.sendAction('goToNextLevel', this.get('invoice'));
            },
            goToInvoice: function goToInvoice() {
                this.sendAction('goToInvoice', this.get('invoice.id'));
            }
        }
    });
});