define('tm3/pods/components/input-resource-viewable/component', ['exports', 'tm3/pods/components/input-resource-viewable/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,

        viewableOptions: Ember.A([{ value: 0, display: 'Private', icon: 'lock', color: '#9DB3C0' }, { value: 2, display: 'Pending', icon: 'unlock', color: '#7F54A2' }, { value: 1, display: 'Public', icon: 'unlock', color: '#009933' }]),

        tmViewableOptions: Ember.A([{
            value: 0,
            display: 'Private: Hidden from profile',
            icon: 'lock',
            color: '#9DB3C0'
        }, {
            value: 2,
            display: 'Pending: Will be public when subject is published',
            icon: 'unlock',
            color: '#7F54A2'
        }, {
            value: 1,
            display: 'Public: Display on profile',
            icon: 'unlock',
            color: '#009933'
        }]),

        useTmOptions: false,

        selectedViewable: Ember.computed('resource.viewable', function () {
            var options = this.get('useTmOptions') ? this.get('tmViewableOptions') : this.get('viewableOptions');
            if (Ember.typeOf(options) === 'array') {
                return options.findBy('value', this.get('resource.viewable'));
            }
            return null;
        }),

        actions: {
            changeViewable: function changeViewable(newOption) {
                if (typeof this.attrs['onChange'] === 'function') {
                    this.attrs['onChange'](newOption.value);
                }
            },
            changeDoNotUse: function changeDoNotUse(event) {
                var options = this.get('useTmOptions') ? this.get('tmViewableOptions') : this.get('viewableOptions');
                if (event.target.checked) {
                    this.send('changeViewable', options.findBy('value', 0));
                }
                if (typeof this.attrs['onChangeDoNotUse'] === 'function') {
                    this.attrs['onChangeDoNotUse'](event.target.checked);
                }
            }
        }
    });
});