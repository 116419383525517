define('tm3/initializers/setup-pikaday', ['exports', 'ember-pikaday/components/pikaday-input'], function (exports, _pikadayInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() /* container, application */{
        /**
         * Make pikaday components start weeks from Sunday
         */
        _pikadayInput.default.reopen({
            firstDay: 0
        });
    }

    exports.default = {
        name: 'setup-pikaday',
        initialize: initialize
    };
});