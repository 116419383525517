define('tm3/pods/matter/document-fields/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        queryParams: {
            templateId: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var model = this.modelFor('matter');
            var currentUserId = this.get('session.data.authenticated.id');

            return Ember.RSVP.hash({
                matter: model.matter,
                docTemplates: this.store.query('document-template', {
                    user_id: currentUserId
                }),
                documentTemplateHasFields: this.store.query('document-template-has-field', {
                    document_template_id: params.templateId
                }),
                params: params,
                user: this.store.findRecord('user', currentUserId)
            });
        },

        willTransition: function willTransition(transition) {
            this.controller.cancel();
        },

        getColorForField: function getColorForField(fieldName) {
            switch (fieldName) {
                case 'Doc Type':
                    return '#00c0ef';
                case 'Updated On/By':
                case 'Created On/By':
                    return '#ff9800';
                case 'Subject':
                    return '#3c8dbc';
                case 'Lock (Public/Private)':
                case 'Relationship':
                    return '#00a65a';
                case 'Tags':
                    return '#9c27b0';
                default:
                    return '#9b9b9b';
            }
        },
        setupController: function setupController(controller, resolved) {
            var _this = this;

            this._super(controller, resolved);
            controller.set('docTemplates', resolved.docTemplates);
            controller.set('matter', resolved.matter);
            controller.set('user', resolved.user);

            var dthfProxyList = [];
            resolved.documentTemplateHasFields.forEach(function (dthf) {
                var matterProxy = Ember.ObjectProxy.create({
                    color: _this.getColorForField(dthf.get('fieldName')),
                    content: dthf
                });
                dthfProxyList.push(matterProxy);
            });

            controller.set('documentTemplateHasFields', dthfProxyList);

            var selectedDocTemplate = resolved.docTemplates.findBy('id', resolved.params.templateId);
            controller.set('selectedDocTemplate', selectedDocTemplate);
            controller.set('docTemplateName', selectedDocTemplate.get('name'));
        }
    });
});