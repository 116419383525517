define('tm3/initializers/extend-dropdown-direction', ['exports', 'tm-common/initializers/extend-dropdown-direction'], function (exports, _extendDropdownDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _extendDropdownDirection.default;
    }
  });
});