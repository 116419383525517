define('tm3/pods/accounting/billing/tax-rules/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        quickSearch: '',
        sortField: 'id',
        tableTitle: 'Tax Rules',
        quickSearchField: 'name',
        appName: 'tm-admin',
        columnFields: Ember.computed('columns', function () {
            return this.get('columns').sortBy('order').map(function (c) {
                return c.get('apiInteractionName');
            });
        }),
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),
        columns: [_column.default.create({
            displayName: 'Name',
            fieldName: 'name',
            order: 2,
            width: '30%'
        }), _column.default.create({
            displayName: 'Description',
            fieldName: 'description',
            order: 3,
            width: '30%'
        }), _column.default.create({
            displayName: 'Rate',
            fieldName: 'formattedRate',
            apiName: 'rate',
            width: '20%',
            order: 4
        }), _column.default.create({
            displayName: 'QB Map',
            fieldName: 'qbMap',
            apiName: 'qb_map',
            width: '20%',
            order: 5
        })],
        toolsParams: Ember.computed(function () {
            return {
                buttonLink: 'accounting.billing.tax-rules.add',
                buttonText: 'New Tax Rule'
            };
        }),
        linkPath: 'accounting.billing.tax-rules.form'
    });
});