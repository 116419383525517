define('tm3/pods/crm/contacts/info/matters/add-matter/route', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        /**
         *
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            var clientsInfoModel = this.modelFor('crm.contacts.info').model;
            var clientsInfoMatterModel = this.modelFor('crm.contacts.info.matters');
            var activity = this.controllerFor('crm.contacts.info.activity');
            var client = clientsInfoModel.get('firstObject');
            var userId = client.get('id');

            return Ember.RSVP.hash({
                client: client,
                employees: activity.get('employees'),
                clientUser: this.store.findRecord('user', userId),
                userGroups: this.store.query('group', {
                    group_type: _environment.default.APP.groupTypes.client
                }),
                matterHasUser: clientsInfoMatterModel.matterHasUser,
                contactRelationships: this.get('lists').getListForGroup('case_contact_types')
            });
        },


        /**
         *
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, resolved) {
            this._super(controller, {});

            // load data for tagging system
            controller.set('clientUser', resolved.clientUser);
            controller.set('employees', resolved.employees);
            controller.set('client', resolved.client);
            controller.set('groups', resolved.userGroups);
            controller.set('matterHasUser', resolved.matterHasUser);

            controller.set('contactRelationships', resolved.contactRelationships);
            controller.set('relatedMatter', null);
            controller.set('selectedCaseName', '');
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'crm.contacts.info' });
        }
    });
});