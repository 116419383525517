define('tm3/components/if-equal', ['exports', 'tm-common/components/if-equal'], function (exports, _ifEqual) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ifEqual.default;
    }
  });
});