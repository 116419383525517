define('tm3/pods/admin/employees/info/details/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tm-common/mixins/authorized-route-mixin'], function (exports, _authenticatedRouteMixin, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authorizedRouteMixin.default, {
        allowedGroups: ['System - Administrator', 'System - ROOT'],
        lists: Ember.inject.service(),

        model: function model(params) {
            var infoModel = this.modelFor('admin.employees.info');

            return Ember.RSVP.hash({
                employee: this.store.findRecord('employee', infoModel.employee.get('id')),
                user: this.store.findRecord('user', infoModel.employee.get('id')),
                userGroups: this.store.query('user-has-group', {
                    user_id: infoModel.employee.get('id'),
                    with: 'all'
                }),
                userTeams: this.store.query('user-has-team', {
                    user_id: infoModel.employee.get('id'),
                    with: 'all'
                }),
                groups: this.store.query('group', { sort: 'name' }),
                teams: this.store.query('team', { sort: 'name', mode: 'Employee' }),
                roles: this.get('lists').getListForGroup('employee_role'),
                departments: this.get('lists').getListForGroup('employee_department')
            });
        },

        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});