define('tm3/pods/accounting/billing/tax-rules/form/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        taxRule: Ember.computed.alias('model'),

        actions: {
            rollBack: function rollBack() {
                var model = this.get('taxRule');

                if (model) {
                    model.rollbackAttributes();
                }
            }
        }
    });
});