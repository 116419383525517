define('tm3/models/matter-has-user', ['exports', 'tm-common/models/matter-has-user'], function (exports, _matterHasUser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _matterHasUser.default.extend({
        isCaseOwner: Ember.computed('group', 'group.name', function () {
            return this.get('group.name') === 'Case Owner';
        }),
        isStandardResearcher: Ember.computed('group', 'group.name', function () {
            return this.get('group.name') === 'Standard Researcher';
        })
    });
});