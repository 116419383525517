define('tm3/pods/case-group/edit-matter/controller', ['exports', 'tm3/mixins/case-group-column', 'tm-common/mixins/crud/error'], function (exports, _caseGroupColumn, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var EditableColumn = Ember.Object.extend({
        column: null,
        editCellComponent: null
    });

    exports.default = Ember.Controller.extend(_caseGroupColumn.default, _error.default, {
        notify: Ember.inject.service(),
        goBack: Ember.inject.service(),

        collection: Ember.computed.alias('model.collection'),
        matter: Ember.computed.alias('model.matter'),
        columns: Ember.computed.alias('model.collectionColumns'),
        workflowStatuses: Ember.computed.alias('model.workflowStatuses'),
        employees: Ember.computed.alias('model.employees'),

        editableColumns: Ember.computed('columns', function () {
            var _this = this;

            return this.get('columns').map(function (c) {
                return EditableColumn.create({
                    column: c,
                    editCellComponent: _this.getEditCellComponent(c.get('editRenderType')),
                    viewCellComponent: _this.getViewCellComponent(c.get('viewRenderType'))
                });
            });
        }),

        actions: {
            goBackToList: function goBackToList() {
                this.transitionToRoute('matter-list');
            },
            saveMatter: function saveMatter() {
                var _this2 = this;

                var matter = this.get('matter');
                matter.save().then(function () {
                    _this2.get('notify').success('Case was saved successfully');
                    _this2.send('goBackToList');
                }).catch(function () {
                    _this2.validationReport(matter);
                });
            },
            cancelMatter: function cancelMatter() {
                this.get('matter').rollbackAttributes();
                this.send('goBackToList');
            }
        }
    });
});