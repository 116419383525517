define('tm3/pods/components/document-ready-notification/component', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notifications: Ember.inject.service(),
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        lists: Ember.inject.service(),
        session: Ember.inject.service(),
        router: Ember.inject.service(),

        documentEditModal: false,
        documentIsLoading: false,
        notification: null,
        documentNavigationModal: false,

        actions: {
            getName: function getName(metadata) {
                return JSON.parse(metadata).name;
            },
            editDocument: function editDocument(d) {
                var _this = this;

                var m = JSON.parse(d.metadata);
                var matterId = m.matter_id;
                var docId = m.document_id;
                this.set('notification', d);
                this.set('documentEditModal', true);
                this.set('documentIsLoading', true);
                Ember.RSVP.hash({
                    document: this.get('store').queryRecord('document', {
                        resource_id: docId,
                        with: 'subjects'
                    }),
                    documentTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                    tags: this.get('store').query('tag', { type: 'document' }),
                    reportNames: this.get('store').query('report', {
                        matter_id: matterId
                    }),
                    subjects: this.get('store').query('subject', {
                        matter_id: matterId,
                        sort: 'last_name,first_name'
                    })
                }).then(function (result) {
                    if (result.document.get('docType') === 'TRASH') {
                        if (result.document.get('docName').indexOf('Invoice') >= 0) {
                            result.document.set('docType', 'INVOICE');
                        } else {
                            result.document.set('docType', 'REPORT');
                        }
                    }
                    _this.setProperties({
                        matterId: matterId,
                        reportDocument: result.document,
                        documentTypes: result.documentTypes.rejectBy('value', 'TRASH'),
                        reports: result.reportNames,
                        subjects: result.subjects,
                        tags: result.tags
                    });
                    _this.set('documentIsLoading', false);
                }, function () {
                    _this.get('notify').error('An error has occurred. Please try again later');
                });
            },
            saveDocumentEdit: function saveDocumentEdit() {
                var _this2 = this;

                var doc = this.get('reportDocument');
                var promises = [];
                promises.push(doc.save());
                var selectedTags = doc.get('tags') || [];
                selectedTags.forEach(function (tag) {
                    var rht = _this2.get('store').createRecord('resource-has-tag', {
                        tag: tag,
                        resource: doc
                    });
                    promises.push(rht.save());
                });

                return Promise.all(promises).then(function () {
                    _this2.set('documentEditModal', false);
                    _this2.set('documentReadyId', doc.get('id'));
                    _this2.set('documentNavigationModal', true);
                    _this2.get('notify').success('Document changes were saved');
                    _this2.get('notifications.dismissDocument').perform(_this2.get('notification'));
                    _this2.set('notification', null);
                });
            },
            cancelDocument: function cancelDocument(d) {
                this.get('notifications.dismissDocument').perform(d);
            },
            downloadDocument: function downloadDocument(d) {
                var _this3 = this;

                this.get('notifications.dismissDocument').perform(d).then(function () {
                    var documentId = JSON.parse(d.metadata).document_id;
                    var baseURI = _environment.default.APP.documentDownloadURL;
                    var url = '' + baseURI + documentId + '/download?token=' + _this3.get('session.data.authenticated.token');

                    var $downloadLink = document.getElementById('forceDownloadLink');
                    $downloadLink.setAttribute('href', url);
                    // firefox hack doesn`t allow click trigger on href
                    var ev = document.createEvent('MouseEvents');
                    ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    // Fire event
                    $downloadLink.dispatchEvent(ev);
                });
            },
            navigateToDocuments: function navigateToDocuments() {
                this.set('documentNavigationModal', false);
                var url = this.get('router').urlFor('matter.documents', this.get('reportDocument.matter'), {
                    queryParams: {
                        groupBy: 'docType',
                        selectedGroup: this.get('reportDocument.docType')
                    }
                });
                window.open(url);
            }
        }
    });
});