define('tm3/controllers/application', ['exports', 'tm3/config/environment', 'tm-common/mixins/token-controller'], function (exports, _environment, _tokenController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_tokenController.default, {
        confirmAction: Ember.inject.service(),
        router: Ember.inject.service(),

        isTest: Ember.computed.equal(_environment.default.environment, 'test'),

        currentPathDidChange: Ember.observer('currentPath', function () {
            Ember.run.next(function () {
                // Left menu
                Ember.$('ul.sidebar-menu li:has(>a.active)').addClass('active');
                Ember.$('ul.sidebar-menu li:not(:has(>a.active))').removeClass('active');
                Ember.$('ul.sidebar-menu li:has(>a.active)').closest('.treeview').addClass('active');

                Ember.$('ul.sidebar-menu li:has(>a.ember-transitioning-in)').addClass('active');

                // Nav bars
                var $navBars = Ember.$('ul.nav-tabs:not(.nav-tabs--handles-active)');

                $navBars.find('li:has(>a.active)').addClass('active');
                $navBars.find('li:not(:has(>a.active))').removeClass('active');
            });
        }),

        /**
            Provides a route specific HTML class name for scoping styles to
            individual pages (routes).
            @property routeClassName
            @type {String}
         */
        routeClassName: Ember.computed('router.currentRouteName', function () {
            var currentRouteName = this.get('router.currentRouteName');

            return Ember.String.dasherize(currentRouteName.replace(/\./g, '-')) + '-route';
        })
    });
});