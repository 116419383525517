define('tm3/components/timelines/new-timeline-event', ['exports', 'tm-common/components/timelines/new-timeline-event'], function (exports, _newTimelineEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _newTimelineEvent.default;
    }
  });
});