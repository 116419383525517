define('tm3/pods/components/sc-calendar/week/component', ['exports', 'ember-power-calendar/components/power-calendar/days', 'ember-power-calendar-utils', 'moment'], function (exports, _days, _emberPowerCalendarUtils, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _days.default.extend({
        start: Ember.computed('currentCenter', function () {
            return this.firstDay();
        }),
        end: Ember.computed('currentCenter', function () {
            return this.lastDay();
        }),

        firstDay: function firstDay() {
            var firstDay = (0, _emberPowerCalendarUtils.startOf)(this.get('currentCenter'), 'week');
            return (0, _emberPowerCalendarUtils.startOfWeek)(firstDay, this.get('localeStartOfWeek'));
        },
        lastDay: function lastDay() {
            var localeStartOfWeek = this.get('localeStartOfWeek');
            (false && !(!isNaN(this.get('currentCenter').getTime())) && Ember.assert('The center of the calendar is an invalid date.', !isNaN(this.get('currentCenter').getTime())));

            var lastDay = (0, _emberPowerCalendarUtils.endOf)(this.get('currentCenter'), 'week');
            return (0, _emberPowerCalendarUtils.endOfWeek)(lastDay, localeStartOfWeek);
        },


        actions: {
            isInside: function isInside(day, scCalendarEvent) {
                return (0, _emberPowerCalendarUtils.isBetween)(day, (0, _moment.default)(scCalendarEvent.get('start'), 'YYYY-MM-DD'), (0, _moment.default)(scCalendarEvent.get('end'), 'YYYY-MM-DD'), 'day', true);
            }
        }
    });
});