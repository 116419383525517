define('tm3/pods/matter/admin/invoice-groups/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        queryParams: {
            refresh: {
                refreshModel: true
            }
        },

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        beforeModel: function beforeModel(transition) {
            var routeParams = transition.params['matter.admin.invoice-groups.detail'];
            var childRouteId = routeParams ? routeParams.invoice_group_id : null;
            this.set('detailId', childRouteId);
        },
        model: function model(params) {
            var matterId = this.get('currentMatter.id');
            // params.matter_id
            return Ember.RSVP.hash({
                matter: this.store.findRecord('matter', matterId),
                accounts: this.store.query('firm-has-matter', { matter_id: matterId }),
                invoice_groups: this.store.query('invoice-group', { matter_id: matterId })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.matter);

            controller.set('matter', resolved.matter);
            controller.set('accounts', resolved.accounts);
            controller.set('invoice_groups', resolved.invoice_groups);

            if (this.get('detailId')) {
                controller.set('detailId', this.get('detailId'));
            }
        },


        actions: {
            willTransition: function willTransition(transition) {
                if (transition.params['matter.admin.invoice-groups.detail'] && 'invoice_group_id' in transition.params['matter.admin.invoice-groups.detail']) {
                    var childRouteId = transition.params['matter.admin.invoice-groups.detail'].invoice_group_id;
                    this.set('controller.detailId', childRouteId);
                }
            },
            refresh: function refresh() {
                return true;
            }
        }
    });
});