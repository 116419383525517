define('tm3/pods/accounting/billing/subscription-costs/processes/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        renderTemplate: function renderTemplate() {
            this.render({ into: 'accounting.billing' });
        },
        model: function model(params) {
            var _this = this;

            return Ember.RSVP.hash({
                process: this.store.queryRecord('prorate-subscription-cost-process', {
                    id: params.id,
                    with: 'billable_groups'
                })
            }).then(function (hash) {
                return _this.store.query('billable', {
                    billable_group_id: hash.process.get('billableGroup.id'),
                    with: 'invoices,matters',
                    filter: 'all' // billables by default use invoice_id is null in the query
                }).then(function (billables) {
                    hash.billables = billables;
                    return hash;
                });
            });
        }
    });
});