define('tm3/models/task-list', ['exports', 'tm-common/models/task-list'], function (exports, _taskList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _taskList.default.extend({
        pendingTasks: Ember.computed.filterBy('tasks', 'isDone', false),
        completedTasks: Ember.computed.filterBy('tasks', 'isDone', true)
    });
});