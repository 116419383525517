define('tm3/components/bs-table-pagination/table-row', ['exports', 'ember-cli-table-pagination/components/bs-table-pagination/table-row'], function (exports, _tableRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tableRow.default;
    }
  });
});