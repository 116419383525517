define('tm3/pods/crm/contacts/info/activity/add-note/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);

            // load a copy of the client model since it's already been requested
            var clientsInfoModel = this.modelFor('crm.contacts.info').model;
            controller.set('client', clientsInfoModel);

            // load a copy of employees from upstream since it's already been requested
            var activity = this.controllerFor('crm.contacts.info.activity');
            controller.set('employees', activity.get('employees'));
            controller.set('noteCategories', activity.get('noteCategories'));

            // var clientsInfoActivityModel = this.modelFor('crm.contacts.info.activity');
            // controller.set('crmNoteHasClients', clientsInfoActivityModel);
        }
    });
});