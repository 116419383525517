define('tm3/components/power-select-multiple-with-create', ['exports', 'ember-power-select-with-create/components/power-select-multiple-with-create'], function (exports, _powerSelectMultipleWithCreate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _powerSelectMultipleWithCreate.default;
    }
  });
});