define('tm3/pods/components/travel-expenses/travel-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['box-header'],

        reportBuilder: Ember.inject.service(),
        notify: Ember.inject.service(),

        selectedRows: Ember.computed.reads('titleParams.selectedRows'),
        customExtraParams: Ember.computed.reads('titleParams.customExtraParams'),

        addExtraParams: function addExtraParams(jsonObject, type) {
            var extraParams = this.get(type + 'ExtraParams');
            if (extraParams) {
                // allow passing functions as extraParams so that ehy can be evaluated on demand
                if (extraParams instanceof Function) {
                    extraParams = extraParams();
                }
                for (var key in extraParams) {
                    jsonObject['report_builder'][type][key] = extraParams[key];
                }
            }
        },


        actions: {
            exportReport: function exportReport() {
                var _this = this;

                var rowIds = this.get('selectedRows').mapBy('id');

                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'travel_reimbursement_report',
                            format: 'excel',
                            style: 'misc'
                        },
                        custom: {
                            from_tabular: true,
                            current_params: this.get('currentParams'),
                            column_fields: this.get('columnFields'),
                            column_names: this.get('columnNames'),
                            column_widths: this.get('columnWidths'),
                            entity: this.get('entity'),
                            header_text: this.get('headerText'),
                            landscape: this.get('landscape'),
                            include_footer: this.get('includeFooter'),
                            footer_center: 'Page [page] of [topage]',
                            selected_rows: rowIds
                        }
                    }
                };

                this.addExtraParams(jsonObject, 'custom');
                this.addExtraParams(jsonObject, 'standard');

                this.get('reportBuilder').enqueueReport(jsonObject, function (response) {
                    if (response.ok) {
                        _this.set('enqueuedJobId', response.job);
                    } else {
                        _this.get('notify').error('There was a problem enqueueing the report for generation');
                    }
                });
            }
        }
    });
});