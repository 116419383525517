define('tm3/pods/components/crm/new-note/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        notifications: Ember.inject.service(),
        body: '',

        // component will display only when true
        isEditing: false,

        // observer property on controller
        // allow controller to put component in edit mode
        isAdding: false,

        // expects the model that should be connected to this note by default
        connectTo: false,

        // point to a list on the controller that should be notified explicitly when a new NOTE record is created
        noteTracking: false,

        // point to a list on the controller that should be notified explicitly when a new RELATED record is created
        relatedTracking: false,

        // a list of employees that should be alerted when the note is created
        selectedEmployees: [],

        // watch the configured pointer
        // toggle component on change
        outsideAdd: Ember.observer('isAdding', function () {
            var isAdding = this.get('isAdding');

            if (isAdding === true) {
                this.set('isEditing', true);
            }
        }),

        /**
         * @event
         *
         * called after connect to related items the note
         */
        afterConnect: null,

        actions: {
            cancel: function cancel() {
                this.set('isEditing', false);
                this.set('isAdding', false);
            },
            showMoreOptions: function showMoreOptions() {
                Ember.$('#add-note-more').toggleClass('hidden');
                Ember.$('#add-note-options').toggleClass('hidden');
            },
            save: function save(model) {
                var self = this;
                var relatedTracking = this.get('relatedTracking');
                var body = this.get('body');
                var crmNoteCategory = this.get('selectedNoteCategory');

                var formData = {
                    body: body,
                    crmNoteCategory: crmNoteCategory
                };

                var selectedEmployees = this.get('selectedEmployees');
                var store = this.get('store');

                var note = store.createRecord('crm-note', formData);
                note.save().then(function (child) {
                    // if a noteTracking is provided, then attempt to update it as if it were a pager
                    if (self.get('noteTracking') === true) {
                        self.sendAction('updatePager', note);
                    }

                    // now connect new note to a related item
                    // infer type from model?
                    var connectTo = self.get('connectTo');
                    if (connectTo !== false) {
                        var name = connectTo.constructor.modelName;
                        var formData = {
                            crmNote: note
                        };
                        formData[name] = connectTo;

                        var joinTable = 'crm-note-has-' + name;
                        var relatedRecord = store.createRecord(joinTable, formData);
                        relatedRecord.save();

                        // if a relatedTracking is provided, then attempt to update it as well
                        if (relatedTracking !== false) {
                            self.sendAction('afterConnect', relatedRecord);
                        }
                    }

                    // create a single email log entry if any employees are to be alerted
                    if (selectedEmployees.length > 0) {
                        var metadata = {
                            note: body
                        };

                        var employeeIds = selectedEmployees.map(function (e) {
                            return e.get('id');
                        }).join(',');

                        self.get('notifications').send('CRMActivity', employeeIds, 'CRM Activity Alert', body, null, null, null, JSON.stringify(metadata)).then(function () {
                            self.set('selectedEmployees', []);
                        });
                    }

                    // self.get('notify').success('Note created');
                    self.set('body', '');
                    self.set('isAdding', false);
                    self.set('isEditing', false);
                }, function (reason) {
                    self.validationReport(note);
                });
            }
        }
    });
});