define('tm3/validators/phrase', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A name is required.'
        })],
        phraseType: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A type is required.'
        }), (0, _validators.validateInclusion)({
            list: ['general', 'billing', 'fields', 'links', 'notes'],
            message: 'Phrase type should be one of the options'
        })],
        content: [(0, _validators.validateLength)({
            min: 1,
            max: 60000,
            allowBlank: true
        })],
        includeVariables: [(0, _validators.validatePresence)(true)],
        order: [(0, _validators.validateNumber)({ gte: 0, integer: true, positive: true, allowBlank: true })],
        fieldBreaks: [(0, _validators.validateNumber)({ gte: 0, integer: true, positive: true, allowBlank: true })],
        caseTypeGroup: [(0, _validators.validatePresence)(true)]
    };
});