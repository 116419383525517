define('tm3/pods/matter/subjects/info/workflow/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        session: Ember.inject.service(),
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),
        actions: {
            saveWorkflow: function saveWorkflow(workflow) {
                var _this = this;

                workflow.save().then(function () {
                    workflow.set('editing', false);
                    _this.get('notify').success('Workflow has been saved');
                    _this.send('fetchSubject');
                    _this.send('fetch');
                }, function () {
                    _this.validationReport(workflow);
                });
            }
        }
    });
});