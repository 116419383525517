define('tm3/validators/time-allocation-history', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        entryId: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A time allocation is required.'
        }), (0, _validators.validateNumber)({ integer: true })],
        user: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A member is required.'
        })],
        created: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A date is required.'
        })],
        description: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A description is required.'
        }), (0, _validators.validateLength)({ min: 1, max: 255 })]
    };
});