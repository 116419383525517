define('tm3/adapters/subject-workflow-column', ['exports', 'tm3/config/environment', 'tm3/adapters/application'], function (exports, _environment, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() /* modelName */{
            return 'subject_workflow_v2s/columns';
        },
        urlForFindAll: function urlForFindAll() /* modelName, snapshot */{
            return _environment.default.APP.restDestination + '/' + _environment.default.APP.restNameSpace + '/subject_workflow_v2s/columns';
        }
    });
});