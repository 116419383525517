define('tm3/pods/report/info/review-time-report/route', ['exports', 'tm3/mixins/report-info-route', 'moment'], function (exports, _reportInfoRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'review_time_report',

        queryParams: {
            month: {
                refreshModel: true
            },
            selectedEmployee: {
                refreshModel: true
            },
            billType: {
                refreshModel: true
            },
            selectedCase: {
                refreshModel: true
            },
            perspective: {
                refreshModel: true
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            switch (params.perspective) {
                case 'employee':
                    delete params.selectedCase;
                    break;

                case 'case':
                    delete params.selectedEmployee;
                    break;
            }

            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 50,
                    page: params.page ? params.page : 1,
                    month: params.month,
                    selectedCase: params.selectedCase,
                    selectedEmployee: params.selectedEmployee,
                    billType: params.billType
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                months: this.getMonths(),
                month: params.month,
                employees: this.store.query('employee', {
                    sort: 'last_name'
                }),
                matter: params.selectedCase ? this.store.queryRecord('matter', {
                    tm_code: params.selectedCase
                }) : null,
                selectedEmployee: params.selectedEmployee,
                selectedCase: params.selectedCase
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('months', resolved.months);
            controller.set('month', resolved.month);

            controller.set('employees', resolved.employees);

            if (resolved.selectedEmployee) {
                var e = resolved.employees.findBy('id', resolved.selectedEmployee);
                controller.set('employee', e);
            }

            if (resolved.selectedCase) {
                controller.set('case', resolved.matter);
            }
        },
        getMonths: function getMonths() {
            var startDate = '01-11-2004';
            var nowNormalized = (0, _moment.default)().startOf('month');
            var startDateNormalized = (0, _moment.default)(startDate, 'DD-MM-YYYY').clone().startOf('month').add(1, 'M');
            var months = [];

            while (startDateNormalized.isBefore(nowNormalized)) {
                months.push(startDateNormalized.format('MMMM YYYY'));
                startDateNormalized.add(1, 'M');
            }

            return months.reverse();
        }
    });
});