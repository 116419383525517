define('tm3/pods/components/sort-icon/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        selectedSortField: null,
        selectedSortDirection: 'asc',

        sortField: null,

        showIcon: Ember.computed('selectedSortField', 'sortField', function () {
            return this.get('selectedSortField') === this.get('sortField');
        })
    });
});