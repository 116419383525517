define('tm3/pods/report/info/fixed-cost-database-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['from_date', 'to_date', 'dbType'],

        reportBuilder: Ember.inject.service(),

        from_date: '',
        to_date: '',
        dbType: 'Courts',

        reportCustomParams: Ember.computed('from_date', 'to_date', 'dbType', function () {
            return {
                from_date: this.get('from_date'),
                to_date: this.get('to_date'),
                db_type: this.get('dbType')
            };
        })
    });
});