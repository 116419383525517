define('tm3/adapters/invoice-summary', ['exports', 'tm3/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType(modelName) {
            return 'invoices/invoice_summaries';
        }
    });
});