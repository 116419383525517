define('tm3/helpers/show-filesize', ['exports', 'tm-common/helpers/show-filesize'], function (exports, _showFilesize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _showFilesize.default;
    }
  });
  Object.defineProperty(exports, 'formatFilesize', {
    enumerable: true,
    get: function () {
      return _showFilesize.formatFilesize;
    }
  });
});