define('tm3/components/subjects/sidebar/analytics-accordion', ['exports', 'tm-common/components/subjects/sidebar/analytics-accordion'], function (exports, _analyticsAccordion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _analyticsAccordion.default;
    }
  });
});