define('tm3/utils/editing-workflow', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.ObjectProxy.extend({
        // using .content b/c assignedTo is an async relation
        hasAssignedTo: Ember.computed('fullName', function () {
            var assignedTo = this.get('fullName');
            return Ember.isPresent(assignedTo);
        }),
        hasStatus: Ember.computed.notEmpty('status'),
        hasComment: Ember.computed.notEmpty('comment'),

        observesAssign: Ember.observer('assignedTo', function () {
            if (Ember.isPresent(this.get('assignedTo'))) {
                this.set('firstName', this.get('assignedTo.firstName'));
                this.set('lastName', this.get('assignedTo.lastName'));
            }
        }),

        statuses: [],

        displayForStatus: Ember.computed('status', 'statuses.[]', function () {
            var status = this.get('status');
            var statuses = this.get('statuses');

            var st = statuses.findBy('value', status);

            return st.get('display');
        }),

        labelForStatus: Ember.computed('status', function () {
            switch (this.get('status')) {
                case 'P':
                    return 'label-warning';
                case 'I':
                    return 'label-danger';
                case 'C':
                    return 'label-success';
                case 'S':
                    return 'label-primary';
                case 'U':
                    return 'label-default';
                default:
                    return 'label-default';
            }
        }),
        statusOption: Ember.computed('status', 'statuses.[]', function () {
            var status = this.get('status');
            var statuses = this.get('statuses');

            var st = statuses.findBy('value', status);

            return st;
        })
    });
});