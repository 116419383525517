define('tm3/utils/sc-calendar-event-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var eventTypes = [{
        name: 'event',
        color: '#2C71B4'
    }, {
        name: 'task',
        label: 'Task',
        color: '#E93E97'
    }, {
        name: 'case-created',
        label: 'Case Created',
        color: '#60605b'
    }, {
        name: 'case-trial',
        label: 'Case Trial',
        color: '#6bb440'
    }, {
        name: 'report',
        label: 'Report',
        color: '#40B4B0'
    }];
    var secondaryEventTypes = [{
        name: 'PWL Deadline',
        color: '#F1C40F',
        range: false
    }, {
        name: 'Deposition',
        color: '#8E44AD',
        range: false
    }, {
        name: 'Jury Project',
        color: '#2980B9',
        range: true
    }, {
        name: 'Discovery Deadline',
        color: '#1ABC9C',
        range: false
    }, {
        name: 'Special Project',
        color: '#196F3D',
        range: false
    }, {
        name: 'Report (Published)',
        color: '#E67E22'
    }, {
        name: 'Report (Due)',
        color: '#cc00ff'
    }, {
        name: 'Report Due Date',
        color: '#cc00ff',
        range: false
    }, {
        name: 'Case Due Date',
        color: '#cc0000',
        range: false
    }, {
        name: 'Promise Date',
        color: 'yellowgreen',
        range: false
    }, {
        name: 'Trial Date',
        color: '#CF515F',
        range: false
    }];

    exports.default = { eventTypes: eventTypes, secondaryEventTypes: secondaryEventTypes };
});