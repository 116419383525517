define('tm3/pods/components/sc-calendar/legend/component', ['exports', 'tm3/utils/sc-calendar-event-types'], function (exports, _scCalendarEventTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        eventTypes: _scCalendarEventTypes.default.eventTypes,
        secondaryEventTypes: _scCalendarEventTypes.default.secondaryEventTypes
    });
});