define('tm3/pods/matter/db-memos/browse-memos/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DbWithMemo = Ember.Object.extend({
        db: null,
        subjects: [],
        memos: [],
        isExpanded: false
    });

    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),

        firstTimeObject: {},

        queryParams: {
            only_mine: {
                refreshModel: true
            },
            only_blank: {
                refreshModel: true
            },
            expand: {
                refreshModel: true
            }
        },

        expandedDbs: [],

        model: function model(params) {
            var _this = this;

            var currentMatterId = this.get('currentMatter').get('id');
            var currentUserId = this.get('session.data.authenticated.id');

            this.store.peekAll('db-memo').forEach(function (dbm) {
                return dbm.unloadRecord();
            });

            var memosQuery = {};
            if (params.only_mine === true) {
                memosQuery.created_by_id = currentUserId;
            }
            if (params.only_blank === true) {
                memosQuery.body = ['NULL', ''];
            }

            return Ember.RSVP.hash({
                db_memos: this.store.query('db-memo', Object.assign({
                    sort: '-resources:created_on',
                    matter_id: currentMatterId,
                    with: 'dbs,subjects',
                    limit: 10000
                }, memosQuery)),
                matter_subjects: this.store.query('subject', {
                    matter_id: currentMatterId,
                    sort: 'last_name'
                }),
                matter: this.store.findRecord('matter', currentMatterId),
                matterHasCurrentUser: this.store.queryRecord('matter-has-user', {
                    matter_id: params.matter_id,
                    user_id: currentUserId,
                    with: 'groups'
                }),
                matter_id: currentMatterId,
                expand: params.expand,
                employees: this.store.query('employee', {
                    with_access_to_matter: this.get('currentMatter.id')
                }),
                tags: this.get('ajax').request('db_tags/for-matter/' + currentMatterId, {
                    data: {
                        only_mine: params.only_mine,
                        only_blank: params.only_blank
                    }
                }).then(function (response) {
                    _this.store.pushPayload(response);
                    return response.db_tags.mapBy('id');
                }),
                databases: this.get('ajax').request('dbs/for-matter/' + currentMatterId, {
                    data: {
                        only_mine: params.only_mine,
                        only_blank: params.only_blank
                    }
                }).then(function (response) {
                    _this.store.pushPayload(response);
                    return response.dbs.map(function (db) {
                        return DbWithMemo.create({
                            db: _this.store.peekRecord('db', db.id),
                            tagIds: db.tag_ids.split(','),
                            subjectIds: db.subject_ids === null ? [] : db.subject_ids.split(','),
                            memos: [],
                            subjects: [],
                            isExpanded: _this.get('expandedDbs').includes(db.id)
                        });
                    }).sort(_this.sortDbms.bind(_this));
                })
            });
        },
        customStringCompare: function customStringCompare(strA, strB) {
            var a = Ember.isNone(strA) ? '' : strA;
            var b = Ember.isNone(strB) ? '' : strB;

            return a.localeCompare(b);
        },
        sortDbms: function sortDbms(dbmA, dbmB) {
            var stateA = dbmA.get('db.state.name');
            var stateB = dbmB.get('db.state.name');
            var stateDiff = this.customStringCompare(stateA, stateB);
            var countyA = dbmA.get('db.county.name');
            var countyB = dbmB.get('db.county.name');

            if (stateDiff === 0) {
                var phraseA = dbmA.get('db.name');
                var phraseB = dbmB.get('db.name');

                if (Ember.isEmpty(countyA) !== true) {
                    phraseA = countyA + phraseA;
                }

                if (Ember.isEmpty(countyB) !== true) {
                    phraseB = countyB + phraseB;
                }

                return this.customStringCompare(phraseA, phraseB);
            }

            return stateDiff;
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);

            controller.set('allSubjects', resolved.matter_subjects);

            controller.set('subjects', resolved.matter_subjects.filter(function (s) {
                var exists = resolved.databases.mapBy('subjectIds').reduce(function (previous, subjects) {
                    return previous || subjects.includes('' + s.id);
                }, false);

                return exists;
            }).sortBy('fullName'));

            controller.set('databasesWithMemos', resolved.databases.map(function (dbm) {
                dbm.set('memos', dbm.get('db.dbMemos').sortBy('-createdOn'));
                return dbm;
            }).map(function (dbm) {
                dbm.set('subjects', dbm.subjectIds.map(function (sid) {
                    return resolved.matter_subjects.findBy('id', sid);
                }));
                return dbm;
            }));

            controller.set('tags', this.store.peekAll('db-tag').filter(function (t) {
                return resolved.tags.includes(t.id);
            }));

            controller.set('matter_id', resolved.matter_id);
            controller.set('matter', resolved.matter);
            controller.set('matterHasCurrentUser', resolved.matterHasCurrentUser);

            if (Ember.isEmpty(this.firstTimeObject[resolved.matter_id]) || resolved.expand) {
                controller.expandAll();
                controller.expandAllSubjects();
                this.firstTimeObject[resolved.matter_id] = true;
            }

            controller.startListening();
        },
        deactivate: function deactivate() {
            this.controllerFor('matter.db-memos.browse-memos').stopListening();
        },


        actions: {
            willTransition: function willTransition(transition) {
                var matter = this.get('currentMatter.matter');
                if (matter.get('hasDirtyAttributes')) {
                    matter.rollbackAttributes();
                }
            },
            refreshModel: function refreshModel() {
                this.refresh();
            },
            toggleDbMemo: function toggleDbMemo(dbm, expanded) {
                if (expanded) {
                    dbm.set('isExpanded', true);
                    if (this.get('expandedDbs').includes(dbm.db.id)) {
                        return;
                    }
                    this.get('expandedDbs').pushObject(dbm.db.id);
                } else {
                    dbm.set('isExpanded', false);
                    if (!this.get('expandedDbs').includes(dbm.db.id)) {
                        return;
                    }
                    this.get('expandedDbs').removeObject(dbm.db.id);
                }
            }
        }
    });
});