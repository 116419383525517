define('tm3/pods/matter/admin/budgets/controller', ['exports', 'tm3/validators/budget', 'ember-changeset-validations', 'ember-changeset'], function (exports, _budget, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Controller.extend({
        BudgetValidations: _budget.default,
        notify: Ember.inject.service(),
        budget: null,
        showBudgetModal: false,
        budgetChangeset: null,

        budgets: alias('model.budgets'),

        totalBudgets: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + b.get('amount');
            }, 0);
        }),

        totalEstimatedHours: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + b.get('estimatedHours');
            }, 0);
        }),

        totalTimeSpent: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + (b.get('budgetProgress').time_spent > 0 ? b.get('budgetProgress').time_spent : 0);
            }, 0);
        }),

        totalRemaining: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + (b.get('budgetProgress').remaining > 0 ? b.get('budgetProgress').remaining : 0);
            }, 0);
        }),

        totalServices: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + b.get('budgetProgress').services;
            }, 0);
        }),

        totalExpenses: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + b.get('budgetProgress').expenses;
            }, 0);
        }),

        totalNonBillable: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + b.get('budgetProgress').non_billable;
            }, 0);
        }),

        totalSpent: Ember.computed('budgets.[]', function () {
            return this.get('budgets').reduce(function (previous, b) {
                return previous + b.get('budgetProgress').spent;
            }, 0);
        }),

        actions: {
            showBudgetForm: function showBudgetForm() {
                var budget = this.store.createRecord('budget', {
                    name: 'Default Budget',
                    matter: this.get('model.matter'),
                    includeExpenses: null,
                    trackedType: 'dollars',
                    startOn: moment().format('YYYY-MM-DD')
                });
                var budgetChangeset = new _emberChangeset.default(budget, (0, _emberChangesetValidations.default)(_budget.default), _budget.default);
                this.set('budgetChangeset', budgetChangeset);
                this.set('showBudgetModal', true);
            },
            showEditForm: function showEditForm(budget) {
                var budgetChangeset = new _emberChangeset.default(budget, (0, _emberChangesetValidations.default)(_budget.default), _budget.default);
                this.set('budgetChangeset', budgetChangeset);
                this.set('showBudgetModal', true);
            },
            hideForm: function hideForm() {
                this.set('showBudgetModal', false);
            },
            reorderBudgets: function reorderBudgets(budgets) {
                var _this = this;

                budgets.forEach(function (budget, index) {
                    budget.set('weight', index);
                });
                var data = budgets.map(function (budget) {
                    return budget.getProperties('id', 'weight');
                });

                budgets.get('firstObject').updateWeights({
                    budgets: data
                }).then(function () {
                    _this.get('notify').success('New order saved');
                    _this.send('refreshModel');
                });
            },
            duplicateBudget: function duplicateBudget(budget) {
                var newBudget = this.store.createRecord('budget', budget.toJSON());
                newBudget.set('id', null);
                newBudget.set('enabled', 1);
                newBudget.set('deadlineMet', null);
                var budgetChangeset = new _emberChangeset.default(newBudget, (0, _emberChangesetValidations.default)(_budget.default), _budget.default);
                this.set('budgetChangeset', budgetChangeset);
                this.set('showBudgetModal', true);
            }
        }
    });
});