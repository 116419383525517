define('tm3/serializers/db-tag', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            hash.parentId = hash.parent_id;
            hash.grandParentId = hash.grand_parent_id;
            return this._super(typeClass, hash, prop);
        }
    });
});