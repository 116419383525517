define('tm3/pods/matter/admin/visualizations/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var model = this.modelFor('matter');
            return Ember.RSVP.hash({
                matter: model.matter,
                visualizations: this.store.query('matter-has-visualization', {
                    matter_id: model.matter.id
                })
            });
        },

        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});