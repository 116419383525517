define('tm3/pods/matter/subjects/info/addresses/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),
        subjectId: null,

        beforeModel: function beforeModel(transition) {
            this.set('subjectId', transition.params['matter.subjects.info'].subject_id);
        },
        model: function model(params) {
            return Ember.RSVP.hash({
                subjectId: this.get('subjectId'),
                addresses: [],
                newAddress: this.store.createRecord('subject-addr'),
                addressTypes: this.get('lists').getListForGroup('address_type')
            });
        },
        setupController: function setupController(controller, resolved) {
            resolved.newAddress.set('isEditing', true);
            this._super(controller, resolved);
            var subjectInfo = this.modelFor('matter.subjects.info');
            var subject = subjectInfo.subject.get('firstObject');
            controller.set('model.addresses', subject.get('addresses'));
            controller.set('subject', subject);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            },
            saveAddress: function saveAddress(address) {
                var _this = this;

                address.set('subject', this.get('controller.subject'));
                address.save().then(function () {
                    address.set('isEditing', false);
                    _this.get('notify').success('Address was saved');
                    _this.send('fetchSubject');
                    _this.send('fetch');
                }, function () {
                    _this.get('notify').error('Not saved');
                });
            },
            setFormIsOpenFlag: function setFormIsOpenFlag(value) {
                this.send('modifyFormIsOpen', value);
            },
            willTransition: function willTransition(transition) {
                var newNote = this.get('controller.newNote');
                if (newNote) {
                    newNote.rollbackAttributes();
                }
                this.set('controller.showNewNoteForm', false);
                this.send('modifyFormIsOpen', false);
                return true;
            }
        }
    });
});