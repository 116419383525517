define('tm3/pods/admin/employees/info/details/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),

        /**
         * @property
         *
         * group to be added to the employee
         */
        newGroup: null,

        /**
         * @property
         *
         * team to be added to the employee
         */
        newTeam: null,

        showSaveButton: Ember.computed.notEmpty('model.avatarId'),

        actions: {
            roleChanged: function roleChanged(selectedRole) {
                this.set('model.employee.role', selectedRole.get('display'));
            },
            deptChanged: function deptChanged(selectedDept) {
                this.set('model.employee.department', selectedDept.get('display'));
            },
            saveAvatar: function saveAvatar() {
                var _this = this;

                var employee = this.get('model.employee');
                employee.save().then(function () {
                    _this.get('notify').success('Avatar was saved');
                }, function () {
                    _this.validationReport(employee);
                });
            },
            removeAvatar: function removeAvatar() {
                var _this2 = this;

                var employee = this.get('model.employee');
                employee.set('avatarId', null);
                employee.save().then(function () {
                    _this2.get('notify').success('Avatar was removed');
                }, function () {
                    _this2.validationReport(employee);
                });
            },
            edit: function edit() {
                this.set('isEditing', true);
            },
            cancel: function cancel() {
                this.get('model.employee').rollbackAttributes();
                this.set('isEditing', false);
            },
            save: function save() {
                var _this3 = this;

                var employee = this.get('model.employee');
                employee.save().then(function () {
                    _this3.set('isEditing', false);
                    _this3.get('notify').success('Employee saved successfully');
                }, function () {
                    _this3.validationReport(employee);
                });
            },
            makeActive: function makeActive() {
                var _this4 = this;

                var employee = this.get('model.employee');
                employee.set('status', 'Active');
                employee.save().then(function () {
                    _this4.get('notify').success('Employee saved successfully');
                });
            },
            makeInactive: function makeInactive() {
                var _this5 = this;

                var employee = this.get('model.employee');
                employee.set('status', 'Inactive');
                employee.save().then(function () {
                    _this5.get('notify').success('Employee saved successfully');
                });
            }
        }
    });
});