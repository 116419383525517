define('tm3/validators/matter', ['exports', 'ember-changeset-validations/validators', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _sometimes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        matterType: (0, _validators.validatePresence)(true),
        typeOfService: (0, _validators.validatePresence)(true),
        product: (0, _validators.validatePresence)(true),
        litigationType: (0, _validators.validatePresence)(true),

        tmCode: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function () {
            return !this.get('isNew');
        }),

        name: [(0, _validators.validateLength)({ max: 200 }), (0, _sometimes.default)((0, _validators.validatePresence)({ presence: true, ignoreBlank: true }), function () {
            return this.get('litigationType') === 'N/A';
        }), (0, _validators.validateExclusion)({
            list: ['et al vs.', ' et al vs. ', ' v. '],
            // allowBlank: true,
            message: 'is required'
        })],

        plaintiff: (0, _sometimes.default)([(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1 })], function () {
            return this.get('litigationType') !== 'N/A';
        }),

        defendant: (0, _sometimes.default)([(0, _validators.validatePresence)(true)], function () {
            return this.get('litigationType') !== 'N/A';
        }),

        origin: (0, _validators.validatePresence)(true),
        typeOfReportTab: (0, _validators.validatePresence)(true),
        useNicknameInPortal: (0, _validators.validatePresence)(true),
        purposeOfInvestigation: (0, _validators.validateLength)({ max: 1000, allowBlank: true })
    };
});