define('tm3/helpers/dates-begin-and-end-year', ['exports', 'tm-common/helpers/dates-begin-and-end-year'], function (exports, _datesBeginAndEndYear) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _datesBeginAndEndYear.default;
    }
  });
  Object.defineProperty(exports, 'datesBeginAndEndYear', {
    enumerable: true,
    get: function () {
      return _datesBeginAndEndYear.datesBeginAndEndYear;
    }
  });
});