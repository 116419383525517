define('tm3/pods/report/info/credit-card-reconciliation/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'credit_card_reconciliation',

        queryParams: {
            start: {
                refreshModel: false
            },
            end: {
                refreshModel: false
            },
            employee: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 50,
                    page: params.page ? params.page : 1,
                    start: params.start,
                    end: params.end,
                    employee_id: params.employee
                }),
                employees: this.store.query('employee', {
                    status: 'Active',
                    sortField: 'first_name,last_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer']
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                params: params
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);

            if (resolved.params.employee) {
                controller.set('selectedEmployee', resolved.employees.findBy('id', resolved.params.employee));
            }
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});