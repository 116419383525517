define('tm3/pods/accounting/billing/subscription-costs/processes/controller', ['exports', 'ember-cli-table-pagination/mixins/table-pager/controller', 'ember-cli-table-pagination/mixins/table-pager/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        notify: Ember.inject.service(),

        // logic to handle sorting a list
        sortField: '-id',

        // load pager specific variables
        columns: [_column.default.create({
            displayName: 'Execution Date',
            fieldName: 'formattedExecutionDate',
            apiName: 'execution_date',
            order: 0
        }), _column.default.create({
            displayName: 'Executed By',
            fieldName: 'createdBy.fullName',
            apiName: 'created_by_id',
            order: 1
        }), _column.default.create({
            displayName: 'Billable Group Start',
            fieldName: 'billableGroup.start',
            disableServerInteractions: true,
            order: 2
        }), _column.default.create({
            displayName: 'Billable Group End',
            fieldName: 'billableGroup.end',
            disableServerInteractions: true,
            order: 3
        }), _column.default.create({
            displayName: 'Billable Group Total',
            fieldName: 'billableGroup.formattedTotal',
            disableServerInteractions: true,
            order: 4
        })],

        // need to open single matter record
        linkPath: 'accounting.billing.subscription-costs.processes.info',

        with: 'all',

        tableTitle: 'Historical Prorated Subscriptions',

        toolsParams: Ember.computed(function () {
            return {
                buttonLink: 'accounting.billing.subscription-costs.processes.run',
                buttonText: 'Execute Prorate Process'
            };
        }),

        actions: {
            deleteProcess: function deleteProcess(prorateProcess) {
                var _this = this;

                if (!prorateProcess.get('canBeReversed')) {
                    this.get('notify').warning('Some invoice is already BILLED this process can not be reversed');
                    return;
                }
                prorateProcess.reverse().then(function () {
                    _this.get('notify').success('The process has been reversed correctly');
                    _this.send('refresh');
                }).catch(function () {
                    _this.get('notify').error('The process can not been reversed');
                });
            }
        }
    });
});