define('tm3/breakpoints', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        mobile: '(max-width: 767px)',
        tablet: '(min-width: 768px) and (max-width: 991px)',
        desktop: '(min-width: 992px) and (max-width: 1209px)',
        jumbo: '(min-width: 1210px)'
    };
});