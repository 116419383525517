define('tm3/pods/matter/db-memos/find-db/route', ['exports', 'tm3/mixins/search-db-route'], function (exports, _searchDbRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_searchDbRoute.default, {
        displayMemosCount: true,

        actions: {
            createDbMemo: function createDbMemo(selectedDatabases) {
                this.controller.set('selectedDatabases', selectedDatabases);
                this.controller.set('showSubjects', true);
                this.controller.get('getSubjects').perform();
            },
            createDbMemoWithSubjects: function createDbMemoWithSubjects() {
                var _this = this;

                this.controller.set('showSubjects', false);
                var selectedDatabases = this.controller.get('selectedDatabases');
                var selectedSubjects = this.controller.get('selectedSubjects');
                if (typeof selectedDatabases.get('id') !== 'undefined') {
                    selectedDatabases = [selectedDatabases];
                }
                var currentMatter = this.get('currentMatter');
                var promises = [];
                var dbMemo = null;
                this.store.findRecord('matter', currentMatter.id).then(function (matter) {
                    // Only open a new tab if there is only 1 selected
                    selectedDatabases.forEach(function (db) {
                        dbMemo = _this.store.createRecord('db-memo', {
                            db: db,
                            matter: matter,
                            body: ''
                        });
                        promises.pushObject(dbMemo.save());
                    });
                    Ember.RSVP.all(promises).then(function (memos) {
                        memos.get('firstObject').selectSubjects({
                            subject_ids: selectedSubjects.mapBy('id'),
                            memo_ids: memos.mapBy('id')
                        }).then(function () {
                            _this.get('notify').success('Database memos have been created');
                            if (selectedDatabases.length === 1) {
                                selectedDatabases.forEach(function (db) {
                                    if (db.get('url')) {
                                        window.open(db.get('url'), '_blank');
                                    }
                                });
                                var db = selectedDatabases.get('firstObject');
                                _this.transitionTo('matter.db-memos.for-db', db.get('id'), {
                                    queryParams: {
                                        edit_id: memos.get('firstObject.id')
                                    }
                                });
                            } else {
                                _this.transitionTo('matter.db-memos.browse-memos', {
                                    queryParams: { expand: true }
                                });
                            }
                        });
                    });
                });
            }
        }
    });
});