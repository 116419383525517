define('tm3/pods/crm/contacts/info/matters/add-matter/controller', ['exports', 'tm-common/mixins/crud/error', 'ember-validations'], function (exports, _error, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, _emberValidations.default, {
        notify: Ember.inject.service(),
        contactsInfoMattersController: Ember.inject.controller('crm.contacts.info.matters'),

        validations: {
            relatedMatter: { presence: true },
            selectedGroup: { presence: true }
        },

        selectOptions: [],

        relatedMatter: null,
        selectedGroup: null,

        actions: {
            /**
             *
             */
            save: function save() {
                var self = this;

                var matter = this.get('relatedMatter');
                var user = this.get('clientUser');
                var group = this.get('selectedGroup');

                self.validate().then(function () {
                    self.store.query('matter-has-user', {
                        matter_id: matter.get('id'),
                        group_id: group.get('id'),
                        user_id: user.get('id')
                    }).then(function (data) {
                        if (Ember.isEmpty(data)) {
                            var mhu = self.store.createRecord('matter-has-user', {
                                matter: matter,
                                user: user,
                                group: group,
                                userType: 'client',
                                bill: self.get('bill'),
                                billCopy: self.get('billCopy'),
                                report: self.get('report'),
                                referredBy: self.get('referredBy'),
                                whoHired: self.get('whoHired'),
                                contact: self.get('contact.value')
                            });
                            // no existing records found, save this one
                            mhu.save().then(function (data) {
                                // attempt to reset form data
                                self.set('relatedMatter', null);
                                self.set('selectedCaseName', '');
                                self.set('selectedGroup', null);
                                self.set('selectOptions', []);
                                self.get('notify').success('Matter added');
                                self.transitionToRoute('crm.contacts.info.matters', user.get('id'));
                                self.get('contactsInfoMattersController').send('fetch');
                            }, function () {
                                self.validationReport(mhu);
                            });
                        } else {
                            self.set('relatedMatter', null);
                            self.get('notify').success('This matter is already associated with this client');
                            self.transitionToRoute('crm.contacts.info.matters', user.get('id'));
                            self.get('contactsInfoMattersController').send('fetch');
                        }
                    });
                }, function () {
                    self.notifyClientSideValidations(self);
                });
            },


            /** power-select for clients */
            searchMatters: function searchMatters(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchMatters, term, resolve, reject, 600);
                });
            }
        },

        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            return this.store.query('matter', {
                'name||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});