define('tm3/pods/admin/employees/info/billing/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tm-common/mixins/authorized-route-mixin'], function (exports, _authenticatedRouteMixin, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authorizedRouteMixin.default, {
        requiredPermissions: ['Manage Employees'],

        model: function model(params) {
            var infoModel = this.modelFor('admin.employees.info');

            return Ember.RSVP.hash({
                employee: this.store.findRecord('employee', infoModel.employee.get('id')),
                user: this.store.findRecord('user', infoModel.employee.get('id'))
            });
        },

        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});