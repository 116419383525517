define('tm3/pods/components/matter/workflows/edit-workflow/component', ['exports', 'tm3/pods/components/matter/workflows/edit-workflow/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            this.set('selectedEmployee', this.get('employees').findBy('id', '' + this.get('workflow.assignedToId')));
        },


        actions: {
            changeStatus: function changeStatus(workflow, newStatus) {
                workflow.set('statusOption', newStatus);

                if (newStatus) {
                    workflow.set('status', newStatus.get('value'));
                } else {
                    workflow.set('status', undefined);
                }
            },
            updateAssignee: function updateAssignee(workflow, newAssignee) {
                if (newAssignee) {
                    workflow.set('assignedToId', newAssignee.get('id'));
                    workflow.set('assignedTo', newAssignee.get('shortName'));
                    workflow.set('various', false);
                    this.set('selectedEmployee', newAssignee);
                } else {
                    workflow.set('assignedToId', null);
                    workflow.set('assignedTo', null);
                    workflow.set('various', false);
                    this.set('selectedEmployee', null);
                }
            }
        }
    });
});