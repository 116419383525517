define('tm3/pods/workflows-browser/controller', ['exports', 'moment', 'html2canvas', 'tm-common/mixins/crud/error'], function (exports, _moment, _html2canvas, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Various = Ember.Object.create({
        fullName: 'Various',
        shortName: 'Various',
        id: -2
    });
    var ToBeAssigned = Ember.Object.create({
        fullName: 'To Be Assigned',
        shortName: 'To Be Assigned',
        id: -1
    });

    exports.default = Ember.Controller.extend(_error.default, {
        reportBuilder: Ember.inject.service(),
        notify: Ember.inject.service(),
        workflowStatuses: Ember.computed.alias('model.workflowStatuses'),
        caseWorkflows: Ember.computed.alias('model.case_workflows'),
        subjectWorkflows: Ember.computed.alias('model.subject_workflows'),
        owners: Ember.computed.alias('model.owners'),
        matterTypes: Ember.computed.alias('model.matterTypes'),
        collections: Ember.computed.alias('model.collections'),
        teams: Ember.computed.alias('model.teams'),
        employees: Ember.computed('model.employees', function () {
            var employees = this.get('model.employees').toArray();
            employees.unshift(Various);
            return employees;
        }),
        filterEmployees: Ember.computed('model.employees', function () {
            var employees = this.get('model.employees').filterBy('includeInScheduling', 1).toArray();
            employees.unshift(Various);
            employees.unshift(ToBeAssigned);
            return employees;
        }),
        notAssignedEmployees: Ember.computed('model.employees', 'filteredAndSortedCaseWorkflows', function () {
            var employees = this.get('model.employees').mapBy('id').map(function (id) {
                return parseInt(id);
            }).sort();
            var employeesAssigned = this.get('filteredAndSortedCaseWorkflows').reject(function (cw) {
                return Ember.isEmpty(cw.get('assignedToId'));
            }).map(function (cw) {
                return parseInt(cw.get('assignedToId'));
            }).uniq().sort();
            var notAssigned = employees.reject(function (e) {
                return employeesAssigned.includes(e);
            }).sort();
            return this.get('model.employees').filter(function (e) {
                return notAssigned.includes(parseInt(e.get('id')));
            }).filter(function (e) {
                return e.get('includeInScheduling');
            });
        }),

        queryParams: ['excludeNotAssigned', 'progressStatus'],
        currentTab: 'to-be-assigned',
        groupBy: 'grouped-by-type',
        sortField: 'calcDeadline',
        sortDirection: true, // true = ascending, false = descending
        empSortField: 'fullName',
        empSortDirection: true, // true = ascending, false = descending
        groupByEmployee: false,
        groupByWorkflowType: false,
        excludeNotAssigned: false,
        addedToScheduler: 'All',
        hardDeadlinesOnly: false,
        softDeadlinesOnly: false,
        expandAll: false,
        expandedEmployees: {},
        expandedWorkflowTypes: {},
        expandedCases: {},

        filterStatus: 'exclude_completed',
        filterPriority: null,
        filterDeadlineFrom: null,
        filterDeadlineTo: null,
        filterDeadlineType: null,
        filterDueFrom: null,
        filterDueTo: null,
        filterCompletedFrom: null,
        filterCompletedTo: null,
        filterEmployee: null,
        filterOwner: null,
        operatorWorkflow: 'not_in',
        filterWorkflow: [45], // Default Case Mgr not in
        selectedOperator: null,
        selectedEmployee: null,
        selectedProgressStatusFilter: null,
        selectedMatterTypes: null,
        selectedCollections: null,
        selectedTeams: null,

        statusFilterOptions: [Ember.Object.create({ value: 'all', display: 'Show All' }), Ember.Object.create({
            value: 'exclude_completed',
            display: 'Exclude Completed'
        })],

        wfOperators: [Ember.Object.create({ value: 'in', display: 'Is one of' }), Ember.Object.create({
            value: 'not_in',
            display: 'Is NOT one of'
        })],

        deadlineTypeFilterOptions: [Ember.Object.create({ value: 'hard', display: 'Hard Deadlines Only' }), Ember.Object.create({ value: 'soft', display: 'Soft Deadlines Only' })],

        priorityFilterOptions: [Ember.Object.create({ value: 'white', display: 'No Deadline' }), Ember.Object.create({ value: 'green', display: 'Deadline in 7+ days' }), Ember.Object.create({ value: 'orange', display: 'Deadline within 7 days' }), Ember.Object.create({
            value: 'red',
            display: 'Deadline within 2 days'
        }), Ember.Object.create({ value: 'gray', display: 'Deadline in Past' })],

        progressStatusFilterOptions: [Ember.Object.create({
            value: 'Not Started',
            display: 'Not Started'
        }), Ember.Object.create({
            value: 'Hold',
            display: 'Hold'
        }), Ember.Object.create({
            value: 'In Progress',
            display: 'In Progress'
        }), Ember.Object.create({
            value: 'Complete',
            display: 'Complete'
        })],

        progressStatusObserver: Ember.observer('selectedProgressStatusFilter', function () {
            this.set('progressStatus', (this.get('selectedProgressStatusFilter') || []).mapBy('value'));
        }),

        // TODO update with new filters
        filterCount: Ember.computed('filterStatus', 'filterPriority', 'filterDeadlineFrom', 'filterDeadlineTo', 'filterDeadlineType', 'filterCompletedFrom', 'filterCompletedTo', 'filterEmployee', 'filterOwner', 'filterWorkflow', 'hardDeadlinesOnly', 'softDeadlinesOnly', 'excludeNotAssigned', 'addedToScheduler', 'selectedProgressStatusFilter', 'selectedMatterTypes', 'selectedCollections', 'selectedTeams', 'filterDueFrom', 'filterDueTo', function () {
            var count = 0;
            if (this.get('filterStatus')) count++;
            if (this.get('filterPriority')) count++;
            if (this.get('filterDeadlineFrom')) count++;
            if (this.get('filterDeadlineTo')) count++;
            if (this.get('filterDeadlineType')) count++;
            if (this.get('filterCompletedFrom')) count++;
            if (this.get('filterCompletedTo')) count++;
            if (this.get('filterEmployee')) count++;
            if (this.get('filterOwner')) count++;
            if (this.get('filterWorkflow')) count++;
            if (this.get('hardDeadlinesOnly')) count++;
            if (this.get('softDeadlinesOnly')) count++;
            if (!this.get('excludeNotAssigned')) count++;
            if (this.get('addedToScheduler') !== 'All') count++;
            if (Ember.isPresent(this.get('selectedProgressStatusFilter'))) count++;
            if (Ember.isPresent(this.get('selectedMatterTypes'))) count++;
            if (Ember.isPresent(this.get('selectedCollections'))) count++;
            if (Ember.isPresent(this.get('selectedTeams'))) count++;
            if (this.get('filterDueFrom')) count++;
            if (this.get('filterDueTo')) count++;
            return count;
        }),

        filteredAndSortedCaseWorkflows: Ember.computed('caseWorkflows', 'filterStatus', 'filterPriority', 'filterDeadlineFrom', 'filterDeadlineTo', 'filterDeadlineType', 'filterCompletedFrom', 'filterCompletedTo', 'filterEmployee', 'filterOwner', 'filterWorkflow', 'operatorWorkflow', 'sortField', 'sortDirection', 'hardDeadlinesOnly', 'softDeadlinesOnly', 'excludeNotAssigned', 'addedToScheduler', 'selectedProgressStatusFilter', 'selectedMatterTypes', 'selectedCollections', 'selectedTeams', 'filterDueFrom', 'filterDueTo', 'currentTab', function () {
            var _this = this;

            var records = this.get('caseWorkflows').filter(function () {
                return true;
            });
            switch (this.get('filterStatus')) {
                case 'C':
                case 'P':
                case 'I':
                case 'S':
                case 'U':
                case 'N':
                    records = records.filterBy('status', this.get('filterStatus'));
                    break;
                case 'exclude_completed':
                    records = records.rejectBy('status', 'C');
                    break;
            }

            if (this.get('excludeNotAssigned')) {
                records = records.filter(function (rec) {
                    return Ember.isPresent(rec.get('assignedToId'));
                });
            }
            switch (this.get('currentTab')) {
                case 'to-be-assigned':
                    records = records.filter(function (rec) {
                        return Ember.isEmpty(rec.get('assignedToId'));
                    });
                    break;
                case 'assigned':
                    records = records.filter(function (rec) {
                        return Ember.isPresent(rec.get('assignedToId'));
                    });
                    break;
            }
            switch (this.get('addedToScheduler')) {
                case 'All':
                    // nothing to do
                    break;
                case 'Yes':
                    records = records.filter(function (rec) {
                        return rec.get('onScheduler');
                    });
                    break;
                case 'No':
                    records = records.filter(function (rec) {
                        return !rec.get('onScheduler');
                    });
                    break;
            }
            if (Ember.isPresent(this.get('selectedProgressStatusFilter'))) {
                records = records.filter(function (rec) {
                    return _this.get('selectedProgressStatusFilter').mapBy('value').includes(rec.get('matterProgressStatus'));
                });
            }
            if (Ember.isPresent(this.get('selectedMatterTypes'))) {
                records = records.filter(function (rec) {
                    return _this.get('selectedMatterTypes').mapBy('id').map(function (id) {
                        return +id;
                    }).includes(rec.get('matterTypeId'));
                });
            }
            if (Ember.isPresent(this.get('selectedCollections'))) {
                records = records.filter(function (rec) {
                    return _this.get('selectedCollections').mapBy('id').map(function (id) {
                        return +id;
                    }).includes(rec.get('matterCollectionId'));
                });
            }
            if (Ember.isPresent(this.get('selectedTeams'))) {
                records = records.filter(function (rec) {
                    return _this.get('selectedTeams').mapBy('employees').find(function (employees) {
                        return employees.includes('' + rec.get('assignedToId'));
                    });
                });
            }
            if (Ember.isPresent(this.get('filterEmployee'))) {
                var employees = this.get('filterEmployee');
                records = records.filter(function (rec) {
                    var filterStatus = false;
                    employees.forEach(function (emp) {
                        if (filterStatus) return;
                        switch (emp) {
                            case Various.id:
                                filterStatus = !!rec.get('various');
                                break;
                            case ToBeAssigned.id:
                                filterStatus = rec.get('assignedToId') === null;
                                break;
                            default:
                                if (+rec.get('assignedToId') === +emp) {
                                    filterStatus = true;
                                }
                        }
                    });
                    return filterStatus;
                });
            }
            if (Ember.isPresent(this.get('filterWorkflow'))) {
                records = records.filter(function (rec) {
                    if (_this.get('operatorWorkflow') === 'in') {
                        return _this.get('filterWorkflow').contains(rec.get('workflowId'));
                    } else {
                        return !_this.get('filterWorkflow').contains(rec.get('workflowId'));
                    }
                });
            }
            if (Ember.isPresent(this.get('filterOwner'))) {
                var _employees = this.get('filterOwner');
                records = records.filter(function (rec) {
                    var filterStatus = false;
                    _employees.forEach(function (emp) {
                        if (filterStatus) return;
                        var owners = rec.get('caseOwnerIds');
                        filterStatus = owners.includes(typeof owners[0] === 'string' ? '' + emp : +emp);
                    });
                    return filterStatus;
                });
            }
            if (Ember.isPresent(this.get('filterPriority'))) {
                records = records.filter(function (rec) {
                    return rec.get('dotColor') === _this.get('filterPriority');
                });
            }
            if (Ember.isPresent(this.get('filterDeadlineFrom'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcDeadline')).isSameOrAfter((0, _moment.default)(_this.get('filterDeadlineFrom')));
                });
            }
            if (Ember.isPresent(this.get('filterDeadlineTo'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcDeadline')).isSameOrBefore((0, _moment.default)(_this.get('filterDeadlineTo')));
                });
            }
            if (Ember.isPresent(this.get('filterDeadlineType'))) {
                records = records.filter(function (rec) {
                    switch (_this.get('filterDeadlineType')) {
                        case 'soft':
                            return !rec.get('hardDeadline');
                        case 'hard':
                            return !!rec.get('hardDeadline');
                        default:
                            return true;
                    }
                });
            }
            if (Ember.isPresent(this.get('filterCompletedFrom'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('completedOn')).isSameOrAfter((0, _moment.default)(_this.get('filterCompletedFrom')));
                });
            }
            if (Ember.isPresent(this.get('filterCompletedTo'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('completedOn')).isSameOrBefore((0, _moment.default)(_this.get('filterCompletedTo')));
                });
            }
            if (Ember.isPresent(this.get('filterDueFrom'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcNextDue')).isSameOrAfter((0, _moment.default)(_this.get('filterDueFrom')));
                });
            }
            if (Ember.isPresent(this.get('filterDueTo'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcNextDue')).isSameOrBefore((0, _moment.default)(_this.get('filterDueTo')));
                });
            }

            switch (this.get('sortField')) {
                case 'matterCode':
                case 'name':
                case 'status':
                case 'comment':
                case 'assignedTo':
                case 'hours':
                case 'totalHours':
                case 'buffer':
                    records = records.sortBy(this.get('sortField'));
                    break;
                case 'deadline':
                case 'calcDeadline':
                case 'createdOn':
                case 'assignedOn':
                case 'completedOn':
                case 'lastReportDate':
                case 'lastEntry':
                case 'onSchedulerSince':
                    records = records.sort(function (a, b) {
                        var direction = _this.get('sortDirection');
                        var aDate = a.get(_this.get('sortField'));
                        var bDate = b.get(_this.get('sortField'));
                        if (bDate == aDate) return 0;
                        if (aDate === null) return direction ? Infinity : -Infinity;
                        if (bDate === null) return direction ? -Infinity : Infinity;
                        return (0, _moment.default)(aDate, 'YYYY-MM-DD', true).diff((0, _moment.default)(bDate, 'YYYY-MM-DD', true), 'days');
                    });
                    break;
            }

            console.log({ total_case_workflows: records.length });
            return this.get('sortDirection') ? records : records.reverse();
        }),

        filteredAndSortedSubjectWorkflows: Ember.computed('subjectWorkflows', 'filterStatus', 'filterPriority', 'filterDeadlineFrom', 'filterDeadlineTo', 'filterDeadlineType', 'filterCompletedFrom', 'filterCompletedTo', 'filterEmployee', 'filterOwner', 'filterWorkflow', 'operatorWorkflow', 'sortField', 'sortDirection', 'hardDeadlinesOnly', 'softDeadlinesOnly', 'excludeNotAssigned', 'addedToScheduler', 'selectedProgressStatusFilter', 'selectedMatterTypes', 'selectedCollections', 'selectedTeams', 'filterDueFrom', 'filterDueTo', 'currentTab', function () {
            var _this2 = this;

            var records = this.get('subjectWorkflows').filter(function () {
                return true;
            });
            switch (this.get('filterStatus')) {
                case 'C':
                case 'P':
                case 'I':
                case 'S':
                case 'U':
                case 'N':
                    records = records.filterBy('status', this.get('filterStatus'));
                    break;
                case 'exclude_completed':
                    records = records.rejectBy('status', 'C');
                    break;
            }

            if (this.get('excludeNotAssigned')) {
                records = records.filter(function (rec) {
                    return Ember.isPresent(rec.get('assignedToId'));
                });
            }
            switch (this.get('currentTab')) {
                case 'to-be-assigned':
                    records = records.filter(function (rec) {
                        return Ember.isEmpty(rec.get('assignedToId'));
                    });
                    break;
                case 'assigned':
                    records = records.filter(function (rec) {
                        return Ember.isPresent(rec.get('assignedToId'));
                    });
                    break;
            }
            if (Ember.isPresent(this.get('selectedProgressStatusFilter'))) {
                records = records.filter(function (rec) {
                    return _this2.get('selectedProgressStatusFilter').mapBy('value').includes(rec.get('matterProgressStatus'));
                });
            }
            if (Ember.isPresent(this.get('selectedMatterTypes'))) {
                records = records.filter(function (rec) {
                    return _this2.get('selectedMatterTypes').mapBy('id').map(function (id) {
                        return +id;
                    }).includes(rec.get('matterTypeId'));
                });
            }
            if (Ember.isPresent(this.get('selectedCollections'))) {
                records = records.filter(function (rec) {
                    return _this2.get('selectedCollections').mapBy('id').map(function (id) {
                        return +id;
                    }).includes(rec.get('matterCollectionId'));
                });
            }
            if (Ember.isPresent(this.get('selectedTeams'))) {
                records = records.filter(function (rec) {
                    return _this2.get('selectedTeams').mapBy('employees').find(function (employees) {
                        return employees.includes('' + rec.get('assignedToId'));
                    });
                });
            }
            if (Ember.isPresent(this.get('filterEmployee'))) {
                var employees = this.get('filterEmployee');
                records = records.filter(function (rec) {
                    var filterStatus = false;
                    employees.forEach(function (emp) {
                        if (filterStatus) return;
                        switch (emp) {
                            case Various.id:
                                filterStatus = !!rec.get('various');
                                break;
                            case ToBeAssigned.id:
                                filterStatus = rec.get('assignedToId') === null;
                                break;
                            default:
                                if (+rec.get('assignedToId') === +emp) {
                                    filterStatus = true;
                                }
                        }
                    });
                    return filterStatus;
                });
            }
            if (Ember.isPresent(this.get('filterWorkflow'))) {
                records = records.filter(function (rec) {
                    if (_this2.get('operatorWorkflow') === 'in') {
                        return _this2.get('filterWorkflow').contains(rec.get('workflowId'));
                    } else {
                        return !_this2.get('filterWorkflow').contains(rec.get('workflowId'));
                    }
                });
            }
            if (Ember.isPresent(this.get('filterOwner'))) {
                var _employees2 = this.get('filterOwner');
                records = records.filter(function (rec) {
                    var filterStatus = false;
                    _employees2.forEach(function (emp) {
                        if (filterStatus) return;
                        var owners = rec.get('caseOwnerIds');
                        filterStatus = owners.includes(typeof owners[0] === 'string' ? '' + emp : +emp);
                    });
                    return filterStatus;
                });
            }
            if (Ember.isPresent(this.get('filterPriority'))) {
                records = records.filter(function (rec) {
                    return rec.get('dotColor') === _this2.get('filterPriority');
                });
            }
            if (Ember.isPresent(this.get('filterDeadlineFrom'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcDeadline')).isSameOrAfter((0, _moment.default)(_this2.get('filterDeadlineFrom')));
                });
            }
            if (Ember.isPresent(this.get('filterDeadlineTo'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcDeadline')).isSameOrBefore((0, _moment.default)(_this2.get('filterDeadlineTo')));
                });
            }
            if (Ember.isPresent(this.get('filterDeadlineType'))) {
                records = records.filter(function (rec) {
                    switch (_this2.get('filterDeadlineType')) {
                        case 'soft':
                            return !rec.get('hardDeadline');
                        case 'hard':
                            return !!rec.get('hardDeadline');
                        default:
                            return true;
                    }
                });
            }
            if (Ember.isPresent(this.get('filterCompletedFrom'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('completedOn')).isSameOrAfter((0, _moment.default)(_this2.get('filterCompletedFrom')));
                });
            }
            if (Ember.isPresent(this.get('filterCompletedTo'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('completedOn')).isSameOrBefore((0, _moment.default)(_this2.get('filterCompletedTo')));
                });
            }
            if (Ember.isPresent(this.get('filterDueFrom'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcNextDue')).isSameOrAfter((0, _moment.default)(_this2.get('filterDueFrom')));
                });
            }
            if (Ember.isPresent(this.get('filterDueTo'))) {
                records = records.filter(function (rec) {
                    return (0, _moment.default)(rec.get('calcNextDue')).isSameOrBefore((0, _moment.default)(_this2.get('filterDueTo')));
                });
            }

            switch (this.get('sortField')) {
                case 'matterCode':
                case 'name':
                case 'status':
                case 'comment':
                case 'assignedTo':
                case 'hours':
                case 'totalHours':
                case 'buffer':
                    records = records.sortBy(this.get('sortField'));
                    break;
                case 'deadline':
                case 'calcDeadline':
                case 'createdOn':
                case 'assignedOn':
                case 'completedOn':
                case 'lastReportDate':
                case 'lastEntry':
                case 'onSchedulerSince':
                    records = records.sort(function (a, b) {
                        var direction = _this2.get('sortDirection');
                        var aDate = a.get(_this2.get('sortField'));
                        var bDate = b.get(_this2.get('sortField'));
                        if (bDate == aDate) return 0;
                        if (aDate === null) return direction ? Infinity : -Infinity;
                        if (bDate === null) return direction ? -Infinity : Infinity;
                        return (0, _moment.default)(aDate, 'YYYY-MM-DD', true).diff((0, _moment.default)(bDate, 'YYYY-MM-DD', true), 'days');
                    });
                    break;
            }

            console.log({ total_subject_workflows: records.length });
            return this.get('sortDirection') ? records : records.reverse();
        }),

        workflowsByEmployee: Ember.computed('filteredAndSortedCaseWorkflows', 'filteredAndSortedSubjectWorkflows', 'expandAll', 'empSortField', 'empSortDirection', 'expandedEmployees', function () {
            var _this3 = this;

            var qtyTotal = 0;
            var hrsTotal = 0;
            var filteredCaseWorklflows = this.get('filteredAndSortedCaseWorkflows');
            var filteredSubjectWorkflows = this.get('filteredAndSortedSubjectWorkflows');
            var allWorkflows = filteredCaseWorklflows.concat(filteredSubjectWorkflows);
            var employeeNames = allWorkflows.map(function (rec) {
                return rec.get('assignedToFullName');
            }).uniq().sort();
            var employees = employeeNames.map(function (fullName) {
                var caseWorkflows = filteredCaseWorklflows.filterBy('assignedToFullName', fullName);
                var subjectWorkflows = filteredSubjectWorkflows.filterBy('assignedToFullName', fullName);
                var qty = _this3.get('filteredAndSortedCaseWorkflows').concat(_this3.get('filteredAndSortedSubjectWorkflows')).filterBy('assignedToFullName', fullName).length;
                var hours = _this3.get('filteredAndSortedCaseWorkflows').concat(_this3.get('filteredAndSortedSubjectWorkflows')).filterBy('assignedToFullName', fullName).reduce(function (acc, rec) {
                    return acc + rec.get('hours');
                }, 0);
                var name = Ember.isPresent(fullName) ? fullName : ' To Be Assigned';
                qtyTotal += qty;
                hrsTotal += hours;
                return {
                    fullName: name,
                    qty: qty,
                    hours: hours,
                    caseWorkflows: caseWorkflows,
                    subjectWorkflows: subjectWorkflows,
                    isExpanded: _this3.get('expandedEmployees')[name] || _this3.get('expandAll')
                };
            });

            switch (this.get('empSortField')) {
                case 'fullName':
                case 'qty':
                case 'hours':
                    employees = employees.sortBy(this.get('empSortField'));
                    break;
            }

            Ember.run.next(function () {
                _this3.set('qtyTotal', qtyTotal);
                _this3.set('hrsTotal', hrsTotal);
            });

            return this.get('empSortDirection') ? employees : employees.reverse();
        }),

        workflowsByWorkflowType: Ember.computed('filteredAndSortedCaseWorkflows', 'filteredAndSortedSubjectWorkflows', 'expandAll', 'wfSortField', 'wfSortDirection', 'expandedWorkflowTypes', function () {
            var _this4 = this;

            var qtyTotal = 0;
            var hrsTotal = 0;
            var filteredCaseWorklflows = this.get('filteredAndSortedCaseWorkflows');
            var filteredSubjectWorkflows = this.get('filteredAndSortedSubjectWorkflows');
            var allWorkflows = filteredCaseWorklflows.concat(filteredSubjectWorkflows);
            var workflowNames = allWorkflows.map(function (rec) {
                return rec.get('name');
            }).uniq().sort();
            var workflowTypes = workflowNames.map(function (workflowName) {
                var caseWorkflows = filteredCaseWorklflows.filterBy('name', workflowName);
                var subjectWorkflows = filteredSubjectWorkflows.filterBy('name', workflowName);
                var qty = caseWorkflows.length;
                var hours = caseWorkflows.reduce(function (acc, rec) {
                    return acc + rec.get('hours');
                }, 0);
                var name = Ember.isPresent(workflowName) ? workflowName : 'N/A';
                qtyTotal += qty;
                hrsTotal += hours;
                return {
                    workflowName: name,
                    qty: qty,
                    hours: hours,
                    caseWorkflows: caseWorkflows,
                    subjectWorkflows: subjectWorkflows,
                    isExpanded: _this4.get('expandedWorkflowTypes')[name] || _this4.get('expandAll')
                };
            });

            switch (this.get('wfSortField')) {
                case 'workflowName':
                case 'qty':
                case 'hours':
                    workflowTypes = workflowTypes.sortBy(this.get('wfSortField'));
                    break;
            }

            Ember.run.next(function () {
                _this4.set('qtyTotal', qtyTotal);
                _this4.set('hrsTotal', hrsTotal);
            });

            return this.get('wfSortDirection') ? workflowTypes : workflowTypes.reverse();
        }),

        workflowsByCase: Ember.computed('filteredAndSortedCaseWorkflows', 'filteredAndSortedSubjectWorkflows', 'expandAll', 'caSortField', 'caSortDirection', 'expandedCases', function () {
            var _this5 = this;

            var qtyTotal = 0;
            var hrsTotal = 0;
            var filteredCaseWorklflows = this.get('filteredAndSortedCaseWorkflows');
            var filteredSubjectWorkflows = this.get('filteredAndSortedSubjectWorkflows');
            var allWorkflows = filteredCaseWorklflows.concat(filteredSubjectWorkflows);
            var cases = allWorkflows.map(function (rec) {
                return rec.get('matterCode');
            }).uniq().sort();
            var caseNames = allWorkflows.map(function (rec) {
                return {
                    tmCode: rec.get('matterCode'),
                    name: rec.get('matterName')
                };
            }).uniq();
            var byCase = cases.map(function (tmCode) {
                var caseWorkflows = filteredCaseWorklflows.filterBy('matterCode', tmCode);
                var subjectWorkflows = filteredSubjectWorkflows.filterBy('matterCode', tmCode);
                var qty = caseWorkflows.length;
                var hours = caseWorkflows.reduce(function (acc, rec) {
                    return acc + rec.get('hours');
                }, 0);
                var name = Ember.isPresent(tmCode) ? tmCode : 'N/A';
                var fullName = caseNames.findBy('tmCode', tmCode).name;
                qtyTotal += qty;
                hrsTotal += hours;
                return {
                    tmCode: name,
                    fullName: fullName,
                    qty: qty,
                    hours: hours,
                    caseWorkflows: caseWorkflows,
                    subjectWorkflows: subjectWorkflows,
                    isExpanded: _this5.get('expandedCases')[name] || _this5.get('expandAll')
                };
            });

            switch (this.get('caSortField')) {
                case 'tmCode':
                case 'qty':
                case 'hours':
                    byCase = byCase.sortBy(this.get('caSortField'));
                    break;
            }

            Ember.run.next(function () {
                _this5.set('qtyTotal', qtyTotal);
                _this5.set('hrsTotal', hrsTotal);
            });

            return this.get('caSortDirection') ? byCase : byCase.reverse();
        }),

        workflows: Ember.computed('caseWorkflows.[]', function () {
            var workflows = [];
            this.get('caseWorkflows').forEach(function (rec) {
                if (!workflows.mapBy('id').includes(rec.get('workflowId'))) {
                    workflows.push(Ember.Object.create({
                        id: rec.get('workflowId'),
                        name: rec.get('name')
                    }));
                }
            });
            return workflows;
        }),

        actions: {
            changeStatusFilter: function changeStatusFilter(newFilter) {
                this.set('selectedStatusFilter', newFilter);
                this.set('filterStatus', newFilter ? newFilter.get('value') : null);
            },
            changeEmployeeFilter: function changeEmployeeFilter(newFilter) {
                this.set('selectedEmployeeFilter', newFilter);
                if (newFilter) {
                    this.set('filterEmployee', newFilter.mapBy('id'));
                } else {
                    this.set('filterEmployee', null);
                }
            },
            changeOperatorWorkflow: function changeOperatorWorkflow(newFilter) {
                this.set('selectedOperator', newFilter);
                if (newFilter) {
                    this.set('operatorWorkflow', newFilter.get('value'));
                } else {
                    this.set('operatorWorkflow', null);
                }
            },
            changeWorkflow: function changeWorkflow(newFilter) {
                this.set('selectedWorkflow', newFilter);
                if (newFilter) {
                    this.set('filterWorkflow', newFilter.mapBy('id'));
                } else {
                    this.set('filterWorkflow', null);
                }
            },
            changeCaseOwnerFilter: function changeCaseOwnerFilter(newFilter) {
                this.set('selectedOwnerFilter', newFilter);
                if (newFilter) {
                    this.set('filterOwner', newFilter.mapBy('id'));
                } else {
                    this.set('filterOwner', []);
                }
            },
            changeDeadlineTypeFilter: function changeDeadlineTypeFilter(newFilter) {
                this.set('selectedDeadlineTypeFilter', newFilter);
                this.set('filterDeadlineType', newFilter ? newFilter.get('value') : null);
            },
            changePriorityFilter: function changePriorityFilter(newFilter) {
                this.set('selectedPriorityFilter', newFilter);
                this.set('filterPriority', newFilter ? newFilter.get('value') : null);
            },
            changeHardDeadline: function changeHardDeadline(checked) {
                if (checked) this.set('softDeadlinesOnly', false);
            },
            changeSoftDeadline: function changeSoftDeadline(checked) {
                if (checked) this.set('hardDeadlinesOnly', false);
            },
            editWorkflow: function editWorkflow(cw) {
                var editing = this.get('caseWorkflows').findBy('isEditing');
                if (editing) {
                    editing.rollbackAttributes();
                    editing.set('isEditing', false);
                }
                if (cw.get('various')) {
                    if (cw.get('matterWorkflowAssigneeId')) {
                        this.set('selectedEmployee', this.get('employees').findBy('id', '' + cw.get('assignedToId')));
                    } else {
                        this.set('selectedEmployee', Various);
                    }
                } else {
                    this.set('selectedEmployee', this.get('employees').findBy('id', '' + cw.get('assignedToId')));
                }
                if (Ember.isEmpty(cw.get('status'))) {
                    cw.set('status', 'N');
                }
                cw.set('isEditing', true);
            },
            cancelEdit: function cancelEdit(cw) {
                cw.rollbackAttributes();
                cw.set('isEditing', false);
            },
            updateAssignee: function updateAssignee(cw, employee) {
                if (employee) {
                    if (employee.get('id') === Various.id) {
                        cw.set('assignedToId', null);
                        cw.set('assignedTo', Various.get('fullName'));
                        cw.set('various', true);
                    } else {
                        cw.set('assignedToId', employee.get('id'));
                        cw.set('assignedTo', employee.get('shortName'));
                        cw.set('various', false);
                    }
                    this.set('selectedEmployee', employee);
                } else {
                    cw.set('assignedToId', null);
                    cw.set('assignedTo', null);
                    cw.set('various', false);
                    this.set('selectedEmployee', null);
                }
            },
            saveCaseWorkflow: function saveCaseWorkflow(cw) {
                var _this6 = this;

                cw.save().then(function () {
                    cw.set('isEditing', false);
                    _this6.get('notify').success('Workflow saved successfully');
                    _this6.send('refreshModel');
                }).catch(function () {
                    _this6.validationReport(cw);
                });
            },
            expandEmployee: function expandEmployee(name) {
                var expandedEmployees = this.get('expandedEmployees');
                expandedEmployees[name] = !expandedEmployees[name];
                this.set('expandedEmployees', Object.assign({}, expandedEmployees));
            },
            expandWorkflowType: function expandWorkflowType(name) {
                var expandedWorkflowTypes = this.get('expandedWorkflowTypes');
                expandedWorkflowTypes[name] = !expandedWorkflowTypes[name];
                this.set('expandedWorkflowTypes', Object.assign({}, expandedWorkflowTypes));
            },
            expandCase: function expandCase(name) {
                var expandedCases = this.get('expandedCases');
                expandedCases[name] = !expandedCases[name];
                this.set('expandedCases', Object.assign({}, expandedCases));
            },
            exportPdf: function exportPdf() {
                var _this7 = this;

                if (this.get('printing')) return;
                this.set('printing', true);

                // doing this to avoid cutting the image generated
                var sideBar = document.querySelector('.main-sidebar');
                var contentWrapper = document.querySelector('.content-wrapper');
                var display = void 0;
                var width = void 0;
                if (sideBar) {
                    display = sideBar.style.display;
                    width = sideBar.style.width;
                    sideBar.style.display = 'none';
                    sideBar.style.width = 0;
                }
                var marginLeft = void 0;
                if (contentWrapper) {
                    marginLeft = contentWrapper.style.marginLeft;
                    contentWrapper.style.marginLeft = 0;
                }
                var table = document.querySelector('.workflows-table');
                table.classList.add('full-height');
                Ember.run.later(function () {
                    (0, _html2canvas.default)(document.querySelector('.tasks-wrapper')).then(function (canvas) {
                        var img = canvas.toDataURL('image/png');

                        if (sideBar) {
                            Ember.run.next(function () {
                                sideBar.style.display = display;
                                sideBar.style.width = width;
                                contentWrapper.style.marginLeft = marginLeft;
                            });
                        }

                        Ember.run.next(function () {
                            table.classList.remove('full-height');
                        });

                        var jsonObject = {
                            report_builder: {
                                standard: {
                                    type: 'pdf_export',
                                    format: 'pdf',
                                    style: 'misc'
                                },
                                custom: {
                                    html: window.btoa(unescape(encodeURIComponent('<img src="' + img + '" />'))),
                                    header_text: 'Workflows',
                                    orientation: 'landscape'
                                }
                            }
                        };

                        _this7.get('reportBuilder').executeReport(jsonObject);
                        _this7.set('printing', false);
                    });
                }, sideBar ? 1000 : 0);
            },
            exportExcel: function exportExcel() {
                var _this8 = this;

                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'excel_export',
                            format: 'excel',
                            style: 'misc'
                        },
                        custom: {
                            title: 'Workflow Browser',
                            columns: [{ display: 'Case', value: 'matterCode' }, { display: 'Workflow', value: 'name' }, { display: 'Employee', value: 'assignedTo' }, { display: 'Est. Time', value: 'hours' }, { display: 'Status', value: 'status' }, { display: 'Deadline', value: 'calcDeadline' }, { display: 'Comments', value: 'comment' }],
                            rows: this.get('filteredAndSortedCaseWorkflows').map(function (row) {
                                var simpleObj = {};
                                simpleObj.matterCode = row.get('matterCode') + ' - ' + row.get('matterName');
                                simpleObj.name = row.get('name');
                                simpleObj.assignedTo = row.get('assignedTo');
                                simpleObj.hours = row.get('hours');
                                simpleObj.status = row.get('status') ? _this8.get('workflowStatuses').findBy('value', row.get('status')).get('display') : 'No Status';
                                simpleObj.deadline = row.get('calcDeadline');
                                simpleObj.comment = row.get('comment');
                                return simpleObj;
                            })
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            },
            changeTab: function changeTab(newTab) {
                this.set('currentTab', newTab);
                if (newTab === 'to-be-assigned') {
                    this.send('changeGroup', 'grouped-by-type');
                }
                if (newTab === 'assigned') {
                    this.send('changeGroup', 'not-grouped');
                }
            },
            changeGroup: function changeGroup(newGroup) {
                this.set('groupBy', newGroup);
            }
        }
    });
});