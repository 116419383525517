define('tm3/pods/admin/teams/team/matters/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Case Settings'],
        model: function model(params) {
            var team = this.modelFor('admin.teams.team');
            return Ember.RSVP.hash({
                team: team,
                teamMatters: this.store.query('matter-has-team', {
                    team_id: team.get('id'),
                    with: 'matters'
                }),
                groups: this.store.query('group', {
                    group_type: '*Case'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('team', resolved.team);
            controller.set('teamMatters', resolved.teamMatters);
            controller.set('groups', resolved.groups);
            controller.set('isEditing', false);
        },

        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});