define('tm3/pods/me/notification-settings/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        refreshTime: { seconds: 180, display: 'Every 3 minutes' },

        refreshTimeOptions: [{ seconds: 30, display: 'Every 30 seconds' }, { seconds: 60, display: 'Every minute' }, { seconds: 180, display: 'Every 3 minutes' }, { seconds: 300, display: 'Every 5 minutes' }, { seconds: 600, display: 'Every 10 minutes' }, { seconds: 900, display: 'Every 15 minutes' }, { seconds: 1200, display: 'Every 20 minutes' }],

        areSubjectsAutoSaving: false,

        actions: {
            saveDigestGroupBy: function saveDigestGroupBy(value) {
                var _this = this;

                var settingsRecords = this.store.peekAll('user-setting');

                var record = settingsRecords.findBy('name', 'email_digest_group_by');
                if (Ember.isEmpty(record)) {
                    record = this.store.createRecord('user-setting', {
                        name: 'email_digest_group_by',
                        group_name: 'email_settings',
                        user: this.get('user')
                    });
                }

                if (value !== record.get('value')) {
                    record.set('value', value);
                    record.save().then(function () {
                        return _this.get('notify').success('Email Digest Group by settings updated successfully');
                    });
                }
            },
            saveEmailFrequency: function saveEmailFrequency(value) {
                var _this2 = this;

                var settingsRecords = this.store.peekAll('user-setting');

                var emailFrequencyRecord = settingsRecords.findBy('name', 'email_frequency');

                if (value !== emailFrequencyRecord.get('value')) {
                    emailFrequencyRecord.set('value', value);
                    emailFrequencyRecord.save().then(function () {
                        return _this2.get('notify').success('Email frequency settings updated successfully');
                    });
                }
            },
            changeEmailFrequencyInterval: function changeEmailFrequencyInterval(newValue) {
                var _this3 = this;

                newValue = newValue || 8;
                var emailFrequencyInterval = newValue * 60;
                var settingsRecords = this.store.peekAll('user-setting');
                var emailFrequencyRecord = settingsRecords.findBy('name', 'email_frequency_interval');

                if (emailFrequencyInterval !== emailFrequencyRecord.get('value')) {
                    emailFrequencyRecord.set('value', emailFrequencyInterval);
                    this.set('emailFrequencyInterval', newValue);
                    emailFrequencyRecord.save().then(function () {
                        return _this3.get('notify').success('Email frequency interval updated successfully');
                    });
                }
            },
            changeDeleteDays: function changeDeleteDays(newValue) {
                var _this4 = this;

                newValue = newValue || 30;

                var settingsRecords = this.store.peekAll('user-setting');
                var notificationsKeepBeforeDeleteRecord = settingsRecords.findBy('name', 'notifications_keep_before_delete');
                if (Ember.isEmpty(notificationsKeepBeforeDeleteRecord)) {
                    notificationsKeepBeforeDeleteRecord = this.store.createRecord('user-setting', {
                        name: 'notifications_keep_before_delete',
                        group_name: 'email_settings',
                        user: this.get('user')
                    });
                }
                if (newValue !== notificationsKeepBeforeDeleteRecord.get('value')) {
                    notificationsKeepBeforeDeleteRecord.set('value', newValue);
                    this.set('notificationsKeepBeforeDelete', newValue);
                    notificationsKeepBeforeDeleteRecord.save().then(function () {
                        return _this4.get('notify').success('Keep before delete period updated successfully');
                    });
                }
            },
            changeSubjectRefresh: function changeSubjectRefresh(newValue) {
                var _this5 = this;

                newValue = newValue || { seconds: 180, display: 'Every 3 minutes' };

                var settingsRecords = this.store.peekAll('user-setting');
                var record = settingsRecords.findBy('name', 'subject_refresh_time');
                if (Ember.isEmpty(record)) {
                    record = this.store.createRecord('user-setting', {
                        name: 'subject_refresh_time',
                        group_name: 'user_settings',
                        user: this.get('user')
                    });
                }
                if (newValue.seconds !== record.get('value')) {
                    record.set('value', newValue.seconds);
                    this.set('refreshTime', newValue);
                    record.save().then(function () {
                        return _this5.get('notify').success('Subject refresh time updated successfully');
                    });
                }
            },
            changeSkipOwnNotifications: function changeSkipOwnNotifications(x, y, newValue) {
                var _this6 = this;

                var settingsRecords = this.store.peekAll('user-setting');
                var skipNotificationsRecord = settingsRecords.findBy('name', 'skip_own_notifications');
                if (Ember.isEmpty(skipNotificationsRecord)) {
                    skipNotificationsRecord = this.store.createRecord('user-setting', {
                        name: 'skip_own_notifications',
                        group_name: 'email_settings',
                        user: this.get('user')
                    });
                }
                skipNotificationsRecord.set('value', newValue ? 'true' : 'false');
                skipNotificationsRecord.save().then(function () {
                    return _this6.get('notify').success('Skip my own notifications updated successfully');
                });
            },
            changeAreSubjectsAutoSaving: function changeAreSubjectsAutoSaving(x, y, newValue) {
                var _this7 = this;

                var settingsRecords = this.store.peekAll('user-setting');
                var subjectsAutosaveRecord = settingsRecords.findBy('name', 'subjects_autosave');
                if (Ember.isEmpty(subjectsAutosaveRecord)) {
                    subjectsAutosaveRecord = this.store.createRecord('user-setting', {
                        name: 'subjects_autosave',
                        group_name: 'user_settings',
                        user: this.get('user')
                    });
                }
                subjectsAutosaveRecord.set('value', newValue ? 'true' : 'false');
                subjectsAutosaveRecord.save().then(function () {
                    return _this7.get('notify').success('Autosave subjects preference updated successfully');
                });
            }
        }
    });
});