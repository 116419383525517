define('tm3/pods/admin/dbs/form/route', ['exports', 'tm3/config/environment', 'ember-changeset'], function (exports, _environment, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return Ember.RSVP.hash({
                db: params.id === 'new' ? this.store.createRecord('db') : this.store.queryRecord('db', {
                    id: params.id
                }),
                dbTags: this.store.query('db-has-tag', {
                    db_id: params.id,
                    with: 'db_tags'
                }),
                specialDbTags: this.store.query('db-tag', {
                    name: ['Not Online', 'Not Public', 'Subpoena/Release Required', 'Fixed Cost']
                }),
                regularTags: this.store.query('db-tag', {
                    parent_id: 'NULL'
                }),
                countries: this.store.query('db-tag', {
                    parent_id: _environment.default.APP.tags.countryCategory
                }),
                states: this.store.query('db-tag', {
                    parent_id: _environment.default.APP.tags.stateCategory
                }),
                files: this.store.query('db-has-file', {
                    db_id: params.id,
                    with: 'files'
                })
            });
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'admin' });
        },
        setupController: function setupController(controller, resolved) {
            controller.set('files', resolved.files.sortBy('file.name'));

            controller.set('regularTags', resolved.regularTags.reject(function (tag) {
                return tag.get('name') === 'Not Online' || tag.get('name') === 'Not Public' || tag.get('name') === 'Subpoena/Release Required' || tag.get('name') === 'Countries';
            }));

            // reset when editing another db
            controller.set('selectedTags', null);
            controller.set('selectedPrimary', null);
            controller.set('selectedSecondary', null);
            controller.set('selectedCountry', null);
            controller.set('selectedState', null);
            controller.set('selectedCounty', null);

            controller.set('dbChangeset', new _emberChangeset.default(resolved.db));

            this._super(controller, resolved);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});