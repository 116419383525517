define('tm3/pods/components/report/payment-report/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            calculateTotal: function calculateTotal(field) {
                var items = this.get('tableData');
                return items.reduce(function (acc, item) {
                    if (item.hasOwnProperty(field)) {
                        return acc + parseFloat(item[field]);
                    } else {
                        return acc;
                    }
                }, 0);
            }
        }
    });
});