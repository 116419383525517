define('tm3/pods/matter/admin/sync-tm-data/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        /**
         * check if this function is available for the current model.matter
         * @public
         */
        isAvailable: Ember.computed.alias('model.matter.isTM'),

        /**
         * @property
         *
         * to show hidden or '' the button
         */
        visibility: '',

        actions: {
            /**
             * set the right url for the iframe to be executed,
             * It wasn't done with ajax, b/c of a failing CORS policy
             */
            sync: function sync() {
                var tmNumber = this.get('model.matter.tmNum');
                this.set('visibility', 'hidden');
                this.set('syncURL', 'https://admin.jurorsmarts.com/cron/run_by_name/tm_sync/matter_sync/' + tmNumber + '---' + tmNumber);
            }
        }
    });
});