define('tm3/pods/components/component-templates-list/table-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['box-header', 'with-border'],
        newItemAction: Ember.computed.reads('titleParams.newItemAction')
    });
});