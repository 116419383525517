define('tm3/pods/crm/contacts/info/accounts/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),

        associatedFirm: undefined,

        actions: {
            // connection related logic
            hideConnectForm: function hideConnectForm() {
                Ember.$('#connectForm').addClass('hidden');
            },
            showConnectForm: function showConnectForm() {
                Ember.$('#connectForm').removeClass('hidden');
            },
            newAccountConnection: function newAccountConnection() {
                var self = this;
                var contact = this.get('contact');
                var associatedFirm = this.get('associatedFirm');
                var firmRelationship = this.get('firmRelationship.value');

                if (associatedFirm.get('id') !== '' && associatedFirm.get('name') !== '') {
                    var firmPromise = this.store.findRecord('firm', associatedFirm.get('id'));
                    var userPromise = this.store.findRecord('user', contact.get('id'));

                    Ember.RSVP.all([firmPromise, userPromise]).then(function (array) {
                        var firm = array[0];
                        var user = array[1];

                        var fhc = self.store.createRecord('firm-has-client', {
                            firm: firm,
                            user: user,
                            relationship: firmRelationship
                        });

                        fhc.save().then(function (data) {
                            self.set('associatedFirm', undefined);
                            self.send('hideConnectForm');
                            self.get('notify').success('New account has been associated with this contact');
                            // self.send('reloadModel');
                            self.send('refreshInfoRoute');
                        }, function (reason) {
                            self.validationReport(fhc);
                        });
                    });
                }
            },

            /** power-select for clients */
            searchAccount: function searchAccount(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchAccount, term, resolve, reject, 600);
                });
            }
        },
        _performSearchAccount: function _performSearchAccount(term, resolve, reject) {
            return this.store.query('firm', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});