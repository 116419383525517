define('tm3/services/ajax', ['exports', 'ember-ajax/services/ajax', 'tm3/config/environment'], function (exports, _ajax, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ajax.default.extend({
        host: _environment.default.APP.restDestination,
        namespace: _environment.default.APP.restNameSpace,

        session: Ember.inject.service(),
        headers: Ember.computed('session.data.authenticated.token', {
            get: function get() {
                var headers = {};
                var token = this.get('session.data.authenticated.token');
                if (token) {
                    headers['X-Authorization'] = 'Token: ' + token;
                }
                if (_environment.default.environment === 'test') {
                    headers['X-CI-KEY'] = _environment.default.cikey;
                }
                return headers;
            }
        })
    });
});