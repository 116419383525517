define('tm3/helpers/remove-links', ['exports', 'tm-common/helpers/remove-links'], function (exports, _removeLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _removeLinks.default;
    }
  });
  Object.defineProperty(exports, 'removeLinks', {
    enumerable: true,
    get: function () {
      return _removeLinks.removeLinks;
    }
  });
});