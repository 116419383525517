define('tm3/pods/components/billables/edit/expense-entry/component', ['exports', 'ember-lifeline/mixins/run', 'tm3/pods/components/billables/edit/expense-entry/template'], function (exports, _run, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        tagName: 'tr',
        layout: _template.default,
        showCut: false,
        lockRate: false,
        rateIsDisabled: false,

        reimbursementStatuses: [Ember.Object.create({
            display: 'Company Credit Card',
            value: 'Company Card'
        }), Ember.Object.create({ display: 'Company Check', value: 'Company Check' }), Ember.Object.create({
            display: 'Company Paid - A/P',
            value: 'Company Paid'
        }), Ember.Object.create({ display: 'Employee', value: 'Employee' })],

        isLocked: Ember.computed('expense.reimbursementStatus', 'expense.isNew', 'isCaseOwner', function () {
            if (this.get('expense.isNew')) {
                return false;
            }
            if (this.get('expense.reimbursementStatus') === 'Employee Reimbursed') {
                return true;
            }

            return false;
        }),

        isLockedDescription: Ember.computed('expense.reimbursementStatus', 'expense.isNew', 'isCaseOwner', function () {
            if (this.get('expense.isNew')) {
                return false;
            }
            if (this.get('isCaseOwner')) {
                return false;
            }
            if (this.get('expense.reimbursementStatus') === 'Employee Reimbursed') {
                return true;
            }

            return false;
        }),

        qtyChanged: Ember.observer('expense.qty', function () {
            this.debounceTask('updateField', 'qty', 500);
        }),
        rateChanged: Ember.observer('expense.rate', function () {
            this.debounceTask('updateField', 'rate', 500);
        }),

        billTypeObserver: Ember.on('init', Ember.observer('expense.billableType', 'lockRate', function () {
            if (this.get('lockRate')) {
                var rate = this.get('expense.billableType.rate');
                if (rate) {
                    if (rate != this.get('expense.rate')) {
                        // we check equality because rate is string and expense.rate is number
                        this.set('expense.rate', rate);
                    }
                    this.set('rateIsDisabled', true);
                } else {
                    this.set('rateIsDisabled', false);
                }
            }
        })),

        updateField: function updateField(fieldName) {
            var value = this.get('expense.' + fieldName);
            if (value) {
                value = this.get('expense.' + fieldName).toString();
                value = value.replace(/[^\d.]/g, '');
                value = value.match(/^\d+(\.\d+)?/);
                if (value && value.length > 0) {
                    this.set('expense.' + fieldName, parseFloat(value[0]));
                }
            }
        },


        total: Ember.computed('expense.rate', 'expense.qty', function () {
            var rate = this.get('expense.rate');
            var qty = this.get('expense.qty');
            if (rate && qty) {
                return rate * qty;
            }
        }),
        actions: {
            removeExpense: function removeExpense(item) {
                this.sendAction('removeExpense', item);
            }
        }
    });
});