define('tm3/pods/conflicts/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Edit Case'],

        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.reset();
            controller.selectedTablesObserver();
        },


        actions: {
            didTransition: function didTransition() {
                var self = this;
                Ember.run.schedule('afterRender', self, function () {
                    Ember.$('.conflicts-name').focus();
                });
            }
        }
    });
});