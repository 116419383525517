define('tm3/pods/components/admin/teams/team-form/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        team: null,

        actions: {
            save: function save() {
                var _this = this;

                var team = this.get('team');
                var isNew = team.get('isNew');
                team.save().then(function () {
                    _this.$().closest('.modal').modal('hide');
                    _this.get('notify').success('Team has been saved');
                    if (isNew) {
                        _this.sendAction('refresh');
                    }
                }, function () {
                    _this.validationReport(team);
                });
            },


            /** power-select for client */
            searchMatter: function searchMatter(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchMatters, term, resolve, reject, 600);
                });
            }
        },

        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            var params = {
                'name||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            };

            return this.get('store').query('matter', params).then(function (items) {
                return resolve(items);
            });
        }
    });
});