define('tm3/pods/matter/smm/detail/route', ['exports', 'javascript-state-machine'], function (exports, _javascriptStateMachine) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                subject: this.store.findRecord('subject', params.subject_id),
                currentScrapeId: params.scrapeId,
                fileTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                scrapes: this.get('store').query('scrape', {
                    subject_id: params.subject_id,
                    matter_id: this.get('currentMatter.id'),
                    with: 'hyperlinks,users,subjects,documents,resourceHasTags,posts'
                })
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('subject', model.subject);
            if (model.currentScrapeId) {
                controller.set('currentScrapeType', model.scrapes.findBy('id', model.currentScrapeId).get('job'));
            } else {
                controller.set('currentScrapeType', model.scrapes.get('firstObject.job'));
                controller.set('scrapeId', model.scrapes.get('firstObject.id'));
            }
            controller.set('docTypes', model.fileTypes.sortBy('weight'));

            controller.set('fsm', new _javascriptStateMachine.default({
                init: 'New',
                transitions: [{ name: 'New', from: ['Pending', 'Completed'], to: 'New' }, { name: 'Pending', from: ['New', 'Completed'], to: 'Pending' }, { name: 'Completed', from: ['New', 'Pending'], to: 'Completed' }, {
                    name: 'New (previously failed)',
                    from: ['Failed'],
                    to: 'New (previously failed)'
                },
                // { name: 'Canceled', from: ['New', 'Pending'], to: 'Canceled' },
                // { name: 'Failed', from: 'Pending', to: 'Failed' },
                {
                    name: 'goto',
                    from: '*',
                    to: function to(s) {
                        return s;
                    }
                }]
            }));

            this._super(controller, model.scrapes);
        },


        actions: {
            reloadModel: function reloadModel() {
                this.refresh();
            }
        }
    });
});