define('tm3/pods/accounting/billing/subscription-costs/edit/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        employees: Ember.computed.reads('model.employees'),
        subscription: Ember.computed.alias('model.model'),

        actions: {
            save: function save(subscription) {
                var _this = this;

                subscription.save().then(function (result) {
                    _this.get('notify').success('Updated Successfully');
                    _this.transitionToRoute('accounting.billing.subscription-costs.info', _this.get('subscription.id'));
                }).catch(function (_err) {
                    return _this.validationReport(subscription);
                });
            }
        }
    });
});