define('tm3/pods/crm/contacts/edit/client-urls/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        selectedUrlType: null,
        address: null,
        showForm: false,
        actions: {
            closeForm: function closeForm() {
                this.set('selectedUrlType', null);
                this.set('address', null);
                this.toggleProperty('showForm');
            },


            /**
             * save a new url for a given user
             */
            save: function save() {
                var _this = this;

                var userURL = this.store.createRecord('user-url', {
                    user: this.get('model'),
                    address: this.get('address'),
                    type: this.get('selectedUrlType.value')
                });

                userURL.save().then(function (data) {
                    _this.set('selectedUrlType', null);
                    _this.set('address', null);
                    _this.send('closeForm');
                    _this.get('notify').success('URL Created');
                }, function (reason) {
                    _this.validationReport(userURL);
                    userURL.destroyRecord();
                });
            },


            /**
             * remove existing url number
             * @param item
             */
            delete: function _delete(item) {
                var self = this;
                item.destroyRecord().then(function (data) {
                    self.get('notify').success('Removed URL');
                }, function (reason) {
                    self.validationReport(item);
                });
            }
        }
    });
});