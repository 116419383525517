define('tm3/mixins/report-info-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ['quickSearchField', 'q', 'perPage', 'page', 'tableName'],

        q: '',
        quickSearch: '',
        perPage: '',
        page: '',

        // component name to use when rendering the table
        tableName: 'report/default-table',
        reportStandardParams: { per_page: '300000', page: '1' },

        perPageChanged: Ember.observer('perPage', function () {
            this.set('page', 1);
        }),

        totalPages: Ember.computed('model.tableData.total_rows', 'perPage', function () {
            var totalRows = this.get('model.tableData.total_rows');
            var perPage = this.get('perPage');
            return Math.ceil(parseInt(totalRows) / parseInt(perPage));
        }),

        actions: {
            changePage: function changePage(newPage) {
                this.set('page', newPage);
            }
        }
    });
});