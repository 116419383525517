define('tm3/pods/matter/report-notes/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        model: function model() {
            var currentMatter = this.get('currentMatter');
            return Ember.RSVP.hash({
                events: this.store.query('timeline-event', {
                    matter_id: currentMatter.get('id'),
                    with: 'timeline_event_types'
                })
            }).then(function (hash) {
                var byType = hash.events.map(function (e) {
                    return e.get('timelineEventType.name');
                }).uniq();

                hash.types = byType;
                return hash;
            });
        }
    });
});