define('tm3/pods/components/crm/contacts/portal-access/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error2.default, {
        notify: Ember.inject.service(),
        portalAccessText: 'More Options',

        accessEnabled: Ember.computed.equal('client.status', 'Active'),
        accessDisabled: Ember.computed.equal('client.status', 'Inactive'),
        resetEnabled: Ember.computed.equal('client.status', 'Reset'),

        actions: {
            /* Methods to enable portal access to a user */
            confirmEnable: function confirmEnable() {
                Ember.$('#enableConfirmation').modal('toggle');
            },
            enablePortalAccess: function enablePortalAccess() {
                var _this = this;

                Ember.$('#enableConfirmation').modal('toggle');

                var client = this.get('client');
                client.set('status', 'Active');
                client.save().then(function () {
                    _this.get('notify').success('This user has been enabled to use the portal');
                }, function () {
                    _this.validationReport(client);
                });
            },


            /* Methods to send welcome email to a user */
            confirmWelcome: function confirmWelcome(app) {
                this.set('targetAppname', app);
                Ember.$('#welcomeConfirmation').modal('toggle');
            },
            sendWelcomePortalAccess: function sendWelcomePortalAccess() {
                var _this2 = this;

                Ember.$('#welcomeConfirmation').modal('toggle');

                var client = this.get('client');
                var user = this.get('user');
                var app = this.get('targetAppname');

                client.sendWelcomeEmail({ app: app }).then(function (c) {
                    client.setProperties({ status: c.clients.status });
                    user.setProperties({
                        lastWelcomeEmailSentAt: c.clients.last_welcome_email_sent_at,
                        lastWelcomeEmailSubject: c.clients.last_welcome_email_subject
                    });
                    _this2.get('notify').success('Activation alert sent to email address on file.');
                }, function (error) {
                    _this2.handleInvalidError(error);
                });
            },


            /* Methods to disable access to portal to a user */
            confirmDisable: function confirmDisable() {
                Ember.$('#disableConfirmation').modal('toggle');
            },
            disablePortalAccess: function disablePortalAccess() {
                var _this3 = this;

                Ember.$('#disableConfirmation').modal('toggle');

                var client = this.get('client');

                client.set('status', 'Inactive');

                client.save().then(function () {
                    _this3.get('notify').success('Portal Access removed from this user.');
                }, function (reason) {
                    _this3.validationReport(client);
                });
            },


            /* hit custom action to reset the password for a user's account */
            resetPassword: function resetPassword(app) {
                var _this4 = this;

                var client = this.get('client');
                client.reminder({ app: app }).then(function (c) {
                    client.setProperties({ status: c.clients.status });
                    _this4.get('notify').success('User password reset.  Activation alert sent to email address on file.');
                }, function (_error) {
                    _this4.simpleReport(client);
                });
            }
        }
    });
});