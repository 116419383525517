define('tm3/pods/auth/login/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        notify: Ember.inject.service(),

        isAuthenticating: false,

        successful: false,

        token: false,

        actions: {
            /**
             * handle login form authenticate request
             * TODO validate inputs
             */
            authenticate: function authenticate() {
                var _this = this;

                if (this.get('isAuthenticating')) {
                    return;
                }
                this.set('isAuthenticating', true);

                var _getProperties = this.getProperties('identification', 'password', 'otpToken', 'rememberDevice'),
                    identification = _getProperties.identification,
                    password = _getProperties.password,
                    otpToken = _getProperties.otpToken,
                    rememberDevice = _getProperties.rememberDevice;

                this.get('session').authenticate('authenticator:custom', identification, window.btoa(password), otpToken, rememberDevice).then(function (response) {
                    _this.set('successful', true);
                }).catch(function (reason) {
                    if (reason.verify_token) {
                        _this.get('notify').warning('Please enter your verification code send to your ' + reason.verify_token);
                        _this.set('token', true);
                    }
                }).finally(function () {
                    _this.set('isAuthenticating', false);
                });
            }
        }
    });
});