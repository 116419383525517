define('tm3/pods/report/info/combined-reimbursement/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        tableName: 'report/combined-reimbursement',
        queryParams: ['start', 'end'],

        start: '',
        end: '',

        reportCustomParams: Ember.computed('start', 'end', function () {
            return { start: this.get('start'), end: this.get('end') };
        }),

        actions: {
            clearStart: function clearStart() {
                this.set('start', '');
            },
            clearEnd: function clearEnd() {
                this.set('end', '');
            }
        }
    });
});