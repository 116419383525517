define('tm3/pods/accounting/billing/batches/in-progress/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),

        requireAnyPermission: ['Manage Invoices', 'See Invoices'],

        queryParams: {
            current: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                invoiceBatches: this.store.findAll('filtered-batch'),
                current: params.current
            });
        },
        setupController: function setupController(controller, model) {
            var uniqueBatches = [];
            var prelimStatuses = ['Preliminary', 'Level 1 Complete', 'Level 2 Complete'];
            model.invoiceBatches.forEach(function (batch, i) {
                if (!uniqueBatches.isAny('id', batch.get('id'))) {
                    var invoices = batch.get('invoices');
                    if (invoices.any(function (invoice) {
                        return prelimStatuses.indexOf(invoice.get('status')) !== -1;
                    })) {
                        uniqueBatches.push(batch);
                    }
                }
            });

            model.invoiceBatches = uniqueBatches.sort(function (first, second) {
                return (0, _moment.default)(second.get('createdOn')).diff((0, _moment.default)(first.get('createdOn')));
            });

            this._super(controller, model);
            if (model.current) {
                controller.set('activeInvoiceBatch', model.invoiceBatches.findBy('id', model.current));
            }
            this.set('controller.isChangingStatus', false);
        },
        batchBecameBilled: function batchBecameBilled(newStatus) {
            return newStatus === 'Billed' && this.get('controller.activeInvoices.length') - 1 === this.get('controller.finalPhaseBillableGroups.length');
        },


        actions: {
            approveToNextLevel: function approveToNextLevel(invoice) {
                var _this = this;

                var newStatus = null;
                switch (invoice.get('status')) {
                    case 'Preliminary':
                        newStatus = 'Level 1 Complete';
                        break;
                    case 'Level 1 Complete':
                        newStatus = 'Level 2 Complete';
                        break;
                    case 'Level 2 Complete':
                        newStatus = 'Billed';
                        break;
                }

                if (!invoice.get('availableTransitions').includes('to ' + newStatus)) {
                    this.get('notify').error('Invoice can not transition to the new status');
                    return;
                }

                if (this.get('controller.isChangingStatus')) {
                    return;
                }
                this.set('controller.isChangingStatus', true);

                var oldStatus = invoice.get('status');
                invoice.set('status', newStatus);
                invoice.change({ status: newStatus }).then(function () {
                    if (_this.batchBecameBilled(newStatus)) {
                        _this.get('notify').info('All Invoices have been finalized in this batch. The page will now reset.');
                        _this.set('controller.current', null);
                        _this.get('controller.activeInvoiceBatch').unloadRecord();
                        _this.refresh();
                    } else {
                        _this.get('notify').success('Invoice has changed succesfully');
                        _this.refresh();
                    }
                }, function (error) {
                    invoice.set('status', oldStatus);
                    if (error.errors.length) {
                        _this.get('notify').error(error.errors[0].detail.title);
                    } else {
                        _this.get('notify').error('Error occurred while updating invoice');
                    }
                    _this.refresh();
                });
            },
            transitionToInvoice: function transitionToInvoice(invoiceId) {
                this.transitionTo('accounting.billing.invoices.info', invoiceId);
            }
        }
    });
});