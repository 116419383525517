define('tm3/pods/crm/accounts/info/matters/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'firm-has-matter',
        controllerName: 'crm.accounts.info.matters',

        model: function model(params) {
            var infoModel = this.modelFor('crm.accounts.info');
            var firm = infoModel.firm;
            params['firm_id'] = firm.get('id');
            return this._super(params);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});