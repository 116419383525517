define('tm3/session-stores/application', ['exports', 'tm3/config/environment', 'ember-simple-auth/session-stores/adaptive', 'ember-simple-auth/session-stores/ephemeral'], function (exports, _environment, _adaptive, _ephemeral) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    // TODO: investigate why the env is testing and not test. Will the change affect the end to end testing ?
    var store = void 0;
    if (_environment.default.environment === 'testing') {
        store = _ephemeral.default.extend();
    } else {
        store = _adaptive.default.extend({ localStorageKey: 'ember_simple_auth:tm2-mgr' });
    }
    exports.default = store;
});