define('tm3/components/document-editor', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        notify: Ember.inject.service(),

        'data-test-component': 'document-editor',

        init: function init() {
            this._super.apply(this, arguments);

            this.set('documentTypes', []);
        },


        /**
         * @property
         *
         * the matter where the document is linked to
         */
        matter: undefined,

        /**
         * @property
         *
         * the document record we need to show/edit on this component
         */
        document: undefined,

        /**
         * @property
         *
         * if the document is being edited
         */
        isEditing: false,

        docTypeInitial: Ember.computed('document.docType', 'documentTypes.[]', function () {
            return this.get('documentTypes').findBy('value', this.get('document.docType'));
        }),

        actions: {
            edit: function edit() {
                this.set('isEditing', true);
            },
            save: function save() {
                var self = this;
                var document = this.get('document');

                document.set('matter', this.get('matter'));
                document.save().then(function () {
                    self.get('notify').success('Document has been saved');
                    self.send('cancel');
                }, function () {
                    self.validationReport(document);
                });
            },
            cancel: function cancel() {
                this.get('document').rollbackAttributes();
                this.set('isEditing', false);
            },
            changeType: function changeType(docType) {
                this.set('docTypeInitial', docType);
                this.set('document.docType', docType.get('value'));
            },
            delete: function _delete() {
                this.sendAction('delete');
            }
        }
    });
});