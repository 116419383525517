define('tm3/services/raven', ['exports', 'ember-cli-deploy-sentry/services/raven'], function (exports, _raven) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _raven.default.extend({
        unhandledPromiseErrorMessage: '',

        captureException: function captureException() /* error */{
            this._super.apply(this, arguments);
        },
        captureMessage: function captureMessage() /* message */{
            return this._super.apply(this, arguments);
        },
        enableGlobalErrorCatching: function enableGlobalErrorCatching() {
            return this._super.apply(this, arguments);
        },
        ignoreError: function ignoreError(error) {
            // Ember 2.X seems to not catch `TransitionAborted` errors caused by
            // regular redirects. We don't want these errors to show up in Sentry
            // so we have to filter them ourselfs.
            // Once this issue https://github.com/emberjs/ember.js/issues/12505 is
            // resolved we can remove this code.
            return error === undefined || error.name === 'TransitionAborted' || error.message === 'The adapter operation was aborted' || error.message === 'The adapter rejected the commit because it was invalid';
            // but ignore network disconnects/failures
        },
        callRaven: function callRaven() /* methodName, ...optional */{
            return this._super.apply(this, arguments);
        }
    });
});