define('tm3/pods/components/custom-table-pagination/matter-tags-cell/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        tagName: 'div',

        valueAsList: Ember.computed('value', function () {
            var value = this.get('value');
            return value ? value.split(',') : [];
        }),

        tagType: 'private',

        tagTypeClasses: Ember.computed('tagType', function () {
            // public - green ; private - gray
            var classes = '';
            switch (this.get('tagType')) {
                case 'public':
                    classes = 'badge-green';
                    break;
                case 'private':
                    classes = 'badge-gray';
                    break;
                default:
                    break;
            }
            return classes;
        })
    });
});