define('tm3/pods/accounting/billing/batches/in-progress/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['current', 'case', 'from', 'to'],
        current: null,
        case: null,
        from: null,
        to: null,

        activeInvoiceBatch: null,

        isProrating: false,

        isChangingStatus: false,

        activeInvoices: Ember.computed('activeInvoiceBatch.invoices', function () {
            var activeInvoiceBatch = this.get('activeInvoiceBatch');
            return activeInvoiceBatch ? activeInvoiceBatch.get('invoices') : [];
        }),

        preliminaryInvoices: Ember.computed('activeInvoices.@each.status', function () {
            var activeInvoices = this.get('activeInvoices');
            return activeInvoices.filterBy('status', 'Preliminary');
        }),

        level1Invoices: Ember.computed('activeInvoices.@each.status', function () {
            var activeInvoices = this.get('activeInvoices');
            return activeInvoices.filterBy('status', 'Level 1 Complete');
        }),

        level2Invoices: Ember.computed('activeInvoices.@each.status', function () {
            var activeInvoices = this.get('activeInvoices');
            return activeInvoices.filterBy('status', 'Level 2 Complete');
        }),

        finalPhaseBillableGroups: Ember.computed('activeInvoices.@each.status', function () {
            var activeInvoices = this.get('activeInvoices');
            return activeInvoices.filterBy('status', 'Billed');
        }),

        actions: {
            setActiveInvoiceBatch: function setActiveInvoiceBatch(invoiceBatch) {
                this.set('current', invoiceBatch.get('id'));
                this.set('activeInvoiceBatch', invoiceBatch);
            },
            showCaseBillables: function showCaseBillables() {
                this.set('showCaseBillables', true);
                this.set('showTravelBillables', false);
                this.set('caseBillActive', 'active');
                this.set('travBillActive', '');
            },
            showTravelBillables: function showTravelBillables() {
                this.set('showCaseBillables', false);
                this.set('showTravelBillables', true);
                this.set('caseBillActive', '');
                this.set('travBillActive', 'active');
            },
            viewChargesForBillableGroup: function viewChargesForBillableGroup(billableGroup) {
                this.set('currentTravelBillableGroup', billableGroup);
                this.set('activeTravelBillable', billableGroup);
            },
            clearFrom: function clearFrom() {
                this.set('from', '');
            },
            clearTo: function clearTo() {
                this.set('to', '');
            }
        }
    });
});