define('tm3/pods/components/table-pagination/slim-pager/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            changePage: function changePage(newPage) {
                this.attrs.changePage(newPage);
            }
        }
    });
});