define('tm3/pods/matter/detail/info/controller', ['exports', 'tm3/utils/report-tab-types'], function (exports, _reportTabTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showStopWorkForm: false,
        showStopWorkConfirm: false,
        notify: Ember.inject.service(),
        store: Ember.inject.service(),

        reportTabTypes: _reportTabTypes.reportTabTypes,

        actions: {
            sortAccount: function sortAccount(a, b) {
                var aP = a.get('primary') || false;
                var bP = b.get('primary') || false;

                // eslint-disable-next-line no-nested-ternary
                return aP === bP ? 0 : aP ? -1 : 1;
            },
            goToStopWorkConfirm: function goToStopWorkConfirm() {
                var model = this.get('model');

                if (model.get('stopWorkDate') && ['Hold', 'Postpone'].indexOf(model.get('status')) > -1) {
                    this.set('showStopWorkForm', false);
                    this.set('showStopWorkConfirm', true);
                } else {
                    this.get('notify').error('Select date and status before continuing');
                }
            },
            saveStopWork: function saveStopWork() {
                var _this = this;

                this.get('model').save().then(function () {
                    _this.set('showStopWorkForm', false);
                    _this.set('showStopWorkConfirm', false);
                    _this.get('notify').success('Stop work information saved');
                }, function (error) {
                    _this.get('notify').error(error.errors[0].detail.title);
                });
            },
            cancelStopWork: function cancelStopWork() {
                this.get('model').rollbackAttributes();
                this.set('showStopWorkForm', false);
                this.set('showStopWorkConfirm', false);
            },
            calculateRecognition: function calculateRecognition() {
                var _this2 = this;

                this.get('model').calculateRecognition().then(function (resp) {
                    if (resp.ok) {
                        _this2.get('store').unloadAll('matter-has-recognition');
                        _this2.get('notify').success('Calculated correctly');
                    } else {
                        _this2.get('notify').error('There was an error calculating the recognition');
                    }
                    _this2.send('refreshModel');
                }).catch(function (error) {
                    _this2.get('notify').error(error.errors[0].detail.title);
                });
            }
        }
    });
});