define('tm3/initializers/autosize-textarea', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() /* container, application */{
        /**
         * Adding this code allow any textarea to have the ability
         * to grow depending on the text being entered so everything
         * is visible
         */
        Ember.TextArea.reopen({
            didInsertElement: function didInsertElement() {
                // initialize autosize http://www.jacklmoore.com/autosize/
                autosize(this.$());
                // when the textarea is focused re-calculate heigh
                Ember.$(this.$()).focus(function () {
                    autosize.update(this);
                });
            }
        });
    }

    exports.default = {
        name: 'autosize-textarea',
        initialize: initialize
    };
});