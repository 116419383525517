define('tm3/pods/components/search-db/search-panel/component', ['exports', 'ember-lifeline/mixins/run'], function (exports, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        // Fed in properties:
        currentTab: 'general',
        states: [],
        countries: [],
        counties: [],
        generalTags: [],
        filteredTags: [],
        useLocation: false, // Not used

        state: null,
        country: null,
        tags: [], // tag ids

        canAddTag: false,
        loading: false,

        // Properties
        isAddingTag: false,
        newTagName: '',
        isCountySelected: false,

        filterByCreated: false,
        filterByUpdated: false,
        activityDays: 30,

        allCountiesOption: Ember.Object.create({ name: 'All counties' }),

        init: function init() {
            this._super.apply(this, arguments);
            // This is used to move the affix menu when scrolling
            // not needed when not using affix
            // this.runTask(function () {
            //   let $scrollSpy = Ember.$('body');
            //   $scrollSpy.scrollspy({
            //     target: '#affix-nav',
            //     offset: Ember.$('#db-list').offset().top
            //   });
            //   $scrollSpy.css('position', 'relative');
            //   let $affixNav = Ember.$('#affix-nav');
            //   $affixNav.affix({
            //     offset: Ember.$('#db-list').offset().top
            //   });
            // }, 1000);
        },


        countyOptions: Ember.computed('counties.[]', function () {
            var countyOptions = [];
            var counties = this.get('counties');
            if (counties.get('length')) {
                countyOptions.pushObject(this.get('allCountiesOption'));
                // counties.forEach((county) => {
                countyOptions.pushObjects(counties);
                // })
            }
            return countyOptions;
        }),

        /**
         *
         */
        selectedTags: Ember.computed('generalTags.@each.isSelected', {
            get: function get(key) {
                return this.get('generalTags').filterBy('isSelected');
            },
            set: function set(key, value) {
                return value;
            }
        }),

        /**
         *
         */
        stateSelection: Ember.computed('state', 'states', {
            get: function get(key) {
                return this.get('states').findBy('id', this.get('state'));
            },
            set: function set(key, value) {
                if (value) {
                    this.set('state', value.get('id'));
                } else {
                    this.set('state', null);
                }
                return value;
            }
        }),

        /**
         * build a list of available countries
         */
        countrySelection: Ember.computed('country', 'countries', {
            get: function get(key) {
                return this.get('countries').findBy('id', this.get('country'));
            },
            set: function set(key, value) {
                if (value) {
                    this.set('country', value.get('id'));
                } else {
                    this.set('country', null);
                }
                return value;
            }
        }),

        /**
         * build a list of counties included in the current search results
         */
        selectedCounties: Ember.computed('tags', 'counties', {
            get: function get(key) {
                var _this = this;

                return this.get('counties').filter(function (countyTag) {
                    return _this.get('tags').includes(countyTag.get('id'));
                });
            },
            set: function set(key, value) {
                return value;
            }
        }),

        callIsCountySelected: function callIsCountySelected() {
            if (this.get('selectedCounties.length')) {
                this.send('setIsCountySelected', true);
            } else {
                this.send('setIsCountySelected', false);
            }
        },


        countyObserver: Ember.observer('selectedCounties', function () {
            this.callIsCountySelected();
        }),

        didInsertElement: function didInsertElement() {
            this.callIsCountySelected();
        },


        /**
         * build a list of tags that are currently included in the search results
         */
        tagSelection: Ember.computed('stateSelection', 'countrySelection', 'selectedCounties.[]', 'useLocation', 'selectedTags', function () {
            // the tag selection is all tags selected
            var tagSelection = [];
            var stateSelection = this.get('stateSelection');
            if (stateSelection) {
                tagSelection.pushObject(stateSelection);
            }
            var selectedCounties = this.get('selectedCounties');
            if (selectedCounties) {
                tagSelection.pushObjects(selectedCounties);
            }
            var countrySelection = this.get('countrySelection');
            if (countrySelection) {
                tagSelection.pushObject(countrySelection);
            }
            this.get('selectedTags').forEach(function (t) {
                tagSelection.pushObject(t);
            });

            return tagSelection;
        }),

        actions: {
            setIsCountySelected: function setIsCountySelected(value) {
                this.sendAction('setIsCountySelected', value);
            },

            /**
             * Actions to save a tag
             *
             */
            saveTag: function saveTag() {
                var _this2 = this;

                var newTagName = this.get('newTagName');

                this.attrs.saveTag(newTagName).then(function (didWork) {
                    _this2.set('isAddingTag', !didWork); // i.e. if it worked leave the adding tag mode
                    if (didWork) {
                        _this2.set('newTagName', '');
                    }
                });
            },


            /**
             * Actions to handle changes on filters
             * @param tag
             */
            toggleTagInFilter: function toggleTagInFilter(tag) {
                this.attrs.tagSelectionChange(this.get('tagSelection'));
            },
            newStateSelection: function newStateSelection(state) {
                if (this.get('stateSelection') !== state) {
                    // if there is an actual stateSelection change...
                    // - reset the selectedCounties,
                    this.set('selectedCounties', []);
                    // - update the state one
                    this.set('stateSelection', state);
                    // - and let's roll
                    this.attrs.tagSelectionChange(this.get('tagSelection'));
                }

                if ('activeElement' in document) {
                    Ember.run.next(function () {
                        document.activeElement.blur();
                    });
                }
            },
            countyChanged: function countyChanged(selectedCounties) {
                var allIsSelected = selectedCounties.findBy('name', 'All counties');
                if (allIsSelected) {
                    this.set('selectedCounties', this.get('counties'));
                } else {
                    this.set('selectedCounties', selectedCounties);
                }
                this.send('newCountySelection');
            },
            newCountySelection: function newCountySelection() {
                this.attrs.tagSelectionChange(this.get('tagSelection'));
                if ('activeElement' in document) {
                    Ember.run.next(function () {
                        document.activeElement.blur();
                    });
                }
            },
            newCountrySelection: function newCountrySelection(country) {
                this.set('countrySelection', country);
                this.attrs.tagSelectionChange(this.get('tagSelection'));
            },
            useLocChange: function useLocChange(update) {
                this.attrs.useLocationChange(update);
            },


            /**
             * change the currentTab to a new value
             * reset search in the process
             *
             * @param newTab
             */
            changeTab: function changeTab(newTab) {
                this.set('currentTab', newTab);
                this.send('clearFilters');
            },


            /**
             * reset the filters to their default position
             */
            clearFilters: function clearFilters() {
                this.set('selectedTags', []);
                this.set('stateSelection', null);
                this.set('selectedCounties', []);
                this.set('countrySelection', null);
                this.set('filterByCreated', false);
                this.set('filterByUpdated', false);
                this.set('activityDays', 30);
                this.attrs.queryChange('');
                this.attrs.queryAllChange('');
                this.attrs.tagSelectionChange(this.get('tagSelection'));
            },
            searchDbs: function searchDbs() {
                this.attrs.queryAllChange(this.get('term'));
            },
            deselectAllCounties: function deselectAllCounties() {
                this.set('selectedCounties', []);
                this.send('newCountySelection');
            }
        }
    });
});