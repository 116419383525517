define('tm3/pods/components/travel-expenses/reimburse-cell/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        notify: Ember.inject.service(),
        actions: {
            markAsReimbursed: function markAsReimbursed(row) {
                var _this = this;

                row.get('content').reimburse().then(function () {
                    row.get('content').reload().then(function () {
                        _this.get('notify').success('Marked as reimbursed successfully');
                    });
                }).catch(function (e) {
                    _this.get('notify').error(e.errors[0].detail.title);
                }).finally(function () {
                    _this.set('isReimburseConfirming', null);
                });
            },
            markAsUnreimbursed: function markAsUnreimbursed(row) {
                var _this2 = this;

                row.get('content').undoReimbursement().then(function () {
                    row.get('content').reload().then(function () {
                        _this2.get('notify').success('Undo reimbursement was successful');
                    });
                }).catch(function (e) {
                    _this2.get('notify').error(e.errors[0].detail.title);
                }).finally(function () {
                    _this2.set('isUnfoReimbursementConfirming', null);
                });
            }
        }
    });
});