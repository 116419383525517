define('tm3/pods/accounting/billing/tax-rules/form/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        requiredPermissions: ['Manage Time & Expense'],

        renderTemplate: function renderTemplate() {
            this.render({ into: 'accounting.billing' });
        },
        model: function model(params) {
            return params.id === 'new' ? this.store.createRecord('tax-rule') : this.store.findRecord('tax-rule', params.id);
        },


        actions: {
            save: function save() {
                var _this = this;

                var rule = this.controller.get('taxRule');

                rule.save().then(function () {
                    _this.get('notify').success('Tax rule saved successfully');
                    _this.transitionTo('accounting.billing.tax-rules', {
                        queryParams: { refresh: Math.floor(Math.random() * (9000 - 1)) + 1 }
                    });
                }, function () {
                    _this.validationReport(rule);
                });
            }
        }
    });
});