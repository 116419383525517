define('tm3/pods/report/info/bonus-report/route', ['exports', 'tm3/mixins/report-info-route', 'moment'], function (exports, _reportInfoRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        lists: Ember.inject.service(),

        apiName: 'bonus_report',

        queryParams: {
            year: {
                refreshModel: false
            },
            month: {
                refreshModel: false
            },
            showStoredReport: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    year: params.year,
                    month: params.month,
                    show_stored: params.showStoredReport
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                years: this.getYears(),
                months: this.getMonths(),
                year: params.year,
                month: params.month,
                show_stored: params.showStoredReport
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('years', resolved.years);
            controller.set('months', resolved.months);

            if (Ember.isEmpty(resolved.year)) {
                controller.set('year', (0, _moment.default)().format('YYYY'));
            }
            if (Ember.isEmpty(resolved.month)) {
                controller.set('month', (0, _moment.default)().format('MM'));
            }
            controller.set('showStoredReport', resolved.show_stored);
        },
        getYears: function getYears() {
            var startDate = '01-01-2000';
            var nowNormalized = (0, _moment.default)().startOf('month');
            var startDateNormalized = (0, _moment.default)(startDate, 'DD-MM-YYYY').clone().startOf('month').add(1, 'M');
            var years = [];

            while (startDateNormalized.isBefore(nowNormalized)) {
                years.push(startDateNormalized.format('YYYY'));
                startDateNormalized.add(1, 'Y');
            }

            return years.reverse();
        },
        getMonths: function getMonths() {
            var monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

            return monthNumbers.map(function (number) {
                return Ember.Object.create({
                    display: (0, _moment.default)('2000-' + number + '-01', 'YYYY-M-DD').format('MMMM'),
                    value: '' + number,
                    sortValue: number
                });
            }).sortBy('sortValue');
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});