define('tm3/pods/admin/matter-type/info/configurations/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var matterType = this.modelFor('admin/matter-type/info').matterType;
            return Ember.RSVP.hash({
                matterType: matterType,
                includeRelationshipInDocName: matterType.getMatterTypeConfiguration({
                    config: 'include_relationship_in_document_name'
                }).then(function (response) {
                    return response.value;
                })
            });
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});