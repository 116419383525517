define('tm3/pods/components/file-download-url/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        session: Ember.inject.service(),

        showName: false,

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),
        /**
         * return the url to hit to download the file
         */
        downloadURL: Ember.computed('id', function () {
            var ENV = this.get('ENV');
            var download = ENV.APP.documentDownloadURL.replace('documents', 'files');
            return download + '/' + this.get('id') + '/download?token=' + this.get('session.data.authenticated.token');
        })
    });
});