define('tm3/pods/admin/teams/team/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),

        requiredPermissions: ['Manage Case Settings'],

        model: function model(params) {
            return this.store.findRecord('team', params.id);
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'admin' });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            this.get('lists').getListForGroup('teams_mode').then(function (modes) {
                controller.set('modes', modes);
            });

            controller.set('isEditing', false);
        }
    });
});