define('tm3/pods/matter/tasks/add-multiple/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        queryParams: {
            task_list_id: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var currentMatter = this.get('currentMatter');
            return Ember.RSVP.hash({
                taskList: params.task_list_id ? this.store.findRecord('task-list', params.task_list_id) : null,
                employees: this.store.query('employee', {
                    with_access_to_matter: this.get('currentMatter.id')
                }),
                matter: currentMatter.get('matter')
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('plainText', '');
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'matter' });
        }
    });
});