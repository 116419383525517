define('tm3/pods/components/sc-calendar/component', ['exports', 'ember-power-calendar/components/power-calendar', 'ember-power-calendar-utils', 'moment'], function (exports, _powerCalendar, _emberPowerCalendarUtils, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _powerCalendar.default.extend({
        classNames: ['sc-calendar'],

        view: 'month',

        showLegend: true,

        // For agenda view
        startDate: null,
        endDate: null,

        navComponent: 'sc-calendar/nav',
        daysComponent: Ember.computed('view', function () {
            switch (this.get('view')) {
                case 'month':
                    return 'sc-calendar/days';
                case 'week':
                    return 'sc-calendar/week';
                case 'agenda':
                    return 'sc-calendar/agenda';
            }
        }),

        didInsertElement: function didInsertElement() {
            if (this.attrs.initReference) {
                this.attrs.initReference(this);
            }
            this.get('changeCenterTask').perform(this.get('currentCenter'), this.get('publicAPI'));
        },

        _publicAPI: Ember.computed('selected', 'currentCenter', 'locale', 'powerCalendarService.locale', 'changeCenterTask.isRunning', 'publicActions', 'view', 'showLegend', 'fromDate', 'toDate', function () {
            return {
                uniqueId: Ember.guidFor(this),
                type: this.get('_calendarType'),
                selected: this.get('selected'),
                loading: this.get('changeCenterTask.isRunning'),
                center: this.get('currentCenter'),
                locale: this.get('locale') || this.get('powerCalendarService.locale'),
                actions: this.get('publicActions'),
                // added additional to the already done in parent component
                view: this.get('view'),
                showLegend: this.get('showLegend'),
                fromDate: this.get('fromDate'),
                toDate: this.get('toDate')
            };
        }),

        fromDate: Ember.computed('center', 'view', 'startDate', function () {
            var view = this.get('view');
            var date = this.get('center');
            var startDate = this.get('startDate');
            switch (view) {
                case 'month':
                    return (0, _moment.default)((0, _emberPowerCalendarUtils.startOf)(date, 'month')).format('YYYY-MM-DD');
                case 'week':
                    return (0, _moment.default)((0, _emberPowerCalendarUtils.startOf)(date, 'week')).format('YYYY-MM-DD');
                case 'agenda':
                    if (Ember.isEmpty(startDate)) {
                        startDate = (0, _moment.default)(date).format('YYYY-MM-DD');
                    }
                    return startDate;
            }
        }),

        toDate: Ember.computed('center', 'view', 'endDate', function () {
            var view = this.get('view');
            var date = this.get('center');
            var endDate = this.get('endDate');
            switch (view) {
                case 'month':
                    return (0, _moment.default)((0, _emberPowerCalendarUtils.endOf)(date, 'month')).format('YYYY-MM-DD');
                case 'week':
                    return (0, _moment.default)((0, _emberPowerCalendarUtils.endOf)(date, 'week')).format('YYYY-MM-DD');
                case 'agenda':
                    if (Ember.isEmpty(endDate)) {
                        endDate = (0, _moment.default)(date).format('YYYY-MM-DD');
                    }
                    return endDate;
            }
        }),

        publicActions: Ember.computed('onSelect', 'onCenterChange', function () {
            var _this = this;

            var actions = {};
            if (this.get('onSelect')) {
                actions.select = function () {
                    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                        args[_key] = arguments[_key];
                    }

                    return _this.send.apply(_this, ['select'].concat(args));
                };
            }
            if (this.get('onCenterChange')) {
                var changeCenter = function changeCenter(newCenter, calendar, e) {
                    return _this.get('changeCenterTask').perform(newCenter, calendar, e);
                };
                actions.changeCenter = changeCenter;
                actions.moveCenter = function (step, unit, calendar, e) {
                    var newCenter = (0, _emberPowerCalendarUtils.add)(_this.get('currentCenter'), step, unit);
                    _this.set('center', newCenter);
                    return changeCenter(newCenter, { fromDate: _this.get('fromDate'), toDate: _this.get('toDate') }, e);
                };
                // Added additional to the already done in parent component
                actions.changeView = function (newView, calendar, e) {
                    switch (newView) {
                        case 'month':
                        case 'week':
                        case 'agenda':
                            _this.set('view', newView);
                            changeCenter(calendar.center, { fromDate: _this.get('fromDate'), toDate: _this.get('toDate') }, e);
                            break;
                        default:
                            (false && !(true) && Ember.assert('Invalid view name for sc-calendar', true));

                    }
                };
                actions.setStart = function (newDate) {
                    return _this.set('startDate', newDate);
                };
                actions.setEnd = function (newDate) {
                    return _this.set('endDate', newDate);
                };
            }
            return actions;
        })
    });
});