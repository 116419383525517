define('tm3/mixins/edit-billable-group-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        notify: Ember.inject.service(),
        showUpload: false,
        actions: {
            /** ** upload events *****/
            beforeUpload: function beforeUpload(filesToUpload) {
                var self = this;
                filesToUpload.forEach(function (file) {
                    file.set('extra', { billable_group_id: self.get('model.billableGroup.id') });
                });
            },
            afterUpload: function afterUpload(response) {
                if (Ember.isPresent(response.receipts)) {
                    this.get('notify').success('Files were uploaded succcessfully');
                    this.set('showUpload', false);
                    this.send('fetch');
                } else {
                    this.get('notify').alert('There was a problem uploading the documents');
                }
            },
            cancelUpload: function cancelUpload() {
                this.set('showUpload', false);
            },
            errorUpload: function errorUpload(_error) {
                this.get('notify').alert('There was a problem uploading the documents');
            },
            addFiles: function addFiles() {
                this.set('showUpload', true);
            }
        }
    });
});