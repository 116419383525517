define('tm3/pods/report/info/billed-cases-by-month/route', ['exports', 'tm3/mixins/report-info-route', 'moment'], function (exports, _reportInfoRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'billed_cases_by_month',

        queryParams: {
            month: {
                refreshModel: true
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    month: params.month,
                    year: params.perspective
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                months: this.getMonths(),
                month: params.month
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('months', resolved.months);
            controller.set('month', resolved.month);
        },
        getMonths: function getMonths() {
            var startDate = '01-11-2004';
            var nowNormalized = (0, _moment.default)().startOf('month');
            var startDateNormalized = (0, _moment.default)(startDate, 'DD-MM-YYYY').clone().startOf('month').add(1, 'M');
            var months = [];

            while (startDateNormalized.isBefore(nowNormalized)) {
                months.push(startDateNormalized.format('MMMM YYYY'));
                startDateNormalized.add(1, 'M');
            }

            return months.reverse();
        }
    });
});