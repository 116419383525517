define('tm3/pods/crm/dash/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            var currentUser = this.get('session.data.authenticated.id');
            return Ember.RSVP.hash({
                todos: this.store.query('todo', {
                    sort: 'due_on',
                    archived: 0,
                    with: 'employees,clients',
                    assigned_to_id: currentUser
                }),
                notes: this.store.query('crm-note', { sort: '-created_on', comment: 0, limit: 7, with: 'all' })
            });
        },
        setupController: function setupController(controller, resolved) {
            // controller.set("model", resolved.todos);
            var selectOptions = [];
            controller.set('selectOptions', selectOptions);
            this._super(controller, resolved.todos);
            controller.set('notes', resolved.notes);
        }
    });
});