define('tm3/pods/accounting/billing/batches/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'invoice-batch',
        currentRoute: 'accounting.billing.batches.list',

        requireAnyPermission: ['Manage Invoices', 'See Invoices'],

        // the name of the current controller since I don't know how to auto detect
        controllerName: 'accounting.billing.batches.list',

        model: function model(params) {
            return {};
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {});
            this._super(controller, resolved);
        }
    });
});