define('tm3/validators/due-date', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = validateDueDate;
    function validateDueDate() /* options = {} */{
        return function (key, newValue, oldValue, changes, content) {
            var startOn = changes.startOn ? changes.startOn : content.get('startOn');

            if (newValue === null || newValue === undefined || newValue === '') {
                return true;
            }

            if (startOn) {
                return (0, _moment.default)(newValue).isAfter((0, _moment.default)(startOn)) ? true : 'Start date must be before due date';
            }

            return true;
        };
    }
});