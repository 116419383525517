define('tm3/pods/accounting/billing/subscription-costs/processes/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'prorate-subscription-cost-process',
        currentRoute: 'accounting.billing.subscription-cost.processes',

        queryParams: {
            refresh: {
                refreshModel: true
            }
        },

        requiredPermissions: ['Manage Invoices'],

        model: function model(params) {
            delete params.refresh;
            params.with = 'all';
            return this._super(params);
        },


        actions: {
            doNothing: function doNothing() {}
        }
    });
});