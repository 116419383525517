define('tm3/pods/crm/activity/info/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        notify: Ember.inject.service(),

        model: function model(params) {
            params.with = 'crm_note_has_clients,crm_note_has_deals,employees,crm_note_categories';
            return Ember.RSVP.hash({
                note: this.store.query('crm-note', params),
                comments: this.store.query('crm-note', { parent_id: params.id }),
                employees: this.store.query('employee', { status: 'Active', sort: 'last_name' }),
                noteCategories: this.store.findAll('crm-note-category')
            });
        },
        setupController: function setupController(controller, resolved) {
            var model = resolved.note.get('firstObject');
            this._super(controller, model);
            controller.set('employees', resolved.employees);
            controller.set('noteCategories', resolved.noteCategories);
        },


        actions: {
            // wipe the supplied record and go back to the mother ship
            delete: function _delete(model) {
                var self = this;
                var controller = this.controllerFor('crm.activity.list');
                model.destroyRecord().then(function () {
                    // clear from activity list
                    if (!Ember.isEmpty(controller.get('model'))) {
                        controller.get('model').content.removeObject(model);
                    }
                    self.get('notify').success('Successfully removed note');
                    self.transitionTo('crm.activity.list');
                }, function (reason) {
                    Ember.Logger.debug(reason);
                    self.get('notify').error('Could not delete record!');
                });
            }
        }
    });
});