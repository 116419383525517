define('tm3/pods/components/currency-input/component', ['exports', 'inputmask'], function (exports, _inputmask) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        initialValue: null,
        isDisabled: false,
        classNames: ['w-100'],

        didInsertElement: function didInsertElement() {
            var selector = this.$('.money-input');
            var self = this;
            selector.val(this.get('initialValue'));
            var im = new _inputmask.default('currency', {
                rightAlign: false,
                digits: 0,
                oncomplete: function oncomplete() {
                    var value = parseInt(this.value.slice(2).replace(/,/g, ''));
                    self.updateValue(value);
                }
            });
            im.mask(selector);
        }
    });
});