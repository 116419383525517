define('tm3/pods/admin/groups/sidebar-tab-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['nav', 'nav-pills', 'nav-stacked'],

        groups: Ember.computed('groups', function () {
            return this.get('groups');
        })
    });
});