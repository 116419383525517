define('tm3/components/ember-inline-editable', ['exports', 'ember-inline-edit/components/ember-inline-editable'], function (exports, _emberInlineEditable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberInlineEditable.default;
    }
  });
});