define('tm3/pods/matter/tasks/route', ['exports', 'tm3/config/environment', 'tm3/mixins/alert-users-route'], function (exports, _environment, _alertUsersRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_alertUsersRoute.default, {
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model(params) {
            var currentMatter = this.get('currentMatter');
            params['matter_id'] = currentMatter.get('id');
            return Ember.RSVP.hash({
                taskLists: this.store.query('task-list', {
                    matter_id: params['matter_id'],
                    with: 'tasks,notes,task_has_notes,task_has_events',
                    sort: 'created_on,id'
                }),
                fileTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                employees: this.store.query('employee', {
                    with_access_to_matter: params['matter_id']
                }),
                listTemplateGroups: this.store.query('list-template-group', {
                    with: 'all'
                }).then(function (lists) {
                    var matterTypeId = parseInt(currentMatter.get('matter.matterTypeId'), 10);
                    return lists.filter(function (list) {
                        return Ember.isEmpty(list.get('matterType.id')) || parseInt(list.get('matterType.content.id'), 10) === matterTypeId;
                    });
                }),
                states: this.store.query('db-tag', {
                    parent_id: _environment.default.APP.tags.stateCategory
                }),
                filterAssignedTo: params.filterAssignedTo,
                filterDueOn: params.filterDueOn,
                caseOwners: this.store.query('matter-has-user', {
                    matter_id: this.get('currentMatter.id'),
                    user_type: 'employee',
                    contact: 'Primary',
                    group_id: 28, // Case Owners
                    with: 'users'
                }),
                alertUsersContacts: this.contactsModelMixin(),
                alertUsersEmployees: this.employeesModelMixin()
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            var filterEmployees = resolved.employees.toArray();

            filterEmployees.unshiftObject(Ember.Object.create({
                id: 'unassigned',
                fullName: 'Unassigned'
            }));
            filterEmployees.unshiftObject(Ember.Object.create({
                id: 'all',
                fullName: 'Anyone'
            }));
            controller.set('filterEmployees', filterEmployees);
            controller.set('states', resolved.states);

            controller.set('filterAssignedToInitial', filterEmployees.findBy('id', resolved.filterAssignedTo));
            controller.set('filterDueOnInitial', controller.get('dueOnFilters').findBy('value', resolved.filterDueOn));
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});