define('tm3/helpers/is-orphan-matter-field', ['exports', 'tm-common/helpers/is-orphan-matter-field'], function (exports, _isOrphanMatterField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isOrphanMatterField.default;
    }
  });
  Object.defineProperty(exports, 'arrayByIndex', {
    enumerable: true,
    get: function () {
      return _isOrphanMatterField.arrayByIndex;
    }
  });
});