define('tm3/pods/admin/teams/team/members/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        requiredPermissions: ['Manage Case Settings'],
        model: function model(params) {
            var team = this.modelFor('admin.teams.team');
            return Ember.RSVP.hash({
                team: team,
                teamUsers: this.store.query('user-has-team', {
                    team_id: team.get('id'),
                    with: 'users'
                }),
                contactRelationships: this.get('lists').getListForGroup('case_contact_types')
            });
        },

        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});