define('tm3/pods/report/info/new-cases/route', ['exports', 'tm3/mixins/report-info-route', 'moment'], function (exports, _reportInfoRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        lists: Ember.inject.service(),
        ajax: Ember.inject.service(),

        apiName: 'new_cases_report',

        queryParams: {
            year: {
                refreshModel: true
            },
            state: {
                refreshModel: true
            },
            timeFrame: {
                refreshModel: false
            },
            recentMonths: {
                refreshModel: true
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    year: params.year,
                    state: params.state,
                    recentMonths: params.recentMonths,
                    timeFrame: params.timeFrame
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                years: this.getYears(),
                year: params.year,
                state: params.state,
                states: this.get('ajax').request('matters/states_from_year', {
                    data: {
                        year: params.year,
                        recentMonths: params.recentMonths
                    }
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('years', resolved.years);
            controller.set('year', resolved.year);
            controller.set('states', resolved.states.states);
            controller.set('state', resolved.state);
        },
        getYears: function getYears() {
            var startDate = '01-11-2004';
            var nowNormalized = (0, _moment.default)().startOf('month');
            var startDateNormalized = (0, _moment.default)(startDate, 'DD-MM-YYYY').clone().startOf('month').add(1, 'M');
            var months = [];

            while (startDateNormalized.isBefore(nowNormalized)) {
                months.push(startDateNormalized.format('YYYY'));
                startDateNormalized.add(1, 'Y');
            }
            months.push(startDateNormalized.format('YYYY'));

            return months.reverse();
        }
    });
});