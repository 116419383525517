define('tm3/mixins/matter-analytics-controller', ['exports', 'ember-changeset', 'ember-changeset-validations', 'tm3/validators/matter'], function (exports, _emberChangeset, _emberChangesetValidations, _matter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        matter: Ember.computed.alias('model.matter'),
        plaintiff: Ember.computed.alias('model.plaintiff'),
        decedent: Ember.computed.alias('model.decedent'),

        // set chageset and validation from matter
        setupChangeset: function setupChangeset() {
            var matter = this.get('matter');
            this.set('matterChangeset', new _emberChangeset.default(matter, (0, _emberChangesetValidations.default)(_matter.default), _matter.default));
        }
    });
});