define('tm3/serializers/receipt', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            if (typeof hash.resource_id !== 'undefined' && Ember.isPresent(hash.resource_id)) {
                hash.id = hash.resource_id;
            }

            return this._super(typeClass, hash, prop);
        }
    });
});