define('tm3/pods/components/accounting/billing/subscription-costs/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'form',
        attributeBindings: ['role'],
        role: 'form',

        // inputs
        employees: undefined,
        subscription: undefined,

        // internal stuff
        cost: Ember.computed('subscription.type', 'subscription.annual', 'subscription.monthly', {
            get: function get() {
                if (this.get('subscription.type') === 'Monthly') {
                    return this.get('subscription.monthly');
                } else {
                    return this.get('subscription.annual') || this.get('subscription.monthly') * 12;
                }
            },
            set: function set(key, value) {
                if (this.get('subscription.type') === 'Monthly') {
                    this.set('subscription.monthly', value);
                    this.set('subscription.annual', Math.round(value * 12));
                } else {
                    this.set('subscription.monthly', Math.round(value * 100 / 12) / 100);
                    this.set('subscription.annual', value);
                }
                return value;
            }
        }),
        monthNumbers: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    });
});