define('tm3/pods/report/info/expense-receipt/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['receipts', 'invoices', 'start', 'end'],

        receipts: 'all',
        invoices: 'in',
        start: null,
        end: null,

        reportCustomParams: Ember.computed('receipts', 'invoices', 'start', 'end', function () {
            return {
                receipts: this.get('receipts'),
                invoices: this.get('invoices'),
                start: this.get('start'),
                end: this.get('end')
            };
        }),

        actions: {
            clearStart: function clearStart() {
                this.set('start', '');
            },
            clearEnd: function clearEnd() {
                this.set('end', '');
            }
        }
    });
});