define('tm3/pods/report/info/credit-card-reconciliation/controller', ['exports', 'tm3/mixins/report-info-controller', 'moment'], function (exports, _reportInfoController, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['start', 'end', 'employee'],

        start: null,
        end: null,
        employee: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('start', (0, _moment.default)().startOf('month').subtract(1, 'month').format('YYYY-MM-28'));
            this.set('end', (0, _moment.default)().startOf('month').format('YYYY-MM-27'));
        },


        reportCustomParams: Ember.computed('start', 'end', 'employee', function () {
            return {
                start: this.get('start'),
                end: this.get('end'),
                employee_id: this.get('employee')
            };
        }),

        actions: {
            clearStart: function clearStart() {
                this.set('start', '');
            },
            clearEnd: function clearEnd() {
                this.set('end', '');
            },
            changeEmployee: function changeEmployee(newEmp) {
                this.set('selectedEmployee', newEmp);
                if (newEmp) this.set('employee', newEmp.get('id'));else this.set('employee', null);
            }
        }
    });
});