define('tm3/pods/admin/matter-type/info/rate-list/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),

        model: function model() {
            var matterType = this.modelFor('admin/matter-type/info').matterType;

            return Ember.RSVP.hash({
                matterType: matterType,
                employees: this.store.query('employee', {
                    sort: 'last_name',
                    status: 'Active'
                }),
                userHasMatterTypes: this.store.query('user-has-matter-type', {
                    matter_type_id: matterType.get('id'),
                    with: 'employees'
                })
            });
        },


        actions: {
            save: function save(obj) {
                var _this = this;

                var caseTypeRate = obj.caseTypeRate;
                var employee = obj.employee;

                var modelName = caseTypeRate.constructor.modelName;

                if (modelName === 'user-has-matter-type') {
                    caseTypeRate.save().then(function () {
                        _this.get('notify').success('Rate updated successfully');
                        _this.refresh();
                    }, function () {
                        _this.validationReport(caseTypeRate);
                    });
                } else {
                    this.store.findRecord('employee', employee.get('id')).then(function (user) {
                        var userHasMatterType = _this.store.createRecord('user-has-matter-type', {
                            rate: caseTypeRate.rate,
                            employee: user,
                            matterType: _this.get('controller.model.matterType')
                        });

                        userHasMatterType.save().then(function () {
                            if (!_this.get('isMassEditSave')) {
                                _this.get('notify').success('Rate added successfully');
                                _this.refresh();
                            }
                        }, function () {
                            _this.validationReport(userHasMatterType);
                        });
                    });
                }
            },


            /**
             * perform the save operation on multiple records
             */
            massSave: function massSave() {
                var _this2 = this;

                if (!this.get('controller.isSavingMassEdit')) {
                    var matterType = this.get('controller.model.matterType');
                    var massEditValue = this.get('controller.massEditValue');
                    if (!massEditValue) {
                        this.get('notify').error('Mass Edit rate must be populated');
                    } else if (massEditValue < 0 || massEditValue > 999) {
                        this.get('notify').error('Mass Edit rate must be between 0 and 999');
                    } else {
                        this.set('controller.isSavingMassEdit', true);
                        this.get('ajax').post('user_has_matter_types/mass_edit', {
                            data: {
                                rate: massEditValue,
                                matter_type_id: matterType.get('id')
                            }
                        }).then(function () {
                            _this2.get('notify').success('Rate added successfully');
                            _this2.set('controller.isSavingMassEdit', false);
                            _this2.set('controller.massEditValue', null);
                            _this2.set('controller.isMassEdit', false);
                            _this2.refresh();
                        }, function () {
                            _this2.set('controller.isSavingMassEdit', false);
                            _this2.get('notify').error('There was an error updating all employee rates for this matter type.');
                        });
                    }
                }
            }
        }
    });
});