define('tm3/pods/admin/phrases/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                current_user: this.store.findRecord('user', this.get('session.data.authenticated.id')),
                billing_phrases: this.store.query('phrase', {
                    with: 'phrase_case_type_group,phrase_case_type_group_matter_type',
                    phrase_type: 'billing',
                    soft_deleted: 0,
                    sort: 'billable_type_id,order,name,content'
                }),
                billable_types: this.store.query('billable-type', {
                    'time||database': 1,
                    active: 1,
                    sort: 'database,name'
                }),
                matter_types: this.store.query('matter-type', {
                    active: 1,
                    sort: 'name,case_category,prefix'
                }),
                case_type_groups: this.store.query('phrase-case-type-group', {
                    soft_deleted: 0
                })
            });
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});