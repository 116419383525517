define('tm3/pods/components/custom-table-pagination/number-cell/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: 'align-right'
    });
});