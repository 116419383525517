define('tm3/pods/accounting/billing/travel-expenses/info/route', ['exports', 'tm3/mixins/travel-expense-route'], function (exports, _travelExpenseRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_travelExpenseRoute.default, {
        afterSaveTravelExpenseRoute: 'accounting.billing.travel-expenses',
        checkIfBilled: false,
        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);
            if (isExiting) {
                this.controller.set('isEditing', false);
            }
        }
    });
});