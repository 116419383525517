define('tm3/pods/matter/billable-groups/info/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service(),
        permissions: Ember.inject.service(),
        notify: Ember.inject.service(),
        isLinked: false,
        isCaseOwner: false,

        expenses: Ember.computed('model.billableGroup.billables.@each.billType', function () {
            return this.get('model.billableGroup.billables').filter(function (billable) {
                switch (billable.get('billType')) {
                    case 'Time:Expense':
                    case 'Piece:Expense':
                    case 'Expense':
                        return true;
                }
                return false;
            });
        }),

        adminTimeExpense: Ember.computed(function () {
            return this.get('permissions').check(['Admin Time & Expense']);
        }).volatile(),
        seeTimeExpense: Ember.computed(function () {
            return this.get('permissions').check(['See Time & Expense']);
        }).volatile(),

        hasEditPermission: Ember.computed('adminTimeExpense', 'seeTimeExpense', 'model.billableGroup.createdBy', 'model.matterHasCurrentUser', function () {
            return this.get('seeTimeExpense') && (this.get('adminTimeExpense') || this.get('model.matterHasCurrentUser.isCaseOwner') || this.get('model.billableGroup.createdBy.id') === this.get('currentUser.user.id'));
        }),

        userIsManagerOwner: Ember.computed('model.matterHasCurrentUser', function () {
            return this.get('model.matterHasCurrentUser.isCaseOwner');
        }),

        billableTime: Ember.computed('model.billableGroup.billables.@each.billType', function () {
            return this.get('model.billableGroup.billables').filterBy('billType', 'Time').get('firstObject');
        }),

        billablePiece: Ember.computed('model.billableGroup.billables.@each.billType', function () {
            return this.get('model.billableGroup.billables').filterBy('billType', 'Piece').get('firstObject');
        }),

        isBillableTime: Ember.computed('billableTime', 'billablePiece', function () {
            return this.get('billableTime') !== undefined && this.get('billablePiece') === undefined;
        }),

        isBillablePiece: Ember.computed('billablePiece', function () {
            return this.get('billablePiece') !== undefined;
        }),

        actions: {
            saveExpenseDescription: function saveExpenseDescription(expense) {
                var _this = this;

                expense.save().then(function () {
                    _this.get('notify').success('Description saved');
                });
            }
        }
    });
});