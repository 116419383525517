define('tm3/pods/components/custom-table-pagination/accounting/billing/create-batch/table-content/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['ember-cli-table-content'],
        // tagName: '',

        showFilter: false,
        a: Ember.computed(function () {
            this.get('contentParams');
        }),
        checkAll: Ember.computed.reads('contentParams.checkAll'),

        actions: {
            checkAll: function checkAll() {
                var trueOrFalse = this.get('checkAllChecked');
                this.contentParams.checkAll(trueOrFalse);
            }
        }
    });
});