define('tm3/pods/components/subjects/subject-addr-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @property
     * @public
     *
     * to set the subject-addr model
     * and allow edit its properties
     */
    address: {},

    /**
     * @property
     * @public
     *
     * list of address type to show on editing
     */
    addressTypes: []
  });
});