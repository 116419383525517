define('tm3/pods/report/info/cut-time-entries/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'cut_time_entries',
        queryParams: {
            start: {
                refreshModel: false
            },
            end: {
                refreshModel: false
            },
            field: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params+
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    start: params.start,
                    end: params.end,
                    group: params.field
                }),
                field: params.field,
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);

            controller.set('cutTimeEntriesGrouped', Object.entries(resolved.tableData.table_data).map(function (item) {
                return item[1];
            }));

            controller.set('field', resolved.field);
            controller.set('fieldFromRequest', resolved.field);

            if (resolved.field) {
                var groupFieldsOptions = [{ display: 'Employee', value: 'employee' }, { display: 'Case', value: 'case' }];

                var selectedOption = groupFieldsOptions.find(function (option) {
                    return option['value'] === resolved.field;
                });
                this.set('selectedField', selectedOption);
            }
        },

        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});