define('tm3/pods/crm/side-bar/component', ['exports', 'tm3/mixins/sidebar-tree'], function (exports, _sidebarTree) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_sidebarTree.default, {
        tagName: 'aside',
        classNames: ['main-sidebar']
    });
});