define('tm3/pods/me/travel/edit/route', ['exports', 'tm3/mixins/travel-expense-route'], function (exports, _travelExpenseRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_travelExpenseRoute.default, {
        goBackOptions: {
            skip: 'me.travel.add'
        }
    });
});