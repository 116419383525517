define('tm3/mixins/report-info-route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_error.default, {
        reportCatalog: Ember.inject.service(),

        ajax: Ember.inject.service(),

        queryParams: {
            quickSearchField: {
                refreshModel: true
            },
            q: {
                refreshModel: true
            },
            perPage: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 50,
                    page: params.page ? params.page : 1
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1
            });
        },
        afterModel: function afterModel(model, transition) {
            if (Ember.typeOf(model.tableData) === 'string') {
                transition.abort();
                this.transitionTo('unauthorized');
            }
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('apiName', this.get('apiName'));
        },


        /**
        Makes a request to the API server.
        @method makeRequest
        @param {Object} data The request data
        @return {Ember.RSVP.Promise}
        @protected
        */
        makeRequest: function makeRequest(data) {
            return this.get('ajax').request('report_builder', { data: data }).catch(function (error) {
                // catch a 403 message
                return error.message;
            });
        }
    });
});