define('tm3/pods/crm/contacts/info/deals/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var clientsInfoModel = this.modelFor('crm.contacts.info');
            var client = clientsInfoModel.model.get('firstObject');
            var clientId = client.get('id');

            return Ember.RSVP.hash({
                dealHasClients: this.store.query('deal-has-client', {
                    client_id: clientId,
                    with: 'deals'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            var deals = [];

            resolved.dealHasClients.forEach(function (dealObj) {
                var dealId = dealObj.get('deal').get('id');
                var exists = deals.filter(function (item, index, self) {
                    var existId = item.get('id');
                    if (existId === dealId) {
                        return true;
                    }
                });

                var foo = exists.length;
                if (foo === 0) {
                    deals.pushObject(dealObj.get('deal'));
                }
            });

            controller.set('deals', deals);
            this._super(controller, []);
        }
    });
});