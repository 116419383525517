define('tm3/components/subject-profile/profile-data-row', ['exports', 'tm-common/components/subject-profile/profile-data-row'], function (exports, _profileDataRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _profileDataRow.default;
    }
  });
});