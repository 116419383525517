define("tm3/initializers/before-unload", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() /* application */{
        window.addEventListener('beforeunload', function (event) {
            if (($("button.btn-primary:contains('Save')").length > 0 || $("button.btn.save-cut-time:contains('Save')").length > 0) && event.target.activeElement.attributes.title.textContent != 'Download the document') {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    }

    exports.default = {
        initialize: initialize
    };
});