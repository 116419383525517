define('tm3/pods/components/case-management/global-add-case-to-group-form/component', ['exports', 'tm-common/mixins/crud/error', 'tm3/pods/components/case-management/global-add-case-to-group-form/template'], function (exports, _error, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        layout: _template.default,
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('selectedMatterHash', this.get('selectedMatters'));
        },


        actions: {
            searchCollections: function searchCollections(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchCollections, term, resolve, reject, 600);
                });
            },

            /** power-select for deal */
            searchMatters: function searchMatters(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchDeals, term, resolve, reject, 600);
                });
            },
            clearInput: function clearInput() {
                this.set('selectedMatterHash', []);
                this.set('selectedCollection', null);
            },
            saveNewCase: function saveNewCase(matterHash, collection) {
                var _this3 = this;

                if (!matterHash.length || !collection) {
                    this.get('notify').error('One collection and at least one case must be selected');
                }

                var data = {
                    matters: matterHash.mapBy('id')
                };
                collection.moveMattersToCollection(data).then(function () {
                    _this3.send('clearInput');
                    _this3.sendAction('afterSave');
                }, function (error) {
                    _this3.handleXHR(error);
                });
            },
            cancelSaveGroup: function cancelSaveGroup() {
                this.send('clearInput');
                this.sendAction('afterCancel');
            }
        },
        _performSearchCollections: function _performSearchCollections(term, resolve, reject) {
            return this.get('store').query('collection', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items.rejectBy('name', 'Global View'));
            });
        },
        _performSearchDeals: function _performSearchDeals(term, resolve, reject) {
            return this.get('store').query('matter', {
                'name||plaintiff||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});