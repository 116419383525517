define('tm3/helpers/nl-2br', ['exports', 'tm-common/helpers/nl-2br'], function (exports, _nl2br) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _nl2br.default;
    }
  });
  Object.defineProperty(exports, 'nl2br', {
    enumerable: true,
    get: function () {
      return _nl2br.nl2br;
    }
  });
});