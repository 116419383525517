define('tm3/pods/components/subjects-list/table-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),

        classNames: ['box-header', 'with-border'],

        showEditMultipleModal: false,

        matter: Ember.computed.reads('titleParams.matter'),
        selectedRows: Ember.computed.reads('titleParams.selectedRows')
    });
});