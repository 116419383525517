define('tm3/pods/components/matter/dashboard/client-documents/component', ['exports', 'moment', 'tm-common/mixins/crud/error', 'tm3/mixins/save-event'], function (exports, _moment, _error, _saveEvent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, _saveEvent.default, {
        title: 'Client Documents',
        route: 'matter.documents',
        linkText: 'View All Documents',
        store: Ember.inject.service(),
        lists: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        maxDocs: 10,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            this.set('documents', []);
            this.getDocuments();
        },


        matterId: Ember.computed('matterId', 'currentMatter.id', function () {
            return this.get('matterId') ? this.get('matterId') : this.get('currentMatter.id');
        }),

        getDocuments: function getDocuments() {
            var _this = this;

            this.get('store').query('document', {
                matter_id: this.get('matterId'),
                doc_type: ['REPORT', 'CLIENT', 'CLIENT_UPLOAD', 'COMPLAINT'],
                with: 'v_resource_has_resources,subjects,resource_has_tags,reports'
            }).then(function (data) {
                _this.set('documents', data);
                _this.get('documents.content').sort(function (a, b) {
                    return (0, _moment.default)(b._data.createdOn).diff((0, _moment.default)(a._data.createdOn));
                });
            });
        },


        actions: {
            markDocumentAsViewed: function markDocumentAsViewed(doc) {
                doc.set('hasBeenViewed', 1);
            }
        }
    });
});