define('tm3/pods/admin/employees/new/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tm-common/mixins/authorized-route-mixin'], function (exports, _authenticatedRouteMixin, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authorizedRouteMixin.default, {
        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        allowedGroups: ['System - Administrator', 'System - ROOT'],
        lists: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                roles: this.get('lists').getListForGroup('employee_role'),
                departments: this.get('lists').getListForGroup('employee_department'),
                newEmployee: this.store.createRecord('employee', {
                    status: 'Active',
                    includeInScheduling: 0
                })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('newEmployee', model.newEmployee);
        }
    });
});