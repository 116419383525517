define('tm3/pods/admin/task-list-templates/edit/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Tasks'],
        model: function model(params) {
            return Ember.RSVP.hash({
                matter_types: this.store.query('matter-type', { active: 1 }),
                list_template_group: params.id === 'new' ? this.store.createRecord('list-template-group') : this.store.peekRecord('list-template-group', params.id),
                creating: params.id === 'new'
            });
        }
    });
});