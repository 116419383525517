define('tm3/pods/components/accounting/billing/batches/info/print-invoices/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        service: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var service = invoiceSubs.findBy('type', 'Service');
            return service || 0;
        }),

        expense: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var expense = invoiceSubs.findBy('type', 'Expense');
            return expense || 0;
        }),

        discount: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var discount = invoiceSubs.findBy('type', 'Discount');
            return discount || 0;
        }),

        tax: Ember.computed('invoiceSummary.invoiceSubs', function () {
            var invoiceSubs = this.get('invoiceSummary.invoiceSubs');
            var tax = invoiceSubs.findBy('type', 'Tax');
            return tax || 0;
        }),

        /**
         * a series of observers to support toggle functionality
         */

        watchAllInvoice: Ember.observer('selectAllInvoices', function () {
            var selectAllInvoices = this.get('selectAllInvoices');
            if (selectAllInvoices) {
                this.set('includeInExport', true);
            } else {
                this.set('includeInExport', false);
            }
        }),

        watchReceiptToggle: Ember.observer('toggleReceipts', function () {
            var _this = this;

            var toggleReceipts = this.get('toggleReceipts');
            var invoiceSummaryFirms = this.get('invoiceSummary.modifiedFirms');
            var status = this.get('receiptsStatus');
            invoiceSummaryFirms.forEach(function (firm) {
                firm.get('modifiedClients').forEach(function (client) {
                    if (status) {
                        _this.set('receiptsStatus', toggleReceipts);
                        client.set('receipts', toggleReceipts);
                    } else {
                        _this.set('receiptsStatus', toggleReceipts);
                        client.set('receipts', toggleReceipts);
                    }
                });
            });
        }),

        watchTravelReceiptToggle: Ember.observer('toggleTravelReceipts', function () {
            var _this2 = this;

            var toggleTravelReceipts = this.get('toggleTravelReceipts');
            var invoiceSummaryFirms = this.get('invoiceSummary.modifiedFirms');
            var status = this.get('travelReceiptsStatus');
            invoiceSummaryFirms.forEach(function (firm) {
                firm.get('modifiedClients').forEach(function (client) {
                    if (status) {
                        _this2.set('travelReceiptsStatus', toggleTravelReceipts);
                        client.set('travelReceipts', toggleTravelReceipts);
                    } else {
                        _this2.set('travelReceiptsStatus', toggleTravelReceipts);
                        client.set('travelReceipts', toggleTravelReceipts);
                    }
                });
            });
        }),

        watchSummaryToggle: Ember.observer('toggleSummary', function () {
            var _this3 = this;

            var toggleSummary = this.get('toggleSummary');
            var invoiceSummaryFirms = this.get('invoiceSummary.modifiedFirms');
            var status = this.get('receiptsStatus');
            invoiceSummaryFirms.forEach(function (firm) {
                firm.get('modifiedClients').forEach(function (client) {
                    if (status) {
                        _this3.set('summaryAndDetailStatus', toggleSummary);
                        client.set('summaryAndDetail', toggleSummary);
                    } else {
                        _this3.set('summaryAndDetailStatus', toggleSummary);
                        client.set('summaryAndDetail', toggleSummary);
                    }
                });
            });
        }),

        watchExpenseToggle: Ember.observer('toggleExpenses', function () {
            var _this4 = this;

            var toggleExpenses = this.get('toggleExpenses');
            var invoiceSummaryFirms = this.get('invoiceSummary.modifiedFirms');
            var status = this.get('manualExpensesStatus');
            invoiceSummaryFirms.forEach(function (firm) {
                firm.get('modifiedClients').forEach(function (client) {
                    if (status) {
                        _this4.set('manualExpensesStatus', toggleExpenses);
                        client.set('manualExpenses', toggleExpenses);
                    } else {
                        _this4.set('manualExpensesStatus', toggleExpenses);
                        client.set('manualExpenses', toggleExpenses);
                    }
                });
            });
        }),

        watchRemittanceToggle: Ember.observer('toggleRemittance', function () {
            var _this5 = this;

            var toggleRemittance = this.get('toggleRemittance');
            var invoiceSummaryFirms = this.get('invoiceSummary.modifiedFirms');
            var status = this.get('manualExpensesStatus');
            invoiceSummaryFirms.forEach(function (firm) {
                firm.get('modifiedClients').forEach(function (client) {
                    if (status) {
                        _this5.set('checkAllRemittanceStatus', toggleRemittance);
                        client.set('remittance', toggleRemittance);
                    } else {
                        _this5.set('checkAllRemittanceStatus', toggleRemittance);
                        client.set('remittance', toggleRemittance);
                    }
                });
            });
        }),

        actions: {
            formattedPercentage: function formattedPercentage(invoiceOutput) {
                var billSplit = invoiceOutput.get('billSplit');
                return parseFloat(billSplit * 100).toFixed(2) + '%';
            }
        }
    });
});