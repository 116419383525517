define('tm3/pods/crm/full-search/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['terms', 'searchLimit', 'accounts', 'notes', 'contacts', 'opportunities', 'cases', 'todos'],
        terms: null,
        termsToSearch: null,
        searchLimit: 25,
        accounts: true,
        notes: true,
        contacts: true,
        opportunities: true,
        cases: true,
        todos: true,

        searchLimitOptions: [5, 10, 25, 50, 100],

        limit: 25,

        searchValue: '',

        actions: {
            updateSearch: function updateSearch() {
                this.set('terms', this.get('termsToSearch'));
            },
            showMoreOptions: function showMoreOptions() {
                Ember.$('#moreOptions').toggleClass('hidden');
            }
        }
    });
});