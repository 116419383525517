define('tm3/pods/accounting/billing/subscription-costs/edit/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Admin Time & Expense'],
        model: function model(params) {
            return Ember.RSVP.hash({
                model: this.store.findRecord('subscription-cost', params.id),
                employees: this.store.findAll('employee')
            });
        }
    });
});