define('tm3/pods/admin/employees/new/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),

        actions: {
            save: function save() {
                var _this = this;

                var newEmployee = this.get('newEmployee');

                newEmployee.save().then(function () {
                    _this.get('notify').success('Employee was created successfully');
                    _this.transitionToRoute('admin.employees.info.details', newEmployee.get('id'));
                }).catch(function () {
                    _this.validationReport(newEmployee);
                });
            },
            invalid: function invalid() {},
            cancel: function cancel() {
                this.get('newEmployee').destroyRecord();
                this.transitionToRoute('admin.employees.list');
            }
        }
    });
});