define('tm3/pods/matter/admin/visualizations/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        store: Ember.inject.service(),
        currentVisualization: null,

        actions: {
            createVisualization: function createVisualization() {
                var _this = this;

                var editingId = this.get('editing');
                var visualization = void 0;
                if (Ember.isEmpty(editingId)) {
                    visualization = this.get('store').createRecord('matter-has-visualization', {
                        matter: this.get('model.matter')
                    });
                } else {
                    visualization = this.get('store').peekRecord('matter-has-visualization', editingId);
                }
                visualization.set('name', this.get('newName'));
                visualization.set('iframeSrc', this.get('newIframeSrc'));
                visualization.set('viewable', this.get('newViewable'));

                visualization.save().then(function () {
                    _this.setProperties({
                        newName: null,
                        newIframeSrc: null,
                        newViewable: false,
                        editingId: null
                    });
                    _this.set('showNewForm', false);
                    _this.get('notify').success('Saved successfully');
                    _this.send('refresh');
                }).catch(function () {
                    _this.validationReport(visualization);
                });
            },
            editVisualization: function editVisualization(vis) {
                this.setProperties({
                    editing: vis.get('id'),
                    newName: vis.get('name'),
                    newIframeSrc: vis.get('iframeSrc'),
                    newViewable: vis.get('viewable')
                });
                this.set('showNewForm', true);
            },
            deleteVisualization: function deleteVisualization(vis) {
                var _this2 = this;

                if (!window.confirm('Are you sure you want to remove this item?')) {
                    return;
                }
                vis.destroyRecord().then(function () {
                    _this2.get('notify').success('Removed successfully');
                    _this2.send('refresh');
                }).catch(function () {
                    _this2.validationReport(vis);
                });
            },
            testVisualization: function testVisualization(vis) {
                this.set('currentVisualization', vis);
            }
        }
    });
});