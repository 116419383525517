define('tm3/pods/preview/document-events/route', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment'], function (exports, _error, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        subjectId: null,
        queryParams: {
            matterId: {
                refreshModel: false
            }
        },
        lists: Ember.inject.service(),
        model: function model(params, transition) {
            var _this = this;

            this.set('documentId', transition.params.preview.document_id);
            var documentPromise = this.store.queryRecord('document', {
                resource_id: this.get('documentId'),
                with: 'timeline_events'
            });
            return documentPromise.then(function (document) {
                return Ember.RSVP.hash({
                    documentId: _this.get('documentId'),
                    document: document,
                    subjectId: _this.get('subjectId'),
                    events: _this.store.query('timeline-event', {
                        resource_id: document.get('timelineEvents').map(function (e) {
                            return e.get('id');
                        }),
                        with: 'subjects'
                    }).then(function (events) {
                        return events.sortBy('sortableDate');
                    }),
                    eventTypes: _this.store.findAll('timeline-event-type').then(function (eventTypes) {
                        return eventTypes.sortBy('name');
                    }),
                    timelineEventParents: _this.store.findAll('timeline-event-parent'),
                    subjectsInCase: _this.store.query('subject', { matter_id: document.get('matter').content.id }),
                    matter: _this.store.findRecord('matter', document.get('matter').content.id),
                    emptyEvent: {},
                    consolidatedTags: _this.store.query('db-tag', {
                        parent_id: [_environment.default.APP.tags.stateCategory, _environment.default.APP.tags.countryCategory, 'NULL']
                    }),
                    documentTypes: _this.get('lists').getListForGroup('jury_doc_type_tm')
                }).then(function (hash) {
                    hash.states = hash.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.stateCategory);
                    hash.countries = hash.consolidatedTags.filterBy('parentId', _environment.default.APP.tags.countryCategory);

                    delete hash.consolidatedTags;

                    return hash;
                });
            });
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});