define('tm3/pods/me/travel/edit/controller', ['exports', 'tm3/mixins/travel-expense-controller'], function (exports, _travelExpenseController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_travelExpenseController.default, {
        application: Ember.inject.controller(),

        queryParams: ['billable_type'],
        matterId: null,
        billable_type: null
    });
});