define('tm3/pods/matter/uploads/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        currentStep: 1,
        log: [],
        result: [],
        isProcessing: false,

        selectedType: 'import_hyperlinks',

        actions: {
            cancelUpload: function cancelUpload() {
                this.transitionToRoute('matter.uploads');
            },
            beforeUpload: function beforeUpload(filesToUpload) {
                filesToUpload.setEach('extra', {
                    type_of_parser: this.get('selectedType'),
                    matter_id: this.get('currentMatter.id'),
                    field_value: this.get('selectedField.value')
                });
                this.set('isProcessing', true);
                this.set('log', []);
                this.set('result', []);
            },
            afterUpload: function afterUpload(response) {
                if (response.uploaded) {
                    this.get('notify').success('The file has been uploaded and processed please review the log');
                    this.set('log', ['File processed correctly']);
                    this.set('result', []);
                    this.set('currentStep', 2);
                    this.set('isProcessing', false);
                }
            },
            errorUpload: function errorUpload(error) {
                this.set('currentStep', 2);
                this.set('isProcessing', false);

                if (Ember.isPresent(error.responseJSON)) {
                    this.get('notify').error('Error uploading file: ' + error.responseJSON.errors[0].additional_info.title);
                    this.set('log', error.responseJSON.errors[0].additional_info.developer_message);
                } else {
                    this.get('notify').error('Error uploading file: ' + error.statusText);
                }
            }
        }
    });
});