define('tm3/pods/matter/billable-groups/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route', 'tm-common/mixins/crud/error'], function (exports, _route, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, _error.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        modelName: 'billable-group',
        currentRoute: 'matter.billable-groups.list',

        queryParams: {
            start: {
                refreshModel: true
            },
            end: {
                refreshModel: true
            },
            selectedEmployee: {
                refreshModel: true
            },
            status: {
                refreshModel: true
            },
            billType: {
                refreshModel: true
            },
            budget_id: {
                refreshModel: true
            }
        },

        model: function model(params) {
            delete params.status;
            delete params.end;
            delete params.selectedEmployee;
            delete params.status;
            delete params.billType;
            delete params.budget_id;
            return Ember.RSVP.hash({
                budgets: this.store.query('budget', {
                    matter_id: this.get('currentMatter.id')
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            var params = {};
            var selectedEmployee = controller.get('selectedEmployee');
            var start = controller.get('start');
            var end = controller.get('end');
            var selectedBillingStatus = controller.get('status');

            params['bg_type'] = controller.get('billType');
            params['budget_id'] = controller.get('budget_id');

            if (selectedEmployee) {
                params['created_by_id'] = selectedEmployee;
            }

            params['matter_id'] = this.get('currentMatter.id');

            if (start && end) {
                params['start'] = '~' + start + '~' + end;
            } else {
                if (start) {
                    params['start'] = '>=' + start;
                }
                if (end) {
                    params['start'] = '<=' + end;
                }
            }

            switch (selectedBillingStatus) {
                case 'All':
                    params['approval_status'] = null;
                    params['include_for_billing'] = null;
                    params['include_billable_cut'] = null;
                    break;
                case 'Billable - Not Invoiced':
                    params['approval_status'] = 'null';
                    params['include_for_billing'] = '1';
                    params['include_billable_cut'] = null;
                    break;
                case 'Non-Billable/Cut':
                    params['approval_status'] = null;
                    params['include_billable_cut'] = '1';
                    break;
                case 'Billable - Invoiced':
                    params['approval_status'] = 'Billed';
                    params['include_for_billing'] = '1';
                    params['include_billable_cut'] = null;
                    break;
                default:
                    break;
            }

            controller.set('loadDataParams', params);
            controller.set('budgets', resolved.budgets);
            this._super(controller, resolved);
        },
        validateToCreateTimePiece: function validateToCreateTimePiece() {
            if (this.controller.get('model.invoiceGroups.length') === 0) {
                this.get('notify').alert('This case requires a valid Invoice Group before you can create a Time & Expense entry');
                return false;
            }

            if (this.controller.get('model.budgets.length') === 0) {
                this.get('notify').alert('This case requires a valid Budget before you can create a Time & Expense entry');
                return false;
            }

            return true;
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            createTime: function createTime() {
                if (!this.validateToCreateTimePiece()) {
                    return;
                }
                this.transitionTo('matter.billable-groups.edit', 'new', {
                    queryParams: { billable_type: 'Time' }
                });
            },


            /**
             * Create a billable group of type 'Piece'
             * Create a billable of type time and one billable of type Piece
             * Redirect to edit that new billable-group
             */
            createPiece: function createPiece() {
                if (!this.validateToCreateTimePiece()) {
                    return;
                }
                this.transitionTo('matter.billable-groups.edit', 'new', {
                    queryParams: { billable_type: 'Piece' }
                });
            },
            removeBillableGroup: function removeBillableGroup(billableGroup) {
                this.controller.set('deleteItem', billableGroup);
            }
        }
    });
});