define('tm3/pods/matter/billable-groups/info/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        ajax: Ember.inject.service(),
        notify: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        parentController: Ember.computed(function () {
            return this.controllerFor('matter.billable-groups');
        }),

        model: function model(params) {
            this.get('parentController').set('openedBillableGroupId', params.billable_group_id);

            var matterHasCurrentUser = this.modelFor('matter').matterHasCurrentUser;
            return Ember.RSVP.hash({
                billableGroup: this.store.queryRecord('billable-group', {
                    id: params.billable_group_id,
                    with: 'billables,budgets,receipts,invoice_groups,charges,matters'
                }),
                billableTypes: this.store.findAll('billable-type'),
                matterHasCurrentUser: matterHasCurrentUser,
                invoiceGroups: this.store.query('invoice-group', {
                    matter_id: this.get('currentMatter.id')
                }),
                budgets: this.store.query('budget', {
                    matter_id: this.get('currentMatter.id'),
                    enabled: 1
                }),
                isLinkedToCase: this.get('ajax').request('auth/is_linked', {
                    method: 'GET',
                    accept: 'application/json',
                    data: {
                        user_id: this.get('currentUser.user.id'),
                        matter_id: this.get('currentMatter.id')
                    }
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            if (resolved) {
                resolved.billableGroup.get('billables').forEach(function (b) {
                    b.set('receipt', resolved.billableGroup.get('receipts').filterBy('billable.id', b.get('id')).get('firstObject'));
                });
                controller.set('isLinked', resolved.isLinkedToCase.is_linked);
                controller.set('isCaseOwner', resolved.isLinkedToCase.is_owner);
            }
            this._super(controller, resolved);
        }
    });
});