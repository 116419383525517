define('tm3/pods/components/accounting/billing/invoice-outputs/is-multiple-selected/component', ['exports', 'ember-lifeline/mixins/run'], function (exports, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        invoiceSummaries: null,
        multipleInvoicesAreSelected: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.pollTask('updateSelected', 'updating-selected#updateSelected');
        },
        updateSelected: function updateSelected(next) {
            var summaries = this.get('invoiceSummaries');
            if (summaries) {
                this.set('multipleInvoicesAreSelected', this.attrs.buildInvoicesArray(summaries).length > 1);
            } else {
                this.set('multipleInvoicesAreSelected', false);
            }
            if (!this.get('multipleInvoicesAreSelected')) {
                this.set('withBudgetsInCover', false);
            }
            this.runTask(next, 500);
        }
    });
});