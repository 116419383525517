define('tm3/pods/crm/accounts/info/deals/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var infoModel = this.modelFor('crm.accounts.info');
            var firmId = infoModel.firm.get('id');

            return Ember.RSVP.hash({
                firmHasClients: this.store.query('firm-has-client', {
                    firm_id: firmId,
                    with: 'users'
                }),
                deals: this.store.findAll('deal')
            });
        },
        setupController: function setupController(controller, resolved) {
            var self = this;
            var deals = [];

            resolved.firmHasClients.forEach(function (fhc) {
                var clientId = fhc.get('user').get('id');
                self.store.query('deal-has-client', {
                    client_id: clientId,
                    with: 'deals'
                }).then(function (dealArray) {
                    dealArray.forEach(function (dealObj) {
                        var dealId = dealObj.get('deal').get('id');
                        var exists = deals.filter(function (item, index, self) {
                            var existId = item.get('id');
                            if (existId === dealId) {
                                return true;
                            }
                        });

                        var foo = exists.length;
                        if (foo === 0) {
                            deals.pushObject(dealObj.get('deal'));
                        }
                    });
                });
            });

            controller.set('deals', deals);
            this._super(controller, []);
        }
    });
});