define('tm3/pods/components/crm/show-todo/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        notify: Ember.inject.service(),
        isEditing: false,

        // Added this field to not trigger the change of group on the list
        // of todos, just after saving only
        editingDueOn: null,
        editingPriority: null,

        /**
         * @computed
         * used as checkbox
         */
        editingIsHigh: Ember.computed('editingPriority', {
            get: function get(key) {
                return this.get('editingPriority') === 'High';
            },
            set: function set(key, value) {
                if (value) {
                    this.set('editingPriority', 'High');
                } else {
                    this.set('editingPriority', 'Normal');
                }
                return value;
            }
        }),

        actions: {
            edit: function edit() {
                this.set('editingDueOn', this.get('todo.dueOn'));
                this.set('editingPriority', this.get('todo.priority'));
                this.set('isEditing', true);
            },
            cancel: function cancel() {
                this.set('isEditing', false);
            },


            // send this action up to the controller
            deleteTodo: function deleteTodo(todo) {
                this.sendAction('delete', todo);
            },
            selectToggle: function selectToggle(todo) {
                this.sendAction('toggle', todo);
            },
            archiveTodo: function archiveTodo(todo) {
                this.sendAction('archive', todo);
            },
            save: function save(model) {
                var self = this;
                model.set('dueOn', this.get('editingDueOn'));
                model.set('priority', this.get('editingPriority'));
                model.save().then(function (data) {
                    self.get('notify').success('Todo saved');
                    if (!self.get('isDestroyed') && !self.get('isDestroying')) {
                        self.set('isEditing', false);
                    }
                }, function (reason) {
                    model.rollbackAttributes();
                    self.validationReport(model);
                });
            }
        }
    });
});