define('tm3/pods/components/admin/appl-lists-categories/list-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        editing: false,
        tagName: 'li',
        classNames: ['list-group-item'],

        actions: {
            updateItem: function updateItem(item) {
                if (item.get('display') === '' || item.get('value') === '') {
                    this.get('notify').error("You must enter a 'Display' and a 'Value'");
                } else {
                    this.attrs.updateApplListItem(item);
                }
            }
        }
    });
});