define('tm3/pods/components/light-table-pagination-subjects/component', ['exports', 'ember-cli-table-pagination/components/light-table-pagination'], function (exports, _lightTablePagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _lightTablePagination.default.extend({
        ltColumns: Ember.computed('tableActionsComponent', 'columns', function () {
            return [{
                fieldType: '',
                label: 'Actions',
                sortable: false,
                width: '115px',
                cellComponent: this.get('tableActionsComponent'),
                type: 'quick-filter-toggle'
            }].pushObjects(this.get('columns').map(function (column) {
                return {
                    fieldType: column.get('fieldType'),
                    label: column.get('displayName'),
                    valuePath: column.get('fieldName'),
                    sortable: true,
                    width: column.get('width'),
                    cellComponent: column.get('cellComponent'),
                    type: 'base-with-filter',
                    tpColumn: column
                };
            }));
        })
    });
});