define('tm3/pods/components/matter/admin/contact-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['panel', 'panel-default'],
        matterContact: null,
        firmHasClients: Ember.computed('findFirmHasClientsTask.value', 'matterContact', function () {
            var allFirmHasClients = this.get('findFirmHasClientsTask.value');
            return allFirmHasClients ? allFirmHasClients.filterBy('user.id', this.get('matterContact.content.user.id')) : [];
        }),
        actions: {
            edit: function edit() {
                this.sendAction('edit', this.get('matterContact.content'));
            },
            delete: function _delete() {
                this.sendAction('delete', this.get('matterContact.content'));
            },
            updateMatterHasUser: function updateMatterHasUser() {
                this.sendAction('updateMatterHasUser', this.get('matterContact.content'));
            },
            removeAccountConnection: function removeAccountConnection(item) {
                this.sendAction('removeAccountConnection', item);
            },
            updateAccountConnection: function updateAccountConnection(item) {
                this.sendAction('updateAccountConnection', item);
            }
        }
    });
});