define('tm3/components/x-todo', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        'data-test-component': 'x-todo',

        init: function init() {
            this._super.apply(this, arguments);

            this.setProperties({
                categories: [],
                employees: []
            });
        },


        /**
         * @property
         *
         * to show the form or a display of the To do
         */
        isEditing: false,

        /**
         * @property
         *
         * allow edit a todo
         */
        allowEdit: true,

        /**
         * @property
         *
         * the todo mode lto be displayed/edited/created
         */
        model: null,

        /**
         * @event
         *
         * action called after a successfull save
         */
        afterSave: '',

        /**
         * @event
         *
         * action called after cancel editing
         */
        afterCancel: '',

        actions: {
            edit: function edit() {
                this.set('isEditing', true);
            },
            cancel: function cancel() {
                this.get('model').rollbackAttributes();
                this.set('isEditing', false);
                this.sendAction('afterCancel');
            },
            save: function save() {
                var self = this;
                var todo = this.get('model');

                todo.save().then(function () {
                    self.get('notify').success('To do was saved successfully');
                    self.set('isEditing', false);
                    self.sendAction('afterSave', todo);
                }, function () {
                    self.validationReport(todo);
                });
            }
        }
    });
});