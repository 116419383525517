define('tm3/pods/crm/accounts/info/matters/add-matter/controller', ['exports', 'tm-common/mixins/crud/error', 'ember-validations'], function (exports, _error, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, _emberValidations.default, {
        notify: Ember.inject.service(),
        accountsInfoMattersController: Ember.inject.controller('crm.accounts.info.matters'),

        validations: {
            relatedMatter: { presence: true }
        },

        selectOptions: [],

        relatedMatter: null,

        relationships: ['Corporation', 'Law Firm'],

        councilTypes: ['Local', 'National'],

        actions: {
            /**
             *
             */
            save: function save() {
                var self = this;
                var matter = this.get('relatedMatter');
                var firm = this.get('firm');
                self.validate().then(function () {
                    self.store.query('firm-has-matter', {
                        matter_id: matter.get('id'),
                        firm_id: firm.get('id')
                    }).then(function (data) {
                        if (Ember.isEmpty(data)) {
                            var fhm = self.store.createRecord('firm-has-matter', {
                                matter: matter,
                                firm: firm,
                                firmMatterNumber: self.get('firmMatterNumber'),
                                councilType: self.get('councilType'),
                                relationshipToMatter: self.get('relationship'),
                                defendant: self.get('defendant'),
                                primary: self.get('primary')
                            });
                            // no existing records found, save this one
                            fhm.save().then(function (data) {
                                // attempt to reset form data
                                self.set('relatedMatter', null);
                                self.set('councilType', '');
                                self.set('relationship', null);
                                self.set('defendant', false);
                                self.set('primary', false);
                                self.set('firmMatterNumber', null);
                                self.get('notify').success('Matter added');
                                self.transitionToRoute('crm.accounts.info.matters', firm.get('id'));
                                self.get('accountsInfoMattersController').send('fetch');
                            }, function () {
                                self.validationReport(fhm);
                            });
                        } else {
                            self.set('relatedMatter', null);
                            self.get('notify').success('This matter is already associated with this account');
                            self.transitionToRoute('crm.accounts.info.matters', firm.get('id'));
                            self.get('accountsInfoMattersController').send('fetch');
                        }
                    });
                }, function () {
                    self.notifyClientSideValidations(self);
                });
            },


            /** power-select for clients */
            searchMatters: function searchMatters(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchMatters, term, resolve, reject, 600);
                });
            }
        },

        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            return this.store.query('matter', {
                'name||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});