define('tm3/pods/components/custom-table-pagination/accounting/billing/invoices/list/table-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',

        invoiceExpenses: Ember.computed('item', function () {
            // let item = this.get('item');
            var invoiceSubs = this.get('item.invoiceSubs');
            var expenses = invoiceSubs.filterBy('type', 'Expense');

            var rtn = '';
            expenses.forEach(function (expense) {
                rtn = 'Expenses: $' + parseFloat(expense.get('amount')).toFixed(2);
            });

            return rtn;
        }),

        invoiceServices: Ember.computed('item', function () {
            // let item = this.get('item');
            var invoiceSubs = this.get('item.invoiceSubs');
            var services = invoiceSubs.filterBy('type', 'Service');

            var rtn = '';
            services.forEach(function (service) {
                rtn = 'Services: $' + parseFloat(service.get('amount')).toFixed(2);
            });

            return rtn;
        }),

        invoiceDiscounts: Ember.computed('item', function () {
            // let item = this.get('item');
            var invoiceSubs = this.get('item.invoiceSubs');
            var discounts = invoiceSubs.filterBy('type', 'Discount');

            var rtn = '';
            discounts.forEach(function (discount) {
                rtn = 'Discounts: $' + parseFloat(discount.get('amount')).toFixed(2);
            });

            return rtn;
        }),

        invoiceTaxes: Ember.computed('item', function () {
            // let item = this.get('item');
            var invoiceSubs = this.get('item.invoiceSubs');
            var taxes = invoiceSubs.filterBy('type', 'Tax');

            var rtn = '';
            taxes.forEach(function (tax) {
                rtn = 'Taxes: $' + parseFloat(tax.get('amount')).toFixed(2);
            });

            return rtn;
        })
    });
});