define('tm3/pods/components/admin/teams/matter-team/component', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment'], function (exports, _error, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        team: null,
        groups: null,
        matterTeam: null,
        /**
         * if an employee group is selected then only use case groups
         * otherwise use portal groups for a team of type client
         */
        filteredGroups: Ember.computed('groups', 'team', function () {
            var mode = this.get('team.mode');
            var groupType = mode === 'Employee' ? _environment.default.APP.groupTypes.employee : _environment.default.APP.groupTypes.client;
            return this.get('groups').filterBy('groupType', groupType);
        }),

        actions: {
            save: function save() {
                var _this = this;

                var matterTeam = this.get('matterTeam');
                matterTeam.set('team', this.get('team'));
                var isNew = matterTeam.get('isNew');
                matterTeam.save().then(function () {
                    _this.$().closest('.modal').modal('hide');
                    _this.get('notify').success('User has been saved');
                    if (isNew) {
                        _this.sendAction('refresh');
                    }
                }, function () {
                    _this.validationReport(matterTeam);
                });
            },


            /** power-select for client */
            searchMatter: function searchMatter(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchMatters, term, resolve, reject, 600);
                });
            }
        },

        _performSearchMatters: function _performSearchMatters(term, resolve, reject) {
            var params = {
                'name||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            };

            return this.get('store').query('matter', params).then(function (items) {
                return resolve(items);
            });
        }
    });
});