define('tm3/helpers/min-width-if-true', ['exports', 'ember-cli-table-pagination/helpers/min-width-if-true'], function (exports, _minWidthIfTrue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _minWidthIfTrue.default;
    }
  });
  Object.defineProperty(exports, 'minWidthIfTrue', {
    enumerable: true,
    get: function () {
      return _minWidthIfTrue.minWidthIfTrue;
    }
  });
});