define('tm3/models/outbound-communication-log-user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        Model = _emberData.default.Model;
    exports.default = Model.extend({
        userFullName: attr('string'),
        userId: attr('number'),
        userType: attr('string'),
        outboundCommunicationLog: belongsTo('outbound-communication-log')
    });
});