define('tm3/helpers/you-or-else', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.youOrElse = youOrElse;
    function youOrElse(params /*, hash */) {
        var user = params[0];
        var currentUserId = params[1];

        if (user.get('id') === currentUserId) {
            return 'You';
        } else {
            return user.get('fullName');
        }
    }

    exports.default = Ember.Helper.helper(youOrElse);
});