define('tm3/pods/admin/employees/info/groups-and-teams/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tm-common/mixins/authorized-route-mixin', 'tm3/config/environment'], function (exports, _authenticatedRouteMixin, _authorizedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authorizedRouteMixin.default, {
        model: function model(params) {
            var infoModel = this.modelFor('admin.employees.info');

            return Ember.RSVP.hash({
                employee: this.store.findRecord('employee', infoModel.employee.get('id')),
                user: this.store.findRecord('user', infoModel.employee.get('id')),
                userGroups: this.store.query('user-has-group', {
                    user_id: infoModel.employee.get('id'),
                    with: 'all'
                }),
                userTeams: this.store.query('user-has-team', {
                    user_id: infoModel.employee.get('id'),
                    with: 'all'
                }),
                groups: this.store.query('group', {
                    sort: 'name',
                    group_type: _environment.default.APP.groupTypes.system
                }),
                teams: this.store.query('team', { sort: 'name', mode: 'Employee' })
            });
        },

        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});