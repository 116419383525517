define('tm3/helpers/set-var', ['exports', 'tm-common/helpers/set-var'], function (exports, _setVar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _setVar.default;
    }
  });
});