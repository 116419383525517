define('tm3/services/remote-handler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        remoteError: function remoteError(e) {
            // TODO (TM-2010, TM-2011): extract if client state becomes out of sync with the server
            // or if network is down or other common problems
            return {
                e: e,
                outOfSync: false,
                offline: false
            };
        },
        bind: function bind(name) {
            return this[name].bind(this);
        }
    });
});