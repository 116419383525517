define('tm3/pods/preview/report-browser/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentReportName: undefined,

        sortedDocuments: Ember.computed('model.documents', 'model.docTypes', function () {
            var docTypes = this.get('model.docTypes');

            // update the name and docTypeOrder in each document
            this.get('model.documents').forEach(function (d) {
                d.set('name', d.get('name').replace(/\s\s+/g, ' '));
                if (docTypes) {
                    var docType = docTypes.findBy('value', d.get('docType'));
                    if (docType) {
                        d.set('docTypeOrder', docType.get('weight'));
                    }
                }
            });

            return this.get('model.documents').sortBy('report.name', 'tabNumber', 'docTypeOrder', 'name');
        }),

        tabs: Ember.computed('sortedDocuments', function () {
            return this.get('sortedDocuments').filter(function (d) {
                return d.get('tabNumber') > 0;
            });
        }),

        reports: Ember.computed('sortedDocuments', function () {
            return this.get('sortedDocuments').filter(function (d) {
                return d.get('tabNumber') === 0 || Ember.isEmpty(d.get('tabNumber'));
            });
        })
    });
});