define('tm3/pods/components/matter/admin/reports/billable-group/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['panel', 'panel-default'],

        time: Ember.computed.reads('dataObj.time'),

        employeeName: Ember.computed('time.employee_name', 'time.last_name', 'time.first_name', function () {
            return this.get('time.employee_name') || this.get('time.last_name') + ', ' + this.get('time.first_name');
        }),
        actions: {
            expand: function expand() {
                Ember.set(this.get('dataObj'), 'expanded', true);
                this.sendAction('collapsedChanged');
            },
            collapse: function collapse() {
                Ember.set(this.get('dataObj'), 'expanded', false);
                this.sendAction('collapsedChanged');
            },
            clickedBadge: function clickedBadge() {
                Ember.set(this.get('dataObj'), 'expanded', !this.get('dataObj.expanded'));
                this.sendAction('collapsedChanged');
            }
        }
    });
});