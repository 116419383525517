define('tm3/pods/components/custom-table-pagination/full-width-pager/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['col-md-12', 'col-xs-12', 'pull-right'],
        actions: {
            changePage: function changePage(newPage) {
                this.attrs.changePage(newPage);
            }
        }
    });
});