define('tm3/helpers/get-date', ['exports', 'tm-common/helpers/get-date'], function (exports, _getDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getDate.default;
    }
  });
  Object.defineProperty(exports, 'getDate', {
    enumerable: true,
    get: function () {
      return _getDate.getDate;
    }
  });
});