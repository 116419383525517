define('tm3/pods/components/case-management/global-custom-actions/component', ['exports', 'tm3/pods/components/case-management/global-custom-actions/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        tagName: '',

        actions: {
            editRow: function editRow(chmId) {
                this.sendAction('editRow', chmId);
            }
        }
    });
});