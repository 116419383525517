define('tm3/pods/matter/admin/invoice-groups/detail/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requireAnyPermission: ['Manage All Cases', 'Manage Case'],
        model: function model(params) {
            var matter = this.modelFor('matter').matter;
            var matterId = matter.id;
            return Ember.RSVP.hash({
                model: this.store.queryRecord('invoice-group', {
                    id: params.invoice_group_id,
                    with: 'invoice_group_members'
                }),
                accounts: this.store.query('firm-has-matter', { matter_id: matterId })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('accounts', resolved.accounts.filter(function (account) {
                return !Ember.isPresent(resolved.model.get('invoiceGroupMembers').findBy('firm.id', account.get('firm.id')));
            }));
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            savePrimary: function savePrimary(item) {
                this.controllerFor('matter.admin.invoice-groups').send('makePrimary', item);
            }
        }
    });
});