define('tm3/pods/admin/employees/info/reports/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tm-common/mixins/authorized-route-mixin'], function (exports, _authenticatedRouteMixin, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authorizedRouteMixin.default, {
        notify: Ember.inject.service(),
        reportCatalog: Ember.inject.service(),

        model: function model(params) {
            var infoModel = this.modelFor('admin.employees.info');

            return Ember.RSVP.hash({
                employee: this.store.findRecord('employee', infoModel.employee.get('id')),
                user: this.store.findRecord('user', infoModel.employee.get('id')),
                reportCatalog: this.get('reportCatalog').getReportList(),
                dataReports: this.store.findAll('data-report'),
                dataReportHasUsers: this.store.query('data-report-has-user', {
                    user_id: infoModel.employee.get('id')
                })
            });
        },

        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            resolved.dataReports.forEach(function (dr) {
                var catalogReport = resolved.reportCatalog.findBy('apiName', dr.get('name'));
                if (catalogReport) {
                    if (catalogReport.get('displayName')) {
                        dr.set('displayName', catalogReport.get('displayName'));
                    }
                } else {
                    var nameWords = [];
                    dr.get('name').split('_').forEach(function (word) {
                        nameWords.push(Ember.String.capitalize(word));
                    });
                    dr.set('displayName', nameWords.join(' '));
                }
            });

            var notDisplayedReports = ['all_employee_time_reports', 'employee_time_reports', 'billable_type_time_reports'];

            var dataReports = resolved.dataReports.reject(function (report) {
                return notDisplayedReports.contains(report.get('name'));
            });
            controller.set('dataReports', dataReports);
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            },

            giveAccess: function giveAccess(report) {
                var _this = this;

                var employee = this.get('controller.model.employee');
                var dataReportHasUser = this.store.createRecord('data-report-has-user', {
                    user: employee,
                    dataReport: report
                });
                dataReportHasUser.save().then(function (result) {
                    _this.get('notify').success('Added access to report');
                    _this.refresh();
                }, function (_error) {
                    _this.get('notify').error('There was a problem trying to give access to the report');
                });
            },
            removeAccess: function removeAccess(dataReportHasUser) {
                var _this2 = this;

                dataReportHasUser.destroyRecord().then(function (result) {
                    _this2.get('notify').success('Removed access to report');
                    _this2.refresh();
                }, function (_error) {
                    _this2.get('notify').error('There was a problem trying to remove access to the report');
                });
            }
        }
    });
});