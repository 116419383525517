define('tm3/pods/matter/tasks/info/route', ['exports', 'tm3/mixins/alert-users-route', 'tm3/config/environment'], function (exports, _alertUsersRoute, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_alertUsersRoute.default, {
        session: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        renderTemplate: function renderTemplate() {
            this.render({ into: 'matter' });
        },
        model: function model(params) {
            var _this = this;

            return Ember.RSVP.hash({
                currentUser: this.store.findRecord('user', this.get('session.data.authenticated.id')),
                task: this.store.findRecord('task', params.task_id),
                task_notes: this.store.query('task-has-note', {
                    task_id: params.task_id,
                    with: 'notes,resource_has_communications'
                }),
                task_events: this.store.query('task-has-event', {
                    task_id: params.task_id
                }),
                task_documents: this.store.query('task-has-document', {
                    task_id: params.task_id,
                    with: 'documents'
                }),
                employees: this.store.query('employee', {
                    with_access_to_matter: this.get('currentMatter.id')
                }),
                matter: this.modelFor('matter').matter,
                states: this.store.query('db-tag', {
                    parent_id: _environment.default.APP.tags.stateCategory
                }),
                fileTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                alertUsers: this.employeesModelMixin()
            }).then(function (hash) {
                return _this.store.query('resource-has-communication', {
                    resource_id: hash.task_notes.mapBy('note.id')
                }).then(function (rhc) {
                    hash.communications = rhc;
                    return hash;
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.send('createNewNote');
            controller.set('fileTypes', model.fileTypes);
            controller.set('states', model.states);
            controller.set('communications', model.communications);
            controller.resetSelectedEmployees();
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});