define('tm3/pods/report/info/managers-billing-report/route', ['exports', 'tm3/mixins/report-info-route', 'moment'], function (exports, _reportInfoRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'managers_billing_report',

        queryParams: {
            caseTypes: {
                refreshModel: false
            }
        },

        model: function model(params) {
            var currentMonth = (0, _moment.default)().subtract(1, 'M').format('MMMM YYYY'); // TODO: this will be selected by user
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    case_types: params.caseTypes,
                    month: currentMonth
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                matterTypes: this.store.query('matter-type', {
                    active: 1,
                    sort: 'name'
                }),
                caseTypes: params.caseTypes
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('matterTypes', resolved.matterTypes);
            controller.set('caseTypes', resolved.caseTypes);
            controller.set('month', (0, _moment.default)().subtract(1, 'M').format('MMMM YYYY'));
        },


        actions: {
            loading: function loading(transition, originRoute) {
                return true; // allows the loading template to be shown
            },
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});