define('tm3/pods/components/report/new-cases-detail/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        'data-test-component': 'new-cases-details-report',

        grandTotal: Ember.computed('tableData', function () {
            return this.get('tableData').reduce(function (previousValue, item) {
                return previousValue + parseFloat(item.total_hours);
            }, 0);
        })
    });
});