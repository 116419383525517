define('tm3/pods/components/report/billing-report-by-person/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            var totalHours = 0;
            var totalServiceRevenue = 0;

            this.get('tableData').forEach(function (data) {
                totalHours += parseFloat(data['hours']);
                totalServiceRevenue += parseFloat(data['time_revenue']);
            });

            this.set('totalHours', totalHours);
            this.set('totalServiceRevenue', totalServiceRevenue);
        }
    });
});