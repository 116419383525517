define('tm3/pods/preview/by-subject/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        model: function model(params, transition) {
            var _this = this;

            this.set('documentId', transition.params.preview.document_id);
            return this.store.queryRecord('document', {
                resource_id: this.get('documentId'),
                with: 'timeline_events'
            }).then(function (document) {
                var matter = _this.modelFor('preview').matter;
                return Ember.RSVP.hash({
                    subjectsInCase: _this.modelFor('preview').subjectsInCase,
                    matter: matter
                });
            });
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            },
            selectionChange: function selectionChange(subject) {
                this.set('currentSubject', subject);
                this.transitionTo('preview.by-subject.subject-events', subject.get('id'));
            }
        }
    });
});