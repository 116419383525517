define('tm3/pods/crm/accounts/info/matters/controller', ['exports', 'ember-cli-table-pagination/mixins/table-pager/controller'], function (exports, _controller) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        sortingOptions: [{ title: 'Date (Ascending)', field: 'matters:updated_on' }, { title: 'Date (Descending)', field: '-matters:updated_on' }, { title: 'Prefix (Ascending)', field: 'matters:tm_code' }, { title: 'Prefix (Descending)', field: '-matters:tm_code' }],
        selectedSortingOption: null,

        with: 'matters',
        sortField: 'matters:name',
        perPage: 20,

        selectedSortingOptionObserver: Ember.observer('selectedSortingOption', function () {
            var selectedSortingOption = this.get('selectedSortingOption');
            if (selectedSortingOption !== null) {
                this.set('sortField', selectedSortingOption.field);
            } else {
                this.set('sortField', 'Matters:name');
            }
            this.send('fetch');
        }),

        actions: {
            // show a detailed information on the particular matter
            // somehow...maybe a modal?  maybe a list down the left side?
            showDetail: function showDetail(item) {
                this.set('currentMatter', item);
                Ember.$('#modal').modal('toggle');
            },
            expandAll: function expandAll() {
                this.get('modelSorted').forEach(function (item) {
                    item.get('actionReceiver').send('getBillingInfo');
                });
            }
        }
    });
});