define('tm3/pods/components/notification/notification-list/component', ['exports', 'tm3/pods/components/notification/notification-list/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        tagName: 'li',
        classNames: ['notification-list'],
        classNameBindings: ['hasNewNotifications', 'renderNotifications:open'],
        notificationsService: Ember.inject.service('notifications'),

        init: function init() {
            this._super.apply(this, arguments);

            // notificationOrdering
            this.set('ordering', ['createdOn:desc']);
        },


        /**
         * Add an event listener on the document for hiding the notification list
         * when clicks happen outside it.
         */
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get('notificationsService').startNotificationsUpdate();

            var documentClickEvent = function documentClickEvent(event) {
                if (_this.get('renderNotifications')) {
                    if (!_this.element.contains(event.target) || _this.element.classList.contains('close-dropdown')) {
                        _this.hideNotifications();
                    }
                }
            };

            this.set('_documentClickEvent', documentClickEvent);
            document.addEventListener('click', documentClickEvent, true);
        },


        /**
         * Remove the click event listener on the document.
         */
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            this.get('notificationsService').stopNotificationsUpdate();

            document.removeEventListener('click', this.get('_documentClickEvent'), true);
        },


        // Route path used to expand a notification
        notificationRoutePath: 'me.notifications.notification',

        /**
         * Whether the notification list should be rendered.
         *
         * @type {Boolean}
         */
        renderNotifications: false,

        /**
         * Notifications to show.
         *
         * @type {Ember.Array}
         */
        notifications: Ember.computed.alias('notificationsService.notifications'),

        /**
         * Number of unread notifications on the server
         * @type Number
         */
        unreadNotificationsCount: Ember.computed.alias('notificationsService.unreadNotificationsCount'),

        /**
         * The current selected notification. This will be displayed in the right panel
         */
        currentNotification: null,

        /**
         * Text to show if there are no notifications.
         *
         * @type {String}
         */
        noNotificationsText: 'You have no notifications.',

        /**
         * Notifications ordered by date.
         *
         * @type {Ember.Array}
         */
        orderedNotifications: Ember.computed.sort('notifications', 'ordering'),
        newNotifications: Ember.computed.filterBy('orderedNotifications', 'read', 0),

        /**
         * used to know if the app is JS2-admin
         */
        isJSapp: false,

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        tm2AdminURL: Ember.computed('ENV', function () {
            var app = this.get('ENV').APP;

            if ('tm2AdminURL' in app) {
                return app.tm2AdminURL;
            }

            return '';
        }),

        height: Ember.computed('orderedNotifications.length', function () {
            var notifications = this.get('orderedNotifications');
            var notificationNr = notifications.get('length');
            var fullHeight = notificationNr * 112;

            fullHeight = fullHeight > 0 ? fullHeight : 112;
            var wantedHeight = fullHeight > 504 ? 504 : fullHeight;
            var windowHeight = Ember.$(window).height();
            var navbar = Ember.$('.navbar-custom-menu').height();
            var maxHeight = windowHeight - navbar;

            return wantedHeight > maxHeight ? maxHeight - 80 : wantedHeight;
        }),

        notificationBodyHeight: Ember.computed('height', function () {
            return this.get('height') > 200 ? this.get('height') - 100 : 300;
        }),

        notificationBodyHeightStyle: Ember.computed('notificationBodyHeight', function () {
            var value = this.get('notificationBodyHeight');

            return Ember.String.htmlSafe('height:' + value + 'px');
        }),

        dropdownWidth: Ember.computed('currentNotification', function () {
            var windowWidth = Ember.$(window).width();

            return windowWidth * 2 / 3 > 600 ? windowWidth * 2 / 3 : 600;
        }),

        dropdownWidthStyle: Ember.computed('dropdownWidth', function () {
            var dropdownWidth = this.get('dropdownWidth');

            return Ember.String.htmlSafe('width:' + dropdownWidth + 'px');
        }),

        listWidth: Ember.computed('currentNotification', 'dropdownWidth', function () {
            return this.get('currentNotification') ? 400 : this.get('dropdownWidth');
        }),

        relatedLoadedTask: null,

        showNotifications: function showNotifications() {
            this.set('renderNotifications', true);

            // load related if they are not loaded yet
            if (!this.get('notificationsService.relatedLoaded')) {
                this.get('notificationsService.storeRelated').perform(this.get('notifications'));
            }
        },


        /**
         * Hide the list of notifications.
         */
        hideNotifications: function hideNotifications() {
            this.set('renderNotifications', false);
        },


        actions: {
            dismiss: function dismiss(notification) {
                notification.set('read', 1);
                notification.save();
            },
            markAsUnread: function markAsUnread(notification) {
                notification.set('read', 0);
                notification.save();
            },
            dismissAll: function dismissAll() {
                // TODO: Implement this with a single api call
                var markAsRead = 0;

                this.get('notifications').filterBy('read', 0).forEach(function (unseen) {
                    unseen.set('read', 1);
                    unseen.save();
                    markAsRead += 1;
                });
                this.decrementProperty('unreadNotificationsCount', markAsRead);
            },
            deleteAll: function deleteAll() {
                /* TODO: Implement this - OPTIONAL
                   done(function () {
                     that.get('unreadNotifications').forEach(function (notification) {
                       notification.unloadRecord()
                     })
                   }) */
            },
            closeNotification: function closeNotification() {
                this.set('currentNotification', null);
            },
            deleteNotification: function deleteNotification() {
                this.get('currentNotification').destroyRecord();
                this.set('currentNotification', null);
            },
            openNotification: function openNotification(notification) {
                this.set('currentNotification', notification);

                if (!notification.get('read')) {
                    notification.set('read', 1);
                    notification.save();
                }
            },


            /**
             * Toggle visibility of the notification list.
             */
            toggleVisibility: function toggleVisibility() {
                if (this.get('renderNotifications')) {
                    this.hideNotifications();
                } else {
                    this.showNotifications();
                }
            }
        }
    });
});