define('tm3/pods/components/report/default-table/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        'data-test-component': 'report-default-table',

        showTotalRow: false,

        totalsColSpan: Ember.computed('columns', function () {
            var colNames = this.get('columns').mapBy('fieldName');

            return colNames.indexOf('amount');
        }),

        remainingColSpan: Ember.computed('columns', function () {
            var columns = this.get('columns');
            var colNames = columns.mapBy('fieldName');

            return columns.get('length') - colNames.indexOf('amount');
        }),

        total: Ember.computed('showTotalRow', 'tableData', function () {
            var total = 0;

            this.get('tableData').forEach(function (data) {
                total += parseFloat(data.amount);
                total = parseFloat(total.toFixed(2));
            });

            return total;
        })
    });
});