define('tm3/helpers/dates-begin-and-end-month', ['exports', 'tm-common/helpers/dates-begin-and-end-month'], function (exports, _datesBeginAndEndMonth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _datesBeginAndEndMonth.default;
    }
  });
  Object.defineProperty(exports, 'datesBeginAndEndMonth', {
    enumerable: true,
    get: function () {
      return _datesBeginAndEndMonth.datesBeginAndEndMonth;
    }
  });
});