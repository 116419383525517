define('tm3/serializers/case-note', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (Ember.isNone(payload['case_notes'])) {
                payload.case_notes = [];
            }
            return this._super.apply(this, arguments);
        }
    });
});