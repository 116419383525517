define('tm3/pods/matter/sent-reports/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            page: {
                refreshModel: true,
                replace: true
            },
            perPage: {
                refreshModel: true,
                replace: true
            }
        },

        /**
            Fetches paged `outbound-communication-log` records for the current
            `MatterRecord` and side loads related `outbound-communication-log-things`
            and `outbound-communication-log-users`.
             @method model
            @param {Object} params
         */
        model: function model(params) {
            var _modelFor = this.modelFor('matter'),
                matter = _modelFor.matter;

            var page = params.page,
                perPage = params.perPage;


            return this.get('store').query('outbound-communication-log', {
                matter_id: matter.id,
                page: page,
                perPage: perPage,
                with: 'employees,outbound_communication_log_things,outbound_communication_log_users'
            });
        },


        /**
            Resets page number when exiting the route.
             @method resetController
            @param {Ember.Controller} controller
            @param {Boolean} isExiting
         */
        resetController: function resetController(controller, isExiting /* , transition */) {
            if (isExiting) {
                controller.set('page', 1);
            }
        }
    });
});