define('tm3/models/db-memo', ['exports', 'tm-common/models/db-memo'], function (exports, _dbMemo) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dbMemo.default.extend({
        dbId: Ember.computed.alias('db.id'),
        dbName: Ember.computed.alias('db.name'),
        fullName: Ember.computed.alias('db.fullName')
    });
});