define('tm3/pods/report/info/bonus-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['year', 'month', 'showStoredReport'],

        reportBuilder: Ember.inject.service(),

        year: '',
        month: '',
        showStoredReport: false,

        tableData: Ember.computed.alias('model.tableData.table_data'),
        columns: Ember.computed.alias('model.tableData.columns'),
        showTotalRow: false,
        noDataMessage: "Select a Year and Month then click Apply to generate a report.",

        actions: {
            getReport: function getReport(format) {
                var jsonOptions = {
                    report_builder: {
                        standard: {
                            type: 'bonus_report',
                            format: format,
                            style: 'tabular'
                        },
                        custom: {
                            per_page: 20000,
                            page: 1,
                            year: this.get('year'),
                            month: this.get('month'),
                            show_stored: this.get('showStoredReport')
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonOptions);
            },
            toggleClass: function toggleClass(emp_id) {
                var element = document.getElementById('cases-' + emp_id);
                element.classList.toggle('jury-cases');
            }
        }
    });
});