define('tm3/pods/components/activity-list/table-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['box-header'],
        isAdding: false,

        noteCategories: Ember.computed.reads('titleParams.categories'),
        employees: Ember.computed.reads('titleParams.employees'),

        actions: {
            addNote: function addNote() {
                this.set('isAdding', true);
            }
        }
    });
});