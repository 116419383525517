define('tm3/components/videojs-player', ['exports', 'ember-videojs/components/videojs-player'], function (exports, _videojsPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _videojsPlayer.default;
    }
  });
});