define('tm3/pods/matter/admin/config/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Controller.extend({
        currentMatter: Ember.inject.service(),
        store: Ember.inject.service(),

        matter: alias('model.matter'),

        currentJsSubjectSort: Ember.computed('model.jsSubjectSort', function () {
            var jsSubjectSort = this.get('model.jsSubjectSort');
            var sortField = null;

            switch (jsSubjectSort.get('length')) {
                case 2:
                    sortField = jsSubjectSort.rejectBy('name', 'matters.default.js_subject_sort').get('firstObject.value');
                    break;
                case 1:
                    sortField = jsSubjectSort.get('firstObject.value');
                    break;
            }

            switch (sortField) {
                case 'order_only':
                    return 'Order Only';
                case 'panel_then_order':
                    return 'Panel then Order';
                default:
                    return 'Not recognized configuration';
            }
        }),

        jsSubjectSortDefault: Ember.computed('model.jsSubjectSort', function () {
            return this.get('model.jsSubjectSort.length') === 1;
        }),

        currentScPortalDashboardType: Ember.computed('model.scPortalDashboardType', function () {
            var scPortalDashboardType = this.get('model.scPortalDashboardType');
            var dashboardType = null;

            switch (scPortalDashboardType.get('length')) {
                case 2:
                    dashboardType = scPortalDashboardType.rejectBy('name', 'matters.default.sc_portal_dashboard_type').get('firstObject.value');
                    break;
                case 1:
                    dashboardType = scPortalDashboardType.get('firstObject.value');
                    break;
            }

            switch (dashboardType) {
                case 'jury_dashboard':
                    return 'Jury Dashboard';
                case 'mdl_dashboard':
                    return 'MDL Dashboard (Zantac)';
                default:
                    return 'Not recognized configuration';
            }
        }),

        scPortalDashboardTypeDefault: Ember.computed('model.scPortalDashboardType', function () {
            return this.get('model.scPortalDashboardType.length') === 1;
        }),

        currentScPortalReportTab: Ember.computed('model.scPortalReportTab', function () {
            var scPortalReportTab = this.get('model.scPortalReportTab');
            var tab = null;

            switch (scPortalReportTab.get('length')) {
                case 2:
                    tab = scPortalReportTab.rejectBy('name', 'matters.default.sc_portal_report_tab').get('firstObject.value');
                    break;
                case 1:
                    tab = scPortalReportTab.get('firstObject.value');
                    break;
            }

            switch (tab) {
                case 'matter.new-report':
                    return 'Subject Reports';
                case 'matter.report-browser':
                    return 'Report Browser';
                default:
                    return 'Not recognized configuration';
            }
        }),

        scPortalReportTabDefault: Ember.computed('model.scPortalReportTab', function () {
            return this.get('model.scPortalReportTab.length') === 1;
        }),

        actions: {
            overrideJsSubjectSort: function overrideJsSubjectSort() {
                var _this = this;

                var matterId = this.get('currentMatter.id');
                this.get('store').createRecord('appl-config', {
                    name: 'matters.' + matterId + '.js_subject_sort',
                    value: 'panel_then_order'
                }).save().then(function () {
                    _this.send('refresh');
                });
            },
            removeJsSubjectSort: function removeJsSubjectSort() {
                var _this2 = this;

                var matterId = this.get('currentMatter.id');
                this.get('model.jsSubjectSort').findBy('name', 'matters.' + matterId + '.js_subject_sort').destroyRecord().then(function () {
                    _this2.send('refresh');
                });
            },
            overrideScPortalDashboardType: function overrideScPortalDashboardType() {
                var _this3 = this;

                var matterId = this.get('currentMatter.id');
                this.get('store').createRecord('appl-config', {
                    name: 'matters.' + matterId + '.sc_portal_dashboard_type',
                    value: 'mdl_dashboard'
                }).save().then(function () {
                    _this3.send('refresh');
                });
            },
            removeScPortalDashboardType: function removeScPortalDashboardType() {
                var _this4 = this;

                var matterId = this.get('currentMatter.id');
                this.get('model.scPortalDashboardType').findBy('name', 'matters.' + matterId + '.sc_portal_dashboard_type').destroyRecord().then(function () {
                    _this4.send('refresh');
                });
            },
            overrideScPortalReportTab: function overrideScPortalReportTab() {
                var _this5 = this;

                var matterId = this.get('currentMatter.id');
                this.get('store').createRecord('appl-config', {
                    name: 'matters.' + matterId + '.sc_portal_report_tab',
                    value: 'matter.report-browser'
                }).save().then(function () {
                    _this5.send('refresh');
                });
            },
            removeScPortalReportTab: function removeScPortalReportTab() {
                var _this6 = this;

                var matterId = this.get('currentMatter.id');
                this.get('model.scPortalReportTab').findBy('name', 'matters.' + matterId + '.sc_portal_report_tab').destroyRecord().then(function () {
                    _this6.send('refresh');
                });
            },
            saveConfig: function saveConfig(config, value) {
                var _this7 = this;

                this.get('matter').setMatterConfiguration({
                    config: config,
                    value: value
                }).then(function () {
                    _this7.send('refresh');
                });
            }
        }
    });
});