define('tm3/mixins/subjects-import-controller', ['exports', 'tm3/mixins/sorted-field', 'tm3/mixins/subject-import', 'npm:papaparse', 'string-similarity'], function (exports, _sortedField, _subjectImport, _npmPapaparse, _stringSimilarity) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var compareTwoStrings = _stringSimilarity.default.compareTwoStrings;
    var parse = _npmPapaparse.default.parse;
    var debug = Ember.Logger.debug;
    exports.default = Ember.Mixin.create({
        notify: Ember.inject.service(),

        breadCrumb: 'CSV Import',

        // start at the first
        currentStep: 1,
        showStep1: Ember.computed('currentStep', function () {
            return this.get('currentStep') === 1;
        }),
        showStep2: Ember.computed('currentStep', function () {
            return this.get('currentStep') === 2;
        }),
        showStep3: Ember.computed('currentStep', function () {
            return this.get('currentStep') === 3;
        }),
        showStep4: Ember.computed('currentStep', function () {
            return this.get('currentStep') === 4;
        }),

        showFailureMessage: Ember.computed('currentStep', 'partialSubjects.[]', function () {
            var show = false;
            if (this.get('currentStep') === 4) {
                this.get('partialSubjects').forEach(function (ps) {
                    if (ps.get('result') === 'Failure') {
                        show = true;
                    }
                });
            }
            return show;
        }),

        // Holds the FileReader object on init
        reader: null,

        // If FileReader is not supported shows a message to the user
        notSupported: false,

        // Contents of the file without the header
        data: [],

        // Fields on the file
        fields: [],

        // Fields for the matter
        matterFields: [],

        // Number of lines on the file
        numberOfSubjects: 0,
        // property used for computed.sort
        fieldsSortProperties: ['order:asc'],

        // fields sorted by order
        sortedFields: Ember.computed.sort('fields', 'fieldsSortProperties'),

        // matter fields sorted by order
        sortedMatterFields: Ember.computed.sort('matterFields', 'fieldsSortProperties'),

        processedSubjects: [],

        // starts the spinner button
        isImportingSubjects: false,

        // init function to init the FileReader if available
        checkFileAPI: Ember.on('init', function () {
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                this.reader = new FileReader();
            } else {
                this.get('notify').warning('The File APIs are not fully supported by your browser. Fallback required.');
                this.set('notSupported', true);
            }
        }),

        /**
         * We need matterFields (system) and fields (imported file) to match as closely as possible
         * We match each pair of fields and save their comparison values in a matrix
         * We find the largest values, one at a time = that is considered a close match and 'remove' its row and column from the matrix
         * After we find all matches, we re-order the fields so the matches will be displayed side by side
         */
        getFieldMatches: function getFieldMatches() {
            var matches = [];
            var fileFields = this.get('fields');
            var systemFields = this.get('matterFields');
            var fileFieldsLength = fileFields.get('length');
            var systemFieldsLength = systemFields.get('length');

            // fields = rows (left)
            // matterFields = columns (top)
            var matrix = [];
            for (var i = 0; i < fileFieldsLength; i++) {
                matrix[i] = [];
                for (var j = 0; j < systemFieldsLength; j++) {
                    matrix[i][j] = compareTwoStrings(fileFields[i].get('fieldDisplay'), systemFields[j].get('fieldDisplay'));
                }
            }

            var smallestListLength = fileFieldsLength <= systemFieldsLength ? fileFieldsLength : systemFieldsLength;
            for (var k = 0; k < smallestListLength; k++) {
                var maxValue = 0;
                var maxValueI = 0;
                var maxValueJ = 0;

                // find max value from matrix
                for (var _i = 0; _i < fileFieldsLength; _i++) {
                    for (var _j = 0; _j < systemFieldsLength; _j++) {
                        if (matrix[_i][_j] !== '-' && maxValue < matrix[_i][_j]) {
                            maxValue = matrix[_i][_j];
                            maxValueI = _i;
                            maxValueJ = _j;
                        }
                    }
                }

                // we have max value coordinates => we have a match
                matches.push([fileFields[maxValueI], systemFields[maxValueJ]]);

                // we can 'remove' (set value of "-") the matched row and col
                for (var _i2 = 0; _i2 < fileFieldsLength; _i2++) {
                    if (_i2 === maxValueI) {
                        for (var _j2 = 0; _j2 < systemFieldsLength; _j2++) {
                            matrix[_i2][_j2] = '-';
                        }
                    } else {
                        for (var _j3 = 0; _j3 < systemFieldsLength; _j3++) {
                            if (_j3 === maxValueJ) {
                                matrix[_i2][_j3] = '-';
                            }
                        }
                    }
                }
            }

            return matches;
        },
        orderFields: function orderFields(matches) {
            var fileFields = this.get('fields');
            var systemFields = this.get('matterFields');
            var fileFieldsLength = fileFields.get('length');
            var systemFieldsLength = systemFields.get('length');

            // set all orders to 0
            fileFields.forEach(function (field) {
                field.set('order', 0);
            });
            systemFields.forEach(function (field) {
                field.set('order', 0);
            });

            // set orders. start with found matches then continue with what remains (fields without match that remain in largest of the 2 lists)
            var order = 1;
            matches.forEach(function (match) {
                match[0].set('order', order);
                match[1].set('order', order);
                order += 1;
            });

            if (fileFieldsLength < systemFieldsLength) {
                systemFields.forEach(function (field) {
                    if (!field.get('order')) {
                        field.set('order', order);
                        order += 1;
                    }
                });
            } else {
                fileFields.forEach(function (field) {
                    if (!field.get('order')) {
                        field.set('order', order);
                        order += 1;
                    }
                });
            }
        },


        /**
         * Translate Subject type to the proper mapping. eg: juror -> juror_subjects
         */
        translateSubjectType: function translateSubjectType(subject) {
            var subjectType = subject.get('subjectType');
            if (subjectType) {
                var newSubjectType = null;
                subjectType = subjectType.toLowerCase().trim();
                switch (subjectType) {
                    case 'juror':
                        newSubjectType = 'juror_subjects';
                        break;
                    case 'witness':
                        newSubjectType = 'witness_subjects';
                        break;
                    case 'plaintiff':
                        newSubjectType = 'plaintiff_subjects';
                        break;
                    case 'other':
                        newSubjectType = 'other_subjects';
                        break;
                    case 'decedent':
                        newSubjectType = 'decedent_subjects';
                        break;
                    default:
                        newSubjectType = subjectType;
                }
                subject.set('subjectType', newSubjectType);
            }
        },


        /**
         * Reads the file using the FileReader object
         */
        readFile: function readFile() {
            var _this = this;

            var filePath = Ember.$('#csv-file').get(0);
            if (filePath.files.length === 0) {
                this.get('notify').error('Please select a csv file first');
                this.set('currentStep', 1);
                return;
            }

            var extension = filePath.files[0].name.toLowerCase().substr(-3);
            if (extension !== 'csv') {
                this.get('notify').error('Please select a CSV file first');
                this.set('currentStep', 1);
                return;
            }

            parse(filePath.files[0], {
                complete: function complete(results, file) {
                    debug('Parsing complete', results, file);
                    _this.set('numberOfSubjects', results.data.length - 1);
                    var order = 1;
                    var orderInFile = 0;
                    _this.set('fields', results.data[0].map(function (field) {
                        return _sortedField.default.create({
                            fieldName: field,
                            fieldDisplay: field,
                            order: order++,
                            orderInFile: orderInFile++
                        });
                    }));
                    _this.set('data', results.data.slice(1));
                    var matches = _this.getFieldMatches();
                    _this.orderFields(matches);
                }
            });

            this.set('currentStep', 2);
        },


        /**
         * process all subjects and determine whether to add or update existing
         */
        previewSubjects: function previewSubjects() {
            var _this2 = this;

            // hold a list of subjects and their data including what to process
            var partialSubjects = [];

            // line up final imported field with counter part in api
            var sortedFields = this.get('sortedFields');
            sortedFields.forEach(function (field) {
                var fieldAligned = _this2.get('sortedMatterFields').findBy('order', field.get('order'));
                if (fieldAligned) {
                    field.set('fieldNameAligned', fieldAligned.get('fieldName'));
                }
            });

            this.get('data').forEach(function (line) {
                var currentSubject = _subjectImport.default.create({ mode: 'insert', id: null });

                if (Ember.isEmpty(line) || line.length === 1 && Ember.isEmpty(line[0])) {
                    // do nothing with an empty line
                } else {
                    var fields = line;
                    var data = {};
                    sortedFields.forEach(function (field) {
                        var fieldName = field.get('fieldNameAligned').camelize();
                        if (fieldName === 'id') {
                            // match proposed, do something with the id
                            currentSubject.set('id', fields[field.get('orderInFile')].trim());
                        } else {
                            var value = fields[field.get('orderInFile')];
                            data[fieldName] = Ember.isPresent(value) ? value.trim() : null;
                        }
                    });
                    // store full data set for the new/existing subject
                    currentSubject.set('data', data);

                    // check for existing record based on ID
                    // support name based search?
                    if (!Ember.isEmpty(currentSubject.get('id'))) {
                        currentSubject.set('mode', 'Confirming Match');

                        // this needs to be aware of the current case
                        _this2.store.findRecord('subject', currentSubject.get('id'), {
                            matters_id: _this2.get('model.matter.id')
                        }).then(function (record) {
                            currentSubject.set('mode', 'Update Existing');
                            currentSubject.set('current', record);
                            partialSubjects.pushObject(currentSubject);
                        }).catch(function () {
                            currentSubject.set('id', undefined);
                            currentSubject.set('mode', 'insert');
                            partialSubjects.pushObject(currentSubject);
                        });
                    } else {
                        partialSubjects.pushObject(currentSubject);
                    }
                }
            });
            this.set('partialSubjects', partialSubjects);
        },


        actions: {
            /**
             * increment the step and run any required logic
             */
            incStep: function incStep(formerStep) {
                var currentStep = formerStep + 1;
                if (currentStep === 2) {
                    this.readFile();
                    return;
                }
                if (currentStep === 3) {
                    this.previewSubjects();
                }
                if (currentStep === 4) {
                    this.importSubjects();
                }

                // basically start over
                if (currentStep === 5) {
                    this.set('currentStep', 1);
                    this.set('numberOfSubjects', null);
                    this.set('processedSubjects', []);
                    this.set('fields', []);
                    this.set('data', []);
                    return;
                }

                this.set('currentStep', currentStep);
            },


            /**
             * decrement the step and run any required logic
             */
            decStep: function decStep(formerStep) {
                this.set('currentStep', formerStep - 1);
            },


            /**
             * With the contents read from the file
             * create the subjects and send to the api
             */
            importSubjects: function importSubjects() {
                var _this3 = this;

                this.set('isImportingSubjects', true);
                var promises = [];
                var partialSubjects = this.get('partialSubjects');
                // either POST or PUT records depending on the mode

                partialSubjects.forEach(function (subject) {
                    var modSubject = null;
                    if (subject.get('mode') === 'insert') {
                        modSubject = _this3.store.createRecord('subject', subject.get('data'));
                        modSubject.set('matter', _this3.get('model.matter'));
                        _this3.translateSubjectType(modSubject);

                        promises.pushObject(modSubject.save().then(function () {
                            subject.set('result', 'Success');
                        }).catch(function () {
                            subject.set('result', 'Failure');
                            _this3.loadError(modSubject);
                            subject.set('errors', _this3.get('validationList'));
                        }));

                        // promises.pushObject(newSubject.save());
                    } else {
                        // update!
                        modSubject = _this3.store.peekRecord('subject', subject.get('id'));
                        var data = subject.get('data');
                        Object.keys(subject.get('data')).forEach(function (key) {
                            modSubject.set(key, data[key]);
                        });
                        _this3.translateSubjectType(modSubject);

                        promises.pushObject(modSubject.patch({
                            subject: data
                        }).then(function () {
                            subject.set('result', 'Success');
                        }).catch(function (reason) {
                            subject.set('result', 'Failure');
                            _this3.loadError(modSubject);
                            subject.set('errors', _this3.get('validationList'));
                        }));
                    }
                });
                Ember.RSVP.all(promises).finally(function () {
                    // when all are processed, turn off the spinner
                    _this3.set('isImportingSubjects', false);
                    _this3.set('currentStep', 4);
                });
            },


            /**
             * action sent by the sortable component to re-order the list of fields
             */
            reorderFields: function reorderFields(fields) {
                var order = 1;
                fields.map(function (f) {
                    f.set('order', order++);
                });
            }
        }
    });
});