define('tm3/pods/accounting/billing/create-batch/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route', 'moment'], function (exports, _route, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'invoice-batch',

        queryParams: {
            matterType: {
                refreshModel: true
            },
            cutDate: {
                refreshModel: true
            }
        },

        // the name of the current controller since I don't know how to auto detect
        controllerName: 'accounting.billing.create-batch',

        // ask for additional data to be sideloaded
        model: function model(params) {
            delete params.selectedMatterType;

            params.with = 'invoices';

            var queryObj = {};
            if (params.matterType) {
                queryObj.matter_type = params.matterType;
            }

            if (params.cutDate) {
                queryObj.cut_date = params.cutDate;
            }

            return Ember.RSVP.hash({
                model: this.store.query('eligible-billable', queryObj),
                matterTypes: this.store.query('matter-type', {}),
                params: params
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('matterTypes', resolved.matterTypes);
            controller.send('checkAll', false);
            controller.send('changeFilter', resolved.matterTypes.findBy('prefix', resolved.params.matterType));
            if (resolved.params.cutDate) {
                controller.set('jsCutDate', (0, _moment.default)(resolved.params.cutDate, 'YYYY-MM-DD').toDate());
            }
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('invoiceBatchName', null);
            }
        }
    });
});