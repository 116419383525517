define('tm3/pods/components/contacts-list/table-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var reads = Ember.computed.reads;
    exports.default = Ember.Component.extend({
        newItemAction: reads('titleParams.newItemAction')
    });
});