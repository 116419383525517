define('tm3/mixins/sorted-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        // What column is this field on the source file
        orderInFile: 0,

        // Order designated by the user through the UI
        order: 0,

        // Internal field name used to map the field on file with the field on system
        fieldName: '',

        // Used only by the system field that has a readable name
        fieldDisplay: '',

        // for file fields, show what system field is related to
        fieldNameAligned: '',

        // show a mark for required fields
        isRequired: false
    });
});