define('tm3/pods/components/subjects/edit-multiple/component', ['exports', 'tm3/pods/components/subjects/edit-multiple/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        notify: Ember.inject.service(),

        selectedRows: null,

        fieldsToEdit: Ember.Object.create(),

        actions: {
            toggleEditMultipleSubjectsModal: function toggleEditMultipleSubjectsModal() {
                var selectedRows = this.get('selectedRows');

                if (selectedRows.length > 0) {
                    this.set('showEditMultiple', true);
                } else {
                    this.get('notify').error('You must selected at least one subject.');
                }
            }
        }
    });
});