define('tm3/pods/matter/report-notes/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        reportBuilder: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        actions: {
            exportNotes: function exportNotes() {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'pdf_export',
                            format: 'pdf',
                            style: 'misc'
                        },
                        custom: {
                            html: window.btoa(unescape(encodeURIComponent(Ember.$('#report-notes').html()))),
                            header_text: this.get('currentMatter.matter.personOfInterest'),
                            include_footer: this.get('includeFooter'),
                            footer_center: '[page]'
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            }
        }
    });
});