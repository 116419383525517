define('tm3/mixins/case-management/info/admin/fields-controller', ['exports', 'tm-common/mixins/crud/error', 'tm-common/utils/inner-join', 'tm3/utils/case-workflow'], function (exports, _error, _innerJoin, _caseWorkflow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_error.default, {
        notify: Ember.inject.service(),
        breadCrumb: 'Fields',
        isLoading: false,

        renderTypes: [Ember.Object.create({ value: 'text', display: 'Text Input' }), Ember.Object.create({
            value: 'textWithCaseNote',
            display: 'Text Input + Case Note'
        }), Ember.Object.create({ value: 'longtext', display: 'Text Area' }), Ember.Object.create({
            value: 'longtextWithCaseNote',
            display: 'Text Area + Case Note'
        }), Ember.Object.create({ value: 'date', display: 'Date' }), Ember.Object.create({ value: 'enum', display: 'Select Box' }), Ember.Object.create({ value: 'hyperlink', display: 'Hyperlink' }), Ember.Object.create({ value: 'readonly', display: 'Read Only' })],

        compiledList: [Ember.Object.create({ value: 'budget_notes', display: 'Case Budget Notes' }), Ember.Object.create({ value: 'report_notes', display: 'Case Report Notes' }), Ember.Object.create({
            value: 'related_budget_notes',
            display: 'Related Budget Notes'
        })],

        /**
         * This computed property is used to simulate the left
         * join with matter_field_defaults, so it show first
         * the matter_fields from that matter and concatenate the
         * results with the matterFieldDefaults that are not
         * already on the first list, set the right order
         * and create new (unsaved) 'matter-field' records
         * from the 'matter-field-default' record.
         */
        // original page: fields
        unifiedMatterFields: Ember.computed('collectionFields', 'collectionFieldDefaults', function () {
            var _this = this;

            var collectionFields = this.get('collectionFields');
            var values = collectionFields.map(function (field) {
                return field.get('value');
            });
            var collectionFieldDefaults = this.get('collectionFieldDefaults');
            var bigWeight = collectionFields.get('length') + 1;
            return (0, _innerJoin.default)(collectionFieldDefaults.toArray(), collectionFields.toArray(), function (fieldDefault, field) {
                if (fieldDefault.get('value') === field.get('value')) {
                    field.set('enabled', 1);
                    return field;
                }
            }).concat(collectionFieldDefaults.filter(function (fieldDefault) {
                // Add the defaults that are not in matter_fields
                return !values.includes(fieldDefault.get('value'));
            }).map(function (fieldDefault) {
                if (fieldDefault.get('weight') === null) {
                    fieldDefault.set('weight', bigWeight++);
                }
                return fieldDefault;
            }).map(function (fieldDefault) {
                return _this.store.createRecord('collection-field', {
                    value: fieldDefault.get('value'),
                    display: fieldDefault.get('display'),
                    viewable: 0,
                    weight: fieldDefault.get('weight'),
                    columnWidth: fieldDefault.get('columnWidth'),
                    renderType: fieldDefault.get('renderType'),
                    renderList: fieldDefault.get('renderList'),
                    collection: _this.get('collection'),
                    dashboardViewable: fieldDefault.get('dashboardViewable')
                });
            }));
        }),

        // original page: matter-fields
        unifiedMatterColumns: Ember.computed('matterColumns', 'collectionHasMatterColumns', function () {
            var _this2 = this;

            var collection = this.get('collection');
            var matterColumns = this.get('matterColumns');
            var collectionHasMatterColumns = this.get('collectionHasMatterColumns');

            // remove newly created matter columns (during previous render)
            this.store.peekAll('collection-has-matter-column').forEach(function (mf) {
                if (mf.get('isNew')) {
                    mf.unloadRecord();
                }
            });

            var columnValues = collectionHasMatterColumns.map(function (chmc) {
                return chmc.get('value');
            });
            var bigWeight = collectionHasMatterColumns.get('length') + 1;

            var fieldList = [];
            matterColumns.forEach(function (fieldDefault) {
                if (!columnValues.includes(fieldDefault.get('value'))) {
                    var weight = bigWeight++;
                    fieldList.pushObject(_this2.store.createRecord('collection-has-matter-column', {
                        value: fieldDefault.get('value'),
                        display: fieldDefault.get('display'),
                        weight: weight,
                        columnWidth: 80,
                        enabled: 0,
                        collection: collection,
                        matterColumn: fieldDefault
                    }));
                }
            });

            return (0, _innerJoin.default)(matterColumns.toArray(), collectionHasMatterColumns.toArray(), function (fieldDefault, field) {
                if (fieldDefault.get('value') === field.get('value')) {
                    field.set('enabled', 1);
                    field.set('collection', collection);
                    field.set('matterColumn', fieldDefault);
                    return field;
                }
            }).concat(fieldList);
        }),

        // original page: workflows (on route)
        workflowFields: Ember.computed('workflows', function () {
            var _this3 = this;

            var workflows = [];
            this.get('workflows').forEach(function (workflow) {
                // reset default values
                // they will be modified if a matching matter workflow is found
                var relatedCollectionWorkflow = null;

                // look for a matching matter's record
                _this3.get('collectionHasWorkflows').forEach(function (collectionWorkflow) {
                    var relatedWorkflow = collectionWorkflow.get('workflow');
                    if (relatedWorkflow.get('id') === workflow.get('id')) {
                        relatedCollectionWorkflow = collectionWorkflow;
                    }
                });

                var workflowObject = _caseWorkflow.default.create({
                    parent: workflow,
                    child: relatedCollectionWorkflow
                });
                workflows.pushObject(workflowObject);
            });
            return workflows;
        }),

        allFields: Ember.computed('unifiedMatterFields', 'unifiedMatterColumns', 'workflowFields', function () {
            var allFields = [];

            this.get('unifiedMatterFields').forEach(function (umf) {
                allFields.pushObject(Ember.Object.create({
                    fieldObject: umf,
                    type: 'matterField',
                    display: umf.get('display'),
                    enabled: umf.get('isEnabledCase') ? 1 : 0,
                    weight: umf.get('weight'),
                    columnWidth: umf.get('columnWidth')
                }));
            });
            this.get('unifiedMatterColumns').forEach(function (umc) {
                allFields.pushObject(Ember.Object.create({
                    fieldObject: umc,
                    type: 'matterColumn',
                    display: umc.get('display'),
                    enabled: umc.get('enabled') ? 1 : 0,
                    weight: umc.get('weight'),
                    columnWidth: umc.get('columnWidth')
                }));
            });
            this.get('workflowFields').forEach(function (wf) {
                allFields.pushObject(Ember.Object.create({
                    fieldObject: wf,
                    type: 'workflowField',
                    display: wf.get('parent.name'),
                    enabled: wf.get('isEnabled') ? 1 : 0,
                    weight: wf.get('child.weight'),
                    columnWidth: wf.get('child.columnWidth')
                }));
            });

            return allFields;
        }),

        sortCriteria: ['enabled:desc', 'weight'],
        sortedFields: Ember.computed.sort('allFields', 'sortCriteria'),

        /**
         * if the field to save is now enabled means that we need
         * to remove the record from 'matter-field'
         * if not just send to save to the server
         */
        saveMatterField: function saveMatterField(field, enabled) {
            var _this4 = this;

            if (!enabled) {
                if (field.get('value').indexOf('custom') >= 0 && field.get('renderType') !== 'computed' && field.get('value').capitalize() !== field.get('display')) {
                    if (!window.confirm('Warning, by removing this custom field, you will lose the name of the custom field (it will revert to "' + field.get('value').capitalize() + '")')) {
                        return;
                    }
                }
                field.destroyRecord().then(function () {
                    _this4.get('notify').success('Field Removed');
                    _this4.send('fetchModel');
                }, function () {
                    _this4.validationReport(field);
                });
            } else {
                field.save().then(function () {
                    _this4.get('notify').success('Field Saved');
                    _this4.send('fetchModel');
                }, function () {
                    _this4.validationReport(field);
                });
            }
        },
        saveWorkflowField: function saveWorkflowField(workflow) {
            var _this5 = this;

            var collectionHasWorkflow = workflow.get('child');

            if (Ember.isEmpty(collectionHasWorkflow)) {
                // create record in API first
                var chw = this.store.createRecord('collection-has-workflow', {
                    workflow: workflow.get('parent'),
                    collection: this.get('collection'),
                    weight: 1,
                    columnWidth: 80,
                    enabled: 1
                });

                chw.save().then(function (data) {
                    _this5.get('notify').success('Enable workflow for case.');
                    _this5.send('fetchModel');
                }, function (reason) {
                    _this5.validationReport(chw);
                });
            } else {
                collectionHasWorkflow.destroyRecord().then(function () {
                    _this5.send('fetchModel');
                });
            }
        },


        actions: {
            save: function save(field) {
                var type = field.get('type');
                if (type === 'matterField' || type === 'matterColumn') {
                    this.saveMatterField(field.get('fieldObject'), field.get('enabled'));
                } else {
                    this.saveWorkflowField(field.get('fieldObject'));
                }
            },

            /**
             * take in an updated workflow and save record to api
             * @param workflow
             */
            updateCollectionHasWorkflow: function updateCollectionHasWorkflow(workflow) {
                var _this6 = this;

                var chw = workflow.get('child');

                if (chw.get('enabled') < 1) {
                    this.saveWorkflowField(workflow);
                    this.get('notify').warning('All fields disabled for workflow, so workflow has been disabled.');
                } else {
                    chw.save().then(function (data) {
                        _this6.get('notify').success('Change Saved');
                    }, function (reason) {
                        _this6.validationReport(chw);
                    });
                }
            },


            /**
             * just get the new array of fields already sorted
             * and update the weight on all of them and save
             * waiting to all promises fullfill in order to show
             * the success notify
             */
            reorderFields: function reorderFields(sortedFields) {
                var _this7 = this;

                var weight = 0;
                var updates = [];
                this.set('isLoading', true);
                this.get('notify').warning('Saving new order...');
                sortedFields.forEach(function (f) {
                    var field = f.get('fieldObject');
                    switch (f.get('type')) {
                        case 'matterField':
                            if (field.get('isEnabledCase')) {
                                field.set('weight', weight++);
                                updates.pushObject(field.save());
                            }
                            break;

                        case 'matterColumn':
                            if (field.get('enabled')) {
                                field.set('weight', weight++);
                                updates.pushObject(field.save());
                            }
                            break;

                        case 'workflowField':
                            var collectionHasWorkflow = field.get('child');
                            if (field.get('isEnabled')) {
                                collectionHasWorkflow.set('weight', weight++);
                                updates.pushObject(collectionHasWorkflow.save());
                            }
                            break;
                    }
                });

                Ember.RSVP.all(updates).then(function () {
                    _this7.get('notify').success('Fields Order Saved');
                    _this7.send('fetchModel');
                    _this7.set('isLoading', false);
                });
            }
        }
    });
});