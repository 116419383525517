define('tm3/pods/components/accounting/billing/invoices/qty-cut-edit/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['qty-cut-inline-edit'],
        qty: null,
        cut: null,
        tempQty: null,
        tempCut: null,
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('tempQty', parseFloat(this.get('qty')));
            this.set('tempCut', parseFloat(this.get('cut')));
        },

        actions: {
            save: function save() {
                var qty = this.get('tempQty');
                var cut = this.get('tempCut');
                this.sendAction('save', qty, cut);
            },
            cancel: function cancel() {
                this.sendAction('cancel');
            }
        }
    });
});