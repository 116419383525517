define('tm3/pods/tasks/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route', 'tm3/config/environment'], function (exports, _route, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        queryParams: {
            createdById: {
                refreshModel: true
            },
            assignedToId: {
                refreshModel: true
            },
            noDue: {
                refreshModel: true
            },
            dueFrom: {
                refreshModel: true
            },
            dueTo: {
                refreshModel: true
            },
            county: {
                refreshModel: true
            },
            city: {
                refreshModel: true
            },
            state: {
                refreshModel: true
            },
            matterTypeId: {
                refreshModel: true
            },
            onlyUrgent: {
                refreshModel: true
            },
            showCompletedCases: {
                refreshModel: true
            },
            completedOnly: {
                refreshModel: true
            },
            showWaiting: {
                refreshModel: true
            }
        },

        modelName: 'task',
        controllerName: 'tasks',

        model: function model(params) {
            if (Ember.typeOf(params.assignedToId) === 'string') {
                params.assignedToId = JSON.parse(params.assignedToId);
            }
            if (Ember.typeOf(params.createdById) === 'string') {
                params.createdById = JSON.parse(params.createdById);
            }
            var paramsTmp = Object.assign({}, params);
            if (Ember.isPresent(params.assignedToId) && params.assignedToId !== 'null') {
                params.assigned_to_id = params.assignedToId.uniq();
            }
            if (Ember.isPresent(params.createdById) && params.createdById !== 'null') {
                params.created_by_id = params.createdById.uniq();
            }
            if (Ember.isPresent(params.noDue)) {
                params.no_due = params.noDue;
            } else {
                params.no_due = false;
            }
            if (Ember.isPresent(params.dueFrom)) {
                params.due_from = params.dueFrom;
            }
            if (Ember.isPresent(params.dueTo)) {
                params.due_to = params.dueTo;
            }
            if (Ember.isPresent(params.matterTypeId) && params.matterTypeId !== 'null') {
                params.matter_type = params.matterTypeId;
            }
            if (Ember.isPresent(params.onlyUrgent)) {
                params.only_urgent = params.onlyUrgent;
            } else {
                params.only_urgent = false;
            }
            if (params.completedOnly) {
                params.completed_only = true;
            } else {
                params.completed_only = false;
            }
            if (params.showWaiting) {
                params.waiting_only = true;
            } else {
                params.waiting_only = false;
            }
            if (params.showCompletedCases) {
                params.show_completed_cases = true;
            } else {
                params.show_completed_cases = false;
            }

            delete params.createdById;
            delete params.assignedToId;
            delete params.noDue;
            delete params.dueFrom;
            delete params.dueTo;
            delete params.matterTypeId;
            delete params.onlyUrgent;
            delete params.completedOnly;
            delete params.showWaiting;
            delete params.showCompletedCases;

            // this page excludes workflow type tasks
            params.exclude_workflow = true;
            params.only_active_cases = true;

            // pending show only tasks to the user by case that has access to

            return Ember.RSVP.hash({
                tasks: this._super(params),
                employees: this.store.query('employee', {
                    status: 'Active',
                    sort: 'last_name,first_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer']
                }),
                matterTypes: this.store.query('matter-type', {}),
                states: this.store.query('db-tag', {
                    parent_id: _environment.default.APP.tags.stateCategory
                }),
                params: paramsTmp
            });
        },

        extraEmployeeOption: Ember.ArrayProxy.create({
            content: [{ id: 'null', fullName: 'All Unassigned' }]
        }),

        setupController: function setupController(controller, model) {
            var _this = this;

            // initialize data for table-pager/route
            this._super(controller, model.tasks);
            controller.set('employees', model.employees);
            controller.set('matterTypes', model.matterTypes);
            controller.set('states', model.states);
            controller.setProperties(model.params);
            controller.set('matterType', model.matterTypes.findBy('id', model.params.matterTypeId));
            controller.set('selectedState', model.params.state);
            var assignedIds = model.params.assignedToId;
            if (assignedIds && Array.isArray(assignedIds) && assignedIds.length) {
                var assignedList = assignedIds.uniq().map(function (id) {
                    if (id !== 'null' && parseInt(id, 10) > 0) {
                        return model.employees.findBy('id', id);
                    } else {
                        return _this.get('extraEmployeeOption.firstObject');
                    }
                });
                controller.set('assignedTo', assignedList);
            }
            var creatorIds = model.params.createdById;
            if (creatorIds && Array.isArray(creatorIds) && creatorIds.length) {
                var creatorList = creatorIds.uniq().map(function (id) {
                    if (id !== 'null' && parseInt(id, 10) > 0) {
                        return model.employees.findBy('id', id);
                    } else {
                        return _this.get('extraEmployeeOption.firstObject');
                    }
                });
                controller.set('createdBy', creatorList);
            }
        }
    });
});