define('tm3/pods/matter/report-wizard/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        model: function model() {
            return this.store.findRecord('matter', this.get('currentMatter.id'));
        }
    });
});