define('tm3/components/field-is-visible', ['exports', 'tm-common/components/field-is-visible'], function (exports, _fieldIsVisible) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fieldIsVisible.default;
    }
  });
});