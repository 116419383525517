define('tm3/pods/matter/admin/employees/route', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment'], function (exports, _error2, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error2.default, {
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),
        notify: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        model: function model(params) {
            var matterId = this.get('currentMatter.id');

            return Ember.RSVP.hash({
                matter: this.store.findRecord('matter', matterId),
                employees: this.store.query('matter-has-user', {
                    matter_id: matterId,
                    user_type: 'employee'
                }),
                groups: this.store.query('group', {
                    group_type: _environment.default.APP.groupTypes.employee
                }),
                case_contact_types: this.get('lists').getListForGroup('case_contact_types')
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.matter);

            controller.set('matter', resolved.matter);
            controller.set('employees', resolved.employees);
            controller.set('groups', resolved.groups);
            controller.set('caseContactTypes', resolved.case_contact_types);
        },


        actions: {
            /**
             * Saves the matter-has-user record
             */
            save: function save(employee) {
                var _this = this;

                var finishMessage = employee.get('isNew') ? 'New employee has been added to the case!' : 'Employee has been updated on the case!';
                var matter = this.controller.get('matter');
                employee.set('matter', matter);
                employee.set('userType', 'employee');

                employee.save().then(function (e) {
                    _this.controller.set('showModal', false);
                    _this.get('notify').success(finishMessage);
                    _this.refresh();
                }, function (_error) {
                    _this.validationReport(employee);
                }).catch(function (reason) {
                    _this.handleXHR(reason);
                });
            },


            /**
             * Removes the record matter-has-user
             */
            delete: function _delete(employee) {
                var _this2 = this;

                employee.destroyRecord().then(function () {
                    _this2.get('notify').success('Successfully removed from this case!');
                    _this2.refresh();
                });
            }
        }
    });
});