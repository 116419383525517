define('tm3/pods/matter/admin/accounts/list/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],
        model: function model(params) {
            var matterId = this.get('currentMatter.id');
            // params.matter_id
            return Ember.RSVP.hash({
                matter: this.store.findRecord('matter', matterId),
                accounts: this.store.query('firm-has-matter', {
                    matter_id: matterId,
                    with: 'firm_has_matter_has_taxes'
                }),
                invoice_groups: this.store.query('invoice-group', { matter_id: matterId }),
                taxRules: this.store.findAll('tax-rule'),
                lists: this.get('lists').fetchMultipleLists(['States'])
            });
        },
        accountTaxesAreValid: function accountTaxesAreValid() {
            var sum = 0;
            var firmHasMatterHasTaxes = this.controller.get('account.firmHasMatterHasTaxes');
            if (firmHasMatterHasTaxes.get('length')) {
                firmHasMatterHasTaxes.reduce(function (previousValue, item) {
                    sum = sum + parseFloat(item.get('split'));
                }, 0);
                return sum >= 1 && sum <= 100;
            }
            return true;
        },
        addRelatedObjectSaveOrDeleteToPromises: function addRelatedObjectSaveOrDeleteToPromises(promises, oldIds, relatedList, recordName, account, isEdit) {
            var _this = this;

            var oldIdsList = this.controller.get(oldIds);
            var relatedObjects = account.get(relatedList);
            if (isEdit) {
                var currentRelatedObjectsIds = relatedObjects.mapBy('id').without(null);
                oldIdsList.forEach(function (id) {
                    if (!currentRelatedObjectsIds.includes(id)) {
                        promises.push(_this.store.peekRecord(recordName, id).destroyRecord());
                    }
                });
            }

            relatedObjects.forEach(function (obj) {
                promises.push(obj.save());
            });
        },


        actions: {
            save: function save(account) {
                var _this2 = this;

                var isEdit = !account.get('isNew');
                if (!this.accountTaxesAreValid()) {
                    this.get('notify').warning('Warning, the sum of the tax rule splits exceeds 100% or is less than 1%', {
                        closeAfter: null
                    });
                }

                account.set('matter', this.controller.get('model.matter'));
                account.save().then(function () {
                    var promises = [];

                    _this2.addRelatedObjectSaveOrDeleteToPromises(promises, 'fhmhtIds', 'firmHasMatterHasTaxes', 'firm-has-matter-has-tax', account, isEdit); // tax

                    Ember.RSVP.all(promises).then(function () {
                        _this2.get('notify').success('New account added!');
                        _this2.controller.set('account', undefined);
                        _this2.refresh();
                    });
                }).catch(function () {
                    _this2.validationReport(account.get('content'));
                });
            },
            cancel: function cancel(account) {
                account.rollback();
                this.controller.set('account', undefined);
                this.controller.set('showCreateAccount', false);
            },
            delete: function _delete(account) {
                var _this3 = this;

                // Check if firm is invoiced
                account.invoiceCount().then(function (result) {
                    if (result.count > 0) {
                        _this3.get('notify').error('The account has been issued invoices for this Case, you can not remove it.');
                        return;
                    }

                    account.destroyRecord().then(function () {
                        _this3.get('notify').success('Successfully deleted Account entry!');
                    }).catch(function (error) {
                        // Firefox and Chrome are having issues with the delete and parse result
                        if (error.errors) {
                            _this3.get('notify').error(error.errors[0].additional_info.details);
                        } else {
                            _this3.get('notify').error('There was a problem deleting the account');
                        }
                    });
                });
            }
        }
    });
});