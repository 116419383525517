define('tm3/pods/crm/deals/list/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        reportBuilder: Ember.inject.service(),
        queryParams: ['selectedStages'],

        // query param for stages to report on
        selectedStages: ['Connect', 'Discover', 'Develop', 'Propose'],
        selectedEmployees: [],

        // hold a list of all employee in the current group of deals
        employeeList: [],

        // build a list of active stages
        activeStages: null,
        //
        stageList: null,
        // count the number of stages
        stageCount: 0,
        // used to format pipeline
        columnStyle: 'width: 100%; float: left;',

        dealFormTitle: null,
        editDeal: null,

        /**
         * observe changes in stage list and update employeeList
         */
        employeeFilter: Ember.observer('stageList', function () {
            var stageList = this.get('stageList');
            var employeeList = [];
            stageList.forEach(function (stage) {
                if (stage.isActive) {
                    // now loop through all deals plucking out the employee
                    stage.get('dealList').forEach(function (deal) {
                        var employee = deal.get('employee');
                        if (!employeeList.findBy('id', employee.get('id'))) {
                            employeeList.pushObject(employee);
                        }
                    });
                }
            });
            this.set('employeeList', employeeList);
        }),

        /**
         * observe changes to stages and deals
         */

        updateStageList: Ember.observer('stages', 'deals.@each.priority', 'activeStages', 'selectedEmployees', function () {
            var activeStages = this.get('activeStages');
            var stageList = [];
            var stageCount = 0;
            var selectedEmployeeIds = this.get('selectedEmployees').map(function (e) {
                return e.get('id');
            });

            var stages = this.get('stages');
            var deals = this.get('deals');

            if (Ember.isEmpty(stages) || Ember.isEmpty(deals)) {
                return;
            }

            // build a list of deals for each returned stage
            stages.forEach(function (stage) {
                // reset the deal list
                var dealList = Ember.A();
                deals.forEach(function (deal) {
                    if (deal.get('stage') === stage.get('value')) {
                        if (selectedEmployeeIds.length === 0 || selectedEmployeeIds.includes(deal.get('employee.id'))) {
                            deal.set('isActive', true);
                        } else {
                            deal.set('isActive', false);
                        }
                        dealList.push(deal);
                    }
                });

                // figure out if this stage is active and to be displayed on screen
                stage.set('isActive', false);
                activeStages.forEach(function (item) {
                    if (item === stage.get('display')) {
                        stage.set('isActive', true);
                        stageCount += 1;
                    }
                });

                // load the full list of deals for the given stage
                stage.set('dealList', dealList.sortBy('fallBack', 'priority'));

                // add to the list fully prepared stages
                stageList.push(stage);
            });

            this.set('stageList', stageList.sortBy('weight'));
            this.set('stageCount', stageCount);

            var width = 100;
            width = Math.floor(100 / stageCount);
            this.set('columnStyle', 'width: ' + width + '%; float: left; padding: 0 10px; position: relative;');
        }),

        actions: {
            addStage: function addStage(add) {
                var stageList = this.get('stageList');
                var activeStageList = [add];
                stageList.forEach(function (stage) {
                    if (stage.isActive) {
                        activeStageList.push(stage.get('display'));
                    }
                });
                this.set('selectedStages', activeStageList);
            },
            removeStage: function removeStage(remove) {
                var stageList = this.get('stageList');
                var activeStageList = [];
                stageList.forEach(function (stage) {
                    if (stage.isActive && remove !== stage.get('display')) {
                        activeStageList.push(stage.get('display'));
                    }
                });
                this.set('selectedStages', activeStageList);
            },
            sortFinished: function sortFinished(sortedDeals) {
                var _this = this;

                var promises = [];
                var order = 1;
                sortedDeals.forEach(function (d) {
                    d.set('priority', order++);
                    promises.pushObject(d.save());
                });
                Ember.RSVP.all(promises).then(function () {
                    _this.get('notify').success('Priority of the deals has been changed');
                });
            },
            exportAsPDF: function exportAsPDF() {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'pdf_export',
                            format: 'pdf',
                            style: 'misc'
                        },
                        custom: {
                            html: window.btoa(unescape(encodeURIComponent(Ember.$('#deals-list').html())))
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            },
            toggleDeals: function toggleDeals() {
                this.get('deals').setEach('isExpanded', this.get('expandAll'));
            },
            addDeal: function addDeal() {
                this.set('editDeal', this.store.createRecord('deal', {}));
                this.set('dealFormTitle', 'New Opportunity');
            },
            editDeal: function editDeal(item) {
                this.set('editDeal', item);
                this.set('dealFormTitle', 'Edit Opportunity');
            },
            closeDealForm: function closeDealForm() {
                var editDeal = this.get('editDeal');
                if (editDeal.get('isNew')) {
                    editDeal.destroy();
                } else {
                    editDeal.rollbackAttributes();
                }
                this.set('editDeal', null);
            },
            saveDeal: function saveDeal() {
                var _this2 = this;

                var editDeal = this.get('editDeal');
                editDeal.save().then(function () {
                    _this2.get('notify').success('Opportunity Saved');
                    _this2.set('editDeal', null);
                    _this2.send('refresh');
                }, function () {
                    _this2.validationReport(editDeal);
                    _this2.get('notify').error('Error while saving Opportunity');
                });
            }
        }
    });
});