define('tm3/pods/components/matter/admin/reports/employee-time/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didReceiveAttrs: function didReceiveAttrs() {
            Ember.set(this.get('userData'), 'expanded', true);
        },


        totalService: Ember.computed('billableGroups', 'isMoney', function () {
            var time = 0;
            if (this.get('isMoney')) {
                var billableGroupsDict = this.get('billableGroups');
                for (var key in billableGroupsDict) {
                    var billableGroup = billableGroupsDict[key];
                    time += parseFloat(billableGroup['time']['total']);
                }
            }
            return time;
        }),
        totalExpenses: Ember.computed('billableGroups', 'isMoney', function () {
            var expenses = 0;
            if (this.get('isMoney')) {
                var billableGroupsDict = this.get('billableGroups');
                for (var key in billableGroupsDict) {
                    var billableGroup = billableGroupsDict[key];
                    billableGroup['expenses'].forEach(function (exp) {
                        expenses += parseFloat(exp['total']);
                    });
                }
            }
            return expenses;
        }),
        actions: {
            expandEmployee: function expandEmployee() {
                Ember.set(this.get('userData'), 'expanded', true);
                this.sendAction('collapsedEmployeeChanged');
            },
            collapseEmployee: function collapseEmployee() {
                Ember.set(this.get('userData'), 'expanded', false);
                this.sendAction('collapsedEmployeeChanged');
            }
        }
    });
});