define('tm3/pods/me/time/info/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        permissions: Ember.inject.service(),
        isCaseAdmin: Ember.computed(function () {
            return this.get('permissions').check(['Case Admin']);
        }).volatile(),
        isAccountAdmin: Ember.computed(function () {
            return this.get('permissions').check(['Admin Time & Expense']);
        }).volatile(),
        matter: Ember.computed('model.billableGroup.billables.firstObject', function () {
            return this.get('model.billableGroup.billables.firstObject.matter');
        }),
        expenses: Ember.computed('model.billableGroup.billables.@each.billType', function () {
            return this.get('model.billableGroup.billables').filter(function (billable) {
                switch (billable.get('billType')) {
                    case 'Time:Expense':
                    case 'Piece:Expense':
                    case 'Expense':
                        return true;
                }
                return false;
            });
        }),
        billableTime: Ember.computed('model.billableGroup.billables.@each.billType', function () {
            return this.get('model.billableGroup.billables').filterBy('billType', 'Time').get('firstObject');
        }),

        billablePiece: Ember.computed('model.billableGroup.billables.@each.billType', function () {
            return this.get('model.billableGroup.billables').filterBy('billType', 'Piece').get('firstObject');
        }),
        isBillableTime: Ember.computed('billableTime', 'billablePiece', function () {
            return this.get('billableTime') !== undefined && this.get('billablePiece') === undefined;
        }),

        isBillablePiece: Ember.computed('billablePiece', function () {
            return this.get('billablePiece') !== undefined;
        }),
        showTimeExpense: Ember.computed('model.billableGroup', function () {
            var model = this.get('model.billableGroup');
            var type = model.get('type');
            if (type === 'Time' || type === 'Piece') {
                return true;
            }
        }),
        canApprove: Ember.computed('model.billableGroup.id', function () {
            var bg = this.get('model.billableGroup');
            return bg.get('approvalStatus') === 'Preliminary';
        }),

        actions: {
            back: function back() {
                this.transitionToRoute('me.time.list');
            },
            approve: function approve() {
                var _this = this;

                var bg = this.get('model.billableGroup');
                bg.set('approvalStatus', 'Level 1 Complete');
                bg.set('type', bg.get('type'));
                bg.save().then(function () {
                    _this.get('notify').success('Successfully approved');
                    _this.transitionToRoute('me.time.list');
                }, function () {
                    _this.get('notify').error('Error occurred while approving');
                });
            }
        }
    });
});