define('tm3/pods/accounting/billing/batches/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column', 'accounting/format-money', 'moment'], function (exports, _controller, _column, _formatMoney, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        permissions: Ember.inject.service(),
        appName: 'tm-admin',
        quickSearchField: 'name',
        quickSearch: null,

        // load pager specific variables
        columns: [_column.default.create({ displayName: 'Batch Name', fieldName: 'name', order: 0 }), _column.default.create({
            displayName: 'Status',
            fieldName: 'status',
            order: 1,
            cellComponent: 'accounting/billing/batches/info/status-cell'
        }), _column.default.create({ displayName: 'File #', fieldName: 'tmNum', order: 2 }), _column.default.create({ displayName: 'Created', fieldName: 'formattedCreatedOn', apiName: 'created_on', order: 3 }), _column.default.create({
            displayName: '# of Invoices',
            fieldName: 'invoiceCount',
            order: 4,
            disableServerInteractions: true
        }), _column.default.create({
            displayName: '$ Billed',
            fieldName: 'billedAmount',
            order: 5,
            disableServerInteractions: true
        })],
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),
        columnFields: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                switch (c.get('fieldName')) {
                    case 'tmNum':
                        return 'tm_num';
                    case 'formattedCreatedOn':
                        return 'created_on';
                    case 'invoiceCount':
                        return 'invoice_count';
                    case 'billedAmount':
                        return 'sum_billed_amount';
                    default:
                        return c.get('fieldName');
                }
            });
        }),
        // need to open single matter record
        linkPath: 'accounting.billing.batches.info.summary',

        tableTitle: 'Invoice Batches',

        sortField: '-created_on',
        with: 'invoices',

        processTableData: function processTableData(tableData) {
            tableData.forEach(function (item) {
                var invoices = item.get('invoices');
                var count = invoices.get('length');
                var billedAmount = 0;
                invoices.reduce(function (previousValue, item) {
                    billedAmount = billedAmount + item.get('billedAmount');
                }, 0);

                item.set('billedAmount', (0, _formatMoney.default)(billedAmount));
                item.set('invoiceCount', count);
            });
            return tableData;
        },


        // Add custom parameters for the table-pagination component
        titleParams: Ember.computed('columns', 'currentParams', function () {
            var allowCreate = this.get('permissions').check(['Manage Invoices']);
            return {
                icon: 'building',
                newItemClass: 'btn btn-primary btn-sm',
                newItemLabel: 'Create Invoice Batch',
                newItemLink: 'accounting.billing.create-batch',
                showNewItem: allowCreate,
                columnFields: this.get('columnFields'),
                columnNames: this.get('columnNames'),
                currentParams: this.get('currentParams'),
                entity: 'InvoiceBatch'
            };
        })
    });
});