define('tm3/pods/components/matter/notes/matter-notes-container/component', ['exports', 'tm3/pods/components/matter/notes/matter-notes-container/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        actions: {
            isNull: function isNull(parentId) {
                return parentId === null;
            }
        }
    });
});