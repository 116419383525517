define('tm3/helpers/trunc-text-by-lines', ['exports', 'tm-common/helpers/trunc-text-by-lines'], function (exports, _truncTextByLines) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _truncTextByLines.default;
    }
  });
  Object.defineProperty(exports, 'truncTextByLines', {
    enumerable: true,
    get: function () {
      return _truncTextByLines.truncTextByLines;
    }
  });
});