define('tm3/pods/me/travel/add/controller', ['exports', 'moment', 'tm3/mixins/get-available-matters'], function (exports, _moment, _getAvailableMatters) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_getAvailableMatters.default, {
        startDate: Ember.computed.alias('model.newBillableGroup.start'),
        endDate: Ember.computed.alias('model.newBillableGroup.end'),

        tooManyDays: Ember.computed.gt('diffDays', 7),
        invalidDays: Ember.computed.lte('diffDays', 0),

        dateRange: [],
        chargesByExpenseType: [],
        allCharges: [],
        deductions: [],
        isCreatingExpenses: false,

        /**
         * watch for the number of days to change and
         */
        createChargeRecords: Ember.observer('invalidDays', 'tooManyDays', 'startDate', 'endDate', function () {
            var _this = this;

            if (!this.get('startDate') || !this.get('endDate') || this.get('tooManyDays') || this.get('invalidDays')) {
                this.set('dateRange', []);
                this.set('chargesByExpenseType', []);
                return;
            }

            var daysToProcess = (0, _moment.default)(this.get('endDate')).diff((0, _moment.default)(this.get('startDate')), 'd') + 1;

            var dateRange = [];
            var chargesByExpenseType = [];
            this.set('allCharges', []);

            var initialDay = (0, _moment.default)(this.get('startDate'));
            for (var d = 0; d < daysToProcess; d++) {
                var date = initialDay.clone();
                dateRange.pushObject({ date: date });
                initialDay.add(1, 'day');
            }

            this.get('model.expenseTypes').forEach(function (expenseType) {
                var rowObj = {};
                rowObj.expenseType = expenseType;
                rowObj.charges = [];
                var initialDay = (0, _moment.default)(_this.get('startDate'));
                for (var _d = 0; _d < daysToProcess; _d++) {
                    var _date = initialDay.clone();
                    rowObj.charges.pushObject(_this.store.createRecord('charge', {
                        date: _date,
                        billableType: expenseType
                    }));
                    initialDay.add(1, 'day');
                }
                chargesByExpenseType.pushObject(rowObj);
                _this.get('allCharges').pushObjects(rowObj.charges);
            });

            this.set('dateRange', dateRange);
            this.set('chargesByExpenseType', chargesByExpenseType);
        }),

        chargesObserver: Ember.observer('allCharges.@each.{qty,rate}', function () {
            var total = this.get('allCharges').map(function (charge) {
                charge.finalizeTotal();
                return charge;
            }).filter(function (charge) {
                return Ember.isPresent(charge.get('total'));
            }).reduce(function (acc, charge) {
                return acc + parseFloat(charge.get('total'));
            }, 0);
            this.set('model.newBillableGroup.total', total);
        }),

        availableMattersObserver: Ember.observer('model.newBillableGroup', 'startDate', 'endDate', function () {
            var start = this.get('startDate');
            var end = this.get('endDate');
            var startParameter = void 0,
                endParameter = void 0;
            if (Ember.isEmpty(start)) {
                startParameter = '';
            } else {
                startParameter = (0, _moment.default)(start).format('YYYY-MM-DD');
            }
            if (Ember.isEmpty(end)) {
                endParameter = '';
            } else {
                endParameter = (0, _moment.default)(end).format('YYYY-MM-DD');
            }
            this.get('getAvailableMattersTask').perform(this.get('model.newBillableGroup'), startParameter, endParameter);
        })
    });
});