define('tm3/pods/report/info/new-cases/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['year', 'state', 'timeFrame', 'recentMonths'],

        year: '',
        state: '',
        recentMonths: '',

        perspective: 'byMonth',
        timeFrame: 'year',

        lastMonths: [1, 2, 3, 6, 12],

        recentMonthsBackup: '',

        reportCustomParams: Ember.computed('year', 'state', 'recentMonths', function () {
            return {
                year: this.get('year'),
                state: this.get('state'),
                recentMonths: this.get('recentMonths')
            };
        }),

        perspectiveChanged: Ember.observer('timeFrame', function () {
            if (this.get('timeFrame') === 'year') {
                this.set('recentMonthsBackup', this.get('recentMonths'));
                this.set('recentMonths', '');
            } else {
                this.set('year', '');
                if (!this.get('recentMonths')) {
                    this.set('recentMonths', this.get('recentMonthsBackup') || 1);
                }
            }
            this.set('state', '');
            this.set('selectedState', null);
        }),

        selectStateDisabled: Ember.computed('year', 'recentMonths', function () {
            return !(this.get('recentMonths') || this.get('year'));
        }),

        stateYearObserver: Ember.observer('year', 'state', 'perspective', 'model.tableData.[]', function () {
            if (this.get('perspective') === 'byState' && this.get('year') && this.get('state') === '') {}
        }),

        componentName: Ember.computed('state', function () {
            if (this.get('state')) {
                return 'report/new-cases-report/by-state';
            } else {
                return 'report/new-cases-report/by-month';
            }
        }),

        stateObserver: Ember.observer('state', function () {
            if (this.get('state') === '') {
                this.set('selectedState', null);
            }
        }),

        timeObserver: Ember.observer('year', 'recentMonths', 'states', function () {
            var state = this.get('state');
            if ((this.get('year') !== '' || this.get('recentMonths') !== '') && state !== '' && this.get('states.length')) {
                this.set('selectedState', this.get('states').findBy('display', state));
            }
        }),

        actions: {
            yearChanged: function yearChanged(selectedYear) {
                this.set('year', selectedYear || '');
            },
            monthsChanged: function monthsChanged(selectedMonths) {
                this.set('recentMonths', selectedMonths || '');
            },
            stateChanged: function stateChanged(selectedState) {
                this.set('selectedState', selectedState);
                this.set('state', selectedState ? selectedState['display'] : '');
            }
        }
    });
});