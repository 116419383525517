define('tm3/pods/components/matter/subjects/subject-flags/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        // Color codes for different subject statuses
        // This should be in order of less priority to more priority on class names
        classNameBindings: ['hardshipLocation', 'causeLocation', 'isForeperson', 'isInactive'],

        isInactive: Ember.computed('item', function () {
            return this.get('item.inactive') === '1';
        }),
        isForeperson: Ember.computed('item', function () {
            return this.get('item.foreperson') === '1';
        }),
        hardshipLocation: Ember.computed('item', function () {
            return this.get('item.location') === 'hardship';
        }),
        causeLocation: Ember.computed('item', function () {
            return this.get('item.location') === 'cause';
        })
    });
});