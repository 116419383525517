define('tm3/pods/components/subjects/info/db-memos/memo-display/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        'data-test-component': 'subjects-info-db-memos-memo-display-item',
        showDbName: false,
        tagName: 'div'
    });
});