define('tm3/pods/case-group/edit-matter/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                collection: this.store.findRecord('collection', params.collection_id),
                collectionColumns: this.store.query('collection-column', {
                    collection_id: params.collection_id,
                    sortField: 'weight',
                    with: 'workflow'
                }),
                matter: this.store.findRecord('matter', params.matter_id),
                workflowStatuses: this.get('lists').getListForGroup('workflow_status'),
                //employees: this.store.query('employee', {
                //  with_access_to_matter: params.matter_id
                //})
                employees: this.get('store').query('employee', {
                    sortField: 'last_name,first_name',
                    role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
                    status: 'Active'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super.apply(this, arguments);

            controller.set('caseEmployees', resolved.employees);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});