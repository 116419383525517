define('tm3/pods/components/chartjs-component/component', ['exports', 'chart.js/auto'], function (exports, _auto) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        chartId: Math.floor(Math.random() * 100000),

        type: 'line',

        chart: null,
        labels: [],
        data: [],

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var chartCanvas = this.$().find('#' + this.get('chartId'));
            this.set('chart', new _auto.default(chartCanvas, {
                type: this.get('type'),
                data: {
                    labels: this.get('labels'),
                    datasets: [{
                        label: '',
                        data: this.get('data'),
                        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
                        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
                        borderWidth: 1
                    }]
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: this.get('title')
                        },
                        legend: {
                            display: false
                        }
                    },
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('chart')) {
                this.get('chart').data.datasets.data = this.get('labels');
                this.get('chart').data.labels = this.get('data');
                this.get('chart').update();
            }
        }
    });
});