define('tm3/components/document-edit-table', ['exports', 'tm-common/components/document-edit-table'], function (exports, _documentEditTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _documentEditTable.default;
    }
  });
});