define('tm3/pods/components/sc-calendar/days/component', ['exports', 'ember-power-calendar/components/power-calendar/days', 'ember-power-calendar-utils', 'moment'], function (exports, _days, _emberPowerCalendarUtils, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _days.default.extend({
        actions: {
            isInside: function isInside(day, scCalendarEvent) {
                return (0, _emberPowerCalendarUtils.isBetween)(day, (0, _moment.default)(scCalendarEvent.get('start'), 'YYYY-MM-DD'), (0, _moment.default)(scCalendarEvent.get('end'), 'YYYY-MM-DD'), 'day', true);
            }
        }
    });
});