define('tm3/pods/crm/contacts/info/merge/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var clientsInfoModel = this.modelFor('crm.contacts.info');
            var client = clientsInfoModel.model.get('firstObject');

            return Ember.RSVP.hash({
                contact: client
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, {});
            controller.set('contact', resolved.contact);
            controller.set('selectedContacts', []);
        }
    });
});