define('tm3/components/subjects/profile/modules/field-content', ['exports', 'tm-common/components/subjects/profile/modules/field-content'], function (exports, _fieldContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fieldContent.default;
    }
  });
});