define('tm3/instance-initializers/notifyfix', ['exports', 'tm3/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    // The scope of this is to allow logging capabilities for the notify service in the testing ENV
    // We can use assertions for the notifications text

    function aliasToShowAndLog(type) {
        return function (message, options) {
            var container = Ember.$('div.ember-notify-testing');
            if (container.length === 0) {
                container = Ember.$('<div class="ember-notify-testing" style="display: none;"></div>').appendTo(_environment.default.APP.rootElement || Ember.$('.ember-application')[0]);
            }
            var html = '';
            var text = '';
            if ((typeof message === 'undefined' ? 'undefined' : _typeof(message)) === 'object') {
                html = message.html || '';
                text = message.text || '';
            } else {
                text = message;
            }
            container.append('<span data-test-message=\'' + type + '\'>' + text + html);
            return this.show(type, message, options);
        };
    }
    function initialize(instance) {
        if (_environment.default.enviroment !== 'production') {
            var service = instance.lookup('service:notify');
            service.info = aliasToShowAndLog('info');
            service.success = aliasToShowAndLog('success');
            service.warning = aliasToShowAndLog('warning');
            service.alert = aliasToShowAndLog('alert');
            service.error = aliasToShowAndLog('error');
        }
    }

    exports.default = {
        name: 'notifyfix',
        initialize: initialize
    };
});