define('tm3/serializers/v-note-parent', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            if (hash.table === 'subjects') {
                hash.subject_id = hash.id;
            } else if (hash.table === 'notes') {
                hash.parentnote_id = hash.id;
            }

            return this._super(typeClass, hash, prop);
        }
    });
});