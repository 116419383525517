define('tm3/pods/admin/matter-type/info/rate-list/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),

        actions: {
            toggleMassEdit: function toggleMassEdit() {
                this.set('massEditValue', null);
                this.set('isMassEdit', !this.get('isMassEdit'));
            }
        }
    });
});