define('tm3/helpers/contact-heading', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.contactHeading = contactHeading;


    /**
     * display a formatted name, title and primary account if present
     * @param params
     * @returns {*}
     */
    function contactHeading(params /*, hash */) {
        var contact = params[0];
        var result = contact.get('lastName') + ', ' + contact.get('firstName');
        if (contact.get('middleName')) {
            result = result + ' ' + contact.get('middleName');
        }
        if (contact.get('title')) {
            result = result + ' - ' + contact.get('title');
        }
        return result;
    }

    exports.default = Ember.Helper.helper(contactHeading);
});