define('tm3/adapters/invoice', ['exports', 'tm3/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        updateRecord: function updateRecord(store, type, snapshot) {
            var data = {};
            var serializer = store.serializerFor(type.modelName);

            serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

            var id = snapshot.id;
            var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

            if (snapshot.adapterOptions && snapshot.adapterOptions.editNote) {
                url += '?editNote=' + snapshot.adapterOptions.editNote;
            }
            if (snapshot.adapterOptions && snapshot.adapterOptions.editDate) {
                url += '?editDate=' + snapshot.adapterOptions.editDate;
            }

            return this.ajax(url, 'PUT', { data: data });
        }
    });
});