define('tm3/pods/admin/task-list-templates/edit/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        adminTaskListTemplatesController: Ember.inject.controller('admin.task-list-templates'),

        validateGoodState: function validateGoodState(group) {
            // validate that exists at least one list and one task and all data is entered
            if (Ember.isEmpty(group.get('name'))) {
                this.get('notify').warning('Task template needs a name');
                return false;
            }
            if (group.get('listTemplates').filterBy('isDeleted', false).length === 0) {
                this.get('notify').warning('Please create at least one list');
                return false;
            }
            var listsWithoutTasks = 0;
            group.get('listTemplates').filterBy('isDeleted', false).forEach(function (lt) {
                listsWithoutTasks += lt.get('taskTemplates').filterBy('isDeleted', false).length === 0 ? 1 : 0;
            });
            if (listsWithoutTasks > 0) {
                this.get('notify').warning('All the lists need at least one task');
                return false;
            }
            if (group.get('listTemplates').filterBy('isDeleted', false).filter(function (lt) {
                return Ember.isEmpty(lt.get('name'));
            }).length > 0) {
                this.get('notify').warning('All the lists need a name');
                return false;
            }
            var tasksWithoutComment = 0;
            group.get('listTemplates').filterBy('isDeleted', false).forEach(function (lt) {
                tasksWithoutComment += lt.get('taskTemplates').filter(function (t) {
                    return Ember.isEmpty(t.get('comment'));
                }).length;
            });
            if (tasksWithoutComment > 0) {
                this.get('notify').warning('All the tasks need a description');
                return false;
            }
            return true;
        },


        actions: {
            createListTemplate: function createListTemplate() {
                this.store.createRecord('list-template', {
                    listTemplateGroup: this.get('model.list_template_group')
                });
            },
            removeListTemplate: function removeListTemplate(tl) {
                var self = this;
                var group = this.get('model.list_template_group');
                tl.deleteRecord();
                if (!this.validateGoodState(group)) {
                    if (tl.get('isNew')) {
                        this.send('createListTemplate');
                    } else {
                        tl.rollbackAttributes();
                    }
                    return;
                }
                tl.save().then(function removeTaskTemplate() {
                    self.get('notify').success('List removed');
                });
            },
            addTask: function addTask(listTemplate) {
                this.store.createRecord('task-template', {
                    listTemplate: listTemplate
                });
            },
            removeTask: function removeTask(task) {
                var self = this;
                var group = this.get('model.list_template_group');
                var listTemplate = task.get('listTemplate');
                task.deleteRecord();
                if (!this.validateGoodState(group)) {
                    if (task.get('isDeleted') && !task.get('hasDirtyAttributes')) {
                        this.send('addTask', listTemplate);
                    } else {
                        task.rollbackAttributes();
                    }
                    return;
                }
                task.save().then(function removeTaskTemplate() {
                    self.get('notify').success('Task removed');
                });
            },
            saveAll: function saveAll() {
                var _this = this;

                var self = this;
                var promises = Ember.A();
                var group = this.get('model.list_template_group');
                if (!this.validateGoodState(group)) {
                    return;
                }
                promises.pushObject(group.save().then(function () {
                    group.get('listTemplates').forEach(function (lt) {
                        lt.save().then(function saveListTemplate(response) {
                            lt.get('taskTemplates').forEach(function (task) {
                                promises.pushObject(task.save().then(null, function () {
                                    self.validationReport(task);
                                    throw new _error.default('Invalid task data');
                                }));
                            });
                        }, function failSaveListTemplate() {
                            self.validationReport(lt);
                            throw new _error.default('Invalid list data');
                        });
                    });
                }, function (error) {
                    _this.validationReport(group);
                    throw error;
                }));

                Ember.RSVP.all(promises).then(function finishedSaveTaskTemplate() {
                    self.get('notify').success('Task template was saved successfully');
                    var matterTypeId = group.get('matterType.id');
                    if (!Ember.isPresent(matterTypeId)) {
                        matterTypeId = null;
                    }
                    self.transitionToRoute('admin.task-list-templates', {
                        queryParams: { matterTypeFilter: matterTypeId }
                    });
                    self.get('adminTaskListTemplatesController').send('fetch');
                }, function failSaveTaskTemplate() {
                    self.get('notify').error('Task template was not saved');
                });
            }
        }
    });
});