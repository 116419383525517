define('tm3/pods/matter/calendar/route', ['exports', 'tm3/utils/sc-calendar-event'], function (exports, _scCalendarEvent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var appLists = ['calendar_type', 'report_types'];

    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        queryParams: {
            event_types: {
                refreshModel: true
            },
            event_kinds: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var currentMatterId = this.get('currentMatter').get('id');
            if (Ember.isEmpty(params.event_kinds)) {
                params.event_kinds = ['event', 'task', 'report', 'case-created', 'case-trial'];
            }

            return Ember.RSVP.hash({
                events: params.event_kinds.includes('event') ? this.store.query('event', {
                    matter_id: currentMatterId,
                    event_type: params.event_types,
                    calendar_view: true
                }) : [],
                event_types: params.event_types,
                event_kinds: params.event_kinds,
                lists: this.get('lists').fetchMultipleLists(appLists),
                tasks: params.event_kinds.includes('task') ? this.store.query('task-list', {
                    matter_id: currentMatterId,
                    with: 'tasks'
                }).then(function (taskLists) {
                    return taskLists.map(function (list) {
                        return list.get('tasks').filter(function (task) {
                            return task.get('isDone');
                        });
                    }).reduce(function (joinedList, list) {
                        return joinedList.concat(list);
                    }, []);
                }) : [],
                reports: params.event_kinds.includes('report') ? this.store.query('report', {
                    matter_id: currentMatterId
                }) : []
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var selectedTypes = controller.get('allCalendarTypes').filter(function (ct) {
                switch (ct.get('type')) {
                    case 'event':
                        return model.event_kinds.includes('event') && Ember.isEmpty(model.event_types) ? true : model.event_types.includes(ct.get('content.display'));
                    default:
                        return model.event_kinds.includes(ct.get('type'));
                }
            });
            controller.set('selectedEventTypes', selectedTypes);

            var matter = this.get('currentMatter.matter');
            var allEvents = [];

            allEvents.pushObjects(model.events.map(function (evt) {
                return _scCalendarEvent.default.create().fromEvent(evt);
            }));
            allEvents.pushObjects(model.tasks.map(function (task) {
                return _scCalendarEvent.default.create().fromTask(task);
            }));
            allEvents.pushObject(_scCalendarEvent.default.create().fromCaseCreated(matter));
            allEvents.pushObject(_scCalendarEvent.default.create().fromCaseTrial(matter));
            allEvents.pushObjects(model.reports.map(function (report) {
                return _scCalendarEvent.default.create().fromReportPublished(report);
            }));
            allEvents.pushObjects(model.reports.map(function (report) {
                return _scCalendarEvent.default.create().fromReportDue(report);
            }));

            controller.set('allEvents', allEvents);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});