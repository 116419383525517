define('tm3/pods/preview/file-browser/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentDocType: undefined,
        notify: Ember.inject.service(),

        sortedDocuments: Ember.computed('model.documents', 'model.docTypes', function () {
            var docTypes = this.get('model.docTypes');

            // update the name and docTypeOrder in each document
            this.get('model.documents').forEach(function (d) {
                d.set('name', d.get('name').replace(/\s\s+/g, ' '));
                if (docTypes) {
                    var docType = docTypes.findBy('value', d.get('docType'));
                    if (docType) {
                        d.set('docTypeOrder', docType.get('weight'));
                    }
                }
            });

            return this.get('model.documents').sortBy('report.name', 'tabNumber', 'docTypeOrder', 'name');
        }),

        actions: {
            changeDocType: function changeDocType(newDocType) {
                var _this = this;

                this.store.queryRecord('document', {
                    matter_id: this.get('model.document.matter.id'),
                    doc_type: newDocType.get('value'),
                    'reports:name': 'NULL',
                    with: 'reports',
                    limit: 1
                }).then(function (doc) {
                    if (doc) {
                        _this.get('notify').success('Loading the list of files of that type');
                        _this.transitionToRoute('preview.file-browser', doc.get('id'));
                    } else {
                        _this.get('notify').warning('There is no documents of that document type on this case');
                    }
                });
            }
        }
    });
});