define('tm3/models/subject-workflow-summary', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        assignedTo: belongsTo('user', { async: false }),
        notStarted: attr('number'),
        inProgress: attr('number'),
        incomplete: attr('number'),
        complete: attr('number'),
        researchStopped: attr('number'),
        unableToIdentify: attr('number'),
        total: attr('number')
    });
});