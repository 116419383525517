define('tm3/pods/components/crm/tooltip-contact/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['info-tooltip'],
        attributeBindings: ['customTitle:title'],
        contentAsHTML: true,

        client: Ember.Object.create({})
    });
});