define('tm3/pods/matter/subjects/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        modelName: 'subject',
        currentRoute: 'matter.subjects.list',
        lists: Ember.inject.service(),

        /**
         * @override from RouteFromTableSettings
         */
        getCurrentRouteParams: function getCurrentRouteParams() {
            return [this.get('currentMatter.id')];
        },
        model: function model() {
            return Ember.RSVP.hash({
                events: this.store.query('timeline-event', {
                    matter_id: this.get('currentMatter.id')
                }),
                eventTypes: this.store.findAll('timeline-event-type', { backgroundReload: false }),
                timelineEventParents: this.store.findAll('timeline-event-parent', { backgroundReload: false })
            });
        },


        /**
         *
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, resolved) {
            var _modelFor = this.modelFor('matter'),
                phrases = _modelFor.phrases;

            controller.set('phrases', phrases);
            controller.set('columns', this.get('currentMatter').get('subjectColumns'));
            controller.set('additionalColumnsForFilter', this.get('currentMatter').get('subjectColumnsForFilter'));

            // we need to keep old params so we can filter by multiple columns
            var params = controller.get('filterParams');
            var newParams = controller.get('filterParams');

            for (var key in params) {
                newParams[key] = params[key];
            }
            controller.set('filterParams', newParams);

            var currentMatter = this.get('currentMatter');
            // remove some hard coded values from the list
            var matterFieldsAll = currentMatter.get('matterFields');

            var matterFields = matterFieldsAll.reject(function (item) {
                return item.get('value') === 'last_name' || item.get('value') === 'first_name';
            }).map(function (item) {
                return Ember.ObjectProxy.create({
                    content: item
                });
            });

            controller.set('matterFields', matterFields.filter(function (mf) {
                return mf.get('isViewableAdminSubjectProfile');
            }));
            controller.set('matterFieldsAll', matterFieldsAll.filter(function (mf) {
                return mf.get('isViewableSubject');
            }));
            controller.set('matterFieldFamilies', currentMatter.get('matterFieldFamilies'));
            controller.set('loadDataParams', {
                matter_id: currentMatter.get('id')
            });

            controller.set('events', resolved.events);
            controller.set('eventTypes', resolved.eventTypes);
            controller.set('timelineEventParents', resolved.timelineEventParents);

            controller.set('documentTypes', this.get('lists').getListForGroup('jury_doc_type_tm'));
            controller.set('stateAbbreviations', this.get('lists').getListForGroup('states_abbreviations'));
            this._super(controller, resolved);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.send('selectAll', false);
                this.refresh();
            },
            willTransition: function willTransition(transition) {
                if (this.controller.get('isEditingSubject') && Object.keys(this.controller.get('subjectToEdit').changedAttributes()).length > 0 && !window.confirm('Are you sure you want to navigate away from the editing form? Any unsaved edits will be lost.')) {
                    transition.abort(true); // I don't know why I have to pass true, I didn't see anything in the docs, but if I don't the confirmation pops up indefinitely

                    return true;
                }
                this.controller.set('isEditingSubject', false);

                return true;
            }
        }
    });
});