define('tm3/pods/components/matter/db-memos/db-title/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        // what field is used to separate tags?
        separator: ' - ',

        // display the primary tag?
        primaryTag: true,

        // display special tags?
        specialTags: true,

        countMemos: null,

        displayCountry: Ember.computed('db.country', 'db.country.isUSA', 'db.name', function () {
            if (this.get('db.country')) {
                return !this.get('db.country.isUSA') && this.get('db.name').indexOf(this.get('db.country.name')) !== 0;
            }
            return false;
        })
    });
});