define('tm3/pods/admin/task-list-templates/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        requiredPermissions: ['Manage Tasks'],
        model: function model() {
            return Ember.RSVP.hash({
                matter_types: this.store.query('matter-type', { active: 1 }),
                list_template_groups: this.store.query('list-template-group', { with: 'matter_types,list_templates' }),
                task_templates: this.store.query('task-template', {})
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.get('model.matter_types').forEach(function (mt) {
                mt.set('numberOfTaskTemplates', resolved.list_template_groups.filterBy('matterType.id', mt.get('id')).length);
            });
            controller.set('matterTypeFilterObject', resolved.matter_types.findBy('id', controller.get('matterTypeFilter')));
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});