define('tm3/pods/components/relevant-post-display/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        documentTypes: [Ember.Object.extend({}).create({
            value: 'POST',
            display: 'POST'
        })],
        postScrapes: Ember.computed('scrapes.[]', function () {
            return this.get('scrapes').filterBy('post.id', this.get('post.id')).sortBy('createdOn');
        }),

        actions: {
            beforeUpload: function beforeUpload(filesToUpload) {
                filesToUpload.forEach(function (file) {
                    file.set('docType', 'POST');
                });
            },
            afterUpload: function afterUpload(response) {
                var _this = this;

                if (Ember.isEmpty(response.documents)) {
                    this.get('notify').error('No documents response');
                    return;
                }
                this.get('store').findRecord('document', response.documents[0].id).then(function (document) {
                    _this.get('post').set('documentResource', document);
                    _this.get('post').save().then(function () {
                        _this.set('showUploadForm', false);
                        _this.get('refresh')();
                        _this.get('notify').success('Documents uploaded');
                    });
                });
            },
            errorUpload: function errorUpload() {
                this.get('notify').error('There was a problem uploading the files');
            },
            cancelUpload: function cancelUpload() {
                this.set('showUploadForm', false);
            }
        }
    });
});