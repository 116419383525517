define('tm3/pods/components/matter/matter-connection/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['link label matter-connection'],
        classNameBindings: ['isPrimary:label-success:label-default'],
        isPrimary: alias('connection.primary'),
        showX: false,
        connection: null,
        mouseEnter: function mouseEnter() {
            this.set('showX', true);
        },
        mouseLeave: function mouseLeave() {
            this.set('showX', false);
        },

        additionalData: Ember.computed('connection.{group,isOrigin}', function () {
            var connection = this.get('connection');
            if (connection.constructor.modelName === 'matter-has-user') {
                var data = '';

                if (connection.get('group.name') === 'Case Owner') {
                    data += ' - Case Owner';
                }

                if (connection.get('isOrigin')) {
                    data += ' - Business Origin';
                }
                return data;
            }

            return '';
        }),
        actions: {
            deleteConnection: function deleteConnection() {
                this.sendAction('deleteConnection');
            },
            editConnection: function editConnection() {
                this.sendAction('editConnection');
            }
        }
    });
});