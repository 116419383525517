define('tm3/helpers/subject-field', ['exports', 'tm-common/helpers/subject-field'], function (exports, _subjectField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subjectField.default;
    }
  });
  Object.defineProperty(exports, 'subjectField', {
    enumerable: true,
    get: function () {
      return _subjectField.subjectField;
    }
  });
});