define('tm3/pods/accounting/billing/side-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'aside',
        classNames: ['main-sidebar'],
        currentUser: Ember.inject.service(),
        isAccounting: Ember.computed('currentUser', function () {
            return this.get('currentUser.user.groups').findBy('name', 'Accounting Administrator');
        })
    });
});