define('tm3/mixins/document-search-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ['t', 'dt', 's', 'd', 'm'],
        t: null,
        dt: null,
        s: null,
        d: null,
        m: null,

        matterChanged: Ember.observer('selectedMatter', function () {
            if (this.get('selectedMatter')) {
                this.set('m', this.get('selectedMatter.id'));
            }
        })
    });
});