define('tm3/pods/matter/admin/fields/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],
        model: function model() {
            var model = this.modelFor('matter');

            return Ember.RSVP.hash({
                matter: model.matter,
                publicLists: this.store.query('appl-lists-category', {
                    private: false
                }),
                matterFields: this.store.query('matter_field', {
                    matter_id: model.matter.id
                }),
                keyAttributeRules: this.store.query('key-attribute-rule', {
                    matter_id: model.matter.id
                }),
                matterFieldDefaults: this.store.query('matter_field_default', {
                    field_template_id: model.matter.get('matterType.fieldTemplate.id')
                }),
                fieldTemplates: this.store.findAll('field-template'),
                lists: this.get('lists').fetchMultipleLists(['matter_field_input_types', 'icon_file_names'])
            });
        },
        setupController: function setupController(controller, resolved) {
            resolved.iconList = resolved.lists['icon_file_names'];
            resolved.matterFieldInputTypes = resolved.lists['matter_field_input_types'];
            this._super(controller, resolved);
            controller.set('publicLists', resolved.publicLists.map(function (l) {
                return Ember.Object.create({
                    value: l.get('categoryName')
                });
            }));
        },


        actions: {
            fetchModel: function fetchModel() {
                this.refresh();
            },
            willTransition: function willTransition(transition) {
                this.controllerFor('matter').refreshModel();
                return true;
            }
        }
    });
});