define('tm3/pods/components/billables/edit/receipt-list/component', ['exports', 'tm3/pods/components/billables/edit/receipt-list/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        availableOptions: Ember.computed('receiptList.[]', 'receiptList.@each.billable', 'expenses.[]', 'expenses.@each.id', function () {
            var receipts = this.get('receiptList');
            var expensesTiedToReceiptIds = receipts.mapBy('billable.id');

            return this.get('expenses').filter(function (expense) {
                return !expense.get('isNew') && expensesTiedToReceiptIds.indexOf(expense.get('id')) === -1;
            });
        }),
        actions: {
            removeReceipt: function removeReceipt(receipt) {
                this.sendAction('removeReceipt', receipt);
            },
            saveReceipt: function saveReceipt(receipt) {
                this.sendAction('saveReceipt', receipt);
            },
            changeBillable: function changeBillable(receipt, selectedBillable) {
                // Had to do this instead of mut receipt.billable b/c it was not working with pipe helper
                receipt.set('billable', selectedBillable);
            }
        }
    });
});