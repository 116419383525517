define('tm3/validators/time-allocation', ['exports', 'ember-changeset-validations/validators', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _sometimes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        user: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A member is required.'
        })],
        startDate: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A start date is required.'
        })],
        duration: [(0, _validators.validatePresence)({
            presence: true,
            message: 'A duration is required.'
        }), (0, _validators.validateNumber)({ gte: 0, positive: true })],
        allocationType: [(0, _validators.validatePresence)({
            presence: true,
            message: 'An allocation type is required.'
        })]
    };
});