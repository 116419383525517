define('tm3/pods/crm/contacts/info/email-logs/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'tm-common/mixins/crud/error', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _controller, _error, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, _error.default, {
        store: Ember.inject.service(),
        appName: 'tm-admin',
        useTableSettings: true,
        sortField: '-created_on',

        with: 'users',
        quickSearchField: 'subject',
        quickSearch: null,

        perPage: 10,

        columns: [_column.default.create({
            displayName: 'Subject',
            fieldName: 'subject',
            order: 1,
            width: '300px'
        }), _column.default.create({
            displayName: 'Date Sent',
            fieldName: 'createdOn',
            apiName: 'created_on',
            cellComponent: 'custom-table-pagination/date-cell',
            order: 2,
            width: '100px'
        }), _column.default.create({
            displayName: 'Created By',
            fieldName: 'createdBy.fullName',
            apiName: 'createdBy:last_name',
            enableSearch: false,
            order: 3,
            width: '200px'
        }), _column.default.create({
            displayName: 'Result',
            fieldName: 'result',
            order: 4
        })]
    });
});