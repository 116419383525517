define('tm3/pods/components/matter/new-task-note/component', ['exports', 'tm-common/mixins/crud/error', 'ember-component-inbound-actions/inbound-actions', 'ember-lifeline/mixins/run', 'ember-concurrency'], function (exports, _error, _inboundActions, _run, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: 'row',
        currentUser: Ember.inject.service()
    });
});