define('tm3/pods/matter/subjects/info/social-links/route', ['exports', 'tm-common/models/scrape'], function (exports, _scrape) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model() {
            // fetching the subject:
            var subjectInfo = this.modelFor('matter.subjects.info');
            var subject = subjectInfo.subject.get('firstObject');
            var socialLinks = subject.get('hyperlinks');
            var creators = socialLinks.map(function (link) {
                return link.get('createdById.id');
            });
            var updators = socialLinks.map(function (link) {
                return link.get('updatedById.id');
            });
            var users = [].concat(_toConsumableArray(new Set([].concat(_toConsumableArray(creators), _toConsumableArray(updators)))));
            var resourcesIds = socialLinks.map(function (link) {
                return link.get('resourceId');
            });
            return Ember.RSVP.hash({
                currentUser: this.store.findRecord('user', this.get('session.data.authenticated.id')),
                assignToUser: this.store.findRecord('employee', _scrape.autoAssignToUserId),
                matter: this.get('currentMatter.matter'),
                socialLinks: socialLinks,
                scrapes: this.store.query('scrape', {
                    hyperlink_resource_id: resourcesIds,
                    post_id: 'NULL',
                    with: 'documents'
                }),
                posts: this.store.query('post', {
                    hyperlink_resource_id: resourcesIds
                }),
                linkTypes: this.get('lists').getListForGroup('sm_links'),
                subject: subject,
                users: this.store.query('user', { id: users }),
                metrics: this.store.query('hyperlink_metric', {
                    resource_id: resourcesIds
                }),
                phrases: this.store.query('field-phrase', {
                    field: 'Web and Social Media',
                    matter_id: ['NULL', this.get('currentMatter.id')]
                }),
                socialField: this.store.queryRecord('matter-field', {
                    value: 'social_media',
                    matter_id: this.get('currentMatter.id')
                })
            });
        },


        actions: {
            willTransition: function willTransition(transition) {
                var currentLink = this.get('controller.currentLink');
                if (this.get('controller.showLinkForm') && (Object.keys(currentLink.changedAttributes()).length > 0 || Object.keys(this.get('controller.currentLinkProxy')._changes).length > 1) && !window.confirm('Are you sure you want to navigate away from the editing form? Any unsaved edits will be lost.')) {
                    transition.abort(true);
                    return true;
                }
                if (currentLink) {
                    currentLink.rollbackAttributes();
                }
                this.set('controller.showLinkForm', false);
                this.send('modifyFormIsOpen', false);
                return true;
            },
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});