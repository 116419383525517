define('tm3/adapters/filtered-batch', ['exports', 'tm3/adapters/application', 'tm3/config/environment'], function (exports, _application, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        urlForFindAll: function urlForFindAll(modelName, snapshot) {
            return _environment.default.APP.restDestination + '/' + _environment.default.APP.restNameSpace + '/invoice_batches/filtered_by_user';
        }
    });
});