define('tm3/pods/case-management/info/calendar/route', ['exports', 'tm3/utils/sc-calendar-event'], function (exports, _scCalendarEvent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var appLists = ['calendar_type'];

    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        model: function model() {
            var _this = this;

            var infoModel = this.modelFor('case-management.info');

            return this.store.query('collection-has-matter', {
                collection_id: infoModel.collection.get('id'),
                with: 'matters'
            }).then(function (hasMatter) {
                var matters = hasMatter.map(function (hm) {
                    return hm.get('matter');
                });
                var matterList = hasMatter.map(function (hm) {
                    return hm.get('matter.id');
                });
                return Ember.RSVP.hash({
                    matters: Ember.RSVP.Promise.resolve(matters),
                    collection: infoModel.collection,
                    events: _this.store.query('event', {
                        matter_id: matterList
                    }),
                    tasks: _this.store.query('task-list', {
                        matter_id: matterList,
                        with: 'tasks'
                    }).then(function (taskLists) {
                        return (
                            // use reduce to flatten the list
                            taskLists.reduce(function (allTasks, currentList) {
                                return allTasks.concat(currentList);
                            }, []).map(function (task) {
                                return _scCalendarEvent.default.create().fromTask(task);
                            })
                        );
                    }),
                    lists: _this.get('lists').fetchMultipleLists(appLists)
                }).then(function (hashResult) {
                    return Ember.RSVP.hash({
                        matters: Ember.RSVP.Promise.resolve(hashResult.matters),
                        collection: hashResult.collection,
                        modelEvents: hashResult.events,
                        jsonEvents: Ember.RSVP.Promise.resolve(hashResult.events.map(function (evt) {
                            return _scCalendarEvent.default.create().fromEvent(evt);
                        }).concat(hashResult.tasks)),
                        lists: Ember.RSVP.Promise.resolve(hashResult.lists)
                    });
                });
            });
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});