define('tm3/components/subject-avatar', ['exports', 'tm-common/components/subject-avatar'], function (exports, _subjectAvatar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subjectAvatar.default;
    }
  });
});