define('tm3/components/charts/custom-bar-chart/horizontal-single-bar', ['exports', 'tm-common/components/charts/custom-bar-chart/horizontal-single-bar'], function (exports, _horizontalSingleBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _horizontalSingleBar.default;
    }
  });
});