define('tm3/mixins/matter/notes/matter-note-route', ['exports', 'tm3/mixins/alert-users-route', 'tm-common/mixins/crud/error'], function (exports, _alertUsersRoute, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_alertUsersRoute.default, _error.default, {
        currentMatter: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        session: Ember.inject.service(),
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),
        notifications: Ember.inject.service(),

        model: function model() {
            var _this = this;

            var currentMatter = this.get('currentMatter');

            return Ember.RSVP.hash({
                alertUsersEmployees: this.employeesModelMixin(),
                alertUsersContacts: this.contactsModelMixin(),
                matter: currentMatter.get('matter'),
                notes: this.store.query('case-note', {
                    matter_id: currentMatter.get('matter.id'),
                    with: 'all'
                })
            }).then(function (hash) {
                return _this.store.query('resource-has-communication', {
                    resource_id: hash.notes.mapBy('id')
                }).then(function (rhc) {
                    hash.communications = rhc;
                    return hash;
                });
            });
        },
        recipients: function recipients(employees) {
            return employees.map(function (employee) {
                return employee.get('id') + ':' + employee.get('fullName');
            }).join('/ ');
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            deleteNote: function deleteNote(note) {
                var _this2 = this;

                if (!window.confirm('Are you sure you want to remove this note?')) {
                    return;
                }
                if (note.get('children.length') > 0) {
                    this.get('notify').warning('You can not delete a note with replies');
                    return;
                }
                note.destroyRecord().then(function () {
                    _this2.get('notify').success('Note was removed successfully');
                    _this2.refresh();
                });
            },
            saveNote: function saveNote(noteBody, note, replyingNote, employeesToAlert, contactsToAlert) {
                var _this3 = this;

                var user = this.get('currentUser.user');
                var newNote = void 0;
                var emailMessage = user.get('fullName') + ' created a Case Note';
                if (note) {
                    note.set('matter', this.get('currentMatter.matter'));
                    newNote = note;
                    newNote.set('doNotUse', 0);
                    newNote.set('noteType', 4);
                    emailMessage = user.get('fullName') + ' edited a Case Note';
                } else {
                    newNote = this.store.createRecord('note', {
                        matter: this.get('currentMatter.matter'),
                        body: noteBody,
                        noteType: '4',
                        sendEmail: false,
                        recipients: this.recipients(employeesToAlert)
                    });
                }

                return newNote.save().then(function () {
                    if (replyingNote) {
                        // create r_h_r record for this relationship
                        // link note with parent note
                        var rhr = _this3.store.createRecord('resource-has-resource', {
                            parentId: replyingNote.get('id'),
                            childId: newNote.get('id')
                        });
                        rhr.save();
                        emailMessage = user.get('fullName') + ' commented on Case Note';
                    }

                    var subject = emailMessage;
                    var body = newNote.get('body');

                    // send notification to employees
                    if (employeesToAlert.length > 0) {
                        var users = employeesToAlert.mapBy('id').join(',');
                        var matter = _this3.get('currentMatter.matter');
                        var metadata = {
                            notes_id: newNote.get('id'),
                            notes_body: newNote.get('body'),
                            matters_id: matter.get('id'),
                            matters_tm_code: matter.get('tmCode'),
                            matters_person_of_interest: matter.get('personOfInterest'),
                            matters_case_nickname: matter.get('caseNickname'),
                            users_id: _this3.get('currentUser.user.id'),
                            users_first_name: _this3.get('currentUser.user.firstName'),
                            users_last_name: _this3.get('currentUser.user.lastName'),
                            recipients: employeesToAlert.mapBy('realFullName').join(', ')
                        };
                        _this3.get('notifications').send('CaseNote', users, subject, body, 'note', newNote.get('id'), _this3.get('currentMatter.id'), JSON.stringify(metadata));
                    }

                    // create a single email log entry if any client users are to be alerted
                    // OK TO CREATE EMAIL-LOG TO SEND EMAILS TO CLIENT USERS
                    var emailsToSendAlert = contactsToAlert.reject(function (e) {
                        return Ember.isEmpty(e.get('email'));
                    }).mapBy('email');

                    if (emailsToSendAlert.length > 0) {
                        var emailData = {
                            content: body,
                            subject: subject
                        };
                        emailData.recipients = emailsToSendAlert.join();
                        var email = _this3.store.createRecord('email-log', emailData);
                        email.save();
                    }
                    _this3.get('notify').success('The note was created successfully');
                    _this3.refresh();
                }, function () {
                    _this3.validationReport(newNote);
                    return Ember.RSVP.Promise.reject();
                });
            },
            publishNote: function publishNote(note) {
                var _this4 = this;

                if (!window.confirm('Are you sure you want to publish this note?')) {
                    return;
                }

                note.set('viewable', note.get('viewable') === 1 ? 0 : 1);
                note.set('matter', this.get('currentMatter.matter'));
                note.set('noteType', 4);

                note.save().then(function () {
                    _this4.get('notify').success('Note was made public');
                    _this4.refresh();
                });
            }
        }
    });
});