define('tm3/pods/matter/smm/index/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'scrape',
        currentRoute: 'smm',
        controllerName: 'matter.smm.index',
        currentMatter: Ember.inject.service(),

        model: function model(params) {
            return {};
        },
        activate: function activate() {
            var controller = this.controllerFor('matter.smm.index');
            controller.set('filterParams', { status: '=New' });
            controller.get('columns').findBy('displayName', 'Status').setProperties({
                advFilterOperator: {
                    display: 'Is equal to:',
                    value: 'equal',
                    input: 1
                },
                advFilterValue: 'New',
                filterValue: 'New'
            });
        },
        setupController: function setupController(controller, resolved) {
            var currentMatter = this.get('currentMatter');

            controller.set('loadDataParams', {
                matter_id: currentMatter.get('id')
            });
            this._super(controller, resolved);
        }
    });
});