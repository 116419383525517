define('tm3/pods/me/reimbursements/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentUser: Ember.inject.service(),

        queryParams: {
            reimbursementStatus: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            },
            perPage: {
                refreshModel: true
            },
            byDate: {
                refreshModel: true
            },
            from: {
                refreshModel: true
            },
            to: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var _this = this;

            var status = '';
            switch (params.reimbursementStatus) {
                case 'all':
                    status = ['Employee', 'Employee Reimbursed'];
                    break;
                case 'pending':
                    status = 'Employee';
                    break;
                case 'reimbursed':
                    status = 'Employee Reimbursed';
                    break;
            }

            var query = {
                type: ['Time:Expense', 'Piece:Expense'],
                with: 'billable_groups,billable_types,matters,receipts',
                sort: 'billable_groups:created_by_id,billable_types:name,billable_groups:start',
                reimbursement_status: status,
                page: params.page,
                perPage: params.perPage
            };

            var dateToFilter = void 0;
            var fromTime = ' 00:00:00';
            var toTime = ' 23:59:59';
            switch (params.byDate) {
                case 'Expense Date':
                    dateToFilter = 'billable_groups:start';
                    fromTime = toTime = '';
                    break;
                case 'Reimbursement Date':
                    dateToFilter = 'billable_groups:reimbursed_on';
                    break;
            }

            var from = void 0,
                to = void 0;
            if (!Ember.isPresent(params.from)) {
                from = (0, _moment.default)().subtract(1, 'month').format('YYYY-MM-DD');
                params.from = from;
            } else {
                from = params.from.substring(0, 10) + fromTime;
            }
            if (!Ember.isPresent(params.to)) {
                to = (0, _moment.default)().format('YYYY-MM-DD');
                params.to = to;
            } else {
                to = params.to.substring(0, 10) + toTime;
            }
            query[dateToFilter] = '~' + from + '~' + to;

            query['billable_groups:created_by_id'] = this.get('currentUser.authUserId');

            return Ember.RSVP.hash({
                billables: this.store.query('billable', query).then(function (model) {
                    _this.set('totalRecords', model.meta.total_record_count);
                    _this.set('totalPages', model.meta.total_pages);
                    return model;
                }),
                params: params,
                query: query
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            resolved.billables.forEach(function (b) {
                b.set('receipt', b.get('billableGroup.receipts').filterBy('billable.id', b.get('id')).get('firstObject'));
            });

            controller.set('totalRecords', this.get('totalRecords'));
            controller.set('totalPages', this.get('totalPages'));
            if (Ember.isPresent(resolved.params.from)) {
                controller.set('from', resolved.params.from);
            }
            if (Ember.isPresent(resolved.params.to)) {
                controller.set('to', resolved.params.to);
            }

            controller.set('currentParams', resolved.query);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});