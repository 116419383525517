define('tm3/pods/components/task-events/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        task: null,

        lastEvent: Ember.computed('task.events.[]', function () {
            var events = this.get('task.events');
            return events.sortBy('createdOn').get('lastObject');
        }),

        sortProperty: ['createdOn:desc'],
        sortedEvents: Ember.computed.sort('task.events.[]', 'sortProperty')
    });
});