define('tm3/pods/components/matter/tasks/info/document-display/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['box-comment'],

        actions: {
            delete: function _delete() {
                this.sendAction('delete');
            }
        }
    });
});