define('tm3/helpers/even-odd', ['exports', 'tm-common/helpers/even-odd'], function (exports, _evenOdd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _evenOdd.default;
    }
  });
  Object.defineProperty(exports, 'evenOdd', {
    enumerable: true,
    get: function () {
      return _evenOdd.evenOdd;
    }
  });
});