define('tm3/pods/admin/field-phrases/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'field-phrase',
        currentRoute: 'admin.field-phrases',
        requiredPermissions: ['Manage Case Settings'],

        queryParams: {
            field: {
                refreshModel: true
            },
            refresh: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return {
                field: params.field
            };
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {
                field: resolved.field,
                matter_id: ['NULL', '']
            });
            this._super(controller, resolved);
        },


        actions: {
            doNothing: function doNothing() {},
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});