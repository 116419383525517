define('tm3/pods/case-group/edit-columns/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        goBack: Ember.inject.service(),

        collection: Ember.computed.alias('model.collection'),
        collections: Ember.computed.alias('model.collections'),
        columns: Ember.computed.alias('model.collectionColumns'),
        publicLists: Ember.computed.alias('model.publicLists'),

        collectionSelected: null,

        matterWorkflows: Ember.computed('model.matterWorkflows', 'columns', function () {
            var enabledWorkflows = this.get('columns').mapBy('workflow').filter(function (w) {
                return w.get('id');
            });
            return this.get('model.matterWorkflows').reject(function (mw) {
                return enabledWorkflows.findBy('id', '' + mw.get('id')) !== undefined;
            });
        }),
        caseColumns: Ember.computed('model.caseColumns', 'columns', function () {
            var enabledColumns = this.get('columns').filter(function (c) {
                return c.get('editRenderType') !== 'workflow';
            });
            return this.get('model.caseColumns').reject(function (cc) {
                return enabledColumns.findBy('apiName', '' + cc.column) !== undefined;
            });
        }),

        editColumn: null,

        viewRenderTypes: [Ember.Object.create({ value: 'text', display: 'Text' }), Ember.Object.create({ value: 'date', display: 'Date' }), Ember.Object.create({ value: 'due-date', display: 'Due Date' }), Ember.Object.create({ value: 'money', display: 'Money' }), Ember.Object.create({ value: 'number', display: 'Number' }), Ember.Object.create({ value: 'tags', display: 'Tags' }), Ember.Object.create({ value: 'case-group', display: 'Case Group' })],

        editRenderTypes: [Ember.Object.create({ value: 'readonly', display: 'Read Only' }), Ember.Object.create({ value: 'text', display: 'Text' }), Ember.Object.create({ value: 'longtext', display: 'Text area' }), Ember.Object.create({ value: 'hyperlink', display: 'Hyperlink' }), Ember.Object.create({ value: 'date', display: 'Date' }), Ember.Object.create({ value: 'enum', display: 'Select' })],

        actions: {
            goBackToList: function goBackToList() {
                this.transitionToRoute('matter-list', {
                    queryParams: { collection_id: this.get('model.collection.id') }
                });
            },
            reorderFields: function reorderFields(sortedFields) {
                var self = this;
                var weight = 1;
                var updates = [];
                this.get('notify').warning('Saving new order...');
                sortedFields.forEach(function (f) {
                    f.set('weight', weight++);
                    updates.pushObject(f.save());
                });
                Ember.RSVP.all(updates).then(function () {
                    self.get('notify').success('Fields Order Saved');
                });
            },
            changeType: function changeType(field, newFieldType) {
                if (newFieldType) {
                    field.set('editRenderType', newFieldType.get('value'));
                    var fieldType = newFieldType.get('value');

                    if (fieldType !== 'enum' && fieldType !== 'radio' && fieldType !== 'checkboxes') {
                        // reset list value
                        field.set('editRenderList', '');
                    }
                }
                field.set('selectedFieldType', newFieldType);
            },
            validateNumber: function validateNumber(number, columnName) {
                var field = this.get('editColumn');
                field.set(columnName, number.replace(/[^\d]+/, ''));
            },
            saveColumn: function saveColumn() {
                var _this = this;

                var editColumn = this.get('editColumn');

                if (editColumn.get('viewRenderType') === 'workflow') {
                    // check that the sum of all workflows is 100%
                    var total = this.get('columns').filterBy('viewRenderType', 'workflow').reduce(function (acc, cur) {
                        return acc + parseFloat(cur.get('percentage'));
                    }, 0);
                    if (total > 100) {
                        this.get('notify').error('The total percentage of workflows must be 100%');
                        return;
                    }
                }

                editColumn.save().then(function () {
                    _this.get('notify').success('Saved');
                    _this.send('refresh');
                    _this.set('editColumn', null);
                });
            },
            deleteColumn: function deleteColumn() {
                var _this2 = this;

                var removeColumn = this.get('removeColumn');

                removeColumn.destroyRecord().then(function () {
                    _this2.get('notify').success('Removed');
                    _this2.send('refresh');
                    _this2.set('removeColumn', null);
                });
            },
            createColumn: function createColumn() {
                var _this3 = this;

                var _getProperties = this.getProperties('collection', 'columns', 'fieldType', 'columnSelected', 'workflowSelected'),
                    collection = _getProperties.collection,
                    columns = _getProperties.columns,
                    fieldType = _getProperties.fieldType,
                    columnSelected = _getProperties.columnSelected,
                    workflowSelected = _getProperties.workflowSelected;

                var cc = void 0;

                var maxWeight = columns.reduce(function (previous, item) {
                    return item.get('weight') > previous ? item.get('weight') : previous;
                }, 0);

                switch (fieldType) {
                    case 'case':
                        if (Ember.isEmpty(columnSelected)) {
                            this.get('notify').warning('Please select a column from the list');
                            return;
                        }
                        cc = this.get('store').createRecord('collection-column', {
                            collection: collection,
                            label: columnSelected,
                            fieldName: Ember.String.camelize(columnSelected),
                            apiName: columnSelected,
                            weight: maxWeight + 1,
                            columnWidth: 80,
                            viewRenderType: 'text',
                            editRenderType: 'text',
                            dashboardViewable: false
                        });
                        cc.save().then(function () {
                            _this3.get('notify').success('New column saved');
                            _this3.send('refresh');
                            _this3.set('addField', false);
                            _this3.set('editColumn', cc);
                        });
                        break;
                    case 'workflow':
                        if (Ember.isEmpty(workflowSelected)) {
                            this.get('notify').warning('Please select a workflow from the list');
                            return;
                        }
                        cc = this.get('store').createRecord('collection-column', {
                            collection: collection,
                            label: workflowSelected.get('name'),
                            fieldName: workflowSelected.get('frontendName'),
                            apiName: workflowSelected.get('backendName'),
                            weight: maxWeight + 1,
                            columnWidth: 80,
                            viewRenderType: 'workflow',
                            editRenderType: 'workflow',
                            dashboardViewable: false,
                            workflow: workflowSelected
                        });
                        cc.set('workflowAssigneeEnabled', true);
                        cc.set('workflowStatusEnabled', true);
                        cc.save().then(function () {
                            _this3.get('notify').success('New column saved');
                            _this3.send('refresh');
                            _this3.set('addField', false);
                            _this3.set('editColumn', cc);
                        });
                        break;
                }
            },
            executeCopy: function executeCopy() {
                var _this4 = this;

                var collectionSelected = this.get('collectionSelected');
                if (Ember.isEmpty(collectionSelected)) {
                    this.get('notify').warning('You need to select a case group');
                    return;
                }
                var collection = this.get('collection');
                collection.copyFrom({
                    from_collection_id: this.get('collectionSelected.id')
                }).then(function () {
                    _this4.get('notify').success('Fields copied successfully');
                    _this4.send('refresh');
                    _this4.set('copyFrom', false);
                    _this4.set('collectionSelected', null);
                }).catch(function () {
                    _this4.get('notify').error('There was a problem copying the fields');
                });
            }
        }
    });
});