define('tm3/pods/components/admin/matter-type/info/rate-list/rate-widget/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),

        initComponent: Ember.on('init', function () {
            this.set('initialValue', this.get('caseTypeRate.rate'));
        }),

        // track what edit state the widget should be in
        isEditing: false,

        caseTypeRate: Ember.computed('employee', 'caseTypeRates', function () {
            var caseTypeRates = this.get('caseTypeRates');
            var employee = this.get('employee');
            var caseTypeRateResult = caseTypeRates.filterBy('employee.id', employee.get('id'));
            var caseTypeRate = void 0;
            if (caseTypeRateResult.get('length') === 1) {
                caseTypeRate = caseTypeRateResult.get('firstObject');
            } else if (caseTypeRateResult.get('length') > 1) {
                this.get('notify').error('Too many user-has-matter-type records were found');
            } else {
                caseTypeRate = {};
            }

            return caseTypeRate;
        }),

        objectToSave: Ember.computed('employee', 'caseTypeRate', function () {
            var employee = this.get('employee');
            var caseTypeRate = this.get('caseTypeRate');

            return {
                employee: employee,
                caseTypeRate: caseTypeRate
            };
        }),

        isValid: Ember.computed('caseTypeRate.rate', function () {
            var caseTypeRate = this.get('caseTypeRate');

            if (caseTypeRate.constructor.modelName) {
                if (caseTypeRate.get('rate') < 0 || caseTypeRate.get('rate') > 999) {
                    return false;
                }
            } else {
                if (caseTypeRate.rate < 0 || caseTypeRate.rate > 999) {
                    return false;
                }
            }
            return true;
        }),

        actions: {
            showInvalid: function showInvalid(obj) {
                var caseTypeRate = this.get('caseTypeRate');
                if (caseTypeRate.get('rate') < 0 || caseTypeRate.get('rate') > 999) {
                    this.get('notify').error('Rate must be between 0 and 999');
                }
            },
            resetRate: function resetRate() {
                this.set('caseTypeRate.rate', this.get('initialValue'));
            },
            updateRate: function updateRate() {
                this.set('initialValue', this.get('caseTypeRate.rate'));
            }
        }
    });
});