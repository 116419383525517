define('tm3/pods/components/report/expense-receipt/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        data: Ember.computed('tableData.[]', function () {
            var tableData = this.get('tableData');
            var sortedData = {};

            tableData.forEach(function (td) {
                var tmCode = td.tm_code;
                var bgId = td.billable_group_id;
                if (sortedData.hasOwnProperty(tmCode)) {
                    if (sortedData[tmCode].hasOwnProperty(bgId)) {
                        sortedData[tmCode][bgId]['list'].push(td);
                    } else {
                        sortedData[tmCode][bgId] = { list: [td], date: td.start };
                    }
                } else {
                    var bgObj = {};
                    bgObj[bgId] = { list: [td], date: td.start };
                    sortedData[tmCode] = bgObj;
                }
            });

            var sortedByDate = [];

            var _loop = function _loop(k) {
                var bgDict = sortedData[k];

                var items = Object.keys(bgDict).map(function (key) {
                    return [key, bgDict[key]['date'], bgDict[key]['list']];
                });

                items.sort(function (first, second) {
                    return (0, _moment.default)(first[1]).isBefore((0, _moment.default)(second[1]));
                });
                sortedByDate.push(items);
            };

            for (var k in sortedData) {
                _loop(k);
            }

            return sortedByDate;
        })
    });
});