define('tm3/utils/copy-to-clipboard', ['exports', 'tm-common/utils/copy-to-clipboard'], function (exports, _copyToClipboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _copyToClipboard.default;
    }
  });
});