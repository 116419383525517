define('tm3/pods/components/document-search/component', ['exports', 'tm3/pods/components/document-search/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,

        terms: '',
        selectedSubject: null,
        selectedMatter: null,
        docName: '',
        query: '',

        documents: [],

        actions: {
            clearFilters: function clearFilters() {
                this.setProperties({
                    terms: '',
                    selectedSubject: null,
                    selectedMatter: null,
                    docName: ''
                });

                this.get('documentTypes').forEach(function (dt) {
                    return dt.set('selected', false);
                });

                this.set('query', '');
            }
        }
    });
});