define('tm3/pods/accounting/billing/batches/info/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        nameIsEditing: false,
        batchName: null,

        showDeleteModal: false,

        currentParams: Ember.computed('model', function () {
            return { invoice_batch_id: this.get('model.id') };
        }),
        reportName: 'invoice_batch_report',

        totalBilled: Ember.computed('model.invoices.@each.billedAmount', function () {
            return this.get('model.invoices').reduce(function () {
                var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
                var inv = arguments[1];
                return amount + parseFloat(inv.get('billedAmount'));
            });
        }),

        application: Ember.inject.controller(),
        reportBuilder: Ember.inject.service(),

        isAccounting: Ember.computed('currentUser', function () {
            return this.get('currentUser.user.groups').findBy('name', 'Accounting Administrator');
        }),

        modelStatusLabel: Ember.computed('model.status', function () {
            switch (this.get('model.status')) {
                case 'Billed':
                    return 'label-success';
                case 'Preliminary':
                    // 'Timekeeper Approval Required'
                    return 'label-warning';
                case 'Level 1 Complete':
                    // 'Manager Approval Required'
                    return 'label-dark';
                case 'Level 2 Complete':
                    // 'Accounting Approval Required'
                    return 'label-primary';
                default:
                    return 'label-warning';
            }
        }),

        childRouteTitle: Ember.computed('application.currentRouteName', function () {
            var currentRouteName = this.get('application.currentRouteName');

            var rtn = '';

            switch (currentRouteName) {
                case 'accounting.billing.batches.info.summary':
                    rtn = 'summary';
                    break;

                case 'accounting.billing.batches.info.print':
                    rtn = 'print';
                    break;
            }

            return rtn;
        }),

        actions: {
            saveBatch: function saveBatch() {
                var _this = this;

                var batch = this.get('model');
                batch.set('name', this.get('batchName'));
                batch.save().then(function () {
                    _this.set('nameIsEditing', false);
                    _this.get('notify').success('Invoice batch saved');
                }, function () {
                    _this.get('notify').error('Error while updating invoice batch');
                });
            },
            cancelEditBatch: function cancelEditBatch() {
                this.set('nameIsEditing', false);
                this.set('batchName', this.get('model.name'));
            },
            deleteInvoiceBatch: function deleteInvoiceBatch() {
                var _this2 = this;

                this.get('model').destroyRecord().then(function () {
                    _this2.set('showDeleteModal', false);
                    _this2.transitionToRoute('accounting.billing.batches.list');
                }, function () {
                    _this2.get('notify').error('Error occurred while deleting invoice batch');
                });
            },
            someInvoiceIsBilled: function someInvoiceIsBilled(invoices) {
                return invoices.isAny('status', 'Billed');
            }
        }
    });
});