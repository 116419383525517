define('tm3/pods/matter/uploads/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                matterFields: this.store.query('matter-field', {
                    matter_id: this.get('currentMatter.id'),
                    display: ['Client Data', 'Questionnaire']
                })
            });
        }
    });
});