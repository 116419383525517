define('tm3/helpers/status-label', ['exports', 'tm-common/helpers/status-label'], function (exports, _statusLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _statusLabel.default;
    }
  });
});