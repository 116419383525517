define('tm3/pods/report/info/revenue-quickbooks-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['year', 'month'],

        reportBuilder: Ember.inject.service(),

        year: '',
        month: '',

        actions: {
            exportQuickbooks: function exportQuickbooks() {
                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: 'revenue_quickbooks_report',
                            format: 'quickbooks',
                            style: 'tabular'
                        },
                        custom: {
                            year: this.get('year'),
                            month: this.get('month')
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonObject);
            }
        }
    });
});