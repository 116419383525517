define('tm3/pods/matter/admin/contacts/route', ['exports', 'tm-common/mixins/crud/error', 'tm3/config/environment'], function (exports, _error, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var GroupFilter = Ember.ObjectProxy.extend({
        isActive: false
    });

    var Contact = Ember.ObjectProxy.extend({
        isEditable: Ember.computed.equal('type', 'user'),
        type: '', // it can be user or team
        groupId: null // to show by team
    });

    exports.default = Ember.Route.extend(_error.default, {
        lists: Ember.inject.service(),
        notify: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        queryParams: {
            filter_by_groups: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var matterId = this.get('currentMatter.id');

            // params.matter_id
            return Ember.RSVP.hash({
                matter: this.store.findRecord('matter', matterId),
                matterHasUsers: this.store.query('matter-has-user', {
                    matter_id: matterId,
                    user_type: 'client',
                    with: 'users,groups,teams',
                    sortField: 'users:last_name',
                    with_current_employer: true

                    // add_last_sent_welcome: true // TODO too slow
                }),
                groups: this.store.query('group', {
                    group_type: _environment.default.APP.groupTypes.client
                }),
                matterUserPermissions: this.store.query('v-matter-user-permission', {
                    matter_id: matterId,
                    with: 'users'
                }),
                contactRelationships: this.get('lists').getListForGroup('case_contact_types'),
                filter_by_groups: params.filter_by_groups,
                relationships: this.get('lists').getListForGroup('contact_account_relations')
            });
        },


        /**
         * provide extra logic to allow showing permissions by both direct link and by team membership
         *
         * @param controller
         * @param resolved
         */
        setupController: function setupController(controller, model) {
            var resolved = model;

            // build a list of groups
            var groups = resolved.groups.map(function (group) {
                return GroupFilter.create({ content: group });
            });

            var byTeamGroup = this.store.peekRecord('group', 'by-team');

            if (!byTeamGroup) {
                byTeamGroup = this.store.createRecord('group', {
                    name: 'Team',
                    id: 'by-team'
                });
            }
            groups.pushObject(GroupFilter.create({
                content: byTeamGroup,
                isActive: false
            }));
            controller.set('groups', groups);

            if (typeof resolved.filter_by_groups === 'undefined') {
                resolved.filter_by_groups = [];
                controller.get('groups').forEach(function (g) {
                    if (g.get('id') !== 'by-team') {
                        g.set('isActive', true);
                        resolved.filter_by_groups.pushObject(g.get('id'));
                    }

                    if (g.get('name') === 'Expired Contact') {
                        g.set('isActive', false);
                    }
                });
                controller.set('filter_by_groups', resolved.filter_by_groups.join(','));
            } else {
                resolved.filter_by_groups.split(',').forEach(function (groupId) {
                    var g = controller.get('groups').findBy('id', groupId);

                    if (Ember.isPresent(g)) {
                        g.set('isActive', true);
                    }
                });
            }

            var contacts = Ember.A();

            controller.set('matterHasUsers', resolved.matterHasUsers);
            controller.get('matterHasUsers').forEach(function (mhu) {
                contacts.pushObject(Contact.create({
                    content: mhu,
                    groupId: mhu.get('group.id'),
                    type: 'user'
                }));
            });

            controller.set('users_by_team', resolved.matterUserPermissions.filter(function (mup) {
                return !Ember.isPresent(resolved.matterHasUsers.findBy('user.id', mup.get('user.id'))) && mup.get('user.userType') === 'client';
            }));
            controller.get('users_by_team').sortBy('fullName').forEach(function (ubt) {
                contacts.pushObject(Contact.create({
                    content: ubt,
                    groupId: 'by-team',
                    type: 'team'
                }));
            });

            controller.set('clients', []);
            controller.set('matter', resolved.matter);

            controller.set('contactRelationships', resolved.contactRelationships);
            controller.set('contacts', contacts);

            controller.set('relationships', resolved.relationships);

            this._super(controller, resolved);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});