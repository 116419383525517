define('tm3/pods/accounting/billing/travel-expenses/controller', ['exports', 'ember-cli-table-pagination/mixins/table-pager/controller', 'ember-cli-table-pagination/mixins/table-pager/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        permissions: Ember.inject.service(),
        notify: Ember.inject.service(),
        application: Ember.inject.controller(),
        status: 'New',
        filter: 'All',
        employee: null,
        from: null,
        to: null,
        byDate: 'Expense Date',
        queryParams: ['filter', 'status', 'employee', 'from', 'to', 'byDate'],
        inMasterPage: Ember.computed.equal('application.currentRouteName', 'accounting.billing.travel-expenses.index'),

        columns: [_column.default.create({
            displayName: 'Date Range',
            fieldName: 'rangeDate',
            apiName: 'start',
            disableServerInteractions: true,
            order: 0
        }), _column.default.create({
            displayName: 'Employee',
            fieldName: 'createdBy.fullName',
            apiName: 'employee_name',
            order: 2
        }), _column.default.create({
            displayName: 'Total',
            fieldName: 'formattedTotal',
            apiName: 'total',
            align: 'right',
            width: '100px',
            order: 3
        }), _column.default.create({
            displayName: 'Reimbursed On',
            fieldName: 'reimbursedOn',
            apiName: 'reimbursed_on',
            cellComponent: 'travel-expenses.reimburse-cell',
            order: 4
        })],
        additionalColumnsForFilter: [_column.default.create({
            displayName: 'Billed Status',
            fieldName: 'billedStatus',
            order: 0
        })],
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),
        columnFields: Ember.computed('columns', function () {
            return this.get('columns').filter(function (c) {
                return Ember.isPresent(c.get('displayName'));
            }).sortBy('order').map(function (c) {
                return c.get('apiInteractionName');
            });
        }),

        toolsParams: Ember.computed(function () {
            var _this = this;

            return {
                status: this.get('status'),
                statusChanged: function statusChanged(newStatus) {
                    _this.send('statusChanged', newStatus);
                },
                filter: this.get('filter'),
                filterChanged: function filterChanged(newFilter) {
                    _this.send('filterChanged', newFilter);
                },
                employee: this.get('employee'),
                employeeChanged: function employeeChanged(newEmployee) {
                    _this.send('employeeChanged', newEmployee);
                },
                from: this.get('from'),
                fromChanged: function fromChanged(from) {
                    _this.send('fromChanged', from);
                },
                to: this.get('to'),
                toChanged: function toChanged(to) {
                    _this.send('toChanged', to);
                },
                byDate: this.get('byDate'),
                dateTypeChanged: function dateTypeChanged(byDate) {
                    _this.send('dateTypeChanged', byDate);
                }
            };
        }),

        // linkPath: 'accounting.billing.travel-expenses.info',

        tableTitle: 'Travel Expenses',

        titleParams: Ember.computed('status', 'filter', 'employee', 'from', 'to', 'byDate', 'selectedRows.[]', function () {
            return {
                customExtraParams: {
                    approval_status: this.get('status'),
                    reimbursed_on: this.get('filter'),
                    employee: this.get('employee'),
                    from: this.get('from'),
                    to: this.get('to'),
                    date_field_comp: this.get('byDate')
                },
                selectedRows: this.get('selectedRows')
            };
        }),

        sortField: 'employee_name',

        with: 'charges,billables',

        filteredModel: Ember.computed('model', 'onlyInInvoice', function () {
            var filteredModel = [];
            var model = this.get('model');
            if (this.get('onlyInInvoice')) {
                model.forEach(function (bg) {
                    if (bg.get('billables.length') > 0) {
                        filteredModel.push(bg);
                    }
                });
                return filteredModel;
            } else {
                return model;
            }
        }),

        actions: {
            statusChanged: function statusChanged(newStatus) {
                this.set('status', newStatus);
            },
            filterChanged: function filterChanged(newFilter) {
                this.set('filter', newFilter);
            },
            employeeChanged: function employeeChanged(newEmployee) {
                this.set('employee', newEmployee ? newEmployee.get('id') : null);
            },
            fromChanged: function fromChanged(from) {
                this.set('from', from);
            },
            toChanged: function toChanged(to) {
                this.set('to', to);
            },
            dateTypeChanged: function dateTypeChanged(byDate) {
                this.set('byDate', byDate);
            },
            viewBillableGroupInfo: function viewBillableGroupInfo(id) {
                var _this2 = this;

                var queryParams = this.get('queryParams');
                var params = {};
                queryParams.forEach(function (param) {
                    params[param] = _this2.get(param);
                });
                this.transitionToRoute('accounting.billing.travel-expenses.info', id, { queryParams: params });
            }
        }
    });
});