define('tm3/pods/accounting/billing/review-time/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'billable-group',
        currentRoute: 'accounting.billing.invoices.list',

        requireAnyPermission: ['Manage Time & Expense', 'See Time & Expense'],

        queryParams: {
            start: {
                refreshModel: true
            },
            end: {
                refreshModel: true
            },
            selectedEmployees: {
                refreshModel: true
            },
            selectedCase: {
                refreshModel: true
            },
            selectedCaseType: {
                refreshModel: true
            },
            billType: {
                refreshModel: true
            },
            currentView: {
                refreshModel: false
            },
            selectedBillingStatus: {
                refreshModel: true
            }
        },

        necessaryParams: false,

        // filter only some types of invoices
        model: function model(params) {
            var necessaryParams = false;
            if (params.selectedCase || params.selectedEmployees || params.end || params.start) {
                necessaryParams = true;
            }
            this.set('necessaryParams', necessaryParams);

            delete params.selectedEmployees;
            delete params.billType;
            delete params.selectedBillingStatus;
            delete params.selectedCase;
            delete params.selectedCaseType;
            delete params.currentView;
            delete params.end;

            return {};
        },
        setupController: function setupController(controller, resolved) {
            if (this.get('necessaryParams')) {
                var params = {};
                var selectedEmployees = controller.get('selectedEmployees');
                var selectedCase = controller.get('selectedCase');
                var selectedCaseType = controller.get('selectedCaseType');
                var start = controller.get('start');
                var end = controller.get('end');
                var selectedBillingStatus = controller.get('selectedBillingStatus');

                params['bg_type'] = controller.get('billType');

                if (selectedEmployees) {
                    params['created_by_id'] = selectedEmployees.split(',');
                }

                if (selectedCase) {
                    params['matter_id'] = selectedCase;
                }

                if (selectedCaseType) {
                    params['matter_type_id'] = selectedCaseType;
                }

                if (start && end) {
                    params['start'] = '~' + start + '~' + end;
                } else {
                    if (start) {
                        params['start'] = '>=' + start;
                    }
                    if (end) {
                        params['start'] = '<=' + end;
                    }
                }

                switch (selectedBillingStatus) {
                    case 'All':
                        params['approval_status'] = null;
                        params['include_for_billing'] = null;
                        params['include_billable_cut'] = null;
                        break;
                    case 'Billable - Not Invoiced':
                        params['approval_status'] = ['null', 'Preliminary', 'Level 1 Complete', 'Level 2 Complete'];
                        params['include_for_billing'] = '1';
                        params['include_billable_cut'] = null;
                        break;
                    case 'Non-Billable/Cut':
                        params['approval_status'] = null;
                        params['include_for_billing'] = null;
                        params['include_billable_cut'] = '1';
                        break;
                    case 'Billable - Invoiced':
                        params['approval_status'] = 'Billed';
                        params['include_for_billing'] = '1';
                        params['include_billable_cut'] = null;
                        break;
                    default:
                        break;
                }

                controller.set('loadDataParams', params);
                this._super(controller, resolved);
            }
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('showBillableGroupModal', false);
            }
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});