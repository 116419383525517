define('tm3/pods/report/info/invoiced-customers/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'invoiced_customers',

        queryParams: {},

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 50,
                    page: params.page ? params.page : 1,
                    from_date: params.from_date,
                    to_date: params.to_date,
                    employee_id: params.employee,
                    matter_id: params.matter
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                params: params
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('totalPages', resolved.tableData.total_pages);
            controller.set('report', resolved.report);
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});