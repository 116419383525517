define('tm3/pods/crm/accounts/info/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var id = params.firm_id;
            return Ember.RSVP.hash({
                firm: this.store.queryRecord('firm', { id: id, with: 'firm_invoice_history' }),
                // ask for additional data here...billing metrics perhaps?
                firmTags: this.store.query('crm-tag-has-firm', {
                    firm_id: id,
                    with: 'crm_tags'
                }),
                tags: this.store.findAll('crm-tag')
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.firm);

            // load data for tagging system
            controller.set('firmTags', resolved.firmTags);
            controller.set('tags', resolved.tags);
        },


        // redirect when going to the regular info route
        afterModel: function afterModel(resolvedModel, transition) {
            var self = this;
            transition.then(function () {
                // Ember.Logger.debug(self.get('router.url'));
                var pieces = self.get('router.url').split('/').length;
                if (pieces === 5) {
                    self.replaceWith('crm.accounts.info.activity');
                }
            });
        },


        actions: {
            reloadModel: function reloadModel() {
                this.refresh();
            }
        }
    });
});