define('tm3/mixins/sidebar-tree', ['exports', 'ember-computed-style', 'ember-resize/mixins/resize-aware'], function (exports, _emberComputedStyle, _resizeAware) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_resizeAware.default, {
        style: (0, _emberComputedStyle.default)('sidebarHeight', 'sidebarWidth'),
        attributeBindings: ['style'],
        sidebarHeight: { height: '500px' },
        sidebarWidth: { width: '230px' },
        // fix sidebar height when sidebar is higher than the content. It's used by ember scrollable
        debouncedDidResize: function debouncedDidResize(width, height, evt) {
            var sidebarHeight = Ember.$(window).height() - Ember.$('.main-header').height() + 'px';
            this.set('sidebarHeight', { height: sidebarHeight });
        },
        mouseEnter: function mouseEnter() {
            var body = Ember.$('body');
            if (body.hasClass('sidebar-collapse')) {
                this.set('sidebarWidth', { width: '230px !important' });
            }
        },
        mouseLeave: function mouseLeave() {
            var body = Ember.$('body');
            if (body.hasClass('sidebar-collapse')) {
                this.set('sidebarWidth', { width: null });
            }
        },
        layoutFix: function layoutFix() {
            // a window resize will eventually 'fix' the sidebar scrollbar
            Ember.$(window).trigger('resize');
        },

        // Converts the sidebar into a multileveltree view menu.
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var animationSpeed = 500;
            var self = this;
            Ember.run.next(function () {
                self.layoutFix();
                self.debouncedDidResize();
            });
            this.$().off('click.sidebar_tree', 'li a').on('click.sidebar_tree', 'li a', function (e) {
                // Get the clicked link and the next element
                var $this = Ember.$(this);
                var checkElement = $this.next();

                // Check if the next element is a menu and is visible
                if (checkElement.is('.treeview-menu') && checkElement.is(':visible') && !Ember.$('body').hasClass('sidebar-collapse')) {
                    // Close the menu
                    checkElement.slideUp(animationSpeed, function () {
                        checkElement.removeClass('menu-open');
                        // Fix the layout in case the sidebar stretches over the height of the window
                        self.layoutFix();
                    });
                    checkElement.parent('li').removeClass('active');
                } else if (checkElement.is('.treeview-menu') && !checkElement.is(':visible')) {
                    // If the menu is not visible
                    // Get the parent menu
                    var parent = $this.parents('ul').first();
                    // Close all open menus within the parent
                    var ul = parent.find('ul:visible').slideUp(animationSpeed);
                    // Remove the menu-open class from the parent
                    ul.removeClass('menu-open');
                    // Get the parent li
                    var parentLi = $this.parent('li');

                    // Open the target menu and add the menu-open class
                    checkElement.slideDown(animationSpeed, function () {
                        // Add the class active to the parent li
                        checkElement.addClass('menu-open');
                        parent.find('li.active').removeClass('active');
                        parentLi.addClass('active');
                        // Fix the layout in case the sidebar stretches over the height of the window
                        self.layoutFix();
                    });
                }
                // if this isn't a link, prevent the page from being redirected
                if (checkElement.is('.treeview-menu')) {
                    e.preventDefault();
                }
            });
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments); // Allows ember-resize to unbind listeners

            this.$().off('click.sidebar_tree');
        }
    });
});