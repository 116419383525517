define('tm3/pods/matter/subjects/info/db-memos/find-db/route', ['exports', 'tm3/mixins/search-db-route'], function (exports, _searchDbRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_searchDbRoute.default, {
        notify: Ember.inject.service(),
        queryParams: {
            subject_id: {
                refrehModel: true
            }
        },

        renderTemplate: function renderTemplate() {
            this.render({ into: 'matter.subjects' });
        },


        actions: {
            createDbMemo: function createDbMemo(selectedDatabases) {
                var _this = this;

                if (typeof selectedDatabases.get('id') !== 'undefined') {
                    selectedDatabases = [selectedDatabases];
                }
                var currentMatter = this.get('currentMatter');
                var promises = [];
                var dbMemo = null;
                this.store.findRecord('matter', currentMatter.id).then(function (matter) {
                    selectedDatabases.forEach(function (db) {
                        dbMemo = _this.store.createRecord('db-memo', {
                            db: db,
                            matter: matter,
                            body: ''
                        });
                        promises.pushObject(dbMemo.save().then(function () {
                            // link dbmemo with subject
                            var rhr = _this.store.createRecord('resource-has-resource', {
                                parentId: dbMemo.get('id'),
                                childId: _this.controller.get('subject_id')
                            });
                            promises.pushObject(rhr.save());
                        }, function () {
                            _this.validationReport(dbMemo);
                        }));
                    });

                    Ember.RSVP.all(promises).then(function () {
                        _this.get('notify').success('Database memos has been created');
                        _this.transitionTo('matter.subjects.info.db-memos', dbMemo.get('matter.id'), _this.controller.get('subject_id'), {
                            queryParams: {
                                refresh: Math.floor(Math.random() * (9000 - 1)) + 1,
                                new_memo_id: dbMemo.get('id')
                            }
                        });
                    });
                });
            }
        }
    });
});