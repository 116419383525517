define('tm3/pods/matter/admin/reports/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'time_reports',

        queryParams: {
            invoice_group: {
                refreshModel: true
            },
            budget: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var model = this.modelFor('matter');

            return Ember.RSVP.hash({
                matter: model.matter,
                invoiceGroups: this.store.query('invoice-group', {
                    matter_id: model.matter.id
                }),
                budgets: this.store.query('budget', {
                    matter_id: model.matter.id
                }),

                employeeTableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id,
                    invoice_group: params.invoice_group ? params.invoice_group : null,
                    budget: params.budget ? params.budget : null,
                    group: 'employee',
                    bill_type: ['Time', 'Piece']
                }),
                typeTableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id,
                    invoice_group: params.invoice_group ? params.invoice_group : null,
                    budget: params.budget ? params.budget : null,
                    group: 'type',
                    bill_type: ['Time', 'Piece']
                }),
                travelTableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id,
                    invoice_group: params.invoice_group ? params.invoice_group : null,
                    budget: params.budget ? params.budget : null,
                    group: 'type',
                    bill_type: ['Travel']
                }),
                proratedTableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id,
                    invoice_group: params.invoice_group ? params.invoice_group : null,
                    budget: params.budget ? params.budget : null,
                    group: 'type',
                    bill_type: ['Pro-Rated']
                }),
                manualTableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: params.perPage ? params.perPage : 20000,
                    page: params.page ? params.page : 1,
                    matter: model.matter.id,
                    invoice_group: params.invoice_group ? params.invoice_group : null,
                    budget: params.budget ? params.budget : null,
                    group: 'type',
                    bill_type: ['Manual']
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                igId: params.invoice_group,
                budgetId: params.budget
            });
        },
        setupController: function setupController(controller, resolved) {
            if (typeof resolved.employeeTableData === 'string') {
                controller.set('showEmployeeWarning', true);
            }
            if (typeof resolved.typeTableData === 'string') {
                controller.set('showTableWarning', true);
            }
            if (typeof resolved.travelTableData === 'string') {
                controller.set('showTravelTableWarning', true);
            }
            if (typeof resolved.proratedTableData === 'string') {
                controller.set('showProratedTableWarning', true);
            }
            if (typeof resolved.manualTableData === 'string') {
                controller.set('showManualTableWarning', true);
            }
            this._super(controller, resolved);

            if (resolved.igId) {
                controller.set('selectedInvoiceGroup', resolved.invoiceGroups.findBy('id', resolved.igId));
                if (Ember.isEmpty(controller.get('selectedInvoiceGroup'))) {
                    controller.set('invoice_group', null);
                }
            } else {
                controller.set('selectedInvoiceGroup', null);
            }
            if (resolved.budgetId) {
                controller.set('selectedBudget', resolved.budgets.findBy('id', resolved.budgetId));
                if (Ember.isEmpty(controller.get('selectedBudget'))) {
                    controller.set('budget', null);
                }
            } else {
                controller.set('selectedBudget', null);
            }
        }
    });
});