define('tm3/pods/components/notification/notification-item/component', ['exports', 'tm3/pods/components/notification/notification-item/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        tagName: 'li',
        classNameBindings: ['isActive'],
        isActive: false,
        notification: null,
        displayLarge: true,
        colorStyle: Ember.computed('notification.notificationType.color', function () {
            var value = this.get('notification.notificationType.color');
            return Ember.String.htmlSafe('color:' + value);
        }),
        actions: {
            dismiss: function dismiss() {
                this.get('notification').set('read', 1);
                this.get('notification').save();
            }
        }
    });
});