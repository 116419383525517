define('tm3/pods/components/time-list-table/table-actions/component', ['exports', 'ember-cli-table-pagination/components/light-table-pagination/table-actions'], function (exports, _tableActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _tableActions.default.extend({
        matterId: Ember.computed('row', function () {
            return this.get('row.content.timeBillables.firstObject.matter.id');
        }),
        billableType: Ember.computed('row', function () {
            return this.get('row.content.type');
        })
    });
});