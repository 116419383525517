define('tm3/pods/components/matter/event-form/component', ['exports', 'tm3/utils/sc-calendar-event-types'], function (exports, _scCalendarEventTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        onInit: Ember.on('init', function () {
            var _this = this;

            this.set('currentEvent.newReport', this.get('store').createRecord('report'));
            this.get('store').query('employee', {
                with_access_to_matter: this.get('currentMatter.id')
            }).then(function (employees) {
                _this.set('employees', employees);
            });
        }),

        startChanged: Ember.observer('currentEvent.start', function () {
            this.set('currentEvent.newReport.dueOn', this.get('currentEvent.start'));
        }),
        titleChanged: Ember.observer('currentEvent.title', function () {
            this.set('currentEvent.newReport.name', this.get('currentEvent.title'));
        }),

        actions: {
            isRange: function isRange(type) {
                var eventTypes = _scCalendarEventTypes.default.secondaryEventTypes;
                var currentType = eventTypes.findBy('name', type);
                return currentType && currentType.range;
            }
        }
    });
});