define('tm3/serializers/document', ['exports', 'active-model-adapter', 'npm:he'], function (exports, _activeModelAdapter, _npmHe) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            if (Ember.isPresent(hash.resource_id)) {
                hash.id = hash.resource_id;
            }

            // decode any html characters found along the way
            if (typeof hash.name === 'string') {
                hash.name = _npmHe.default.decode(hash.name);
            }
            if (typeof hash.description === 'string') {
                hash.description = _npmHe.default.decode(hash.description);
            }

            return this._super(typeClass, hash, prop);
        },
        serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
            var key = relationship.key;
            if (key === 'additionalSubjects') {
                var hasMany = snapshot.hasMany(key, { ids: true });
                if (hasMany !== undefined) {
                    // if provided, use the mapping provided by `attrs` in
                    // the serializer
                    var payloadKey = this._getMappedKey(key, snapshot.type);
                    if (payloadKey === key && this.keyForRelationship) {
                        payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
                    }

                    json[payloadKey] = hasMany;
                }
            }
        }
    });
});