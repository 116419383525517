define('tm3/pods/report/info/fixed-cost-database-report/route', ['exports', 'tm3/mixins/report-info-route', 'moment'], function (exports, _reportInfoRoute, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        lists: Ember.inject.service(),

        apiName: 'fixed_cost_database_report',

        queryParams: {
            from_date: {
                refreshModel: false
            },
            to_date: {
                refreshModel: false
            },
            dbType: {
                refreshModel: false
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    from_date: params.from_date,
                    to_date: params.to_date,
                    db_type: params.dbType
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                from_date: params.from_date,
                to_date: params.to_date
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);

            if (Ember.isEmpty(resolved.from_date)) {
                controller.set('from_date', (0, _moment.default)().subtract(1, 'month').format('YYYY-MM-DD'));
            }

            if (Ember.isEmpty(resolved.to_date)) {
                controller.set('to_date', (0, _moment.default)().format('YYYY-MM-DD'));
            }
        },


        actions: {
            makeReportRequest: function makeReportRequest() {
                this.refresh();
            }
        }
    });
});