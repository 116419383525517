define('tm3/serializers/matter-has-user', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            if (typeof hash.user_id !== 'undefined') {
                hash.employee_id = hash.user_id;
            }
            return this._super(typeClass, hash, prop);
        }
    });
});