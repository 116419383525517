define('tm3/pods/matter/admin/doc-reports/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        lists: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        model: function model() {
            var matterId = this.get('currentMatter.id');
            return Ember.RSVP.hash({
                reports: this.store.query('report', {
                    matter_id: matterId,
                    sort: 'name'
                }),
                reportTypes: this.get('lists').getListForGroup('report_types')
            });
        },

        actions: {
            save: function save(report) {
                var _this = this;

                report.set('matter', this.get('currentMatter.matter'));
                report.save().then(function () {
                    _this.get('notify').success('Report saved succesfully');
                    _this.controller.set('newReport', null);
                    report.set('isEditing', false);
                    _this.refresh();
                }).catch(function () {
                    _this.validationReport(report);
                });
            },
            deleteReport: function deleteReport(report) {
                var _this2 = this;

                report.destroyRecord().then(function () {
                    _this2.get('notify').success('Report deleted succesfully');
                    _this2.refresh();
                }).catch(function () {
                    _this2.validationReport(report);
                });
            },
            cancel: function cancel() {
                this.controller.set('newReport', null);
            }
        }
    });
});