define('tm3/pods/admin/phrases/controller', ['exports', 'tm-common/mixins/crud/error', 'ember-changeset', 'ember-changeset-validations', 'tm3/validators/phrase'], function (exports, _error, _emberChangeset, _emberChangesetValidations, _phrase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        selectedPhrase: null,
        selectedPhraseChangeset: null,

        // Tab Specific Properties
        tabs: [{
            display: 'Billing',
            key: 'billing'
        }],
        currentTab: 'billing',

        // Modal Specific Properties
        showPhraseCreateEditModal: false,
        showPhraseDeleteModal: false,
        modalTitle: Ember.computed('currentTab', function () {
            var title = '';
            var tab = this.get('currentTab');
            switch (tab) {
                default:
                    title = Ember.String.capitalize(tab) + ' Phrase';
                    break;
            }
            return title;
        }),

        actions: {
            changeTab: function changeTab(newTabKey) {
                this.set('currentTab', newTabKey);
            },

            openAddPhraseModal: function openAddPhraseModal() {
                var newPhrase = this.store.createRecord('phrase', {
                    phraseType: this.get('currentTab'),
                    createdUser: this.get('model.current_user'),
                    updatedUser: this.get('model.current_user'),
                    includeVariables: false,
                    softDeleted: false
                });
                var newChangeset = new _emberChangeset.default(newPhrase, (0, _emberChangesetValidations.default)(_phrase.default), _phrase.default);
                this.set('selectedPhrase', newPhrase);
                this.set('selectedPhraseChangeset', newChangeset);
                this.set('showPhraseCreateEditModal', true);
            },

            openEditPhraseModal: function openEditPhraseModal(phrase) {
                var existingChangeset = new _emberChangeset.default(phrase, (0, _emberChangesetValidations.default)(_phrase.default), _phrase.default);
                this.set('selectedPhrase', phrase);
                this.set('selectedPhraseChangeset', existingChangeset);
                this.set('showPhraseCreateEditModal', true);
            },

            resetSelectedPhrase: function resetSelectedPhrase() {
                if (this.get('selectedPhrase')) {
                    this.get('selectedPhrase').rollbackAttributes();
                }
                this.set('selectedPhrase', null);
                if (this.get('selectedPhraseChangeset')) {
                    this.get('selectedPhraseChangeset').rollback();
                }
                this.set('selectedPhraseChangeset', null);
                this.set('showPhraseCreateEditModal', false);
                this.set('showPhraseDeleteModal', false);
                this.send('refreshModel');
            },

            saveSelectedPhrase: function saveSelectedPhrase(isNew) {
                var _this = this;

                var phrase = this.get('selectedPhrase');
                var changeset = this.get('selectedPhraseChangeset');
                if (changeset.get('isDirty')) {
                    // force the update timestamp & update user
                    changeset.set('updated', new Date());
                    changeset.set('updatedUser', this.get('model.current_user'));
                }
                return changeset.validate().then(function () {
                    if (changeset.get('isInvalid')) {
                        _this.get('notify').error('Please review the validation errors');
                        var errorMsg = '';
                        changeset.get('errors').forEach(function (error) {
                            error.validation.forEach(function (msg) {
                                errorMsg = errorMsg + msg + '<br/>';
                            });
                        });
                        _this.get('notify').error({ html: errorMsg });
                    } else {
                        changeset.save().then(function () {
                            _this.get('notify').success(isNew ? 'Phrase Created!' : 'Phrase Updated!');
                            _this.set('selectedPhrase', null);
                            _this.set('selectedPhraseChangeset', null);
                            _this.set('showPhraseCreateEditModal', false);
                            _this.send('refreshModel');
                        }).catch(function () {
                            _this.validationReport(changeset);
                        });
                    }
                }).catch(function () {
                    _this.get('notify').error('Error validating the phrase');
                });
            },

            openDeletePhraseModal: function openDeletePhraseModal(phrase) {
                var existingChangeset = new _emberChangeset.default(phrase, (0, _emberChangesetValidations.default)(_phrase.default), _phrase.default);
                this.set('selectedPhrase', phrase);
                this.set('selectedPhraseChangeset', existingChangeset);
                this.set('showPhraseDeleteModal', true);
            },

            deleteSelectedPhrase: function deleteSelectedPhrase() {
                var _this2 = this;

                var changeset = this.get('selectedPhraseChangeset');

                changeset.set('softDeleted', 1);

                // force the update timestamp & update user
                changeset.set('updated', new Date());
                changeset.set('updatedUser', this.get('model.current_user'));

                changeset.validate().then(function () {
                    if (changeset.get('isInvalid')) {
                        _this2.get('notify').error('Please review the validation errors');
                        var errorMsg = '';
                        changeset.get('errors').forEach(function (error) {
                            error.validation.forEach(function (msg) {
                                errorMsg = errorMsg + msg + '<br/>';
                            });
                        });
                        _this2.get('notify').error({ html: errorMsg });
                    } else {
                        changeset.save().then(function () {
                            _this2.get('notify').success('Phrase has been removed');
                            _this2.set('selectedPhrase', null);
                            _this2.set('selectedPhraseChangeset', null);
                            _this2.set('showPhraseDeleteModal', false);
                            _this2.send('refreshModel');
                        }).catch(function (results) {
                            _this2.validationReport(results);
                        });
                    }
                });
            }
        }
    });
});