define('tm3/mixins/notifications-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),
        notifications: Ember.inject.service(),
        queryParams: {
            dateRange: {
                refreshModel: true
            }
        },
        model: function model(params) {
            var _this = this;

            var userId = this.get('session').get('data.authenticated.id');
            var notificationOptions = {
                user_id: userId,
                with: 'matters'
            };
            if (params.dateRange) {
                notificationOptions.date_range = params.dateRange;
            }
            return this.store.query('notification', notificationOptions).then(function (notifications) {
                return Ember.RSVP.hash({
                    notifications: notifications,
                    related: _this.get('notifications.loadRelated').perform(notifications),
                    notificationTypes: _this.get('notifications.getNotificationTypes').perform()
                });
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('notificationTypes', resolved.notificationTypes);
            var notifications = resolved.notifications;
            controller.set('allNotifications', notifications);
            controller.set('tasks', resolved.related[0]);
            controller.set('notes', resolved.related[1]);
            controller.set('scrapes', resolved.related[2]);
            controller.set('documents', resolved.related[3]);
            controller.set('typeNames', notifications.mapBy('notificationType').mapBy('content.label').uniq());
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});