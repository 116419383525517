define('tm3/pods/crm/contacts/edit/client-numbers/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        actions: {
            /**
             * hide number form
             */
            hideForm: function hideForm() {
                Ember.$('#numberForm').addClass('hidden');
            },


            /**
             * show number form
             */
            showForm: function showForm() {
                Ember.$('#numberForm').removeClass('hidden');
                this.set('newNumber', this.store.createRecord('user-number'));
            },


            /**
             * save a new phone number and reset for next run
             */
            save: function save() {
                var self = this;
                var userNumber = this.get('newNumber');
                userNumber.set('user', this.get('model'));

                userNumber.save().then(function (data) {
                    self.set('newNumber', { type: '', primary: '', number: '', ext: '' });
                    self.send('hideForm');
                    self.get('notify').success('Number Created');
                }, function (reason) {
                    userNumber.deleteRecord();
                    self.validationReport(userNumber);
                });
            },


            /**
             * remove existing phone number
             * @param item
             */
            delete: function _delete(item) {
                var self = this;
                item.destroyRecord().then(function (data) {
                    self.get('notify').success('Phone Number Removed');
                }, function (reason) {
                    self.validationReport(item);
                });
            }
        }
    });
});