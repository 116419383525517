define('tm3/pods/crm/contacts/info/merge/controller', ['exports', 'tm-common/mixins/crud/error', 'ember-lifeline/mixins/run'], function (exports, _error, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var MergeOption = Ember.Object.extend({ id: '', name: '', contact: null });

    exports.default = Ember.Controller.extend(_error.default, _run.default, {
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),

        selectedContacts: [],

        actions: {
            // connection related logic
            hideMergeForm: function hideMergeForm() {
                Ember.$('#mergeForm').addClass('hidden');
            },
            showMergeForm: function showMergeForm() {
                Ember.$('#mergeForm').removeClass('hidden');
            },
            removeSelectedContact: function removeSelectedContact(item) {
                var selectedContacts = this.get('selectedContacts');

                selectedContacts.removeObject(item);

                this.set('selectedContacts', selectedContacts);
            },
            mergeContacts: function mergeContacts() {
                if (window.confirm('Are you sure you want to merge these two contacts?')) {
                    var self = this;

                    var selectedContacts = this.get('selectedContacts');

                    var primaryEmail = false;
                    var primaryEmailInputs = Ember.$("input[name='primary_email']");
                    Ember.$(primaryEmailInputs).each(function (ndx, input) {
                        if (input.checked) {
                            primaryEmail = Ember.$(input).val();
                        }
                    });

                    if (!primaryEmail) {
                        this.get('notify').warning('No primary email selected');
                    } else {
                        var merges = [];
                        selectedContacts.forEach(function (contact) {
                            merges.push(contact.get('id'));
                        });
                        var data = {
                            primary_email: primaryEmail,
                            primary_contact: this.get('contact').get('id'),
                            merge_contacts: merges
                        };

                        // Can't use the memberAction here b/c of the contentType being always application/json
                        this.get('ajax').post('clients/merge', {
                            data: data,
                            contentType: 'application/x-www-form-urlencoded; charset=utf-8'
                        }).then(function () {
                            self.runTask(function () {
                                self.set('selectedOptions', []);
                                self.get('notify').success("Contact's were successfully merged.");
                                self.send('refreshModel');
                                self.transitionToRoute('crm.contacts.info.activity', self.get('contact').get('id'));
                            }, 1);
                        });
                    }
                }
            },


            // triggers when a value has been selected in the auto suggest
            selectClient: function selectClient(mergeOption) {
                var selectedContacts = this.get('selectedContacts');

                selectedContacts.pushObject(mergeOption.get('contact'));

                this.set('selectedContacts', selectedContacts);
                this.set('selectedClient', undefined);
            },


            /** power-select for clients */
            searchClient: function searchClient(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchClient, term, resolve, reject, 600);
                });
            }
        },
        _performSearchClient: function _performSearchClient(term, resolve, reject) {
            var _this2 = this;

            return this.store.query('client', {
                'first_name||last_name': '*' + term + '*',
                limit: 100,
                sortField: 'last_name'
            }).then(function (mergeOptions) {
                var optionsList = [];
                // build generic object from each account object and add to optionsList array
                var filteredMergeOptions = mergeOptions.filter(function (item, index, enumberable) {
                    var contactId = this.get('id');
                    var possibleMergeOptionId = item.get('id');
                    if (contactId === possibleMergeOptionId) {
                        return false;
                    }
                    return true;
                }, _this2.get('contact'));

                filteredMergeOptions.forEach(function (contact) {
                    var item = MergeOption.create({
                        id: contact.get('id'),
                        name: contact.get('fullName'),
                        contact: contact
                    });
                    optionsList.pushObject(item);
                });
                resolve(optionsList);
            });
        }
    });
});