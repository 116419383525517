define('tm3/pods/accounting/billing/subscription-costs/processes/run/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            start: {
                refreshModel: true
            },
            end: {
                refreshModel: true
            }
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'accounting.billing' });
        },
        model: function model(params) {
            var start = params['start'];
            var end = params['end'];
            var queryParams = {
                with: 'matters',
                custom_endpoint: 'invoices/eligible_prorate_subscription_costs'
            };
            if (start && end) {
                queryParams.start = start;
                queryParams.end = end;
            } else {
                var current = (0, _moment.default)();
                queryParams.start = current.startOf('month').format('YYYY-MM-DD');
                queryParams.end = current.endOf('month').format('YYYY-MM-DD');
            }
            return this.store.query('invoice', queryParams);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.calculateLastMonths();
            controller.setSelectedMonth();
        }
    });
});