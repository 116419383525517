define('tm3/pods/components/accounting/billing/status-button-group/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        btnType: 'default',
        invoice: null,

        canSendAction: function canSendAction(status) {
            switch (status) {
                case 'Level 1 Complete':
                    return this.get('toLevel1Class') === '';
                case 'Level 2 Complete':
                    return this.get('toLevel2Class') === '';
                case 'Billed':
                    return this.get('toBilledClass') === '';
            }
        },

        toLevel1Class: Ember.computed('invoice.status', 'invoice.canGoToLevel1', function () {
            if (this.get('invoice.canGoToLevel1')) {
                return '';
            }
            return 'disabled';
        }),

        toLevel2Class: Ember.computed('hasPreliminaryEntries', 'invoice.status', function () {
            if (this.get('invoice.canGoToLevel2')) {
                return '';
            }
            return 'disabled';
        }),

        toBilledClass: Ember.computed('hasPreliminaryEntries', 'invoice.status', 'invoice.canGoToBilled', function () {
            if (this.get('invoice.canGoToBilled')) {
                return '';
            }
            return 'disabled';
        }),

        actions: {
            changeStatus: function changeStatus(newStatus) {
                if (this.canSendAction(newStatus)) {
                    this.sendAction('changeStatus', newStatus);
                }
            }
        }
    });
});