define('tm3/mixins/matter-analytics-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var applLists = ['user_status_options', 'relationship_of_coplaintiff_options', 'parties_in_court_options', 'case_status'];

    exports.default = Ember.Mixin.create({
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                plaintiff: this.store.queryRecord('subject', {
                    matter_id: this.get('currentMatter.id'),
                    subject_type: 'plaintiff_subjects',
                    sortField: '-updated_on'
                }),
                decedent: this.store.queryRecord('subject', {
                    matter_id: this.get('currentMatter.id'),
                    subject_type: 'decedent_subjects',
                    sortField: '-updated_on'
                }),

                initialProducts: this.get('currentMatter.matter').searchProductNames({
                    name: '**'
                }),

                matter: this.get('currentMatter.matter'),

                lists: this.get('lists').fetchMultipleLists(applLists)
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            applLists.forEach(function (item) {
                controller.set(item, model.lists[item]);
            });

            if ('products' in model.initialProducts && model.initialProducts.products) {
                controller.set('initialProducts', model.initialProducts.products.map(function (p) {
                    return p.name;
                }));
            } else {
                controller.set('initialProducts', []);
            }
            controller.setupChangeset();
        }
    });
});