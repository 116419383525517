define('tm3/pods/components/table-filter-components/case-group/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        onInit: Ember.on('init', function () {
            var _this = this;

            this.get('store').query('collection', {
                sort: 'name',
                name: '!=Global View'
            }).then(function (collections) {
                if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
                    _this.set('collections', collections.map(function (mt) {
                        return mt.get('name');
                    }));
                }
            });
        })
    });
});