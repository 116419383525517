define('tm3/pods/crm/todos/controller', ['exports', 'tm-common/mixins/crud/error', 'ember-group-by'], function (exports, _error, _emberGroupBy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        selectedOptions: [],

        queryParams: ['assigned_to_id', 'archived', 'categories'],
        assigned_to_id: 'me',
        archived: false,
        selectedCategories: [],
        categories: [],

        // selectedAssigned: function () {
        //    return this.get('assigned_to_id');
        // }.observes('assigned_to_id'),
        // currentUser: function () {
        //    return this.session.get('id');
        // },

        sortProperties: ['dueOn', 'priority'],
        sortedModel: Ember.computed.sort('model', 'sortProperties'),
        groupedTodos: (0, _emberGroupBy.default)('sortedModel', 'dueGroup'),

        selectedItems: [],

        hasSelected: Ember.computed('model.@each.isSelected', function () {
            var todos = this.get('model');
            return todos.filterBy('isSelected', true).get('length');
        }),

        /**
         * @property
         *
         * boolean to check when the button to save multiple todos is spinning
         */
        isSavingCommon: false,

        actions: {
            // triggers when a value has been selected in the auto suggest
            itemSelected: function itemSelected(item) {
                this.set('relatedDeal', this.store.findRecord('deal', item.get('id')));
                // this.transitionToRoute('crm.contacts.info.activity', item.get('id'));
            },
            assignedToChanged: function assignedToChanged(item) {
                if (item === 'my') {
                    item = this.session.get('session.data.authneticated.id');
                }
                this.set('assigned_to_id', item);
            },


            // reset form to empty values
            openTodoForm: function openTodoForm() {
                var newTodo = this.store.createRecord('todo', { priority: 'Normal' });
                this.set('newTodo', newTodo);
                Ember.$('#commonForm').addClass('hidden');
                Ember.$('#todoForm').removeClass('hidden');
            },
            editTodoForm: function editTodoForm(todo) {
                this.set('newTodo', todo);
                Ember.$('#todoForm').removeClass('hidden');
            },
            closeTodoForm: function closeTodoForm() {
                Ember.$('#todoForm').addClass('hidden');
            },
            closeCommonForm: function closeCommonForm() {
                Ember.$('#commonForm').addClass('hidden');
            },
            toggleArchive: function toggleArchive() {
                this.set('archived', !this.get('archived'));
            },
            toggle: function toggle(todo) {
                var selectedItems;
                selectedItems = this.get('selectedItems');
                todo.set('isSelected', !todo.get('isSelected'));
                if (selectedItems.includes(todo)) {
                    return this.set('selectedItems', selectedItems.without(todo));
                } else {
                    return this.get('selectedItems').push(todo);
                }
            },
            toggleSelectAll: function toggleSelectAll() {
                var self = this;
                if (this.get('selectedItems.length') === 0) {
                    this.set('selectedItems', []);
                    this.model.forEach(function (todo) {
                        todo.set('isSelected', true);
                        self.get('selectedItems').push(todo);
                    });
                } else {
                    this.set('selectedItems', []);
                    this.model.forEach(function (todo) {
                        todo.set('isSelected', false);
                    });
                }
            },
            clearSelection: function clearSelection() {
                this.set('selectedItems', []);
                this.model.forEach(function (todo) {
                    todo.set('isSelected', false);
                });
            },
            editSelected: function editSelected() {
                var commonTodo = this.store.createRecord('todo', { priority: 'Normal' });
                this.set('commonTodo', commonTodo);
                Ember.$('#todoForm').addClass('hidden');
                Ember.$('#commonForm').removeClass('hidden');
            },
            changeCategories: function changeCategories(categories) {
                this.set('categories', categories.map(function (c) {
                    return c.get('id');
                }));
            },

            /** power-select for clients */
            searchDeals: function searchDeals(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchDeals, term, resolve, reject, 600);
                });
            },
            changeAssignedTo: function changeAssignedTo(assignedTo) {
                this.set('assignedTo', assignedTo);
                if (assignedTo) {
                    this.set('assigned_to_id', assignedTo.get('id'));
                } else {
                    this.set('assigned_to_id', 'all');
                }
            }
        },
        _performSearchDeals: function _performSearchDeals(term, resolve, reject) {
            return this.store.query('deal', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});