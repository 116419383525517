define('tm3/pods/matter/admin/teams/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_error.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),

        requireAnyPermission: ['Manage All Cases', 'Manage Case'],

        /**
         * load data for page
         */
        model: function model() {
            var matterId = this.get('currentMatter.id');

            return Ember.RSVP.hash({
                teams: this.store.findAll('team'),
                groups: this.store.query('group', {
                    group_type: '*Case'
                }),
                matterTeams: this.store.query('matter-has-team', {
                    matter_id: matterId
                }),
                matter: this.store.findRecord('matter', matterId)
            });
        },


        /**
         * pass to controller
         */
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('matter', resolved.matter);
            controller.set('allTeams', resolved.teams);
            controller.set('allGroups', resolved.groups);
            controller.set('matterTeams', resolved.matterTeams);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },

            /**
             * save a matter-has-team record
             */
            save: function save(team) {
                var _this = this;

                var finishMessage = team.get('isNew') ? 'New team has been added to the case!' : 'Team has been updated on the case!';
                var matter = this.controller.get('matter');
                team.set('matter', matter);
                team.save().then(function () {
                    _this.controller.send('hideForm');
                    _this.get('notify').success(finishMessage);
                    _this.refresh();
                }).catch(function () {
                    _this.validationReport(team);
                });
            }
        }
    });
});