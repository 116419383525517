define('tm3/helpers/moment-from-now-est', ['exports', 'ember-moment/utils/helper-compute', 'ember-moment/helpers/-base'], function (exports, _helperCompute, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        compute: (0, _helperCompute.default)(function (params, _ref) {
            var hideSuffix = _ref.hideSuffix,
                locale = _ref.locale,
                timeZone = _ref.timeZone;

            this._super.apply(this, arguments);
            // this is a hack helper. Because the created At is in UTC-4 (EST db time) we need to fake a UTC time so the format works
            var moment = Ember.get(this, 'moment');
            var date = moment.moment(params[0]);
            var newparams = [];
            newparams[0] = date.add(5, 'hours');
            newparams[1] = params[1];

            return this.morphMoment(moment.moment.apply(moment, newparams), {
                locale: locale,
                timeZone: timeZone
            }).fromNow(hideSuffix);
        })
    });
});