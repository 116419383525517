define('tm3/pods/outbound-communication/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),

        log: function log(sentFrom, matter, users, things) {
            return this.get('ajax').post('outbound_communication_logs', {
                contentType: 'application/json',
                data: {
                    outbound_communication_log: {
                        sent_from: sentFrom,
                        matter_id: matter.get('id'),
                        users: users,
                        things: things
                    }
                }
            });
        }
    });
});