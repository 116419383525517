define('tm3/mixins/case-management/info-route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_error.default, {
        notify: Ember.inject.service(),
        model: function model(params) {
            return Ember.RSVP.hash({
                collection: this.store.findRecord('collection', params.collection_id)
            }).then(function (model) {
                return Ember.RSVP.hash({
                    collection: model.collection,
                    hasAccess: model.collection.hasAccess()
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            if (!('status' in model.hasAccess && model.hasAccess.status === true)) {
                this.get('notify').warning('You don\'t have access to all cases within this case group,\n      therefore this section will not work for you.');
                // this.transitionTo('case-management.list')
            }
        },


        actions: {
            saveNewCase: function saveNewCase(matterHash, collection) {
                var _this = this;

                var mh = matterHash;
                var c = collection;

                mh.forEach(function (matter) {
                    var cc = _this.store.createRecord('collection-has-matter', {
                        matter: matter,
                        collection: c
                    });

                    cc.save().then(function () {
                        _this.set('controller.isAdding', false);
                        _this.refresh();
                    }, function () {
                        _this.validationReport(cc);
                    });
                });
            },
            cancelSaveGroup: function cancelSaveGroup() {
                this.set('controller.isAdding', false);
            },
            invalidateModel: function invalidateModel() {
                this.refresh();
            }
        }
    });
});