define('tm3/pods/components/crm/show-tags/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        isEditing: false,

        initialTags: [],
        selectedTags: [],

        tagList: [],

        refresh: '',

        // use this to create a matching list of just plain tags, on the parent-tags that are passed in
        watchTags: Ember.on('init', Ember.observer('tagList', function () {
            var tagList = this.get('tagList');
            var justTagListA = [];
            var justTagListB = [];
            tagList.forEach(function (item) {
                justTagListA.pushObject(item.get('crmTag'));
                justTagListB.pushObject(item.get('crmTag'));
            });
            this.set('selectedTags', justTagListA);
            this.set('initialTags', justTagListB);
        })),

        actions: {
            edit: function edit() {
                this.set('isEditing', true);
            },
            cancel: function cancel() {
                this.sendAction('refresh');
                this.set('isEditing', false);
            },


            // create a brand new tag since this wasn't detected in our list
            createTag: function createTag(select, e) {
                var self = this;
                var store = this.get('store');
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
                    var term = select.searchText;
                    var isExisting = this.get('tags').findBy('name', term);

                    // to not call submit
                    e.preventDefault();
                    // to avoid calling this twice
                    e.stopPropagation();

                    if (!isExisting) {
                        var newTag = store.createRecord('crm-tag', { name: term });

                        newTag.save().then(function () {
                            // register new tag in the widget
                            var tags = self.get('tags').toArray();
                            tags.pushObject(newTag);
                            self.set('tags', tags);
                            var selectedTags = self.get('selectedTags');
                            selectedTags.pushObject(newTag);
                            select.actions.close(e);
                        }, function (reason) {
                            self.validationReport(newTag);
                        });
                    }
                }
            },
            save: function save() {
                var _this = this;

                var promises = [];
                // new code to add/remove tags on save
                var initialTags = this.get('initialTags');
                var modifiedTags = this.get('selectedTags');

                var tagsToRemove = initialTags.filter(function (t) {
                    return Ember.isEmpty(modifiedTags.findBy('id', t.get('id')));
                });
                var tagsToAdd = modifiedTags.filter(function (t) {
                    return Ember.isEmpty(initialTags.findBy('id', t.get('id')));
                });

                tagsToRemove.map(function (tag) {
                    var tagList = _this.get('tagList');
                    var matchList = tagList.filterBy('crmTag', tag);

                    matchList.forEach(function (parentTag) {
                        promises.pushObject(parentTag.destroyRecord());
                    });
                });
                tagsToAdd.map(function (tag) {
                    var parentType = _this.get('type');
                    var modelName = 'crm-tag-has-' + parentType;

                    var store = _this.get('store');
                    var newTag = {
                        crmTag: tag
                    };
                    newTag[parentType] = _this.get('parent');
                    newTag = store.createRecord(modelName, newTag);
                    promises.pushObject(newTag.save().catch(function () {
                        return _this.validationReport(newTag);
                    }));
                });

                Ember.RSVP.all(promises).then(function () {
                    _this.send('cancel');
                });
            }
        }
    });
});