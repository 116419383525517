define('tm3/pods/components/case-management/info/case-group-edit-case-form/component', ['exports', 'tm3/pods/components/case-management/info/case-group-edit-case-form/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        store: Ember.inject.service(),

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this._super.apply(this, arguments);

            this._performSearchGroups('', function (groups) {
                _this.set('options', groups);
            });
        },


        actions: {
            /** power-select for case groups */
            searchGroups: function searchGroups(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchGroups, term, resolve, reject, 600);
                });
            }
        },

        _performSearchGroups: function _performSearchGroups(term, resolve, reject) {
            return this.get('store').query('collection', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});