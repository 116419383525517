define('tm3/services/confirm-action', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        deferred: null,
        message: null,
        preFormatted: false,
        showConfirmActionModal: false,
        title: null,

        /**
            Returns a deferred Promise to the invoking context (that can be awaited
            for a user response).
             @param {Object} object containing the desired `title` and `message`
            @returns {Promise}
         */
        ask: function ask(_ref) {
            var title = _ref.title,
                message = _ref.message,
                preFormatted = _ref.preFormatted;

            this.setProperties({
                deferred: Ember.RSVP.defer(),
                message: message,
                showConfirmActionModal: true,
                title: title,
                preFormatted: preFormatted
            });

            return this.get('deferred.promise');
        },


        /**
            Resets relevant values to their defaults.
             @method reset
         */
        reset: function reset() {
            this.setProperties({
                deferred: null,
                message: null,
                showConfirmActionModal: false,
                title: null
            });
        },


        actions: {
            /**
                Handles the `ConfirmActionModalComponent` _No_ button click.
                 @method cancel
             */
            cancel: function cancel() {
                this.get('deferred').resolve(false);
                this.reset();
            },


            /**
                Handles the `ConfirmActionModalComponent` _Yes_ button click.
                 @method cancel
             */
            confirm: function confirm() {
                this.get('deferred').resolve(true);
                this.reset();
            }
        }
    });
});