define('tm3/pods/report/info/revenue-summary/controller', ['exports', 'tm3/mixins/report-info-controller', 'xlsx'], function (exports, _reportInfoController, _xlsx) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['start', 'end', 'perspective', 'onlyBilled'],

        start: '',
        end: '',

        perspective: 'caseType',

        onlyBilled: false,

        perspectiveChanged: Ember.observer('perspective', function () {
            this.set('year', '');
            this.set('state', '');
        }),

        actions: {
            clearStart: function clearStart() {
                this.set('start', '');
            },
            clearEnd: function clearEnd() {
                this.set('end', '');
            },
            downloadExcel: function downloadExcel() {
                var table = document.querySelector('table.revenue-summary');
                var workbook = _xlsx.default.utils.table_to_book(table, { sheet: 'revenue-summary' });

                _xlsx.default.writeFile(workbook, 'revenue-summary.xls');
            }
        }
    });
});