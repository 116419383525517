define('tm3/pods/matter/documents/search/route', ['exports', 'tm3/mixins/document-search-route'], function (exports, _documentSearchRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_documentSearchRoute.default, {
        docTypeList: 'jury_doc_type_tm',

        renderTemplate: function renderTemplate() {
            this.render({ into: 'matter' });
        }
    });
});