define('tm3/pods/admin/employees/info/skills/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tm-common/mixins/authorized-route-mixin'], function (exports, _authenticatedRouteMixin, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authorizedRouteMixin.default, {
        allowedGroups: ['System - Administrator', 'System - ROOT'],

        model: function model() {
            var infoModel = this.modelFor('admin.employees.info');

            var viewData = {
                employee: this.store.findRecord('employee', infoModel.employee.get('id')),
                user: this.store.findRecord('user', infoModel.employee.get('id')),
                skills: this.store.query('skill', {
                    sort: 'name'
                }),
                userSkills: this.store.query('user-has-skill', {
                    user_id: infoModel.employee.get('id'),
                    with: 'all'
                })
            };
            return Ember.RSVP.hash(viewData);
        },

        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});