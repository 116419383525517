define('tm3/pods/components/crm/contacts/info/matters/edit-connection/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),

        actions: {
            cancel: function cancel() {
                this.set('currentConnection', null);
                this.set('showForm', false);
            }
        }
    });
});