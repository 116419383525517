define('tm3/pods/crm/contacts/edit/client-numbers/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),

        model: function model(params) {
            var clientId = this.modelFor('crm.contacts.edit').get('id');

            return Ember.RSVP.hash({
                model: this.store.queryRecord('user', {
                    id: clientId,
                    with: 'user_numbers'
                }),
                phoneTypes: this.get('lists').getListForGroup('phone_type')
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('phoneTypes', resolved.phoneTypes);
        }
    });
});