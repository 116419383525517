define('tm3/pods/components/crm/new-comment/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        body: '',

        actions: {
            save: function save(model) {
                var self = this;
                var parent = this.get('parent');

                var formData = {
                    body: this.get('body'),
                    parent: parent
                };
                var store = this.get('store');

                var comment = store.createRecord('crm-note', formData);
                comment.save().then(function (child) {
                    self.get('notify').success('Comment added');
                    self.set('body', '');
                }, function (reason) {
                    self.validationReport(comment);
                });
            }
        }
    });
});