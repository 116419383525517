define('tm3/pods/components/admin/teams/user-team/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        team: null,
        userTeam: null,

        actions: {
            save: function save() {
                var _this = this;

                var userTeam = this.get('userTeam');
                userTeam.set('team', this.get('team'));
                var isNew = userTeam.get('isNew');
                userTeam.save().then(function () {
                    _this.$().closest('.modal').modal('hide');
                    _this.get('notify').success('User has been saved');
                    if (isNew) {
                        _this.sendAction('refresh');
                    }
                }, function () {
                    _this.validationReport(userTeam);
                });
            },


            /** power-select for client */
            searchClient: function searchClient(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchClient, term, resolve, reject, 600);
                });
            }
        },

        _performSearchClient: function _performSearchClient(term, resolve, reject) {
            var params = {
                'first_name||last_name': '*' + term + '*',
                limit: 100,
                sortField: 'last_name'
            };

            if (this.get('team.mode') === 'Employee') {
                params['employees:role'] = ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'];
                params['with'] = 'employees';
                params['user_type'] = 'employee';
            } else {
                params['user_type'] = 'client';
            }

            return this.get('store').query('user', params).then(function (items) {
                return resolve(items);
            });
        }
    });
});