define('tm3/pods/crm/contacts/info/access/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var parentParams = this.paramsFor('crm.contacts.info');
            var id = parentParams.user_id;
            return Ember.RSVP.hash({
                model: this.store.query('client', { id: id, with: 'user_numbers,user_addrs,user_urls' }),
                user: this.store.query('user', { id: id, with: 'extra_data' }).then(function (users) {
                    return users.get('firstObject');
                }),
                userHasTeams: this.store.query('user-has-team', { user_id: id }),
                clientTeams: this.store.query('team', { mode: 'Contact' })
            });
        },
        setupController: function setupController(controller, resolved) {
            var model = resolved.model.get('firstObject');
            this._super(controller, model);

            controller.set('user', resolved.user);
            controller.set('userHasTeams', resolved.userHasTeams);

            var availableClientTeams = [];
            resolved.clientTeams.forEach(function (team) {
                var matchedTeam = resolved.userHasTeams.find(function (item, index, enumberable) {
                    var existingTeamName = item.get('team').get('name');
                    var proposedTeamName = this.get('name');
                    if (existingTeamName === proposedTeamName) {
                        return true;
                    }
                }, team);

                if (typeof matchedTeam === 'undefined') {
                    availableClientTeams.push(team);
                }
            });

            controller.set('clientTeams', availableClientTeams);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            },
            didTransition: function didTransition() {
                Ember.$('#addUserToTeamSpinner').addClass('hidden');
            }
        }
    });
});