define('tm3/pods/components/modals/report-component/create-update/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isGlobal: false,

        allTemplates: Ember.computed('reportComponentTemplates.[]', function () {
            var result = [];
            if (this.get('reportComponentTemplates')) {
                this.get('reportComponentTemplates').forEach(function (template) {
                    result.push(Ember.ObjectProxy.create({
                        content: template,
                        selected: false
                    }));
                });
            }
            return result;
        }),

        selectedTemplates: Ember.computed('allTemplates.[]', 'allTemplates.@each.selected', function () {
            return this.get('allTemplates').filterBy('selected');
        }),

        actions: {
            applyTemplates: function applyTemplates() {
                var newText = this.get('selectedTemplates').mapBy('content.text').join('\n');
                this.set('model.text', newText);
            },
            saveChanges: function saveChanges() {
                this.sendAction('save');
            },
            cancelChanges: function cancelChanges() {
                this.get('model').rollbackAttributes();
                this.sendAction('cancel');
            }
        }
    });
});