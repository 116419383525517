define('tm3/pods/components/admin/appl-lists-categories/display/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),

        showCategoryForm: false,
        showItemForm: false,

        currentPage: 0,

        applListTotalPages: Ember.computed('totalRecords', function () {
            var totalRecords = this.get('totalRecords');
            if (totalRecords) {
                var pageArr = Ember.A();
                for (var i = 0; i < parseInt(Math.ceil(totalRecords / 50)); i++) {
                    pageArr.push({ pageNum: i + 1 });
                }

                return pageArr.get('length') > 1 ? pageArr : false;
            } else {
                return false;
            }
        }),

        currentPageApplListItems: Ember.computed('applListFilter', 'currentPage', 'applList', function () {
            var self = this;

            var currentPage = this.get('currentPage');
            var applList = this.get('applList');
            var applListFilter = this.get('applListFilter');

            var matchedApplListItems = Ember.A();
            if (Ember.isEmpty(applListFilter)) {
                matchedApplListItems = applList;
            } else {
                matchedApplListItems = applList.filter(function (applListItem) {
                    var search1 = applListItem.get('display').toLowerCase().indexOf(self.get('applListFilter').toLowerCase()) >= 0;

                    if (search1) {
                        return search1;
                    } else {
                        return applListItem.get('value').toLowerCase().indexOf(self.get('applListFilter').toLowerCase()) >= 0;
                    }
                });
            }

            if (matchedApplListItems && matchedApplListItems.get('length') > 0) {
                var offset = parseInt(currentPage) * 50;
                this.set('totalRecords', matchedApplListItems.get('length'));
                return matchedApplListItems.slice(offset, offset + 50);
            } else {
                return false;
            }
        }),

        listSort: ['weight'],
        currentPageApplListItemsSorted: Ember.computed.sort('currentPageApplListItems', 'listSort'),

        filteredApplListCategories: Ember.computed('applListsCategories', 'searchFilter', function () {
            var searchFilter = this.get('searchFilter');

            return this.get('applListsCategories').filter(function (applListCategory) {
                if (Ember.isEmpty(searchFilter)) {
                    return true;
                }
                return applListCategory.get('categoryName').toLowerCase().indexOf(searchFilter) >= 0;
            });
        }),

        actions: {
            toggleCategoryForm: function toggleCategoryForm() {
                var currentState = this.get('showCategoryForm');
                if (currentState) {
                    this.set('showCategoryForm', false);
                } else {
                    this.set('showCategoryForm', true);
                }
            },
            toggleItemForm: function toggleItemForm() {
                var currentState = this.get('showItemForm');
                if (currentState) {
                    this.set('newItem', Ember.Object.create({ display: '', value: '', order: 0 }));
                    this.set('showItemForm', false);
                } else {
                    this.set('showItemForm', true);
                }
            },
            saveCategory: function saveCategory() {
                var newCategory = this.get('newCategory');
                if (newCategory.get('categoryName') === '') {
                    this.get('notify').error('You must enter a Category Name');
                } else {
                    this.attrs.saveApplListCategory(newCategory);
                }
            },
            saveItem: function saveItem(item) {
                if (item.get('display') === '' || item.get('value') === '') {
                    this.get('notify').error("You must enter a 'Display' and a 'Value'");
                } else {
                    this.attrs.saveApplListItem(item);
                }
            },
            changePage: function changePage(page) {
                this.set('currentPage', page);
            },
            openList: function openList(category) {
                this.attrs.openList(category);
                this.set('currentPage', 0);
            }
        }
    });
});