define('tm3/pods/crm/contacts/edit/client-addrs/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        model: function model() {
            var clientId = this.modelFor('crm.contacts.edit').get('id');

            return Ember.RSVP.hash({
                model: this.store.queryRecord('user', {
                    id: clientId,
                    with: 'user_addrs'
                }),
                lists: this.get('lists').fetchMultipleLists(['States', 'countries', 'address_type']),
                linkedAccounts: this.store.query('firm-has-client', {
                    user_id: clientId,
                    with: 'firms'
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('states', resolved.lists['States']);
            controller.set('countries', resolved.lists['countries']);
            controller.set('addressTypes', resolved.lists['address_type']);
            controller.set('linkedAccounts', resolved.linkedAccounts.reject(function (fhc) {
                // remove alreaday linked accounts
                return resolved.model.get('userAddrs').findBy('firm.id', fhc.get('firm.id')) || fhc.get('relationship') !== 'Current Employer';
            }));
        },


        actions: {
            fetch: function fetch() {
                this.refresh();
            }
        }
    });
});