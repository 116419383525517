define('tm3/pods/components/matter/workflows/custom-row/component', ['exports', 'ember-light-table/components/lt-row', 'tm-common/mixins/analytics-util'], function (exports, _ltRow, _analyticsUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ltRow.default.extend(_analyticsUtil.default, {
        // Color codes for different subject statuses
        // This should be in order of less priority to more priority on class names
        classNameBindings: ['hardshipLocation'],

        hardshipLocation: Ember.computed.equal('row.location', 'hardship'),
        analyticsData: Ember.computed('row.content', 'extra.analyticsLists.[]', function () {
            var subject = this.get('row.content');
            var analyticsLists = this.get('extra.analyticsLists');

            if (subject && analyticsLists) {
                return {
                    subject: subject,
                    editMode: false,
                    statesOptions: this.buildStatesOptions(subject, this.filterAnalyticsLists('states')),
                    propertyOptions: analyticsLists.property,
                    civilOptions: this.buildCivilOptions(subject, this.filterAnalyticsLists('civil_analysis')),
                    criminalOptions: this.buildCriminalOptions(subject, this.filterAnalyticsLists('criminal_analysis'), this.filterAnalyticsLists('criminal_charges'), this.filterAnalyticsLists('criminal_rights')),
                    trafficOptions: this.buildTrafficOptions(subject, this.filterAnalyticsLists('traffic_analysis')),
                    employmentOptions: this.buildEmploymentOptions(subject, this.filterAnalyticsLists('employment_status'), this.filterAnalyticsLists('occupations'), this.filterAnalyticsLists('employment_analysis'), this.filterAnalyticsLists('employment_analysis_category')),
                    educationOptions: this.buildEducationOptions(subject, this.filterAnalyticsLists('education_analysis')),
                    socnetOptions: this.buildSocnetOptions(subject, this.filterAnalyticsLists('socnet_other'), this.filterAnalyticsLists('socnet_themes'), this.filterAnalyticsLists('socnet_themes_hidden'), this.filterAnalyticsLists('socnet_tones')),
                    possibleConnectionsOptions: this.buildPossibleConnectionsOptions(subject, this.filterAnalyticsLists('possible_connections_category')),
                    maritalStatusOptions: this.buildMaritalStatusOptions(subject, this.filterAnalyticsLists('marital_status')),
                    childrenOptions: this.buildChildrenOptions(subject, this.filterAnalyticsLists('has_children'), this.filterAnalyticsLists('children')),
                    employmentTwoOptions: this.buildEmploymentTwoOptions(subject, this.filterAnalyticsLists('employment_type'), this.filterAnalyticsLists('employment_length')),
                    productUseOptions: this.buildProductUseOptions(subject, this.filterAnalyticsLists('product_use'), this.filterAnalyticsLists('product_use_tobacco'), this.filterAnalyticsLists('addiction_withdrawal_experience'), this.filterAnalyticsLists('juror_medical_issues')),
                    otherOptions: this.buildOtherOptions(subject, this.filterAnalyticsLists('political_views'), this.filterAnalyticsLists('media_outlet')),
                    higherEducationOptions: this.buildHigherEducationOptions(subject, this.filterAnalyticsLists('higher_education'), this.filterAnalyticsLists('higher_education_masters'), this.filterAnalyticsLists('higher_education_post_grad')),
                    religionOptions: this.buildReligionOptions(subject, this.filterAnalyticsLists('religious_interests'), this.filterAnalyticsLists('religious_affiliation'), this.filterAnalyticsLists('religious_level_of_interest'))
                };
            }
        }),
        filterAnalyticsLists: function filterAnalyticsLists(listName) {
            return this.get('extra.analyticsLists').filter(function (item, inex, enumerable) {
                if (item.get('categoryName') === listName) {
                    return true;
                }
            });
        }
    });
});