define('tm3/pods/admin/groups/route', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var PermissionObject = Ember.Object.extend(_error.default, {
        id: '',
        name: '',
        description: '',
        isEnabled: false
    });

    exports.default = Ember.Route.extend({
        lists: Ember.inject.service(),
        notify: Ember.inject.service(),

        queryParams: {
            setName: {
                refreshModel: true
            },
            group: {
                refreshModel: true
            }
        },

        init: function init() {
            this._super.apply(this, arguments);

            this.set('requiredPermissions', ['Manage Security']);
        },
        model: function model(params) {
            return Ember.RSVP.hash({
                activeGroupId: params.group,
                activeSetName: params.setName,
                groups: this.store.query('group', {
                    group_type: params.setName,
                    with: 'group_has_permissions',
                    sortField: 'name'
                }),
                permissions: this.store.query('permission', {
                    with: 'permission_has_endpoints'
                }),
                permission_sets: this.get('lists').getListForGroup('permission_sets')
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);

            // add a class property to the permission sets to determine which is active
            resolved.permission_sets.forEach(function (item) {
                var name = item.get('display');
                var activeSet = resolved.activeSetName;

                item.set('class', name === activeSet ? 'active' : '');
            });

            if (resolved.groups.get('length') > 0) {
                var activeGroup = void 0;

                /**
                    add a class property to the groups to determine which is the
                    active one if there is no active group provided from the url
                    params, then set one manually else use the provided group from
                    the url params and match it appropriate group
                 */
                if (resolved.activeGroupId === null) {
                    // first set all of the groups to inactive
                    resolved.groups.forEach(function (item) {
                        item.set('class', '');
                    });

                    // then set the active group
                    activeGroup = resolved.groups.get('firstObject');

                    if (activeGroup) {
                        activeGroup.set('class', 'active');
                    }
                } else {
                    activeGroup = this.store.peekRecord('group', resolved.activeGroupId);
                    resolved.groups.forEach(function (item) {
                        var name = item.get('name');

                        if (name === activeGroup.get('name')) {
                            item.set('class', 'active');
                        } else {
                            item.set('class', '');
                        }
                    });
                }

                /**
                 * build a fake array of of the current groups permissions to work
                 * with setting them to 'inactive' by default
                 */
                var fakePermissionArray = [];

                resolved.permissions.forEach(function (item) {
                    fakePermissionArray.pushObject(PermissionObject.create({
                        id: item.get('id'),
                        name: item.get('name'),
                        description: item.get('description'),
                        permission: item,
                        isEnabled: false
                    }));
                });

                // now loop through the fake permissions array and set the active ones appropriately
                if (typeof activeGroup !== 'undefined') {
                    activeGroup.get('groupHasPermissions').then(function (ghpArray) {
                        ghpArray.forEach(function (ghp) {
                            var permId = ghp.get('permission').get('id');

                            fakePermissionArray.forEach(function (item) {
                                if (item.get('id') === permId) {
                                    Object.assign(item, {
                                        isEnabled: true
                                    });
                                }
                            });
                        });
                    });
                }

                var groupMembers = [];

                this.store.query('user-has-group', {
                    group_id: activeGroup.get('id'),
                    with: 'users'
                }).then(function (items) {
                    items.forEach(function (item) {
                        groupMembers.pushObject({
                            fullName: item.get('user').get('fullName'),
                            lastName: item.get('user').get('lastName')
                        });
                    });

                    groupMembers.sort(function (a, b) {
                        var x = a.lastName.toLowerCase();
                        var y = b.lastName.toLowerCase();

                        // eslint-disable-next-line no-nested-ternary
                        return x < y ? -1 : x > y ? 1 : 0;
                    });
                    controller.set('groupMembers', groupMembers);
                });

                controller.setProperties({
                    permissions: fakePermissionArray,
                    activeGroupId: activeGroup.get('id')
                });
            } else {
                controller.set('permissions', []);
            }

            controller.setProperties({
                permission_sets: resolved.permission_sets,
                groups: resolved.groups,
                activeSet: resolved.activeSetName
            });
        },


        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});