define('tm3/pods/preview/document-events/controller', ['exports', 'tm3/config/environment', 'tm-common/mixins/crud/error', 'tm-common/mixins/timeline-controller'], function (exports, _environment, _error, _timelineController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, _timelineController.default, {
        apiUrl: _environment.default.APP.restDestination,
        color: { code: '#10B4B0', name: 'teal' },

        sortOptionsEvents: ['startDate', 'weight'],
        sortedEvents: Ember.computed.sort('model.events', 'sortOptionsEvents'),
        displayedEvents: Ember.computed('sortedEvents', 'showHidden', function () {
            var events = this.get('sortedEvents');
            if (this.get('showHidden')) {
                return events;
            } else {
                return events.filterBy('viewable', 1);
            }
        }),
        actions: {
            addNewTimelineEvent: function addNewTimelineEvent(subject) {
                this.set('newEventModal', true);
            },
            editTimelineEvent: function editTimelineEvent(event) {
                this.set('currentEvent', event);
                this.set('editEventModal', true);
            }
        }
    });
});