define('tm3/pods/matter/document-new/route', ['exports', 'tm3/mixins/alert-users-route'], function (exports, _alertUsersRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_alertUsersRoute.default, {
        currentMatter: Ember.inject.service(),
        lists: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                model: this.get('currentMatter.matter'),
                fileTypes: this.get('lists').getListForGroup('jury_doc_type_tm'),
                tags: this.store.query('tag', { type: 'document' }),
                reports: this.store.query('report', {
                    matter_id: this.get('currentMatter.id')
                }),
                alertUsers: this.employeesModelMixin()
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved.model);
            controller.set('fileTypes', resolved.fileTypes.sortBy('weight'));
            controller.set('tags', resolved.tags);

            controller.set('alertUsers', resolved.alertUsers);
            controller.set('reports', resolved.reports);
        }
    });
});