define('tm3/pods/crm/activity/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-pager/controller'], function (exports, _controller) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        // load pager specific variables
        columns: [],

        // need to open single matter record
        linkPath: 'crm.activity.info',

        tableTitle: 'Activity',
        sortField: '-created_on',

        titleParams: Ember.computed('noteCategories', function () {
            return {
                categories: this.get('noteCategories'),
                employees: this.get('employees')
            };
        })
    });
});