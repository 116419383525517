define('tm3/pods/report/info/wip-by-case/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        lists: Ember.inject.service(),
        apiName: 'wip_by_case'
    });
});