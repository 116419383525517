define('tm3/pods/report/info/new-cases-detail/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'new_cases_detail_report',

        queryParams: {
            caseType: {
                refreshModel: true
            },
            year: {
                refreshModel: true
            },
            month: {
                refreshModel: true
            },
            state: {
                refreshModel: true
            },
            perspective: {
                refreshModel: true
            }
        },

        /**
         * load reprot and prep some data
         * @param params
         * @returns {*}
         */
        model: function model(params) {
            return Ember.RSVP.hash({
                tableData: this.makeRequest({
                    type: this.get('apiName'),
                    style: 'tabular',
                    per_page: 20000,
                    page: params.page ? params.page : 1,
                    caseType: params.caseType,
                    month: params.month,
                    year: params.year,
                    state: params.state,
                    perspective: params.perspective
                }),
                report: this.get('reportCatalog').getReport(this.get('apiName')),
                perPage: params.perPage ? params.perPage : 50,
                page: params.page ? params.page : 1,
                month: params.month,
                perspective: params.perspective,
                state: params.state
            });
        },
        setupController: function setupController(controller, resolved) {
            this._super(controller, resolved);
            controller.set('perPageOptions', ['50', '100', '500', '1000', '2000']);
            controller.set('months', resolved.months);
            controller.set('month', resolved.month);
            controller.set('state', resolved.state);
            controller.set('perspective', resolved.perspective);
        }
    });
});