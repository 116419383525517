define('tm3/pods/accounting/billing/manual-expense/list/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),

        requireAnyPermission: ['Manage Manual Expenses', 'See Manual Expenses'],

        session: Ember.inject.service(),

        queryParams: {
            selected: {
                refreshModel: false
            },
            filter: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var startFrom = null;
            if (Ember.isPresent(params.filter) && params.filter !== 'all') {
                startFrom = (0, _moment.default)().subtract(parseInt(params.filter, 10), 'month').format('YYYY-MM-DD');
            }

            return Ember.RSVP.hash({
                billableGroups: this.store.query('billable-group', {
                    bg_type: 'Manual',
                    approval_status: 'NULL',
                    include_manual_details: true,
                    with: 'users',
                    limit: 100000,
                    sortField: '-start,-created_on,type',
                    start: '>=' + startFrom
                }),
                params: params
            });
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        },

        setupController: function setupController(controller, resolved) {
            resolved.billableGroups = resolved.billableGroups.filter(function (bg) {
                return bg.get('billablesCount') > 0;
            });

            this._super.apply(this, arguments);

            if (resolved.params.selected) {
                controller.set('activeBillableGroup', resolved.billableGroups.findBy('id', resolved.params.selected));
            } else {
                controller.set('activeBillableGroup', resolved.billableGroups.get('firstObject'));
            }
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('activeBillableGroup', null);
            }
        }
    });
});