define('tm3/utils/nickname-portal-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var nicknameInPortalOptions = exports.nicknameInPortalOptions = [Ember.Object.create({
        display: 'Yes',
        value: 'true'
    }), Ember.Object.create({
        display: 'No',
        value: 'false'
    })];
});