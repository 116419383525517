define('tm3/pods/components/matter/show-tags/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        store: Ember.inject.service(),
        isEditable: Ember.computed('routeTargetName', function () {
            var routeTargetName = this.get('routeTargetName');
            if (routeTargetName === 'matter.detail.edit' || routeTargetName === 'matter.dashboard') {
                return true;
            }
            return false;
        }),

        /**
         * used to toggle component between two display modes
         */
        isEditing: false,

        initialTags: [],
        selectedTags: [],

        tagList: [],

        initialPrivateTags: [],
        selectedPrivateTags: [],

        privateTagList: [],

        /**
         * render component in edit mode
         */
        watchTags: Ember.on('init', Ember.observer('tagList', 'privateTagList', function () {
            var tagList = this.get('tagList');
            var justTagListA = [];
            var justTagListB = [];
            tagList.forEach(function (item) {
                justTagListA.pushObject(item.get('matterTag'));
                justTagListB.pushObject(item.get('matterTag'));
            });
            this.set('selectedTags', justTagListA.sortBy('name'));
            this.set('initialTags', justTagListB.sortBy('name'));

            var privateTagList = this.get('privateTagList');
            var justPrivateTagListA = [];
            var justPrivateTagListB = [];
            privateTagList.forEach(function (item) {
                justPrivateTagListA.pushObject(item.get('matterPrivateTag'));
                justPrivateTagListB.pushObject(item.get('matterPrivateTag'));
            });
            this.set('selectedPrivateTags', justPrivateTagListA.sortBy('name'));
            this.set('initialPrivateTags', justPrivateTagListB.sortBy('name'));
        })),

        actions: {
            edit: function edit() {
                this.set('isEditing', true);
            },


            /**
             * render component in read mode
             */
            cancel: function cancel() {
                this.sendAction('refresh');
                this.set('isEditing', false);
            },


            /**
             * create a brand new tag since this wasn't detected in our list
             */
            createTag: function createTag(term) {
                var self = this;
                var store = this.get('store');
                var newTag = store.createRecord('matter-tag', { name: term });
                newTag.save().then(function () {
                    // register new tag in the widget
                    var tags = self.get('tags').toArray();
                    tags.pushObject(newTag);
                    self.set('tags', tags);
                    var selectedTags = self.get('selectedTags');
                    selectedTags.pushObject(newTag);
                }, function (reason) {
                    self.validationReport(newTag);
                });
            },

            /*
             * create a brand new private tag since this wasn't detected in our list
             */
            createPrivateTag: function createPrivateTag(term) {
                var self = this;
                var store = this.get('store');
                var newTag = store.createRecord('matter-private-tag', { name: term });

                newTag.save().then(function () {
                    // register new tag in the widget
                    var tags = self.get('privateTags').toArray();
                    tags.pushObject(newTag);
                    self.set('tags', tags);
                    var selectedTags = self.get('selectedPrivateTags');
                    selectedTags.pushObject(newTag);
                }, function (reason) {
                    self.validationReport(newTag);
                });
            },


            /**
             * save one or all tags?
             */
            save: function save() {
                var _this = this;

                var promises = [];
                // new code to add/remove tags on save
                // public tags
                var initialTags = this.get('initialTags');
                var modifiedTags = this.get('selectedTags');

                var tagsToRemove = initialTags.filter(function (t) {
                    return Ember.isEmpty(modifiedTags.findBy('id', t.get('id')));
                });
                var tagsToAdd = modifiedTags.filter(function (t) {
                    return Ember.isEmpty(initialTags.findBy('id', t.get('id')));
                });

                tagsToRemove.map(function (tag) {
                    var tagList = _this.get('tagList');
                    var matchList = tagList.filterBy('matterTag', tag);

                    matchList.forEach(function (parentTag) {
                        promises.pushObject(parentTag.destroyRecord());
                    });
                });
                tagsToAdd.map(function (tag) {
                    var parent = _this.get('parent');
                    var modelName = 'matter-has-tag';

                    var store = _this.get('store');
                    var newTag = {
                        matterTag: tag,
                        matter: parent
                    };
                    newTag = store.createRecord(modelName, newTag);
                    promises.pushObject(newTag.save().catch(function () {
                        return _this.validationReport(newTag);
                    }));
                });

                // private tags
                var initialPrivateTags = this.get('initialPrivateTags');
                var modifiedPrivateTags = this.get('selectedPrivateTags');

                var privateTagsToRemove = initialPrivateTags.filter(function (t) {
                    return Ember.isEmpty(modifiedPrivateTags.findBy('id', t.get('id')));
                });
                var privateTagsToAdd = modifiedPrivateTags.filter(function (t) {
                    return Ember.isEmpty(initialPrivateTags.findBy('id', t.get('id')));
                });

                privateTagsToRemove.map(function (tag) {
                    var privateTagList = _this.get('privateTagList');
                    var privateMatchList = privateTagList.filterBy('matterPrivateTag', tag);

                    privateMatchList.forEach(function (parentTag) {
                        promises.pushObject(parentTag.destroyRecord());
                    });
                });
                privateTagsToAdd.map(function (tag) {
                    var parent = _this.get('parent');
                    var modelName = 'matter-has-private-tag';

                    var store = _this.get('store');
                    var newTag = {
                        matterPrivateTag: tag,
                        matter: parent
                    };
                    newTag = store.createRecord(modelName, newTag);
                    promises.pushObject(newTag.save().catch(function () {
                        return _this.validationReport(newTag);
                    }));
                });

                Ember.RSVP.all(promises).then(function () {
                    _this.send('cancel');
                });
            }
        }
    });
});