define('tm3/pods/crm/contacts/info/activity/add-note/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_error.default, {
        notify: Ember.inject.service(),
        notifications: Ember.inject.service(),
        contactsInfoActivityController: Ember.inject.controller('crm/contacts/info/activity'),

        // list of employees who are to be notified
        selectedEmployees: [],
        noteCategories: [],

        // required by auto suggest
        relatedDeal: undefined,

        category: undefined,

        actions: {
            /**
             * save a note connected to a contact
             * might also connect to a deal
             * might also send out alerts to staff
             *
             * @param model
             */
            save: function save(model) {
                var self = this;
                var formData = {
                    body: this.get('body'),
                    crmNoteCategory: this.get('category')
                };
                var note = this.store.createRecord('crm-note', formData);
                var selectedEmployees = this.get('selectedEmployees');

                note.save().then(function (child) {
                    // now connect new note to a contact
                    // for some reason the client object loads as an array even though we already did 'firstObject'
                    var client = self.get('client');
                    var formData = {
                        crmNote: note,
                        client: client.get('firstObject')
                    };

                    var crmNoteHasClients = self.get('contactsInfoActivityController');
                    var relatedClient = self.store.createRecord('crm-note-has-client', formData);
                    relatedClient.save().then(function (child) {
                        // push new related record into item list
                        crmNoteHasClients.get('model').pushObject(relatedClient);
                        // this is the only way I can force the parent controller to update it's list and sort correctly
                        crmNoteHasClients.set('tickleObserver', Math.floor(Math.random() * 1000 + 1));
                    });

                    // if a deal was selected, also relate note to deal
                    var deal = self.get('relatedDeal');
                    if (typeof deal !== 'undefined' && deal.get('id') !== '') {
                        var dealData = {
                            crmNote: note,
                            deal: deal
                        };
                        var relatedDeal = self.store.createRecord('crm-note-has-deal', dealData);
                        relatedDeal.save();
                    }

                    // create a single email log entry if any employees are to be alerted
                    if (selectedEmployees.length > 0) {
                        var metadata = {
                            note: note.get('body')
                        };

                        var employeeIds = selectedEmployees.map(function (e) {
                            return e.get('id');
                        }).join(',');

                        self.get('notifications').send('CRMActivity', employeeIds, 'CRM Activity Alert', note.get('body'), null, null, null, JSON.stringify(metadata)).then(function () {
                            self.set('selectedEmployees', []);
                            self.get('notify').success('Staff alerts queued');
                        });
                    }

                    // attempt to reset form data
                    self.set('relatedDeal', undefined);
                    self.set('body', '');

                    self.get('notify').success('Note created');
                    self.transitionToRoute('crm.contacts.info.activity', { queryParams: { refresh: Math.random() } });
                }, function (reason) {
                    self.validationReport(note);
                });
            },
            showMoreOptions: function showMoreOptions() {
                Ember.$('#add-note-more').toggleClass('hidden');
                Ember.$('#add-note-options').toggleClass('hidden');
            },


            /** power-select for clients */
            searchDeals: function searchDeals(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchDeals, term, resolve, reject, 600);
                });
            },

            /** power-select for categories */
            searchCategories: function searchCategories(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchCategories, term, resolve, reject, 600);
                });
            }
        },
        _performSearchDeals: function _performSearchDeals(term, resolve, reject) {
            return this.store.query('deal', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});