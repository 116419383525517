define('tm3/pods/notes-service/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        expanded: false,

        toggleExpanded: function toggleExpanded() {
            this.toggleProperty('expanded');
        }
    });
});