define('tm3/pods/components/matter-list/editable-cell/workflow/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Various = Ember.Object.create({
        fullName: 'Various',
        id: 0
    });

    // get this list with: select concat('''', name, ''',') from workflows where type = 'matter'
    var allWorkflows = ['DB-Initial', 'DB-Main', 'DB-Supp', 'DB-Wits', 'DB-Monitor', 'Field-Main', 'Field-Supp', 'Rpt-Main', 'Rpt-Supp', 'QA-Main', 'QA-Supp', 'Office-Main', 'Office-LE', 'Office-WC', 'Office-DL', 'Office-BK', 'Office-Courts', 'Office-Socnet', 'Admin-Main', 'Admin-Records', 'Admin-Report', 'Case-Mgr', 'DB-Alt-Cause', 'Interviews', 'Alt-Cause-Employment', 'Alt-Cause-AHERA', 'Alt-Cause-Residential', 'Alt-Cause-Ambient'];

    var workflowAssignedFields = allWorkflows.map(function (f) {
        return Ember.String.camelize(f.toLowerCase()) + 'A';
    }).concat(allWorkflows.map(function (f) {
        return Ember.String.camelize(f.toLowerCase()) + 'Various';
    }));

    var statusAssignedFields = allWorkflows.map(function (f) {
        return Ember.String.camelize(f.toLowerCase()) + 'S';
    });
    var hoursAssignedFields = allWorkflows.map(function (f) {
        return Ember.String.camelize(f.toLowerCase()) + 'Hours';
    });
    var deadlineAssignedFields = allWorkflows.map(function (f) {
        return Ember.String.camelize(f.toLowerCase()) + 'Deadline';
    });
    var commentsAssignedFields = allWorkflows.map(function (f) {
        return Ember.String.camelize(f.toLowerCase()) + 'C';
    });

    exports.default = Ember.Component.extend({
        tagName: '',
        store: Ember.inject.service(),

        readonly: false,

        fieldName: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            this.set('fieldName', this.get('column.valuePath') || this.get('column.fieldName'));
            this.set('newHours', this.get('hours'));
            this.set('newDeadline', this.get('deadline'));
            this.set('newStatus', this.get('status'));
            this.set('newComment', this.get('comment'));
            this.set('assignField', this.get('assignedTo'));
        },


        allEmployees: Ember.computed('tableActions.employees.[]', function () {
            var all = this.get('tableActions.employees').toArray();

            all.unshift(Various);

            return all;
        }),

        observeComment: Ember.observer('newComment', function () {
            this.set('row.content.' + this.get('fieldName') + 'C', this.get('newComment'));
        }),
        observeDeadline: Ember.observer('newDeadline', function () {
            this.set('row.content.' + this.get('fieldName') + 'Deadline', this.get('newDeadline'));
        }),

        assignedTo: Ember.computed('row.content.{' + workflowAssignedFields.join(',') + '}', 'column', 'fieldName', function () {
            if (this.get('row.content.' + this.get('fieldName') + 'Various')) {
                return Various;
            }

            return this.get('row.content.' + this.get('fieldName') + 'A');
        }),
        assignedToName: Ember.computed('row.content.{' + workflowAssignedFields.join(',') + '}', 'column', 'fieldName', function () {
            if (this.get('row.content.' + this.get('fieldName') + 'Various')) {
                return Various.get('fullName');
            }

            return this.get('row.content.' + this.get('fieldName') + 'A.shortName');
        }),
        status: Ember.computed('row.content.{' + statusAssignedFields.join(',') + '}', 'column', 'fieldName', function () {
            return this.get('row.content.' + this.get('fieldName') + 'S');
        }),
        hours: Ember.computed('row.content.{' + hoursAssignedFields.join(',') + '}', 'column', 'fieldName', function () {
            return this.get('row.content.' + this.get('fieldName') + 'Hours');
        }),
        deadline: Ember.computed('row.content.{' + deadlineAssignedFields.join(',') + '}', 'column', 'fieldName', function () {
            return this.get('row.content.' + this.get('fieldName') + 'Deadline');
        }),
        comment: Ember.computed('row.content.{' + commentsAssignedFields.join(',') + '}', 'column', 'fieldName', function () {
            return this.get('row.content.' + this.get('fieldName') + 'C');
        }),

        actions: {
            changeAssignee: function changeAssignee(newAssignee) {
                this.set('assignField', newAssignee);

                if (newAssignee && newAssignee.get('id') === 0) {
                    this.set('row.content.' + this.get('fieldName') + 'A', null);
                    this.set('row.content.' + this.get('fieldName') + 'Various', true);
                } else {
                    this.set('row.content.' + this.get('fieldName') + 'A', newAssignee);
                    this.set('row.content.' + this.get('fieldName') + 'Various', false);

                    // Check if assingee is in the case
                    if (this.get('tableActions.showAddEmployeeIfNotInCase')) {
                        this.get('tableActions.showAddEmployeeIfNotInCase')(newAssignee);
                    }
                }

                if (Ember.isEmpty(this.get('row.content.' + this.get('fieldName') + 'S'))) {
                    this.set('newStatus', 'N');
                    this.set('row.content.' + this.get('fieldName') + 'S', 'N');
                }
            },
            changeStatus: function changeStatus(newStatus) {
                this.set('newStatus', newStatus.get('value'));
                this.set('row.content.' + this.get('fieldName') + 'S', newStatus.get('value'));
            },
            changeHours: function changeHours(newHours) {
                this.set('newHours', newHours);
                this.set('row.content.' + this.get('fieldName') + 'Hours', newHours);
                var assigned = this.get('row.content.' + this.get('fieldName') + 'A.id');

                if (newHours > 0 && !assigned) {
                    // auto set to: to be assigned
                    var tobeassigned = this.get('tableActions.employees').findBy('userName', 'tobeassigned');

                    this.set('assignField', tobeassigned);
                    this.set('row.content.' + this.get('fieldName') + 'A', tobeassigned);
                }
            }
        }
    });
});