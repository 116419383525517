define('tm3/pods/components/me/expenses/edit-time/component', ['exports', 'ember-lifeline/mixins/run'], function (exports, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        matter: null,
        timeBillableTypes: Ember.computed('billableTypes.@each.time', function () {
            // used for time and flat entries
            return this.get('billableTypes').filterBy('time', 1);
        }),

        expenseBillableTypes: Ember.computed('billableTypes.@each.expense', function () {
            return this.get('billableTypes').filterBy('expense', 1);
        }),

        timePieceTotal: Ember.computed('timePiece.qty', 'timePiece.rate', function () {
            var qty = this.get('timePiece.qty');
            var rate = this.get('timePiece.rate');

            return qty * rate;
        }),

        timePiece: Ember.computed('model.billables', function () {
            var billables = this.get('model.billables');
            var timePiece = billables.filterBy('billType', 'Time');
            timePiece = timePiece.get('firstObject');
            return timePiece;
        }),

        // because there will be no more selected invoice group --> it's readonly'
        // timePieceObserver: Ember.observer('timePiece', function() {
        //   let timePiece = this.get('timePiece');
        //   this.set('selectedInvoiceGroup', timePiece.get('invoiceGroup'));
        // }),

        expensePieces: Ember.computed('model.billables', 'model.billables.@each.isDeleted', function () {
            var billables = this.get('model.billables');
            var expencePieces = billables.filterBy('billType', 'Time:Expense');
            return expencePieces;
        }),

        manualExpenses: Ember.computed('model.billables', 'model.billables.@each.isDeleted', function () {
            var billables = this.get('model.billables');
            var manualExpenses = billables.filterBy('billType', 'Manual');
            return manualExpenses;
        }),

        qtyChanged: Ember.observer('timePiece.qty', function () {
            this.debounceTask('updateQty', 500);
        }),

        updateQty: function updateQty() {
            var qty = this.get('timePiece.qty').toString();
            qty = qty.replace(/[^\d.]/g, '');
            qty = qty.match(/^\d+(\.\d+)?/);
            if (qty && qty.length > 0) {
                this.set('timePiece.qty', qty[0]);
            }
        },


        rateChanged: Ember.observer('timePiece.rate', function () {
            this.debounceTask('updateRate', 500);
        }),

        updateRate: function updateRate() {
            var rate = this.get('timePiece.rate').toString();
            rate = rate.replace(/[^\d.]/g, '');
            rate = rate.match(/^\d+(\.\d+)?/);
            if (rate && rate.length > 0) {
                this.set('timePiece.rate', rate[0]);
            }
        },


        cutChanged: Ember.observer('timePiece.cut', function () {
            this.debounceTask('updateCut', 500);
        }),

        updateCut: function updateCut() {
            var cut = this.get('timePiece.cut').toString();
            cut = cut.replace(/[^\d.]/g, '');
            cut = cut.match(/^\d+(\.\d+)?/);
            if (cut && cut.length > 0) {
                this.set('timePiece.cut', cut[0]);
            }
        },


        actions: {
            addExpense: function addExpense() {
                this.sendAction('addExpense', 'Manual');
            },
            removeExpense: function removeExpense(item) {
                this.sendAction('removeExpense', item);
            },
            addFiles: function addFiles() {
                this.sendAction('addFiles');
            },
            cancelUpload: function cancelUpload() {
                this.sendAction('cancelUpload');
            },
            beforeUpload: function beforeUpload(filesToUpload) {
                this.sendAction('beforeUpload', filesToUpload);
            },
            afterUpload: function afterUpload(response) {
                this.sendAction('afterUpload', response);
            },
            errorUpload: function errorUpload() {
                this.sendAction('errorUpload');
            }
        }
    });
});