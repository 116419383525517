define('tm3/pods/crm/dash/controller', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Item = Ember.Object.extend({ id: '', name: '', type: '', relevance: 0 });

    exports.default = Ember.Controller.extend(_error.default, {
        searchType: 'Full Search',

        // the final list of options to display in the drop down
        selectOptions: [],

        actions: {
            // triggers when a value has been selected in the auto suggest
            navigateToSelection: function navigateToSelection(item) {
                // this.set('selectedItem', item);

                switch (item.get('type')) {
                    case 'Account':
                        this.transitionToRoute('crm.accounts.info', item.get('id'));
                        break;

                    case 'Contact':
                        this.transitionToRoute('crm.contacts.info', item.get('id'));
                        break;

                    case 'Opportunity':
                        this.transitionToRoute('crm.deals.info', item.get('id'));
                        break;

                    case 'Note':
                        this.transitionToRoute('crm.activity.info', item.get('id'));
                        break;

                    case 'Todo':
                        this.transitionToRoute('crm.todos.info', item.get('id'));
                        break;

                    case 'Case':
                        this.transitionToRoute('matter.subjects.list', item.get('id'));
                        break;
                }
            },
            toggleSearchMethods: function toggleSearchMethods() {
                Ember.$('#quickSearch').toggleClass('hidden');
                Ember.$('#traditionalSearch').toggleClass('hidden');

                var searchType = this.get('searchType');
                if (searchType === 'Full Search') {
                    this.set('searchType', 'Quick Search');
                } else {
                    this.set('searchType', 'Full Search');
                }
            },
            traditionalSearch: function traditionalSearch(searchValue) {
                this.transitionToRoute('crm.full-search', { queryParams: { terms: searchValue } });
            },


            /** power-select for clients */
            search: function search(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearch, term, resolve, reject, 600);
                });
            }
        },
        _performSearch: function _performSearch(term, resolve, reject) {
            var wildcard = '*';

            var accounts = this.store.query('firm', {
                full_name: term,
                limit: 30
            });
            var clients = this.store.query('client', {
                full_name: term,
                limit: 30
            });
            var deals = this.store.query('deal', {
                'name||notes': wildcard + term + wildcard,
                limit: 30,
                sortField: 'name'
            });
            var notes = this.store.query('crm-note', {
                body: wildcard + term + wildcard,
                limit: 30,
                sortField: 'body'
            });
            // Commented until sales define what to do when selecting a Todo
            // var todos = this.store.query('todo', {
            // 'body': wildcard + term + wildcard,
            // limit: 30,
            // sortField: 'body'
            // });
            var matters = this.store.query('matter', {
                'name||plaintiff||allegation||special_instructions': wildcard + term + wildcard,
                limit: 30,
                sortField: 'name'
            });

            return Ember.RSVP.all([accounts, clients, deals, notes, matters]).then(function (array) {
                // process all promises here
                var optionsList = [];
                var accounts = array[0];
                var clients = array[1];
                var deals = array[2];
                var notes = array[3];
                var matters = array[4];
                var todos = array[5];

                // build generic object from each account object and add to optionsList array
                if (typeof accounts !== 'undefined') {
                    accounts.forEach(function (account, index) {
                        var item = Item.create({
                            id: account.get('id'),
                            name: account.get('name'),
                            type: 'Account',
                            relevance: index
                        });
                        var city = account.get('city');
                        if (Ember.isEmpty(city) === false && city.length !== 0) {
                            item.name = item.name + ' (' + city + ')';
                        }

                        optionsList.pushObject(item);
                    });
                }

                // build generic object from each client object and add to optionsList array
                if (typeof clients !== 'undefined') {
                    clients.forEach(function (client, index) {
                        var item = Item.create({
                            id: client.get('id'),
                            name: client.get('fullName'),
                            type: 'Contact',
                            relevance: index
                        });
                        optionsList.pushObject(item);
                    });
                }

                if (typeof deals !== 'undefined') {
                    deals.forEach(function (deal) {
                        var truncatedNotes = Ember.$.trim(deal.get('notes')).substring(0, 100);
                        var item = Item.create({
                            id: deal.get('id'),
                            name: deal.get('name') + ' (' + truncatedNotes + ')',
                            type: 'Opportunity'
                        });
                        optionsList.pushObject(item);
                    });
                }

                if (typeof notes !== 'undefined') {
                    notes.forEach(function (note) {
                        var truncatedBody = Ember.$.trim(note.get('body')).substring(0, 100);
                        var item = Item.create({
                            id: note.get('id'),
                            name: truncatedBody,
                            type: 'Note'
                        });
                        optionsList.pushObject(item);
                    });
                }

                if (typeof todos !== 'undefined') {
                    todos.forEach(function (todo) {
                        var truncatedBody = Ember.$.trim(todo.get('body')).substring(0, 100);
                        var item = Item.create({
                            id: todo.get('id'),
                            name: truncatedBody,
                            type: 'Todo'
                        });
                        optionsList.pushObject(item);
                    });
                }

                if (typeof matters !== 'undefined') {
                    matters.forEach(function (matter) {
                        var truncatedInstructions = Ember.$.trim(matter.get('special_instructions')).substring(0, 100);
                        var item = Item.create({
                            id: matter.get('id'),
                            name: matter.get('name') + ' ' + truncatedInstructions,
                            type: 'Case'
                        });
                        optionsList.pushObject(item);
                    });
                }

                // sort optionsList by name
                optionsList.sort(function (a, b) {
                    if (a.relevance !== 0 && b.relevance !== 0) {
                        return a.relevance - b.relevance;
                    }
                    var x = a.name.toLowerCase();
                    var y = b.name.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });

                resolve(optionsList);
            });
        }
    });
});