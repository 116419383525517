define('tm3/models/event', ['exports', 'tm-common/models/event', 'tm3/utils/sc-calendar-event-types'], function (exports, _event, _scCalendarEventTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _event.default.extend({
        color: Ember.computed('eventType', function () {
            var _this = this;

            var type = _scCalendarEventTypes.default.secondaryEventTypes.find(function (et) {
                return et.name === _this.get('eventType');
            });
            return type ? type.color : '#eeeee';
        })
    });
});