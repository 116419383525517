define('tm3/pods/matter/updates/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        model: function model() {
            return this.store.query('passive-event', {
                matter_id: this.get('currentMatter').get('id'),
                event_action: ['New', 'Delete', 'Edit'],
                sort: '-created_on'
            });
        }
    });
});