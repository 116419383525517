define('tm3/serializers/note', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            if (typeof hash.resource_id !== 'undefined') {
                hash.id = hash.resource_id;
            }
            if (typeof hash.type !== 'undefined') {
                hash.note_type = hash.type;
            }
            if (typeof hash.matters_id !== 'undefined') {
                hash.matter_id = hash.matters_id;
            }
            return this._super(typeClass, hash, prop);
        }
    });
});