define('tm3/helpers/target-blank-links', ['exports', 'tm-common/helpers/target-blank-links'], function (exports, _targetBlankLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _targetBlankLinks.default;
    }
  });
  Object.defineProperty(exports, 'targetBlankLinks', {
    enumerable: true,
    get: function () {
      return _targetBlankLinks.targetBlankLinks;
    }
  });
});