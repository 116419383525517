define('tm3/serializers/v-matter-user-permission', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var id = 1;

    /**
     * provide an expected subject_id that the view/api would not otherwise provide
     */
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        normalize: function normalize(typeClass, hash, prop) {
            hash.id = id++;
            return this._super(typeClass, hash, prop);
        }
    });
});