define('tm3/serializers/subject-addr', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
        keyForAttribute: function keyForAttribute(attr, method) {
            if (attr === 'addr1') {
                return 'addr_1';
            }
            if (attr === 'addr2') {
                return 'addr_2';
            }

            return this._super.apply(this, arguments);
        },
        serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
            if (key === 'addr1') {
                json['addr_1'] = snapshot.attr(key);
            } else if (key === 'addr2') {
                json['addr_2'] = snapshot.attr(key);
            } else {
                this._super(snapshot, json, key, attribute);
            }
        }
    });
});