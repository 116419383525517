define('tm3/metrics-adapters/google-analytics-extra', ['exports', 'tm-common/metrics-adapters/google-analytics-extra'], function (exports, _googleAnalyticsExtra) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleAnalyticsExtra.default;
    }
  });
});