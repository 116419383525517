define('tm3/mixins/case-management/info/admin/fields-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        collectionId: null,
        lists: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this.set('collectionId', transition.params['case-management.info'].collection_id);
        },


        requiredPermissions: ['Manage Case Groups'],
        model: function model(params) {
            var order = 0;
            return Ember.RSVP.hash({
                // fields
                collection: this.store.findRecord('collection', this.get('collectionId')),
                publicLists: this.store.query('appl-lists-category', {
                    private: false
                }),
                collectionFields: this.store.query('collection-field', {
                    collection_id: this.get('collectionId')
                }),
                collectionFieldDefaults: [Ember.Object.create({
                    value: 'matter.tmCode|matters:tm_code',
                    display: 'Case #',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.matterType.name|matters:matter_type_id',
                    display: 'Type',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.caseNickname|matters:person_of_interest',
                    display: 'Case Nickname',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.createdOn|matters:created_on|custom-table-pagination/date-cell',
                    display: 'Created On',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.venueCounty|matters:venue_county',
                    display: 'County/Jurisdiction',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.caseOwnerNames|matters:case_owner_names',
                    display: 'Case Owner',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.privateTagNames|matters:private_tag_names',
                    display: 'Tags',
                    weight: order++,
                    columnWidth: 220,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'collection.name|collections:name',
                    display: 'Case Group',
                    weight: order++,
                    columnWidth: 120,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.progressStatus|matters:progress_status',
                    display: 'Work Status',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.progressStatusNotes|matters:progress_status_notes',
                    display: 'Work Status Notes',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.hoursToDate|matters:hours_to_date',
                    display: 'Hours To Date',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.nextDueDate|matters:next_due_date|custom-table-pagination/date-cell',
                    display: 'Next Due Date',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.lastSent|matters:last_sent|custom-table-pagination/date-cell',
                    display: 'Last Report Sent',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.budgetTotal|matters:budget_total|custom-table-pagination/money-cell',
                    display: 'Total Budget',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.budgetSpent|matters:budget_spent|custom-table-pagination/money-cell',
                    display: 'Spent',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.budgetUtilization|matters:budget_utilization|custom-table-pagination/budget-utilization',
                    display: 'Utilization',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.invoiced|matters:invoiced|custom-table-pagination/money-cell',
                    display: 'Invoiced',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.lastInvoiceDate|matters:last_invoice_date|custom-table-pagination/date-cell',
                    display: 'Last Invoice Date',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.lastInvoiceAmount|matters:last_invoice_amount|custom-table-pagination/money-cell',
                    display: 'Last Invoice Amount',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'matter.product|matters:product',
                    display: 'Products',
                    weight: order++,
                    columnWidth: 100,
                    viewable: 0,
                    renderType: 'computed',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom1',
                    display: 'Custom1',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom2',
                    display: 'Custom2',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom3',
                    display: 'Custom3',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom4',
                    display: 'Custom4',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom5',
                    display: 'Custom5',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom6',
                    display: 'Custom6',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom7',
                    display: 'Custom7',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom8',
                    display: 'Custom8',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom9',
                    display: 'Custom9',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom10',
                    display: 'Custom10',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom11',
                    display: 'Custom11',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom12',
                    display: 'Custom12',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom13',
                    display: 'Custom13',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom14',
                    display: 'Custom14',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom15',
                    display: 'Custom15',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom16',
                    display: 'Custom16',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                }), Ember.Object.create({
                    value: 'custom17',
                    display: 'Custom17',
                    weight: order++,
                    columnWidth: 80,
                    viewable: 0,
                    renderType: 'text',
                    renderList: null,
                    dashboardViewable: false
                })],
                // matter-fields
                matterColumns: this.store.findAll('matter-column'),
                collectionHasMatterColumns: this.store.query('collection-has-matter-column', {
                    collection_id: this.get('collectionId')
                }),
                // workflows
                workflows: this.store.query('workflow', { workflow_type: 'matter' }),
                collectionHasWorkflows: this.store.query('collection_has_workflow', {
                    collection_id: this.get('collectionId')
                })
            });
        },
        setupController: function setupController(controller, resolved) {
            controller.set('collection', resolved.collection);
            controller.set('collectionFields', resolved.collectionFields);
            controller.set('collectionFieldDefaults', resolved.collectionFieldDefaults);
            controller.set('publicLists', resolved.publicLists.map(function (l) {
                return Ember.Object.create({
                    display: l.get('displayName'),
                    value: l.get('categoryName')
                });
            }));

            controller.set('matterColumns', resolved.matterColumns);
            controller.set('collectionHasMatterColumns', resolved.collectionHasMatterColumns);

            controller.set('workflows', resolved.workflows);
            controller.set('collectionHasWorkflows', resolved.collectionHasWorkflows);
        },


        actions: {
            fetchModel: function fetchModel() {
                this.refresh();
            }
        }
    });
});