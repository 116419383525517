define('tm3/pods/components/report/managers-billing-report/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: 'table-responsive',
        caseDataColumnHeaders: ['File #', 'Labels', 'Person Of Interest', 'Create Date', 'Budget Notes', 'Related Budget Notes', 'Report Notes', 'Budget Notes (JS1)', 'Report Notes (JS1)'],
        caseDataColumns: ['tm_code', 'matter_tags', 'person_of_interest', 'created_on', 'budget_notes', 'related_budget_notes', 'report_notes', 'js1_budget_notes', 'js1_report_notes'],

        curBudgetColumns: Ember.computed('budgetColumns.[]', function () {
            return this.getBudgetColumns('current');
        }),
        prevBudgetColumns: Ember.computed('budgetColumns.[]', function () {
            return this.getBudgetColumns('previous');
        }),
        getBudgetColumns: function getBudgetColumns(type) {
            var columns = [];
            var budgetColumns = this.get('budgetColumns');
            budgetColumns.forEach(function (budgetColumn) {
                if (budgetColumn.includes(type)) {
                    // handle service/expense types
                    var columnBits = budgetColumn.split('_' + type + '_');
                    if (columnBits.length === 2 && type !== 'budget') {
                        var typeName = columnBits[1].charAt(0).toUpperCase() + columnBits[1].slice(1);
                        columns.push({ display: columnBits[0] + ' ' + typeName, label: budgetColumn });
                    }
                }
            });
            return columns;
        },


        nonNullCurBudgetColumns: Ember.computed('curBudgetColumns.[]', function () {
            return this.getNonNullBudgetColumns('cur');
        }),
        nonNullPrevBudgetColumns: Ember.computed('prevBudgetColumns.[]', function () {
            return this.getNonNullBudgetColumns('prev');
        }),

        // returns columns if there is activity in at least one of the listed cases.
        getNonNullBudgetColumns: function getNonNullBudgetColumns(type) {
            var columns = [];
            var tableData = this.get('tableData');
            var allBudgetColumnsOfType = this.get(type + 'BudgetColumns');
            allBudgetColumnsOfType.forEach(function (column) {
                var label = column.label;
                var valueFound = false;
                tableData.forEach(function (td) {
                    var value = td[label];
                    if (value && parseFloat(value)) {
                        valueFound = true;
                    }
                });
                if (valueFound) {
                    columns.push(column);
                }
            });
            return columns;
        },


        showCurNonBudgeted: Ember.computed('tableData', function () {
            return this.calculateShowNonBudgeted('current');
        }),
        showPrevNonBudgeted: Ember.computed('tableData', function () {
            return this.calculateShowNonBudgeted('previous');
        }),

        calculateShowNonBudgeted: function calculateShowNonBudgeted(type) {
            var hasValue = false;
            var expenseLabel = 'non_' + type + '_expense';
            var serviceLabel = 'non_' + type + '_service';
            var travelLabel = 'non_' + type + '_travel';
            var tableData = this.get('tableData');
            tableData.forEach(function (td) {
                var expense = td[expenseLabel];
                var service = td[serviceLabel];
                var travel = td[travelLabel];
                if (expense && service && travel && parseFloat(expense) + parseFloat(service) + parseFloat(travel)) {
                    hasValue = true;
                }
            });
            return hasValue;
        },


        curBudgetColumnsLength: Ember.computed('nonNullCurBudgetColumns.[]', 'showCurNonBudgeted', function () {
            return this.get('nonNullCurBudgetColumns.length') + (this.get('showCurNonBudgeted') ? 5 : 4); // +4 for the "Totals" & "Non-Budgeted" & "Non-NTE" & "Discount" columns
        }),
        prevBudgetColumnsLength: Ember.computed('nonNullPrevBudgetColumns.[]', 'showPrevNonBudgeted', function () {
            return this.get('nonNullPrevBudgetColumns.length') + (this.get('showPrevNonBudgeted') ? 5 : 4); // +4 for the "Totals" & "Non-Budgeted" & Non-NTE & Discount columns
        })
    });
});