define('tm3/pods/components/custom-table-pagination/progress-status/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            setStatus: function setStatus(newStatus) {
                var _this = this;

                var matter = this.get('row.content');
                switch (newStatus) {
                    case 'Complete':
                        matter.set('caseCompletedOn', (0, _moment.default)().format('YYYY-MM-DD'));
                        break;
                    case 'In Progress':
                        matter.set('caseCompletedOn', null);
                        matter.set('progressStatus', 'In Progress');
                        break;
                    case 'Not Started':
                        matter.set('caseCompletedOn', null);
                        matter.set('progressStatus', 'Not Started');
                        break;
                }
                matter.save().then(function () {
                    _this.get('notify').success('Work status saved');
                });
            }
        }
    });
});