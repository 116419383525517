define('tm3/pods/components/matter/workflows/base-cell/component', ['exports', 'ember-light-table/components/cells/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        showChangeStatusModal: false,
        showChangeAssignModal: false,

        mouseEnter: function mouseEnter() {
            if (this.get('column.tpColumn.workflowAssignedColumn') && !this.get('value')) {
                this.set('showAssignToMe', true);
            }

            if (this.get('column.tpColumn.workflowStatusColumn')) {
                if (!this.get('value')) {
                    this.set('showMakeStatusProgress', true);
                }
                if (this.get('value') === 'P') {
                    this.set('showMakeStatusComplete', true);
                }
            }
        },

        mouseLeave: function mouseLeave() {
            if (this.get('column.tpColumn.workflowAssignedColumn') && !this.get('value')) {
                this.set('showAssignToMe', false);
            }

            if (this.get('column.tpColumn.workflowStatusColumn')) {
                this.set('showMakeStatusComplete', false);
                this.set('showMakeStatusProgress', false);
            }
        },

        actions: {
            replaceHoursWithDeadline: function replaceHoursWithDeadline(string) {
                return string.replace('Hours', 'Deadline');
            }
        }
    });
});