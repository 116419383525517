define('tm3/pods/preview/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentSubject: null,
        routing: Ember.inject.service('-routing'),

        currentRoute: Ember.computed('routing.currentRouteName', function () {
            return this.get('routing.currentRouteName');
        }),

        actions: {
            selectionChange: function selectionChange(subject) {
                this.set('currentSubject', subject);
                this.transitionToRoute('preview.timeline-events', subject.id, {
                    queryParams: { matterId: this.get('model.matter.id') }
                });
            }
        }
    });
});