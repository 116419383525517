define('tm3/pods/case-group/edit-columns/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                collection: this.store.findRecord('collection', params.collection_id),
                collections: this.store.findAll('collection'),
                publicLists: this.store.query('appl-lists-category', {
                    private: false
                }).then(function (pl) {
                    return pl.map(function (item) {
                        return Ember.Object.create({
                            value: item.get('categoryName'),
                            display: item.get('displayName')
                        });
                    });
                }),
                collectionColumns: this.store.query('collection-column', {
                    collection_id: params.collection_id,
                    sortField: 'weight',
                    with: 'workflow'
                }),
                caseColumns: this.get('ajax').request('/matters/case_columns').then(function (ccs) {
                    return ccs.case_columns;
                }),
                matterWorkflows: this.store.query('workflow', { workflow_type: 'matter' })
            });
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});