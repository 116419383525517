define('tm3/pods/matter/admin/billing-summary/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentMatter: Ember.inject.service(),
        modelName: 'invoice',
        requireAnyPermission: ['Manage All Cases', 'Manage Case'],
        currentRoute: 'matter.admin.billing-summary',

        model: function model(params) {
            return {};
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {
                matter_id: this.get('currentMatter.id'),
                include_budgets: true
            });
            this._super(controller, resolved);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});