define('tm3/pods/components/accounting/billing/batches/info/status-cell/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        getLabelColor: Ember.computed('row.content.status', function () {
            var classes = '';

            switch (this.get('row.content.status')) {
                case 'Preliminary':
                    // 'Timekeeper Approval Required'
                    classes = 'label-warning';
                    break;
                case 'Level 1 Complete':
                    // 'Manager Approval Required'
                    classes = 'label-dark';
                    break;
                case 'Level 2 Complete':
                    // 'Accounting Approval Required'
                    classes = 'label-primary';
                    break;
                default:
                    classes = 'label-success';
                    break;
            }

            return classes;
        })
    });
});