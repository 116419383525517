define('tm3/pods/me/tasks/route', ['exports', 'ember-cli-table-pagination/mixins/table-pager/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        session: Ember.inject.service(),
        modelName: 'task',
        currentRoute: 'me.tasks',

        queryParams: {
            highPriority: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var _this = this;

            params.assigned_to_id = this.get('session.data.authenticated.id');
            params.completed_only = false;
            params.exclude_workflow = true;
            params.show_completed_cases = true;
            params.only_active_cases = true;
            if (params.highPriority) {
                params.only_urgent = true;
            } else {
                params.only_urgent = false;
            }
            delete params.highPriority;
            return Ember.RSVP.hash({
                tasks: this._super(params)
            }).then(function (hash) {
                var matterIds = hash.tasks.map(function (task) {
                    return task.get('taskList.matter.id');
                }).uniq();
                if (matterIds.length <= 100) {
                    return _this.store.query('matter', {
                        id: matterIds
                    }).then(function (matters) {
                        hash.matters = matters;
                        return hash;
                    });
                } else {
                    return hash;
                }
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model.tasks);
            controller.set('matters', model.matters);
        }
    });
});