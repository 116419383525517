define('tm3/pods/components/matter/dashboard/case-details/component', ['exports', 'moment', 'tm-common/mixins/crud/error'], function (exports, _moment, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        currentMatter: Ember.inject.service(),
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        title: 'Dashboard Panel, please pass as attribute',
        route: 'matter.dashboard',
        matterHasUsers: null,

        venueString: Ember.computed('matter.venueState', 'matter.venueCounty', 'matter.courtType', function () {
            return [Ember.get(this, 'matter.venueState'), Ember.get(this, 'matter.venueCounty'), Ember.get(this, 'matter.courtType')].filter(Boolean).join(', ');
        }),
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
        },

        daysToFirstReport: Ember.computed('matter.createdOn', 'matter.publishedOn', function () {
            var create = this.get('matter.createdOn');
            var published = this.get('matter.publishedOn');
            if (create && published) {
                return (0, _moment.default)(create).diff((0, _moment.default)(published), 'days');
            }
            return null;
        }),

        actions: {
            saveCaseDetails: function saveCaseDetails() {
                var _this = this;

                this.get('matter').save().then(function () {
                    _this.get('notify').success('Case details saved successfully');
                    _this.set('editing', false);
                }).catch(function () {
                    _this.validationReport(_this.get('matter'));
                });
            },
            invalidData: function invalidData() {
                this.get('notify').warning('Some data is invalid');
            },
            createProduct: function createProduct(select, e) {
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
                    if (select.searchText.length > 30) {
                        this.get('notify').error('The product name must be less than 30 characters');
                    } else {
                        // to not call submit
                        e.preventDefault();
                        // to avoid calling this twice
                        e.stopPropagation();

                        // Add to the list of products for the dropdown
                        if (!this.get('initialProducts').findBy(select.searchText)) {
                            // Update the list to include the new custom product
                            //select.options?
                            var products = this.get('initialProducts');
                            products.pushObject(select.searchText);
                            this.set('initialProducts', products);
                            select.options = products;
                        }

                        // Set the new value to the dropdown and the model
                        this.set('model.matter.product', select.searchText);
                        select.selected = select.searchText;

                        select.actions.close(e);
                    }
                }
            },
            changeTypeOfService: function changeTypeOfService(newList) {
                var newValues = newList.map(function (item) {
                    return item.get('value');
                });
                this.get('matter').set('typeOfService', newValues);
                this.notifyPropertyChange('matter.typeOfService');
            },
            cancel: function cancel() {
                this.get('matter').rollbackAttributes();
                this.set('editing', false);
            }
        }
    });
});