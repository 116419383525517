define('tm3/pods/matter/admin/reports-by-employee/route', ['exports', 'tm3/mixins/report-info-route'], function (exports, _reportInfoRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reportInfoRoute.default, {
        apiName: 'employee_time_reports',
        allEmployeesApiName: 'all_employee_time_reports',

        model: function model(params) {
            var model = this.modelFor('matter');
            if (params.employee_id === 'all') {
                return Ember.RSVP.hash({
                    matter: model.matter,
                    tableData: this.makeRequest({
                        type: this.get('allEmployeesApiName'),
                        style: 'tabular',
                        per_page: 20000,
                        page: 1,
                        matter: model.matter.id,
                        invoice_group: params.invoice_group ? params.invoice_group : null,
                        budget: params.budget ? params.budget : null
                    }),
                    showAllEmployees: true
                });
            } else {
                return Ember.RSVP.hash({
                    matter: model.matter,
                    tableData: this.makeRequest({
                        type: this.get('apiName'),
                        style: 'tabular',
                        per_page: params.perPage ? params.perPage : 20000,
                        page: params.page ? params.page : 1,
                        matter: model.matter.id,
                        employee: params.employee_id,
                        invoice_group: params.invoice_group ? params.invoice_group : null,
                        budget: params.budget ? params.budget : null
                    }),
                    employee: this.store.findRecord('employee', params.employee_id),
                    report: this.get('reportCatalog').getReport(this.get('apiName')),
                    perPage: params.perPage ? params.perPage : 50,
                    page: params.page ? params.page : 1,
                    showAllEmployees: false
                });
            }
        },
        renderTemplate: function renderTemplate() {
            this.render();
            window.scrollTo(0, 0);
        },
        addRowToBillGroups: function addRowToBillGroups(billGroups, row) {
            if (row.bill_type === 'Time' || row.bill_type === 'Piece') {
                billGroups[row.id]['time'] = row;
            } else {
                billGroups[row.id]['expenses'].push(row);
            }
        },
        setupController: function setupController(controller, resolved) {
            var _this = this;

            var showAllEmployees = resolved.showAllEmployees;
            controller.set('showAllEmployees', showAllEmployees);
            this._super(controller, resolved);

            if (showAllEmployees) {} else {
                var billGroups = {};
                resolved.tableData.table_data.forEach(function (row) {
                    if (billGroups[row.id]) {
                        _this.addRowToBillGroups(billGroups, row);
                    } else {
                        billGroups[row.id] = { time: null, expenses: [], expanded: false };
                        _this.addRowToBillGroups(billGroups, row);
                    }
                });
                controller.set('billGroups', billGroups);
            }

            controller.set('apiName', this.get('apiName'));
            controller.set('allEmployeesApiName', this.get('allEmployeesApiName'));
        }
    });
});