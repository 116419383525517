define('tm3/pods/components/me/time-entry-row/component', ['exports', 'ember-light-table/components/lt-row'], function (exports, _ltRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ltRow.default.extend({
        classNameBindings: ['isHighlighted'],

        isHighlighted: Ember.computed('row.hasExpenseWithoutReceipt', function () {
            return this.get('row.hasExpenseWithoutReceipt') === true;
        })
    });
});