define('tm3/components/empty-component', ['exports', 'tm-common/components/empty-component'], function (exports, _emptyComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emptyComponent.default;
    }
  });
});