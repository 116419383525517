define('tm3/pods/components/matter/notes/matter-notes/component', ['exports', 'tm3/pods/components/matter/notes/matter-notes/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notesService: Ember.inject.service(),
        layout: _template.default,

        // -- inputs:
        notes: [],
        alertUsersEmployees: {},
        alertUsersContacts: {},

        replyingNote: null,
        newNoteBody: '',

        employeesToAlert: [],
        contactsToAlert: [],

        columnFields: ['full_name', 'body', 'created_on'],
        columnNames: ['Created by', 'Note', 'Date'],

        showNewNoteForm: false,
        showEditNoteForm: false,
        editingNote: null,

        actions: {
            saveNote: function saveNote(note) {
                var _this = this;

                return this.attrs.saveNote(this.get('newNoteBody'), note, this.get('replyingNote'), this.get('employeesToAlert'), this.get('contactsToAlert')).then(function () {
                    _this.send('resetCreateNoteForm');
                }, function () {
                    // Do nothing but we know it didn't work.
                });
            },
            reply: function reply(note) {
                this.set('replyingNote', note);
                this.set('showNewNoteForm', true);
            },
            editNote: function editNote(note) {
                this.set('editingNote', note);
                this.set('showEditNoteForm', true);
            },
            resetCreateNoteForm: function resetCreateNoteForm() {
                this.set('showNewNoteForm', false);
                this.set('showEditNoteForm', false);
                this.set('editingNote', null);
                this.set('replyingNote', null);
                this.set('newNoteBody', '');
                this.set('employeesToAlert', []);
                this.set('contactsToAlert', []);
                this.get('alertUsersEmployees.caseEmployees').setEach('isSelected', false);
                this.get('alertUsersEmployees.caseTeams').setEach('isSelected', false);
                this.get('alertUsersContacts.caseContacts').setEach('isSelected', false);
                this.get('alertUsersContacts.caseContactTeams').setEach('isSelected', false);
            },
            toggleExpand: function toggleExpand() {
                this.get('notesService').toggleExpanded();
            }
        }
    });
});