define('tm3/pods/accounting/billing/manual-expense/import/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),

        currentStep: 1,
        log: [],
        newBillableGroups: [],
        isProcessing: false,

        selectedType: 'Accurint',

        actions: {
            cancelUpload: function cancelUpload() {
                this.transitionToRoute('accounting.billing.manual-expense.list');
            },
            beforeUpload: function beforeUpload(filesToUpload) {
                filesToUpload.setEach('extra', {
                    type: this.get('selectedType')
                });
                this.set('isProcessing', true);
                this.set('log', []);
                this.set('newBillableGroups', []);
            },
            afterUpload: function afterUpload(response) {
                if (response.log) {
                    this.get('notify').success('The file has been uploaded and processed please review the log');
                    this.set('log', response.log);
                    this.set('newBillableGroups', response.billable_groups);
                    this.set('currentStep', 2);
                    this.set('isProcessing', false);
                }
            },
            errorUpload: function errorUpload(error) {
                this.set('isProcessing', false);
                if (Ember.isPresent(error.responseJSON)) {
                    this.get('notify').error('Error uploading file: ' + error.responseJSON.errors[0].additional_info.title);
                } else {
                    this.get('notify').error('Error uploading file: ' + error.statusText);
                }
            }
        }
    });
});