define('tm3/pods/report/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        application: Ember.inject.controller(),
        tabTitle: Ember.computed('application.currentRouteName', function () {
            var appRouteName = this.get('application.currentRouteName');
            switch (appRouteName) {
                case 'report.info.billed-cases-details':
                    return 'Billed Cases Detail';

                case 'report.info.billed-cases-by-month':
                    return 'Billed Cases By Month';

                case 'report.info.client-file-report':
                    return 'Client File Report';

                case 'report.info.fake-report':
                    return 'Fake Report';

                case 'report.info.revenue-summary':
                    return 'Revenue Summary';

                case 'report.info.billing-report-by-person':
                    return 'Billing Report By Person';

                case 'report.info.new-cases':
                    return 'New Cases';

                case 'report.info.new-cases-detail':
                    return 'New Cases Detail';

                case 'report.info.review-time-report':
                    return 'Review Time Report';

                case 'report.info.sales-report':
                    return 'Sales Report';

                case 'report.info.utilization-report':
                    return 'Utilization Report';

                case 'report.info.managers-billing-report':
                    return 'Managers Billing Report';
            }
        })
    });
});