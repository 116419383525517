define('tm3/initializers/accounting', ['exports', 'accounting/settings'], function (exports, _settings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'accounting.js',
        initialize: function initialize() {
            _settings.currency.symbol = '$';
            _settings.number.decimal = '.';
            _settings.number.thousand = ',';
        }
    };
});