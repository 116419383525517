define('tm3/pods/components/matter/dashboard/billing-widget/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentUser: Ember.inject.service(),

        title: 'Billing',
        route: 'matter.admin.billing-summary',
        linkText: 'Go To Billing',

        store: Ember.inject.service(),
        currentMatter: Ember.inject.service(),

        matterId: Ember.computed('matterId', 'currentMatter.id', function () {
            //Have the value provided by parent component or pull from store
            return this.get('matterId') ? this.get('matterId') : this.get('currentMatter.id');
        }),

        lastBilledInvoices: [],

        servicesTotal: Ember.computed('billedServices', 'wipServices', function () {
            return this.get('billedServices') + this.get('wipServices');
        }),
        expensesTotal: Ember.computed('billedExpenses', 'wipExpenses', function () {
            return this.get('billedExpenses') + this.get('wipExpenses');
        }),
        discountTotal: Ember.computed('billedDiscounts', function () {
            return this.get('billedDiscounts');
        }),
        taxesTotal: Ember.computed('billedTaxes', 'wipTaxes', function () {
            return this.get('billedTaxes') + this.get('wipTaxes');
        }),
        billingTotal: Ember.computed('servicesTotal', 'expensesTotal', 'discountTotal', 'taxesTotal', function () {
            return this.get('servicesTotal') + this.get('expensesTotal') + this.get('discountTotal') + this.get('taxesTotal');
        }),

        billedServices: 0,
        billedExpenses: 0,
        billedTaxes: 0,
        billedTotal: Ember.computed('billedServices', 'billedExpenses', 'billedDiscounts', 'billedTaxes', function () {
            return this.get('billedServices') + this.get('billedExpenses') + this.get('billedTaxes') + this.get('billedDiscounts');
        }),

        wipServices: 0,
        wipExpenses: 0,
        wipTaxes: 0,
        wipTotal: Ember.computed('wipServices', 'wipExpenses', 'wipTaxes', function () {
            return this.get('wipServices') + this.get('wipExpenses') + this.get('wipTaxes');
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            // Get a subset of invoices
            this.getLatestInvoices();
            // Services/Expenses can be pulled from the billables
            this.getBillablesForSummary();
            // Taxes can be pulled from the invoices. They do not exist on the billables
            this.getTaxesFromInvoices();
        },
        getLatestInvoices: function getLatestInvoices() {
            var _this = this;

            // Get the latest invoices that have the Billed status for this specific matter
            this.get('store').query('invoice', {
                matter_id: this.get('matterId'),
                with: 'invoice_outputs,invoice_subs',
                status: 'Billed',
                limit: 1,
                sortField: '-bill_date'
            }).then(function (data) {
                var invoicesAsList = [];
                data.map(function (item) {
                    invoicesAsList.pushObject({
                        billDate: (0, _moment.default)(item.get('data').billDate).format('MM/DD/YY'),
                        service: item.get('data').serviceAmount,
                        expense: item.get('data').expenseAmount,
                        tax: item.get('data').taxAmount,
                        discountAmount: item.get('data').discountAmount,
                        total: item.get('data').billedAmount
                    });
                });
                _this.set('lastBilledInvoices', invoicesAsList);
            });
        },
        getBillablesForSummary: function getBillablesForSummary() {
            var _this2 = this;

            // Get the billable list for this specific matter
            this.get('store').query('billable', {
                matter_id: this.get('matterId'),
                with: 'billable_groups,billable_types,invoices',
                'billable_groups:include_for_billing': 1,
                'billable_types:id': '!=' + 471
            }).then(function (data) {
                var billedServicesTotal = 0;
                var billedExpensesTotal = 0;
                var billedDiscounts = [];

                var wipServicesTotal = 0;
                var wipExpensesTotal = 0;

                data.map(function (item) {
                    var billableItem = item.get('data');
                    // Check if the item was billed or not
                    if (billableItem.billed) {
                        if (!Ember.isPresent(billedDiscounts[item.get('invoice.id')])) {
                            billedDiscounts[item.get('invoice.id')] = item.get('invoice.discountAmount');
                        }
                        // These items are Billed, now check for type
                        if (billableItem.billType == 'Time') {
                            billedServicesTotal += billableItem.total;
                        }
                        //Invoices combine Expenses and Manual entries into the expenses total
                        if (billableItem.billType !== 'Time') {
                            billedExpensesTotal += billableItem.total;
                        }
                    } else {
                        // These items are WIP, now check for type
                        if (billableItem.billType == 'Time') {
                            wipServicesTotal += billableItem.total;
                        }
                        //Invoices combine Expenses and Manual entries into the expenses total
                        if (billableItem.billType !== 'Time') {
                            wipExpensesTotal += billableItem.total;
                        }
                    }
                });

                _this2.set('billedServices', billedServicesTotal);
                _this2.set('billedExpenses', billedExpensesTotal);
                _this2.set('billedDiscounts', billedDiscounts.reduce(function (acc, item) {
                    return acc + item;
                }, 0));

                _this2.set('wipServices', wipServicesTotal);
                _this2.set('wipExpenses', wipExpensesTotal);
            });
        },
        getTaxesFromInvoices: function getTaxesFromInvoices() {
            var _this3 = this;

            // get the taxes on the invoices for a specific matter
            this.get('store').query('invoice', {
                matter_id: this.get('matterId'),
                with: 'invoice_outputs,invoice_subs'
            }).then(function (data) {
                var billedTaxesTotal = 0;
                var wipTaxesTotal = 0;

                data.map(function (item) {
                    var invoiceStatus = item.get('data').status;
                    var invoiceTax = item.get('data').taxAmount;

                    if (invoiceStatus === 'Billed') {
                        billedTaxesTotal += invoiceTax;
                    } else {
                        wipTaxesTotal += invoiceTax;
                    }
                });

                _this3.set('billedTaxes', billedTaxesTotal);
                _this3.set('wipTaxes', wipTaxesTotal);
            });
        }
    });
});