define('tm3/pods/report/info/managers-billing-report/controller', ['exports', 'tm3/mixins/report-info-controller'], function (exports, _reportInfoController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: ['caseTypes'],
        selectedCaseTypes: null,
        tableName: 'report/managers-billing-report',
        month: null, // TODO: The user may be able to select this in the future

        reportCustomParams: Ember.computed('caseOwner', 'caseTypes', 'month', function () {
            return { case_types: this.get('caseTypes'), month: this.get('month') };
        }),

        typeObserver: Ember.observer('caseTypes', 'matterTypes.[]', function () {
            var matterTypes = this.get('matterTypes');
            if (matterTypes) {
                var caseTypeIds = this.get('caseTypes') ? this.get('caseTypes').split(',') : [];
                var selectedCaseTypes = matterTypes.filter(function (mt) {
                    return caseTypeIds.indexOf(mt.get('id')) !== -1;
                });

                this.set('selectedCaseTypes', selectedCaseTypes);
            }
        }),

        actions: {
            typeChanged: function typeChanged(selectedCaseTypes, value, operation) {
                this.set('selectedCaseTypes', selectedCaseTypes);
                this.set('caseTypes', selectedCaseTypes.mapBy('id').join(','));
            }
        }
    });
});