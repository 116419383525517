define('tm3/pods/components/my-account-link/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNames: ['user', 'user-menu'],

        currentUser: Ember.inject.service(),
        media: Ember.inject.service(),

        profile: Ember.computed.readOnly('currentUser.user')
    });
});