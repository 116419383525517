define('tm3/pods/admin/report-component-templates/index/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        lists: Ember.inject.service(),
        modelName: 'report-component-template',
        currentRoute: 'admin.report-component-templates.index',

        model: function model(params) {
            return {};
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {});
            this._super(controller, resolved);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            doNothing: function doNothing() {
                // Do nothing at all
            }
        }
    });
});