define('tm3/pods/components/case-management/info/case-group-add-case-form/component', ['exports', 'tm3/pods/components/case-management/info/case-group-add-case-form/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        store: Ember.inject.service(),
        actions: {
            /** power-select for deal */
            searchMatters: function searchMatters(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchDeals, term, resolve, reject, 600);
                });
            },
            clearInput: function clearInput() {
                this.set('selectedMatterHash', []);
            }
        },
        _performSearchDeals: function _performSearchDeals(term, resolve, reject) {
            return this.get('store').query('matter', {
                'name||plaintiff||tm_code': '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});