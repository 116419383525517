define('tm3/pods/components/matter/billable-group-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentUser: Ember.inject.service(),
        tagName: 'li',
        billableGroupProxy: null,
        billableGroup: Ember.computed.alias('billableGroupProxy.content'),
        isActive: Ember.computed.alias('billableGroupProxy.isActive'),
        showDeleteButton: Ember.computed('billableGroup', 'currentUser', 'isCaseOwner', function () {
            return (this.get('billableGroup.approvalStatus') === 'Preliminary' || this.get('billableGroup.approvalStatus') === null) && (this.get('billableGroup.createdBy.id') === this.get('currentUser.user.id') || this.get('isCaseOwner'));
        }),

        isLocked: Ember.computed('billableGroupProxy.billables.[]', function () {
            return this.get('billableGroupProxy.billables').any(function (expense) {
                return expense.get('reimbursementStatus') === 'Employee Reimbursed';
            });
        }),

        totalQty: Ember.computed('billableGroup', function () {
            var qty = 0;
            this.get('billableGroup.billables').reduce(function (previousValue, item) {
                qty = qty + item.get('qty');
            }, 0);
            return qty;
        }),

        actions: {
            selectBillableGroup: function selectBillableGroup() {
                this.sendAction('selectBillableGroup', this.get('billableGroup.id'));
            },
            removeBillableGroup: function removeBillableGroup() {
                this.sendAction('removeBillableGroup', this.get('billableGroup'));
            }
        }
    });
});