define('tm3/pods/crm/contacts/info/matters/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service(),
        sortingOptions: [{ title: 'Date (Ascending)', field: 'matter.updatedOn' }, { title: 'Date (Descending)', field: 'matter.updatedOn:desc' }, { title: 'Pefix (Ascending)', field: 'matter.tmCode' }, { title: 'Prefix (Descending)', field: 'matter.tmCode:desc' }],
        selectedSortingOption: null,
        sortField: 'matter.tmNum:desc',

        queryParams: ['page', 'showOnly'],
        page: 1,
        totalPages: 1,

        showForm: false,

        showOnly: 'individual',

        listSort: ['matter.tmNum:desc'],
        teamAccessSorted: Ember.computed.sort('teamAccess', 'listSort'),
        filteredTeamAccess: Ember.computed('teamAccessSorted', 'accessFilter', function () {
            var teamAccessSorted = this.get('teamAccessSorted');
            var accessFilter = this.get('accessFilter');
            if (accessFilter === null) {
                return teamAccessSorted;
            } else {
                return teamAccessSorted.filter(function (access) {
                    return access.get('group.name') === accessFilter;
                });
            }
        }),
        individualAccessSorted: Ember.computed.sort('individualAccess', 'listSort'),
        filteredIndividualAccess: Ember.computed('individualAccessSorted', 'accessFilter', function () {
            var individualAccessSorted = this.get('individualAccessSorted');
            var accessFilter = this.get('accessFilter');
            if (accessFilter === null) {
                return individualAccessSorted;
            } else {
                return individualAccessSorted.filter(function (access) {
                    return access.get('group.name') === accessFilter;
                });
            }
        }),

        selectedSortingOptionObserver: Ember.observer('selectedSortingOption', function () {
            var selectedSortingOption = this.get('selectedSortingOption');
            if (selectedSortingOption !== null) {
                this.set('listSort', [selectedSortingOption.field]);
            } else {
                this.set('listSort', ['matter.tmNum:desc']);
            }
        }),

        filter: Ember.computed.alias('showOnly'),
        accessFilter: null,

        actions: {
            showDetail: function showDetail(item) {
                this.transitionToRoute('matter.subjects', item);
            },


            /**
             * delete a matter has user record
             * @param item
             * @returns {boolean}
             */
            removeMatterConnection: function removeMatterConnection(item) {
                item.destroyRecord();
                // this.get('matterHasUser').removeObject(item);
                this.get('notify').success('Successfully removed contact from this case!');
                return false;
            },
            setInitialPage: function setInitialPage() {
                this.set('page', 1);
            },
            expandAll: function expandAll() {
                var list = void 0;
                if (this.get('filter') === 'individual') {
                    list = this.get('individualAccessSorted');
                } else {
                    list = this.get('teamAccessSorted');
                }
                if (list.some(function (item) {
                    return item.get('showMore');
                })) {
                    list.forEach(function (item) {
                        return item.set('showMore', false);
                    });
                } else {
                    list.forEach(function (item) {
                        item.get('actionReceiver').send('getBillingInfo');
                    });
                }
            },
            removeAccess: function removeAccess() {
                var _this = this;

                if (!confirm('Are you sure to disable access to all related cases?')) {
                    return;
                }
                this.get('client').removeAccessToMatters().then(function () {
                    _this.get('notify').success('All cases have been removed');
                    _this.send('fetch');
                });
            }
        }
    });
});