define('tm3/helpers/subject-field-is-null', ['exports', 'tm-common/helpers/subject-field-is-null'], function (exports, _subjectFieldIsNull) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subjectFieldIsNull.default;
    }
  });
});