define('tm3/pods/components/custom-table-pagination/billing-table-tfoot/component', ['exports', 'ember-light-table/utils/css-styleify'], function (exports, _cssStyleify) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',

        table: null,

        data: Ember.computed('table.rows.[]', function () {
            return this.get('table.rows');
        }),

        totalLabel: 'Totals (Current Records Shown):',
        billableHoursLabel: 'Billable Hours',
        nonBillableHoursLabel: 'Non-Billable / Cut Hours',
        nonBillableItemType: 'NON BILLABLE',

        isMatterReviewTime: Ember.computed('columns', function () {
            // Matter specific Review Time pages will have a Type column instead of a File # column.
            return this.get('columns').mapBy('label').includes('Type');
        }),

        totalBillableHours: Ember.computed('data', function () {
            var _this = this;

            var total = 0;

            this.get('data').forEach(function (row) {
                if (Ember.isEmpty(row.get('billables.firstObject'))) {
                    return;
                }
                if (row.get('billables.firstObject.billableType.name') != _this.get('nonBillableItemType') && row.get('includeForBilling')) {
                    total = total + parseFloat(row.get('billables.firstObject.qty'));
                }
            });

            return total;
        }),

        totalNonBillableHours: Ember.computed('data', function () {
            var _this2 = this;

            var total = 0;

            this.get('data').forEach(function (row) {
                if (Ember.isEmpty(row.get('billables.firstObject'))) {
                    return;
                }
                if (row.get('includeForBilling')) {
                    if (row.get('billables.firstObject.billableType.name') == _this2.get('nonBillableItemType')) {
                        total = total + parseFloat(row.get('billables.firstObject.qty'));
                    } else {
                        total = total + parseFloat(row.get('billables.firstObject.cut'));
                    }
                } else {
                    total = total + parseFloat(row.get('billables.firstObject.qty'));
                }
            });

            return total;
        }),

        actions: {
            style: function style(column) {
                return (0, _cssStyleify.default)(column.getProperties(['width']));
            }
        }
    });
});