define('tm3/pods/components/case-management/custom-cell/component', ['exports', 'ember-light-table/components/cells/base', 'tm-common/mixins/crud/error', 'tm3/pods/components/case-management/custom-cell/template'], function (exports, _base, _error, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_error.default, {
        layout: _template.default,
        notify: Ember.inject.service(),
        store: Ember.inject.service(),

        actions: {
            editField: function editField() {
                this.set('row.isCollapsed', false);
            },
            saveField: function saveField(value) {
                var _this = this;

                if (this.get('column.tpColumn.renderType') === 'workflow') {
                    // save as workflow
                    var workflowName = this.get('column.tpColumn.displayName');
                    var fieldName = this.get('column.tpColumn.fieldName');

                    var wStatus = this.get('row.content.' + fieldName + 'S');
                    var wAssignee = this.get('row.content.' + fieldName + 'A.id');
                    var wVarious = this.get('row.content.' + fieldName + 'Various');
                    var wComment = this.get('row.content.' + fieldName + 'C');
                    var wHours = this.get('row.content.' + fieldName + 'Hours');
                    var wDeadline = this.get('row.content.' + fieldName + 'Deadline');

                    var caseWorkflows = this.get('store').query('case-workflow', {
                        matter_id: this.get('row.content.id'),
                        workflow_name: workflowName
                    });

                    caseWorkflows.then(function (workflows) {
                        workflows.forEach(function (workflow) {
                            if (workflow.get('name') === workflowName) {
                                workflow.set('assignedToId', wAssignee);
                                workflow.set('various', wVarious);
                                workflow.set('status', wStatus);
                                workflow.set('comment', wComment);
                                workflow.set('hours', wHours);
                                workflow.set('deadline', wDeadline);
                                workflow.save().then(function () {
                                    _this.get('notify').success('Saved successfully');
                                    _this.get('tableActions.refresh')();
                                }).catch(function () {
                                    _this.get('tableActions.refresh')();
                                    _this.validationReport(workflow);
                                });
                            }
                        });
                    });

                    this.get('row.content').rollbackAttributes();

                    return;
                }
                var matter = this.get('row.content');
                matter.save().then(function () {
                    _this.get('notify').success('Saved successfully');
                    _this.get('tableActions.refresh')();
                }).catch(function () {
                    _this.get('tableActions.refresh')();
                    _this.validationReport(matter);
                });
            },
            rollbackField: function rollbackField() {
                this.get('row.content').rollbackAttributes();
            }
        }
    });
});