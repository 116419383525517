define('tm3/pods/admin/matter-type/info/configurations/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    exports.default = Ember.Controller.extend({
        store: Ember.inject.service(),

        matterType: alias('model.matterType'),

        actions: {
            saveConfig: function saveConfig(config, value) {
                var _this = this;

                this.get('matterType').setMatterTypeConfiguration({
                    config: config,
                    value: value
                }).then(function () {
                    _this.send('refresh');
                });
            }
        }
    });
});