define('tm3/pods/client-collections/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            collectionSelected: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                collections: this.store.findAll('client-collection'),
                cases: params.collectionSelected ? this.store.query('matter', {
                    client_collection_id: params.collectionSelected,
                    with: 'matter_types,matter_has_tags,matter_tags,matter_private_tags,matter_has_private_tags,case_owners'
                }) : [],
                params: params
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            if (model.params.collectionSelected) {
                controller.set('selected', model.collections.findBy('id', model.params.collectionSelected));
                controller.set('mainCase', model.cases.findBy('id', '' + controller.get('selected.mainMatterId')));
            }
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});