define('tm3/pods/components/documents/table-pagination/component', ['exports', 'ember-cli-table-pagination/components/table-pagination'], function (exports, _tablePagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _tablePagination.default.extend({
        // layout: layout,
        // ui customization
        classNames: ['box', 'box-top', 'ember-cli-table-pagination'],
        classNameBindings: ['boxSizeClass'],

        // properties
        boxSize: 12,

        // override inherited properties
        perPage: 50,

        // computed:
        boxSizeClass: Ember.computed('boxSize', function () {
            var boxSize = this.get('boxSize');
            return 'box-' + boxSize;
        }),

        // override the components:
        bodyComponent: 'documents/table-body',
        contentComponent: 'documents/table-content',
        footerComponent: 'bs-table-pagination.table-footer',
        pagerComponent: 'documents/table-pager',
        titleComponent: 'documents/table-title',
        toolbarComponent: 'documents/table-toolbar',
        toolsComponent: 'documents/table-tools',
        noDataComponent: 'documents/table-no-data'
    });
});