define('tm3/pods/report/info/invoiced-customers/controller', ['exports', 'tm3/mixins/report-info-controller', 'ember-concurrency'], function (exports, _reportInfoController, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reportInfoController.default, {
        queryParams: [],

        reportBuilder: Ember.inject.service(),

        tableData: Ember.computed.alias('model.tableData.table_data'),
        columns: Ember.computed.alias('model.tableData.columns'),
        showTotalRow: false,
        noDataMessage: 'Select some filters to show the report',

        actions: {
            getReport: function getReport(format) {
                var jsonOptions = {
                    report_builder: {
                        standard: {
                            type: 'invoiced_customers',
                            format: format,
                            style: 'misc'
                        },
                        custom: {
                            per_page: 1000000,
                            page: 1,
                            from_tabular: true
                        }
                    }
                };

                this.get('reportBuilder').executeReport(jsonOptions);
            }
        }
    });
});