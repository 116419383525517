define('tm3/pods/admin/scopes/scope/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notify: Ember.inject.service(),
        lists: Ember.inject.service(),

        requiredPermissions: ['Manage Case Scopes'],

        model: function model(params) {
            return this.store.findRecord('report-component', params.id);
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'admin' });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('simple', false);
        }
    });
});