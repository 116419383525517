define('tm3/helpers/height-value', ['exports', 'ember-cli-table-pagination/helpers/height-value'], function (exports, _heightValue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _heightValue.default;
    }
  });
  Object.defineProperty(exports, 'heightValue', {
    enumerable: true,
    get: function () {
      return _heightValue.heightValue;
    }
  });
});