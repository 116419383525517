define('tm3/pods/crm/accounts/info/activity/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var _this = this;

            var infoModel = this.modelFor('crm.accounts.info');
            var firmsId = infoModel.firm.get('id');

            return this.store.query('firm-has-client', {
                firm_id: firmsId,
                with: 'users,clients'
            }).then(function (firmHasClients) {
                var clientIds = firmHasClients.map(function (fhc) {
                    return fhc.get('user.id');
                });
                return _this.store.query('crm-note-has-client', {
                    client_id: clientIds,
                    with: 'crm_notes'
                });
            }).then(function (notesPerClient) {
                return notesPerClient.map(function (note) {
                    return note.get('crmNote');
                });
            });
        }
    });
});